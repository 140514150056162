<template>
    <InteractiveDocs :steps="steps"></InteractiveDocs>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { waitFor } from "@/interactive-docs/utils"
    import { circler } from "@/interactive-docs/mixin"

    export default {
        name: "ReviewDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [
                    analysisCard,
                    imgtAndPipelineVersion,
                    changeAnalysisButtons,
                    approve,
                    support,
                    runList,
                    qcDetails,
                ],
            }
        }
    }

    const analysisCard = {
        name: "analysisCard",
        mixins: [ circler ],
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector(".analysis-card"))
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        <b>Предназначение:</b> утверждение генотипов, назначенных специалистом службы поддержки.
                    </p>
                    <br/>
                    <p>
                        Приложение состоит из карточки образца и элементов управления
                        Карточка образца разделена на 2 смысловые части: метаданные и генотипы.
                    </p>
                    <br/>
                    <p>Метаданные:
                        <ul class="bullet-list">{
                            [
                                [ "Имя образца", "analysis-name" ],
                                [ "Дата запуска", "sequencing-run-date" ],
                                [ "Имя запуска", "run-name" ],
                                [ "Идентификатор образца в запуске", "sample-id-in-run" ],
                                [ "Серия набора реагентов, с которым был поставлен образец", "lot-badge" ],
                                [ "Детали контроля качества. Если какие-то метрики попали в субоптимальную зону, рядом с кнопкой будет отображен желтый кружок", "qc-details-btn" ],
                            ].map(([ text, id ]) =>
                                (<li class="bullet-list__element dashed-underline"
                                     onMouseenter={() => this.circler.moveHighlight([ document.querySelector(`#${id}`) ])}
                                     onMouseleave={() => this.circler.clean()}
                                >{text}</li>))}
                        </ul>
                    </p>
                </div>
            )
        }
    }


    const imgtAndPipelineVersion = {
        name: "imgtAndPipelineVersion",
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight([
                document.querySelector("#lot-badge"),
                document.querySelector("#imgt-version a"),
            ])
        },
        methods: {},
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Версия алгоритма анализа данных, использованного для анализа текущего образца,
                        и базы IMGT указаны во всплывающей подсказке бейджа серии набора реагентов.

                        Версия IMGT со ссылкой на сайт указана внизу карточки.
                    </p>
                </div>
            )
        }
    }

    const changeAnalysisButtons = {
        name: "changeAnalysisButtons",
        props: {
            highlighter: Object,
        },
        async mounted() {
            const nextAnalysisBtn = await waitFor(() => document.querySelector(".next-analysis-btn"))
            nextAnalysisBtn.dispatchEvent(new MouseEvent("click"))

            const previousAnalysisBtn = await waitFor(() => document.querySelector(".previous-analysis-btn"))
            this.highlighter.moveHighlight([
                nextAnalysisBtn,
                previousAnalysisBtn,
            ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Для перехода к следующему/предыдущему анализу можно использовать кнопки
                        в виде стрелок по бокам карточки анализа.
                        Либо воспользоваться <i>стрелками влево/вправо</i> на клавиатуре.
                    </p>
                </div>
            )
        }
    }

    const approve = {
        name: "approve",
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector("#approve-btn"))
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Если внесенные работником службы поддержки изменения не вызывают вопросов,
                        то образец можно утвердить, нажав на кнопку "утвердить"
                        или на <i>стрелку вниз</i> на клавиатуре.
                    </p>
                </div>
            )
        }
    }

    const support = {
        name: "support",
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector("#to-support-btn"))
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Если внесенные работником службы поддержки изменения вызывают вопросы, можно отправить образец в
                        поддержку, нажав
                        на кнопку "отправить в поддержку"
                        или на <i>стрелку вверх</i> на клавиатуре.
                        В открывшемся модальном окне необходимо отметить локусы,
                        вызывающие вопросы, и оставить комментарии для сотрудника службы поддержки.
                    </p>
                </div>
            )
        }
    }

    const runList = {
        name: "runList",
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector("#run-with-analyses-to-review-list"))
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Если в вашей организации есть несколько запусков с анализами, ожидающими одобрения,
                        их список с количеством соответствующих анализов будет отображен в правой части экрана.
                        С его помощью можно перейти к интересующему ваc запуску.
                    </p>
                </div>
            )
        }
    }

    const qcDetails = {
        name: "qcDetails",
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector("#qc-details-btn"))
            el.dispatchEvent(new MouseEvent("click"))
            const modal = await waitFor(() => document.querySelector(".analysis-details-modal"))
            this.highlighter.moveHighlight([ modal ], { isModal: true })
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение одобрения</h3>
                    <p>
                        Описание метрик контроля качества дано в&nbsp;
                        <a href="/docs/#/?id=Метрики-качества-образца"
                           target="_blank"
                           rel="noreferrer noopener">документации</a>.

                        Графики размера вставки, среднего качества прочтений и
                        числа прочтений на локус строятся по все образцам запуска,
                        поэтому будут недоступны до окончания
                        анализа всех образцов в запуске.
                    </p>
                </div>
            )
        },
        destroyed() {
            document.querySelector(".analysis-details-modal .btn")?.dispatchEvent(new MouseEvent("click"))
        }
    }
</script>
