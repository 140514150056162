export type SampleValidationErrorType = "forwardFilesCount" | "reverseFilesCount" | "wrongSampleName"
type SampleValidationError = {
    type: SampleValidationErrorType,
    details?: any
}

const validators: Array<(sample: SampleForUpload) => SampleValidationError | null> = [
    sample => {
        const forwardFilesCount = countResources(sample, RawDataResourceTypes.FORWARD)
        const expected = 1
        return forwardFilesCount !== expected
            ? {
                type: "forwardFilesCount",
                details: { actual: forwardFilesCount, expected }
            }
            : null
    },
    sample => {
        const reverseFilesCount = countResources(sample, RawDataResourceTypes.REVERSE)
        const expected = 1
        return reverseFilesCount !== expected
            ? {
                type: "reverseFilesCount",
                details: { actual: reverseFilesCount, expected }
            }
            : null
    },
    sample => new RegExp("^[A-Za-z0-9-]{1,25}$").test(sample.name)
        ? null
        : {
            type: "wrongSampleName",
        },
]

function countResources(sample: SampleForUpload, resourceTypeRegExp: RegExp) {
    return sample.files.map(it => it.name)
        .map(it => resourceTypeRegExp.test(it))
        .filter(it => it).length
}

class RawDataResourceTypes {
    static FORWARD = new RegExp("^\\S+_R1\\S*\\.fastq\\.gz$")
    static REVERSE = new RegExp("^\\S+_R2\\S*\\.fastq\\.gz$")
}

export function validateSample(sample: SampleForUpload): Array<SampleValidationError> {
    return <Array<SampleValidationError>>validators
        .map(validate => validate(sample))
        .filter(it => it)
}

export class SampleForUpload {
    constructor(
        public readonly name: string,
        public readonly runId: string,
        public readonly files: File[],
    ) {
    }
}
