<template>
    <div class="samples-container custom-scroll">
        <div class="sample"
             :key="sample.runId"
             v-for="sample in lot.samplesOrderedByRunId"
        >
            <span class="sample--field  sample--run-id">{{ sample.runId }}</span>
            <span class="sample--field sample--name ellipsis">{{ sample.name }}</span>
            <p-with-tooltip class="ml-auto" fixed>
                <p-icon
                    class="sample--field"
                    icon-name="file-filled"
                />
                <template #tooltip>
                    <div class="tooltip-black">
                        <p :key="file.name" v-for="file in sample.files">{{ file.name }}</p>
                    </div>
                </template>
            </p-with-tooltip>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LotSamples",
        props: {
            lot: Object
        }
    }
</script>

<style scoped>
    .samples-container {
        height: 100%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .sample {
        width: 17em;
        height: fit-content;
        margin: .5em;
        display: flex;
        padding: .7em 0;
        align-items: center;
        border: 1px solid var(--grey-400);
        border-radius: 4px;
    }

    .sample--field {
        margin: 0 1em;
    }

    .sample--name {
        flex-grow: 0;
    }

    .sample--run-id {
        width: 3em;
    }
</style>
