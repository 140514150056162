<template>
    <div class="relative">
        <div class="container flex">
            <div class="ml-1 flex-fixed-icon"
                 v-if="$slots['icon-left']"
                 @mousedown.prevent>
                <slot name="icon-left"></slot>
            </div>
            <input :class="isInvalid ? 'invalid': ''"
                   :value="value"
                   class="md-input"
                   placeholder="don't remove me"
                   ref="input"
                   v-bind="$attrs"
                   v-on="inputListeners"
            >
            <!--
             Why separate div for border:
             1. To prevent content from moving, when border of component becomes thicker on focus
             2. Only input has means to watch for focus, without tricks. So I cannot make border an ::after for container
             3. I also can't make ::after on input cause input might be only part of container content, i.e. prepend/append icons
              -->
            <div class="md-border"></div>
            <label
                class="md-label"
                :class="{
                    'ml-2': $slots['icon-left']
                }"
            > {{ $attrs.placeholder }}</label>
            <div class="mr-1 flex-fixed-icon"
                 v-if="$slots['icon-right']"
                 @mousedown.prevent>
                <slot name="icon-right"></slot>
            </div>
        </div>
        <p-input-errors v-if="touched" :validation-errors="validationErrors"/>
    </div>
</template>

<script>
    import { inputMixin } from "@/mixins/input-mixin"

    /*
     * Do not use any builtin validation, if you want any preform it yourself and pass validationErrors
     *
     * Placeholder of input is hidden, but it should not be empty for
     * placeholder-shown selector to work
     *
     * Label is used as a placeholder and when input is focused as label above input
     */
    export default {
        name: "PInput",
        inheritAttrs: false,
        mixins: [ inputMixin ],
        data() {
            return {
                observer: null
            }
        },
        props: {
            autofocus: Boolean
        },
        computed: {
            inputListeners() {
                return {
                    ...this.$listeners,
                    input: (event) => {
                        this.$emit("input", event.target.value)
                    },
                    focusout: () => {
                        // Actually there is no side-effect during computed property calculation
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.touched = true
                    }
                }
            }
        },
        mounted() {
            const styles = [ "md-outlined", "md-filled" ]
            if (!styles.some(style => [ ...this.$el.classList ].includes(style))) {
                console.error(`Specified style: ${this.style} is not valid. Available are ${styles}`)
            }
            /*
             I found no other way to detect component visibility. If component is under `v-show`
             it's mounted once, opposed to `v-if`, when component is mounted/destroyed every time.
             So I can use mounted with `this.$refs.input.focus()` when this component is under `v-if`,
             but can't when it's under `v-show`.
             I chosen the most universal solution. If it will be non performant
             or not well supported (e.g. IE11 lacks support) I will replace it and force `v-if`.
             */
            if (this.autofocus) {
                this.observer = new IntersectionObserver(changes => {
                    for (const change of changes) {
                        if (change.isIntersecting) {
                            this.$refs.input.focus()
                        }
                    }
                }, {})
                this.observer.observe(this.$refs.input)
            }
        },
        destroyed() {
            this.observer?.disconnect()
        }
    }
</script>
