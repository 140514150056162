<template>
    <div class="w-fit-content flex" style="flex-flow: column">
        <div v-if="getBrand === 'PARSEQ'" class="w-fit-content flex" style="flex-flow: column">
            <svg
                style="overflow: visible; margin: 1em; height: 3em"
                id="logo"
                viewBox="0 0 60 60"
                x="0"
                y="0"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="30" cy="30" fill="none" r="5" stroke="var(--parseq-blue)" stroke-width="3.5" />
                <g class="circle first-circle">
                    <path
                        d="M30,16 A14,14 0 1,0 42.12435565298214,23.0"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                    <path
                        d="M34.78828200655936,16.844303308997283 A14, 14 0 0,1 38.99902653561155,19.275377796334308"
                        fill="none"
                        stroke="var(--parseq-orange)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                </g>
                <g class="circle second-circle">
                    <path
                        d="M16.80774196392594,11.159502981353189 A23,23 0 0,0 32.00458208319614,52.912478056110146"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                    <path
                        d="M37.86646329649038,51.61293027807589 A23,23 0 0,0 41.5,49.918584287042094"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                    <path
                        d="M46.2634559672906,46.2634559672906 A23,23 0 0,0 22.13353670350962,8.387069721924107"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                </g>
                <g class="circle third-circle">
                    <path
                        d="M24.44325831465823,-1.5138480963906567 A32,32 0 0,1 30.0,-2.0"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                    <path
                        d="M35.55674168534177,-1.5138480963906567 A32,32 0 0,1 57.712812921102035,13.999999999999996"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                    <path
                        d="M60.07016386514907,19.055355413578596 A32,32 0 0,1 62.0,29.999999999999996"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                </g>
                <g class="circle fourth-circle">
                    <path
                        d="M44.022825876352414,-8.527397452222246 A41,41 0 0,1 65.50704155516198,9.499999999999996"
                        fill="none"
                        stroke="var(--parseq-blue)"
                        stroke-linecap="round"
                        stroke-width="3.5"
                    />
                </g>
            </svg>
            <p style="text-align: center">
                {{ $t("loadingData") }}
            </p>
        </div>
        <svg v-if="getBrand === 'EPIVALUE'" class="spinner" viewBox="0 0 50 50" style="margin: 1em; height: 3em;">
            <circle class="path" cx="25" cy="25" r="20" fill="none" style="stroke: royalblue;" stroke-width="5" />
        </svg>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "PSpinner",
        i18n: {
            messages: {
                [Locales.EN]: {
                    loadingData: "Loading data"
                },
                [Locales.RU]: {
                    loadingData: "Идет загрузка данных"
                }
            }
        },
        computed: {
            getBrand() {
                const { brand } = useBrand()
                return brand
            }
        }
    }
</script>

<style scoped>
.circle {
    transform-origin: center;
}

.first-circle {
    animation: rotate-clockwise 3s ease-in-out infinite;
}

.second-circle {
    animation: rotate-counter-clockwise 3s ease-in-out infinite;
}

.third-circle {
    animation: rotate-clockwise 3s ease-in-out infinite;
}

.fourth-circle {
    animation: rotate-counter-clockwise 3s ease-in-out infinite;
}

@keyframes rotate-clockwise {
    from {
        transform: rotate(0deg);
        stroke: var(--parseq-orange);
    }
    to {
        transform: rotate(360deg);
        stroke: var(--parseq-blue);
    }
}

@keyframes rotate-counter-clockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.spinner {
    width: 25px;
    height: 25px;
    animation: rotate 2s linear infinite;
}

.spinner .path {
    stroke: var(--primary-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
</style>
