import { Analysis } from "@/utils/analysis"

export const analysesForAnalysesDetailsChartsStubs: Array<Analysis> = [
    {
        "id": "1c91a919-cb74-3cdd-97f5-20d84b865dbb",
        "runId": 1,
        "name": "52104",
        "createdAt": 1580917329691,
        sequencingRunDate: 1580917329691,
        "status": "IN_SUPPORT",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+03:01",
                "B": "40:02+50:01",
                "C": "02:02+06:02",
                "DQB1": "02:01+06:03",
                "DRB1": "03:01/03:147+13:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52104_S1",
            "qualityMetrics": {
                "insertSize": 422,
                "contamination": false,
                "meanReadsQuality": 35.50779638775464,
                "readsDistribution": {
                    "targetLoci": 85376,
                    "relatedLoci": 23574,
                    "qualityTrimmed": 31726,
                    "offTargetRegions": 31376
                },
                "targetLociCoverage": {
                    "A": 21390,
                    "B": 9492,
                    "C": 21288,
                    "DQB1": 12970,
                    "DRB1": 20236
                }
            },
        },
        maybeSupportRequest: null,
        maybeResolutionDraft: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "8060edbd-0c4e-3ebe-8ac8-c8f5cdf48fa8",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "1d95df1e-80ea-3aef-a8b1-0460ae14c2cb",
        "runId": 2,
        "name": "52105",
        "createdAt": 1580917348622,
        sequencingRunDate: 1580917348622,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+25:01",
                "B": "18:01+40:01",
                "C": "03:04+12:03",
                "DQB1": "04:02+06:02",
                "DRB1": "08:01+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52105_S2",
            "qualityMetrics": {
                "insertSize": 432,
                "contamination": false,
                "meanReadsQuality": 35.47047016171034,
                "readsDistribution": {
                    "targetLoci": 77636,
                    "relatedLoci": 2592,
                    "qualityTrimmed": 22906,
                    "offTargetRegions": 21200
                },
                "targetLociCoverage": {
                    "A": 19102,
                    "B": 7682,
                    "C": 17988,
                    "DQB1": 12442,
                    "DRB1": 20422
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "b61d665c-aba3-3335-99c1-8b37a19a3eae",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "317716fc-bfc2-32b8-a4b1-084da8a560e1",
        "runId": 3,
        "name": "52106",
        "createdAt": 1580917340080,
        sequencingRunDate: 1580917340080,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "03:01+03:01",
                "B": "35:01+38:01",
                "C": "04:01+12:03",
                "DQB1": "03:02+05:01",
                "DRB1": "01:01+04:02"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52106_S3",
            "qualityMetrics": {
                "insertSize": 446,
                "contamination": false,
                "meanReadsQuality": 35.17876258039132,
                "readsDistribution": {
                    "targetLoci": 75158,
                    "relatedLoci": 12508,
                    "qualityTrimmed": 31184,
                    "offTargetRegions": 25230
                },
                "targetLociCoverage": {
                    "A": 15722,
                    "B": 8524,
                    "C": 17218,
                    "DQB1": 16032,
                    "DRB1": 17662
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "29373841-cfff-353c-be9d-3bf8a0da5793",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "1482cff4-b243-3c45-a8fe-00165f925efc",
        "runId": 4,
        "name": "52107",
        "createdAt": 1580917335745,
        sequencingRunDate: 1580917335745,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+02:01",
                "B": "18:01+44:27",
                "C": "07:01+07:04",
                "DQB1": "03:01/03:276N/03:297+05:02",
                "DRB1": "11:01+16:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52107_S4",
            "qualityMetrics": {
                "insertSize": 428,
                "contamination": false,
                "meanReadsQuality": 35.22472034224924,
                "readsDistribution": {
                    "targetLoci": 72496,
                    "relatedLoci": 10896,
                    "qualityTrimmed": 28946,
                    "offTargetRegions": 22858
                },
                "targetLociCoverage": {
                    "A": 18512,
                    "B": 7794,
                    "C": 15638,
                    "DQB1": 13912,
                    "DRB1": 16640
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "6a6031e5-6c0c-3aa5-b61c-3097aa0a587f",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "5e935839-58fc-3cf5-8b52-c0e59681e436",
        "runId": 5,
        "name": "52108",
        "createdAt": 1580917332704,
        sequencingRunDate: 1580917332704,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+30:01",
                "B": "07:02+44:02",
                "C": "07:02+16:04",
                "DQB1": "03:01/03:276N/03:297+06:02",
                "DRB1": "11:04+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52108_S5",
            "qualityMetrics": {
                "insertSize": 442,
                "contamination": false,
                "meanReadsQuality": 35.334257629110255,
                "readsDistribution": {
                    "targetLoci": 89540,
                    "relatedLoci": 14172,
                    "qualityTrimmed": 34052,
                    "offTargetRegions": 33208
                },
                "targetLociCoverage": {
                    "A": 23588,
                    "B": 10084,
                    "C": 20758,
                    "DQB1": 13842,
                    "DRB1": 21268
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "967fd075-80b1-30ea-b8ec-b7fe3ef6ec48",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "b762942a-c294-3a05-a028-01e79932b392",
        "runId": 6,
        "name": "52109",
        "createdAt": 1580917347437,
        sequencingRunDate: 1580917347437,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+03:01",
                "B": "44:03+52:01",
                "C": "04:01+12:02",
                "DQB1": "02:02+05:01",
                "DRB1": "01:01+07:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52109_S6",
            "qualityMetrics": {
                "insertSize": 423,
                "contamination": false,
                "meanReadsQuality": 35.15169952400572,
                "readsDistribution": {
                    "targetLoci": 74640,
                    "relatedLoci": 9890,
                    "qualityTrimmed": 29934,
                    "offTargetRegions": 23222
                },
                "targetLociCoverage": {
                    "A": 17508,
                    "B": 8610,
                    "C": 17006,
                    "DQB1": 11668,
                    "DRB1": 19848
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "7da5a60b-e37a-3879-b77d-31487974767e",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "565371e1-a32f-34a3-a16e-29df304e006c",
        "runId": 7,
        "name": "52110",
        "createdAt": 1580917346842,
        sequencingRunDate: 1580917346842,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "yellow",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 95748,
                        "quality": "yellow"
                    }
                }
            },
            "genotypes": {
                "A": "02:01+03:01",
                "B": "35:01+57:01",
                "C": "04:01+06:02",
                "DQB1": "02:02+03:03",
                "DRB1": "07:01+07:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52110_S7",
            "qualityMetrics": {
                "insertSize": 405,
                "contamination": false,
                "meanReadsQuality": 35.374790461274316,
                "readsDistribution": {
                    "targetLoci": 57336,
                    "relatedLoci": 3940,
                    "qualityTrimmed": 18372,
                    "offTargetRegions": 16100
                },
                "targetLociCoverage": {
                    "A": 13566,
                    "B": 6734,
                    "C": 13324,
                    "DQB1": 6280,
                    "DRB1": 17432
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "7fa57f32-1f2e-380a-afd0-9934cb17c761",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "48b93296-079e-3207-9422-d80d5accb169",
        "runId": 8,
        "name": "52111",
        "createdAt": 1580917351977,
        sequencingRunDate: 1580917351977,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "01:01+02:01",
                "B": "08:01+57:01",
                "C": "06:02+07:01",
                "DQB1": "02:01+03:03",
                "DRB1": "03:01/03:147+07:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52111_S8",
            "qualityMetrics": {
                "insertSize": 389,
                "contamination": false,
                "meanReadsQuality": 35.23014616545632,
                "readsDistribution": {
                    "targetLoci": 78878,
                    "relatedLoci": 18388,
                    "qualityTrimmed": 34410,
                    "offTargetRegions": 29600
                },
                "targetLociCoverage": {
                    "A": 20582,
                    "B": 8556,
                    "C": 18854,
                    "DQB1": 10552,
                    "DRB1": 20334
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "e6120200-5734-37ca-991c-88f4624fb303",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "71d8dc22-ec31-36e3-9b54-9733f41669ee",
        "runId": 9,
        "name": "52112",
        "createdAt": 1580917341339,
        sequencingRunDate: 1580917341339,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "33:01+33:03",
                "B": "07:02+58:01",
                "C": "03:02+07:02",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+12:01/12:10"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52112_S9",
            "qualityMetrics": {
                "insertSize": 414,
                "contamination": false,
                "meanReadsQuality": 35.628555033610766,
                "readsDistribution": {
                    "targetLoci": 99342,
                    "relatedLoci": 22272,
                    "qualityTrimmed": 33790,
                    "offTargetRegions": 33892
                },
                "targetLociCoverage": {
                    "A": 26008,
                    "B": 11326,
                    "C": 21912,
                    "DQB1": 20982,
                    "DRB1": 19114
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "b7adc934-a368-3107-b7ad-15da3771d179",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "09ded031-220e-38ce-b7f9-509e2ac95375",
        "runId": 10,
        "name": "52113",
        "createdAt": 1580917363943,
        sequencingRunDate: 1580917363943,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:06+03:01",
                "B": "35:03+51:01",
                "C": "03:04+04:01",
                "DQB1": "03:01/03:276N/03:297+04:02",
                "DRB1": "08:01+11:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52113_S10",
            "qualityMetrics": {
                "insertSize": 480,
                "contamination": false,
                "meanReadsQuality": 35.44932375845835,
                "readsDistribution": {
                    "targetLoci": 94736,
                    "relatedLoci": 11720,
                    "qualityTrimmed": 32548,
                    "offTargetRegions": 27294
                },
                "targetLociCoverage": {
                    "A": 23134,
                    "B": 10386,
                    "C": 21240,
                    "DQB1": 16096,
                    "DRB1": 23880
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "db67bc39-91c9-3e51-827c-c15ae7e550b6",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "f75462ff-8974-3289-96e8-6e4d31330b5f",
        "runId": 11,
        "name": "52114",
        "createdAt": 1580917340649,
        sequencingRunDate: 1580917340649,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+11:01",
                "B": "07:02+49:01",
                "C": "07:01+07:02|07:27+07:639",
                "DQB1": "06:02+06:04|06:39+06:84",
                "DRB1": "13:02+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52114_S11",
            "qualityMetrics": {
                "insertSize": 417,
                "contamination": false,
                "meanReadsQuality": 35.603358717098516,
                "readsDistribution": {
                    "targetLoci": 79202,
                    "relatedLoci": 17938,
                    "qualityTrimmed": 27734,
                    "offTargetRegions": 27590
                },
                "targetLociCoverage": {
                    "A": 21230,
                    "B": 9718,
                    "C": 16916,
                    "DQB1": 13530,
                    "DRB1": 17808
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "f8227182-8860-3be0-a9af-d1d85baa85db",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "f6425b59-05b1-3ca8-9d6e-467e4091ff74",
        "runId": 12,
        "name": "52115",
        "createdAt": 1580917355417,
        sequencingRunDate: 1580917355417,
        "status": "IN_SUPPORT",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "red",
            "problems": {
                "locusSpecificMetrics": {
                    "C": {
                        "genotypesNumber": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 2,
                            "quality": "red"
                        }
                    }
                }
            },
            "genotypes": {
                "A": "02:01+25:01",
                "B": "18:01+41:02",
                "C": "12:03+17:03|12:03+17:39",
                "DQB1": "03:01/03:276N/03:297+06:02",
                "DRB1": "13:03+15:01"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "52115_S12",
            "qualityMetrics": {
                "insertSize": 411,
                "contamination": false,
                "meanReadsQuality": 35.50404237931418,
                "readsDistribution": {
                    "targetLoci": 115022,
                    "relatedLoci": 19576,
                    "qualityTrimmed": 41004,
                    "offTargetRegions": 36682
                },
                "targetLociCoverage": {
                    "A": 31456,
                    "B": 11946,
                    "C": 26570,
                    "DQB1": 18110,
                    "DRB1": 26940
                }
            },
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "c3d35af3-f086-3f07-9f0c-6b5fec9a5c04",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "9a1f7858-4e66-3b65-811e-5e2a50315f66",
        "runId": 13,
        "name": "52116",
        "createdAt": 1580917346248,
        sequencingRunDate: 1580917346248,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "26:01+26:01",
                "B": "40:02+56:01",
                "C": "01:02+03:03",
                "DQB1": "04:02+06:02",
                "DRB1": "08:02+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52116_S13",
            "qualityMetrics": {
                "insertSize": 426,
                "contamination": false,
                "meanReadsQuality": 35.609302258541646,
                "readsDistribution": {
                    "targetLoci": 93860,
                    "relatedLoci": 6630,
                    "qualityTrimmed": 29650,
                    "offTargetRegions": 32970
                },
                "targetLociCoverage": {
                    "A": 20954,
                    "B": 10298,
                    "C": 20162,
                    "DQB1": 18002,
                    "DRB1": 24444
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "51cd9f8d-b892-3831-a9ba-b99517bf5409",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "be3cd9f5-f5fc-36cb-ae53-7286e1f56f87",
        "runId": 14,
        "name": "52117",
        "createdAt": 1580917352381,
        sequencingRunDate: 1580917352381,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "01:01+11:01",
                "B": "27:05+51:01",
                "C": "02:02+04:01",
                "DQB1": "03:01/03:276N/03:297+06:02",
                "DRB1": "04:07+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52117_S14",
            "qualityMetrics": {
                "insertSize": 457,
                "contamination": false,
                "meanReadsQuality": 35.29021233078181,
                "readsDistribution": {
                    "targetLoci": 77482,
                    "relatedLoci": 5990,
                    "qualityTrimmed": 29952,
                    "offTargetRegions": 24950
                },
                "targetLociCoverage": {
                    "A": 18358,
                    "B": 9398,
                    "C": 18266,
                    "DQB1": 13598,
                    "DRB1": 17862
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "d5a0521a-36a3-37c4-8c91-8ba90b7a8c7d",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "18ea0bbc-8007-3f32-9eba-5d0d2761f875",
        "runId": 15,
        "name": "52118",
        "createdAt": 1580917367964,
        sequencingRunDate: 1580917367964,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "29:02+31:01",
                "B": "38:01+44:03",
                "C": "12:03+16:01",
                "DQB1": "02:02+03:03",
                "DRB1": "07:01+07:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52118_S15",
            "qualityMetrics": {
                "insertSize": 424,
                "contamination": false,
                "meanReadsQuality": 35.577876439560896,
                "readsDistribution": {
                    "targetLoci": 91046,
                    "relatedLoci": 14672,
                    "qualityTrimmed": 30472,
                    "offTargetRegions": 30470
                },
                "targetLociCoverage": {
                    "A": 21990,
                    "B": 8994,
                    "C": 19966,
                    "DQB1": 13012,
                    "DRB1": 27084
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "45127a86-0032-3e3e-b36d-29875b9eb044",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "5aad35b0-cef7-37f7-9c13-b387183b8efa",
        "runId": 16,
        "name": "52119",
        "createdAt": 1580917336147,
        sequencingRunDate: 1580917336147,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+24:02",
                "B": "35:02+44:02",
                "C": "04:01+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "11:04+11:04"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52119_S16",
            "qualityMetrics": {
                "insertSize": 384,
                "contamination": false,
                "meanReadsQuality": 35.45430529680266,
                "readsDistribution": {
                    "targetLoci": 76006,
                    "relatedLoci": 24952,
                    "qualityTrimmed": 32782,
                    "offTargetRegions": 32564
                },
                "targetLociCoverage": {
                    "A": 20488,
                    "B": 9806,
                    "C": 19600,
                    "DQB1": 8638,
                    "DRB1": 17474
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "53a43579-d84f-343b-a5d5-8473976dfaf9",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "bf79e838-aad4-3108-aa8a-ed4f654ec69c",
        "runId": 17,
        "name": "52120",
        "createdAt": 1580917367787,
        sequencingRunDate: 1580917367787,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+03:01",
                "B": "13:02+40:02",
                "C": "02:02+06:02",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "11:01+11:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52120_S17",
            "qualityMetrics": {
                "insertSize": 382,
                "contamination": false,
                "meanReadsQuality": 35.89946582638393,
                "readsDistribution": {
                    "targetLoci": 97130,
                    "relatedLoci": 24692,
                    "qualityTrimmed": 26090,
                    "offTargetRegions": 33362
                },
                "targetLociCoverage": {
                    "A": 23680,
                    "B": 11586,
                    "C": 23140,
                    "DQB1": 19590,
                    "DRB1": 19134
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "a8894df1-0153-318c-8ce9-d427152225b0",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "679c4afe-b6f1-3586-bd47-3d2289a8a29a",
        "runId": 18,
        "name": "52121",
        "createdAt": 1580917346445,
        sequencingRunDate: 1580917346445,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+02:01",
                "B": "07:02+27:02",
                "C": "02:02+07:02",
                "DQB1": "05:01+05:02",
                "DRB1": "01:01+16:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52121_S18",
            "qualityMetrics": {
                "insertSize": 432,
                "contamination": false,
                "meanReadsQuality": 35.70475868499466,
                "readsDistribution": {
                    "targetLoci": 156148,
                    "relatedLoci": 21602,
                    "qualityTrimmed": 43248,
                    "offTargetRegions": 44294
                },
                "targetLociCoverage": {
                    "A": 35228,
                    "B": 15264,
                    "C": 32226,
                    "DQB1": 33790,
                    "DRB1": 39640
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "29529499-bc39-398e-9791-b55b5562f452",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
    {
        "id": "038cc217-4361-31d9-9105-5d29069176db",
        "runId": 19,
        "name": "52122",
        "createdAt": 1580917355259,
        sequencingRunDate: 1580917355259,
        "status": "COMPLETED",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            annotations: null,
            "quality": "green",
            "problems": null,
            "genotypes": {
                "A": "02:01+03:01",
                "B": "07:02+07:02",
                "C": "07:02+07:02",
                "DQB1": "06:02+06:02",
                "DRB1": "15:01+15:01"
            },
            "resolution": "WELL_TYPED",
            "sampleName": "52122_S19",
            "qualityMetrics": {
                "insertSize": 439,
                "contamination": false,
                "meanReadsQuality": 35.514923599943984,
                "readsDistribution": {
                    "targetLoci": 123936,
                    "relatedLoci": 5604,
                    "qualityTrimmed": 34966,
                    "offTargetRegions": 31822
                },
                "targetLociCoverage": {
                    "A": 29778,
                    "B": 13734,
                    "C": 23886,
                    "DQB1": 20072,
                    "DRB1": 36466
                }
            }
        },
        maybeResolutionDraft: null,
        maybeSupportRequest: null,
        "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "55a05671-51b5-3375-99b4-dd74993a8939",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "PASSED",
        "priorStates": []
    },
]
