var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allele-panel"},[(_vm.modals.addExistingAllele.opened)?_c('p-modal',[_c('div',{staticClass:"modal"},[_c('add-existing-allele-form',{attrs:{"genotype":_vm.tempGenotypeAtActiveLocus,"allele-colors":_vm.alleleColors},model:{value:(_vm.existingAlleleWithIndex),callback:function ($$v) {_vm.existingAlleleWithIndex=$$v},expression:"existingAlleleWithIndex"}}),_c('div',{staticClass:"flex",staticStyle:{"font-size":"1.4rem"}},[_c('button',{staticClass:"md-btn-text",staticStyle:{"margin-left":"-1.1em"},on:{"click":_vm.cancelExistingAlleleAddition}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")]),_c('button',{staticClass:"md-btn-contained ml-auto royal-blue",attrs:{"disabled":!_vm.couldSaveExistingAllele},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])])],1)]):_vm._e(),_c('div',{staticClass:"filter p-1"},[_c('p-input',{staticClass:"md-outlined monospace bold mb-1",attrs:{"type":"text","placeholder":_vm.$t('searchAlleleNumber')},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('p-icon',{attrs:{"icon-name":"search","size":"1.5em"}})]},proxy:true}]),model:{value:(_vm.alleleFilter),callback:function ($$v) {_vm.alleleFilter=$$v},expression:"alleleFilter"}})],1),(_vm.alleleHints.length)?_c('div',{staticClass:"overflow-auto custom-scroll"},[_c('ul',{attrs:{"data-test-id":"allele-hints-list"}},_vm._l((_vm.filteredAlleleHints),function(ref){
var hint = ref[0];
var tools = ref[1];
return _c('li',{key:hint,staticClass:"allele-hint",class:{selected: _vm.selectedAllele === hint},attrs:{"data-test-id":"allele-hint"},on:{"click":function($event){return _vm.selectAllele(hint)}}},[_c('div',{staticStyle:{"width":"8em"}},_vm._l((tools),function(tool){return _c('span',{staticClass:"badge badge-margin tool-badge",class:("badge-" + tool)},[_vm._v(_vm._s(tool)+" ")])}),0),_c('span',{staticClass:"monospace bold",staticStyle:{"width":"15em"}},[_vm._v(_vm._s(hint))]),_c('div',{staticClass:"flex"},[_vm._l((_vm.tempGenotypeAtActiveLocus),function(genotypePart,genotypePartIndex){return [_c('fieldset',{staticClass:"allele-hint-toggle__genotype-part"},_vm._l(([0, 1]),function(alleleIndex){return _c('label',{class:("allele-hint-toggle__allele-wrapper " + (genotypePart[alleleIndex].includes(_vm.NEW_ALLELE_PLACEHOLDER) ? 'disable' : '')),style:({
                                    color: _vm.alleleColors[2 * genotypePartIndex + alleleIndex],
                                    backgroundColor: genotypePart[alleleIndex].includes(_vm.NEW_ALLELE_PLACEHOLDER) ? 'rgb(209 210 210)' : 'transparent',
                                }),on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{class:("allele-hint-toggle__allele " + (genotypePart[alleleIndex].includes(_vm.NEW_ALLELE_PLACEHOLDER) ? 'disable' : '')),style:({
                                        color: _vm.alleleColors[2 * genotypePartIndex + alleleIndex],
                                        backgroundColor: genotypePart[alleleIndex].includes(hint) ? 'currentColor' : 'transparent',
                                    }),attrs:{"type":"radio","name":(hint + "-" + genotypePartIndex),"disabled":genotypePart[alleleIndex].includes(_vm.NEW_ALLELE_PLACEHOLDER)},domProps:{"checked":genotypePart[alleleIndex].includes(hint)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAlleleInNewGenotype(hint, genotypePartIndex, alleleIndex)}}})])}),0)]})],2)])}),0),_c('button',{staticClass:"add-allele-btn",on:{"click":function($event){return _vm.openModal('addExistingAllele')}}},[_c('p-icon',{attrs:{"icon-name":"plus","size":"1.5rem"}})],1)]):_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"2rem"}},[_c('p',[_vm._v(_vm._s(_vm.$t("uploadData")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }