<template>
    <div style="padding: 1rem;"
         :style="{ backgroundColor: !filteredGenotypes.length ? '#dddddd' : 'white' }">
        <h4 class="mb-1"><span class="mr-1">HLA-HD</span>
            <span class="badge"
                  v-if="results.contamination !== false"
                  :class="{ 'red': results.contamination === true,
                            'yellow': results.contamination === undefined
                          }">{{ $t("contamination") }}</span>
        </h4>
        <template v-if="filteredGenotypes.length">
            <!-- eslint-disable-next-line-->
            <div v-for="genotype in filteredGenotypes"
                 class="mv-1"
                 style="display: grid; grid-template-columns: 45% 10% 45%; justify-items: center">
                <allele-component
                    :highlight="targetAllele"
                    :allele="alleleFromString(genotype.alleles[0].allele)"
                />
                <span style="color: rgb(189, 189, 189);">+</span>
                <allele-component
                    :highlight="targetAllele"
                    :allele="alleleFromString(genotype.alleles[1].allele)"
                />
            </div>
        </template>
        <div v-else>
            {{ $t("selectedAlleleNotFound") }}
        </div>
    </div>
</template>

<script>
    import { Allele, alleleIncludes } from "@/genotype"

    import AlleleComponent from "./allele"

    export default {
        name: "HlaHdResults",
        components: {
            AlleleComponent
        },
        computed: {
            filteredGenotypes() {
                return this.targetAllele
                    ? this.results.genotypes.filter(
                        it => it.alleles.some(allele => alleleIncludes(allele.allele, this.targetAllele))
                    )
                    : this.results.genotypes
            }
        },
        methods: {
            alleleFromString: Allele.fromGLString
        },
        props: {
            results: Object,
            targetAllele: String
        }
    }
</script>

<style scoped>

</style>
