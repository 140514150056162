<template>
    <div class="content" id="lot-step">
        <error-message v-if="maybeFirstError" @close="removeFirstError">
            <sample-validation-error v-if="maybeFirstError.type === 'samplesError'" :error="maybeFirstError"/>
            <file-validation-error v-else-if="maybeFirstError.type === 'filesError'" :error="maybeFirstError"/>
            <template v-else-if="maybeFirstError.type === 'duplicatedSamples'">
                <h3 class="error__header">{{ $t("error") }}</h3>
                <div>{{ $t("duplicatedSamples", [error.details.duplicatedSamples]) }}</div>
            </template>
        </error-message>
        <div :key="lot.name" class="lot" style="position: relative"
             v-for="lot in lots">
            <div class="lot-name">{{ $t("lot") }} {{ lot.name }}</div>
            <p-file-dropzone :on-files-drop="(files) => onFilesDrop(files, lot)"
                            class="dropzone raw-data-dropzone"
                            v-if="!lot.samples.length">
                <template>
                    <div class="dropzone-description">
                        <div>
                            <p-icon class="file-icon"
                                    icon-name="file-empty"
                                    size="2em"
                            />
                            <div>{{ $t("dropzonePlaceholder1") }}
                                <label class="p-dropzone-hidden-file-input-label">{{ $t("dropzonePlaceholder2") }}
                                    <input @change="($event) => onFilesDrop(fileListToArray($event.target.files), lot)"
                                           class="d-none"
                                           multiple
                                           type="file"
                                    >
                                </label>
                                {{ $t("dropzonePlaceholder3") }}
                            </div>
                        </div>
                    </div>
                </template>
            </p-file-dropzone>
            <template v-else>
                <div class="lot-details">
                    <div style="margin: 2rem 0">
                        <span class="badge green">{{ lot.samples.length }}</span> {{ $tc("lotSamplesCount",
                        lot.samples.length) }}:
                        <!-- eslint-disable-next-line vue/require-v-for-key -->
                        <span class="badge badge-margin bg--royal-blue text-white"
                              v-for="group in collapseRunIds(lot.samples.map(it => it.runId))">{{ group }}</span>
                    </div>

                    <p-select-with-search
                            :options="controlSampleSelectOptions(lot)"
                            @input="(controlSample) => setControlSampleForLot(lot.name, controlSample)"
                            :value="lot.controlSample"
                            :placeholder="$t('controlSample')"
                            @update:search-query="(v) => updateSearchQuery(v, lot.name)"
                            class="select md-outlined"
                            :get-key="option => option.runId"
                    >
                        <template v-slot:default="slotProps">
                            <span class="mr-1">{{ slotProps.option.name }}</span>
                            <span style="color: var(--grey-400)">{{ slotProps.option.runId }}</span>
                        </template>
                    </p-select-with-search>
                </div>

                <div class="lot-actions">
                    <button @click="openLotSamplesModal(lot)"
                            class="md-btn-text see-btn">
                        {{ $t("seeSamples") }}
                    </button>
                    <button @click="removeAllSamplesFromLot(lot.name)"
                            class="md-btn-text remove-btn">
                        {{ $t("removeSamples") }}
                    </button>
                </div>
            </template>
        </div>
        <p-modal v-if="modals['lotSamples'].opened">
            <div class="lot-modal">
                <h2 style="text-align: center;">{{ $t("lotSamplesModalTitle", { name: lotShown.name }) }}</h2>
                <LotSamples
                        :lot="lotShown"
                        style="font-size: 1.6rem"/>
                <button @click="closeLotSamplesModal"
                        class="md-btn-contained close-btn royal-blue">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
    </div>
</template>

<script>
    import { SampleForUpload, validateSample } from "@/store/modules/sample"
    import LotSamples from "@/views/data-upload/components/lot-samples"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { Locales } from "@/i18n/main"
    import ErrorMessage from "@/components/error-message"
    import SampleValidationError from "@/components/errors/sample-validation-error"
    import FileValidationError from "@/components/errors/file-validation-error"
    import { errorMixin } from "@/mixins/error-mixin"

    const LOT_SAMPLES_MODAL = "lotSamples"

    export default {
        name: "LotStep",
        mixins: [ modalMixin, errorMixin ],
        components: {
            FileValidationError,
            SampleValidationError,
            ErrorMessage,
            LotSamples,
        },
        props: {
            platform: Object,
            lots: Array,
            addSamplesToLot: Function,
            removeAllSamplesFromLot: Function,
            setControlSampleForLot: Function
        },
        data() {
            return {
                modals: {
                    [LOT_SAMPLES_MODAL]: {
                        opened: false
                    }
                },
                lotShown: null,
                controlSampleSearchQueries: {}
            }
        },
        computed: {
            fileNameRegexp() {
                return new RegExp(this.platform.properties.fileNameMask)
            }
        },
        methods: {
            collapseRunIds,
            openLotSamplesModal(lot) {
                this.lotShown = lot
                this.openModal(LOT_SAMPLES_MODAL)
            },
            closeLotSamplesModal() {
                this.lotShown = null
                this.closeModal(LOT_SAMPLES_MODAL)
            },
            fileListToArray(files) {
                return [ ...files ]
            },
            onFilesDrop(files, lot) {
                const correctFiles = this.filterFiles(files)
                const samples = this.groupFilesToSamples(
                    correctFiles,
                    (fileName) => fileName.match(this.fileNameRegexp).slice(1, 3)
                )
                const { validSamples, samplesWithValidationErrors } = validateSamples(this.filterDuplicatedSamples(samples))
                if (samplesWithValidationErrors.length > 0) {
                    this.addError({
                        type: "samplesError",
                        details: { samplesWithErrors: samplesWithValidationErrors }
                    })
                }
                this.addSamplesToLot(lot.name, validSamples)
            },
            filterFiles(files) {
                let [ correctFiles, failedFiles ] = partitionFilesByRegexp(files, this.fileNameRegexp)
                if (failedFiles.length) {
                    this.addError({
                        type: "filesError",
                        details: {
                            filesWithErrors: failedFiles.map(file => ([ file, { type: "wrongFileName" }]))
                        }
                    })
                }
                return correctFiles
            },
            filterDuplicatedSamples(newSamples) {
                const existingSampleIdsWithLotNames = this.lots.reduce(
                    (sampleIds, lot) => new Map([ ...sampleIds.entries(), ...lot.samples.map(it => [ it.runId, lot.name ]) ]),
                    new Map()
                )
                const { duplicatedSamples, uniqueSamples } = newSamples.reduce(
                    (duplicates, sample) => existingSampleIdsWithLotNames.has(sample.runId)
                        ? ({
                            ...duplicates,
                            duplicatedSamples: [ ...duplicates.duplicatedSamples, `Sample ${sample.name}-${sample.runId} already exist in lot ${existingSampleIdsWithLotNames.get(sample.runId)}` ]
                        })
                        : ({
                            ...duplicates,
                            uniqueSamples: [ ...duplicates.uniqueSamples, sample ]
                        }),
                    {
                        duplicatedSamples: [],
                        uniqueSamples: []
                    }
                )
                if (duplicatedSamples.length) {
                    this.addError({
                        type: "duplicatedSamples",
                        details: {
                            // TODO: Translate error message
                            duplicated: duplicatedSamples.join("\n")
                        }
                    })
                }
                return uniqueSamples
            },
            /**
             * Groups files to samples based on sample run id extracted from file name using fileNameRegexp
             *
             * @param {Array<File> } files list of files to group
             * @param extractSampleRunIdAndName function that returns array of sample name and sample run id given file name
             * @returns {Array} Array with samples
             */
            groupFilesToSamples(files, extractSampleRunIdAndName) {
                const groupedFiles = files.reduce(
                    (_groupedFiles, file) => {
                        const [ , sampleRunId ] = extractSampleRunIdAndName(file.name)
                        _groupedFiles.set(
                            sampleRunId,
                            _groupedFiles.has(sampleRunId)
                                ? [ ..._groupedFiles.get(sampleRunId), file ]
                                : [ file ]
                        )
                        return _groupedFiles
                    },
                    new Map()
                )
                return [ ...groupedFiles.values() ].map(_files => {
                    const [ sampleName, sampleRunId ] = extractSampleRunIdAndName(_files[0].name)
                    return new SampleForUpload(sampleName, sampleRunId, _files)
                })
            },
            controlSampleSelectOptions(lot) {
                return this.controlSampleSearchQueries[lot.name]
                    ? lot.samplesOrderedByRunId.filter(it => it.name.toLowerCase().includes(this.controlSampleSearchQueries[lot.name].toLowerCase()))
                    : lot.samplesOrderedByRunId
            },
            updateSearchQuery(v, lotName) {
                this.controlSampleSearchQueries = { ...this.controlSampleSearchQueries, [lotName]: v }
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    removeSamples: "Remove samples",
                    seeSamples: "See samples",
                    lotSamplesModalTitle: "Lot {name} samples",
                    dropzonePlaceholder1: "Drop or ",
                    dropzonePlaceholder2: "select",
                    dropzonePlaceholder3: "sequencing data",
                    error: "Errors when adding a file",
                    duplicatedSamples: "Duplicated samples - {0}",
                },
                [Locales.RU]: {
                    removeSamples: "Удалить образцы",
                    seeSamples: "Посмотреть образцы",
                    lotSamplesModalTitle: "Образцы серии {name}",
                    dropzonePlaceholder1: "Перетащите или ",
                    dropzonePlaceholder2: "выберите файлы",
                    dropzonePlaceholder3: "с данными секвенирования",
                    error: "Ошибки при добавлении файла",
                    duplicatedSamples: "Дублированные образцы - {0}"
                }
            }
        }
    }

    export function collapseRunIds(runIds) {
        runIds.sort((id, otherId) => id.slice(1) - otherId.slice(1))
        return runIds.slice(1)
            .reduce(
                (prev, cur) => {
                    const lastGroup = prev[prev.length - 1]
                    if (isNeighbourIds(lastGroup[lastGroup.length - 1], cur)) {
                        lastGroup.push(cur)
                    } else {
                        prev.push([ cur ])
                    }
                    return prev
                },
                [ [ runIds[0] ] ]
            ).reduce(
                (prev, idsGroup) => {
                    prev.push(
                        (idsGroup.length > 1)
                            ? `${idsGroup[0]} - ${idsGroup[idsGroup.length - 1]}`
                            : idsGroup[0]
                    )
                    return prev
                },
                []
            )
    }

    function isNeighbourIds(id, otherId) {
        return Math.abs(Number.parseInt(id.slice(1)) - Number.parseInt(otherId.slice(1))) === 1
    }

    function partitionBy(predicate, entities) {
        const [ correctEntities, failedEntities ] = entities.reduce(
            (acc, entity) => predicate(entity)
                ? [ [ ...acc[0], entity ], acc[1] ]
                : [ acc[0], [ ...acc[1], entity ] ]
            ,
            [ [], [] ]
        )
        return [ correctEntities, failedEntities ]
    }

    function partitionFilesByRegexp(files, regexp) {
        return partitionBy((file) => regexp.test(file.name), files)
    }

    function validateSamples(samples) {
        return samples.reduce(
            (acc, sample) => {
                const validationErrors = validateSample(sample)
                return validationErrors.length === 0
                    ? { ...acc, validSamples: [ ...acc.validSamples, sample ] }
                    : {
                        ...acc,
                        samplesWithValidationErrors: [ ...acc.samplesWithValidationErrors, [ sample, validationErrors ] ]
                    }
            }
            ,
            { validSamples: [], samplesWithValidationErrors: [] }
        )
    }
</script>

<style scoped>
    .dropzone-description {
        width: 100%;
        text-align: center;
        font-size: 1.7rem;
    }

    .raw-data-dropzone {
        position: relative;
        width: 100%;
        flex-grow: 1;
    }

    .content {
        margin: 3em;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .lot {
        position: relative;
        width: 30em;
        min-height: 25em;
        padding: 2.5em 2em;
        display: flex;
        flex-flow: column;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
        margin: 2rem;
    }

    .lot-name {
        font-size: 2.5rem;
        padding: 0 1.5rem;
        margin-bottom: 2rem;
        margin-top: -.5rem;
        background-color: white;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .lot-details {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0 2rem;
        flex-flow: column;
        font-size: 1.7rem;
    }

    .lot-actions {
        align-self: flex-end;
        padding: .3em .5em;
        margin-bottom: -2rem;
        margin-right: -1rem;
        display: flex;
        font-size: 1.6rem;
        align-items: center;
    }

    .see-btn,
    .remove-btn {
        font-size: 1.4rem;
    }

    .see-btn {
        color: forestgreen;
        transition: background-color .2s;
    }

    .see-btn:focus,
    .see-btn:hover {
        background-color: hsl(120, 61%, 95%);
    }

    .remove-btn {
        color: var(--red);
        transition: background-color .2s;
    }

    .remove-btn:focus,
    .remove-btn:hover {
        background-color: hsl(0, 65%, 95%)
    }

    .lot-modal {
        position: relative;
        height: 80vh;
        width: 70vw;
        padding: 2rem;
        display: grid;
        grid-template-rows: min-content minmax(80%, 1fr) min-content;
        grid-row-gap: 2rem;
    }

    .close-btn {
        justify-self: right;
    }

    .select {
        width: 30rem;
        margin: 2rem 0;
    }
</style>
