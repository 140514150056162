<template>
    <div id="docs">
        <div class="docs-section">
            <h2 class="h3 mb-2">{{ $t("appDocs") }}</h2>
            <div class="flex">
                <p-icon icon-name="menu-book" size="10rem" style="flex-shrink: 0"/>
                <ul style="margin-left: 3rem; font-size: 1.4rem">
                    <li class="m-1">
                        <a href="/docs/"
                           target="_blank"
                           rel="noreferrer noopener"
                        >{{ $t("baseDocs") }}</a>
                    </li>
                    <li class="m-1">
                        <a href="/docs/#/CHANGELOG"
                           target="_blank"
                           rel="noreferrer noopener"
                        >{{ $t("changelog") }}
                        </a>
                    </li>
                    <li class="m-1" style="color: var(--grey-400)">
                        <a
                            href="https://support.parseq.pro/"
                            target="_blank" ref="noreferrer noopener"
                        >{{ $t("contactSupport") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="docs-section overflow-auto">
            <h2 class="h3 mb-2">{{ $t("pipelineDocs") }}</h2>
            <ul class="overflow-auto custom-scroll">
                <li :key="p.name" v-for="p in pipelines" class="li">
                    <a href="#" @click="viewPipelineDocs(p.name)">{{ p.name }}</a>
                    <span class="badge yellow ml-1">{{ p.imgtVersion }}</span>
                </li>
            </ul>
        </div>
        <div class="lots-section relative">
            <h2 class="h3 mb-2">{{ $t("lotList") }}</h2>
            <p-input class="md-outlined ml-1 mb-2"
                     :placeholder="$tc('lot', 0)"
                     style="width: 30rem; margin: var(--margin);"
                     v-model="lotFilter.nameStartsWith"/>
            <ul class="flex overflow-auto custom-scroll"
                ref="lots"
                style="flex-flow: column"
                v-if="lots.length">
                <li :key="lot.name"
                    class="card outlined"
                    v-for="lot in filteredLots">
                    <h4 class="h4 mb-2" style="text-align: center">{{ lot.name }}</h4>
                    <div class="mb-2">
                        <a href="#"
                           @click="viewPipelineDocs(lot.configuredPipeline.name)"
                        >{{ lot.configuredPipeline.name }}</a>
                        <span class="badge badge-margin yellow ml-1"
                        >{{ lot.configuredPipeline.imgtVersion }}</span>
                    </div>
                </li>
            </ul>
            <p-spinner v-else-if="fetchInProgress" class="abs-center"></p-spinner>
            <div class="ml-1 fallback-text" v-else>{{ $t("noLotsFound") }}</div>
        </div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import {
        documentedReagentKitLotsActionNames,
        documentedReagentKitLotsGetterNames,
    } from "@/store/modules/documented-reagent-kit-lots"
    import { mapGetters } from "vuex"
    import { debounce } from "@/main"
    import { fetchDocumentationLinks, fetchPipelineDocsLink, fetchPipelines } from "@/endpoints"
    import { viewPdfInNewTab } from "@/helpers"


    export default {
        name: "Docs",
        data() {
            return {
                pipelines: [],
                lotFilter: {
                    nameStartsWith: null
                },
                documentationLinks: []
            }
        },
        watch: {
            lotFilter: {
                handler() {
                    this.debouncedFetchLotsMatchingFilter()
                },
                deep: true
            }
        },
        computed: {
            filteredLots() {
                return this.lots.filter(lot => !isDevPipeline(lot.configuredPipeline))
            },
            ...mapGetters({
                lots: documentedReagentKitLotsGetterNames.resourceList,
                lotsNumber: documentedReagentKitLotsGetterNames.resourceNumber,
                fetchInProgress: documentedReagentKitLotsGetterNames.fetchInProgress
            }),
        },
        methods: {
            async viewPipelineDocs(name) {
                viewPdfInNewTab(await fetchPipelineDocsLink(name))
            },
            fetchLotsMatchingFilter() {
                const resourceListScrollableContainer = this.$refs["lots"]

                return this.$store
                    .dispatch(documentedReagentKitLotsActionNames.startNewSearch, { filter: this.lotFilter })
                    .then(() => {
                        // eslint-disable-next-line promise/always-return
                        if (resourceListScrollableContainer) {
                            resourceListScrollableContainer && resourceListScrollableContainer.scrollTo(0, 0)
                        }
                    })
                    .then(() => this.fetchLotsTillScrollAppearsOrTheyEnd())
                    .catch(error => console.log(error))
                    .then(() => this.lots)
            },
            async fetchLotsTillScrollAppearsOrTheyEnd() {
                const resourceListScrollableContainer = this.$refs["lots"]
                let scrollIsMissing = () => resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight === 0
                while (scrollIsMissing() && this.lots.length !== this.lotsNumber) {
                    await this.$store.dispatch(documentedReagentKitLotsActionNames.continueSearch)
                }
            },
        },
        created() {
            fetchPipelines()
                .then(pipelines => this.pipelines = pipelines.filter(pipeline => !isDevPipeline(pipeline)))

            this.fetchLotsMatchingFilter()
                // TODO: [@aslepchenkov 30.10.2020] Maybe we can add another endpoint using authentication , not lot name
                .then(lots => fetchDocumentationLinks(lots[0].name))
                // eslint-disable-next-line promise/always-return
                .then(links => {
                    this.documentationLinks = links
                })

            this.debouncedFetchLotsMatchingFilter = debounce(this.fetchLotsMatchingFilter, 300)
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    changelog: "Changelog",
                    contactSupport: "Support contacts",
                    baseDocs: "Base documentation",
                    interactiveDocs: "To turn on interactive docs press <code>Ctrl + H</code> inside any application",
                    appDocs: "Application docs",
                    pipelineDocs: "Pipeline docs",
                    labManuals: "Lab manuals",
                    comingSoon: "Coming soon",
                    noLotsFound: "No lots found",
                    lotList: "Lots",
                },
                [Locales.RU]: {
                    contactSupport: "Написать в поддержку",
                    changelog: "История изменений",
                    baseDocs: "Основная документация",
                    interactiveDocs: "Для доступа к интерактивной документации нажмите <code>Ctrl + H</code>, находясь в приложении",
                    appDocs: "Документация приложения",
                    pipelineDocs: "Документация алгоритмов",
                    labManuals: "Лабораторные протоколы",
                    comingSoon: "В разработке",
                    noLotsFound: "Не найдено ни одной серии",
                    lotList: "Серии реагентов",
                },
            }
        }
    }

    function isDevPipeline(pipeline) {
        return pipeline.name.includes("dev")
    }
</script>

<style scoped>
    #docs {
        padding: 4rem 5rem;
        display: grid;
        grid-column-gap: 6rem;
        grid-row-gap: 10rem;
        grid-template-rows: min-content 1fr;
        grid-template-columns: 2fr 1fr;
    }

    .docs-section {
        width: fit-content;
        height: 100%;
        padding: 1rem 2.5rem;
        display: flex;
        flex-flow: column;
    }

    .li {
        padding: 1rem 0;
    }

    .lots-section {
        grid-column: 2 / 3;
        grid-row: 1 / -1;
        justify-self: end;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        --margin: 2rem 3rem 2rem 1rem;
        padding: 1rem 0;
    }

    .card {
        min-height: 10rem;
        height: fit-content;
        min-width: 30rem;
        border-radius: 4px;
        padding: 1rem 2.5rem;
        margin: var(--margin);
    }

    .card.outlined {
        border: 1px solid var(--grey-400);
    }
</style>
