import { render, staticRenderFns } from "./choose-metadate-new-allele-step.vue?vue&type=template&id=0fef68c8&scoped=true&"
import script from "./choose-metadate-new-allele-step.vue?vue&type=script&lang=js&"
export * from "./choose-metadate-new-allele-step.vue?vue&type=script&lang=js&"
import style0 from "./choose-metadate-new-allele-step.vue?vue&type=style&index=0&id=0fef68c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fef68c8",
  null
  
)

export default component.exports