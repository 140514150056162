export const inputMixin = {
    props: {
        value: [ String, Number, Array, Object ],
        validationErrors: {
            type: [ String, Array ],
            default() {
                return []
            }
        }
    },
    data() {
        return {
            touched: false
        }
    },
    computed: {
        isInvalid() {
            return this.touched && this.validationErrors !== "pending" ? this.validationErrors.length > 0 : false
        }
    }
}
