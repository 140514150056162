<template>
    <p-dropdown
        class="md-container"
        full-width
        v-model="dropdownShown"
    >
        <template v-slot:activator="{ toggleDropdown }">
            <select-button
                :disabled="disabled"
                :invalid="isInvalid"
                :label="$attrs.placeholder"
                :value-is-empty="!value"
                :view="view"
                @toggle="toggleDropdown"
                @clear="clearSelection"
            >
                <slot :option="value" v-if="value">
                    {{ view ? view(value) : value }}
                </slot>
            </select-button>
            <p-input-errors v-if="touched" :validationErrors="validationErrors"/>
        </template>
        <div class="dropdown--white">
            <ul class="select__options custom-scroll">
                <!-- We don't update this option list, why I need keys -->
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <li @click="selectOption(option)"
                    class="select__option"
                    v-for="option in options"
                >
                    <slot :option="option">
                        {{ view(option) }}
                    </slot>
                </li>
            </ul>
        </div>
    </p-dropdown>
</template>

<script>
    import { selectMixin } from "@/mixins/select-mixin"
    import SelectButton from "@/base-components/selects/select-button"

    export default {
        name: "PSelect",
        components: { SelectButton },
        mixins: [ selectMixin ],
        methods: {
            selectOption(option) {
                this.$emit("input", option)
                this.closeDropdown()
            },
            clearSelection() {
                this.$emit("input", null)
            },
        }
    }
</script>
