<template>
    <div :class="controlSampleStatus" class="badge">{{ lotName }}</div>
</template>

<script>
    export default {
        name: "RunLotBadge",
        props: {
            controlSampleStatus: {
                type: String,
                required: true
            },
            lotName: {
                type: String,
                required: true
            },
        }
    }
</script>

<style scoped>
    .badge.PASSED {
        border: 2px solid var(--green);
        font-weight: 600;
        color: var(--green);
    }

    .badge.FAILED {
        border: 2px solid var(--red);
        font-weight: 600;
        color: var(--red);
    }

    .badge.UNDEFINED {
        border: 2px solid var(--grey-400);
        font-weight: 600;
        color: var(--grey-400);
    }
</style>
