<template>
    <div class="analysis-container">
        <p-modal v-if="modals.summary.opened">
            <div class="modal">
                <div class="overflow-auto custom-scroll">
                    <div class="flex mb-2" style="font-size: 2.4rem; justify-content: space-between;">
                        <div>{{ analysis.sequencingRunId }}</div>
                        <div class="flex">
                            <div class="mr-1">{{ analysis.name }}</div>
                            <div style="color: var(--grey-400);">S{{ analysis.runId }}</div>
                        </div>
                    </div>
                    <genotypes-comparison-table :new-genotypes="savedGenotypes"
                                                :annotations="mergedAnnotations || {}"
                                                :hasReAnalysisGenotypes="true"
                                                :analysis="analysis"
                                                :old-genotypes="oldGenotypes"
                    />
                </div>
                <div class="flex" style="margin-top: 3rem">
                    <button class="md-btn-text"
                            @click="cancelGenotypesAssign"
                    >{{ $t("buttons.cancel") }}
                    </button>
                    <button class="md-btn-contained green ml-auto"
                            @click="finishGenotypesAssign"
                    >{{ $t("buttons.approve") }}
                    </button>
                </div>
            </div>
        </p-modal>
        <header class="flex" style="justify-content: space-between">
            <div class="flex btn-group">
                <button
                    class="btn"
                    @click="activeComponent='genotypes'"
                    :class="{ active: activeComponent === 'genotypes' }"
                >{{ $t("genotypes") }}
                </button>
                <button
                    class="btn"
                    @click="activeComponent='charts'"
                    :class="{ active: activeComponent === 'charts' }"
                >{{ $t("charts") }}
                </button>
                <button
                    class="btn"
                    @click="activeComponent='metrics'"
                    :class="{ active: activeComponent === 'metrics' }"
                >{{ $t("labQc") }}
                </button>
            </div>

            <div class="flex" style="font-size: 2.4rem">
                {{ analysis.name }}
                <span class="ml-1" style="color: var(--grey-400)">S{{ analysis.runId }}</span>
                <analysis-lot-badge
                    style="font-size: .6em"
                    class="ml-1"
                    :control-sample-status="analysis.reagentKitLotQcStatus"
                    :lot-name="analysis.reagentKitLotName"
                    :configured-pipeline-name="analysis.configuredPipelineName"
                    :imgt-version="analysis.configuredPipelineImgtVersion"
                />

                <p-dropdown
                    class="ml-1"
                    align="right-top"
                    i-docs="sample-card-context-menu"
                    style="margin-top: 0.5rem; font-size: 1.4rem"
                    fixed
                >
                    <template v-slot:activator="{ toggleDropdown }">
                        <p-icon-button
                            class="icon-btn--contrast icon-btn--round"
                            icon-name="more-vertical"
                            size="2em"
                            @click="toggleDropdown"
                        />
                    </template>
                    <div class="dropdown--white">
                        <ul>
                            <li>
                                <div class="flex sample-card--btn md-btn-text">
                                    <a :href="`api/support/sequencingRuns/${analysis.sequencingRunUuid}/analyses/${analysis.id}/configuredPipelineResultResourcesArchive`"
                                        target="_blank"
                                        style="all: unset;">
                                        {{ $t("configuredPipelineResultResourcesArchive") }}
                                    </a>
                                    <p-icon style="margin-left: 0.5rem" iconName="save-alt"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </p-dropdown>
            </div>

            <p-with-tooltip fixed class="flex">
                <template>
                <button
                    class="reload_btn"
                    :disabled="canRestart || canReAnalysisRunning"
                    :style="{'background-color': getColorButtonReanalysis}"
                    @click="restartPipeline(analysis)">
                        <div v-if="canReAnalysisRunning" class="loader"></div>
                        <p-icon
                            v-else-if="canRestart"
                            icon-name="block"
                            color="white"
                            size="2.5em"
                        />
                        <p-icon
                            v-else
                            icon-name="autoplay"
                            color="white"
                            size="2.5em"
                        />
                </button>
                </template>

                <template
                    #tooltip
                    v-if="canRestart || canReAnalysisRunning"
                >
                    <div class="tooltip-black">
                        <template v-if="canRestart">
                            <p>{{ $t("pipelineErrorsHeader") }}</p>
                            <p v-if="pipelineErrorHandling().length">{{ $t("wrongLoci") }}: {{ pipelineErrorHandling() }}</p>
                            <p v-if="hasSavedWrongGenotypes()">{{ $t("notSavedLoci") }}</p>
                        </template>

                        <template v-else-if="canReAnalysisRunning">
                            <p>{{ $t("reanalysisLaunched") }}</p>
                        </template>
                    </div>
                </template>
            </p-with-tooltip>

            <p-btn-dropdown
                class="md-contained green"
                :disabled="canSavedGenotype"
                @click="startGenotypesAssign"
                style="margin-left: .3rem; margin-right: -1rem"
            >
                {{ $t("buttons.assignGenotypes") }}
                <template #secondary-btns>
                    <button
                        class="md-btn-text red w-100"
                        @click="markAnalysisAsContaminated"
                    >{{ $t("contaminated") }}
                    </button>
                </template>
            </p-btn-dropdown>
        </header>
        <div v-if="activeComponent === 'genotypes'"
             class="genotypes-view">
            <template v-if="toolResults && Object.keys(toolResults).length">
                <div class="loci-tabs flex">
                    <div
                        :class="{ active: activeLocus === locus}"
                        :key="locus"
                        @click="changeActiveLocusTo(locus)"
                        class="loci-tabs--tab-header"
                        v-for="locus in LOCI"
                    >
                        <div class="circle" style="font-size: .6em;"
                             :class="locusQualityForSupport(analysis, locus)"></div>
                        <div style="font-weight: 600; margin-left: .5rem; margin-right: .5rem;">{{ locus }}
                        </div>
                        <div v-if="hasReAnalysisState" class="circle mr-1" style="font-size: .6em;"
                             :class="locusQualityReAnalysisForSupport(analysis, locus)"></div>
                        <div class="badge yellow"
                             v-if="hasChangedGenotype(analysis, locus)"
                        >{{ $t("changed") }}
                        </div>
                        <div class="badge green"
                             v-else-if="hasSavedGenotype(analysis, locus)"
                        >{{ $t("saved") }}
                        </div>
                    </div>
                </div>
                <locus-tab
                    :active-locus="activeLocus"
                    :analysis="analysis"
                    :tool-results="toolResults"
                    :known-ambiguities="knownAmbiguities"
                    :annotations="mergedAnnotations"
                />
            </template>
            <p-spinner
                v-else
                class="abs-center h4"
            />
        </div>
        <charts
            v-else-if="activeComponent === 'charts'"
            class="w-100"
            :key="analysis.name"
            :analysis="analysis"
            :locus="activeLocus"
        />
        <Run v-else-if="activeComponent === 'metrics'" :hasSupportRunAnalyses="true"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import AnalysisLotBadge from "@/components/analysis/lot-badge"
    import { getGenotype, getSavedGenotype, hasSavedGenotype, parseRawGenotypes, hasSavedNewAllele, getSavedNewAllele } from "@/utils/analysis"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { supportActionNames, supportGetterNames } from "@/store/modules/support"
    import { Locales } from "@/i18n/main"
    import { genotypesEqual, LOCI, newAllelesEqual } from "@/genotype"
    import GenotypesComparisonTable from "@/components/analysis/genotypes-comparison-table"
    import { configuredPipelinesActionNames } from "@/store/modules/configured-pipelines"
    import {
        locusQualityForSupport,
        mergeAnnotations,
        locusQualityReAnalysisForSupport,
    } from "@/utils/support"
    import { mapValues } from "@/extensions/object-extensions"
    import Run from "@/views/run-labqc/run.vue"
    import { supportEndpoints } from "@/endpoints"

    import LocusTab from "./analysis-parts/locus-tab"
    import Charts from "./analysis-parts/charts"


    export default {
        name: "AnalysisSupport",
        mixins: [ modalMixin ],
        components: {
            Run,
            LocusTab,
            GenotypesComparisonTable,
            Charts,
            AnalysisLotBadge
        },
        props: {
            analysis: Object,
        },
        /*
        Vue tricks, this component is not recreated on props change. So I have to fetch data
        both in created and updated hooks. Fuck this, just use watcher with immediate.
        */
        watch: {
            "analysis.configuredPipelineName": {
                handler() {
                    this.$store.dispatch(
                        configuredPipelinesActionNames.fetchPipelineData,
                        this.analysis.configuredPipelineName
                    ).then(({ knownAmbiguities }) => this.knownAmbiguities = knownAmbiguities)
                },
                immediate: true
            }
        },
        data() {
            return {
                activeComponent: "genotypes",
                activeLocus: "A",
                pipelineRestartErrors: {},
                modals: {
                    summary: {
                        opened: false
                    },
                },
                LOCI,
                knownAmbiguities: null,
            }
        },
        computed: {
            oldGenotypes() {
                return parseRawGenotypes(this.analysis.result.genotypes)
            },
            tempGenotypes() {
                return this.$store.state.support.tempGenotypes[this.analysis.id]
            },
            tempNewAlleles() {
                return this.$store.state.support.tempNewAllele[this.analysis.id]
            },
            savedGenotypes() {
                return mapValues(
                    this.tempGenotypes,
                    (genotype, locus) => hasSavedGenotype(this.analysis, locus) ? genotype : null
                )
            },
            toolResults() {
                return this.$store.state.support.toolResults[this.analysis.id]
            },
            canRestart() {
                const hasWrongLocus = this.getWrongLocus()
                const hasUnsavedLocus = this.hasSavedWrongGenotypes()

                return hasWrongLocus || hasUnsavedLocus
            },
            canReAnalysisRunning() {
                return this.analysis?.maybeReAnalysisState?.status === "RUNNING"
            },
            canSavedGenotype() {
                const redQualityLocus = LOCI.map((it) => this.locusQualityForSupport(this.analysis, it))
                const changedLocus = LOCI.map(it => this.hasChangedGenotype(this.analysis, it)).filter(it => it)
                const savedLocus = LOCI.map(it => this.hasSavedGenotype(this.analysis, it))
                const notSavedBadLoci = []

                //Checking that everything is saved
                redQualityLocus.forEach((item, index) => {
                    if(item === "red" && !savedLocus[index]) {
                        notSavedBadLoci.push(item)
                    }
                })

                if(this.analysis?.maybeReAnalysisState?.status === "RUNNING") {
                    return true
                }

                return Boolean(notSavedBadLoci.length) || Boolean(changedLocus.length)
            },
            mergedAnnotations() {
                return mergeAnnotations(
                    this.analysis.annotations ?? {},
                    this.analysis.maybeResolutionDraft?.annotations ?? {}
                )
            },
            hasReAnalysisState() {
                return this.analysis?.maybeReAnalysisState?.maybeResult
            },
            getColorButtonReanalysis() {
                if(this.analysis?.maybeReAnalysisState?.status === "COMPLETE_FAILURE") {
                    return "var(--red)"
                } else if(this.canReAnalysisRunning) {
                    return "var(--grey-400)"
                } else {
                    return "var(--green)"
                }
            },
            ...mapGetters({
                run: supportGetterNames.activeRun,
            }),
        },
        methods: {
            changeActiveLocusTo(locus) {
                this.activeLocus = locus
                this.alleleFilter = null
                this.selectedAllele = null
            },
            hasChangedGenotype(analysis, locus) {
                return !(
                    hasSavedGenotype(analysis, locus)
                        ? genotypesEqual(getSavedGenotype(analysis, locus), this.tempGenotypes[locus])
                        : genotypesEqual(getGenotype(analysis, locus), this.tempGenotypes[locus])
                ) || !(hasSavedNewAllele(analysis, locus)
                    ? newAllelesEqual(getSavedNewAllele(analysis, locus), this.tempNewAlleles, locus)
                    : true)
            },
            startGenotypesAssign() {
                this.openModal("summary")
            },
            cancelGenotypesAssign() {
                this.closeModal("summary")
            },
            finishGenotypesAssign() {
                // All this terminology with assign is a little bit messy, maybe I will come up with better solution
                this.$store.dispatch(supportActionNames.markAnalysisAsAssigned, this.analysis)
                    .then(() => this.closeModal("summary"))
            },
            markAnalysisAsContaminated() {
                this.$store.dispatch(supportActionNames.markAnalysisAsContaminated, this.analysis)
            },
            getWrongLocus() {
                let hasWrongLocus = false
                const currentGenotypes = this.$store.state.support.tempGenotypes[this.analysis.id]

                LOCI.forEach(locus => {
                    this.pipelineRestartErrors[locus] = false
                })

                for (const key in currentGenotypes) {
                    currentGenotypes[key]["genotypeParts"].forEach(item => {
                        if(!item[0]["parts"].length && !item[1]["parts"].length) {
                            this.pipelineRestartErrors[key] = true
                            hasWrongLocus = true
                        }

                        if(item[0]["parts"].includes("NEW") || item[1]["parts"].includes("NEW")) {
                            this.pipelineRestartErrors[key] = true
                            hasWrongLocus = true
                        }
                    })
                }

                return hasWrongLocus
            },
            hasSavedWrongGenotypes() {
                const redQualityLocus = LOCI.map((it) => this.locusQualityForSupport(this.analysis, it))
                const changedLocus = LOCI.map(it => this.hasChangedGenotype(this.analysis, it))
                const savedLocus = LOCI.map(it => this.hasSavedGenotype(this.analysis, it))
                const notSavedBadLoci = []
                const changedBadLoci = []

                //Checking that everything is saved
                redQualityLocus.forEach((item, index) => {
                    if(item === "red" && !savedLocus[index]) {
                        notSavedBadLoci.push(item)
                    }

                    if((item === "red" || item === "yellow" || item === "green") && changedLocus[index]) {
                        changedBadLoci.push(item)
                    }
                })

                return Boolean(notSavedBadLoci.length) || Boolean(changedBadLoci.length)
            },
            pipelineErrorHandling() {
                const errorText = []

                for (const locus in this.pipelineRestartErrors) {
                    if(this.pipelineRestartErrors[locus]) {
                        errorText.push(locus)
                    }
                }

                return errorText.join(",")
            },
            async restartPipeline(analysis) {
                await supportEndpoints.restartPipeline(analysis.id)
                await this.$store.dispatch(supportActionNames.updateAnalysis, analysis)
            },
            hasSavedGenotype,
            locusQualityForSupport,
            locusQualityReAnalysisForSupport,
        },
        mounted() {
            this.activeLocus = LOCI.find(locus => locusQualityForSupport(this.analysis, locus) === "red") ?? "A"
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    genotypes: "Генотипы",
                    charts: "Графики",
                    metrics: "Метрики КК",
                    labQc: "Лаб. КК",
                    relatedAmbiguities: "{n} известная неоднозначность | {n} известные неоднозначности | {n} известных неоднозначностей",
                    contaminated: "Контаминация",
                    changed: "Изменен",
                    saved: "Сохранен",
                    configuredPipelineResultResourcesArchive: "Архив с результатами анализа",
                    pipelineErrorsHeader: "Ошибки перезапуска пайплайна:",
                    wrongLoci: "Некорректные локусы",
                    notSavedLoci: "Не сохранены проблемные или изменённые локусы",
                    reanalysisLaunched: "Запущен реанализ",
                },
                [Locales.EN]: {
                    genotypes: "Genotypes",
                    charts: "charts",
                    metrics: "QC metrics",
                    labQc: "Lab QC",
                    relatedAmbiguities: "{n} related ambiguity | {n} related ambiguities",
                    contaminated: "Contaminated",
                    changed: "Changed",
                    saved: "Saved",
                    configuredPipelineResultResourcesArchive: "Archive with analysis results",
                    pipelineErrorsHeader: "Pipeline restart errors:",
                    wrongLoci: "Incorrect loci",
                    notSavedLoci: "Problem or changed loci not saved",
                    reanalysisLaunched: "Reanalysis launched",
                }
            }
        }
    }
</script>

<style scoped>

.loci-tabs {
    display: flex;
    justify-content: space-around;
}

.loci-tabs--tab-header {
    padding: .5rem 1.5rem .3rem;
    border-bottom: 2px solid var(--grey-100);
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loci-tabs--tab-header:hover {
    background-color: hsl(225, 73%, 97%);
    /*border-color: royalblue;*/
}

.loci-tabs--tab-header.active {
    color: royalblue;
    border-bottom-style: solid;
    border-color: royalblue;
    border-bottom-width: 2px;
}

.genotypes-view {
    display: grid;
    grid-template-rows: min-content minmax(80%, 1fr);
    grid-row-gap: 2rem;
}

.circle {
    --size: 1em;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
}


.modal {
    padding: 2rem 3rem 1.5rem;
    min-width: 35rem;
    max-height: 80vh;
    max-width: 80vw;
    display: flex;
    flex-flow: column;
}

.btn-group {
    font-size: 1.2rem;
    border: 1px solid #3949AB;
    border-radius: 4px;
    overflow: hidden;
}

.btn-group .btn {
    border-radius: 0;
    padding: .5rem 1rem;
    height: 100%;
    text-transform: uppercase;
    color: #3949AB;
}

.btn-group .btn:not(:last-child) {
    border-right: 1px solid #3949AB;
}

.btn-group .btn.active {
    background-color: #4169e1;
    color: white;
}

.analysis-container {
    display: grid;
    grid-template-rows: min-content minmax(70%, 1fr);
    grid-row-gap: 2.6rem;
    padding: 1.5rem 2.5rem;
    overflow: auto;
}

.sample-card--btn {
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 600;
    color: royalblue;
    white-space: nowrap;
    padding: 1rem 1.5rem;
    width: 100%;
    cursor: pointer;
}

.sample-card--btn:hover {
    background-color: rgba(65, 105, 225, .15);
}

.reload_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    letter-spacing: inherit;
    text-transform: inherit;
    padding: 0 0.7em;
    min-height: 2.6em;
    border-radius: 4px;
}

.tooltip-black {
    width: 30rem;
    word-break: break-all;
    text-align: center;
}

.loader {
  width: 2.2em;
  height: 2.2em;
  border: .20em solid currentColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: loader-spin 1s linear infinite;
  color: white;
}

@keyframes loader-spin {
  to {
    transform: rotate(1turn);
  }
}
</style>
