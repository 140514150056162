<template>
    <div class="flex">
        <span v-if="locusNameVisable" style="flex: 1 1 0;">
            {{ locusName }}
        </span>
        <div style="flex: 3 1 0;">
            <span>
                <!-- eslint-disable-next-line -->
                <GenotypePart 
                    v-for="(genotypePart, index) in genotype.genotypeParts"
                      :genotype="genotypePart"
                      :locus-name="locusName"
                      :annotations="annotations"
                      :hide-ambiguities="hideAmbiguities"
                      :analysis="analysis"
                      :genotypePartIndex="index"
                >
                    <template v-if="index < genotype.genotypeParts.length - 1">
                        <span :key="genotypePart" style="margin-left: 1rem;">|</span>
                    </template>
                </GenotypePart>

            </span>

        </div>
    </div>
</template>

<script>
    import { Genotype, MISSING_ALLELE_PLACEHOLDER } from "@/genotype"

    import GenotypePart from "./genotype-part"


    export default {
        name: "Genotype",
        components: { GenotypePart },
        data() {
            return {
                firstAlleleEllipsis: false,
                secondAlleleEllipsis: false,
                MISSING_ALLELE_PLACEHOLDER
            }
        },
        props: {
            locusName: String,
            genotype: Genotype,
            annotations: Object,
            hideAmbiguities: Boolean,
            analysis: Object,
            locusNameVisable: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<style scoped>
</style>
