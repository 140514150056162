import { SequencingPlatform } from "@/store/modules/sequencing-platform"
import { ActionTree, Dispatch, GetterTree, Module, MutationTree } from "vuex"
import { BatchUploadMessageBroker, IBatchUploadMessageBroker } from "@/batch-upload-message-broker"
import axios from "axios"
import { backgroundTasksActionNames, Task } from "@/store/modules/background-tasks"
import { throttle, zip } from "lodash"
import { SampleForUpload } from "@/store/modules/sample"
import { Lot } from "@/store/modules/lot"
import { websocket } from "@/endpoints"
import { i18n } from "@/i18n/main"


function createMessageBroker(
    onError?: (error: any) => any,
    onSpontaneousClose?: (closeEvent: CloseEvent) => any
) {
    // You can use FakeMessageBroker to test upload side effects
    return new BatchUploadMessageBroker(websocket.dataUpload, onError, onSpontaneousClose)
}

// Blueprint suffix used to sync terminology with backend. They include data needed for backend to create entities
export interface RunBlueprint {
    name: string
    date: number
    reagentKitLotUUID: string
    properties: object
}

export interface SampleBlueprint {
    name: string
    runId: number
    isControlSample: boolean
}


export interface RawDataUploadState {
    platforms: SequencingPlatform[]
}


function initialState() {
    return {
        platforms: [
            new SequencingPlatform(
                "MiSeq System",
                [
                    {
                        name: "MiSeq Reagent Kit v2",
                        properties: {
                            tileCount: "14"
                        }
                    },
                    {
                        name: "MiSeq Reagent Kit v2 Nano",
                        properties: {
                            tileCount: "2"
                        }
                    },
                    {
                        name: "MiSeq Reagent Kit v2 Micro",
                        properties: {
                            tileCount: "4"
                        }
                    },
                    {
                        name: "MiSeq Reagent Kit v3",
                        properties: {
                            tileCount: "19"
                        }
                    },
                ],
                {
                    fileNameMask: "^([-a-zA-Z0-9]{1,25})_(S[1-9][0-9]{0,9})_L001_R(?:1|2)_001\\.(?:fastq|fq)\\.gz$",
                    runIdMask: ".*-(\\w+)"
                }
            ),
        ]
    }
}

function composeFileUploadTask(
    dispatch: Dispatch,
    file: File,
    runUploadTaskId: string,
    sampleUploadTaskId: string,
    messageBroker: IBatchUploadMessageBroker,
    sampleId: string
) {
    const fileUploadTaskId = file.name
    dispatch(backgroundTasksActionNames.addTask, {
        path: [ runUploadTaskId, sampleUploadTaskId ],
        id: fileUploadTaskId,
        description: i18n.t("fileUploadTaskDescription", [ file.name ])
    })
    // Fixes problem with too frequent updates. Either vue or vuex causes page to die
    // (possibly: too many watchers on mutations)
    const throttledUpdateTask = throttle((progress: number) => dispatch(backgroundTasksActionNames.updateTask, {
        fullPath: [ runUploadTaskId, sampleUploadTaskId, fileUploadTaskId ],
        progress
    }), 2000)

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        throttledUpdateTask(percentCompleted)
        /*
         We decided not to track progress of upload to keep socket alive, maybe we are wrong and
         this will be restored when some nasty nginx will close our websocket due to it's emptiness
         messageBroker.sendSampleUploadProgressMessage(sample.properties.runId, file, percentCompleted)
        */
        console.debug(`Uploading file ${file.name} - ${percentCompleted}`)
    }
    return () => uploadFile(messageBroker, sampleId, file, onUploadProgress).then(() => throttledUpdateTask.flush())
}

function composeSampleUploadTask(
    dispatch: Dispatch,
    messageBroker: IBatchUploadMessageBroker,
    runUploadTaskId: string,
    sample: SampleForUpload,
    sampleId: string
) {

    const sampleUploadTaskId = sample.name
    dispatch(backgroundTasksActionNames.addTask, {
        path: [ runUploadTaskId ],
        id: sampleUploadTaskId,
        description: i18n.t("sampleUploadTaskDescription", [ sample.name ])
    })

    const fileUploadTasks = sample.files.map(file =>
        composeFileUploadTask(dispatch, file, runUploadTaskId, sampleUploadTaskId, messageBroker, sampleId))

    return () => Promise.all(
        fileUploadTasks.map(task =>
            /*
             TODO: [@aslepchenkov 19.02.2020] Tricky moment here, if we don't catch errors here
              Promise.all will be rejected, but other tasks will continue running, I can't stop them.
              And there is problem with high coupling. I remove task for run upload when tasks for all samples are done.
              Rejected task feels like done, so I remove run upload task with all it's children, but
              upload of the second file continues and is trying to update nonexistent task. No big deal, but
              causes errors in console.
              Best way - stop all tasks when at least one is rejected.
              Easy way - leave as it is. Think this task as pending until all it's tasks
              are rejected or resolved
            */
            task()
                .then(() => true)
                .catch(() => {
                    console.error("File upload failed")
                    return false
                }))
    )
        .then(areUploadsSuccessful => {
            if (areUploadsSuccessful.every(it => it)) {
                return messageBroker.sendCompleteSampleUploadMessage(sampleId)
            } else {
                throw "One of the file uploads failed"
            }
        })
}

const actions: ActionTree<RawDataUploadState, any> = {

    async uploadRun({ dispatch }, payload: { run: RunBlueprint, lots: Array<Lot> }) {
        let brokerIsBroken = false
        const failUpload = () => {
            console.debug("Failing upload")
            brokerIsBroken = true
        }
        const messageBroker = createMessageBroker(failUpload, failUpload)
        const { run, lots } = payload
        // TODO: Introduce motivation of such an exotic type annotation (why so tuple?)
        const samplesWithLot: Array<[ SampleForUpload, Lot ]> =
            lots.flatMap(lot => lot.samples.map(it => <[ SampleForUpload, Lot ]>[ it, lot ]))
        const sampleBlueprints: Array<SampleBlueprint> =
            samplesWithLot.map(([ sampleForUpload, lot ]) => ({
                name: sampleForUpload.name,
                runId: Number.parseInt(sampleForUpload.runId.slice(1)),
                isControlSample: lot.controlSample?.runId === sampleForUpload.runId
            }))

        const sampleIds = await messageBroker.sendNewRunBatchUploadInitializationMessage(
            run,
            sampleBlueprints
        )
            .waitForBatchUploadIds()

        const samplesWithId = <Array<[ SampleForUpload, string ]>>zip(samplesWithLot.map(([ sample ]) => sample), sampleIds)

        const runUploadTaskId = run.name
        dispatch(backgroundTasksActionNames.addTask, {
            path: [],
            id: runUploadTaskId,
            description: i18n.t("runUploadTaskDescription", [ run.name ])
        })

        const sampleUploadTasks = samplesWithId.map(([ sample, sampleId ]) =>
            composeSampleUploadTask(dispatch, messageBroker, runUploadTaskId, sample, sampleId))

        let samplesSuccessfullyUploaded
        // Let's hope this will not be optimized. Since it's updated only from other thread (via callbacks).
        if (!brokerIsBroken) {
            samplesSuccessfullyUploaded = await performSequentially(sampleUploadTasks)
        }
        if (!brokerIsBroken) {
            // We suppose that close is sent after last "COMPLETE_SAMPLE_UPLOAD" messages, cause it's called after it
            await messageBroker.completeUpload()
        }
        await dispatch(backgroundTasksActionNames.removeTask, { fullPath: [ runUploadTaskId ] })
        return !brokerIsBroken && samplesSuccessfullyUploaded
    }
}

const getters: GetterTree<RawDataUploadState, any> = {}

const mutations: MutationTree<RawDataUploadState> = {
    resetState(state) {
        console.debug("Raw data upload state reset")
        Object.assign(state, initialState())
    }
}

export const rawDataUpload: Module<RawDataUploadState, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
}

class NotificationService {
    notify(text: string, status: string) {
        // TODO: [@aslepchenkov 13.06.2019] Implement with browser notification api
        console.log(`Text: ${text}, status: ${status}`)
    }
}

const notificationService = new NotificationService()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const notifyTaskWasDone = (completedTask: Task) => notificationService.notify(completedTask.description, "COMPLETED")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const notifyAnalysisWasStarted = (sample: SampleBlueprint) => notificationService.notify(`${sample.name} analysis`, "STARTED")

function uploadFile(
    messageBroker: IBatchUploadMessageBroker,
    sampleId: string,
    file: File,
    onUploadProgress: (e: ProgressEvent) => void
) {
    return messageBroker.sendRequestForSampleFileUploadLink(sampleId, file.name)
        .waitForSampleFileUploadLink()
        .then(uploadLink => axios.request({
            method: "PUT",
            url: uploadLink,
            data: file,
            onUploadProgress
        }))
}

async function performSequentially(tasks: (() => Promise<any>)[]): Promise<boolean> {
    let errorEncountered = false
    for (const task of tasks) {
        try {
            await task()
        } catch {
            errorEncountered = true
            break
        }
    }
    return !errorEncountered
}
