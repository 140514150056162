import Vue from "vue"
import VueI18n, { DateTimeFormats, LocaleMessages } from "vue-i18n"
import { AnalysisStatus } from "@/enums/analysis-status"
import { ValidationError } from "@/validation/validators"

Vue.use(VueI18n)

export enum Locales {
    EN = "en-US",
    RU = "ru"
}

const messages: Record<Locales, { [key: string]: string | object } & { analysisStatuses: { [key in AnalysisStatus]: string } }> & LocaleMessages = {
    [Locales.EN]: {
        buttons: {
            send: "Send",
            cancel: "Cancel",
            close: "Close",
            search: "Search",
            reset: "Reset",
            export: "Export",
            genotypesExport: "Genotypes",
            generalStatisticsExport: "General statistics",
            locusSpecificStatisticsExport: "Locus specific statistics",
            reexport: "Reexport",
            previous: "Previous",
            next: "Next",
            startUpload: "Start upload",
            login: "Login",
            save: "Save",
            approve: "Approve",
            assignGenotypes: "Assign genotypes",
            markAsContaminated: "Mark as contaminated",
            clear: "Clear",
            undo: "Undo",
            select: "Select",
            toSupport: "Send to support",
            // TODO: [@aslepchenkov 22.04.2020] Should be some other word as in russian
            review: "Approve",
            accept: "Accept",
            reject: "Reject"
        },
        units: {
            bp: "bp",
            phred: "phred",
        },
        name: "Name",
        password: "Password",
        allele: "Allele",
        runId: "Run ID",
        runInId: "Run in ID",
        status: "Status",
        sampleName: "Sample name",
        runDate: "Run date",
        sequencingKit: "Sequencing kit",
        platform: "Platform",
        flows: "Flows",
        lot: "Lot",
        controlSample: "Control sample",
        lotSamplesCount: "sample with run ids | samples with run ids",
        notSelected: "not selected",
        knownAmbiguity: "Known ambiguity",
        rareAllele: "Rare allele",
        analysisStatuses: {
            "AWAITING_APPROVE": "Awaiting approve",
            "AWAITING_REVIEW": "Awaiting review",
            "COMPLETED": "Completed",
            "IN_SUPPORT": "In support",
            "ERROR": "Error",
            "LAB_FAILURE": "Lab failure",
            "TYPING_FAULT": "Typing fault",
            "RUNNING": "Processing",
        },
        analysisMetrics: {
            totalReads: "Total reads",
            insertSize: "Insert size",
            exonInLqrRegion: "Exon coverage",
            locusCoverage: "Reads per locus",
            locusCoveragePercent: "Percentage of reads per locus",
            meanReadsQuality: "Mean reads quality",
            goodMeanReadsQuality: "Mean reads quality: PASSED",
            contamination: "MIX",
            genotypesNumber: "Genotypes number",
            allelesNumber: "Alleles number",
            readsDistribution: "Reads distribution",
            targetLoci: "Target loci",
            relatedLoci: "Related loci",
            qualityTrimmed: "Quality trimmed",
            offTargetRegions: "Off target regions",
            // Why singular? God knows, ask other team members
            novelAlleles: "Novel allele",
            total: "Total",
            typingError: "Typing error"
        },
        runFilterPlaceholders: {
            liveSearch: "Search by run ID",
            from: "From",
            to: "To",
            organization: "Organisation"
        },
        toSupportModal: {
            comments: "Comments",
            selectProblemLoci: "Select problem loci"
        },
        validating: "Validating",
        errorMessages: {
            [ValidationError.FlowsFormatError]: "Must be forward read length + reverse read length",
            [ValidationError.ExistingRunId]: "Run with such id already exists",
            [ValidationError.Required]: "Field is required",
            [ValidationError.InvalidAlleleFormat]: "Invalid allele format. Example: A*12:120, A*12:120:123 or A@N",
            [ValidationError.ExistingLotName]: "Lot with such name already exists",
            [ValidationError.InvalidLotName]: "Lot name must look like WWYY-N",
            [ValidationError.LotDoesNotExist]: "Such lot doesn't exist",
            [ValidationError.AlleleAlreadyExists]: "The same allele already exists",
            [ValidationError.WrongAlleleFormatAtSupport]: "Allele format must adhere to 01:01:01, 02.02N"
        },
        selectedAlleleNotFound: "Selected allele not found",
        locus: "Locus",
        genotype: "Genotype",
        oldGenotype: "Old genotype",
        newGenotype: "New genotype",
        qcDetails: "QC details",
        history: "History",
        report: "Report",
        runReport: "Run report",
        sampleReport: "Sample report",
        analysisInProgress: "Analysis in progress",
        invalidCredentials: "Invalid credentials",
        settings: "Settings",
        subscriptions: {
            title: "Email notifications",
            RAW_DATA_ANALYSIS_COMPLETION: "Raw data analysis completion",
            SEQUENCING_RUN_READY_FOR_APPROVE: "Sequencing run is ready for approve",
            SEQUENCING_RUN_READY_FOR_EXPORT: "Sequencing run is ready for export",
            SUCCESSFUL_RAW_DATA_UPLOAD: "Successful data upload",
            RAW_DATA_UPLOAD_FAILURE: "Error loading data",
            ANALYSIS_DATA_REMOVE: "Removing analysis data",
        },
        profile: "Profile",
        roles: {
            "ADMIN": "Admin",
            "SUPERVISOR": "Supervisor",
            "SUPPORT": "Support engineer",
            "TECHNICIAN": "Technician",
            "SUPER_ADMIN": "Superadmin",
        },
        noMatchingResults: "No results found",
        timezone: "Timezone",
        userEmail: "Email",
        editUserEmail: "Change",
        saveUserEmail: "Save",
        fileUploadTaskDescription: "Uploading file {0}",
        sampleUploadTaskDescription: "Uploading sample {0}",
        runUploadTaskDescription: "Uploading run {0}",
        tooLittleReadsToBeAnalysed: "Sample has too little reads to be analysed",
        makePipelineRelevant: "Make the pipeline relevant",
        currentPipeline: "Current pipeline",
        reAnalysisGenotype: "Reanalysis Genotype",
        reportRun: "Run report",
        reportAnalysis: "Analysis report",
    },
    [Locales.RU]: {
        buttons: {
            send: "Отправить",
            cancel: "Отмена",
            close: "Закрыть",
            search: "Поиск",
            reset: "Сбросить",
            export: "Экспорт",
            genotypesExport: "Генотипы",
            generalStatisticsExport: "Общие метрики",
            locusSpecificStatisticsExport: "Локус специфичные метрики",
            reexport: "Реэкспорт",
            previous: "Назад",
            next: "Вперед",
            startUpload: "Начать загрузку",
            login: "Войти",
            save: "Сохранить",
            approve: "Одобрить",
            select: "Выбрать",
            assignGenotypes: "Присвоить генотипы",
            markAsContaminated: "Контаминация",
            clear: "Очистить",
            undo: "Уничтожать сделанное",
            toSupport: "Отправить в поддержку",
            review: "Утвердить",
            accept: "Принять",
            reject: "Отклонить"
        },
        units: {
            bp: "п.н.",
            phred: "phred",
        },
        name: "Имя",
        password: "Пароль",
        allele: "Аллель",
        runId: "ID запуска",
        runInId: "ID в запуске",
        status: "Статус",
        sampleName: "Имя образца",
        runDate: "Дата запуска",
        sequencingKit: "Набор для секвенирования",
        platform: "Платформа",
        flows: "Циклы",
        lot: "Серия",
        controlSample: "Контрольный образец",
        lotSamplesCount: "образец с идентификатором | образца с идентификаторами | образцов с идентификаторами",
        notSelected: "не выбран",
        knownAmbiguity: "Известная неоднозначность",
        rareAllele: "Редкая аллель",
        analysisStatuses: {
            "AWAITING_APPROVE": "Ожидает одобрения",
            "AWAITING_REVIEW": "Ожидает утверждения",
            "COMPLETED": "Утверждён",
            "IN_SUPPORT": "В поддержке",
            "ERROR": "Тех. ошибка",
            "LAB_FAILURE": "Лаб. ошибка",
            "TYPING_FAULT": "Ошибка типирования",
            "RUNNING": "Анализируется",
        },
        analysisMetrics: {
            totalReads: "Число прочтений на образец",
            insertSize: "Размер вставки",
            exonInLqrRegion: "Покрытие экзона",
            locusCoverage: "Число прочтений на локус",
            locusCoveragePercent: "Доля прочтений на локус",
            meanReadsQuality: "Ср. качество прочтений",
            goodMeanReadsQuality: "Ср. качество прочтений в образцах запуска: ПРИЕМЛЕМОЕ",
            contamination: "СМЕСЬ",
            genotypesNumber: "Число генотипов",
            allelesNumber: "Число аллелей",
            readsDistribution: "Распределение прочтений",
            targetLoci: "Целевые",
            relatedLoci: "Гомологичные",
            qualityTrimmed: "Трим. по качеству",
            offTargetRegions: "Нецелевые",
            // Why singular? God knows ask other team members
            novelAlleles: "Новая аллель",
            total: "Всего",
            typingError: "Ошибка типирования"
        },
        runFilterPlaceholders: {
            liveSearch: "Введите ID запуска",
            from: "От",
            to: "До",
            organization: "Организация"
        },
        toSupportModal: {
            comments: "Комментарии",
            selectProblemLoci: "Укажите проблемные локусы"
        },
        validating: "Идет проверка",
        errorMessages: {
            [ValidationError.FlowsFormatError]: "Формат: длина прямого рида + длина обратного рида",
            [ValidationError.ExistingRunId]: "Запуск с таким ID уже существует",
            [ValidationError.Required]: "Обязательное поле",
            [ValidationError.InvalidAlleleFormat]: "Неверная запись аллели. Пример: A*12:120, A*12:120:123 или A@N",
            [ValidationError.ExistingLotName]: "Такая серия реагентов уже существует",
            [ValidationError.InvalidLotName]: "Серия должна иметь формат: ННГГ-Ч",
            [ValidationError.LotDoesNotExist]: "Набора реагентов с такой серией не существует",
            [ValidationError.AlleleAlreadyExists]: "Такая аллель уже существует",
            [ValidationError.WrongAlleleFormatAtSupport]: "Аллель должна быть в формате 01:01:01, 02.02N"
        },
        selectedAlleleNotFound: "Выбранная аллель не найдена",
        locus: "Локус",
        genotype: "Генотип",
        oldGenotype: "Старый генотип",
        newGenotype: "Новый генотип",
        qcDetails: "Детали КК",
        history: "История",
        report: "Отчет",
        runReport: "Отчет по запуску",
        sampleReport: "Отчет по образцу",
        analysisInProgress: "Идет анализ",
        invalidCredentials: "Пользователя с таким именем/паролем не существует",
        settings: "Настройки",
        subscriptions: {
            title: "Уведомления на электронную почту",
            RAW_DATA_ANALYSIS_COMPLETION: "Окончание автоматического анализа сырых данных",
            SEQUENCING_RUN_READY_FOR_APPROVE: "Запуск готов для одобрения после цикла поддержки",
            SEQUENCING_RUN_READY_FOR_EXPORT: "Запуск готов к экспорту",
            SUCCESSFUL_RAW_DATA_UPLOAD: "Успешная загрузка данных",
            RAW_DATA_UPLOAD_FAILURE: "Ошибка загрузки данных",
            ANALYSIS_DATA_REMOVE: "Удаление данных анализа",
        },
        profile: "Профиль",
        roles: {
            "ADMIN": "Администратор",
            "SUPERVISOR": "Супервизор",
            "SUPPORT": "Работник службы поддержки",
            "TECHNICIAN": "Лаборант",
            "SUPER_ADMIN": "Суперадмин",
        },
        noMatchingResults: "Нет подходящих вариантов",
        timezone: "Часовой пояс",
        userEmail: "Электронная почта",
        editUserEmail: "Изменить",
        saveUserEmail: "Сохранить",
        fileUploadTaskDescription: "Загрузка файла {0}",
        sampleUploadTaskDescription: "Загрузка образца {0}",
        runUploadTaskDescription: "Загрузка запуска {0}",
        tooLittleReadsToBeAnalysed: "В образце слишком мало прочтений для проведения анализа",
        makePipelineRelevant: "Сделать пайплайн актуальным",
        currentPipeline: "Актуальный пайплайн",
        reAnalysisGenotype: "Генотип реанализа",
        reportRun: "Отчет по запуску",
        reportAnalysis: "Отчет по образцу",
    }
}

const dateTimeFormatsFactory: (timeZone: string) => DateTimeFormats
    = (timeZone: string) => (
        {
            [Locales.EN]: {
                short: {
                    year: "2-digit", month: "2-digit", day: "2-digit",
                    timeZone
                },
                long: {
                    year: "numeric", month: "short", day: "2-digit",
                    weekday: "short", hour: "numeric", minute: "numeric", hour12: true,
                    timeZone
                },
            },
            [Locales.RU]: {
                short: {
                    year: "2-digit", month: "2-digit", day: "2-digit",
                    timeZone
                },
                long: {
                    year: "numeric", month: "short", day: "2-digit",
                    weekday: "short", hour: "numeric", minute: "numeric", hour12: false,
                    timeZone
                },
            }
        })

export function setTimeZone(timeZone: string) {
    const dateTimeFormats = dateTimeFormatsFactory(timeZone)
    Object.entries(dateTimeFormats).forEach(([ locale, dateTimeFormat ]) => {
        i18n.setDateTimeFormat(locale, dateTimeFormat)
    })
}

export const i18n = new VueI18n({
    locale: Locales.RU,
    messages,
    silentFallbackWarn: true,
    pluralizationRules: {
        [Locales.RU]: russianPluralization
    }
})


/**
 * VueI18n russian pluralization
 *
 * @param count objects count
 * @param choicesLength translations for different objects count. Could be:
 *   * 3 variants - ending with 1 | ending with 2-4 | 0 and ending with 5-9
 *   * 4 variants - no objects (0) | ending with 1 | ending with 2-4 | ending with 5-9
 *   * 2 variants - 1 | more than 1
 *  Of course 10-19 are handled
 *
 */
export function russianPluralization(count: number, choicesLength: number): 0 | 1 | 2 | 3 {
    if (choicesLength === 2) {
        return count === 1 ? 0 : 1
    }

    if (count === 0) {
        return choicesLength === 4 ? 0 : 2
    }

    const teen = count > 10 && count < 20
    const endsWithOne = count % 10 === 1

    if (!teen && endsWithOne) {
        return choicesLength === 4 ? 1 : 0
    }

    if (!teen && count % 10 >= 2 && count % 10 <= 4) {
        return choicesLength === 4 ? 2 : 1
    }

    return (choicesLength === 4) ? 3 : 2
}
