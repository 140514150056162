export function groupBy<T, U extends keyof any>(array: T[], getKey: (entity: T) => U): Record<U, T[]> {
    return array.reduce(
        (prev, cur) => {
            const key = getKey(cur)
            prev[key] = prev[key]
                ? [ ...prev[key], cur ]
                : [ cur ]
            return prev
        },
        {} as Record<U, T[]>
    )
}


type CompareFunction<T> = (a: T, b: T) => number


export const compareNumbersAscending: CompareFunction<number> = (a: number, b: number) => a - b
export const compareNumbersDescending: CompareFunction<number> = (a: number, b: number) => b - a


export const compareByWeightAscending = <T extends keyof any>(weights: Record<T, number>) => {
    return (first: T, second: T) => compareNumbersAscending(weights[first], weights[second])
}

export function updateOrSet<K extends keyof any, V>(record: Record<K, V[]>, key: K, value: V) {
    record[key] = record[key]
        ? [ ...record[key], value ]
        : [ value ]
    return record
}

export function downloadUsingPhantomLink(link: string) {
    const phantomLink = document.createElement("a")
    phantomLink.href = link
    phantomLink.dispatchEvent(new MouseEvent("click"))
}

export function viewPdfInNewTab(link: string) {
    const phantomLink = document.createElement("a")
    phantomLink.href = link
    phantomLink.setAttribute("target", "_blank")
    phantomLink.setAttribute("rel", "noopener noreferrer")
    phantomLink.dispatchEvent(new MouseEvent("click"))
}

type TypeofResult = "undefined" | "object" | "boolean" | "number" | "bigint" | "string" | "symbol" | "function"

/**
 * Validator for vue component props, to allow null values for required props
 */
export function nullable(typeName: TypeofResult): (prop: unknown) => boolean {
    return prop => typeof prop === typeName || prop === null
}
