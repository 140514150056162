import { Run } from "@/store/modules/runs"

const constantProperties = {
    ownedBy: "Parseq Lab",
    createdBy: "Parseq",
    createdAt: new Date().getTime(),
    date: new Date().getTime(),
}

const sequencingKit = {
    name: "MiSeq Reagent Kit v2",
    platform: "MiSeq"
}

export const runs: Array<Run> = [
    {
        id: "1",
        name: "G2DLA",
        exportStatus: "NOT_READY",
        ...constantProperties,
        properties: {
            flows: "150 + 150",
            labQcAggs: {
                green: 189,
                red: 0,
                yellow: 3
            },
            statusAggs: {
                AWAITING_REVIEW: 10,
                AWAITING_APPROVE: 10,
                ERROR: 1,
                IN_SUPPORT: 10,
                COMPLETED: 10,
                LAB_FAILURE: 1,
                RUNNING: 10,
                TYPING_FAULT: 10
            },
            sequencingKit,
            controlSampleQc: {
                "6019-1": "UNDEFINED",
            }
        },
    },
    {
        id: "4",
        name: "G2DM4",
        exportStatus: "NOT_READY",
        ...constantProperties,
        properties: {
            flows: "150 + 150",
            labQcAggs: {
                green: 189,
                red: 0,
                yellow: 3
            },
            statusAggs: {
                AWAITING_REVIEW: 10,
                AWAITING_APPROVE: 10,
                ERROR: 0,
                IN_SUPPORT: 10,
                COMPLETED: 10,
                LAB_FAILURE: 1,
                RUNNING: 0,
                TYPING_FAULT: 10
            },
            sequencingKit,
            controlSampleQc: {
                "6019-1": "PASSED",
                "6019-2": "FAILED"
            }
        },
    },
    {
        id: "2",
        name: "G59G2",
        exportStatus: "ALREADY",
        ...constantProperties,
        properties: {
            flows: "150 + 150",
            labQcAggs: {
                green: 189,
                red: 0,
                yellow: 3
            },
            statusAggs: {
                AWAITING_REVIEW: 10,
                AWAITING_APPROVE: 10,
                ERROR: 1,
                IN_SUPPORT: 10,
                COMPLETED: 10,
                LAB_FAILURE: 1,
                RUNNING: 10,
                TYPING_FAULT: 10
            },
            sequencingKit,
            "controlSampleQc": {
                "6019-1": "UNDEFINED"
            }
        },
    },
    {
        id: "3",
        name: "D843M3",
        exportStatus: "READY",
        ...constantProperties,
        properties: {
            flows: "150 + 150",
            labQcAggs: {
                green: 189,
                red: 0,
                yellow: 3
            },
            statusAggs: {
                AWAITING_REVIEW: 10,
                AWAITING_APPROVE: 10,
                ERROR: 1,
                IN_SUPPORT: 10,
                COMPLETED: 10,
                LAB_FAILURE: 1,
                RUNNING: 10,
                TYPING_FAULT: 10
            },
            sequencingKit,
            "controlSampleQc": {
                "6019-1": "UNDEFINED"
            }
        },
    },
    {
        id: "5",
        name: "G2HB5",
        exportStatus: "ALREADY",
        ...constantProperties,
        properties: {
            flows: "150 + 150",
            labQcAggs: {
                green: 189,
                red: 0,
                yellow: 3
            },
            statusAggs: {
                AWAITING_REVIEW: 10,
                AWAITING_APPROVE: 10,
                ERROR: 0,
                IN_SUPPORT: 10,
                COMPLETED: 10,
                LAB_FAILURE: 1,
                RUNNING: 0,
                TYPING_FAULT: 10
            },
            sequencingKit,
            "controlSampleQc": {
                "6019-1": "UNDEFINED"
            }
        },
    },
]
