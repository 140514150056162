<template>
    <div class="sample-card">
        <error-message v-if="maybeFirstError" @close="removeFirstError">
            <template v-if="maybeFirstError.type === 'dataNotAvailableForArchive'">
                <div>{{ $t("dataNotAvailableForArchive") }}</div>
            </template>
            <template v-if="maybeFirstError.type === 'noRunAnalysisFound'">
                <div>{{ $t("noRunAnalysisFound") }}</div>
            </template>
            <template v-if="maybeFirstError.type === 'errorDuringDeletion'">
                <div>{{ $t("errorDuringDeletion") }}</div>
            </template>
        </error-message>
        <p-modal v-if="modals['sampleDetails'].opened">
            <div class="analysis-details-modal">
                <AnalysisDetails :analysis="analysis"></AnalysisDetails>
                <button @click="closeModal('sampleDetails')"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <p-modal v-if="sampleHistoryOpened">
            <div class="analysis-details-modal">
                <AnalysisHistory :analysis="analysis"/>
                <button @click="closeModal('sampleHistory')"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <p-modal v-if="modals['support'].opened">
            <div class="to-support-modal">
                <div style="padding: 1rem">
                    <div>{{ $t("toSupportModal.selectProblemLoci") }}:</div>
                    <button class="flex"
                            style="padding: .2rem .6rem"
                            @click="selectedProblemLoci[locus] = !selectedProblemLoci[locus]"
                            :key="locus" v-for="(isSelected, locus) in selectedProblemLoci">
                        <p-icon :icon-name="isSelected ? 'checkbox-checked' : 'checkbox-unchecked'"
                                class="mr-1"/>
                        <span>{{ locus }}</span>
                    </button>
                </div>
                <p-text-area
                    class="md-outlined"
                    :placeholder="$t('toSupportModal.comments')"
                    style="height: 30rem; width: 30rem; margin: 1rem"
                    v-model="supportRequestComment"
                />
                <div class="to-support-modal--actions">
                    <button @click="onSendToSupportBtnClick"
                            :disabled="!supportRequestFormStateIsValid"
                            class="md-btn-contained royal-blue">{{ $t("buttons.send") }}
                    </button>
                    <button @click="closeModal('support')"
                            class="md-btn-text" style="color: royalblue">{{ $t("buttons.cancel") }}
                    </button>
                </div>
            </div>
        </p-modal>
        <p-modal v-if="modals['reviewInfo'].opened">
            <div class="approve-info-modal">
                <analysis-card :analysis="analysis" style="margin: 1rem"/>

                <div class="approve-btn-container">
                    <button @click="review(analysis)"
                            id="to-review-btn"
                            class="to-support-button md-btn-contained green"
                    >
                        {{ $t("buttons.review") }}
                        <span style="transform: translateY(-10%)">↓</span>
                    </button>
                </div>

                <button @click="closeModal('reviewInfo')"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <p-modal v-if="modals['approveInfo'].opened">
            <div class="approve-info-modal">
                <approve-analysis-card :analysis="analysis" :key="analysis.id"/>

                <div class="approve-btn-container">
                    <button @click="approve(analysis)"
                            id="to-approve-btn"
                            class="to-support-button md-btn-contained green"
                    >
                        {{ $t("buttons.approve") }}
                        <span style="transform: translateY(-10%)">↓</span>
                    </button>
                </div>

                <button @click="closeModal('approveInfo')"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <div class="sample-card--description">
            <div style="display: flex; align-items: baseline">
                <h1 class="mr-1 ellipsis" style="display: inline" id="sample-name">{{ analysis.name }}</h1>
                <h2 style="font-weight: 400; margin-right: 1rem; display: inline"
                    id="sample-id-in-run"
                >S{{ analysis.runId }}
                </h2>
                <!-- P.S. I was forced to align this button with the first line, and I think this solution is better than
                     hardcoded styles -->
                <p-dropdown
                    class="ml-auto"
                    align="left-top"
                    i-docs="sample-card-context-menu"
                    fixed
                >
                    <template v-slot:activator="{ toggleDropdown }">
                        <p-icon-button
                            class="icon-btn--contrast icon-btn--round"
                            icon-name="more-vertical"
                            size="2em"
                            @click="toggleDropdown"
                        />
                    </template>
                    <div class="dropdown--white">
                        <ul>
                            <li>
                                <button
                                    v-if="hasBeenSuccessfullyAnalyzed"
                                    id="qc-details-btn"
                                    @click="openModal('sampleDetails')"
                                    class="sample-card--btn"
                                >
                                    {{ $t("qcDetails") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    id="history-btn"
                                    @click="openModal('sampleHistory')"
                                    class="sample-card--btn"
                                >
                                    {{ $t("history") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="hasTerminalStatus"
                                    id="report-btn"
                                    @click="downloadReport"
                                    class="sample-card--btn"
                                >
                                    {{ $t("reportAnalysis") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="hasTerminalStatus && sample.sequencingExportStatus === 'ALREADY'"
                                    id="report-btn"
                                    @click="viewRunPdfReport"
                                    class="sample-card--btn"
                                >
                                    {{ $t("reportRun") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    id="archive-btn"
                                    @click="downloadArchive"
                                    class="flex sample-card--btn complex-button"
                                >
                                    <span>{{ $t("configuredPipelineResultResourcesArchive") }}</span>
                                    <p-icon style="margin-left: 0.5rem" iconName="save-alt"/>
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="hasSupport"
                                    id="archive-pipeline-btn"
                                    @click="downloadConfiguredPipelineResultResourcesArchive"
                                    class="flex sample-card--btn complex-button"
                                >
                                    <span>{{ $t("configuredPipelineResultResourcesAllArchive") }}</span>
                                    <p-icon style="margin-left: 0.5rem" iconName="save-alt"/>
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="hasSuperAdmin && possibilityRemovingSamplesAndRuns"
                                    id="sample-delete-btn"
                                    @click="deleteSample"
                                    class="sample-card--btn"
                                >
                                    {{ $t("deleteSample") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="hasSuperAdmin && possibilityRemovingSamplesAndRuns"
                                    id="run-delete-btn"
                                    @click="deleteRun"
                                    class="sample-card--btn"
                                >
                                    {{ $t("deleteRun") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="isAwaitingReview && hasSupervisor"
                                    id="review-btn"
                                    @click="openModal('reviewInfo')"
                                    class="sample-card--btn"
                                >
                                    {{ $t("buttons.review") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="isAwaitingApprove && hasSupervisor"
                                    id="approve-btn"
                                    @click="openModal('approveInfo')"
                                    class="sample-card--btn"
                                >
                                    {{ $t("buttons.approve") }}
                                </button>
                            </li>
                            <li>
                                <button
                                    v-if="isAwaitingReviewOrAwaitingApproveOrCompleted"
                                    id="request-support-btn"
                                    @click="openModal('support')"
                                    class="sample-card--btn"
                                >
                                    {{ $t("buttons.toSupport") }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </p-dropdown>
            </div>
            <div class="mb-1" style="display: flex; align-items: baseline">
                <h2 style="font-weight: 600; display: inline"
                    id="run-name"
                    class="mr-1 ellipsis">{{ analysis.sequencingRunId }}</h2>
                <span id="sequencing-run-date">{{ $d(analysis.sequencingRunDate) }}</span>
            </div>
            <AnalysisLotBadge id="lot-badge"
                              class="lot-badge-fit-content"
                              :control-sample-status="analysis.reagentKitLotQcStatus"
                              :lot-name="analysis.reagentKitLotName"
                              :imgt-version="analysis.configuredPipelineImgtVersion"
                              :configured-pipeline-name="analysis.configuredPipelineName"
            />
        </div>
        <div
            v-if="hasBeenSuccessfullyAnalyzed"
            style="grid-column: 1 / -1;"
        >
            <GenotypesTable :genotypes="analysis.result.genotypes"
                            :annotations="analysis.result.annotations || {}"
                            class="sample-card--genotypes-table"
                            :analysis="analysis"/>
            <ImgtVersionLink :imgt-version="analysis.configuredPipelineImgtVersion"/>
        </div>
        <div v-else-if="analysis.status === 'RUNNING'"
             class="sample-card--not-analysed-stub">
            {{ $t("analysisInProgress") }}
        </div>
        <div v-else-if="analysis.status === 'ERROR'"
             class="sample-card--not-analysed-stub">
            {{ $t("analysisError") }}
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { Locales } from "@/i18n/main"
    import AnalysisLotBadge from "@/components/analysis/lot-badge"
    import AnalysisDetails from "@/components/analysis/details"
    import GenotypesTable from "@/components/analysis/genotypes-table"
    import AnalysisHistory from "@/components/analysis/history"
    import ImgtVersionLink from "@/components/imgt-version-link"
    import ApproveAnalysisCard from "@/views/approve/components/approve-analysis-card.vue"
    import AnalysisCard from "@/views/review/components/analysis-card.vue"
    import { viewPdfInNewTab } from "@/helpers"
    import { approveAnalysis, buildSamplePdfReportExportUrl, markAnalysisAsReviewed, sendAnalysisToSupport, buildRunPdfReportExportUrl } from "@/endpoints"
    import { hasBeenSuccessfullyAnalyzed, hasTerminalStatus } from "@/utils/analysis"
    import ErrorMessage from "@/components/error-message"
    import { AnalysisStatuses } from "@/enums/analysis-status"
    import { LOCI } from "@/genotype"
    import { isValidSupportRequestData } from "@/types"
    import { errorMixin } from "@/mixins/error-mixin"
    import { applyResolutionDraft, constructNewProperties } from "@/utils/support"

    const SAMPLE_DETAILS_MODAL = "sampleDetails"
    const SAMPLE_HISTORY_MODAL = "sampleHistory"
    const RAISE_SUPPORT_REQUEST_MODAL = "support"
    const APPROVE_INFO_MODAL = "approveInfo"
    const REVIEW_INFO_MODAL = "reviewInfo"

    export default {
        name: "SampleCard",
        components: { AnalysisCard, ApproveAnalysisCard, ImgtVersionLink, AnalysisLotBadge, AnalysisHistory, GenotypesTable, AnalysisDetails, ErrorMessage },
        mixins: [ modalMixin, errorMixin ],
        props: {
            sample: {
                type: Object,
                required: true,
            },
            possibilityRemovingSamplesAndRuns: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                modals: {
                    [SAMPLE_DETAILS_MODAL]: {
                        opened: false
                    },
                    [SAMPLE_HISTORY_MODAL]: {
                        opened: false
                    },
                    [RAISE_SUPPORT_REQUEST_MODAL]: {
                        opened: false,
                        onOpen: () => {
                            this.selectedProblemLoci =
                                LOCI.reduce((selection, locus) => (selection[locus] = false, selection), {})
                            this.supportRequestComment = ""
                        },
                    },
                    [APPROVE_INFO_MODAL]: {
                        opened: false
                    },
                    [REVIEW_INFO_MODAL]: {
                        opened: false
                    },
                },
                selectedProblemLoci: null,
                supportRequestComment: null,
            }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
            sampleHistoryOpened() {
                return this.modals[SAMPLE_HISTORY_MODAL].opened
            },
            hasBeenSuccessfullyAnalyzed() {
                return hasBeenSuccessfullyAnalyzed(this.analysis)
            },
            hasTerminalStatus() {
                return hasTerminalStatus(this.analysis)
            },
            isAwaitingReviewOrAwaitingApproveOrCompleted() {
                return this.analysis.status === AnalysisStatuses.AWAITING_REVIEW
                    || this.analysis.status === AnalysisStatuses.COMPLETED
                    || this.analysis.status === AnalysisStatuses.AWAITING_APPROVE
                    || this.analysis.status === AnalysisStatuses.TYPING_FAULT
            },
            isAwaitingReview() {
                return this.analysis.status === AnalysisStatuses.AWAITING_REVIEW
            },
            isAwaitingApprove() {
                return this.analysis.status === AnalysisStatuses.AWAITING_APPROVE
            },
            supportRequestFormState() {
                return {
                    comment: this.supportRequestComment,
                    loci: Object
                        .entries(this.selectedProblemLoci)
                        .reduce(
                            (selectedLoci, [ locus, isSelected ]) =>
                                isSelected
                                    ? [ locus, ...selectedLoci ]
                                    : selectedLoci,
                            []
                        ),
                }
            },
            supportRequestFormStateIsValid() {
                return isValidSupportRequestData(this.supportRequestFormState)
            },
            analysis() {
                return (this.sample.status === "AWAITING_APPROVE")
                    ? applyResolutionDraft(this.sample, this.sample.maybeResolutionDraft)
                    : this.sample
            },
            hasSuperAdmin() {
                return this.user.authorities.some(it => it === "SUPER_ADMIN")
            },
            hasSupport() {
                return this.user.authorities.some(it => it === "SUPPORT")
            },
            hasSupervisor() {
                return this.user.authorities.some(it => it === "SUPERVISOR")
            },
        },
        methods: {
            downloadReport() {
                viewPdfInNewTab(buildSamplePdfReportExportUrl(this.analysis.id))
            },
            viewRunPdfReport() {
                viewPdfInNewTab(buildRunPdfReportExportUrl(this.analysis.sequencingRunUuid))
            },
            async onSendToSupportBtnClick() {
                const updatedAnalysis = await sendAnalysisToSupport({
                    analysisId: this.analysis.id,
                    comment: this.supportRequestFormState.comment,
                    loci: this.supportRequestFormState.loci,
                    // eslint-disable-next-line promise/always-return
                })
                this.closeModal(RAISE_SUPPORT_REQUEST_MODAL)
                this.$emit("update:sample", updatedAnalysis)
                return updatedAnalysis
            },
            async downloadArchive() {
                return axios.get(
                    `/api/analyses/${this.analysis.id}/downloadArchive`,
                    {
                        responseType: "blob"
                    },
                )
                    .then(({ data }) => window.URL.createObjectURL(new Blob([ data ])))
                    .then(link => downloadUsingPhantomLink(link, `${this.analysis.name}__analysis_result.zip`))
                    .catch((error) => {
                        if(error.response.status === 409) {
                            this.addError({
                                type: "dataNotAvailableForArchive",
                            })
                        } else {
                            throw error
                        }
                    })
            },
            async downloadConfiguredPipelineResultResourcesArchive() {
                return axios.get(
                    `api/support/sequencingRuns/${this.analysis.sequencingRunUuid}/analyses/${this.analysis.id}/configuredPipelineResultResourcesArchive`,
                    {
                        responseType: "blob"
                    },
                )
                    .then(({ data }) => window.URL.createObjectURL(new Blob([ data ])))
                    .then(link => downloadUsingPhantomLink(link, `${this.analysis.name}__analysis_result.zip`))
                    .catch((error) => {
                        if(error.response.status === 409) {
                            this.addError({
                                type: "dataNotAvailableForArchive",
                            })
                        } else {
                            throw error
                        }
                    })
            },
            review(analysis) {
                return markAnalysisAsReviewed(analysis.id)
                    .then(() => this.$store.commit("decreaseAwaitingReviewAnalysisNumber"))
                    .then(() => {
                        const updatedAnalysis = analysis
                        updatedAnalysis.status = "COMPLETED"
                        this.$emit("update:sample", updatedAnalysis)
                        return updatedAnalysis
                    })
                    .finally(() => this.closeModal(REVIEW_INFO_MODAL))
            },
            approve(analysis) {
                return approveAnalysis(analysis.id, constructNewProperties(analysis))
                    .then(() => this.$store.commit("decreaseAwaitingCustomerAnalysisNumber"))
                    .then(() => {
                        const updatedAnalysis = analysis
                        updatedAnalysis.status = "COMPLETED"
                        this.$emit("update:sample", updatedAnalysis)
                        return updatedAnalysis
                    })
                    .finally(() => this.closeModal(APPROVE_INFO_MODAL))
            },
            deleteSample() {
                if(confirm(this.$t("youSureWantToDeleteTheAnalysis"))) {
                    return axios.delete(
                        `/api/superAdmin/analysis?analysisId=${this.analysis.id}`,
                    )
                        .then(() => {
                            this.$emit("delete:sample")
                            return ""
                        })
                        .catch((error) => {
                            if(error.response.status === 404) {
                                this.addError({
                                    type: "noRunAnalysisFound",
                                })
                            } else if(error.response.status === 424) {
                                this.addError({
                                    type: "errorDuringDeletion",
                                })
                            } else {
                                throw error
                            }
                        })
                } else {
                    return null
                }
            },
            deleteRun() {
                if(confirm(this.$t("youSureWantToDeleteTheRun"))) {
                    return axios.delete(
                        `/api/superAdmin/run?runId=${this.analysis.sequencingRunUuid}`,
                    )
                        .then(() => {
                            this.$emit("delete:run")
                            return ""
                        })
                        .catch((error) => {
                            if(error.response.status === 404) {
                                this.addError({
                                    type: "noRunAnalysisFound",
                                })
                            } else if(error.response.status === 424) {
                                this.addError({
                                    type: "errorDuringDeletion",
                                })
                            } else {
                                throw error
                            }
                        })
                } else {
                    return null
                }
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    analysisInProgress: "Analysis in progress...",
                    analysisError: "Analysis error occurred",
                    configuredPipelineResultResourcesArchive: "Analysis results",
                    configuredPipelineResultResourcesAllArchive: "All analysis results",
                    dataNotAvailableForArchive: "No data available because the sample was not typed or the genotype was assigned based on additional analysis.",
                    noRunAnalysisFound: "No run or analysis found",
                    errorDuringDeletion: "Error during deletion",
                    deleteSample: "Delete analysis",
                    deleteRun: "Delete run",
                    youSureWantToDeleteTheAnalysis: "Are you sure you want to delete the analysis?",
                    youSureWantToDeleteTheRun: "Are you sure you want to delete the run?",
                },
                [Locales.RU]: {
                    analysisInProgress: "Образец анализируется...",
                    analysisError: "Анализ завершился с ошибкой",
                    configuredPipelineResultResourcesArchive: "Результаты анализа",
                    configuredPipelineResultResourcesAllArchive: "Все результаты анализа",
                    dataNotAvailableForArchive: "Данные отсутствуют, так как образец не был протипирован, или генотип присвоен по результатам дополнительного анализа.",
                    noRunAnalysisFound: "Не найден ран или анализ",
                    errorDuringDeletion: "Ошибка во время удаления",
                    deleteSample: "Удалить анализ",
                    deleteRun: "Удалить ран",
                    youSureWantToDeleteTheAnalysis: "Вы уверены, что хотите удалить анализ?",
                    youSureWantToDeleteTheRun: "Вы уверены, что хотите удалить ран?",
                }
            }
        }
    }

    function downloadUsingPhantomLink(link, fileName) {
        const phantomLink = document.createElement("a")
        phantomLink.href = link
        if (fileName != null) {
            phantomLink.setAttribute("download", fileName)
        }
        phantomLink.dispatchEvent(new MouseEvent("click"))
    }
</script>

<style scoped>

.analysis-details-modal {
    padding: 2em 2em 1em;
    font-size: 1.7rem;
    max-width: 90vw;
}

.sample-card--not-analysed-stub {
    grid-column: 1 / -1;
    font-size: 3rem;
    font-weight: 700;
    color: var(--grey-400);
    text-align: center;
    margin: auto 0;
}

.sample-card {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    padding: 2rem;
    border: 1px solid var(--grey-400);
    border-radius: 4px;
    margin: 0 1rem;
    width: 30em;
    position: relative;
    max-height: 80%;
    min-height: 40rem;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

.sample-card--btn {
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 600;
    color: royalblue;
    white-space: nowrap;
    padding: 1rem 1.5rem;
    width: 100%;
}

.complex-button {
    justify-content: center;
}

.sample-card--btn:hover {
    background-color: rgba(65, 105, 225, .15);
}

.sample-card--genotypes-table {
    margin-top: 2rem;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}

.to-support-modal {
    font-size: 2rem;
    padding: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content;
}

.to-support-modal--actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / -1;
    margin-top: 2em;
    margin-bottom: -1em;
    font-size: 1.8rem;
}

.approve-info-modal {
    font-size: 2rem;
    padding: 2em;
}

.approve-btn-container {
    display: flex;
    justify-content: center;
}

.lot-badge-fit-content {
    width: -moz-fit-content;
    width: fit-content;
}
</style>
