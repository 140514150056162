<template>
    <p-dropdown
        class="md-container"
        full-width
        v-model="dropdownShown"
        :align="align"
    >
        <template v-slot:activator="{ toggleDropdown }">
            <select-button
                :disabled="disabled"
                :invalid="isInvalid"
                :label="$attrs.placeholder"
                :value-is-empty="!value"
                :view="view"
                @toggle="toggleDropdown"
                @clear="clearSelection"
            >
                <slot :option="value" v-if="value">
                    {{ view ? view(value) : value }}
                </slot>
            </select-button>
            <p-input-errors v-if="touched" :validationErrors="validationErrors"/>
        </template>
        <div class="dropdown--white">
            <div class="select__dropdown-search">
                <p-input
                    class="md-outlined"
                    v-model="search"
                    @input="$emit('update:search-query', search)"
                    type="text"
                    autofocus
                >
                    <template #icon-right>
                        <p-icon icon-name="search" size="1.5em"/>
                    </template>
                </p-input>
            </div>
            <ul class="select__options custom-scroll">
                <p-spinner v-if="options === null"/>
                <li v-else-if="options && options.length === 0"
                    class="select__no-results-placeholder"
                >{{ $t("noMatchingResults") }}
                </li>
                <template v-else>
                    <li
                        v-for="option in filteredOptions"
                        @click="selectOption(option)"
                        class="select__option"
                        :key="getKey(option)"
                    >
                        <slot :option="option">
                            {{ view(option) }}
                        </slot>
                    </li>
                </template>
            </ul>
        </div>
    </p-dropdown>
</template>

<script>
    import { selectWithSearchMixin } from "@/mixins/select-with-search-mixin"
    import SelectButton from "@/base-components/selects/select-button"

    export default {
        name: "PSelectWithSearch",
        components: { SelectButton },
        mixins: [ selectWithSearchMixin ],
        props: {
            align: {
                type: String,
                default: "bottom-right"
            }
        },
        methods: {
            selectOption(option) {
                this.$emit("input", option)
                this.closeDropdown()
            },
            clearSelection() {
                this.$emit("input", null)
            },
        },
        computed: {
            filteredOptions() {
                if (!this.$listeners["update:search-query"]) {
                    return this.options.filter(it => this.view(it).includes(this.search))
                } else {
                    return this.options
                }
            }
        },
    }
</script>
