<template>
    <div class="support-run-container">
        <p-modal v-if="modals.runApprove.opened">
            <run-support-results-overview
                :analyses="resolvedAnalyses"
                :run-name="run.name"
            >
                <button
                    @click="closeModal('runApprove')"
                    class="md-btn-text"
                >{{ $t("buttons.cancel") }}
                </button>
                <button
                    @click="viewPdfInNewTab(`api/support/sequencingRuns/${run.id}/genotypes`)"
                    class="md-btn-outlined royal-blue ml-auto"
                >
                    {{ $t("sampleGenotypes") }}
                    <p-icon icon-name="download" style="margin-left: 0.5rem;"/>
                </button>
                <button
                    :disabled="!hasAnalysesResolved"
                    @click="approveAnalysesResolution"
                    class="md-btn-contained ml-auto green"
                >{{ $t("buttons.approve") }}
                </button>
            </run-support-results-overview>
        </p-modal>
        <template v-if="unresolvedAnalyses.length">
            <analysis-support
                v-if="activeAnalysis"
                class="active-analysis"
                :key="activeAnalysis.id"
                :analysis="activeAnalysis"
            />
            <div
                class="active-analysis-placeholder"
                v-else
            >
                {{ $t("selectAnalysis") }}
            </div>
            <div class="flex" style="flex-flow: column">
                <div class="run-description">
                    <span style="font-size: 2rem">{{ run.name }}</span>
                </div>
                <div
                    class="overflow-auto custom-scroll"
                    style="flex-grow: 1"
                >
                    <table class="analyses-table w-100">
                        <tbody>
                        <tr :key="analysis.id"
                            class="analyses-table--analysis"
                            :class="{ active: activeAnalysis === analysis }"
                            @click="changeActiveAnalysisTo(analysis)"
                            v-for="analysis in unresolvedAnalyses">
                            <td class="row-cell">
                                <div class="flex" style="width: min-content">
                                    <div
                                        :class="quality"
                                        :key="locus"
                                        class="locus-status badge-margin"
                                        v-for="(quality, locus) in lociStatuses(analysis)">
                                        &nbsp;
                                    </div>
                                </div>
                            </td>
                            <td class="row-cell">{{ analysis.name }}</td>
                            <td class="row-cell" style="color: var(--grey-400);">S{{ analysis.runId }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <button class="md-btn-text"
                        style="margin: .5rem 1rem"
                        @click="openModal('runApprove')"
                >{{ $t("processed") }} <span
                    style="white-space: nowrap"
                    class="ml-1">{{ resolvedAnalyses.length }} / {{ analysesForSupport.length }}</span>
                </button>
            </div>
        </template>
        <div
            v-else
            class="absent-analyses-placeholder">
            <span>{{ $t("noMoreWork", [ run.name ]) }}</span>
            <button
                class="md-btn-contained green mt-2"
                style="font-size: 1.8rem"
                @click="openModal('runApprove')"
            >{{ $t("buttons.approve") }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { store } from "@/store/store"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { Locales } from "@/i18n/main"
    import { supportActionNames, supportGetterNames } from "@/store/modules/support"
    import { LOCI } from "@/genotype"
    import { locusQualityForSupport } from "@/utils/support"
    import { viewPdfInNewTab } from "@/helpers"
    import { sse } from "@/endpoints"

    import AnalysisSupport from "./components/analysis"
    import RunSupportResultsOverview from "./components/run-support-results-overview"

    export default {
        name: "SupportRun",
        mixins: [ modalMixin ],
        components: {
            AnalysisSupport,
            RunSupportResultsOverview,
        },
        beforeRouteEnter(to, from, next) {
            store.dispatch(supportActionNames.chooseRunForSupport, { id: to.params.id })
                .then(() => next())
        },
        data() {
            return {
                modals: {
                    runApprove: {
                        opened: false
                    }
                },
                loci: LOCI,
                eventSource: {},
            }
        },
        computed: {
            analyses() {
                return this.$store.state.support.analyses
            },
            allAnalysesResolved() {
                return this.unresolvedAnalyses.length === 0
            },
            hasAnalysesResolved() {
                return this.resolvedAnalyses.length >= 1
            },
            ...mapGetters({
                resolvedAnalyses: supportGetterNames.resolvedAnalyses,
                unresolvedAnalyses: supportGetterNames.unresolvedAnalyses,
                run: supportGetterNames.activeRun,
                analysesForSupport: supportGetterNames.analysesForSupport,
                activeAnalysis: supportGetterNames.activeAnalysis
            }),
        },
        methods: {
            viewPdfInNewTab,
            lociStatuses(analysis) {
                return Object.fromEntries(this.loci.map(locus => [ locus, locusQualityForSupport(analysis, locus) ]))
            },
            changeActiveAnalysisTo(analysis) {
                this.$store.dispatch(supportActionNames.changeActiveAnalysisTo, analysis)
            },
            approveAnalysesResolution() {
                this.$store.dispatch(supportActionNames.approveAnalysesResolution)
                    .then(() => this.$router.push({ name: "support" }))
            },
            setUpEventSource() {
                this.eventSource = new EventSource(
                    sse.notifications,
                    { withCredentials: true }
                )

                this.eventSource.addEventListener("reanalysisCompletion", (event) => {
                    const notification = {
                        data: event.data,
                    }

                    const reAnalysis = this.analyses.find(it => it.id === notification.data)

                    if(reAnalysis) {
                        this.$store.dispatch(supportActionNames.updateAnalysis, reAnalysis)
                    } else {
                        console.warn(`Reanalysis with this ID: ${notification.data} was not found`)
                    }
                })

                return new Promise(resolve =>
                    this.eventSource.addEventListener("open", () => resolve()))
            },
        },
        async mounted() {
            this.setUpEventSource()
        },
        destroyed() {
            if (this.eventSource) {
                this.eventSource.close()
            }
            this.eventSource = null
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    noMoreWork: "All problem samples in run {0} are resolved",
                    processed: "Processed",
                    selectAnalysis: "Select analysis to solve its problems",
                    sampleGenotypes: "Sample genotypes"
                },
                [Locales.RU]: {
                    noMoreWork: "Все проблемные образцы в запуске {0} разрешены",
                    processed: "Обработано",
                    selectAnalysis: "Выберите анализ для решения проблем",
                    sampleGenotypes: "Генотипы образцов"
                },
            }
        }
    }
</script>

<style scoped>
.support-run-container {
    display: grid;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 80% 20%;
}

.analyses-table--analysis {
    cursor: pointer;
}

.analyses-table--analysis:hover,
.analyses-table--analysis.active {
    background-color: hsl(225, 73%, 97%);
}

.analyses-table--analysis .row-cell {
    padding: .5rem 1rem;
}

.run-description {
    padding: 1rem;
}

.locus-status {
    border-radius: 50%;
    width: .8rem;
    height: .8rem;
}

.active-analysis-placeholder,
.active-analysis {
    grid-column: 1 / 2;
    position: relative;
    width: 100%;
    height: 100%;
}

.active-analysis-placeholder {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 5rem;
    font-weight: 600;
    color: var(--grey-700);
    padding: 10rem;
}

.absent-analyses-placeholder {
    grid-column: 1 / -1;
    display: flex;
    flex-flow: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 5rem;
    font-weight: 600;
    color: var(--grey-700);
    padding: 10rem;
}
</style>
