import { NonEmptyArray } from "@/types"

const styles = {
    bold: "font-weight: bold"
}

interface Logger {
    debug(message: string, ...data: any[]): void

    log(message: string, ...data: any[]): void

    warn(message: string, ...data: any[]): void

    error(message: string, ...data: any[]): void
}

export class ConsoleLogger implements Logger {

    debug(message: string, ...data: any[]) {
        console.debug(message, ...data)
    }

    log(message: string, ...data: any[]) {
        console.log(message, ...data)
    }

    error(message: string, ...data: any[]) {
        console.error(message, ...data)
    }

    warn(message: string, ...data: any[]) {
        console.warn(message, ...data)
    }
}

export class StyledLogger implements Logger {
    constructor(
        private readonly logger: Logger,
        private readonly prefix: [prefix: string, ...styles: string[]]
    ) {
    }

    debug(message: string, ...data: any[]) {
        this.logger.debug(...this.prefixMessage(message, ...data))
    }

    log(message: string, ...data: any[]) {
        this.logger.log(...this.prefixMessage(message, ...data))
    }

    error(message: string, ...data: any[]) {
        this.logger.error(...this.prefixMessage(message, ...data))
    }

    warn(message: string, ...data: any[]) {
        this.logger.warn(...this.prefixMessage(message, ...data))
    }

    private prefixMessage(message: string, ...data: any[]): NonEmptyArray<string> {
        return [ `${this.prefix[0]}${message}`, ...this.prefix.slice(1), ...data ]
    }
}

export class PrefixedLogger extends StyledLogger {
    constructor(
        logger: Logger,
        prefix: string
    ) {
        super(logger, [ `%c${prefix}%c: `, styles.bold, "" ])
    }
}


export const globalLogger = new ConsoleLogger()
