<template>
    <div>
        <table v-if="analysisStatusHistoryIsNotEmpty" class="history-table">
            <caption>
                <div class="flex" style="justify-content: center">
                    <h2>{{ analysis.name }}</h2>
                    <AnalysisStatusBadge :analysis-status="analysis.status" class="ml-1"/>
                </div>
            </caption>
            <thead>
            <tr>
                <th class="history-table--header-cell">{{ $t("oldStatus") }}</th>
                <th class="history-table--header-cell">{{ $t("details") }}</th>
                <th class="history-table--header-cell">{{ $t("modifiedBy") }}</th>
                <th class="history-table--header-cell">{{ $t("modifiedAt") }}</th>
            </tr>
            </thead>
            <tbody>
            <!-- TODO Actually we should always have one previous state from processing to some other state, but don't have it now -->
            <tr class="history-table--row"
                :key="priorState.prior_analysis_state_modified_at"
                v-for="(priorState, priorStateIndex) in analysisStatusHistoryInReverseChronologicalOrder"
            >
                <td class="history-table--row-cell">
                    <AnalysisStatusBadge :analysis-status="priorState.prior_analysis_state_analysis_status"/>
                </td>
                <td class="history-table--row-cell">
                    <div v-if="priorState.prior_analysis_state_analysis_status === 'RUNNING'">
                        <b>{{ analysis.configuredPipelineName }}</b>
                    </div>
                    <div v-else-if="priorState.prior_analysis_state_analysis_status === 'IN_SUPPORT'">
                        <div v-if="priorState.prior_analysis_state_maybe_support_request">
                            <b>{{ priorState.prior_analysis_state_maybe_support_request.createdBy }}</b>
                            {{ priorState.prior_analysis_state_maybe_support_request.comment }}
                            <span v-for="locus in priorState.prior_analysis_state_maybe_support_request.loci"
                                  v-bind:key="locus"
                                  class="badge badge-margin red"
                            >
                                {{ locus }}
                            </span>
                        </div>
                    </div>
                    <div v-else-if="priorState.prior_analysis_state_analysis_status === 'AWAITING_APPROVE'">
                        <!-- eslint-disable -->
                        <div v-for="priorStateAnalysisResult in [awaitingApproveAnalysisPriorStateAnalysisResult(priorStateIndex)]">
                            <div v-for="locusGenotypeChanges in [awaitingApproveAnalysisPriorStateGenotypeDiff(priorState, priorStateAnalysisResult)]">
                                <div v-if="locusGenotypeChanges.length" class="locus-genotype-changes-grid">
                                    <template
                                        v-for="locusGenotypeChange in awaitingApproveAnalysisPriorStateGenotypeDiff(priorState, priorStateAnalysisResult)">
                                        <b style="justify-self: center; font-weight: 600">
                                            {{ locusGenotypeChange.locus }}
                                        </b>
                                        <!-- TODO: [@aslepchenkov 11.06.2020] At some point we wanna use final result.annotations, but for the rest situations prior annotations are needed. Fix -->
                                        <Genotype
                                            :annotations="priorStateAnalysisResult.annotations || {}"
                                            :locus-name="locusGenotypeChange.locus"
                                            :genotype="locusGenotypeChange.old"/>
                                        <div>&xrarr;</div>
                                        <Genotype
                                            :annotations="priorState.prior_analysis_state_maybe_resolution_draft.annotations || {}"
                                            :locus-name="locusGenotypeChange.locus"
                                            :genotype="locusGenotypeChange.new"/>
                                    </template>
                                </div>
                                <i v-else
                                   style="color: var(--grey-500)"
                                >
                                    {{ $t("noChanges") }}
                                </i>
                            </div>
                        </div>
                        <!-- eslint-enable -->
                    </div>
                </td>
                <td class="history-table--row-cell">{{ priorState.prior_analysis_state_modified_by }}</td>
                <td class="history-table--row-cell">{{ $d(new Date(priorState.prior_analysis_state_modified_at), "long") }}
                </td>
            </tr>
            </tbody>
        </table>
        <div class="mb-1" style="font-size: 1.8rem" v-else>{{ $t("noChanges") }}</div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import Genotype from "@/components/genotype/genotype"
    import { applyGenotypesPatch, diffGenotypes } from "@/utils/analysis"

    import AnalysisStatusBadge from "./status-badge"

    export default {
        name: "AnalysisHistory",
        components: { Genotype, AnalysisStatusBadge },
        props: {
            analysis: Object
        },
        computed: {
            analysisStatusHistoryInReverseChronologicalOrder() {
                return [ ...this.analysis.priorStates ]
                    .sort(
                        (x, y) => {
                            const [ xModifiedAt, yModifiedAt ] = [
                                new Date(x.prior_analysis_state_modified_at).getTime(),
                                new Date(y.prior_analysis_state_modified_at).getTime(),
                            ]
                            return yModifiedAt - xModifiedAt
                        }
                    )
            },
            analysisStatusHistoryIsNotEmpty() {
                return Object.values(this.analysis.priorStates).length
            }
        },
        methods: {
            awaitingApproveAnalysisPriorStateGenotypeDiff(
                awaitingApproveAnalysisPriorState,
                awaitingApproveAnalysisPriorStateAnalysisResult,
            ) {
                const oldGenotypes = awaitingApproveAnalysisPriorStateAnalysisResult.genotypes
                const newGenotypes =
                    applyGenotypesPatch(
                        oldGenotypes,
                        awaitingApproveAnalysisPriorState.prior_analysis_state_maybe_resolution_draft.genotypes
                    )
                return diffGenotypes(oldGenotypes, newGenotypes)
            },
            awaitingApproveAnalysisPriorStateAnalysisResult(awaitingApproveAnalysisPriorStateIndex) {
                return this.analysisStatusHistoryInReverseChronologicalOrder
                    .slice(awaitingApproveAnalysisPriorStateIndex)
                    .find(priorState => priorState.prior_analysis_state_analysis_result)
                    .prior_analysis_state_analysis_result
            },
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    modifiedBy: "Modified by",
                    modifiedAt: "Modified at",
                    oldStatus: "Old status",
                    newStatus: "New status",
                    noChanges: "No changes were made to this sample",
                    details: "Changes",
                },
                [Locales.RU]: {
                    modifiedBy: "Кем изменен",
                    modifiedAt: "Дата изменения",
                    oldStatus: "Пред. статус",
                    newStatus: "Новый статус",
                    noChanges: "Изменения отсутствуют",
                    details: "Изменения"
                }
            }
        }
    }
</script>

<style scoped>
    .history-table {
        display: block;
        max-height: 70vh;
        overflow-y: auto;
    }

    .history-table--header-cell {
        font-weight: 600;
        padding: 1.5rem 1rem .5rem 1rem;
        border-bottom: 1px solid var(--grey-400);
    }

    .history-table--row:nth-child(odd) {
        background-color: rgba(231, 243, 255, .2);
    }

    .history-table--row:hover {
        background-color: rgba(231, 243, 255, .4);
    }

    .history-table--row-cell {
        padding: 1rem;
        border-bottom: 1px solid #e7f3ff;
        text-align: center;
    }

    .locus-genotype-changes-grid {
        display: grid;
        grid-template-columns: min-content minmax(10em, 15em) min-content minmax(10em, 15em);
        grid-column-gap: 1rem;
    }
</style>
