export function range(n: number): number[] {
    return [ ...new Array(n) ].map((_, i) => i)
}

export function* rangeGenerator(startInclusive: number, stopExclusive: number) {
    for (let i = startInclusive; i < stopExclusive; i++) {
        yield i
    }
}

export function sum(array: number[]) {
    return array.reduce((total, val) => total + val, 0)
}

export function deduplicate<T>(array: T[]): T[] {
    return [ ...new Set(array) ]
}
