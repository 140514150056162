<template>
    <InteractiveDocs :steps="steps"></InteractiveDocs>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { waitFor } from "@/interactive-docs/utils"
    import { circler } from "@/interactive-docs/mixin"

    export default {
        name: "RunDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [
                    generalDescription,
                    metadata,
                    labIssueAnalyses,
                    generalMetricsChart,
                    pcrPlateChart,
                ],
            }
        }
    }

    const generalDescription = {
        name: "generalDescription",
        props: {
            highlighter: Object,
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>

                    Окно лабораторного контроля качества запуска разделено на 3 компонента:
                    <ul class="bullet-list">
                        <li class="bullet-list__element dashed-underline"
                            onMouseenter={() => this.highlighter.moveHighlight([ document.querySelector("#run-details") ])}
                            onMouseleave={() => this.highlighter.clean()}
                        >Общая информация по запуску
                        </li>
                        <li class="bullet-list__element dashed-underline"
                            onMouseenter={() => this.highlighter.moveHighlight([ document.querySelector(".lab-issue-analyses") ])}
                            onMouseleave={() => this.highlighter.clean()}
                        >Список образцов с не оптимальными значениями метрик контроля качества
                        </li>
                        <li class="bullet-list__element dashed-underline"
                            onMouseenter={() => this.highlighter.moveHighlight([ document.querySelector("#charts-container") ])}
                            onMouseleave={() => this.highlighter.clean()}
                        >Графики, позволяющие оценить распределение значений этих метрик для всех образцов запуска
                        </li>
                    </ul>
                </div>
            )
        }
    }

    const metadata = {
        name: "metadata",
        mixins: [ circler ],
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight([ document.querySelector("#run-details") ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>
                    <p>
                        Метаданные запуска включают в себя:
                        <ul class="bullet-list">
                            {
                                [
                                    [ "Имя запуска", ".run-details--run-id" ],
                                    [ "Дата запуска", ".run-details--run-date" ],
                                    [ "Набор реагентов и количество циклов", ".run-details--reagent-kit-details" ],
                                    [ "Общее количество образцов", ".run-details--samples-number" ],
                                    [ "Серии наборов реагентов, с которыми были поставлены образцы", ".run-details--lots" ],
                                ].map(
                                    ([ text, query ]) =>
                                        <li onMouseenter={() => this.circler.moveHighlight([ document.querySelector(query) ])}
                                            onMouseleave={() => this.circler.clean()}
                                            class="bullet-list__element dashed-underline"
                                        >{text}</li>
                                )
                            }
                        </ul>
                    </p>
                    <p>
                        <br/>
                        <span>
                            <span
                                onMouseenter={() => this.circler.moveHighlight([ document.querySelector("#run-details--average-quality-mark") ])}
                                onMouseleave={() => this.circler.clean()}
                            >Индикатор среднего качества прочтений образцов запуска размещён слева от имени запуска.</span>.
                              Подробнее о значении этой метрики можно посмотреть <a href="/docs/" target="_blank"
                                                                                    rel="noreferrer noopener">тут</a>.
                        </span>
                    </p>
                </div>
            )
        },
        destroyed() {
            this.highlighter.clean()
        }
    }


    const labIssueAnalyses = {
        name: "labIssueAnalyses",
        mixins: [ circler ],
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight([ document.querySelector(".lab-issue-analyses") ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>

                    <p>В таблице указаны образцы лабораторные метрики которых выходят из оптимальной зоны.
                        Детальное описание метрик дано <a href="/docs/"
                                                          target="_blank"
                                                          rel="noreferrer noopener">тут</a>.
                    </p>
                    <p>
                        Желтый бейдж - значение метрики в субоптимальной зоне.
                        Красный бейдж - контроль качества по этой метрике не пройден.
                    </p>
                    <br/>
                    <p>
                        В бейджах указано значение метрики.
                        Число, указанное в бейдже, может быть сокращено на 3 разряда, что обозначено буквой <q>k</q>,
                        например, 341000 будет записано как <q>341k</q>.
                    </p>
                    <br/>
                    <p>
                        При наведении на строку с образцом, этот же образец подсвечивается на графике.
                    </p>
                </div>
            )
        }
    }

    const generalMetricsChart = {
        name: "generalMetricsChart",
        mixins: [ circler ],
        props: {
            highlighter: Object,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector("#chart-container"))
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>

                    <p>Первый график показывает распределение образцов по размеру вставки и
                        числу прочтений на образец.
                        <br/>
                        Узнать, какому образцу соответствует точка, можно при наведении на нее.
                        Образцы, лабораторные метрики которых находятся в оптимальной зоне, окрашены в
                        бледно-голубой цвет.
                        <br/>
                        Субоптимальная и зона плохого качества на графиках отмечаются фоном соответственно
                        желтого и красного цвета при наведении на образцы попавшие в эти зоны.
                    </p>
                    <br/>
                    <p>Стоит заметить, что красные образцы могут встретиться и в оптимальной зоне - такие образцы
                        являются контаминированными.<br/>
                        Если образец попал в красную зону, он также может быть контаминирован.
                        Из графика это понять нельзя,
                        придется найти образец в таблице проблемных образцов. (Например образец 52108).
                    </p>
                </div>
            )
        }
    }

    const pcrPlateChart = {
        name: "pcrPlateChart",
        mixins: [ circler ],
        props: {
            highlighter: Object,
        },
        async mounted() {
            const nextChartBtn = await waitFor(() => document.querySelector("#next-chart-btn"))
            nextChartBtn.dispatchEvent(new MouseEvent("click"))
            const pcrChart = await waitFor(() => document.querySelector("#pcr-chart-container"))
            this.highlighter.moveHighlight([ pcrChart ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>
                    <p>
                        Второй график показывает расположение образцов на планшетах, при условии что образцы
                        раскапывались по порядку: колонками или рядами (образцы идут друг за другом в порядке увеличения
                        их идентификатора в запуске).
                        Переключить направление с колонок на ряды можно с помощью&nbsp;
                        <span class="dashed-underline"
                              onMouseenter={() => this.circler.moveHighlight([ document.querySelector(".orientation-toggler") ])}
                              onMouseleave={() => this.circler.clean()}
                        >управляющего элемента</span>.
                    </p>
                    <br/>
                    <p>
                        Лунки с образцами, которые не были загружены в приложение, окрашены в серый цвет.
                        Например, лунки 11K и 11M.
                    </p>
                    <br/>
                    <p>
                        Данная схема может помочь определить взаимное расположение проблемных образцов в запуске.
                    </p>
                </div>
            )
        },
        destroyed() {
            document.querySelector("#prev-chart-btn").dispatchEvent(new MouseEvent("click"))
        }
    }
</script>
