import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { sum } from "@/extensions/array-extensions"
import Vue from "vue"
import { last, meanBy, takeRight } from "lodash"
import { fillFalsyValues } from "@/extensions/object-extensions"
import { compareNumbersDescending } from "@/helpers"
import { globalLogger, PrefixedLogger } from "@/logger"

type TaskId = string
type TasksIdMap = Record<TaskId, [number, TasksIdMap | null ]>

interface BackgroundTasks {
    tasks: Task[]
    tasksIdMap: TasksIdMap,
    taskChain: Promise<any>
    currentTask: Task | null,
}

export interface Task {
    id: TaskId
    description: string
    progress: number
    subTasks: Task[],
    remainingTime: number
    _timePoints: Array<{
        progress: number
        speed: number
        time: number
    }>
}

const logger = new PrefixedLogger(globalLogger, "Background tasks")

function initialState(): BackgroundTasks {
    return {
        tasks: [],
        tasksIdMap: {},
        taskChain: Promise.resolve(),
        currentTask: null,
    }
}

function getTask(state: BackgroundTasks, fullPath: TaskId[]): Task | undefined {
    const indicesPath = convertToIndicesPath(state.tasksIdMap, fullPath)
    return indicesPath.reduce<Task | undefined>(
        (parentTask, index) => parentTask?.subTasks[index],
        <Task>{ subTasks: state.tasks }
    )
}

function convertToIndicesPath(tasksIdMap: TasksIdMap | null, fullPath: TaskId[]) {
    const [ indicesPath ] = fullPath.reduce(
        ([ indices, parentMap ], id) => {
            if (parentMap) {
                const [ index, childMap ] = parentMap[id]
                return [ [ ...indices, index ], childMap ]
            } else {
                return [ indices, parentMap ]
            }
        },
        [ [] as number[], tasksIdMap ]
    )
    return indicesPath
}

function parentPath(path: string[]): string[] | null {
    return path.length > 1
        ? path.slice(0, -1)
        : null
}

const mutations: MutationTree<BackgroundTasks> = {
    _reorderTasks(state, reorder: (tasks: Task[]) => Task[]) {
        logger.debug("reordering tasks")
        const reorderedTasks = reorder(state.tasks)
        state.tasksIdMap = convertToTaskIdMap(reorderedTasks)
        state.tasks = reorderedTasks
    },
    _addTask(state, { path, task }) {
        logger.debug("adding task", { path, task })
        const immediateParent = getTask(state, path)
        immediateParent
            ? Vue.set(immediateParent.subTasks, immediateParent.subTasks.length, task)
            : logger.error(`Parent task ${path} not found`)
        state.tasksIdMap = convertToTaskIdMap(state.tasks)
    },
    _updateTask(state, { fullPath, progress }) {
        const timePointsToConsider = 5
        const task = getTask(state, fullPath)
        if (task) {
            /*
             Estimate based on update can be corrupted if updates are rare, but
             I think this will not happen. But in this case I will rewrite this with setInterval watcher.
            */
            const lastTimePoint = last(task._timePoints)
            /*
             I start recording time points after first update, cause I wanna encapsulate all time points
             related logic here (in store) and I don't know when task is started.
             By now tasks are launched from the outside.
            */
            const time = Date.now()
            let remainingTime = Number.POSITIVE_INFINITY
            let speed = 0

            if (lastTimePoint) {
                speed = (progress - lastTimePoint.progress) / (time - lastTimePoint.time)
                const meanSpeed = meanBy(takeRight(task._timePoints, timePointsToConsider), it => it.speed)
                /*
                 I got Infinity here any way (except for the case when task is done, but remaining time is not shown when task is done)
                 cause (not 0)/0 = infinity
                 But it's better to explicitly avoid zero division. See https://stackoverflow.com/a/18838347/6540091
                */
                remainingTime = meanSpeed
                    ? (100 - task.progress) / meanSpeed
                    : Number.POSITIVE_INFINITY
            }

            Vue.set(task, "remainingTime", remainingTime)
            Vue.set(task, "progress", progress)

            task._timePoints.push({
                speed,
                progress,
                time
            })

            /*
             Optimization time. I wanna avoid large arrays cause only need timePointsToConsider elements,
             but it seems to me that removing first element every time will be at cost. But I haven't checked this.
             Feel free to check and remove "optimization"
            */
            if (task._timePoints.length > 50) {
                task._timePoints = takeRight(task._timePoints, timePointsToConsider)
            }
        } else {
            logger.error(`Task ${fullPath} not found`)
        }
    },
    _updateCompositeTask(state, { fullPath }) {
        const task = getTask(state, fullPath)
        if (task) {
            const subTasksWithFiniteEstimates = task.subTasks.map(it => it.remainingTime)
                .filter(it => it !== Number.POSITIVE_INFINITY)
            // 0/0 is NaN, see - https://stackoverflow.com/a/18838347/6540091
            const remainingTime =
                subTasksWithFiniteEstimates.length
                    ? (sum(subTasksWithFiniteEstimates) / subTasksWithFiniteEstimates.length) * task.subTasks.length
                    : Number.POSITIVE_INFINITY

            Vue.set(task, "remainingTime", remainingTime)
            Vue.set(
                task,
                "progress",
                sum(task.subTasks.map(it => it.progress)) / task.subTasks.length
            )
        } else {
            logger.error(`Task ${fullPath} not found`)
        }

    },
    _removeTask(state, payload: { fullPath: TaskId[] }) {
        const parentTask = getTask(state, parentPath(payload.fullPath) ?? [])

        parentTask
            ? Vue.delete(
                parentTask.subTasks,
                last(convertToIndicesPath(state.tasksIdMap, payload.fullPath))!
            )
            : logger.error(`Task ${(payload.fullPath)} not found`)
        state.tasksIdMap = convertToTaskIdMap(state.tasks)
    },
    resetState(state) {
        logger.debug("State reset")
        Object.assign(state, initialState())
    }
}

export const backgroundTasksActionNames = {
    addTask: "",
    updateTask: "",
    removeTask: "",
    reorderTasks: "",
}

export const backgroundTasksGetterNames = {
    generalProgress: "",
    tasks: "",
    tasksCount: ""
}

const fillNameValues = (obj: {}) => fillFalsyValues(key => `backgroundTasks/${key}`, obj)
fillNameValues(backgroundTasksActionNames)
fillNameValues(backgroundTasksGetterNames)

const actions: ActionTree<BackgroundTasks, any> = {
    [backgroundTasksActionNames.addTask]({ commit }, { path, id, description }) {
        const task: Task = {
            id,
            description,
            subTasks: [],
            progress: 0,
            remainingTime: Number.POSITIVE_INFINITY,
            _timePoints: []
        }
        commit("_addTask", { path, task })
        return task.id
    },
    [backgroundTasksActionNames.updateTask]({ commit }, { fullPath, progress }) {
        commit("_updateTask", { fullPath, progress })
        let parentToUpdate = parentPath(fullPath)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const updateParent = (fullPath: string[]) => commit("_updateCompositeTask", { fullPath })
        while (parentToUpdate) {
            updateParent(parentToUpdate)
            parentToUpdate = parentPath(parentToUpdate)
        }
    },
    [backgroundTasksActionNames.removeTask]({ commit }, { fullPath }) {
        commit("_removeTask", { fullPath })
    },
    [backgroundTasksActionNames.reorderTasks]({ commit }, reorder: (tasks: Task[]) => Task[]) {
        commit("_reorderTasks", reorder)
    }
}

const getters: GetterTree<BackgroundTasks, any> = {
    [backgroundTasksGetterNames.tasksCount](state: BackgroundTasks) {
        return state.tasks.length
    },
    [backgroundTasksGetterNames.tasks](state) {
        return state.tasks
    },
    [backgroundTasksGetterNames.generalProgress](state) {
        return sum(state.tasks.map(it => it.progress)) / state.tasks.length
    },
}

export function orderedTasksByProgress(tasks: Task[]): Task[] {
    const taskWithChildrenOrdered: Task[] = tasks.map(task => {
        return "subTasks" in task
            ? { ...task, subTasks: orderedTasksByProgress(task.subTasks) } as Task
            : task
    })

    return taskWithChildrenOrdered.sort((a, b) => {
        // Preserve order of completed tasks
        if (b.progress === 100 && a.progress === 100) {
            return 0
        }
        // Move completed tasks to the end
        if (b.progress === 100) {
            return -1
        }
        if (a.progress === 100) {
            return 1
        }
        return compareNumbersDescending(a.progress, b.progress)
    })
}

function convertToTaskIdMap(tasks: Task[]): TasksIdMap {
    return tasks.reduce(
        (taskIdMap, task, i) => ({
            ...taskIdMap,
            [task.id]: [
                i,
                task.subTasks.length === 0
                    ? null
                    : convertToTaskIdMap(task.subTasks),
            ]
        }),
        {} as TasksIdMap
    )
}

export const backgroundTasks: Module<BackgroundTasks, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
}
