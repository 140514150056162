import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from "d3-scale"
import { select, Selection } from "d3-selection"
import { axisBottom, axisLeft } from "d3-axis"
import { BaseChart, Margin } from "@/charts/base-chart"
import {
    addTooltip,
    calculateBoxPlotData,
    CIRCLE_RADIUS,
    deemphasizeDataPoint,
    emphasizeDataPoint,
} from "@/charts/utils"
import VueI18n from "vue-i18n"
import { max, min } from "d3-array"
import { LOCI } from "@/genotype"
import { Analysis } from "@/utils/analysis"


interface BoxPlotData {
    q1: number
    median: number
    q3: number
    interQuantileRange: number
    min: number
    max: number
}

interface Chart {
    chartGroup: Selection<SVGGElement, unknown, any, any>
    chartWidth: number
    chartHeight: number
    x: ScaleBand<string>
    y: ScaleLinear<number, number>
    margin: Margin

    resize(): void

    onLocaleChange(): void
}

export function drawChart(
    data: {
        sameRunAnalyses: Array<Analysis>,
        currentAnalysis: Analysis
    },
    containerId: string,
    i18n: VueI18n
) {
    const baseChart = new BaseChart(containerId)
    const processedData = Object.entries(LOCI.reduce((acc, locus) => {
        const locusData = data.sameRunAnalyses.map(it => it.result.qualityMetrics.targetLociCoverage[locus])
        acc[locus] = calculateBoxPlotData(locusData)
        return acc
    }, <{ [key: string]: BoxPlotData }>{}))

    const maxCoverage = Math.max(
        max(processedData.map(([ _, boxPlotData ]) => boxPlotData.max))!,
        max(Object.values(data.currentAnalysis.result.qualityMetrics.targetLociCoverage))!
    )

    const minCoverage = Math.min(
        min(processedData.map(([ _, boxPlotData ]) => boxPlotData.min))!,
        min(Object.values(data.currentAnalysis.result.qualityMetrics.targetLociCoverage))!
    )

    const coverageRange = maxCoverage - minCoverage

    const x = scaleBand()
        .domain(LOCI)
        .range([ 0, baseChart.chartWidth ])
        .paddingInner(1)
        .paddingOuter(.5)
    const y = scaleLinear()
        .domain([ minCoverage - coverageRange * .1, maxCoverage + coverageRange * .1 ])
        .range([ baseChart.chartHeight, 0 ])

    function onLocaleChange() {
        // Either TS or I is idiot. TranslateResult is already alias for string
        select("#loci-coverage-chart-title").text(<string>i18n.t("analysisMetrics.locusCoverage"))
    }

    const chart: Chart = {
        ...baseChart,
        x,
        y,
        onLocaleChange
    }

    addAxes(chart)
    addBoxplots(chart, processedData)
    addTitle(baseChart)
    onLocaleChange()
    const tooltip = addTooltip(select(containerId))

    const mouseenter = (event: MouseEvent, d: [string, number]) => {
        emphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        tooltip
            .html(d[1].toString())
            .style("display", "block")
            .style("left", `${event.clientX + 20}px`)
            .style("top", `${event.clientY}px`)
    }

    const mouseleave = (event: MouseEvent) => {
        deemphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        return tooltip
            .style("display", "none")
    }

    baseChart.chartGroup
        .selectAll("point")
        .data(Object.entries(data.currentAnalysis.result.qualityMetrics.targetLociCoverage))
        .enter()
        .append("circle")
        .attr("cx", d => x(d[0])!)
        .attr("cy", d => y(d[1])!)
        .attr("r", CIRCLE_RADIUS)
        .style("fill", "royalblue")
        // @ts-ignore d3 types are stale
        .on("mouseenter", mouseenter)
        // @ts-ignore d3 types are stale
        .on("mouseleave", mouseleave)

    return chart
}

function addAxes(chart: Chart) {
    const { chartGroup, chartHeight, x, y } = chart
    chartGroup.append("g")
        .attr("transform", `translate(0,${chartHeight})`)
        .attr("class", "chart-axis")
        .call(axisBottom(x).tickSizeOuter(0))

    chartGroup.append("g")
        .attr("class", "chart-axis")
        .call(axisLeft(y).ticks(5).tickSizeOuter(0))
}

function addBoxplots(chart: Chart, data: Array<[ string, BoxPlotData ]>) {
    const { chartGroup, x, y } = chart
    chartGroup
        .selectAll("vertLines")
        .data(data)
        .enter()
        .append("line")
        .attr("x1", d => x(d[0])!)
        .attr("x2", d => x(d[0])!)
        .attr("y1", d => y(d[1].min)!)
        .attr("y2", d => y(d[1].max)!)
        .attr("stroke", "black")
        .style("width", 40)

    const boxWidth = 33
    chartGroup
        .selectAll("boxes")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", d => x(d[0])! - boxWidth / 2)
        .attr("y", d => y(d[1].q3)!)
        .attr("height", d => y(d[1].q1)! - y(d[1].q3)!)
        .attr("width", boxWidth)
        .attr("stroke", "black")
        .style("fill", "var(--grey-400)")

    chartGroup
        .selectAll("medianLines")
        .data(data)
        .enter()
        .append("line")
        .attr("x1", d => x(d[0])! - boxWidth / 2)
        .attr("x2", d => x(d[0])! + boxWidth / 2)
        .attr("y1", d => y(d[1].median)!)
        .attr("y2", d => y(d[1].median)!)
        .attr("stroke", "black")
        .style("width", 80)

}

function addTitle(chart: BaseChart) {
    const { svg, containerWidth, margin } = chart
    svg.append("text")
        .attr("id", "loci-coverage-chart-title")
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "middle")
        .attr("x", containerWidth / 2 + 8)
        .attr("y", margin.top / 2)
        .style("font-weight", "600")
        .style("font-size", "1.7rem")
}

