<template>
    <div class="locus-tab">
        <p-modal v-if="modals.ambiguousGenotypes.opened">
            <ambiguous-genotype-selection-modal
                :active-locus="activeLocus"
                :annotations="annotations"
                :initially-assigned-genotype="tempGenotypeAtActiveLocus"
                :related-ambiguities="tempGenotypeAtActiveLocusRelatedAmbiguities"
                @cancel="cancelKnownAmbiguitySelection"
                @confirm="confirmKnownAmbiguitySelection"
            />
        </p-modal>
        <problems
            class="problems w-100"
            :analysis="analysis"
            :locus="activeLocus"
        />
        <allele-panel
            class="allele-panel"
            :allele-colors="alleleColors"
            :allele-hints="alleleHints"
            :selected-allele="selectedAllele"
            :temp-genotype-at-active-locus="tempGenotypeAtActiveLocus"
            :toggle-allele-in-new-genotype="toggleAlleleInNewGenotype"
            :select-allele="selectAllele"
            :save-existing-allele="saveExistingAllele"
        />
        <div class="tool-results custom-scroll">
            <section class="tool-card" style="grid-area: hd">
                <hla-hd-results
                    :target-allele="selectedAllele"
                    :results="toolResults['hla-hd'][activeLocus]"
                />
            </section>
            <section class="tool-card" style="grid-area: hisat">
                <hisat-results
                    :target-allele="selectedAllele"
                    :results="toolResults['hisat'][activeLocus]"
                />
            </section>
            <section class="tool-card" style="grid-area: kourami">
                <kourami-results
                    :target-allele="selectedAllele"
                    :results="toolResults['kourami'][activeLocus]"
                />
            </section>
        </div>
        <genotype-editor
            class="genotype-editor"
            :add-part-to-new-genotype="addPartToNewGenotype"
            :allele-colors="alleleColors"
            :analysis="analysis"
            :active-locus="activeLocus"
            :can-add-new-genotype="canAddNewGenotype"
            :toggle-new-allele="toggleNewAllele"
            :clear-genotype="clearGenotype"
            :reset-genotype="resetGenotype"
            :save-edited-genotype="saveEditedGenotype"
            :start-known-ambiguity-selection="startKnownAmbiguitySelection"
            :temp-genotype-at-active-locus="tempGenotypeAtActiveLocus"
            :temp-genotype-at-active-locus-has-related-ambiguities="tempGenotypeAtActiveLocusHasRelatedAmbiguities"
            :temp-new-allele-at-active-locus="tempNewAlleleAtActiveLocus"
        />
    </div>
</template>
<script>
    import { findRelatedKnownAmbiguousGenotypes } from "@/utils/ambiguities"
    import { genotypesEqual, NEW_ALLELE_PLACEHOLDER } from "@/genotype"
    import { supportActionNames, supportMutationNames } from "@/store/modules/support"
    import { collectAlleleHints, genotypingResolutionToAlleleResolution } from "@/utils/support"
    import { nullable } from "@/helpers"
    import { modalMixin } from "@/mixins/modal-mixin"

    import AmbiguousGenotypeSelectionModal from "./ambiguous-genotype-selection-modal"
    import AllelePanel from "./allele-panel"
    import GenotypeEditor from "./genotype-editor"
    import Problems from "./problems"
    import HisatResults from "./hisat-results"
    import HlaHdResults from "./hla-hd-results"
    import KouramiResults from "./kourami-results"


    export default {
        name: "locus-tab",
        components: { AmbiguousGenotypeSelectionModal, AllelePanel, GenotypeEditor, HisatResults, HlaHdResults, KouramiResults, Problems },
        mixins: [ modalMixin ],
        props: {
            activeLocus: {
                type: String,
                required: true
            },
            analysis: {
                type: Object,
                required: true
            },
            toolResults: {
                type: Object,
                required: true
            },
            knownAmbiguities: {
                validator: nullable("object"),
                required: true
            },
            annotations: {
                required: true,
                validator: nullable("object")
            }
        },
        data() {
            return {
                alleleColors: [
                    "#80DEEA",
                    "#B39DDB",
                    "#CE93D8",
                    "#E6EE9C",
                    "#BCAAA4",
                    "#FFF59D",
                    "#df8181",
                    "#63b663",
                ],
                selectedAllele: null,
                modals: {
                    ambiguousGenotypes: {
                        opened: false
                    }
                }
            }
        },
        computed: {
            tempGenotypeAtActiveLocusHasRelatedAmbiguities() {
                return this.tempGenotypeAtActiveLocusRelatedAmbiguities.length > 0
            },
            tempGenotypeAtActiveLocusRelatedAmbiguities() {
                return this.knownAmbiguities
                    ? findRelatedKnownAmbiguousGenotypes(
                        this.knownAmbiguities.knownGenotypeAmbiguities[this.activeLocus],
                        this.tempGenotypeAtActiveLocus
                    ).filter(it => !genotypesEqual(it, this.tempGenotypeAtActiveLocus))
                    : []
            },
            tempGenotypeAtActiveLocus() {
                return this.$store.state.support.tempGenotypes[this.analysis.id][this.activeLocus]
            },
            tempNewAlleleAtActiveLocus() {
                return this.$store.state.support.tempNewAllele[this.analysis.id] ?? {}
            },
            canAddNewGenotype() {
                return this.tempGenotypeAtActiveLocus.length < (this.alleleColors.length / 2)
            },
            manuallyCreatedAllelesAtActiveLocus() {
                return this.$store.state.support.manuallyCreatedAlleles[this.analysis.id]
                    ? this.$store.state.support.manuallyCreatedAlleles[this.analysis.id][this.activeLocus]
                    : null
            },
            alleleHints() {
                return this.toolResults && this.knownAmbiguities
                    ? collectAlleleHints(
                        this.toolResults,
                        this.activeLocus,
                        this.manuallyCreatedAllelesAtActiveLocus,
                        this.knownAmbiguities,
                        genotypingResolutionToAlleleResolution(this.analysis.configuredPipelineGenotypingResolution)
                    )
                    : []
            },
        },
        methods: {
            saveExistingAllele(newAllele, index) {
                this.$store.commit(
                    supportMutationNames.addManuallyCreatedAllele,
                    {
                        analysisId: this.analysis.id,
                        locus: this.activeLocus,
                        allele: newAllele.value
                    }
                )
                this.toggleAlleleInNewGenotype(newAllele.value, Math.floor(index / 2), index - 2 * Math.floor(index / 2))
            },
            clearGenotype() {
                this.$store.commit(supportMutationNames.clearNewGenotype, {
                    analysis: this.analysis,
                    locus: this.activeLocus
                })
            },
            resetGenotype() {
                this.$store.commit(supportMutationNames.resetNewGenotype, {
                    analysis: this.analysis,
                    locus: this.activeLocus
                })
            },
            selectAllele(allele) {
                this.selectedAllele = this.selectedAllele !== allele ? allele : null
            },
            toggleAlleleInNewGenotype(allele, genotypePartIndex, alleleIndex) {
                this.$store.commit(
                    supportMutationNames.toggleAlleleInNewGenotype,
                    {
                        analysis: this.analysis,
                        locus: this.activeLocus,
                        allele,
                        genotypePartIndex, alleleIndex
                    }
                )
            },
            /*
             *   FIXME: [@bbatanov 03.03.21] Сan be simplified
             */
            toggleNewAllele(newAllele, genotypePartIndex, alleleIndex) {
                let selectGen = genotypePartIndex * 2 + alleleIndex
                let alleleNew = {
                    manuallyCreatedAlleles: {
                        [this.activeLocus]: [{
                            referenceAllele: newAllele.referenceAllele,
                            geneticVariants: newAllele.geneticVariants
                        }]
                    },
                    sampleAlleles: {
                        [this.activeLocus]: {
                            [genotypePartIndex]: {
                                [alleleIndex]: selectGen
                            }
                        }
                    }
                }

                this.$store.commit(
                    supportMutationNames.toggleNewAllele,
                    {
                        analysis: this.analysis,
                        locus: this.activeLocus,
                        newAllele: alleleNew,
                        genotypePartIndex, alleleIndex
                    }
                )
                this.toggleAlleleInNewGenotype(NEW_ALLELE_PLACEHOLDER, Math.floor(selectGen / 2), selectGen - 2 * Math.floor(selectGen / 2))
            },
            saveEditedGenotype() {
                this.$store.dispatch(
                    supportActionNames.saveEditedGenotype,
                    {
                        analysis: this.analysis,
                        locus: this.activeLocus
                    }
                )
            },
            addPartToNewGenotype() {
                this.$store.commit(
                    supportMutationNames.addPartToNewGenotype,
                    { analysis: this.analysis, locus: this.activeLocus }
                )
            },
            startKnownAmbiguitySelection() {
                this.openModal("ambiguousGenotypes")
            },
            cancelKnownAmbiguitySelection() {
                this.closeModal("ambiguousGenotypes")
            },
            confirmKnownAmbiguitySelection(selectedGenotype) {
                this.$store.commit(
                    supportMutationNames.setNewGenotype,
                    { analysis: this.analysis, locus: this.activeLocus, newGenotype: selectedGenotype }
                )
                this.saveEditedGenotype()
                this.closeModal("ambiguousGenotypes")
            },
        },
        filters: {
            problem(val) {
                switch (val) {
                    case "locusCoverage":
                        return "LC"
                    case "genotypesNumber":
                        return "GN"
                    case "allelesNumber":
                        return "AN"
                    case "novelAlleles":
                        return "NA"
                    default:
                        throw new Error(`Unknown problem ${val}`)
                }
            }
        },
    }
</script>

<style scoped>

.locus-tab {
    display: grid;
    overflow: auto;
    grid-template-columns: minmax(40%, 2fr) minmax(40%, 2fr);
    grid-template-rows: min-content 1fr auto;
    grid-row-gap: 1rem;
    height: 100%;
    grid-column-gap: 5rem;
    align-items: start;
}

.allele-panel {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}

.tool-results {
    grid-column: 2 / -1;
    grid-row: 2 / 3;
    overflow: auto;
    height: 100%;
}

.tool-card {
    margin: 3rem 1rem;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
    border-radius: 4px;
    overflow: hidden;
}

.tool-card:first-child {
    margin-top: 1rem;
}


.problems {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;
}

.genotype-editor {
    grid-column: 1 / -1;
    width: 100%;
    height: 100%;
    border-top: 1px solid var(--grey-200);
    padding: 2rem 2rem 1rem 2rem;
}
</style>
