<template>
    <div class="genotype-editor">
        <p-modal v-if="modals.createNewAllele.opened">
            <create-new-allele-modal
                :genotype="tempGenotypeAtActiveLocus"
                :allele-colors="alleleColors"
                :analysis="analysis"
                :genotype-select="selectGenotype"
                :allele-index="alleleIndex"
                :active-locus="activeLocus"
                :genotype-part-index="genotypePartIndex"
                :close-modal="closeModalNewAllele"
                :toggle-new-allele="toggleManuallyNewAllele"
            />
        </p-modal>
        <div class="overflow-auto custom-scroll mr-1 h-100" style="display: flex; flex-wrap: wrap;">
            <!-- eslint-disable-next-line-->
            <span class="monospace bold flex" style="padding: .5rem 0rem;"
                  v-for="(genotypePart, i) in tempGenotypeAtActiveLocus">
                    <p-with-tooltip fixed position="top-left">
                        <template>
                            <button :style="{ backgroundColor: alleleColors[2 * i], border: `2px solid ${alleleColors[2 * i]}` }"
                                    class="allele"
                                    :disabled="isDisableCreateNewAllele(i)"
                                    @click="openModalNewAllele(i, 0)"
                                ><span class="allele--text">{{ genotypePart[0].toGLString() }}</span>
                            </button>
                            <span class="mh-1">+</span>
                        </template>
                        <template #tooltip>
                            <template v-if="getIsNewAllele(i, 0)">
                                <div class="tooltip-black overflow-auto custom-scroll allele-tooltip">
                                    <p>{{$t("referenceAllele")+ ": " + tempNewAlleleAtActiveLocus.manuallyCreatedAlleles[activeLocus][getIndexNewAllele(i, 0)].referenceAllele}}</p>
                                    <p v-for="(variant, index) in tempNewAlleleAtActiveLocus.manuallyCreatedAlleles[activeLocus][getIndexNewAllele(i, 0)].geneticVariants" :key="index">
                                        {{`${variant.region} ${variant.genomicHgvs} ${variant.proteinHgvs}`}}
                                    </p>
                                </div>
                            </template>
                            <template v-else>
                                <p class="tooltip-black">{{ $t("createNewAllele") }}</p>
                            </template>
                        </template>
                    </p-with-tooltip>
                    <p-with-tooltip fixed position="top-left">
                        <template>
                            <button :style="{ backgroundColor: alleleColors[2 * i + 1], border: `2px solid ${alleleColors[2 * i + 1]}` }"
                                    class="allele"
                                    :disabled="isDisableCreateNewAllele(i)"
                                    @click="openModalNewAllele(i, 1)"
                                ><span class="allele--text">{{ genotypePart[1].toGLString() }}</span>
                            </button>
                            <span v-if="i < tempGenotypeAtActiveLocus.length - 1"
                                  class="mh-1 monospace bold"> |</span>
                        </template>
                        <template #tooltip>
                            <template v-if="getIsNewAllele(i, 1)">
                                <div class="tooltip-black overflow-auto custom-scroll allele-tooltip">
                                    <p>{{$t("referenceAllele")+ ": " + tempNewAlleleAtActiveLocus.manuallyCreatedAlleles[activeLocus][getIndexNewAllele(i, 1)].referenceAllele}}</p>
                                    <p v-for="(variant, index) in tempNewAlleleAtActiveLocus.manuallyCreatedAlleles[activeLocus][getIndexNewAllele(i, 1)].geneticVariants" :key="index">
                                        {{`${variant.region} ${variant.genomicHgvs} ${variant.proteinHgvs}`}}
                                    </p>
                                </div>
                            </template>
                            <template v-else>
                                <p class="tooltip-black">{{ $t("createNewAllele") }}</p>
                            </template>
                        </template>
                    </p-with-tooltip>
            </span>
            <button
                class="md-btn-text"
                v-show="canAddNewGenotype"
                @click="addPartToNewGenotype"
                style="font-size: 1.2rem; display: inline-block"
            >
                <span class="mr-1 monospace bold">|</span>
                {{ $t("addNewGenotype") }}
            </button>
        </div>
        <button
            v-if="tempGenotypeAtActiveLocusHasRelatedAmbiguities"
            class="md-btn-contained ml-auto flex-no-shrink"
            style="background-color: var(--parseq-orange);"
            @click="startKnownAmbiguitySelection"
        >
            {{ $t("buttons.select") }}
        </button>
        <button
            v-else
            class="md-btn-contained green ml-auto flex-no-shrink"
            @click="saveEditedGenotype"
        >
            {{ $t("buttons.save") }}
        </button>
        <p-icon-button
            class="ml-1 flex-no-shrink"
            size="3rem"
            @click="clearGenotype"
            icon-name="bin"
        />
        <p-icon-button
            class="ml-1 flex-no-shrink"
            size="3rem"
            @click="resetGenotype"
            icon-name="undo"
        />
    </div>
</template>
<script>
    import { Locales } from "@/i18n/main"
    import { modalMixin } from "@/mixins/modal-mixin"

    import CreateNewAlleleModal from "./create-new-allele-modal"

    export default {
        name: "genotype-editor",
        mixins: [ modalMixin ],
        components: { CreateNewAlleleModal },
        props: {
            addPartToNewGenotype: {
                type: Function,
                required: true
            },
            activeLocus: {
                type: String,
                required: true
            },
            alleleColors: {
                type: Array,
                required: true
            },
            analysis: {
                type: Object,
                required: true
            },
            canAddNewGenotype: {
                type: Boolean,
                required: true
            },
            toggleNewAllele: {
                type: Function,
                required: true
            },
            clearGenotype: {
                type: Function,
                required: true
            },
            resetGenotype: {
                type: Function,
                required: true
            },
            saveEditedGenotype: {
                type: Function,
                required: true
            },
            startKnownAmbiguitySelection: {
                type: Function,
                required: true
            },
            tempGenotypeAtActiveLocus: {
                type: Object,
                required: true
            },
            tempGenotypeAtActiveLocusHasRelatedAmbiguities: {
                type: Boolean,
                required: true
            },
            tempNewAlleleAtActiveLocus: {
                type: Object,
            }
        },
        data() {
            return {
                modals: {
                    createNewAllele: {
                        opened: false,
                    }
                },
                selectGenotype: null,
                alleleIndex: null,
                genotypePartIndex: null
            }
        },
        methods: {
            openModalNewAllele(genotypePartIndex, alleleIndex) {
                this.selectGenotype = genotypePartIndex * 2 + alleleIndex
                this.alleleIndex = alleleIndex
                this.genotypePartIndex = genotypePartIndex
                this.openModal("createNewAllele")
            },
            getIsNewAllele(genotypePartIndex, alleleIndex) {
                if(!this.tempNewAlleleAtActiveLocus.sampleAlleles[this.activeLocus]) return false
                if(this.tempNewAlleleAtActiveLocus.sampleAlleles[this.activeLocus]?.[genotypePartIndex]?.[alleleIndex] !== undefined) {
                    return true
                }
                return false
            },
            getIndexNewAllele(genotypePartIndex, alleleIndex) {
                if(!this.tempNewAlleleAtActiveLocus.sampleAlleles[this.activeLocus][genotypePartIndex][alleleIndex]) return 0
                return this.tempNewAlleleAtActiveLocus.sampleAlleles[this.activeLocus][genotypePartIndex][alleleIndex]
            },
            /*
            *   FIXME: [@bbatanov 03.03.21] I would like to implement this not by validation, but by sorting.
            *   so that new alleles are sorted before sending to the server in the same way as the genotype
            */
            isDisableCreateNewAllele(genotypePartIndex) {
                let previousGenotypePartIndex = genotypePartIndex - 1
                if (previousGenotypePartIndex === -1) {
                    return false
                }

                if (this.tempGenotypeAtActiveLocus.genotypeParts[previousGenotypePartIndex][0].isUndefined
                    && this.tempGenotypeAtActiveLocus.genotypeParts[previousGenotypePartIndex][1].isUndefined) {
                    return true
                }
                return false
            },
            closeModalNewAllele() {
                this.closeModal("createNewAllele")
            },
            toggleManuallyNewAllele(newManuallyAllele, genotypeSelect, alleleIndex) {
                this.toggleNewAllele(newManuallyAllele, genotypeSelect, alleleIndex)
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    addNewGenotype: "Добавить генотип",
                    referenceAllele: "Референсная аллель",
                    createNewAllele: "Создать новую аллель",
                },
                [Locales.EN]: {
                    addNewGenotype: "Add new genotype",
                    referenceAllele: "Reference allele",
                    createNewAllele: "Create a new allele",

                }
            }
        }
    }
</script>

<style scoped>

.genotype-editor {
    overflow: auto;
    display: flex;
    align-items: center;
}

.allele {
    border-radius: 4px;
    padding: .5rem 1rem;
    border: 2px solid;
    transition: all .2s;
}

.allele.selected,
.allele:hover {
    background-color: white;
}

.allele.selected .allele--text,
.allele:hover .allele--text {
    color: black;
}

.allele-tooltip {
    max-width: 60rem;
    height: fit-content;
    max-height: 7rem;
}

</style>
