<template>
    <div>
        <table class="analyses-table">
            <thead>
            <tr>
                <th class="analyses-table--th">
                    <div class="traffic-lights th-content">
                        <span class="badge red mr-1">{{ analyses.filter(it => getLabMetricsQuality(it) === "red").length }}</span>
                        <span class="badge yellow">{{ analyses.filter(it => getLabMetricsQuality(it) === "yellow").length }}</span>
                    </div>
                </th>
                <th class="analyses-table--th">
                    <div class="th-content">{{ $t("analysisMetrics.totalReads") }}</div>
                </th>
                <th class="analyses-table--th">
                    <div class="th-content">{{ $t("analysisMetrics.insertSize") }}</div>
                </th>
                <th class="analyses-table--th">
                    <div class="th-content">&nbsp;</div>
                </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="analysis in analyses">
                <tr :class="getLabMetricsQuality(analysis)"
                    class="analyses-table--tr"
                    v-if="analysisHasTooLittleReadsToBeAnalysed(analysis)"
                    :key="analysis.id"
                    @mouseenter="$emit('analysis-hover-start', analysis)"
                    @mouseleave="$emit('analysis-hover-end', analysis)"
                >
                    <td class="analyses-table--td">{{ analysis.name }}</td>
                    <td colspan="3" class="analyses-table--td">{{ $t("tooLittleReads") }}</td>
                </tr>
                <tr :class="getLabMetricsQuality(analysis)"
                    class="analyses-table--tr"
                    v-else
                    :key="analysis.id"
                    @mouseenter="$emit('analysis-hover-start', analysis)"
                    @mouseleave="$emit('analysis-hover-end', analysis)"
                >
                    <td class="analyses-table--td">{{ analysis.name }}</td>
                    <td class="analyses-table--td">
                            <span :class="analysis.result.problems.generalMetrics.totalReads.quality"
                                  class="badge"
                                  v-if="analysis.result.problems && analysis.result.problems.generalMetrics && analysis.result.problems.generalMetrics.totalReads">
                                {{ analysis.result.problems.generalMetrics.totalReads.value | squash }}</span>
                    </td>
                    <td class="analyses-table--td">
                            <span :class="analysis.result.problems.generalMetrics.insertSize.quality"
                                  class="badge"
                                  v-if="analysis.result.problems && analysis.result.problems.generalMetrics && analysis.result.problems.generalMetrics.insertSize">
                            {{ analysis.result.problems.generalMetrics.insertSize.value }}</span>
                    </td>
                    <td class="analyses-table--td">
                        <span class="badge"
                              :class="analysis.result.problems.generalMetrics.contamination.quality"
                              v-if="analysis.result.problems && analysis.result.problems.generalMetrics && analysis.result.problems.generalMetrics.contamination"
                        >{{ $t("analysisMetrics.contamination") }}</span>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { analysisHasTooLittleReadsToBeAnalysed, getLabMetricsQuality } from "@/utils/analysis"
    import { Locales } from "@/i18n/main"

    export default {
        props: {
            analyses: Array
        },
        name: "LabIssueAnalyses",
        methods: {
            getLabMetricsQuality,
            analysisHasTooLittleReadsToBeAnalysed
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    tooLittleReads: "Слишком мало прочтений"
                },
                [Locales.EN]: {
                    tooLittleReads: "Too little reads"
                }
            }
        }
    }
</script>

<style scoped>
    .badge.red {
        background-color: var(--red);
        color: ghostwhite;
        font-weight: 600;
    }

    .analyses-table {
        font-size: 1.6rem;
        text-align: center;
        width: 100%
    }

    .analyses-table--th {
        background: white;
        position: sticky;
        height: 100%;
        top: 0;
        z-index: var(--second-layer);
        vertical-align: middle;
        padding: .7rem 1rem;
        font-weight: 600;
    }

    .analyses-table--tr {
        border-bottom: 1px solid whitesmoke;
        align-items: center;
        position: relative;
    }

    .analyses-table--td {
        padding: .7rem 1rem;
        vertical-align: middle;
        /*
         * background-clip is added and background-color moved from tr to td
         * because of https://bugzilla.mozilla.org/show_bug.cgi?id=688556 Firefox bug
         */
        background-clip: padding-box;
        background-color: white;
    }

    .analyses-table--tr:hover .analyses-table--td {
        background-color: #FFF8E1;
    }

    .analyses-table--tr.red {
        border-bottom-color: #FFEBEE;
    }

    .analyses-table--tr.red .analyses-table--td {
        background-color: rgba(255, 235, 238, .2);
    }

    .analyses-table--tr.red:hover .analyses-table--td {
        background-color: rgba(255, 235, 238, .4);
    }

    .traffic-lights {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
