import { ascending, quantile } from "d3-array"
import { Selection } from "d3-selection"

export const CIRCLE_RADIUS = 5
export const INCREASED_CIRCLE_RADIUS = 8.5
export const SQUARE_SIZE = 10

export function calculateBoxPlotData(data: Array<number>) {
    const locusData = data.sort(ascending)
    const q1 = quantile(locusData, .25)!
    const median = quantile(locusData, .5)!
    const q3 = quantile(locusData, .75)!
    const interQuantileRange = q3 - q1
    const min = locusData[0]
    const max = locusData[locusData.length - 1]
    return { q1, median, q3, interQuantileRange, min, max }
}

export function addTooltip(container: Selection<SVGSVGElement, unknown, any, any>) {
    return container
        .append("div")
        .attr("class", "chart-tooltip")
}

export function emphasizeDataPoint(circle: Selection<SVGCircleElement, any, any, any>) {
    return circle
        .transition()
        .duration(150)
        .attr("r", INCREASED_CIRCLE_RADIUS)
        .attr("stroke", "black")
        .style("stroke-width", "1px")
}

export function deemphasizeDataPoint(circle: Selection<SVGCircleElement, any, any, any>) {
    return circle
        .transition()
        .duration(150)
        .attr("r", CIRCLE_RADIUS)
        .attr("stroke", "none")
}
