<template>
    <div class="runs-grid">
        <div class="search-panel">
            <div class="composite-search">
                <div class="badge yellow mh-1" v-if="filters.dateFrom">{{ $t("runFilterPlaceholders.from") }}: {{ filters.dateFrom }}</div>
                <div class="badge yellow" v-if="filters.dateTo">{{ $t("runFilterPlaceholders.to") }}: {{ filters.dateTo }}</div>
                <input autocomplete="off"
                       class="composite-search--input"
                       aria-label="run id live search"
                       :placeholder="$t('runFilterPlaceholders.liveSearch')"
                       type="text"
                       v-model="liveSearch"
                >
                <button @click="resetFilters"
                        class="composite-search--clear"
                        v-if="filtersHaveSomeValue">
                    <p-icon icon-name="clear" size="1.5em"/>
                </button>
                <button @click="toggleCompositeSearchDropdown"
                        class="composite-search--arrow"
                        ref="showDropdownButton">
                    <p-icon icon-name="arrow_drop_down" size="1.5em"/>
                </button>
                <div @focusout="onCompositeSearchDropdownFocusout"
                     class="composite-search--dropdown"
                     ref="dropdown"
                     tabindex="-1"
                     v-if="compositeSearchDropdownShown"
                >
                    <div style="font-size: 1.5rem">
                        <p-input autocomplete="off"
                                 class="md-outlined filter"
                                 :placeholder="$t('runId')"
                                 type="text"
                                 v-model="tempFilters.runId"
                        ></p-input>
                        <div class="flex">
                            <p-input autocomplete="off"
                                     class="md-outlined filter"
                                     :placeholder="$t('runFilterPlaceholders.from')"
                                     type="date"
                                     v-model="tempFilters.dateFrom"
                            ></p-input>
                            <p-input autocomplete="off"
                                     class="md-outlined filter"
                                     :placeholder="$t('runFilterPlaceholders.to')"
                                     type="date"
                                     v-model="tempFilters.dateTo"
                            ></p-input>
                        </div>
                        <div class="flex" style="font-size: 1.5rem">
                            <button @click="resetFilters"
                                    class="md-btn-text ml-auto mr-1"
                                    style="color: royalblue">{{ $t("buttons.reset") }}
                            </button>
                            <button @click="search"
                                    class="md-btn-contained royal-blue"
                                    ref="searchDropdownCloser">{{ $t("buttons.search") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex"
                 style="width: 100%; margin-top: 1em"
                 v-if="runs.length !== 0"
            >
                <span style="font-size: .8em; padding-left: .8rem">{{ $tc("foundRuns", runsNumber) }}</span>
            </div>
        </div>
        <div @scroll="throttledOnScroll"
             class="overflow-auto custom-scroll run-list"
             ref="runs"
             v-if="runs.length !== 0">
            <template v-for="run in runs">
                <div :key="run.id"
                     @click="inspectRun(run)"
                     class="run-list--run"
                     v-if="isAnalysed(run)">
                    <h2 class="mr-1">{{ run.name }}</h2>
                    <div class="flex  mr-1">
                        {{ $d(new Date(run.date), "short") }}
                    </div>
                    <div class="flex">
                        <RunLotBadge :control-sample-status="qcStatus"
                                  :key="lot"
                                  :lot-name="lot"
                                  class="mr-1"
                                  v-for="(qcStatus, lot) in run.properties['controlSampleQc']"/>
                    </div>
                    <div class="ml-auto" v-if="run.properties.labQcAggs">
                        <div class="badge run-badge green mr-1">{{ run.properties.labQcAggs.green }}</div>
                        <div class="badge run-badge yellow mr-1">{{ run.properties.labQcAggs.yellow }}</div>
                        <div class="badge run-badge red">{{ run.properties.labQcAggs.red }}</div>
                    </div>
                </div>
                <div :key="run.id"
                     class="run-list--run in-progress"
                     v-else>
                    <h2 class="mr-1">{{ run.name }}</h2>
                    <div class="flex  mr-1">
                        {{ $d(new Date(run.date), "short") }}
                    </div>
                    <div class="flex">
                        <RunLotBadge :control-sample-status="qcStatus"
                                  :key="lot"
                                  :lot-name="lot"
                                  class="mr-1"
                                  v-for="(qcStatus, lot) in run.properties['controlSampleQc']"/>
                    </div>
                    <div class="ml-auto">
                        {{ $t("analysisInProgress") }}
                    </div>
                </div>
            </template>
        </div>
        <span class="fallback-text h1"
              style="align-self: center"
              v-else-if="!fetchInProgress">
            {{ $tc("foundRuns", 0) }}
        </span>
        <p-spinner
            v-if="fetchInProgress"
            class="abs-center h4"
        />
    </div>
</template>

<script>
    import { runsActionNames, runsGetterNames } from "@/store/modules/runs"
    import ld from "lodash"
    import { compositeSearchMixin } from "@/mixins/composite-search"
    import { Locales } from "@/i18n/main"
    import { isAnalysed, prepareFilters } from "@/utils/sequencing-run"
    import RunLotBadge from "@/components/run-lot-badge"
    import { store } from "@/store/store"

    export default {
        name: "Runs",
        components: {
            RunLotBadge,
        },
        mixins: [ compositeSearchMixin ],
        methods: {
            inspectRun(run) {
                this.$router.push({ name: "run", params: { id: run.id, run } })
            },
            onScroll() {
                const resourceListScrollableContainer = this.$refs["runs"]
                const scrollPercent = resourceListScrollableContainer.scrollTop / (resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight)
                if (scrollPercent > 0.9 && !this.fetchInProgress && (this.runs.length !== this.runsNumber)) {
                    store.dispatch(runsActionNames.continueSearch)
                }
            },
            startNewSearch(filters) {
                const backendCompatibleFilters = prepareFilters({
                    ...filters,
                    runId: { value: filters.runId, modifier: "startsWith" }
                })

                const resourceListScrollableContainer = this.$refs["runs"]
                return store
                    .dispatch(runsActionNames.startNewSearch, { filter: backendCompatibleFilters })
                    .then(() => this.fetchRunsTillScrollAppearsOrTheyEnd())
                    .then(() => {
                        // eslint-disable-next-line promise/always-return
                        if (resourceListScrollableContainer) {
                            resourceListScrollableContainer && resourceListScrollableContainer.scrollTo(0, 0)
                        }
                    })
                    .catch(() => { /* Bypass to avoid uncaught promise rejection */
                    })
            },
            async fetchRunsTillScrollAppearsOrTheyEnd() {
                const resourceListScrollableContainer = this.$refs["runs"]
                let scrollIsMissing = () => resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight === 0
                while (scrollIsMissing() && this.runs.length !== this.runsNumber) {
                    await store.dispatch(runsActionNames.continueSearch)
                }
            },
            isAnalysed
        },
        computed: {
            runs() {
                return this.$store.getters[runsGetterNames.resourceList]
            },
            runsNumber() {
                return this.$store.getters[runsGetterNames.resourceNumber]
            },
            fetchInProgress() {
                return this.$store.getters[runsGetterNames.fetchInProgress]
            }
        },
        data() {
            return {
                throttledOnScroll: null,
                liveSearchFilterName: "runId"
            }
        },
        created() {
            this.throttledOnScroll = ld.throttle(this.onScroll, 1000)
            Promise.all([
                store.dispatch(runsActionNames.startNewSearch),
            ]).then(() => this.fetchRunsTillScrollAppearsOrTheyEnd())
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    foundRuns: "No runs found | Found {n} run | Found {n} runs",
                    analysisInProgress: "Analysis in progress"
                },
                [Locales.RU]: {
                    // For reasons why 2, 3 and 4 translations exist, though they are the same, see russianPluralization
                    foundRuns: "Не найдено ни одного запуска | Найдено запусков: {n} | Найдено запусков: {n} | Найдено запусков: {n}",
                    analysisInProgress: "Идет анализ"
                }
            }
        }
    }
</script>

<style scoped>
    .runs-grid {
        display: grid;
        height: 100%;
        grid-template-rows: min-content minmax(60%, 1fr);
        justify-items: center;
    }

    .search-panel {
        align-self: start;
        justify-self: center;
        max-width: 80rem;
        width: 100%;
        padding: 3rem 2rem;
        font-size: 2rem;
        position: relative;
    }

    .filter {
        margin: 2rem;
    }

    .run-list {
        height: 100%;
        width: 100%;
        max-width: 80rem;
        position: relative;
    }

    .run-list--run {
        margin: 1.5rem 2rem;
        padding: 1.5rem 1.5rem;
        border: 1px solid var(--grey-400);
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 1.5rem;
    }

    .run-list--run:not(.in-progress):hover {
        background-color: #F5F5F5;
    }

    .run-list--run:not(.in-progress) {
        cursor: pointer;
    }

    .run-list--run.in-progress {
        background-color: var(--grey-100);
    }

    .run-badge {
        width: 2.5em;
        height: 2.5em;
    }
</style>
