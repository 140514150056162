<template>
    <div>
        <h3 style="font-size: 1.8rem; font-weight: 600; margin-bottom: 2rem">{{ $t("runMetadataValidationError") }}</h3>
        <div>
            {{ $t("runAlreadyExists", [error.details.runId]) }}
        </div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"

    export default {
        name: "ExistingRunError",
        props: {
            error: Object
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    runAlreadyExists: "Run with id {0} already exists",
                    runMetadataValidationError: "Metadata validation failed"
                },
                [Locales.RU]: {
                    runAlreadyExists: "Запуск с номером {0} уже существует",
                    runMetadataValidationError: "Метаданные не прошли валидацию"
                }
            }
        }
    }
</script>
