<template>
    <div class="workbench-grid">
        <WorkflowScheme style="margin: 3rem; max-width: 130rem; width: 100%"/>
    </div>
</template>

<script>
    import WorkflowScheme from "./components/workflow-scheme"

    export default {
        name: "Workbench",
        components: {
            WorkflowScheme
        }
    }
</script>

<style>
    .workbench-grid {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
