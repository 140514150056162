<template>
    <div class="runs-grid">
        <div class="search-panel">
            <div class="composite-search">
                <div class="mt-1">
                    <span class="badge yellow ml-1 mb-1"
                          v-if="filters.dateFrom">{{ $t("runFilterPlaceholders.from") }}: {{ filters.dateFrom }}</span>
                    <span class="badge yellow ml-1 mb-1"
                          v-if="filters.dateTo">{{ $t("runFilterPlaceholders.to") }}: {{ filters.dateTo }}</span>
                    <span class="badge yellow ml-1 mb-1"
                          v-if="filters.organization">Org.: {{ filters.organization }}</span>
                </div>
                <input :placeholder="$t('runFilterPlaceholders.liveSearch')"
                       autocomplete="off"
                       class="composite-search--input"
                       aria-label="run id live search"
                       type="text"
                       v-model="liveSearch"
                >
                <button @click="resetFilters"
                        class="composite-search--clear"
                        v-if="filtersHaveSomeValue">
                    <p-icon icon-name="clear" size="1.5em"/>
                </button>
                <button @click="toggleCompositeSearchDropdown"
                        class="composite-search--arrow"
                        ref="showDropdownButton">
                    <p-icon icon-name="arrow_drop_down" size="1.5em"/>
                </button>
                <div @focusout="onCompositeSearchDropdownFocusout"
                     class="composite-search--dropdown"
                     ref="dropdown"
                     tabindex="-1"
                     v-if="compositeSearchDropdownShown"
                >
                    <div style="font-size: 1.5rem">
                        <p-type-ahead-input
                                class="md-outlined filter"
                                :placeholder="$t('runId')"
                                :options="runTypeAheadOptions"
                                type="text"
                                v-model="tempFilters.runId"/>
                        <div class="flex">
                            <p-input :placeholder="$t('runFilterPlaceholders.from')"
                                     autocomplete="off"
                                     class="md-outlined filter"
                                     type="date"
                                     v-model="tempFilters.dateFrom"
                            ></p-input>
                            <p-input :placeholder="$t('runFilterPlaceholders.to')"
                                     autocomplete="off"
                                     class="md-outlined filter"
                                     type="date"
                                     v-model="tempFilters.dateTo"
                            ></p-input>
                        </div>
                        <div v-if="organizations.length < 4" class="btn-group" style="margin: 2rem">
                            <button class="btn-group--btn"
                                    :class="{ 'btn-group--btn-active': organization === tempFilters.organization }"
                                    @click="tempFilters.organization = tempFilters.organization === organization ? null : organization"
                                    :key="organization"
                                    v-for="organization in organizations">
                                {{ organization }}
                            </button>
                        </div>
                        <p-select v-else
                                  :options="organizations"
                                  :placeholder="$t('runFilterPlaceholders.organization')"
                                  autocomplete="off"
                                  class="md-outlined filter"
                                  type="text"
                                  v-model="tempFilters.organization"
                                  style="margin: 2rem; width: auto"
                        />
                        <div class="flex" style="font-size: 1.5rem">
                            <button @click="resetFilters"
                                    class="md-btn-text ml-auto mr-1"
                                    style="color: royalblue">{{ $t("buttons.reset") }}
                            </button>
                            <button @click="search"
                                    class="md-btn-contained royal-blue"
                                    ref="searchDropdownCloser">{{ $t("buttons.search") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex"
                 style="width: 100%; margin-top: 1em"
                 v-if="runs.length !== 0"
            >
                <span style="font-size: .8em; padding-left: .8rem">{{ $tc("foundRuns", runsNumber) }}</span>
            </div>
        </div>
        <div @scroll="throttledOnScroll"
             class="overflow-auto custom-scroll run-list"
             ref="runs"
             v-if="runs.length !== 0">
            <div :key="run.name"
                 class="run-list--run"
                 v-for="run in runs"
                 @click="supportRun(run)"
            >
                <h2 class="mr-1 ellipsis" style="flex-basis: 7em;">{{ run.name }}</h2>
                <div class="flex mr-1" style="flex-basis: 3em">
                    {{ $d(new Date(run.date), "short") }}
                </div>
                <div class="flex" style="flex-basis: 15%;">
                    <RunLotBadge :control-sample-status="qcStatus"
                              :key="lot"
                              :lot-name="lot"
                              @click.native.prevent.stop.capture
                              class="mr-1"
                              v-for="(qcStatus, lot) in run.properties['controlSampleQc']"/>
                </div>
                <p-select
                    :placeholder="$t('supportUsers')"
                    class="md-outlined"
                    style="flex-basis: 25%; margin-right: 1rem;"
                    :options="supportUsers"
                    :value="run.responsibleSupport"
                    @input="setSupportUsers($event, run)"
                    @click.native.prevent.stop
                />
                <div>{{ run.ownedBy }}</div>
                <div class="ml-auto badge yellow" style="white-space: pre; flex-shrink: 0">
                    <b>{{ run.properties.statusAggs["IN_SUPPORT"] }}</b>
                    / {{ samplesNumber(run) }}
                </div>
            </div>
        </div>
        <div class="fallback-text h1"
             style="align-self: center"
             v-else>
            {{ $tc("foundRuns", 0) }}
        </div>
    </div>
</template>

<script>
    import ld from "lodash"
    import { compositeSearchMixin } from "@/mixins/composite-search"
    import { Locales } from "@/i18n/main"
    import { supportActionNames, supportGetterNames } from "@/store/modules/support"
    import { samplesNumber } from "@/utils/sequencing-run"
    import { debounce } from "@/main"
    import RunLotBadge from "@/components/run-lot-badge"
    import { store } from "@/store/store"
    import { supportEndpoints } from "@/endpoints"

    export default {
        name: "Support",
        components: {
            RunLotBadge
        },
        beforeRouteEnter(to, from, next) {
            Promise.all([
                store.dispatch(supportActionNames.startNewSearch),
                supportEndpoints.organizations(),
            ]).then(([ , organizations ]) =>
                next(vm => vm.organizations = organizations))
        },
        mixins: [ compositeSearchMixin ],
        watch: {
            "tempFilters.runId"(newVal) {
                if (newVal) {
                    this.updateRunTypeAheadOptions(newVal)
                }
            }
        },
        methods: {
            updateRunTypeAheadOptions(prefix) {
                supportEndpoints.fetchRunNamesMatchingPrefix(prefix)
                    .then(data => this.runTypeAheadOptions = data)
            },
            supportRun(run) {
                this.$router.push({ name: "supportRun", params: { id: run.id, run } })
            },
            onScroll() {
                const resourceListScrollableContainer = this.$refs["runs"]
                const scrollPercent = resourceListScrollableContainer.scrollTop / (resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight)
                if (scrollPercent > 0.9 && !this.fetchInProgress && (this.runs.length !== this.runsNumber)) {
                    store.dispatch(supportActionNames.continueSearch)
                }
            },
            startNewSearch(filters) {
                console.debug("New search started")
                const name = filters.runId ? `startsWith:${filters.runId}` : null
                const from = filters.dateFrom ? new Date(filters.dateFrom).getTime() : null
                const to = filters.dateTo ? new Date(filters.dateTo).setHours(23, 59, 59, 999) : null
                const ownedBy = filters.organization ? filters.organization : null
                const resourceListScrollableContainer = this.$refs["runs"]
                return store
                    .dispatch(supportActionNames.startNewSearch, { filter: { name, from, to, ownedBy } })
                    .then(() => {
                        // eslint-disable-next-line promise/always-return
                        if (resourceListScrollableContainer) {
                            resourceListScrollableContainer && resourceListScrollableContainer.scrollTo(0, 0)
                        }
                    })
                    .then(() => this.fetchRunsTillScrollAppearsOrTheyEnd())
                    .catch(() => { /* Bypass to avoid uncaught promise rejection */
                    })
            },
            /*
             TODO: [@aslepchenkov 04.03.2020] This should be removed and compositeSearch should
              be redesigned. Btn group styles depend on tempFilters.organization, but
              tempFilters are initially empty in compositeSearch, so there is no
              reactivity watchers for organization, so button doesn't see any changes
              and it's class remains the same.
            */
            resetTempFilters() {
                this.tempFilters = { organization: null, ...this.filters }
            },
            async setSupportUsers(eve, run) {
                if(eve) {
                    await supportEndpoints.sapportsEdit(run.id, eve)
                } else {
                    await supportEndpoints.sapportsEdit(run.id, null)
                }
                this.resetFilters()
                this.tempFilters = { organization: null, ...this.filters }
                await store.dispatch(supportActionNames.startNewSearch)
            },
            samplesNumber,
            async fetchRunsTillScrollAppearsOrTheyEnd() {
                const resourceListScrollableContainer = this.$refs["runs"]
                let scrollIsMissing = () => resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight === 0
                while (scrollIsMissing() && (this.runs.length !== this.runsNumber)) {
                    await this.$store.dispatch(supportActionNames.continueSearch)
                }
            },
            async fetchUserSupports() {
                this.supportUsers = (await supportEndpoints.getSupports())
                    .map(user => (user.name))
                this.runs.forEach(run => {
                    if(run.responsibleSupport) {
                        this.selectedSupportUsers[run.id] = run.responsibleSupport
                    } else {
                        this.selectedSupportUsers[run.id] = null
                    }
                })
            }
        },
        mounted() {
            this.fetchRunsTillScrollAppearsOrTheyEnd()
            this.fetchUserSupports()
        },
        computed: {
            runs() {
                return this.$store.getters[supportGetterNames.resourceList]
            },
            runsNumber() {
                return this.$store.getters[supportGetterNames.resourceNumber]
            },
            fetchInProgress() {
                return this.$store.getters[supportGetterNames.fetchInProgress]
            }
        },
        data() {
            return {
                throttledOnScroll: null,
                liveSearchFilterName: "runId",
                organizations: [],
                tempFilters: {
                    organization: null  // Dirty hack. See resetTempFilters
                },
                runTypeAheadOptions: [],
                supportUsers: [],
            }
        },
        created() {
            this.throttledOnScroll = ld.throttle(this.onScroll, 1000)
            this.updateRunTypeAheadOptions = debounce(this.updateRunTypeAheadOptions, 200)
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    foundRuns: "No runs found | Found {n} run | Found {n} runs",
                    supportUsers: "Responsible",
                },
                [Locales.RU]: {
                    // For reasons why 2, 3 and 4 translations exist, though they are the same, see russianPluralization
                    foundRuns: "Не найдено ни одного запуска | Найдено запусков: {n} | Найдено запусков: {n} | Найдено запусков: {n}",
                    supportUsers: "Ответственный",
                }
            }
        }
    }
</script>

<style scoped>
    .runs-grid {
        display: grid;
        height: 100%;
        grid-template-rows: min-content minmax(60%, 1fr);
        justify-items: center;
    }

    .search-panel {
        align-self: start;
        justify-self: center;
        max-width: 80rem;
        width: 100%;
        padding: 3rem 2rem;
        font-size: 2rem;
        position: relative;
    }

    .filter {
        margin: 2rem;
    }

    .run-list {
        height: 100%;
        width: 100%;
        max-width: 80rem;
        position: relative;
    }

    .run-list--run {
        margin: 1.5rem 2rem;
        padding: 1.5rem 1.5rem;
        border: 1px solid var(--grey-400);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1.5rem;
    }

    .run-list--run:hover {
        background-color: #F5F5F5;
    }

    .run-badge {
        width: 2.5em;
        height: 2.5em;
    }

    .btn-group {
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid var(--parseq-blue);
        width: fit-content;
        display: flex;
    }

    .btn-group--btn {
        transition: background-color .2s;
        padding: 1rem;
    }

    .btn-group--btn:hover:not(.btn-group--btn-active) {
        background-color: hsl(225, 73%, 95%);
    }

    .btn-group--btn:not(:last-child) {
        border-right: 1px solid var(--parseq-blue);
    }

    .btn-group--btn-active {
        background-color: var(--parseq-blue);
        color: white;
    }
</style>
