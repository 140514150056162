<template>
    <div class="allele-panel">
        <p-modal v-if="modals.addExistingAllele.opened">
            <div class="modal">
                <add-existing-allele-form
                    :genotype="tempGenotypeAtActiveLocus"
                    :allele-colors="alleleColors"
                    v-model="existingAlleleWithIndex"
                />
                <div class="flex" style="font-size: 1.4rem">
                    <button class="md-btn-text"
                            style="margin-left: -1.1em"
                            @click="cancelExistingAlleleAddition">
                        {{ $t("buttons.cancel") }}
                    </button>
                    <button class="md-btn-contained ml-auto royal-blue"
                            :disabled="!couldSaveExistingAllele"
                            @click="onSave">
                        {{ $t("buttons.save") }}
                    </button>
                </div>
            </div>
        </p-modal>
        <div class="filter p-1">
            <p-input class="md-outlined monospace bold mb-1"
                     type="text"
                     v-model="alleleFilter"
                     :placeholder="$t('searchAlleleNumber')"
            >
                <template #icon-left>
                    <p-icon icon-name="search" size="1.5em"/>
                </template>
            </p-input>
        </div>
        <div
            v-if="alleleHints.length"
            class="overflow-auto custom-scroll"
        >
            <ul data-test-id="allele-hints-list">
                <li
                    :key="hint"
                    @click="selectAllele(hint)"
                    class="allele-hint"
                    :class="{selected: selectedAllele === hint}"
                    v-for="[hint, tools] in filteredAlleleHints"
                    data-test-id="allele-hint"
                >
                    <div style="width: 8em;">
                        <!-- eslint-disable-next-line vue/require-v-for-key -->
                        <span
                            class="badge badge-margin tool-badge"
                            :class="`badge-${tool}`"
                            v-for="tool in tools"
                        >{{ tool }}
                    </span>
                    </div>
                    <span
                        class="monospace bold"
                        style="width: 15em;"
                    >{{ hint }}</span>
                    <div
                        class="flex"
                    >
                        <template v-for="(genotypePart, genotypePartIndex) in tempGenotypeAtActiveLocus">
                            <!-- eslint-disable-next-line vue/require-v-for-key -->
                            <fieldset class="allele-hint-toggle__genotype-part">
                                <!-- eslint-disable-next-line vue/require-v-for-key -->
                                <label
                                    v-for="alleleIndex in [0, 1]"
                                    :class="`allele-hint-toggle__allele-wrapper ${genotypePart[alleleIndex].includes(NEW_ALLELE_PLACEHOLDER) ? 'disable' : ''}`"
                                    :style="{
                                        color: alleleColors[2 * genotypePartIndex + alleleIndex],
                                        backgroundColor: genotypePart[alleleIndex].includes(NEW_ALLELE_PLACEHOLDER) ? 'rgb(209 210 210)' : 'transparent',
                                    }"
                                    @click.stop
                                >
                                    <!--  click is listened instead of input/change cause it allows to deselect allele. input/change are not triggered when selected radio btn is clicked again -->
                                    <input
                                        type="radio"
                                        :checked="genotypePart[alleleIndex].includes(hint)"
                                        :name="`${hint}-${genotypePartIndex}`"
                                        :class="`allele-hint-toggle__allele ${genotypePart[alleleIndex].includes(NEW_ALLELE_PLACEHOLDER) ? 'disable' : ''}`"
                                        :disabled="genotypePart[alleleIndex].includes(NEW_ALLELE_PLACEHOLDER)"
                                        :style="{
                                            color: alleleColors[2 * genotypePartIndex + alleleIndex],
                                            backgroundColor: genotypePart[alleleIndex].includes(hint) ? 'currentColor' : 'transparent',
                                        }"
                                        @click.stop="toggleAlleleInNewGenotype(hint, genotypePartIndex, alleleIndex)"
                                    >
                                </label>
                            </fieldset>
                        </template>
                    </div>
                </li>
            </ul>
            <button
                class="add-allele-btn"
                @click="openModal('addExistingAllele')"
            >
                <p-icon icon-name="plus" size="1.5rem"/>
            </button>
        </div>
        <div
            v-else
            style="display: flex; justify-content: center; align-items: center; font-size: 2rem"
        >
            <p>{{ $t("uploadData") }}</p>
        </div>
    </div>
</template>
<script>

    import { createAlleleValidator } from "@/validation/validators"
    import { ValidatableInput } from "@/validation/validatable-input"
    import { EventBus } from "@/event-bus"
    import ld from "lodash"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { Locales } from "@/i18n/main"
    import { NEW_ALLELE_PLACEHOLDER } from "@/genotype"

    import AddExistingAlleleForm from "./add-existing-allele-form"

    export default {
        name: "AllelePanel",
        mixins: [ modalMixin ],
        components: { AddExistingAlleleForm },
        props: {
            alleleColors: {
                type: Array,
                required: true
            },
            alleleHints: {
                type: Array,
                required: true
            },
            selectedAllele: {
                type: String,
                required: false
            },
            saveExistingAllele: {
                type: Function,
                required: true,
            },
            selectAllele: {
                type: Function,
                required: true,
            },
            tempGenotypeAtActiveLocus: {
                type: Object,
                required: true,
            },
            toggleAlleleInNewGenotype: {
                type: Function,
                required: true,
            }
        },
        data() {
            return {
                modals: {
                    addExistingAllele: {
                        opened: false,
                        onOpen: this.clearExistingAlleleWithIndex,
                        onClose: this.clearExistingAlleleWithIndex
                    },
                },
                existingAlleleWithIndex: emptyExistingAlleleWithIndex(this.validateAllele),
                alleleFilter: null,
                NEW_ALLELE_PLACEHOLDER
            }
        },
        computed: {
            filteredAlleleHints() {
                return this.alleleFilter
                    ? this.alleleHints.filter(([ allele ]) => allele.startsWith(this.alleleFilter))
                    : this.alleleHints
            },
            couldSaveExistingAllele() {
                return this.existingAlleleWithIndex[0].errors !== "pending"
                    && this.existingAlleleWithIndex[0].errors.length === 0
                    && !ld.isNil(this.existingAlleleWithIndex[1])
            },
            validateAllele() {
                return createAlleleValidator(this.alleleHints.map(([ allele ]) => allele))
            },
        },
        methods: {
            cancelExistingAlleleAddition() {
                this.closeModal("addExistingAllele")
            },
            clearExistingAlleleWithIndex() {
                this.existingAlleleWithIndex = emptyExistingAlleleWithIndex(this.validateAllele)
            },
            // Stupid name, but I need to prevent name clash with props
            onSave() {
                this.saveExistingAllele(...this.existingAlleleWithIndex)
                this.clearExistingAlleleWithIndex()
                this.closeModal("addExistingAllele")
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    searchAlleleNumber: "Поиск по номеру аллели (01:01)",
                    uploadData: "Данные загружаются...",
                },
                [Locales.EN]: {
                    searchAlleleNumber: "Search by allele number (01:01)",
                    uploadData: "Data is loading....",
                }
            }
        }
    }

    function emptyExistingAlleleWithIndex(validationFn) {
        return [ new ValidatableInput("existingAllele", new EventBus(), validationFn, null), null ]
    }
</script>

<style scoped>
.allele-panel {
    display: grid;
    overflow: auto;
    grid-template-rows: min-content 1fr;
    height: 100%;
    position: relative;
}

.allele-hint {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    padding: .6rem .7rem;
    cursor: pointer;
}

.allele-hint.selected {
    background-color: hsl(225, 73%, 97%);
    position: sticky;
    top: 0;
    bottom: 0;
}

.allele-hint:hover {
    background-color: hsl(225, 73%, 97%);
}

.allele-hint-toggle__genotype-part {
    border: none;
    display: flex;
}

.allele-hint-toggle__genotype-part:not(:first-child) {
    margin-left: 1rem;
}

.allele-hint-toggle__allele-wrapper {
    padding: .8rem;
    cursor: pointer;
    line-height: 1;
    border: 2px solid transparent;
    border-radius: 4px;
}

.allele-hint-toggle__allele-wrapper:focus-within,
.allele-hint-toggle__allele-wrapper:hover {
    border-color: black;
}

.allele-hint-toggle__allele-wrapper .disable {
    border-color: none;
    cursor: not-allowed;
}

.allele-hint-toggle__allele {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    --size: 1.2em;
    display: inline-block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;

    transition: 0.1s all linear;
    outline: none;
    border: 2px solid currentColor;
    cursor: pointer;
}

.allele-hint-toggle__allele .disable {
    border-color: none;
    cursor: not-allowed;
}

.add-allele-btn {
    background-color: white;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
    transition: transform .2s;
    border: 2px solid transparent;
}

.add-allele-btn:focus {
    border-color: black;
    outline: none;
}

.add-allele-btn:hover {
    transform: scale(1.1);
}


.tool-badge {
    font-size: .8em;
    border: 1px solid #337ab7;
}
</style>
