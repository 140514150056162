import { scaleLinear, ScaleLinear } from "d3-scale"
import { select, Selection } from "d3-selection"
import { axisLeft } from "d3-axis"
import { BaseChart, Margin } from "@/charts/base-chart"
import {
    addTooltip,
    calculateBoxPlotData,
    CIRCLE_RADIUS,
    deemphasizeDataPoint,
    emphasizeDataPoint,
} from "@/charts/utils"
import VueI18n from "vue-i18n"


interface BoxPlotData {
    q1: number
    median: number
    q3: number
    interQuantileRange: number
    min: number
    max: number
}

interface Chart {
    chartGroup: Selection<SVGGElement, unknown, any, any>
    chartWidth: number
    chartHeight: number
    y: ScaleLinear<number, number>
    margin: Margin

    resize(): void

    onLocaleChange(): void
}

export function drawChart(
    data: {
        sameRunAnalysesMetric: Array<number>,
        currentAnalysisMetric: number
    },
    containerId: string,
    metricName: string,
    i18n: VueI18n,
    metricUnits: string = "",
    options: {
        fractionDigits: number
    } = { fractionDigits: 0 }
) {
    const baseChart = new BaseChart(containerId, { bottom: 20, left: 40, right: 20, top: 30 })
    const boxPlotData = calculateBoxPlotData(data.sameRunAnalysesMetric)
    const maxMetricValue = Math.max(boxPlotData.max, data.currentAnalysisMetric)
    const minMetricValue = Math.min(boxPlotData.min, data.currentAnalysisMetric)
    const metricValueRange = maxMetricValue - minMetricValue
    const y = scaleLinear()
        .domain([ minMetricValue - metricValueRange * .1, maxMetricValue + metricValueRange * .1 ])
        .range([ baseChart.chartHeight, 0 ])
    const chart: Chart = {
        ...baseChart,
        y,
        onLocaleChange
    }

    function onLocaleChange() {
        select(`${containerId}-title`).text(<string>i18n.t(`analysisMetrics.${metricName}`))
    }

    addAxes(chart)
    addBoxplot(chart, boxPlotData)
    addTitle(baseChart, containerId)
    const tooltip = addTooltip(select(containerId))
    onLocaleChange()

    const mouseenter = (event: MouseEvent, d: number) => {
        emphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        tooltip
            .html(`${d.toFixed(options.fractionDigits).toString()}${metricUnits && ` ${i18n.t(`units.${metricUnits}`)}`}`)
            .style("display", "block")
            .style("left", `${event.clientX + 20}px`)
            .style("top", `${event.clientY}px`)
    }

    const mouseleave = (event: MouseEvent) => {
        deemphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        return tooltip
            .style("display", "none")
    }

    baseChart.chartGroup
        .append("circle")
        // `datum` is needed for mouseenter handler
        .datum(data.currentAnalysisMetric)
        .attr("cx", baseChart.chartWidth / 2)
        .attr("cy", d => y(d)!)
        .attr("r", CIRCLE_RADIUS)
        .style("fill", "royalblue")
        // @ts-ignore d3 types are stale
        .on("mouseenter", mouseenter)
        // @ts-ignore d3 types are stale
        .on("mouseleave", mouseleave)

    return chart
}

function addAxes(chart: Chart) {
    const { chartGroup, y } = chart
    chartGroup.append("g")
        .attr("class", "chart-axis")
        .attr("transform", "translate(15,0)")
        .call(
            axisLeft<number>(y)
                //  From the docs. The specified count is only a hint; the scale may return more or fewer values depending on the domain. See also d3-array’s ticks.
                .ticks(5)
                .tickSizeOuter(0)
        )
}

/*
 * Since we already have two functions for boxplot draw, why not customize this function to draw
 * single boxplot, see addBoxplots in analysis-loci-coverage.ts
 */
function addBoxplot(chart: Chart, data: BoxPlotData) {
    const { chartGroup, y, chartWidth } = chart
    const x = chartWidth / 2
    chartGroup
        .append("line")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", y(data.min)!)
        .attr("y2", y(data.max)!)
        .attr("stroke", "black")
        .style("width", 40)

    const boxWidth = 33
    chartGroup
        .append("rect")
        .attr("x", x - boxWidth / 2)
        .attr("y", y(data.q3)!)
        .attr("height", y(data.q1)! - y(data.q3)!)
        .attr("width", boxWidth)
        .attr("stroke", "black")
        .style("fill", "var(--grey-400)")

    chartGroup
        .append("line")
        .attr("x1", x - boxWidth / 2)
        .attr("x2", x + boxWidth / 2)
        .attr("y1", y(data.median)!)
        .attr("y2", y(data.median)!)
        .attr("stroke", "black")
        .style("width", 80)
}

function addTitle(chart: BaseChart, containerId: string) {
    const { svg, containerWidth, margin } = chart
    svg.append("text")
        .attr("id", `${containerId.slice(1)}-title`)
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "middle")
        .style("font-size", "1.7rem")
        .style("font-weight", "600")
        .attr("x", containerWidth / 2 + 8)
        .attr("y", margin.top / 2)
}
