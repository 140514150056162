<template>
    <div class="genotype">
        <p-with-tooltip fixed
                       class="allele"
                       style="justify-self: end">
            <div v-if="hideAmbiguities && firstAllele.isAmbiguous"
                    class="badge"
                    style="background-color: var(--grey-300)"
            >Ambiguity
            </div>
            <Allele
                v-else
                :rare="firstAlleleIsRare"
                :allele="firstAllele"
                :annotations="annotations[locusName]"
                :analysis="analysis"
                :locus-name="locusName"
                :genotype="genotype"
                :genotypePartIndex="genotypePartIndex"
                :allele-index="0"
                style="justify-content: flex-end; align-items: center"
            />
            <template #tooltip>
                <AlleleTooltip
                    :allele="firstAllele"
                    :rare="firstAlleleIsRare"
                    :annotations="annotations[locusName]"
                    v-if="shouldShowFirstAlleleTooltip"
                />
            </template>
        </p-with-tooltip>
        <div class="mh-1">+</div>
        <p-with-tooltip fixed
                       class="allele"
                       style="display: flex; justify-self: start;">
            <div v-if="hideAmbiguities && secondAllele.isAmbiguous"
                 class="badge"
                 style="background-color: var(--grey-300)"
            >Ambiguity
            </div>
            <Allele
                v-else
                :allele="secondAllele"
                :rare="secondAlleleIsRare"
                style="justify-content: flex-start"
                :analysis="analysis"
                :genotype="genotype"
                :genotypePartIndex="genotypePartIndex"
                :allele-index="1"
                :locus-name="locusName"
                :annotations="annotations[locusName]"
            />
            <slot/>
            <template #tooltip>
                <AlleleTooltip
                    :allele="secondAllele"
                    :rare="secondAlleleIsRare"
                    :annotations="annotations[locusName]"
                    v-if="shouldShowSecondAlleleTooltip"
                />
            </template>
        </p-with-tooltip>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { MISSING_ALLELE_PLACEHOLDER } from "@/genotype"

    import AlleleTooltip from "./allele-tooltip"
    import Allele from "./allele"

    export default {
        name: "GenotypePart",
        components: { Allele, AlleleTooltip },
        data() {
            return {
                firstAlleleEllipsis: false,
                secondAlleleEllipsis: false,
                MISSING_ALLELE_PLACEHOLDER,
            }
        },
        props: {
            locusName: String,
            genotype: {
                type: Array,
                required: true
            },
            annotations: Object,
            hideAmbiguities: {
                default: false
            },
            analysis: Object,
            genotypePartIndex: {
                type: Number,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                "alleleFrequencies",
            ]),
            secondAllele() {
                return this.genotype?.[1]
            },
            firstAllele() {
                return this.genotype?.[0]
            },
            firstAlleleIsRare() {
                return this.isRare(this.locusName, this.firstAllele)
            },
            secondAlleleIsRare() {
                return this.isRare(this.locusName, this.secondAllele)
            },
            shouldShowFirstAlleleTooltip() {
                return this.hideAmbiguities && this.firstAllele.isAmbiguous
                    || this.firstAlleleIsRare
            },
            shouldShowSecondAlleleTooltip() {
                return this.hideAmbiguities && this.secondAllele.isAmbiguous
                    || this.secondAlleleIsRare
            }
        },
        methods: {
            isRare(locus, allele) {
                return !allele.isUndefined
                    && !allele.isNew
                    && !(this.alleleFrequencies[locus][allele.toGLString()] > 5)
            },
        }
    }
</script>

<style scoped>
    .genotype {
        display: grid;
        width: 100%;
        justify-self: center;
        grid-template-columns: minmax(40%, 1fr) min-content minmax(40%, 1fr);
        align-items: center;
    }

    .allele {
        border-bottom: 2px solid transparent;
        position: relative;
    }
</style>
