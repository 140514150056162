import {
    Allele,
    Genotype,
    LOCI,
    Locus,
} from "@/genotype"
import { entriesOfPartial, mapValues } from "@/extensions/object-extensions"
import { AnalysisStatus, AnalysisStatuses, NonTerminalAnalysisStatus } from "@/enums/analysis-status"
import { updateOrSet } from "@/helpers"
import { Maybe } from "@/types"

// For safe usage from JS
export const LAB_ISSUE: ProblemType = "LAB_ISSUE"
export const ANALYSIS_ISSUE: ProblemType = "ANALYSIS_ISSUE"

export type LocusRecord<T> = Record<Locus, T>
export type FunctionalAnnotations = Partial<LocusRecord<Record<string, string>>>
export type Quality = "green" | "red" | "yellow"
export type ProblemType = "LAB_ISSUE" | "ANALYSIS_ISSUE"
export type AnalysisResolution = "SUPPORT_REQUEST" | "WELL_TYPED" | "LAB_ISSUE"
export type SupportResolution = "contaminated" | "assigned" | null
export type LocusSpecificProblem =
    "locusCoverage"
    | "genotypesNumber"
    | "allelesNumber"
    | "novelAlleles"
    | "typingError"
    | "exonInLqrRegion"
export type GLString = string
export type Principal = string
export type SerializedDate = string
export type GenotypingResolution = "ONE_FIELD" | "TWO_FIELD" | "THREE_FIELD"
export type ReagentKitLotQcStatus = "PASSED" | "FAILED" | "UNDEFINED"

export interface Problem {
    type: ProblemType
    quality: Quality
    value: any
}

export type LocusSpecificProblems = Partial<LocusRecord<Partial<Record<LocusSpecificProblem, Problem>>>>

export type GeneralMetricsProblems = Partial<{
    meanReadsQuality: Problem,
    totalReads: Problem,
    insertSize: Problem,
    contamination: Problem
}>

/* eslint-disable camelcase */
export interface AnalysisPriorState {
    prior_analysis_state_modified_at: SerializedDate
    prior_analysis_state_modified_by: Principal
    prior_analysis_state_analysis_status: NonTerminalAnalysisStatus
    prior_analysis_state_analysis_uuid: string
    prior_analysis_state_analysis_result: null | AnalysisResult,
    prior_analysis_state_maybe_resolution_draft: null | ResolutionDraft,
    prior_analysis_state_maybe_support_request: null | SupportRequest
}

/* eslint-enable camelcase */

export type RawGenotypes = LocusRecord<GLString | null>
export type Genotypes = LocusRecord<Genotype>

export type ResolutionDraftNewAlleles = {
    manuallyCreatedAlleles: {
        [locus in Locus]:
            {
                referenceAllele: Allele
                geneticVariants: {
                    region: string
                    genomicHgvs: string
                    proteinHgvs: string
                }[]
            }[]
    }
    sampleAlleles: {
        /*
        *   FIXME: [@bbatanov 03.03.21] types can be adjusted, for genotype number (0,1,2) for allele number (0,1) as an option
        */
        [locus in Locus]: {
            [key: number]: {
                [key: number]: number
            }
        }
    }
}

export type ResolutionDraft = {
    resolution: SupportResolution | null
    genotypes?: Partial<RawGenotypes>
    newAlleles?: ResolutionDraftNewAlleles
    annotations: FunctionalAnnotations | null
}

export type SupportRequest = {
    createdBy: string
    loci: Array<Locus>
    /**
     * "Autogenerated" support request (by configured pipeline itself) has NO comment,
     * but user support request (via PAR) does
     */
    comment?: string
}

export type AnalysisResult = {
    quality: Quality
    resolution: AnalysisResolution
    sampleName: string
    problems: {
        generalMetrics?: GeneralMetricsProblems
        locusSpecificMetrics?: LocusSpecificProblems
    } | null
    // TODO: Can be null for RUNNING
    genotypes: RawGenotypes,
    annotations: FunctionalAnnotations | null,
    qualityMetrics: {
        contamination: boolean
        readsDistribution: {
            offTargetRegions: number
            qualityTrimmed: number
            relatedLoci: number
            targetLoci: number
        }
        targetLociCoverage: LocusRecord<number>
        insertSize: number
        meanReadsQuality: number
    }
}

export interface Analysis {
    id: string
    name: string
    runId: number
    // TODO: [@aslepchenkov 18.01.2021] To be removed
    createdAt: number
    sequencingRunDate: number
    // FIXME: Can be null actually (when in Running status), should be fixed
    result: AnalysisResult
    maybeResolutionDraft: Maybe<ResolutionDraft>
    maybeSupportRequest: Maybe<SupportRequest>
    maybeReAnalysisState?: {
        status: "RUNNING" | "COMPLETE_SUCCESS" | "COMPLETE_FAILURE"
        maybeResult?: AnalysisResult
    },
    configuredPipelineName: string
    configuredPipelineImgtVersion: string
    configuredPipelineGenotypingResolution: GenotypingResolution
    priorStates: Array<AnalysisPriorState>
    isControlSample: boolean
    sequencingRunUuid: string
    sequencingRunId: string
    sampleUuid: string
    reagentKitLotName: string
    reagentKitLotQcStatus: ReagentKitLotQcStatus
    status: AnalysisStatus
}

export interface AnalysisWithResolutionDraft extends Analysis {
    maybeResolutionDraft: ResolutionDraft
}

export interface LocusGenotypeChange {
    locus: Locus
    old: Genotype
    new: Genotype
}

export function hasLabIssues(analysis: Analysis) {
    return getLabMetricsQuality(analysis) !== "grey"
}

export function getLabMetricsQuality(analysis: Analysis) {
    const labMetricNames: Array<keyof GeneralMetricsProblems> = [ "totalReads", "insertSize", "contamination" ]
    return labMetricNames.reduce<string>((overallQuality, metricName) => {
        const maybeQuality = analysis.result.problems?.generalMetrics?.[metricName]
        return maybeQuality && overallQuality !== "red"
            ? maybeQuality.quality
            : overallQuality
    }, "grey")
}

export function locusSpecificProblems(analysis: Analysis): Record<LocusSpecificProblem, [Locus, Problem][]> {
    return entriesOfPartial(analysis.result.problems?.locusSpecificMetrics ?? {})
        .reduce(
            (acc, [ locus, problems ]) => {
                entriesOfPartial(problems)
                    .forEach(([ name, problem ]) => updateOrSet(acc, name, [ locus, problem ]))
                return acc
            },
            {} as Record<LocusSpecificProblem, Array<[ Locus, Problem ]>>
        )
}

export function hasAnalysisIssues(analysis: Analysis, locus: Locus, quality: "red" | "yellow") {

    const locusAnalysisProblems = entriesOfPartial(analysis.result.problems?.locusSpecificMetrics?.[locus] ?? {})
        .filter(([ _, value ]) => value.type === "ANALYSIS_ISSUE" && value.quality === quality)
    return Boolean(locusAnalysisProblems.length)
}


export function hasSavedGenotype(analysis: Analysis, locus: Locus): analysis is AnalysisWithResolutionDraft {
    return analysis.maybeResolutionDraft?.genotypes?.[locus] !== undefined
}

/**
 * Get genotype called during data analysis
 *
 * Cannot be strictly null cause not called genotype is undefined genotype.
 */
export function getGenotype(analysis: Analysis, locus: Locus): Genotype {
    const maybeGenotype = analysis.result.genotypes[locus]
    return maybeGenotype ? Genotype.fromGLString(maybeGenotype) : Genotype.undefinedGenotype()
}

/**
 * Get genotype saved by support or null if support hasn't saved anything
 */
export function getSavedGenotype(analysis: Analysis, locus: Locus): Genotype | null {
    /*
     Complex semantics:
       - if locus is missing from maybeResolutionDraft.genotypes then no draft exists
       - if it's has null value then it's undefined genotype
       - otherwise there should be normal genotype
    */
    const maybeGenotype = analysis.maybeResolutionDraft?.genotypes?.[locus]
    if (maybeGenotype === undefined) {
        return null
    }
    return maybeGenotype ? Genotype.fromGLString(maybeGenotype) : Genotype.undefinedGenotype()
}

export function hasSavedNewAllele(analysis: Analysis, locus: Locus): analysis is AnalysisWithResolutionDraft {
    return analysis.maybeResolutionDraft?.newAlleles?.manuallyCreatedAlleles?.[locus] !== undefined
}

export function getSavedNewAllele(analysis: Analysis, locus: Locus): ResolutionDraftNewAlleles | null {
    const maybeLocusNewAllele = analysis.maybeResolutionDraft?.newAlleles?.manuallyCreatedAlleles?.[locus]
    if (maybeLocusNewAllele === undefined) {
        return null
    }
    const maybeNewAllele = analysis.maybeResolutionDraft?.newAlleles

    return maybeNewAllele ? maybeNewAllele : null
}

export function compareGLStringGenotypes(genotype: string, otherGenotype: string): boolean {
    return genotype === otherGenotype
}

/**
 * True if analysis is completed and no pipeline have error occurred
 */
export function hasBeenSuccessfullyAnalyzed(analysis: Analysis) {
    return analysis.status !== "ERROR" && analysis.status !== "RUNNING"
}

export function hasTerminalStatus(analysis: Analysis): boolean {
    return [
        AnalysisStatuses.COMPLETED,
        AnalysisStatuses.LAB_FAILURE,
        AnalysisStatuses.TYPING_FAULT,
    ].includes(analysis.status)
}

export function isNotTrivial(locusGenotypeChange: LocusGenotypeChange): boolean {
    return locusGenotypeChange.new.toGLString() !== locusGenotypeChange.old.toGLString()
}

export function applyGenotypesPatch(
    subject: RawGenotypes,
    patch: Partial<RawGenotypes>
): RawGenotypes {
    return { ...subject, ...patch }
}

export function diffGenotypes(
    oldGenotypes: RawGenotypes,
    newGenotypes: RawGenotypes
): Array<LocusGenotypeChange> {
    return LOCI
        .map(locus => ({
            locus,
            old: Genotype.fromGLString(oldGenotypes[locus]),
            new: Genotype.fromGLString(newGenotypes[locus])
        }))
        .filter(element => isNotTrivial(element))
}

export function parseRawGenotypes(rawGenotypes: RawGenotypes): Genotypes {
    return mapValues(rawGenotypes, genotype => Genotype.fromGLString(genotype))
}

export function worstQuality(qualities: Array<Quality>): Quality {
    return qualities.reduce(
        (worst, cur) => cur === "red" ? cur : worst === "green" ? cur : worst
        , "green"
    )
}

export function worstProblemsQuality(problems: Array<Problem>): Quality {
    return worstQuality(problems.map(it => it.quality))
}

export function analysisHasTooLittleReadsToBeAnalysed(analysis: Analysis): boolean {
    return analysis.status === "LAB_FAILURE"
        && analysis.result.resolution === "LAB_ISSUE"
        && analysis.result.problems?.generalMetrics?.totalReads?.value === 0
}
