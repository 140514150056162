import { ActionTree, MutationTree } from "vuex"
import { createPageableStore, PageableState, PageableStoreModule } from "@/store/modules/pageable-store"
import ld from "lodash"
import { markNotificationAsRead } from "@/endpoints"

interface Notification {
    id: string
    text: string
    createdAt: number
    isUnread: boolean
}

interface NotificationFilter {
    isUnread: boolean
    orderByCreatedAt: "ASC" | "DESC"
}


const RESOURCE_NAME = "notifications"

function createReadNotificationsStore(): PageableStoreModule<Notification, NotificationFilter> {

    const baseFilter: NotificationFilter = {
        isUnread: false,
        orderByCreatedAt: "DESC",
    }
    return createPageableStore<Notification, NotificationFilter>(RESOURCE_NAME, baseFilter, "read")
}

/*
 Note to my future self, submodule will not help here, cause we tightly intertwine new actions
 with state of underlying pageable store. Seems like this way is even better.
*/
function createNewNotificationsStore() {

    const baseFilter: NotificationFilter = {
        isUnread: true,
        orderByCreatedAt: "DESC",
    }

    const {
        module: {
            state,
            getters,
            actions: pageableStoreActions,
            mutations: pageableStoreMutations,
        },
        actionNames: pageableStoreActionNames,
        getterNames
    } = createPageableStore<Notification, NotificationFilter>(RESOURCE_NAME, baseFilter, "new")

    const newNotificationsActionNames = {
        ...pageableStoreActionNames,
        markAsRead: `${RESOURCE_NAME}/markAsRead`,
    }

    const actions: ActionTree<PageableState<Notification, NotificationFilter>, any> = {
        ...pageableStoreActions,
        [newNotificationsActionNames.markAsRead]({ commit, dispatch }, { id }) {
            commit("optimisticallyMarkNotificationAsReadMakingStateDirty", { id })
            // TODO: [@aslepchenkov 11.12.2019] Throw error to explain rollback of optimistic updates
            markNotificationAsRead(id)
                .then(() => dispatch(`${RESOURCE_NAME}/__debouncedRefetchResources`))
        },
        [`${RESOURCE_NAME}/__debouncedRefetchResources`]: ld.debounce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            ({ dispatch, state }) => dispatch(
                newNotificationsActionNames.startNewSearch,
                { filter: state.currentPage.filter }
            ),
            1000
        )
    }

    const mutations: MutationTree<PageableState<Notification, NotificationFilter>> = {
        ...pageableStoreMutations,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        optimisticallyMarkNotificationAsReadMakingStateDirty(state, { id }) {
            state.resourceList = state.resourceList.filter(it => it.id !== id)
        }
    }

    return {
        module: {
            state,
            mutations,
            actions,
            getters,
        },
        actionNames: newNotificationsActionNames,
        getterNames
    }
}


const { module: newNotifications, getterNames: newNotificationsGetterNames, actionNames: newNotificationsActionNames } = createNewNotificationsStore()
const { module: readNotifications, getterNames: readNotificationsGetterNames, actionNames: readNotificationsActionNames } = createReadNotificationsStore()
export {
    readNotifications,
    newNotifications,
    newNotificationsActionNames,
    newNotificationsGetterNames,
    readNotificationsActionNames,
    readNotificationsGetterNames,
}
