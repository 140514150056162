import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { FunctionalAnnotations } from "@/utils/analysis"
import { fillFalsyValues, mapKeys, mapValuesOfPartial } from "@/extensions/object-extensions"
import { LOCI } from "@/genotype"
import { KnownAmbiguities } from "@/utils/ambiguities"
import { pipelineEndpoints } from "@/endpoints"

type ConfiguredPipelines = {
    pipelines: {
        [name: string]: {
            knownAmbiguities: KnownAmbiguities,
            qualityControlThresholds: {}
        }
    }
}

function initialState(): ConfiguredPipelines {
    return {
        pipelines: {}
    }
}

export const configuredPipelinesActionNames = {
    fetchPipelineData: "",
}

const fillNameValues = (o: {}) => fillFalsyValues(key => `configuredPipelines/${key}`, o)
fillNameValues(configuredPipelinesActionNames)

const mutations: MutationTree<ConfiguredPipelines> = {
    resetState(state) {
        Object.assign(state, initialState())
    },
    setPipeline(state, pipeline: { name: string, data: any }) {
        state.pipelines[pipeline.name] = pipeline.data
    }
}

const actions: ActionTree<ConfiguredPipelines, any> = {
    async [configuredPipelinesActionNames.fetchPipelineData]({ commit, state }, pipelineName: string) {
        if (!state.pipelines[pipelineName]) {
            const [ knownAmbiguities, qualityControlThresholds, functionalAnnotations ] = await Promise
                .all([
                    pipelineEndpoints.fetchKnownAmbiguities(pipelineName),
                    pipelineEndpoints.fetchQualityControlThresholds(pipelineName),
                    pipelineEndpoints.fetchAlleleFunctionalAnnotations(pipelineName),
                ])
            commit("setPipeline", {
                name: pipelineName,
                data: {
                    knownAmbiguities,
                    qualityControlThresholds,
                    functionalAnnotations: parseFunctionalAnnotations(functionalAnnotations)
                }
            })
        }
        return state.pipelines[pipelineName]
    }
}

const getters: GetterTree<ConfiguredPipelines, any> = {}

export const configuredPipelines: Module<ConfiguredPipelines, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
}

// TODO: [@aslepchenkov 09.06.2020] Costyl to remove locus name present in pipelines file, we mostly get data without it
function parseFunctionalAnnotations(functionalAnnotations: FunctionalAnnotations): FunctionalAnnotations {
    return mapValuesOfPartial(
        functionalAnnotations,
        annotations => mapKeys(annotations, (allele) => removeLocusNames(allele)),
    )
}

export function removeLocusNames(s: string): string {
    return s.replace(new RegExp(`(${LOCI.join("|")})\\*`, "g"), "")
}
