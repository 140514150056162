<template functional>
    <div v-if="props.validationErrors === 'pending'" class="md-helper">
        {{ parent.$t("validating") }}...
    </div>
    <div v-else-if="props.validationErrors.length" class="md-errors">
        {{ props.validationErrors.map(it => parent.$t(`errorMessages.${it}`)).join("\n") }}
    </div>
</template>

<script>
    export default {
        name: "PInputErrors",
        functional: true,
        props: {
            validationErrors: {
                type: [ Array, String ],
                default() {
                    return []
                }
            }
        }
    }
</script>
