<template>
    <div @mouseleave="visible = false"
         @mouseover="visible = true"
         ref="tooltip-container"
         class="tooltip-container">
        <slot></slot>
        <transition name="tooltip">
            <div :class="['tooltip', position, fixed ? 'fixed' : 'absolute']"
                 :style="tooltipPosition"
                 v-show="visible">
                <slot name="tooltip"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "PWithTooltip",
        props: {
            position: String,
            fixed: Boolean
        },
        watch: {
            visible: {
                handler() {
                    /*
                     Pray that this watcher is called synchronously after data update and before v-show takes action.
                     Nothing criminal, but it's better to render element in desired place, than show
                     it in stale position and then move it.
                    */
                    if (this.fixed && this.$refs["tooltip-container"]) {
                        const { bottom, left } = this.$refs["tooltip-container"].getBoundingClientRect()
                        this.tooltipPosition = {
                            left: `${left}px`,
                            top: `${bottom}px`,
                        }
                    }
                }
            }
        },
        data() {
            return {
                visible: false,
                tooltipPosition: null
            }
        },
        /*
         Just some history remarks.
         Tried to set position in mounted, but it seems that after mount some elements are repositioned on render,
         although it's not visible in interface, and coordinates that were set became stale.
         $nextTick doesn't help. Only setTimeout (tried with 500ms delay).
         All the same after mounted elements can change position (e.g. after scroll), so we should
         calculate position every time before showing tooltip.
        */
    }
</script>

<style scoped>
    .tooltip-container {
        position: relative;
    }

    .tooltip {
        z-index: var(--fourth-layer);
        backface-visibility: hidden;
    }

    .tooltip-enter-active, .tooltip-leave-active {
        transition: all .3s;
    }

    .tooltip-enter, .tooltip-leave-to {
        opacity: 0;
    }

    .left {
        transform: translateX(calc(-100% - 3px)) translateY(-50%);
        left: 0;
        top: 50%;
    }

    .right {
        transform: translateX(calc(100% + 3px)) translateY(-50%);
        right: 0;
        top: 50%;
    }

    .top {
        transform: translateX(-50%) translateY(calc(-100% - 3px));
        top: 0;
        left: 50%;
    }

    .bottom {
        transform: translateX(-50%) translateY(calc(100% + 3px));
        bottom: 0;
        left: 50%;
    }

    .bottom-left {
        transform: translateX(-80%) translateY(0%);
        left: 0;
        top: 0;
    }

    .top-right {
        transform: translateX(100%) translateY(-6px);
    }

    .top-left {
        transform: translateX(-50%) translateY(-10rem);
        top: 50%;
        left: 0;
    }
</style>
