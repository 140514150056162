/**
 * Cellar with functions to work with ambiguities
 *
 * Implemented after http://confluence.corp.parseq.pro/confluence/pages/viewpage.action?pageId=58463711
 * Should be consistent with MERC pipeline implementation.
 *
 * @module utils/ambiguities
 */
import {
    Allele,
    compareAlleles,
    compareUnambiguousAlleles,
    ComparisonResult,
    Genotype,
    GENOTYPE_PARTS_SEPARATOR,
} from "@/genotype"
import { deduplicate } from "@/extensions/array-extensions"
import { mapKeysAndValuesOfPartial, mapValuesOfPartial } from "@/extensions/object-extensions"
import { removeLocusNames } from "@/store/modules/configured-pipelines"
import { LocusRecord } from "@/utils/analysis"

/**
 * MERC http://stash.corp.parseq.pro/stash/projects/MERC/repos/hla-quality-control/browse/hla_quality_control.py#183
 * Only full match counts
 */
export function isKnownAmbiguousGenotype(
    genotype: Genotype,
    knownAmbiguities: LocusKnownGenotypeAmbiguities
): boolean {
    if (!genotype.isAmbiguous) {
        return false
    }
    const genotypeParts = genotype.toGLString().split(GENOTYPE_PARTS_SEPARATOR)
    const firstGenotypePart = genotypeParts[0]
    const matchedAmbiguity = knownAmbiguities[firstGenotypePart]
    return matchedAmbiguity
        ? genotypeParts.every(part => matchedAmbiguity.includes(part))
        : false
}

/**
 * MERC http://stash.corp.parseq.pro/stash/projects/MERC/repos/hla-quality-control/browse/hla_quality_control.py#183
 * Only full match counts
 */
export function isKnownAmbiguousAllele(
    allele: Allele,
    knownAmbiguities: LocusKnownAlleleAmbiguities
): boolean {
    if (!allele.isAmbiguous) {
        return false
    }
    return allele.parts
        .map(part => findCorrespondingKnownAmbiguousAllelesGroup(part, knownAmbiguities))
        .filter(it => it)
        .map(it => new Allele(it!))
        .some(ambiguity => compareAlleles(ambiguity, allele) === ComparisonResult.EQUAL)
}


export function findCorrespondingKnownAmbiguousAllelesGroup(
    allelePart: string,
    knownAmbiguousAlleles: LocusKnownAlleleAmbiguities
): string[] | undefined {
    return knownAmbiguousAlleles[allelePart]
}

/**
 * Returns allele with all its parts supplemented to known ambiguities if needed
 *
 * @param allele allele to supplement
 * @param knownAmbiguousAlleles
 */
export function supplementAllele(
    allele: Allele,
    knownAmbiguousAlleles: LocusKnownAlleleAmbiguities
): Allele {
    const supplementedAlleles = deduplicate(
        allele.parts.flatMap(
            part =>
                findCorrespondingKnownAmbiguousAllelesGroup(part, knownAmbiguousAlleles) ?? part
        )
    )
    supplementedAlleles.sort(compareUnambiguousAlleles)
    return new Allele(supplementedAlleles)
}

function isOldKnownAmbiguitiesFormat(
    rawKnownGenotypes: OldKnownAmbiguities | KnownAmbiguities
): rawKnownGenotypes is OldKnownAmbiguities {
    return [
        Object.values(rawKnownGenotypes.knownAlleleAmbiguities).every(it => isOldAlleleAmbiguitiesFormat(it)),
        Object.values(rawKnownGenotypes.knownGenotypeAmbiguities).every(it => isOldGenotypeAmbiguitiesFormat(it)),
    ].every(it => it)
}

type OldLocusKnownAlleleAmbiguities = string[][]
export type LocusKnownAlleleAmbiguities = Record<string, string[]>
type OldLocusKnownGenotypeAmbiguities = string[][]
export type LocusKnownGenotypeAmbiguities = Record<string, string[]>

function isOldAlleleAmbiguitiesFormat(
    locusKnownAmbiguities: OldLocusKnownAlleleAmbiguities | LocusKnownAlleleAmbiguities
): locusKnownAmbiguities is OldLocusKnownAlleleAmbiguities {
    return Array.isArray(locusKnownAmbiguities)
}

function isOldGenotypeAmbiguitiesFormat(
    locusKnownAmbiguities: OldLocusKnownGenotypeAmbiguities | LocusKnownGenotypeAmbiguities
): locusKnownAmbiguities is OldLocusKnownGenotypeAmbiguities {
    return Array.isArray(locusKnownAmbiguities)
}

export type KnownAmbiguities = {
    knownAlleleAmbiguities: Partial<LocusRecord<LocusKnownAlleleAmbiguities>>
    knownGenotypeAmbiguities: Partial<LocusRecord<LocusKnownGenotypeAmbiguities>>
}

export type OldKnownAmbiguities = {
    knownAlleleAmbiguities: LocusRecord<OldLocusKnownAlleleAmbiguities>,
    knownGenotypeAmbiguities: LocusRecord<OldLocusKnownGenotypeAmbiguities>
}

/**
 * Turns known ambiguities file from MERC pipeline into format convenient for me to use
 *
 * I don't need extra locus names. Also due to file format switch in MERC-120 and need to utilize
 * both formats at the same time.
 *
 * @param rawKnownAmbiguities object (from JSON) with known ambiguities from MERC pipeline
 */
export function parseKnownAmbiguities(
    rawKnownAmbiguities: OldKnownAmbiguities | KnownAmbiguities
): KnownAmbiguities {
    const knownAmbiguities: KnownAmbiguities = isOldKnownAmbiguitiesFormat(rawKnownAmbiguities)
        ? {
            knownAlleleAmbiguities: convertToCurrentKnownAmbiguitiesFormat(rawKnownAmbiguities.knownAlleleAmbiguities),
            knownGenotypeAmbiguities: convertToCurrentKnownAmbiguitiesFormat(rawKnownAmbiguities.knownGenotypeAmbiguities)
        }
        : rawKnownAmbiguities

    return {
        knownAlleleAmbiguities: removeLocusNamesFromAmbiguities(knownAmbiguities.knownAlleleAmbiguities),
        knownGenotypeAmbiguities: removeLocusNamesFromAmbiguities(knownAmbiguities.knownGenotypeAmbiguities)
    }
}

function convertToCurrentKnownAmbiguitiesFormat<T extends keyof KnownAmbiguities>(
    ambiguities: Partial<OldKnownAmbiguities[T]>
): KnownAmbiguities[T] {
    return mapValuesOfPartial(
        ambiguities,
        it =>
            Object.fromEntries(it.flatMap(ambiguityGroup => ambiguityGroup.map(part => [ part, ambiguityGroup ])))
    )
}

function removeLocusNamesFromAmbiguities<T extends keyof KnownAmbiguities>(
    ambiguities: KnownAmbiguities[T]
): KnownAmbiguities[T] {
    return mapValuesOfPartial(
        ambiguities,
        it => mapKeysAndValuesOfPartial(
            it,
            key => removeLocusNames(key),
            value => value.map(part => removeLocusNames(part))
        )
    )
}

export function findRelatedKnownAmbiguousGenotypes(
    knownAmbiguities: LocusKnownGenotypeAmbiguities,
    genotype: Genotype
): Genotype[] {
    const knownAmbiguityKeys = Object.keys(knownAmbiguities)
    return deduplicate(
        genotype.genotypeParts
            .flatMap(genotypePart =>
                knownAmbiguityKeys.filter(
                    knownAmbiguityKey =>
                        Genotype.fromGLString(knownAmbiguityKey).genotypeParts.some(
                            knownAmbiguityPart =>
                                (genotypePart[0].intersects(knownAmbiguityPart[0]) && genotypePart[1].intersects(knownAmbiguityPart[1]))
                            || (genotypePart[0].intersects(knownAmbiguityPart[1]) && genotypePart[1].intersects(knownAmbiguityPart[0]))
                        )
                ))
            .filter(it => it)
            .map(genotypeGroupKey => knownAmbiguities[genotypeGroupKey].join(GENOTYPE_PARTS_SEPARATOR))
    ).map(it => Genotype.fromGLString(it))
}
