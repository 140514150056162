<template>
    <div class="analysis-details">
        <h2 style="text-align: center; grid-column: 1 / -1; margin-bottom: 3rem">{{ $t("sample") }} {{ analysis.name }}</h2>
        <div
            class="missing-results-placeholder"
            v-if="analysisHadTooLittleReadsToBeAnalysed"
        >
            {{ $t("tooLittleReadsToBeAnalysed") }}
        </div>
        <div
            v-else
            class="charts"
        >
            <chart-container
                    v-bind="readsDistributionTorChart"
                    id="reads-distribution-chart"
                    style="width: 25rem; height: 25rem; position: relative;"
            />
            <div class="relative">
                <chart-container
                        v-bind="insertSizeChart"
                        id="insert-size-chart"
                        style="width: 22rem; height: 25rem; position: relative;"
                        class="relative"
                        v-if="insertSizeChart"
                />
                <div class="chart-transparent-cover" v-if="realDataAbsent">
                    {{ $t("dataNotAvailable") }}<br>
                    {{ $t("runAnalysisInProgress") }}
                </div>
            </div>
            <div class="relative">
                <chart-container
                        v-bind="meanReadsQualityChart"
                        id="mean-reads-quality-chart"
                        style="width: 22rem; height: 25rem; position: relative;"
                        v-if="meanReadsQualityChart"
                />
                <div class="chart-transparent-cover" v-if="realDataAbsent">
                    {{ $t("dataNotAvailable") }}<br>
                    {{ $t("runAnalysisInProgress") }}
                </div>
            </div>
            <div class="relative w-100" style="grid-column: 1 / -1; max-width: 60rem;">
                <chart-container
                        v-bind="lociCoverageChart"
                        id="loci-coverage-chart"
                        v-if="lociCoverageChart"
                />
                <div class="chart-transparent-cover" v-if="realDataAbsent">
                    {{ $t("dataNotAvailable") }}<br>
                    {{ $t("runAnalysisInProgress") }}
                </div>
            </div>
        </div>
        <!-- eslint-disable vue/require-v-for-key -->
        <div class="mv-1 analysis-details--problems">
            <div class="box">
                <h3 class="mb-1 box-header">{{ $t("generalProblems") }}</h3>
                <div v-if="analysis.result.problems && analysis.result.problems.generalMetrics">
                    <div v-for="(problem, metricName) in analysis.result.problems.generalMetrics">
                        <span :class="problem.quality" class="badge badge-margin">{{ $t(`analysisMetrics.${metricName}`) }}</span>
                    </div>
                </div>
                <div style="font-size: 2rem; color: var(--grey-400); font-weight: 700;" v-else>
                    {{ $t("noProblems") }}
                </div>
            </div>
            <div class="box">
                <h3 class="mb-1 box-header">{{ $t("locusSpecificProblems") }}</h3>
                <div v-if="analysis.result.problems && Object.values(locusSpecificProblems).length">
                    <div v-for="(problems, metricName) in locusSpecificProblems">
                        {{ $t(`analysisMetrics.${metricName}`) }}:
                        <span :class="problem[1].quality"
                              class="badge badge-margin"
                              v-for="problem in problems"
                        >{{ problem[0] }}</span>
                    </div>
                </div>
                <div style="font-size: 2rem; color: var(--grey-400); font-weight: 700;" v-else>{{ $t("noProblems") }}</div>
            </div>
        </div>
        <!-- eslint-enable vue/require-v-for-key -->
    </div>
</template>

<script>
    import { ChartContainer } from "@/components/chart-container"
    import { drawChart as readsDistributionTorChart } from "@/charts/analysis-reads-distribution"
    import { drawChart as lociCoverageBoxPlot } from "@/charts/analysis-loci-coverage"
    import { drawChart as singleValueBoxPlot } from "@/charts/single-metric-boxplot"
    import { runsActionNames } from "@/store/modules/runs"
    import { Locales } from "@/i18n/main"
    import { analysisHasTooLittleReadsToBeAnalysed, locusSpecificProblems } from "@/utils/analysis"
    import { analysesForAnalysesDetailsChartsStubs } from "@/data-stubs/analyses-for-analyses-details-charts-stubs"

    export default {
        name: "AnalysisDetails",
        components: { ChartContainer },
        beforeMount() {
            this.data = null
            this.$store.dispatch(runsActionNames.fetchRunAnalyses, { id: this.analysis.sequencingRunUuid })
                .then(data => {
                    const allAnalysesReady = data
                        .map(it => it.status)
                        .every(it => it !== "ERROR" && it !== "RUNNING")
                    // eslint-disable-next-line promise/always-return
                    if (allAnalysesReady) {
                        this.data = data
                        this.realDataAbsent = false
                    } else {
                        /*
                         I wanna show blurred chart stubs when real data is not yet available.
                         So I replace real data with this stub.
                         */
                        this.data = analysesForAnalysesDetailsChartsStubs
                        this.realDataAbsent = true
                    }
                })
        },
        props: {
            analysis: Object
        },
        data() {
            return {
                data: null,
                realDataAbsent: null
            }
        },
        computed: {
            readsDistributionTorChart() {
                return {
                    data: Object.entries(this.analysis.result.qualityMetrics.readsDistribution),
                    chartConstructorFn: data => readsDistributionTorChart(data, "#reads-distribution-chart", this.$i18n)
                }
            },
            lociCoverageChart() {
                return this.analysesWithData
                    ? {
                        data: {
                            sameRunAnalyses: this.analysesWithData,
                            currentAnalysis: this.analysis
                        },
                        chartConstructorFn: data => lociCoverageBoxPlot(data, "#loci-coverage-chart", this.$i18n)
                    }
                    : null
            },
            insertSizeChart() {
                return this.analysesWithData
                    ? {
                        data: {
                            sameRunAnalysesMetric: this.analysesWithData.map(it => it.result.qualityMetrics.insertSize),
                            currentAnalysisMetric: this.analysis.result.qualityMetrics.insertSize
                        },
                        chartConstructorFn: data => singleValueBoxPlot(data, "#insert-size-chart", "insertSize", this.$i18n, "bp")
                    } : null
            },
            meanReadsQualityChart() {
                return this.analysesWithData
                    ? {
                        data: {
                            sameRunAnalysesMetric: this.analysesWithData.map(it => it.result.qualityMetrics.meanReadsQuality),
                            currentAnalysisMetric: this.analysis.result.qualityMetrics.meanReadsQuality
                        },
                        chartConstructorFn: data => singleValueBoxPlot(
                            data,
                            "#mean-reads-quality-chart",
                            "meanReadsQuality",
                            this.$i18n,
                            "phred",
                            { fractionDigits: 2 }
                        )
                    }
                    : null
            },
            locusSpecificProblems() {
                return locusSpecificProblems(this.analysis)
            },
            analysesWithData() {
                return this.data
                    ? this.data.filter(it => !analysisHasTooLittleReadsToBeAnalysed(it))
                    : null
            },
            analysisHadTooLittleReadsToBeAnalysed() {
                return analysisHasTooLittleReadsToBeAnalysed(this.analysis)
            },
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    generalProblems: "General problems",
                    locusSpecificProblems: "Locus specific problems",
                    // I'm not sure that this is really better that No problems.
                    noProblems: "Not found",
                    sample: "Sample",
                    // I've done "research" about verb - https://grammarist.com/usage/data/
                    dataNotAvailable: "Data is not available",
                    runAnalysisInProgress: "Run analysis in progress",
                },
                [Locales.RU]: {
                    generalProblems: "Общие проблемы",
                    locusSpecificProblems: "Локус специфичные проблемы",
                    noProblems: "Не обнаружены",
                    sample: "Образец",
                    dataNotAvailable: "Данные недоступны",
                    runAnalysisInProgress: "Идет анализ запуска",
                }
            }
        }
    }
</script>

<style scoped>

    .analysis-details {
        display: grid;
        grid-template-columns: 1fr 30rem;
        grid-template-rows: min-content 1fr;
        grid-column-gap: 2rem;
        min-height: 67rem;
    }

    .charts {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-items: center;
    }

    #loci-coverage-chart {
        width: 100%;
        max-width: 60rem;
        height: 30rem;
        position: relative;
    }

    .analysis-details--problems {
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .box {
        margin: 2rem 0;
        border: 1px solid var(--grey-400);
        padding: 2rem;
        border-radius: 4px;
        position: relative;
    }

    .box-header {
        position: absolute;
        padding: .8rem;
        top: -1.7rem;
        left: 1rem;
        font-size: 1.1rem;
        font-weight: normal;
        background-color: white;
    }

    .chart-transparent-cover {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 5px);
        height: calc(100% + 5px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6));
        font-weight: 600;
        color: var(--grey-700);
    }

    @supports (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) {

        .chart-transparent-cover {
            -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
        }
    }

    .missing-results-placeholder {
        display: flex;
        padding: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 2rem;
        color: var(--grey-700);
    }

</style>
