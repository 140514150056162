import { SampleForUpload } from "@/store/modules/sample"
import Vue from "vue"

export class Lot {
    private _samples: { [runId: string]: SampleForUpload } = {}
    public controlSample: SampleForUpload | undefined = undefined

    constructor(
        public name: string,
        public id: string,
        sampleMap: Map<string, SampleForUpload> = new Map()
    ) {
        sampleMap.forEach(value => this._samples[value.runId] = value)
    }

    get samples() {
        return Object.values(this._samples)
    }

    get samplesOrderedByRunId() {
        /*
         I'm not so sure that this logic should be here, cause potentially we could have samples with different
         run id convention on some other sequencing platform
        */
        return this.samples.sort((a, b) => Number.parseInt(a.runId.slice(1)) - Number.parseInt(b.runId.slice(1)))
    }

    get samplesAsMap() {
        return new Map(Object.entries(this._samples))
    }

    isEmpty() {
        return Object.keys(this._samples).length === 0
    }

    addSample(sample: SampleForUpload) {
        Vue.set(this._samples, sample.runId, sample)
    }

    removeSample(runId: string) {
        Vue.delete(this._samples, runId)
    }

    removeAllSamples() {
        Vue.set(this, "_samples", {})
    }


}
