import { SequencingKit, SequencingPlatform } from "@/store/modules/sequencing-platform"

export function readFile(file: File): Promise<string | ArrayBuffer | null> {
    const reader = new FileReader()
    const fileContentPromise: Promise<string | ArrayBuffer | null> = new Promise((resolve, reject) => {
        // Mad casts. Seems that event target is the same FileReader
        reader.addEventListener("load", event => resolve((<FileReader>event.target!).result))
        reader.addEventListener("error", event => reject(event))
    })
    reader.readAsText(file)
    return fileContentPromise
}

export function parseRunInfo(platform: SequencingPlatform, fileContent: string): RunMetadata {
    const parser = new DOMParser()
    const runInfo = parser.parseFromString(fileContent, "text/xml")

    const run = getFirstElementByTagName(runInfo, "Run")
    const idAttribute = run.getAttribute("Id")
    if (idAttribute === null) {
        throw {
            type: "idIsMissing"
        }
    }
    const runIdMaskMatch = new RegExp(platform.properties.runIdMask).exec(idAttribute)
    if (runIdMaskMatch === null || runIdMaskMatch.length !== 2) {
        throw {
            type: "idNotMatchMask",
        }
    }
    const id = runIdMaskMatch[1]
    const flows = [ ...getFirstElementByTagName(run, "Reads").querySelectorAll("Read") ]
        .filter(it => it.getAttribute("IsIndexedRead") === "N")
        .map(it => it.getAttribute("NumCycles"))
        .join(" + ")
    const date = getFirstElementByTagName(run, "Date").textContent!
    return {
        id,
        sequencingKit: inferSequencingKit(platform, runInfo),
        flows,
        date: parseDate(date)
    }
}

/**
 * Infers sequencing kit from runInfo using tileCount and swathCount fields.
 * Null will be returned if sequencing kit cannot be inferred properly
 */
function inferSequencingKit(platform: SequencingPlatform, runInfo: Document) {
    const flowcellLayout = getFlowcellLayout(runInfo)
    const tileCount = getTileCount(flowcellLayout)
    const swathCount = getSwathCount(flowcellLayout)
    const filtered = platform.sequencingKits.filter(it =>
        (it.properties.tileCount == tileCount) &&
        (it.properties.swathCount ? it.properties.swathCount == swathCount : true))
    if (filtered.length !== 1) {
        // eslint-disable-next-line no-console
        console.error(`Haven't found sequencing kit with tileCount: ${tileCount} and swathCount ${swathCount}`)
        throw {
            type: "unknownSequencingKit"
        }
    }
    return filtered[0]
}

function getFirstElementByTagName(parent: Element | Document, name: string) {
    const t = parent.querySelectorAll(name)[0]
    if (t) {
        return t
    } else {
        throw {
            type: "fileNotMatchSelectedPlatform"
        }
    }
}

function getFlowcellLayout(run: Element | Document) {
    return getFirstElementByTagName(run, "FlowcellLayout")
}

function getTileCount(flowcellLayout: Element) {
    return flowcellLayout.getAttribute("TileCount")
}

function getSwathCount(flowcellLayout: Element) {
    return flowcellLayout.getAttribute("SwathCount")
}

/**
 * Parses date from RunInfo.xml
 *
 * @param dateString date as string in format yymmdd
 */
function parseDate(dateString: string) {
    const [ year, month, day ] = [ dateString.slice(0, 2), dateString.slice(2, 4), dateString.slice(4, 6) ]
    // Months start from 0, 0 Carl!!!!
    return new Date(2000 + Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day))
}

export interface RunMetadata {
    id: string,
    flows: string,
    sequencingKit: SequencingKit
    date: Date,
}
