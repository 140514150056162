<template>
    <div style="padding: 1rem;"
         :style="{ backgroundColor: isEmpty ? '#dddddd' : 'white' }">
        <h4 class="mb-1">Hisat</h4>
        <div style="display: flex; align-items: flex-start; justify-content: space-between;">
            <table
                v-if="filteredRankedAlleles.length > 0"
                style="text-align: center;"
                :class="{ 'w-100': uniqueCoveredAlleles.length === 0 }"
            >
                <tbody>
                <!-- eslint-disable-next-line-->
                <tr class="row" v-for="allele in filteredRankedAlleles">
                    <td class="row-cell">
                        <allele-component
                            :highlight="targetAllele"
                            :allele="allele.allele"
                        />
                    </td>
                    <td class="row-cell">{{ allele.abundance }}%</td>
                </tr>
                </tbody>
            </table>
            <template v-if="uniqueCoveredAlleles.length > 0">
                <ul
                    v-if="filteredUniqueCoveredAlleles.length"
                    class="ml-auto"
                    style="text-align: center;"
                >
                    <!-- eslint-disable-next-line-->
                    <li class="row" v-for="allele in filteredUniqueCoveredAlleles">
                        <allele-component
                            class="row-cell"
                            :highlight="targetAllele"
                            :allele="allele"
                        />
                    </li>
                </ul>
            </template>
            <div v-if="isEmpty">
                {{ $t("selectedAlleleNotFound") }}
            </div>
        </div>
    </div>
</template>

<script>
    import { Allele, allelesEqual } from "@/genotype"

    import AlleleComponent from "./allele"

    export default {
        name: "HisatResults",
        components: {
            AlleleComponent
        },
        computed: {
            rankedAlleles() {
                return this.results.rankedAlleles.map(it => ({ ...it, allele: Allele.fromGLString(it.allele) }))
            },
            filteredRankedAlleles() {
                return this.targetAllele
                    ? this.rankedAlleles.filter(it => it.allele.includes(this.targetAllele))
                    : this.rankedAlleles
            },
            uniqueCoveredAlleles() {
                return this.results.coveredAlleles
                    .map(it => Allele.fromGLString(it))
                    .filter(it => !this.rankedAlleles.some(rankedAllele => allelesEqual(it, rankedAllele.allele)))
            },
            filteredUniqueCoveredAlleles() {
                return this.targetAllele
                    ? this.uniqueCoveredAlleles.filter(it => it.includes(this.targetAllele))
                    : this.uniqueCoveredAlleles
            },
            isEmpty() {
                return this.filteredUniqueCoveredAlleles.length === 0 && this.filteredRankedAlleles.length === 0
            }
        },
        props: {
            results: Object,
            targetAllele: String
        }
    }
</script>

<style scoped>
    .row-cell {
        padding: .5rem 1rem .5rem 0;
    }
</style>
