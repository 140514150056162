<template>
    <div class="create-new-allele-modal">
        <div
                class="overflow-auto custom-scroll grid-full-row mb-3 flex"
            >
                <h2 class="mr-1">{{ $t("newAllele") }}</h2>
                <div class="flex" style="flex-wrap: wrap">
                    <!-- eslint-disable-next-line-->
                    <div
                        class="monospace bold flex"
                        style="padding: .5rem 0rem;"
                        v-for="(genotypePart, i) in genotype"
                    >
                            <span
                                v-if="i !== 0"
                                class="mh-1 monospace bold"
                            >|</span>
                        <span class="flex">
                            <span
                                :style="{ color: alleleColors[2 * i], opacity: '50%' }"
                                :class="{ selected: genotypeSelect === 2 * i }"
                                class="allele"
                            >
                                <span class="allele--text">{{ genotypePart[0].toGLString() }}</span>
                            </span>
                            <span class="mh-1">+</span>
                            <span
                                :style="{ color: alleleColors[2 * i + 1], opacity: '50%' }"
                                class="allele"
                                :class="{ selected: genotypeSelect === 2 * i + 1 }"
                            >
                                <span class="allele--text">{{ genotypePart[1].toGLString() }}</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        <div class="create-new-allele-form overflow-auto mb-4">
                    <transition :name="transition" mode="out-in">
            <component
                :is="activeStep.component"
                class="active-step"
                v-bind="activeStep.properties"
                v-on="activeStep.listeners"
            />
        </transition>
        </div>
        <div class="wizard-controls">
            <button @click="previous"
                    id="wizard-previous-btn"
                    class="md-btn-outlined wizard-btn previous-btn"
                    v-if="hasPreviousStep"
            >{{ $t("buttons.previous") }}
            </button>
            <button @click="closeModal"
                    id="wizard-previous-btn"
                    class="md-btn-text wizard-btn previous-btn"
                    v-if="!hasPreviousStep"
            >{{ $t("buttons.close") }}
            </button>
            <button @click="next"
                    id="wizard-next-btn"
                    :disabled="!canGoToNextStep"
                    class="md-btn-contained wizard-btn ml-auto royal-blue"
                    v-if="hasNextStep"
            >{{ $t("buttons.next") }}
            </button>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { Locales } from "@/i18n/main"
    import { ValidatableInput, StandaloneValidatableInput } from "@/validation/validatable-input"
    import { required, requiredStandalone, createReferenceAlleleValidator } from "@/validation/validators"
    import { EventBus } from "@/event-bus"
    import { mapValues } from "@/extensions/object-extensions"
    import { supportEndpoints } from "@/endpoints"

    import ChooseMetadateNewAllele from "./choose-metadate-new-allele-step"
    import StartUploadStep from "./start-upload-step"

    export default {
        name: "CreateNewAlleleForm",
        components: {
            ChooseMetadateNewAllele,
            StartUploadStep
        },
        props: {
            genotype: Object,
            genotypeSelect: Number,
            analysis: Object,
            alleleIndex: Number,
            genotypePartIndex: Number,
            activeLocus: String,
            alleleColors: Array,
            closeModal: Function,
            toggleNewAllele: Function
        },
        data() {
            return {
                newAlleleForm: new NewAlleleForm(new EventBus()),
                activeStepIndex: 0,
                transition: "next",
                formIsValid: null,
                progress: {
                    loaded: 0,
                    total: 0
                }
            }
        },
        computed: {
            activeStep() {
                return this.steps[this.activeStepIndex]
            },
            hasNextStep() {
                return this.activeStepIndex < this.steps.length - 1
            },
            hasPreviousStep() {
                return this.activeStepIndex > 0
            },
            canGoToNextStep() {
                return this.steps[this.activeStepIndex].isDone()
            },
            steps() {
                return [
                    {
                        name: this.$t("fillInData"),
                        component: "ChooseMetadateNewAllele",
                        properties: {
                            newAlleleForm: this.newAlleleForm,
                        },
                        isDone: () => {
                            return this.newAlleleForm.isValidSync === true
                        },
                    },
                    {
                        name: this.$t("attachFASTA"),
                        component: "StartUploadStep",
                        properties: {
                            newAlleleForm: this.newAlleleForm,
                            handleFileDrop: this.handleFileDrop,
                            progress: this.progress
                        },
                    },
                ]
            },
            couldSave() {
                console.debug(`formIsValid = ${this.formIsValid}`)
                return this.formIsValid
            }
        },
        methods: {
            next() {
                if (this.canGoToNextStep) this.activeStep.onExit?.()
                this.transition = "next"
                this.activeStepIndex += 1
            },
            previous() {
                this.activeStep.onGoBack?.()
                this.transition = "previous"
                this.activeStepIndex -= 1
            },
            getfileConsesusUpload(genotypePartIndex, alleleIndex) {
                return supportEndpoints.getNewAlleleTemporaryConsensusFastaLink(this.$store.state.support.activeRun, this.analysis, this.activeLocus, genotypePartIndex, alleleIndex)
                    .then(({ data })=> data)
            },
            loader(e) {
                this.progress = {
                    loaded: e.loaded,
                    total: e.total
                }
            },
            fileUpload(link) {
                this.transition = null

                return axios.request({
                    method: "PUT",
                    url: link,
                    data: this.newAlleleForm.alleleSequenceFile.value,
                    onUploadProgress: this.loader
                })
            },
            saveResourceNewAllele(response) {
                if(response.status !== 200) {
                    throw new Error(response.statusText)
                }

                this.toggleNewAllele({
                    referenceAllele: this.newAlleleForm.referenceAllele.value,
                    geneticVariants: this.newAlleleForm.variantsValueAsObject
                }, this.genotypePartIndex, this.alleleIndex)

                this.closeModal()
            },
            handleFileDrop(files){
                if (files.length !== 1) {
                    // TODO: [@aslepchenkov 10.02.2021] Show error
                }
                const file = files[0]
                if (!(file.name.endsWith("fasta") || file.name.endsWith("fa"))) {
                    // TODO: [@aslepchenkov 10.02.2021] Show error
                }

                this.newAlleleForm.alleleSequenceFile.value = file

                // eslint-disable-next-line consistent-return
                return this.getfileConsesusUpload(this.genotypePartIndex, this.alleleIndex)
                    .then(this.fileUpload)
                    .then(this.saveResourceNewAllele)
                    .catch(error => {
                        console.error(error)
                        this.newAlleleForm.alleleSequenceFile.value = null
                    })
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    newAllele: "New allele",
                    fillInData: "Fill in the data",
                    attachFASTA: "Attach file FASTA"
                },
                [Locales.RU]: {
                    newAllele: "Новая аллель",
                    fillInData: "Заполните данные",
                    attachFASTA: "Прекрепите файл FASTA"
                }
            }
        }
    }

    /*
     *   FIXME: [@bbatanov 03.03.21] simple implementation without much validation, I would like to see the implementation for the most powerful
     */
    class NewAlleleForm {
        constructor(eventBus) {
            this.referenceAllele = new ValidatableInput("referenceAllele", eventBus, createReferenceAlleleValidator())
            this.alleleSequenceFile = new ValidatableInput("alleleSequenceFile", eventBus, required("true"))
            this.variants = new ValidatableInput("variants", eventBus, required("variants"), new Array(createEmptyVariant()))
        }

        async isValid() {
            const validationResults = await Promise.all([
                this.referenceAllele.isValid(),
                this.alleleSequenceFile.isValid(),
                this.variants.isValid(),
            ])
            return validationResults.every(it => it)
        }

        get isValidSync() {
            return [
                this.referenceAllele.isValidSync,
                ...this.variants.value.reduce((accumulator, variant) => {
                    return accumulator.concat([
                        variant.region.isValidSync,
                        variant.genomicHgvs.isValidSync,
                        variant.proteinHgvs.isValidSync,
                    ])
                }, []),
            ].every(it => it === true)
        }

        get valuesAsObject() {
            return mapValues(this, (value) => value.value)
        }

        get variantsValueAsObject() {
            return [
                ...this.variants.value.map((variant) => {
                    return {
                        region: variant.region.value,
                        genomicHgvs: variant.genomicHgvs.value,
                        proteinHgvs: variant.proteinHgvs.value,
                    }
                }),
            ]
        }
    }

    function createEmptyVariant() {
        return {
            region: new StandaloneValidatableInput(requiredStandalone, ""),
            genomicHgvs: new StandaloneValidatableInput(requiredStandalone, ""),
            proteinHgvs: new StandaloneValidatableInput(requiredStandalone, ""),
        }
    }
</script>

<style scoped>

.allele {
    border-radius: 4px;
    padding: .5rem 1rem;
    border: 2px solid currentColor;
    transition: all .2s;
}

.allele.selected {
    background-color: currentColor;
}

.allele.selected .allele--text {
    color: black;
}

.create-new-allele-form {
    flex-grow: 1;
}

.create-new-allele-modal {
    padding: 2rem 3rem 1.5rem;
    height: 80vh;
    max-width: 80vw;

    display: flex;
    flex-flow: column;
}

.wizard-controls {
    grid-area: wizardControls;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wizard-btn {
    font-size: 1.6rem;
}

.previous-btn {
    color: royalblue;
}

.previous-enter-active, .previous-leave-active,
.next-enter-active, .next-leave-active {
    transition: all .4s;
}

.previous-enter-active, .previous-leave-active,
.next-enter-active, .next-leave-active {
    transition: all .4s;
}

.next-enter, .next-leave-to, .previous-enter, .previous-leave-to {
    opacity: 0;
}

.next-enter {
    transform: translateX(20%);
}

.next-leave-to {
    transform: translateX(-20%);
}

.previous-enter {
    transform: translateX(-20%);
}

.previous-leave-to {
    transform: translateX(20%);
}
</style>
