<template>
    <div class="swimlane">
        <h2 class="swimlane--header">{{ runsNumber }} {{ $t(status) }}</h2>
        <div class="swimlane--runs-list overflow-auto">
            <div class="swimlane--top-opacity-filter"></div>
            <div :ref="`runs-${status}`"
                 @scroll="onScroll"
                 class="custom-scroll overflow-auto h-100"
                 style="padding-bottom: 7rem;">
                <RunCard :key="run.id"
                         :run="run"
                         :status="status"
                         class="run-card"
                         @run-exported="reload"
                         v-for="run in runs"></RunCard>
            </div>
            <div class="swimlane--opacity-filter"></div>
        </div>
    </div>
</template>

<script>
    import {
        alreadyRunsActionNames,
        alreadyRunsGetterNames,
        notReadyRunsActionNames,
        notReadyRunsGetterNames,
        readyRunsActionNames,
        readyRunsGetterNames,
        SequencingRunFilter,
    } from "@/store/modules/runs"
    import { Locales } from "@/i18n/main"

    import RunCard from "./run-card"


    export default {
        name: "RunsSwimlane",
        components: { RunCard },
        props: {
            status: {
                type: String,
                required: true
            },
            /** Already "prepared" sequencing run filter ({@see SequencingRunFilter}) */
            filter: {
                type: Object,
                required: true
            }
        },
        watch: {
            filter() {
                this.startNewSearch()
            }
        },
        computed: {
            runs() {
                return this.$store.getters[this.getters.resourceList]
            },
            runsNumber() {
                return this.$store.getters[this.getters.resourceNumber]
            },
            fetchInProgress() {
                return this.$store.getters[this.getters.fetchInProgress]
            },
        },
        beforeCreate() {
            switch (this.$options.propsData.status) {
                case "NOT_READY":
                    this.getters = notReadyRunsGetterNames
                    this.actions = notReadyRunsActionNames
                    break
                case "READY":
                    this.getters = readyRunsGetterNames
                    this.actions = readyRunsActionNames
                    break
                case "ALREADY":
                    this.getters = alreadyRunsGetterNames
                    this.actions = alreadyRunsActionNames
                    break
                default:
                    console.error(`Wrong run export status: ${this.$options.propsData.status}`)
            }
        },
        created() {
            this.startNewSearch()
        },
        methods: {
            onScroll() {
                const resourceListScrollableContainer = this.$refs[`runs-${this.status}`]
                const scrollPercent = resourceListScrollableContainer.scrollTop / (resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight)
                if (scrollPercent > 0.9 && !this.fetchInProgress && (this.runs.length !== this.runsNumber)) {
                    this.$store.dispatch(this.actions.continueSearch)
                }
            },
            startNewSearch() {
                const resourceListScrollableContainer = this.$refs[`runs-${this.status}`]

                this.$store
                    .dispatch(this.actions.startNewSearch, { filter: this.filter })
                    .then(() => {
                        if (resourceListScrollableContainer) {
                            resourceListScrollableContainer && resourceListScrollableContainer.scrollTo(0, 0)
                        }
                        return null
                    })
                    .then(() => this.fetchRunsTillScrollAppearsOrTheyEnd())
                    .catch(() => { /* Bypass to avoid uncaught promise rejection */
                    })
            },
            async fetchRunsTillScrollAppearsOrTheyEnd() {
                const resourceListScrollableContainer = this.$refs[`runs-${this.status}`]
                let scrollIsMissing = () => resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight === 0
                while (scrollIsMissing() && this.runs.length !== this.runsNumber) {
                    await this.$store.dispatch(this.actions.continueSearch)
                }
            },
            reload() {
                if (this.status === "READY") {
                    this.$store.dispatch(readyRunsActionNames.startNewSearch, { filter: this.filter })
                    this.$store.dispatch(alreadyRunsActionNames.startNewSearch, { filter: this.filter })
                }
            }
        },
        mounted() {
            this.fetchRunsTillScrollAppearsOrTheyEnd()
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "NOT_READY": "NOT READY",
                    "ALREADY": "EXPORTED",
                    "READY": "READY"
                },
                [Locales.RU]: {
                    "NOT_READY": "НЕ ГОТОВЫ",
                    "ALREADY": "ЭКСПОРТИРОВАНЫ",
                    "READY": "ГОТОВЫ"
                }
            }
        }
    }
</script>

<style scoped>
    .swimlane {
        display: grid;
        grid-template-rows: min-content 1fr;
        min-width: 39rem;
        width: fit-content;
        overflow: auto;
        height: 100%;
        position: relative;
    }

    .swimlane--opacity-filter {
        position: absolute;
        bottom: 0;
        z-index: 0;
        height: 6rem;
        background: linear-gradient(transparent, rgba(255, 255, 255, 0.85) 50%, white);
        width: 95%
    }

    .swimlane--runs-list {
        position: relative;
    }

    .swimlane--top-opacity-filter {
        position: absolute;
        top: 0;
        z-index: 0;
        height: 4rem;
        background: linear-gradient(white, rgba(255, 255, 255, 0.25) 50%, transparent);
        width: 95%
    }

    .swimlane--header {
        margin-bottom: 2rem;
        text-align: center;
        color: #7F7F7F;
    }

    .run-card {
        width: 35rem;
        margin: 2rem;
    }
</style>
