<template>
    <div class="mb-2 overflow-auto custom-scroll" style="font-size: 1.6rem; height: 75rem; padding: 1rem">
        <div>
            <h3 class="h3 mb-2">{{ $t("profile") }}</h3>
            <div>
                <table style="table-layout: fixed; width: 50rem">
                    <tbody>
                    <tr>
                        <td>{{ $t("name") }}</td>
                        <td>{{ user.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("authorities") }}</td>
                        <td>
                        <span v-if="user.authorities.length === 1">
                            {{ $t(`roles.${user.authorities[0]}`) }}
                        </span>
                            <ul class="bullet-list"
                                v-else
                            >
                                <li :key="role"
                                    class="bullet-list__element"
                                    v-for="role in user.authorities"
                                >
                                    {{ $t(`roles.${role}`) }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t("fullName") }}</td>
                        <td>{{ user.fullName }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("organizationOfficialName") }}</td>
                        <td>{{ user.organizationOfficialName }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            <h3 class="h3 mb-2 mt-3">{{ $t("settings") }}</h3>
            <div class="flex mb-2" style="justify-content: space-between">
                <div
                    v-if="getBrandName ==='PARSEQ_NAME'"
                    class="flex relative toggle md-outlined mr-1"
                    style="min-width: fit-content"
                >
                    <span
                        class="md-label filled"
                    >
                        {{ $t("language") }}
                    </span>
                    <button :class="{ 'active': locale === $i18n.locale }"
                            @click="changeLanguage(locale)"
                            class="toggle-option"
                            :key="locale"
                            v-for="locale in $i18n.availableLocales"
                    >
                        {{ locale }}
                    </button>
                </div>
                <p-select
                    style="width: 30rem"
                    class="md-outlined"
                    :options="[user.timeZone]"
                    :placeholder="$t('timezone')"
                    v-model="user.timeZone"
                    disabled
                />
            </div>
            <div class="user-email">
                <p-input
                    class="md-outlined filters--input user-email--input"
                    :placeholder="$t('userEmail')"
                    type="text"
                    v-model="userEmail"
                    :disabled="!userEmailIsUnderModification"
                />
                <button
                    class="toggle-option user-email--btn"
                    @click="toggleUserEmailModificationState"
                    v-if="!userEmailIsUnderModification"
                >
                    {{ $t("editUserEmail") }}
                </button>
                <button
                    class="toggle-option user-email--btn active"
                    @click="changeEmail"
                    :disabled="!userEmail"
                    v-else
                >
                    {{ $t("saveUserEmail") }}
                </button>
            </div>
            <div>
                <h4 class="h4 mb-1 mt-2"></h4>
                <fieldset class="subscriptions">
                    <legend class="h4">{{ $t("subscriptions.title") }}</legend>
                    <label
                        class="subscription"
                        :key="subscriptionName"
                        v-for="(isSubscribed, subscriptionName) in user.subscriptions"
                    >
                        <input
                            type="checkbox"
                            :checked="isSubscribed"
                            @input="toggleUserEmailNotificationSubscription(subscriptionName)"
                            class="mr-1"
                        >
                        <span>{{ $t(`subscriptions.${subscriptionName}`) }}</span>
                    </label>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import { mapGetters } from "vuex"
    import {
        setUserEmail,
        setUserLanguage,
        subscribeForEmailNotification,
        unsubscribeFromEmailNotification,
    } from "@/endpoints"
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "UserProfile",
        computed: {
            ...mapGetters([
                "user",
            ]),
            getBrandName() {
                const { brandName } = useBrand()
                return brandName
            }
        },
        data() {
            return {
                userEmail: this.$store.getters.user.maybeEmail,
                userEmailIsUnderModification: false,
            }
        },
        methods: {
            toggleUserEmailModificationState() {
                this.userEmailIsUnderModification = !this.userEmailIsUnderModification
            },
            toggleUserEmailNotificationSubscription(subscriptionName) {
                return this.user.subscriptions[subscriptionName]
                    // TODO: use actions end move API call to store
                    ? unsubscribeFromEmailNotification(subscriptionName)
                        .then(() => this.$store.commit("unsubscribeFromEmailNotification", subscriptionName))
                    : subscribeForEmailNotification(subscriptionName)
                        .then(() => this.$store.commit("subscribeForEmailNotification", subscriptionName))
            },
            changeEmail() {
                return setUserEmail(this.userEmail)
                    .then(() => this.$store.commit("setUserEmail", this.userEmail))
                    .then(() => this.toggleUserEmailModificationState())
            },
            changeLanguage(locale) {
                let backendCompatibleLanguage
                switch (locale) {
                    case Locales.EN:
                        backendCompatibleLanguage = "EN"
                        break
                    case Locales.RU:
                        backendCompatibleLanguage = "RU"
                        break
                }
                setUserLanguage(backendCompatibleLanguage)
                    .then(() => this.$root.$i18n.locale = locale)
            },
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "language": "Language",
                    "authorities": "Roles",
                    "fullName": "Full name",
                    "organizationOfficialName": "Organization",
                    "name": "Name"
                },
                [Locales.RU]: {
                    "language": "Язык",
                    "authorities": "Роли",
                    "fullName": "ФИО",
                    "organizationOfficialName": "Организация",
                    "name": "Имя"
                },
            }
        }
    }
</script>

<style scoped>
.toggle {
}

.toggle-option {
    border: 1px solid var(--grey-500);
    padding: .4rem 1.4rem;
    text-transform: uppercase;
    min-height: 3em;
}

.toggle-option:first-of-type {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.toggle-option:not(:last-of-type) {
    border-right: none;
}

.toggle-option:last-of-type {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.toggle-option.active {
    font-weight: 600;
}

.toggle-option:not(.active) {
    color: var(--grey-500);
}

td {
    padding: .7rem 1.4rem;
}

.user-email {
    display: grid;
    grid-template-areas: 'userEmailInput userEmailModificationAction';
    grid-template-rows: auto min-content;
}

.user-email--input {
    grid-area: userEmailInput;
}

.user-email--btn {
    grid-area: userEmailModificationAction;
    margin-left: -3px;
    z-index: 1;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: white;
}

.subscriptions {
    border: none;
    display: flex;
    flex-flow: column;
}

.subscription {
    border: 2px solid transparent;
    cursor: pointer;
    padding: .5rem 1rem;
}

.subscription:focus-within {
    border: 2px solid black;
    border-radius: 4px;
}
</style>
