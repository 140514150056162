<template>
    <div class="flex" style="flex-wrap: wrap">
        <template v-for="(allelePart, i) in allele.parts.slice(0, maxAllelesPartsToShow)">
            <!-- eslint-disable-next-line-->
            <span class="allele monospace"
                  :class="{ highlighted: highlight && alleleIncludes(allelePart, highlight) }">{{ allelePart }}</span>
            <!-- eslint-disable-next-line-->
            <span style="color: var(--grey-300); margin: 0 3px"
                  v-if="i < indexOfLastAllelePartToShow">/</span>
        </template>
        <p-with-tooltip fixed>
            <span class="badge ml-1"
                  style="font-size: .8em; background-color: var(--grey-200)"
                  v-if="maxAllelesPartsToShow < allele.parts.length"
            >{{ allele.parts.length - maxAllelesPartsToShow }} more</span>
            <template #tooltip>
                <div class="tooltip-black overflow-auto custom-scroll allele-tooltip">
                    <template v-for="(allelePart, i) in allele.parts">
                        <!-- eslint-disable-next-line-->
                        <span class="allele monospace"
                              :class="{ highlighted: highlight && alleleIncludes(allelePart, highlight) }">{{ allelePart }}</span>
                        <!-- eslint-disable-next-line-->
                        <span style="color: var(--grey-300); margin: 0 3px"
                              v-if="i < allele.parts.length - 1">/ </span>
                    </template>
                </div>
            </template>
        </p-with-tooltip>
    </div>
</template>

<script>
    import { Allele, alleleIncludes } from "@/genotype"

    export default {
        name: "Allele",
        props: {
            allele: Allele,
            highlight: String
        },
        computed: {
            indexOfLastAllelePartToShow() {
                return Math.min(this.maxAllelesPartsToShow - 1, this.allele.parts.length - 1)
            }
        },
        data() {
            return {
                maxAllelesPartsToShow: 4
            }
        },
        methods: {
            alleleIncludes
        }
    }
</script>

<style scoped>
    .highlighted {
        background-color: hsl(225, 73%, 97%);
        border-radius: 4px;
        padding: .3rem .7rem;
        color: black;
    }

    .allele-tooltip {
        max-width: 30rem;
        height: fit-content;
        max-height: 10rem;
    }
</style>
