export const errorMixin = {
    methods: {
        addError(error) {
            this.errors = [
                ...this.errors,
                error,
            ]
        },
        removeFirstError() {
            this.errors = this.errors.slice(1)
        }
    },
    computed: {
        maybeFirstError() {
            return this.errors.length !== 0 ? this.errors[0] : null
        }
    },
    data() {
        return {
            errors: [],
        }
    },
}
