import { createPageableStore } from "@/store/modules/pageable-store"


type DocumentedReagentKitLot = {
    name: string
    configuredPipeline: {
        name: string
        properties: object
    }
}

type DocumentedReagentKitLotsFilter = {
    nameStartsWith: string
}

const { module, actionNames, getterNames } =
    createPageableStore<DocumentedReagentKitLot, DocumentedReagentKitLotsFilter>("documentedReagentKitLots")

export {
    module as documentedReagentKitLots,
    actionNames as documentedReagentKitLotsActionNames,
    getterNames as documentedReagentKitLotsGetterNames,
}
