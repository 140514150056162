/**
 * Wraps async FileSystemDirectoryEntryReader readEntries() method to use it with
 * promises
 *
 * @param {FileSystemDirectoryEntry} dir to list File entries for
 * @param {boolean} recursive whether to extract file entries from nested directories
 * @returns {Promise<Array<FileSystemFileEntry>>} Promise with list of the file entries in given directory
 */
export async function listFiles(
    dir: FileSystemDirectoryEntry,
    recursive: boolean
): Promise<Array<FileSystemFileEntry>> {
    const reader = dir.createReader()
    let fileEntries: Array<FileSystemFileEntry> = []
    let fileEntriesChunk: Array<FileSystemFileEntry> = []
    do {
        fileEntriesChunk = await new Promise((resolve, reject) => {
            reader.readEntries(
                async entries => {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    let fileEntries = <Array<FileSystemFileEntry>>entries.filter(it => !it.isDirectory)
                    if (recursive) {
                        fileEntries = [
                            ...fileEntries,
                            ...(await Promise.all(
                                (<Array<FileSystemDirectoryEntry>>entries
                                    .filter(it => it.isDirectory))
                                    .flatMap((it: FileSystemDirectoryEntry) => listFiles(it, recursive))
                            )
                            )
                                /*
                                FlatMap is needed because each directory returns array of files and the directories
                                are also in array
                                */
                                .flatMap(it => it),
                        ]
                    }
                    resolve(fileEntries)
                },
                e => {
                    // eslint-disable-next-line no-console
                    console.error("Abort directory reading due to error", e)
                    reject(e)
                }
            )
        })
        fileEntries = [ ...fileEntries, ...fileEntriesChunk ]
    } while (fileEntriesChunk.length !== 0)
    return fileEntries
}

/**
 * Wraps async FileSystemFileEntry file() method to use it with
 * promises
 *
 * @param {FileSystemFileEntry} fileEntry
 * @returns {Promise<File>}
 */
export function createFileFromFileEntry(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
        fileEntry.file(
            it => resolve(it),
            e => {
                // eslint-disable-next-line no-console
                console.error(`Cannot create file from FileEntry ${fileEntry}`, e)
                reject(e)
            }
        )
    })
}
