<template>
    <svg :style="{ width: size, height: size }" class="icon">
        <use :xlink:href="`#sprite_icon-${iconName}`"></use>
    </svg>
</template>

<script>
    export default {
        name: "PIcon",
        inheritAttrs: true,
        props: {
            iconName: String,
            size: {
                type: String,
                default: "2rem"
            }
        }
    }
</script>

<style scoped>
    .icon {
        display: block;
        line-height: 1;
        fill: currentColor;
    }
</style>
