<template>
    <InteractiveDocs :steps="steps"></InteractiveDocs>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { circler } from "@/interactive-docs/mixin"

    export default {
        name: "SamplesDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [ samples, sampleCard, search ],
            }
        }
    }

    const search = {
        name: "search",
        props: {
            highlighter: Object,
        },
        mixins: [ circler ],
        mounted() {
            this.highlighter.moveHighlight(document.querySelectorAll(".samples-search"))
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение образцы</h3>
                    <p>
                        Для открытия списка всех фильтров нужно нажать на иконку в правой части строки поиска.
                    </p>
                    <br/>
                    <p>
                        При нажатии на кнопку экспорта вам будут предложены разрешения генотипирования
                        в которых можно экспортировать
                        результаты. Экспорт будет произведен по всем отфильтрованным образцам.
                        Экспорт осуществляется в формате CSV, как описано в&nbsp;
                        <a href="/docs/#/?id=Экспорт-результатов"
                           target="_blank"
                           rel="noreferrer noopener">документации</a>.
                    </p>
                </div>
            )
        }
    }

    const samples = {
        name: "samples",
        props: {
            highlighter: Object,
        },
        mixins: [ circler ],
        mounted() {
            this.highlighter.moveHighlight([ document.querySelector(".samples-list") ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение образцы</h3>
                    <p>
                        <b>Предназначение:</b> просмотр и экспорт результатов анализа образцов организации.
                    </p>
                    <br/>
                    <p>Каждый элемент в списке образцов включает в себя:
                        <ul class="bullet-list">
                            <li class="bullet-list__element">Имя образца</li>
                            <li class="bullet-list__element">Бейдж со статусом</li>
                            <li class="bullet-list__element">Дату запуска</li>
                        </ul>
                    </p>
                    <br/>
                    <p>Выбранный в списке образец отображается справа
                        в виде карточки с подробной информацией.</p>
                </div>
            )
        }
    }
    const sampleCard = {
        name: "samples",
        props: {
            highlighter: Object,
        },
        mixins: [ circler ],
        mounted() {
            document.querySelector(".samples-list").children[4].dispatchEvent(new MouseEvent("click"))
            this.$nextTick(() => this.highlighter.moveHighlight([ document.querySelector(".sample-card") ]))

        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение образцы</h3>

                    <p>Карточка образца разделена на 2 смысловые части: метаданные и генотипы.
                    </p>
                    <br/>
                    <p>Метаданные:
                        <ul class="bullet-list">{
                            [
                                [ "Имя образца", "sample-name" ],
                                [ "Идентификатор образца в запуске", "sample-id-in-run" ],
                                [ "Имя запуска", "run-name" ],
                                [ "Дата запуска", "sequencing-run-date" ],
                                [ "Серия набора реагентов, с которым был поставлен образец", "lot-badge" ],
                            ].map(([ text, id ]) =>
                                (<li class="bullet-list__element dashed-underline"
                                     onMouseenter={() => this.circler.moveHighlight([ document.querySelector(`#${id}`) ])}
                                     onMouseleave={() => this.circler.clean()}
                                     domPropsInnerHTML={text}/>))}
                        </ul>
                    </p>
                    <br/>
                    <p>
                        Таблица генотипов может отсутствовать в двух случаях. Если образец еще анализируется или
                        во время анализа произошла техническая ошибка.
                    </p>
                    <br/>
                    <p>
                        В <span
                            class="dashed-underline"
                            onMouseenter={() => this.circler.moveHighlight([ document.querySelector("[i-docs='sample-card-context-menu']") ])}
                            onMouseleave={() => this.circler.clean()}
                          >контекстном меню</span> можно посмотреть историю образца, детали контроля качества (для проанализированных образцов) и pdf отчет
                        для образцов в терминальном статусе.
                    </p>
                </div>
            )
        }
    }
</script>
