import { Module } from "vuex"
import {
    createIdentifiableResourcePageableStore,
    PageableState,
} from "@/store/modules/pageable-store"
import { AnalysisStatus } from "@/enums/analysis-status"

export interface AnalysisFilterCouldBe {
    fromRunWithId: string
    // FIXME: string type here is like weak doge
    holdingGenotype: string
    nameStartsWith: string
    withStatus: AnalysisStatus
}

interface Sample {
    id: string
}

const {
    getterNames,
    actionNames,
    module
} = createIdentifiableResourcePageableStore<Sample, AnalysisFilterCouldBe>("analyses")
export const samplesGetterNames = getterNames
export const samplesActionNames = actionNames

export const samples: Module<PageableState<Sample, AnalysisFilterCouldBe>, any> = {
    ...module
}
