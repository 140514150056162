import Vue from "vue"

Vue.filter("fileSize", (fileSize: number, unit: string) => {
    let coefficient
    switch (unit) {
        case "kB":
            coefficient = 1e3
            break
        case "MB":
            coefficient = 1e6
            break
        case "GB":
            coefficient = 1e9
            break
        default:
            console.error(`Unknown unit ${unit}. Output will be in bytes`)
            coefficient = 1
    }
    return fileSize / coefficient
})
