<template>
    <div class="allele-tooltip">
        <div class="badge-margin">
            <AlleleComponent :allele="allele"
                        :annotations="annotations"/>
        </div>
        <div class="badge badge-margin yellow"
             v-if="rare">{{ $t("rareAllele") }}
        </div>
    </div>
</template>

<script>
    import { Allele } from "@/genotype"

    import AlleleComponent from "./allele"

    export default {
        name: "AlleleTooltip",
        components: { AlleleComponent },
        props: {
            allele: Allele,
            rare: Boolean,
            annotations: Object
        },
    }
</script>

<style scoped>
    .allele-tooltip {
        background-color: white;
        padding: 1rem 1.5rem;
        font-size: .8em;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        z-index: var(--third-layer);
        border-radius: 4px;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 30rem;
        word-break: break-word;
        white-space: pre-line;
    }
</style>
