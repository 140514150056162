export const pipelineThresholds = {
    "generalMetrics": {
        "meanReadsQuality": {
            "green": [ [ 30, null ] ],
            "yellow": [ [ 25, 30 ] ],
            "red": [ [ 0, 25 ] ]
        },
        "totalReads": {
            "green": [ [ 100000, 300000 ] ],
            "yellow": [ [ 30000, 100000 ], [ 300000, null ] ],
            "red": [ [ null, 30000 ] ]
        },
        "insertSize": {
            "green": [ [ 250, 600 ] ],
            "yellow": [ [ 100, 250 ], [ 600, null ] ],
            "red": [ [ null, 100 ] ]
        }
    },
    "locusSpecificMetrics": {
        "A": {
            "locusCoverage": {
                "green": [ [ 10000, 50000 ] ],
                "yellow": [ [ 5000, 10000 ], [ 50000, null ] ],
                "red": [ [ null, 5000 ] ]
            },
            "genotypesNumber": {
                "green": [ [ 1, 1 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 2, null ] ]
            },
            "allelesNumber": {
                "green": [ [ 1, 2 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 3, null ] ]
            },
            "knownGenotypeAmbiguities": [
                "02:01+02:474|02:06+02:07",
            ],
            "knownAlleleAmbiguities": []
        },
        "B": {
            "locusCoverage": {
                "green": [ [ 5000, 25000 ] ],
                "yellow": [ [ 3000, 5000 ], [ 25000, null ] ],
                "red": [ [ null, 3000 ] ]
            },
            "genotypesNumber": {
                "green": [ [ 1, 1 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 2, null ] ]
            },
            "allelesNumber": {
                "green": [ [ 1, 2 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 3, null ] ]
            },
            "knownGenotypeAmbiguities": [
                "15:01+15:18|15:39+15:498",
                "15:01+35:01|15:20+35:43",
                "35:01+51:01|53:01+78:02",
                "35:01+58:34|35:03+58:01",
                "44:03+51:01|44:302+51:230",
                "07:02+41:02|40:32+42:01",
                "35:01+40:01|35:380+40:379",
                "40:01+49:01|40:117+50:01",
            ],
            "knownAlleleAmbiguities": []
        },
        "C": {
            "locusCoverage": {
                "green": [ [ 10000, 50000 ] ],
                "yellow": [ [ 3000, 10000 ], [ 50000, null ] ],
                "red": [ [ null, 3000 ] ]
            },
            "genotypesNumber": {
                "green": [ [ 1, 1 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 2, null ] ]
            },
            "allelesNumber": {
                "green": [ [ 1, 2 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 3, null ] ]
            },
            "knownGenotypeAmbiguities": [
                "03:02+03:03|03:04+03:132",
                "07:01+07:02|07:27+07:639",
            ],
            "knownAlleleAmbiguities": []
        },
        "DQB1": {
            "locusCoverage": {
                "green": [ [ 5000, 35000 ] ],
                "yellow": [ [ 3000, 5000 ], [ 35000, null ] ],
                "red": [ [ null, 3000 ] ]
            },
            "genotypesNumber": {
                "green": [ [ 1, 1 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 2, null ] ]
            },
            "allelesNumber": {
                "green": [ [ 1, 2 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 3, null ] ]
            },
            "knownGenotypeAmbiguities": [
                "06:02+06:04|06:39+06:84",
                "06:02+06:09|06:84+06:88",
                "06:03+06:04|06:39+06:41",
            ],
            "knownAlleleAmbiguities": []
        },
        "DRB1": {
            "locusCoverage": {
                "green": [ [ 5000, 60000 ] ],
                "yellow": [ [ 3000, 5000 ], [ 60000, null ] ],
                "red": [ [ null, 3000 ] ]
            },
            "genotypesNumber": {
                "green": [ [ 1, 1 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 2, null ] ]
            },
            "allelesNumber": {
                "green": [ [ 1, 2 ] ],
                "yellow": [],
                "red": [ [ 0, 0 ], [ 3, null ] ]
            },
            "knownGenotypeAmbiguities": [],
            "knownAlleleAmbiguities": [
                "01:01/01:100",
                "14:54/14:216",
                "15:140/15:149",
                "12:01/12:10",
                "03:01/03:147",
            ]
        }
    }
}
