<template>
    <svg
      v-if="getIconName === 'PARSEQ_ICON'"
      class="parseq-logo"
      fill="white"
      viewBox="-90.067 -28.513 366.334 115.974"
      >
        <path
            fill="white"
            d="M-37.737,34.227c-1.975-1.755-4.505-2.723-7.122-2.723c-1.391,0-2.756,0.268-4.063,0.795
	c-1.539,0.622-2.88,1.568-3.99,2.811c-1.904,2.143-2.863,4.903-2.695,7.771c0.169,2.87,1.44,5.499,3.581,7.403
	c1.975,1.755,4.505,2.723,7.124,2.723l0,0c1.389,0,2.756-0.268,4.063-0.795c1.539-0.622,2.88-1.568,3.988-2.811
	c1.906-2.143,2.863-4.903,2.695-7.771C-34.324,38.762-35.597,36.131-37.737,34.227z M-41.692,45.095
	c-0.441,0.494-0.972,0.872-1.582,1.117c-1.499,0.604-3.242,0.303-4.444-0.765c-0.852-0.757-1.356-1.802-1.422-2.941
	s0.313-2.235,1.07-3.085c0.439-0.494,0.972-0.872,1.584-1.117c0.521-0.211,1.064-0.317,1.617-0.317c1.039,0,2.042,0.384,2.825,1.082
	c0.852,0.757,1.358,1.802,1.422,2.941C-40.555,43.147-40.934,44.243-41.692,45.095z"
        />
        <path
            fill="white"
            d="M-23.981,60.854c6.74-7.575,8.853-18.234,5.517-27.815c-0.183-0.531-0.486-0.988-0.905-1.359
	c-0.866-0.767-2.08-1.035-3.24-0.63l-0.128,0.049c-1.603,0.647-2.414,2.434-1.845,4.067c2.566,7.37,0.942,15.562-4.238,21.38
	c-2.217,2.493-4.901,4.385-7.978,5.629c-2.615,1.056-5.351,1.592-8.133,1.592c-5.233,0-10.29-1.934-14.238-5.448
	c-4.283-3.809-6.827-9.068-7.163-14.807c-0.334-5.741,1.58-11.26,5.389-15.541c2.235-2.51,4.922-4.413,7.988-5.65
	c2.276-0.919,4.696-1.438,7.191-1.542c1.755-0.073,3.177-1.615,3.103-3.37c-0.037-0.866-0.407-1.664-1.043-2.249
	c-0.634-0.582-1.483-0.889-2.327-0.852c-3.248,0.134-6.4,0.809-9.363,2.005c-3.986,1.609-7.482,4.082-10.388,7.348
	c-10.254,11.525-9.219,29.244,2.304,39.498c5.139,4.57,11.722,7.089,18.537,7.089h0.002c3.618,0,7.177-0.696,10.577-2.07
	C-30.36,66.561-26.867,64.098-23.981,60.854z"
        />
        <path
            fill="white"
            d="M-34.867,23.229c1.767,0.933,3.455,2.154,4.901,3.549c0.639,0.576,1.436,0.895,2.245,0.895
	c0.403,0,0.799-0.077,1.178-0.23c0.429-0.173,0.813-0.435,1.141-0.777c1.236-1.289,1.192-3.343-0.096-4.578
	c-0.258-0.246-0.521-0.49-0.789-0.73c-1.69-1.505-3.559-2.804-5.554-3.856c-0.834-0.441-1.851-0.492-2.727-0.14
	c-0.708,0.285-1.297,0.816-1.653,1.493c-0.403,0.769-0.486,1.649-0.228,2.479C-36.189,22.157-35.626,22.832-34.867,23.229z"
        />
        <path
            fill="white"
            d="M-23.291,77.042c-0.978-0.872-2.481-1.06-3.653-0.451c-1.586,0.826-3.254,1.554-4.968,2.164l-0.126,0.047
	c-1.611,0.651-2.418,2.445-1.836,4.08c0.287,0.813,0.874,1.466,1.655,1.841c0.443,0.212,0.913,0.319,1.397,0.319
	c0.368,0,0.732-0.063,1.08-0.187c1.999-0.708,3.949-1.556,5.796-2.524c1.582-0.826,2.198-2.788,1.371-4.372
	C-22.757,77.612-22.997,77.303-23.291,77.042z"
        />
        <path
            fill="white"
            d="M-14.835,8.485C-23.133,1.102-33.757-2.963-44.749-2.963c-3.022,0-6.077,0.305-9.074,0.909
	c-0.199,0.039-0.393,0.098-0.582,0.173c-1.444,0.582-2.266,2.115-1.958,3.64c0.169,0.85,0.661,1.582,1.381,2.062
	c0.714,0.474,1.594,0.647,2.432,0.478c2.575-0.517,5.194-0.781,7.787-0.781c9.412,0,18.511,3.484,25.62,9.81
	c7.716,6.864,12.298,16.337,12.902,26.676c0.604,10.341-2.845,20.282-9.707,27.994c-0.594,0.669-1.239,1.346-1.916,2.011
	c-0.618,0.604-0.964,1.413-0.974,2.28c-0.01,0.866,0.317,1.682,0.917,2.298c0.6,0.62,1.446,0.976,2.325,0.976
	c0.419,0,0.826-0.079,1.212-0.234c0.392-0.159,0.746-0.39,1.045-0.685c0.746-0.726,1.497-1.513,2.233-2.337
	C-3.093,63.303,0.93,51.698,0.226,39.628C-0.48,27.556-5.83,16.497-14.835,8.485z"
        />
        <path
            fill="white"
            d="M-43.317,80.951c-10.052,0.407-19.808-3.091-27.304-9.762c-7.716-6.864-12.296-16.337-12.9-26.676
	c-0.604-10.339,2.843-20.282,9.707-27.994c2.859-3.215,6.193-5.908,9.908-8.003c0.754-0.423,1.297-1.117,1.529-1.95
	c0.234-0.834,0.128-1.708-0.299-2.465c-0.179-0.315-0.403-0.592-0.667-0.828c-0.903-0.801-2.251-1.033-3.364-0.584
	c-0.13,0.053-0.258,0.114-0.38,0.183c-4.336,2.445-8.228,5.587-11.566,9.337c-8.013,9.007-12.039,20.613-11.334,32.683
	c0.704,12.068,6.052,23.127,15.059,31.138c8.285,7.372,18.944,11.433,30.011,11.433c0.618,0,1.245-0.014,1.865-0.037
	c1.755-0.075,3.175-1.617,3.103-3.368c-0.035-0.866-0.405-1.664-1.041-2.253C-41.621,81.223-42.445,80.922-43.317,80.951z"
        />
        <path
            fill="white"
            d="M12.843,19.412c-0.596-0.529-1.361-0.82-2.154-0.82c-0.382,0-0.755,0.067-1.092,0.191l-0.081,0.031
	c-1.678,0.694-2.481,2.467-1.869,4.135c2.062,5.621,3.205,11.517,3.398,17.526c0.028,0.864,0.39,1.666,1.021,2.259
	c0.602,0.565,1.389,0.875,2.213,0.875l0.106-0.002c1.753-0.057,3.189-1.586,3.134-3.341c-0.214-6.695-1.489-13.272-3.791-19.55
	C13.543,20.215,13.236,19.763,12.843,19.412z"
        />
        <path
            fill="white"
            d="M7.814,8.751C4.723,3.897,0.956-0.523-3.384-4.385c-7.921-7.045-17.538-11.982-27.809-14.279
	c-0.641-0.144-1.31-0.09-1.92,0.157c-0.988,0.399-1.718,1.257-1.95,2.298c-0.388,1.745,0.714,3.482,2.453,3.868
	c9.207,2.06,17.823,6.485,24.917,12.796c3.89,3.461,7.268,7.423,10.042,11.775c0.596,0.942,1.619,1.503,2.733,1.503
	c0.417,0,0.824-0.079,1.212-0.236c0.181-0.073,0.358-0.165,0.529-0.273C8.328,12.263,8.772,10.256,7.814,8.751z"
        />
        <path
            fill="white"
            d="M-49.807-13.489c0.089,0,0.179-0.004,0.27-0.012c1.562-0.13,3.15-0.197,4.714-0.197
	c0.275,0,0.551,0.002,0.872,0.006c1.733,0,3.217-1.462,3.242-3.189c0.014-0.944-0.382-1.845-1.086-2.471
	c-0.578-0.515-1.324-0.805-2.103-0.818c-2.052-0.03-4.136,0.039-6.182,0.211c-0.329,0.028-0.645,0.104-0.942,0.224
	c-1.322,0.533-2.131,1.847-2.015,3.274C-52.898-14.795-51.48-13.489-49.807-13.489z"
        />
        <path
            fill="white"
            d="M21.781-1.387C18.112-6.984,13.718-12.125,8.723-16.67c-4.946-4.499-10.425-8.358-16.28-11.468
	c-0.824-0.437-1.83-0.496-2.699-0.155c-0.72,0.281-1.316,0.815-1.68,1.499c-0.407,0.769-0.492,1.651-0.234,2.483
	c0.254,0.824,0.815,1.499,1.574,1.895C0.283-16.637,9.607-8.135,16.363,2.164c0.596,0.919,1.609,1.468,2.711,1.468
	c0.403,0,0.799-0.075,1.177-0.222c0.207-0.081,0.409-0.185,0.6-0.309c0.724-0.474,1.22-1.202,1.395-2.048
	C22.418,0.203,22.255-0.663,21.781-1.387z"
        />
        <path
            fill="white"
            d="M168.766,4.433l15.124,5.011c0.691,0.256,1.019,0.675,1.064,1.33c0,0.653-0.193,1.218-0.553,1.657
	c-1.889,1.983-4.466,2.989-7.665,2.989c-3.205,0-5.973-1.145-8.232-3.406c-0.183-0.179-0.474-0.484-0.877-0.911
	c-0.545-0.569-1.259-0.856-2.125-0.856c-0.86,0-1.607,0.311-2.221,0.923c-0.61,0.61-0.921,1.359-0.921,2.221
	c0,0.677,0.203,1.302,0.632,1.897c3.77,4.257,8.393,6.416,13.744,6.416c4.93,0,8.987-1.592,12.056-4.724
	c1.645-1.647,2.479-3.6,2.479-5.806c0-2.211-0.803-4.124-2.379-5.674c-0.813-0.838-2.174-1.607-4.183-2.359l-15.123-5.011
	c-0.691-0.256-1.019-0.675-1.064-1.332c0-0.653,0.193-1.218,0.553-1.655c1.889-1.985,4.466-2.99,7.663-2.99
	c3.203,0,5.973,1.145,8.23,3.402c0.183,0.183,0.474,0.486,0.879,0.911c0.541,0.571,1.257,0.86,2.125,0.86
	c0.86,0,1.607-0.311,2.221-0.925c0.612-0.612,0.921-1.356,0.921-2.194c0.026-0.748-0.185-1.391-0.634-1.922
	c-3.791-4.257-8.415-6.416-13.744-6.416c-4.928,0-8.983,1.59-12.054,4.724c-1.645,1.647-2.479,3.598-2.479,5.806
	c0,2.211,0.803,4.124,2.381,5.68C165.392,2.906,166.793,3.697,168.766,4.433z"
        />
        <path
            fill="white"
            d="M62.339-14.069c-9.845,0-17.854,8.011-17.854,17.858v29.568c0,1.725,1.403,3.128,3.128,3.128
	c1.725,0,3.128-1.403,3.128-3.128V3.787c0-6.396,5.204-11.602,11.6-11.602c6.398,0,11.602,5.206,11.602,11.602
	s-5.204,11.6-11.602,11.6c-1.491,0-2.945-0.279-4.32-0.828c-0.372-0.15-0.763-0.224-1.163-0.224c-1.287,0-2.426,0.769-2.902,1.962
	c-0.313,0.775-0.303,1.625,0.026,2.394c0.329,0.769,0.936,1.363,1.712,1.672c2.113,0.85,4.35,1.279,6.648,1.279
	c9.847,0,17.858-8.009,17.858-17.854C80.195-6.058,72.184-14.069,62.339-14.069z"
        />
        <path
            fill="white"
            d="M153.108-6.766c1.277,0,2.414-0.761,2.898-1.936c0.655-1.594-0.108-3.425-1.702-4.082
	c-2.154-0.889-4.442-1.34-6.799-1.34c-9.845,0-17.856,8.009-17.856,17.854v14.785c0,1.725,1.403,3.128,3.128,3.128
	c1.723,0,3.126-1.403,3.126-3.128V3.73c0-6.396,5.204-11.6,11.6-11.6c1.535,0,3.02,0.293,4.419,0.87
	C152.301-6.845,152.7-6.766,153.108-6.766z"
        />
        <path
            fill="white"
            d="M104.013-14.069c-9.845,0-17.854,8.011-17.854,17.858c0,9.845,8.009,17.854,17.854,17.854
	c2.3,0,4.539-0.431,6.652-1.279c1.6-0.643,2.377-2.469,1.733-4.071c-0.48-1.19-1.621-1.958-2.906-1.958
	c-0.397,0-0.789,0.075-1.161,0.224c-1.371,0.549-2.825,0.828-4.318,0.828c-6.396,0-11.6-5.204-11.6-11.6s5.204-11.602,11.6-11.602
	c6.398,0,11.602,5.206,11.602,11.602v14.726c0,1.725,1.403,3.128,3.128,3.128s3.128-1.403,3.128-3.128V3.789
	C121.871-6.058,113.86-14.069,104.013-14.069z"
        />
        <path
            fill="white"
            d="M258.411-14.069c-9.847,0-17.856,8.011-17.856,17.858c0,9.845,8.009,17.854,17.856,17.854
	c2.3,0,4.537-0.431,6.648-1.279c0.775-0.309,1.383-0.903,1.712-1.672c0.329-0.769,0.338-1.619,0.028-2.392
	c-0.476-1.192-1.615-1.963-2.902-1.963c-0.397,0-0.789,0.075-1.163,0.224c-1.375,0.549-2.829,0.828-4.32,0.828
	c-6.396,0-11.602-5.204-11.602-11.6s5.204-11.602,11.602-11.602c6.396,0,11.6,5.206,11.6,11.602v29.568
	c0,1.725,1.405,3.128,3.128,3.128c1.723,0,3.126-1.403,3.126-3.128V3.787C276.266-6.058,268.256-14.069,258.411-14.069z"
        />
        <path
            fill="white"
            d="M216.822-13.982c-9.8,0-17.772,7.972-17.772,17.772c0,9.798,7.972,17.768,17.772,17.768
	c3.917,0,7.634-1.251,10.75-3.618c0.124-0.094,0.201-0.197,0.299-0.321c0.883-0.901,1.125-2.202,0.632-3.394l-0.049-0.122
	c-0.063-0.157-0.14-0.352-0.283-0.539c-0.096-0.13-0.201-0.211-0.323-0.305c-0.87-0.862-2.192-1.133-3.351-0.657l-0.185,0.081
	c-0.144,0.057-0.34,0.136-0.527,0.277c-2.015,1.531-4.423,2.339-6.961,2.339c-6.351,0-11.515-5.164-11.515-11.513
	c0-6.351,5.166-11.515,11.515-11.515c5.119,0,9.688,3.49,11.077,8.373L216.824,0.6c-1.718,0-3.122,1.397-3.128,3.114
	c-0.002,0.836,0.319,1.623,0.907,2.217c0.588,0.592,1.373,0.921,2.209,0.923l14.653,0.057c0.421,0,0.832-0.083,1.214-0.246
	c0.222-0.092,0.39-0.224,0.529-0.331l0.151-0.108c0.083-0.049,0.197-0.114,0.317-0.232c0.138-0.138,0.228-0.291,0.297-0.409
	l0.122-0.181c0.069-0.089,0.173-0.222,0.25-0.401c0.067-0.159,0.089-0.309,0.116-0.5l0.035-0.136
	c0.039-0.146,0.094-0.344,0.094-0.584C234.59-6.011,226.62-13.982,216.822-13.982z"
        />
        <g>
            <path
                fill="white"
                d="M72.141,52.077c-5.324,0-9.654,4.33-9.654,9.656c0,5.324,4.33,9.654,9.654,9.654
		c1.245,0,2.455-0.232,3.596-0.691c0.903-0.362,1.342-1.393,0.978-2.298c-0.271-0.671-0.915-1.104-1.639-1.104
		c-0.224,0-0.443,0.043-0.655,0.126c-0.724,0.291-1.489,0.439-2.28,0.439c-3.378,0-6.125-2.747-6.125-6.125s2.748-6.127,6.125-6.127
		c3.378,0,6.127,2.748,6.127,6.127v7.889c0,0.974,0.791,1.765,1.765,1.765c0.974,0,1.765-0.791,1.765-1.765v-7.889
		C81.797,56.408,77.465,52.077,72.141,52.077z"
            />
            <path
                fill="white"
                d="M96.425,52.115c-1.241,0-2.451,0.232-3.594,0.691c-0.439,0.175-0.781,0.51-0.966,0.946
		c-0.185,0.435-0.189,0.913-0.014,1.35c0.27,0.673,0.913,1.108,1.637,1.108c0.224,0,0.445-0.043,0.655-0.126
		c0.724-0.291,1.493-0.439,2.282-0.439c3.378,0,6.127,2.747,6.127,6.125s-2.748,6.127-6.127,6.127c-3.376,0-6.125-2.748-6.125-6.127
		V45.927c0-0.974-0.793-1.765-1.765-1.765c-0.974,0-1.765,0.793-1.765,1.765v15.842c0,5.324,4.33,9.656,9.654,9.656
		c5.324,0,9.656-4.33,9.656-9.656C106.081,56.445,101.748,52.115,96.425,52.115z"
            />
            <path
                fill="white"
                d="M58.097,67.282c-0.23,0-0.454,0.045-0.669,0.132c-0.74,0.305-1.525,0.458-2.331,0.458
		c-3.378,0-6.127-2.748-6.127-6.127V46.454c0-0.974-0.791-1.765-1.765-1.765s-1.765,0.791-1.765,1.765v15.291
		c0,5.324,4.332,9.654,9.656,9.654c1.273,0,2.51-0.244,3.677-0.724c0.897-0.372,1.328-1.405,0.958-2.304
		C59.459,67.71,58.817,67.282,58.097,67.282z"
            />
        </g>
    </svg>
    <svg v-else-if="getIconName === 'EPIVALUE_ICON'" class="epivalue-logo" fill="white" viewBox="200 200 2140 623">
        <g>
            <path
                d="m 627.5967,583.2284 h 51.0236 c 18.7087,0 34.0158,15.3071 34.0158,34.0158 v 192.7559 h -51.0236 c -18.7087,0 -34.0158,-15.3071 -34.0158,-34.0157 v -73.7008 c 0,-18.7087 15.3071,-34.0158 34.0157,-34.0158 v 0 h 22.6772 v -48.189 c 0,-4.6771 -3.8268,-8.5039 -8.504,-8.5039 h -48.1889 z m 36.8504,113.3858 h 19.8425 v 85.0394 h -19.8425 c -4.6772,0 -8.5039,-3.8268 -8.5039,-8.504 v -68.0315 c 0,-4.6771 3.8267,-8.5039 8.5039,-8.5039 z m 388.3461,-113.3858 v 188.5039 c 0,23.3859 -19.1339,42.5198 -42.5198,42.5198 v 0 c -23.3858,0 -42.5196,-19.1339 -42.5196,-42.5198 V 583.2284 h 28.3464 v 186.378 c 0,7.7953 6.378,14.1732 14.1732,14.1732 h 10e-5 c 7.7953,0 14.1732,-6.3779 14.1732,-14.1732 V 583.2284 Z M 826.0217,810.0001 h 28.3465 V 526.5355 h -28.3465 z m 382.6767,-231.0236 v 0 c 23.3859,0 42.5198,19.1338 42.5198,42.5196 v 75.1182 h -15.9049 -12.4416 -28.3465 v 72.9921 c 0,7.7953 6.378,14.1732 14.1732,14.1732 h 10e-5 c 7.7953,0 14.1732,-6.3779 14.1732,-14.1732 v -44.6457 h 28.3465 v 46.7716 c 0,23.3859 -19.1339,42.5198 -42.5198,42.5198 v 0 c -23.3858,0 -42.5196,-19.1339 -42.5196,-42.5198 V 621.4961 c 0,-23.3858 19.1338,-42.5196 42.5196,-42.5196 z m 14.1733,89.2913 v -44.6457 c 0,-7.7953 -6.3779,-14.1732 -14.1732,-14.1732 h -10e-5 c -7.7952,0 -14.1732,6.3779 -14.1732,14.1732 v 44.6457 z M 478.778,814.4887 h 0.003 c 14.905,-0.001 26.8994,-10.4986 28.2354,-25.768 l 17.9782,-205.4923 h -28.4548 l -17.7565,202.9579 -0.005,0.0638 h -10e-5 v 0 l -0.006,-0.0638 -17.7565,-202.9579 h -28.4547 l 17.9782,205.4923 c 1.3359,15.2694 13.3303,25.7665 28.2354,25.768 z"
                style="fill: #0083b7; fill-rule: evenodd;"
            />
            <path
                d="m 854.3682,271.4173 h 19.8425 c 4.6771,0 8.5039,3.8268 8.5039,8.504 v 153.0708 c 0,4.6772 -3.8268,8.504 -8.5039,8.504 h -19.8425 z m -28.3465,255.1181 h 28.3465 v -56.6929 h 22.6771 c 18.7087,0 34.0158,-15.3071 34.0158,-34.0157 V 277.0866 c 0,-18.7086 -15.3071,-34.0157 -34.0158,-34.0157 h -22.6771 -28.3465 v 226.7716 z m 198.4249,-283.4645 h 28.3465 v 226.7716 h -28.3465 z m -340.1569,85.0396 v -44.6459 c 0,-7.7953 -6.378,-14.1733 -14.1732,-14.1733 v 0 c -7.7953,0 -14.1733,6.378 -14.1733,14.1733 v 44.6459 z m -14.1732,-89.2916 v 0 c 23.3858,0 42.5197,19.1339 42.5197,42.5197 v 75.1184 h -15.905 -12.4415 -28.3465 v 72.9921 c 0,7.7953 6.378,14.1729 14.1733,14.1729 v 0 c 7.7952,0 14.1732,-6.3776 14.1732,-14.1729 v -44.6457 h 28.3465 v 46.7714 c 0,23.3858 -19.1339,42.5197 -42.5197,42.5197 v 0 c -23.3859,0 -42.5197,-19.1339 -42.5197,-42.5197 V 281.3386 c 0,-23.3858 19.1338,-42.5197 42.5197,-42.5197 z"
                style="fill: #ed6b1e; fill-rule: evenodd;"
            />
            <path
                d="m 627.5963,923.3859 v 28.3465 h 425.1969 v -28.3465 z"
                style="fill: #ed6b1e; fill-rule: evenodd;"
            />
        </g>
    </svg>
</template>

<script>
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "BrandIcon",
        computed: {
            getIconName() {
                const { iconName } = useBrand()
                return iconName
            }
        }
    }
</script>
<style scoped>
.parseq-logo {
    width: 15rem;
    height: 5rem;
    margin: 1.5rem 0 0 2rem;
    color: white;
}
.epivalue-logo {
    width: 30rem;
    height: 10rem;
    margin: 1.5rem 0 0 2rem;
    color: white;
}
</style>
