<template>
    <div class="overflow-auto custom-scroll">
        <table class="w-100 table--striped">
            <thead>
            <tr>
                <th class="genotypes-table--header-cell">
                    <div class="genotypes-table--header-cell-content">{{ $t("locus") }}</div>
                </th>
                <th class="genotypes-table--header-cell">
                    <div v-if="!isСontamination" class="genotypes-table--header-cell-content">{{ $t("genotype") }}</div>
                    <div v-else class="genotypes-table--header-cell-content">
                        {{ $t("genotype") }}
                        <p-with-tooltip
                            fixed
                            class="allele"
                            style="justify-self: end">
                            <div class="badge"
                                style="font-size: .5em; margin-left: .2rem; color: white; backgroundColor: var(--red);">
                                С
                            </div>
                            <template #tooltip>
                                <div class="tooltip-black">
                                <div class="badge-margin" style="font-size: 0.8em; display: flex;">
                                    {{ $t("сontamination") }}
                                </div>
                                </div>
                            </template>
                        </p-with-tooltip>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                :key="locusName"
                v-for="(genotypeDetails, locusName) in processedGenotypes"
                :style="getStyleDependingOnAnalysisStatus(locusName)">
                <td v-if="hasStatusInSupportOrAwaitingApprove">
                    <div class="locus-status">
                        <p-with-tooltip
                            v-if="hasInSupportProblemLocus(locusName)"
                            fixed
                            class="mr-1"
                            position="left">

                            <p-icon icon-name="warning" style="color: var(--red);"/>

                            <template #tooltip>
                                <div class="tooltip-black">
                                    <span style="font-size: 1.4rem;">{{ $t("inSupport") }}</span>
                                </div>
                            </template>

                        </p-with-tooltip>

                        <p-with-tooltip
                            v-else-if="hasAwaitingApproveLocusChangeBySupport(locusName)"
                            fixed
                            class="mr-1"
                            position="left">

                            <p-icon icon-name="new-releases" style="color: var(--warning-color);"/>

                            <template #tooltip>
                                <div class="tooltip-black">
                                    <span style="font-size: 1.4rem;">{{ $t("awaitingApprove") }}</span>
                                </div>
                            </template>

                        </p-with-tooltip>

                        <span v-else></span>

                        <span>{{ locusName }}</span>
                    </div>
                </td>
                <td v-else>
                    <span>{{ locusName }}</span>
                </td>
                <td>
                    <GenotypeComponent :genotype="genotypeDetails"
                                       :locus-name="locusName"
                                       :annotations="annotations"
                                        :analysis="analysis"
                    />
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import GenotypeComponent from "@/components/genotype/genotype"
    import { Locales } from "@/i18n/main"
    import { parseRawGenotypes } from "@/utils/analysis"
    import { AnalysisStatuses } from "@/enums/analysis-status"

    export default {
        name: "GenotypesTable",
        components: {
            GenotypeComponent
        },
        props: {
            genotypes: Object,
            annotations: Object,
            analysis: Object,
        },
        computed: {
            processedGenotypes() {
                return parseRawGenotypes(this.genotypes)
            },
            isСontamination() {
                return this.analysis?.result.qualityMetrics.contamination
            },
            hasStatusInSupportOrAwaitingApprove() {
                return this.analysis?.status === AnalysisStatuses.IN_SUPPORT ||
                    this.analysis?.status === AnalysisStatuses.AWAITING_APPROVE
            }
        },
        methods: {
            hasInSupportProblemLocus(locus) {
                return this.analysis?.status === AnalysisStatuses.IN_SUPPORT
                    ? this.analysis.maybeSupportRequest?.loci.includes(locus) : false
            },
            hasAwaitingApproveLocusChangeBySupport(locus) {
                return this.analysis?.status === AnalysisStatuses.AWAITING_APPROVE
                    ? locus in this.analysis.maybeResolutionDraft?.genotypes : false
            },
            getStyleDependingOnAnalysisStatus(locus) {
                if(this.hasInSupportProblemLocus(locus)) {
                    return "background-color: #d32f2f24;"
                } else if (this.hasAwaitingApproveLocusChangeBySupport(locus)) {
                    return "background-color: #eeac5638;"
                }
                return ""
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    autoAssignment: "auto assignment",
                    сontamination: "Contamination",
                    inSupport: "In support",
                    awaitingApprove: "Awaiting approve"
                },
                [Locales.RU]: {
                    autoAssignment: "автоматическое назначение",
                    сontamination: "СМЕСЬ",
                    inSupport: "В поддержке",
                    awaitingApprove: "Ожидает одобрения"
                }
            }
        }
    }
</script>

<style scoped>

.tooltip-black {
    font-size: .9em;
    background-color: hsl(0, 0%, 30%);
    color: white;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: .5em .8em;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
}

.locus-status {
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
}

</style>
