import { setUpAxiosMock, tearDownAxiosMock } from "@/server-mock"

/*
 This mixin is supposed to be used only in "root" App component. But I extracted this function
 to avoid cluttering of App. Maybe this can be refactored to store, cause I want to toggle docMode from
 different places. However, since I mess with router here, which is obviously absent from store.
 I will leave this as mixin for now.
*/
export const docModeMixin = {
    data() {
        return {
            docMode: false,
            lastUrlBeforeDocMode: null,
            docModeRouterHook: null,
            mockResponseInterceptor: null,
        }
    },
    watch: {
        "$route": {
            handler(newVal) {
                /*
                 IMPORTANT! When you pass true/false bool values to router query inside app they get
                 here as bool. However on page reload the same parameter gets here as "true"/"false" string.
                 So I decided to add as many guards as I can, "cast" here and usage of string in router queries.
                */
                if (newVal.query.doc?.toString() === "true") {
                    if (!this.docMode) {
                        console.debug("Activating doc mode")
                        this.activateDocMode()
                    }
                } else if (this.docMode) {
                    this.deactivateDocMode()
                }
            },
            immediate: true
        }
    },
    methods: {
        activateDocMode() {
            // A little dirty, think of this as of component API.
            // If it cannot display errors, than fuck it
            if (!this.$route.matched[0].components.docs) {
                this.addError?.({
                    type: "missingDocs"
                })
                return
            }
            setUpAxiosMock()
            const { query } = this.$router.currentRoute
            this.docMode = true
            this.lastUrlBeforeDocMode = this.$route
            /*
             Little hack to enable usual routing inside doc mode.
             Usual routes doesn't have "doc" in query, so I have to instrument them with it.
             However, I also want doc mode deactivation through route change to be possible.
             So I came with following solution. If doc is explicitly specified then I new route came
             from place that knows what it's doing, otherwise, I add doc to query manually .
            */
            this.docModeRouterHook = this.$router.beforeEach((to, from, next) => {
                if (to.query.doc === "true" || to.query.doc === "false") {
                    console.debug("I came from place that knows about doc mode")
                    next()
                } else {
                    console.debug("Came through usual app routing")
                    next({ path: to.path, params: to.params, query: { ...to.query, doc: "true" } })
                }
            })
            this.$router.push({
                query: {
                    ...query, doc: "true"
                }
            })
        },
        deactivateDocMode() {
            console.debug("Deactivating doc mode")
            this.docModeRouterHook?.()
            tearDownAxiosMock()
            this.docMode = false
            const { query, params, path } = this.lastUrlBeforeDocMode
            this.$router.push({
                params,
                path,
                query: {
                    ...query, doc: "false"
                },
            })
        },
        toggleDocMode() {
            this.docMode
                ? this.deactivateDocMode()
                : this.activateDocMode()
        }
    }
}
