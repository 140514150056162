<template>
    <div class="modal">
        <h3 class="mb-2">{{ $t("selectGenotypeToBeAssigned") }}</h3>
        <div class="mb-1">
            <span style="color: var(--grey-700)">{{ $t("initiallyAssignedGenotype") }}</span>
            <br>
            <button
                style="padding: 1rem"
                @click="selectedGenotype = initiallyAssignedGenotype"
                class="flex"
            >
                <p-icon
                    :icon-name="selectedGenotype === initiallyAssignedGenotype ? 'checkbox-checked' : 'checkbox-unchecked'"
                    class="mr-1 flex-no-shrink"
                    style="color: royalblue"
                />
                <genotype
                    :genotype="initiallyAssignedGenotype"
                    :locus-name="activeLocus"
                    :annotations="annotations ? annotations: {}"
                />
            </button>
        </div>
        <div class="overflow-auto custom-scroll">
            <span style="color: var(--grey-700)">{{ $t("relatedKnownAmbiguities") }}</span>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <button
                style="padding: 1rem"
                @click="selectedGenotype = g"
                v-for="g in relatedAmbiguities"
                class="flex"
            >
                <p-icon
                    :icon-name="selectedGenotype === g ? 'checkbox-checked' : 'checkbox-unchecked'"
                    class="mr-1 flex-no-shrink"
                    style="color: royalblue"
                />
                <genotype
                    :genotype="g"
                    :locus-name="activeLocus"
                    :annotations="annotations ? annotations: {}"
                />
            </button>
        </div>
        <div class="flex mt-3">
            <button
                class="md-btn-text"
                @click="$emit('cancel')"
            >{{ $t("buttons.cancel") }}
            </button>
            <button
                :disabled="selectedGenotype === null"
                class="md-btn-contained green ml-auto"
                @click="$emit('confirm', selectedGenotype)"
            >{{ $t("buttons.save") }}
            </button>
        </div>
    </div>
</template>

<script>
    import Genotype from "@/components/genotype/genotype"
    import { Locales } from "@/i18n/main"
    export default {
        name: "ambiguous-genotype-selection-modal",
        components: { Genotype },
        data() {
            return {
                selectedGenotype: null
            }
        },
        props: {
            activeLocus: {
                type: String,
                required: true
            },
            initiallyAssignedGenotype: {
                type: Object,
                required: true
            },
            annotations: {
                type: Object,
                required: true
            },
            relatedAmbiguities: {
                type: Array,
                required: true
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    selectGenotypeToBeAssigned: "Выберите генотип",
                    initiallyAssignedGenotype: "Введенный генотип",
                    relatedKnownAmbiguities: "Подходящие известные неоднозначности",
                },
                [Locales.EN]: {
                    selectGenotypeToBeAssigned: "Select genotype to be assigned",
                    initiallyAssignedGenotype: "Entered genotype",
                    relatedKnownAmbiguities: "Related known ambiguities",
                }
            }
        }
    }
</script>

<style>
    .modal {
        padding: 2rem 3rem 1.5rem;
        min-width: 35rem;
        max-height: 80vh;
        max-width: 80vw;
    }
</style>
