<template>
    <div class="user-notifications">
        <div class="flex mb-1" style="width: 100%;">
            <div :class="{ active: activeTab === 'NEW' }"
                 @click="changeActiveTabTo('NEW')"
                 class="tab">{{ $t("new") }}
            </div>
            <div :class="{ active: activeTab === 'READ' }"
                 @click="changeActiveTabTo('READ')"
                 class="tab">{{ $t("read") }}
            </div>
        </div>
        <div class="overflow-auto custom-scroll relative"
             v-if="notificationsToShow.length"
             ref="infiniteScrollContainer">
            <transition-group name="notification" tag="div">
                <div :class="{ 'new-notification': activeTab === 'NEW' }"
                     :key="notification.id"
                     class="notification"
                     v-for="notification in notificationsToShow">
                    <button @click="markAsRead(notification.id)"
                            class="mark-as-read-btn">
                        <p-icon
                            icon-name="mark-as-read"
                            size="100%"
                        />
                    </button>
                    <span style="grid-column: 2 / 3">{{ notification.text }}</span>
                    <span style="grid-column: 3 / 4; margin-left: auto; color: var(--grey-400); font-size: .8em">{{ $d(notification.createdAt) }}</span>
                </div>
            </transition-group>
        </div>
        <div class="empty-inbox-placeholder" v-else-if="activeTab === 'NEW'">{{ $t("noNewNotifications") }}</div>
        <div class="empty-inbox-placeholder" v-else-if="activeTab === 'READ'">{{ $t("noReadNotifications") }}</div>
    </div>
</template>

<script>
    import {
        newNotificationsActionNames,
        newNotificationsGetterNames,
        readNotificationsActionNames,
        readNotificationsGetterNames,
    } from "@/store/modules/notifications"
    import { Locales } from "@/i18n/main"

    export default {
        name: "UserNotifications",
        data() {
            return {
                activeTab: "NEW",
                hasNewMarkedAsReadNotificationsSinceLastReadNotificationsFetch: false,
                firstAccessToReadNotificationsTab: true
            }
        },
        computed: {
            notificationsToShow() {
                return this.activeTab === "NEW"
                    ? this.newNotifications
                    : this.readNotifications
            },
            readNotifications() {
                return this.$store.getters[readNotificationsGetterNames.resourceList]
            },
            readNotificationsNumber() {
                return this.$store.getters[readNotificationsGetterNames.resourceNumber]
            },
            readNotificationsFetchInProgress() {
                return this.$store.getters[readNotificationsGetterNames.fetchInProgress]
            },
            newNotifications() {
                return this.$store.getters[newNotificationsGetterNames.resourceList]
            },
            newNotificationsNumber() {
                return this.$store.getters[newNotificationsGetterNames.resourceNumber]
            },
            newNotificationsFetchInProgress() {
                return this.$store.getters[newNotificationsGetterNames.fetchInProgress]
            }
        },
        methods: {
            changeActiveTabTo(tabName) {
                if (this.activeTab !== tabName) {
                    (
                        (
                            tabName === "READ"
                            && this.firstAccessToReadNotificationsTab
                            || this.hasNewMarkedAsReadNotificationsSinceLastReadNotificationsFetch
                        )
                            ? this.$store.dispatch(readNotificationsActionNames.startNewSearch)
                            : Promise.resolve()
                    )
                        // eslint-disable-next-line promise/always-return
                        .then(() => {
                            this.firstAccessToReadNotificationsTab = false
                            this.activeTab = tabName
                            this.hasNewMarkedAsReadNotificationsSinceLastReadNotificationsFetch = false
                        })
                }
            },
            markAsRead(notificationId) {
                this.$store.dispatch(newNotificationsActionNames.markAsRead, { id: notificationId })
                    .then(() => this.hasNewMarkedAsReadNotificationsSinceLastReadNotificationsFetch = true)
            },
            onScroll() {
                let shouldFetch
                let actionNames
                if (this.activeTab === "NEW") {
                    actionNames = newNotificationsActionNames
                    shouldFetch = scrollPercent > 0.9
                        && !this.newNotificationsFetchInProgress
                        && (this.newNotifications.length !== this.newNotificationsNumber)
                } else {
                    actionNames = readNotificationsActionNames
                    shouldFetch = scrollPercent > 0.9
                        && !this.readNotificationsFetchInProgress
                        && (this.readNotifications.length !== this.readNotificationsNumber)
                }

                const resourceListScrollableContainer = this.$refs["infiniteScrollContainer"]
                const scrollPercent = resourceListScrollableContainer.scrollTop / (resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight)
                if (shouldFetch) {
                    this.$store.dispatch(actionNames.continueSearch)
                }
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "new": "New",
                    "read": "Read",
                    noNewNotifications: "No new notifications",
                    noReadNotifications: "No read notifications"
                },
                [Locales.RU]: {
                    "new": "Новые",
                    "read": "Прочитанные",
                    noNewNotifications: "Новых сообщений нет",
                    noReadNotifications: "Прочитанных сообщений нет"
                }
            }
        }
    }
</script>

<style scoped>
    .user-notifications {
        display: grid;
        grid-template-rows: min-content 1fr;
        font-size: 2rem;
        width: 50rem;
        height: 40rem;
    }

    .tab {
        flex: 1;
        text-align: center;
        border-bottom: 2px solid var(--grey-400);
        padding: 0 1em .1em 1em;
        cursor: pointer;
    }

    .tab.active {
        border-color: var(--parseq-orange);
    }

    .notification {
        --mark-as-read-icon-size: 3.5rem;
        position: relative;
        border: 1px solid var(--grey-400);
        border-radius: 4px;
        margin: 1rem 0;
        padding: .5em;
        width: 100%;
        display: grid;
        grid-template-columns: var(--mark-as-read-icon-size) minmax(20%, 1fr) min-content 1em;
        grid-column-gap: 1rem;
        align-items: center;
        word-wrap: break-word;
    }

    .mark-as-read-btn {
        display: none !important;
        position: relative;
        height: var(--mark-as-read-icon-size);
        width: var(--mark-as-read-icon-size);
        padding: .35em;
        margin: -5px;
        color: var(--grey-700);
        border-radius: 50%;
    }

    .mark-as-read-btn:focus,
    .mark-as-read-btn:hover {
        background-color: rgba(60, 64, 67, 0.08);
        cursor: pointer;
        outline: none;
    }

    .new-notification:hover {
        background-color: hsl(0, 0%, 98%);
        cursor: pointer;
    }

    .new-notification:hover .mark-as-read-btn {
        display: unset !important;
    }

    .notification-enter-active, .notification-leave-active {
        transition: all .3s;
    }

    .notification-leave-active {
        position: absolute;
    }

    .notification-enter, .notification-leave-to {
        opacity: 0;
    }

    .notification-move {
        transition: all .3s;
    }

    .empty-inbox-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--grey-400);
    }
</style>
