<template>
    <div id="docs">
        <p-icon-button
            @click="exitDocMode"
            style="position: absolute; right: 1rem;"
            icon-name="cross"
            class="md-round-icon-btn"
            size="1.4em"
        />
        <component class="overflow-auto custom-scroll"
                   :is="currentStep"
                   :highlighter="highlighter"
                   :next-step="next"
                   :prev-step="prev"
        />
        <div id="docs-control-elements"
             class="flex"
             v-if="allowControls"
        >
            <p-icon-button class="h-100" @click="prev"
                        id="docs-previous-step-btn"
                        :disabled="!hasPreviousStep"
                        icon-name="arrow_left"/>
            <h4 class="h4" style="color: var(--grey-400); white-space: nowrap">{{ currentStepIndex + 1 }} {{ $t("of") }}
                {{ stepsCount }}</h4>
            <p-icon-button class="h-100"
                        id="docs-next-step-btn"
                        @click="next"
                        icon-name="arrow_right"
                        :disabled="!hasNextStep"/>
        </div>
    </div>
</template>

<script>
    import { Highlighter } from "@/interactive-docs/utils"
    import { Locales } from "@/i18n/main"

    export default {
        name: "InteractiveDocs",
        props: {
            steps: Array,
            allowControls: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            currentStep() {
                return this.steps[this.currentStepIndex]
            },
            stepsCount() {
                return this.steps.length
            },
            hasNextStep() {
                return this.currentStepIndex < this.stepsCount - 1
            },
            hasPreviousStep() {
                return this.currentStepIndex > 0
            }
        },
        data() {
            return {
                highlighter: new Highlighter("background-color: white; border-radius: 8px;"),
                currentStepIndex: 0
            }
        },
        methods: {
            next() {
                this.currentStepIndex += 1
            },
            prev() {
                this.currentStepIndex -= 1
            },
            exitDocMode() {
                // eslint-disable-next-line no-unused-vars
                let { doc, ...query } = { ...this.$route.query }
                this.$router.push({ query: { ...query, doc: "false" } })
            }
        },
        destroyed() {
            this.highlighter.onDestroy()
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "of": "of"
                },
                [Locales.RU]: {
                    "of": "из"
                },
            }
        }
    }
</script>

<style scoped>
    #docs {
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
        display: grid;
        grid-template-rows: 1fr 7rem;
        padding: 1rem 2rem;
        z-index: var(--modal-content-layer);
        background-color: white;
    }
</style>
