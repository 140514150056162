<template>
    <p-dropdown>
        <template v-slot:activator="{ toggleDropdown }">
            <button
                class="dropdown-btn__primary"
                v-bind="$attrs"
                v-on="$listeners"
            >
                <slot></slot>
            </button>
            <p-icon-button
                @click.stop="toggleDropdown"
                class="dropdown-btn__toggle"
                size="1em"
                icon-name="arrow_drop_down"
            />
        </template>
        <ul class="dropdown">
            <li>
                <slot name="secondary-btns"></slot>
            </li>
        </ul>
    </p-dropdown>
</template>

<script>
    export default {
        name: "p-btn-dropdown",
        inheritAttrs: false
    }
</script>

<style scoped>
.md-contained,
.md-outlined {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .0892857143em;
    min-width: 6.4em;
}
/* TODO: add other styles for md-outlined */

.md-contained:disabled {
    color: rgba(0, 0, 0, .37) !important;
    background-color: rgba(0, 0, 0, .12) !important;
    box-shadow: unset !important;
}

.md-contained {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.dropdown-btn__toggle,
.dropdown-btn__primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: currentColor;
    letter-spacing: inherit;
    text-transform: inherit;
    padding: 0 1.1em;
    min-height: 2.6em;
}

.dropdown-btn__primary {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-right: .55em;
}

.dropdown-btn__toggle {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0 0.7em;
}

.md-contained .dropdown-btn__primary:focus,
.md-contained .dropdown-btn__primary:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.md-contained .dropdown-btn__toggle:focus,
.md-contained .dropdown-btn__toggle:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    color: inherit;
}
</style>
