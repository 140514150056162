<template>
    <div
        id="raw-data-upload-choose-lot-step"
        class="flex"
        style="justify-content: center"
    >
        <form class="choose-lot-form">
            <p-input
                :value="platform.name"
                class="mv-2 md-outlined"
                disabled
                :placeholder="$t('platform')"
                type="text"
                style="width: 60%;"
            />
            <p-select-with-search
                :options="lots"
                :view="lot => lot.name"
                :placeholder="$tc('lot', 1)"
                :get-key="lot => lot.name"
                :value="selectedLot"
                @input="$emit('lot-selected', $event)"
                class="mv-2 md-outlined"
                style="width: 60%;"
                :search-query.sync="searchQuery"
            />
        </form>
    </div>
</template>

<script>
    import { debounce } from "@/main"
    import { mapActions } from "vuex"
    import { fetchLots } from "@/endpoints"

    export default {
        name: "ChooseLotStep",
        props: {
            platform: Object,
            selectedLot: Object
        },
        created() {
            this.debouncedFetchLots = debounce(() => fetchLots({ nameStartsWith: this.searchQuery }), 500)
            fetchLots()
                .then(lots => this.lots = lots)
        },
        watch: {
            searchQuery() {
                this.debouncedFetchLots()
                    .then(lots => this.lots = lots)
            },
        },
        data() {
            return {
                searchQuery: "",
                lots: null,
            }
        },
    }
</script>

<style scoped>
.choose-lot-form {
    font-size: 1.8rem;
    grid-column: 2 / 3;
    min-width: 20em;
    max-width: 30em;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
</style>
