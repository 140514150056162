<template>
    <div :class="classObject"
         @dragenter.prevent.stop="dragEnterHandler"
         @dragleave.prevent.stop="dragLeaveHandler"
         @dragover.prevent.stop="dragOverHandler"
         @drop.prevent.stop="dropHandler"
         ref="dropzone">
        <slot></slot>
    </div>
</template>

<script>

    import { createFileFromFileEntry, listFiles } from "@/utils/file-system"

    /**
     * Note that dragstart and dragend events are not fired when dragging a file into the browser from the OS. From
     * https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API. Therefore drageenter and dragleave are used
     *
     * All code for browser compatibility check is removed for several reasons. We don't support old browsers
     * and safari/opera. Even if we did, compatibility checks must be done before dropzone creation
     * and it should be replaced with simple browser file explorer if not compatible.
     * Example of compatibility check - https://css-tricks.com/drag-and-drop-file-uploading
     */
    export default {
        name: "PFileDropzone",
        props: {
            onFilesDrop: {
                type: Function
            }
        },
        data() {
            return {
                classObject: {
                    dragover: false
                }
            }
        },
        methods: {
            dropHandler(event) {
                this.classObject.dragover = false

                /*
                 * For some reason dataTransfer is declared as nullable, however there is no
                 * reliable information about cases when it can be null, aside from cases
                 * when DragEvent is created manually, and there is no way to add dataTransfer to it.
                 */
                if (!event.dataTransfer) {
                    console.warn("Surprise, dataTransfer is null")
                    return
                }

                Promise.all(
                    /* Why items not files property is used - https://stackoverflow.com/q/44842247,
                     * though nothing said about it in https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API.
                     *
                     * So items is used cause it just work
                     */
                    [ ...event.dataTransfer.items ]
                        .map(item => item.webkitGetAsEntry())
                        .filter(entry => entry)
                        .map(entry => entry.isDirectory ? listFiles(entry, false) : [ entry ])
                )
                    .then(it => it.flatMap(fileEntries => fileEntries))
                    .then(fileEntries => Promise.all(fileEntries.map(fileEntry => createFileFromFileEntry(fileEntry))))
                    .then(files => this.onFilesDrop(files))
            },
            // Dropping must be prohibit on every dragover event. What a miracle!
            dragOverHandler(event) {
                if (!hasFileContent(event)) {
                    event.dataTransfer.effectAllowed = "none"
                    event.dataTransfer.dropEffect = "none"
                }
            },
            dragEnterHandler(event) {
                if (hasFileContent(event)) {
                    this.classObject.dragover = true
                }
            },
            dragLeaveHandler(event) {
                const { top, bottom, left, right } = this.$refs.dropzone.getBoundingClientRect()
                /*
                 * Without this check I cannot distinguish dragLeave caused by leave of dropzone
                 * from dragLeave caused by entering children of dropzone.
                 *
                 * Keep in mind that 0, 0 is left top corner
                 */
                if (event.x >= right || left >= event.x || top >= event.y || event.y >= bottom) {
                    this.classObject.dragover = false
                }
            }
        }
    }

    function hasFileContent(event) {
        /*
         * For some reason firefox (Firefox Quantum 69) adds "application/x-moz-file" type along with the "Files"
         * So to save myself from similar issues, will rely on "Files" presence only, strict to specification.
         *
         * https://html.spec.whatwg.org/multipage/dnd.html#the-datatransfer-interface
         * > In addition, if any files are being dragged, then one of the types will be the string "Files".
         */
        return event.dataTransfer
            ? event.dataTransfer.types.includes("Files")
            : false
    }
</script>
