<template>
    <p-with-tooltip fixed>
        <RunLotBadge :lot-name="lotName" :control-sample-status="controlSampleStatus"/>
        <template #tooltip>
            <div class="tooltip-black" style="text-align: center">
                <ul>
                    <li>
                        <a class="white-link"
                           href="#"
                           @click="viewPipelineDocs(configuredPipelineName)"
                        >{{ $t("pipeline") }}: {{ configuredPipelineName }}</a>
                    </li>
                    <li>{{ $t("imgt") }}: {{ imgtVersion }}</li>
                </ul>
            </div>
        </template>
    </p-with-tooltip>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import RunLotBadge from "@/components/run-lot-badge"
    import { viewPdfInNewTab } from "@/helpers"
    import { fetchPipelineDocsLink } from "@/endpoints"

    export default {
        name: "AnalysisLotBadge",
        components: { RunLotBadge },
        props: {
            controlSampleStatus: {
                type: String,
                required: true
            },
            lotName: {
                type: String,
                required: true
            },
            configuredPipelineName: {
                type: String,
                required: true
            },
            imgtVersion: {
                type: String,
                required: true
            }
        },
        methods: {
            async viewPipelineDocs(name) {
                viewPdfInNewTab(await fetchPipelineDocsLink(name))
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "pipeline": "Pipeline",
                    "imgt": "IPD-IMGT/HLA"
                },
                [Locales.RU]: {
                    "pipeline": "Алгоритм",
                    "imgt": "IPD-IMGT/HLA"
                }
            }
        }
    }
</script>

<style scoped>
    .white-link {
        color: white;
    }

    .white-link:active {
        color: white;
    }
</style>
