<template>
    <div class="runs-export-container">
        <div style="padding: 4rem; position: relative; font-size: 2rem;">
            <div class="composite-search">
                <div class="badge yellow mh-1" v-if="filters.dateFrom">{{ $t("runFilterPlaceholders.from") }}: {{ filters.dateFrom }}</div>
                <div class="badge yellow" v-if="filters.dateTo">{{ $t("runFilterPlaceholders.to") }}: {{ filters.dateTo }}</div>
                <input :placeholder="$t('runFilterPlaceholders.liveSearch')"
                       autocomplete="off"
                       class="composite-search--input"
                       aria-label="run id live-search"
                       type="text"
                       v-model="liveSearch"
                >
                <button @click="resetFilters"
                        class="composite-search--clear"
                        v-if="filtersHaveSomeValue">
                    <p-icon icon-name="clear" size="1.5em"/>
                </button>
                <button @click="toggleCompositeSearchDropdown"
                        class="composite-search--arrow"
                        ref="showDropdownButton">
                    <p-icon icon-name="arrow_drop_down" size="1.5em"/>
                </button>
                <div @focusout="onCompositeSearchDropdownFocusout"
                     class="composite-search--dropdown"
                     ref="dropdown"
                     tabindex="-1"
                     v-if="compositeSearchDropdownShown"
                >
                    <div style="font-size: 1.5rem">
                        <p-input :placeholder="$t('runId')"
                                 autocomplete="off"
                                 class="md-outlined m-2"
                                 type="text"
                                 v-model="tempFilters.runId"
                        ></p-input>
                        <div class="flex">
                            <p-input :placeholder="$t('runFilterPlaceholders.from')"
                                     autocomplete="off"
                                     class="md-outlined m-2"
                                     type="date"
                                     v-model="tempFilters.dateFrom"
                            ></p-input>
                            <p-input :placeholder="$t('runFilterPlaceholders.to')"
                                     autocomplete="off"
                                     class="md-outlined m-2"
                                     type="date"
                                     v-model="tempFilters.dateTo"
                            ></p-input>
                        </div>
                        <div class="flex" style="font-size: 1.5rem">
                            <button @click="resetFilters"
                                    class="md-btn-text ml-auto mr-1"
                                    style="color: royalblue">{{ $t("buttons.reset") }}
                            </button>
                            <button @click="search"
                                    class="md-btn-contained royal-blue"
                                    ref="searchDropdownCloser">{{ $t("buttons.search") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="run-swimlanes-container">
            <RunsSwimlane id="not-ready-runs-swimlane" :filter="backendCompatibleFilters" status="NOT_READY"/>
            <RunsSwimlane id="ready-runs-swimlane" :filter="backendCompatibleFilters" status="READY"/>
            <RunsSwimlane id="already-runs-swimlane" :filter="backendCompatibleFilters" status="ALREADY"/>
        </div>
    </div>
</template>

<script>
    import { compositeSearchMixin } from "@/mixins/composite-search"
    import { prepareFilters } from "@/utils/sequencing-run"

    import RunsSwimlane from "./components/runs-swimlane"

    export default {
        name: "RunsDataExport",
        components: { RunsSwimlane },
        mixins: [ compositeSearchMixin ],
        data() {
            return {
                liveSearchFilterName: "runId",
                backendCompatibleFilters: {},
            }
        },
        methods: {
            startNewSearch(filters) {
                this.backendCompatibleFilters = prepareFilters({
                    ...filters,
                    runId: { value: filters.runId, modifier: "startsWith" }
                })
            }
        }
    }
</script>

<style scoped>
    .run-swimlanes-container {
        height: 100%;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: repeat(3, 1fr);
        margin: auto;
    }

    .runs-export-container {
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
    }
</style>
