<template>
    <div class="license-container">
        <div class="license" >
            <h2 class="h3">Принятое лицензионное соглашение - v.PAR-{{ formattedLicenseSerialNumber }} ({{ $d(user.licenseAgreement.acceptedAt) }})</h2>
            <br><br>
            <article class="license__text overflow-auto custom-scroll">
                <h4 style="text-align: center; margin-bottom: 2rem; position: sticky; top: 0; background-color: white">Уважаемый Пользователь!</h4>
                <p v-html="licenseText"></p>
            </article>
        </div>
        <footer class="footer">
            <p style="text-align: center">
                <span>Программное обеспечение «PARallele™ HLA software» - правообладатель ООО "ПАРСЕК ЛАБ"</span>
                <br>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.parseq.pro/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20PARallele%E2%84%A2%20HLA%20software.pdf"
                >Свидетельство о государственной регистрации программы для ЭВМ №2020615630</a>
            </p>
            <div>
                <p>Товарный знак "PARallele" - правообладатель ООО "ПАРСЕК ЛАБ"</p>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.parseq.pro/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20%D0%A2%D0%97%20PARallele%20803655.pdf"
                >Свидетельство на товарный знак (знак обслуживания) № 803655</a>
            </div>

        </footer>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { fetchEula } from "@/endpoints"

    export default {
        name: "Terms",
        data() {
            return {
                lastAcceptedLicenseVersion: 1,
                licenseText: null
            }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
            formattedLicenseSerialNumber() {
                return this.user.licenseAgreement.serialNumber.toString().padStart(2, 0)
            }
        },
        mounted() {
            fetchEula(this.user.licenseAgreement.serialNumber)
                .then(eula => {
                    // eslint-disable-next-line promise/always-return
                    if (eula) {
                        this.licenseText = eula.text
                    } else {
                        console.error(`Missing license text with serial number: ${this.user.licenseAgreement.serialNumber}`)
                    }
                })
        }
    }
</script>

<style scoped>
.license-container {
    padding: 1rem 2rem;
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: auto;
}

.license {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.license__text {
    max-width: 70rem;
    padding: 0 3rem;
    text-align: justify;
}

.footer {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
    color: var(--grey-600);
}
</style>
