import {
    axisBottom,
    axisLeft,
    scaleLinear,
    ScaleLinear,
    ScaleOrdinal,
    scaleOrdinal,
    select,
    Selection,
} from "d3"
import { addTooltip, CIRCLE_RADIUS, deemphasizeDataPoint, emphasizeDataPoint, SQUARE_SIZE } from "@/charts/utils"
import { Locales } from "@/i18n/main"
import VueI18n from "vue-i18n"
import _ from "lodash"

interface AnalysisGeneralMetricsChart {
    svg: Selection<SVGGElement, unknown, any, any>
    width: number
    height: number
    x: ScaleLinear<number, number>
    y: ScaleLinear<number, number>
    margin: {
        top: number
        right: number
        bottom: number
        left: number
    }
    color: ScaleOrdinal<string, string>
    resize: () => void
    onLocaleChange: (locale: Locales) => void
    onDataChange: (data: { data: Array<DataPoint>, activeDataPointId: string }) => void
}

interface DataPoint {
    quality: string
    value: [ number, number ]
    name: string,
    isControlSample: boolean
}

interface Coordinate {
    x: number
    y: number
}

class Area {
    constructor(
        private element: any,
        public xInterval: [ number, number ],
        public yInterval: [ number, number ]
    ) {
    }

    highlight() {
        this.element.style("fill", "var(--transparent-yellow)")
    }

    removeHighlight() {
        this.element.style("fill", "none")
    }
}

interface GeneralMetricThresholds {
    insertSize: Thresholds,
    totalReads: Thresholds
}

interface Thresholds {
    "red"?: Array<[ number | null, number | null ]>
    "yellow"?: Array<[ number | null, number | null ]>
}

function fallsIntoInterval(point: number, interval: [ number | null, number | null ]) {
    return (Math.floor(point) >= (interval[0] ?? Number.NEGATIVE_INFINITY))
        && (Math.ceil(point) <= (interval[1] ?? Number.POSITIVE_INFINITY))
}

function fallsIntoArea(area: Area, point: [ number, number ]) {
    const [ x, y ] = point
    return fallsIntoInterval(x, area.xInterval) && fallsIntoInterval(y, area.yInterval)
}


/**
 * Find all areas `dataPoint` falls into
 */
function findEncompassingAreas(dataPoint: DataPoint, areas: Array<Area>): Array<Area> {
    return areas.filter(area => fallsIntoArea(area, dataPoint.value))
}


export function drawChart(
    data: { data: Array<DataPoint>, activeDataPointId: string, thresholds: GeneralMetricThresholds },
    containerId: string,
    i18n: VueI18n,
) {
    const container = document.querySelector(containerId)
    if (container === null) {
        throw new Error(`Chart container element with id - ${containerId} - not found`)
    }
    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight
    const margin = { top: 30, right: 30, bottom: 70, left: 60 }
    const chartHeight = containerHeight - margin.top - margin.bottom
    const chartWidth = containerWidth - margin.left - margin.right
    let activeDataPointId: string | null = null
    const minimumTotalReadsThreshold: number | null | undefined = data.thresholds.totalReads?.["red"]?.[0]?.[1]
    const minimumInsertSizeThreshold: number | null | undefined = data.thresholds.insertSize?.["red"]?.[0]?.[1]
    if (!minimumInsertSizeThreshold) {
        console.log("Insert size red thresholds are missing, or threshold file has unsupported format. They won't be rendered", data.thresholds)
    }
    if (!minimumTotalReadsThreshold) {
        console.log("Total reads red thresholds are missing, or threshold file has unsupported format. They won't be rendered", data.thresholds)
    }
    const collectStats = (values: Array<number>) => {
        const sortedData = values.sort((a, b) => a - b)
        const max = sortedData[sortedData.length - 1]
        const min = sortedData[0]
        return {
            min,
            max,
            range: max - min
        }
    }

    const insertSizeStats = collectStats(data.data.map(it => it.value[1]))
    const totalReadsStats = collectStats(data.data.map(it => it.value[0]))

    const y = scaleLinear()
        .domain([
            insertSizeStats.min - insertSizeStats.range * 0.05,
            insertSizeStats.max + insertSizeStats.range * 0.05,
        ])
        .range([ chartHeight, 0 ])
    const x = scaleLinear()
        .domain([
            totalReadsStats.min - totalReadsStats.range * 0.03,
            // Always display minimum total reads threshold border on chart
            Math.max(totalReadsStats.max, minimumTotalReadsThreshold ?? 0) + totalReadsStats.range * 0.05,
        ])
        .range([ 0, chartWidth ])

    const svg = select(containerId)
        .append("svg")
        .attr("width", chartWidth)
        .attr("height", chartHeight)
    svg.attr("viewBox", "0 0 " + containerWidth + " " + containerHeight)
        .attr("preserveAspectRatio", "xMidYMid meet")
    resize()

    function resize() {
        const targetWidth = container!.clientWidth
        const targetHeight = container!.clientHeight
        svg.attr("width", targetWidth)
        svg.attr("height", targetHeight)
    }

    function onLocaleChange() {
        select("#x-label").text(<string>i18n.t("analysisMetrics.totalReads"))
        select("#y-label").text(<string>i18n.t("analysisMetrics.insertSize"))
    }

    function emphasizeRectDataPoint(rect: Selection<SVGRectElement, any, any, any>) {
        return rect
            .transition()
            .duration(150)
            .attr("stroke", "black")
            .style("stroke-width", "1px")
            .attr("width", SQUARE_SIZE + 4)
            .attr("height", SQUARE_SIZE + 4)
            .attr("x", sampleCoordinates => x(sampleCoordinates.value[0])! - SQUARE_SIZE/2 -2)
            .attr("y", sampleCoordinates => y(sampleCoordinates.value[1])! - SQUARE_SIZE/2 -2)
    }

    function deemphasizeRectDataPoint(rect: Selection<SVGRectElement, any, any, any>) {
        return rect
            .transition()
            .duration(150)
            .attr("stroke", "none")
            .attr("width", SQUARE_SIZE)
            .attr("height", SQUARE_SIZE)
            .attr("x", sampleCoordinates => x(sampleCoordinates.value[0])! - SQUARE_SIZE/2)
            .attr("y", sampleCoordinates => y(sampleCoordinates.value[1])! - SQUARE_SIZE/2)
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    function onDataChange(data: { data: Array<DataPoint>, activeDataPointId: string }) {

        if (data.activeDataPointId) {
            activeDataPointId = data.activeDataPointId
            emphasizeDataPoint(svg.selectAll<SVGCircleElement, DataPoint>("circle")
                .filter((d) => d.name === activeDataPointId))
            emphasizeRectDataPoint(svg.selectAll<SVGRectElement, DataPoint>(".chart-dot-rect")
                .filter((d) => d.name === activeDataPointId))
        } else if (activeDataPointId) {
            deemphasizeDataPoint(svg.selectAll<SVGCircleElement, DataPoint>("circle")
                .filter((d) => d.name === activeDataPointId))
            deemphasizeRectDataPoint(svg.selectAll<SVGRectElement, DataPoint>(".chart-dot-rect")
                .filter((d) => d.name === activeDataPointId))
            activeDataPointId = null
        }
    }

    const chartGroup = svg
        .append("g")
        .attr(
            "transform",
            `translate(${margin.left},${margin.top})`
        )

    const color = scaleOrdinal<string, string>()
        .domain([ "grey", "yellow", "red" ])
        .range([ "#e7f3ff", "#FFCA28", "#D32F2F" ])

    const chart: AnalysisGeneralMetricsChart = {
        svg: chartGroup,
        width: chartWidth,
        height: chartHeight,
        x,
        y,
        margin,
        color,
        resize,
        onLocaleChange,
        onDataChange
    }

    addGrid(chart)
    addAxes(chart)
    addXLabel(chart)
    addYLabel(chart)
    if (minimumTotalReadsThreshold) addTotalReadsThresholdLine(chart, minimumTotalReadsThreshold)
    if (minimumInsertSizeThreshold) addInsertSizeThresholdLine(chart, minimumInsertSizeThreshold)
    onLocaleChange()

    const tooltip = addTooltip(select(containerId))

    /*
    * We want the following effect. You hover over data point if any of it's value is in yellow thresholds zone
    * corresponding zone is highlighted (VH-49).
    *
    * First solution (not working): we could add area on hover and remove it after, but SVG 1.1 doesn't support z-index.
    * This results in area being over data point and hover is lost, area is removed, and you mouse is over data point again,
    * so area is added and so on.
    *
    * Second solution (working and implemented):
    * Add all areas without fill before data points and fill them on data point hover. This results
    * in some ugly code encapsulated in `createYellowAreas`
    */
    const areas = createYellowAreas(chart, data.thresholds)

    const mouseenter = (event: MouseEvent, d: DataPoint) => {
        emphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        tooltip
            .html(d.name)
            .style("display", "block")
            .style("left", `${event.clientX + 20}px`)
            .style("top", `${event.clientY}px`)

        findEncompassingAreas(d, areas).forEach(area => area.highlight())
    }

    const mouseenterRect = (event: MouseEvent, d: DataPoint) => {
        emphasizeRectDataPoint(select(event.currentTarget as SVGRectElement))
        tooltip
            .html(d.name)
            .style("display", "block")
            .style("left", `${event.clientX + 20}px`)
            .style("top", `${event.clientY}px`)

        findEncompassingAreas(d, areas).forEach(area => area.highlight())
    }

    const mouseleave = (event: MouseEvent, d: DataPoint) => {
        deemphasizeDataPoint(select(event.currentTarget as SVGCircleElement))
        findEncompassingAreas(d, areas).forEach(area => area.removeHighlight())

        return tooltip
            .style("display", "none")
    }

    const mouseleaveRect = (event: MouseEvent, d: DataPoint) => {
        deemphasizeRectDataPoint(select(event.currentTarget as SVGRectElement))
        findEncompassingAreas(d, areas).forEach(area => area.removeHighlight())

        return tooltip
            .style("display", "none")
    }

    const [ controlSamples, nonControlSamples ] =
        _.partition(data.data, sample => sample.isControlSample)

    // Circles have appearance animation of radius increasing form 0 to CIRCLE_RADIUS
    chartGroup.append("g")
        .attr("class", "chart-dot-list")
        .selectAll("dot")
        .data(nonControlSamples)
        .enter()
        .append("circle")
        .attr("class", "chart-dot-circle")
        .attr("cx", d => x(d.value[0])!)
        .attr("cy", d => y(d.value[1])!)
        .attr("r", 0)
        .style("fill", d => color(d.quality))
        // @ts-ignore d3 types are stale
        .on("mouseenter", mouseenter)
        // @ts-ignore d3 types are stale
        .on("mouseleave", mouseleave)

    chartGroup.selectAll(".chart-dot-list")
        .selectAll("dot")
        .data(controlSamples)
        .enter()
        .append("rect")
        .attr("class", "chart-dot-rect")
        .attr("x", sampleCoordinates => x(sampleCoordinates.value[0])! - SQUARE_SIZE/2)
        .attr("y", sampleCoordinates => y(sampleCoordinates.value[1])! - SQUARE_SIZE/2)
        .attr("width", SQUARE_SIZE)
        .attr("height", SQUARE_SIZE)
        .attr("transform", sampleCoordinates => `rotate(45, ${x(sampleCoordinates.value[0])!}, ${y(sampleCoordinates.value[1])!})`)
        .style("fill", d => color(d.quality))
        // @ts-ignore d3 types are stale
        .on("mouseenter", mouseenterRect)
        // @ts-ignore d3 types are stale
        .on("mouseleave", mouseleaveRect)

    chartGroup.selectAll(".chart-dot-circle")
        .transition()
        .delay((d, i) => i * 2)
        .duration(200)
        .attr("r", CIRCLE_RADIUS)

    return chart
}


function addGrid(chart: AnalysisGeneralMetricsChart) {
    const { svg, height, width, x, y } = chart
    svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .style("color", "rgb(204, 204, 204)")
        .call(axisBottom(x).ticks(5).tickSizeInner(-height).tickFormat(() => ""))
        .select(".domain").remove()

    svg.append("g")
        .style("color", "rgb(204, 204, 204)")
        .call(axisLeft(y).ticks(5).tickSizeInner(-width).tickFormat(() => ""))
        .select(".domain").remove()
}


function addAxes(chart: AnalysisGeneralMetricsChart) {
    const { svg, height, x, y } = chart
    svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .attr("class", "chart-axis")
        .call(axisBottom(x).ticks(5).tickSizeOuter(0))

    svg.append("g")
        .attr("class", "chart-axis")
        .call(axisLeft(y).ticks(5).tickSizeOuter(0))
}

function addXLabel(chart: AnalysisGeneralMetricsChart) {
    const { svg, width, height, margin } = chart
    svg.append("text")
        .attr("id", "x-label")
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "middle")
        .attr("x", width / 2)
        .attr("y", height + margin.top + 15)
}

function addYLabel(chart: AnalysisGeneralMetricsChart) {
    const { svg, margin } = chart
    svg.append("text")
        .attr("id", "y-label")
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "start")
        .attr("y", -margin.top / 2)
        .attr("x", -margin.left / 2)
}

function addTotalReadsThresholdLine(chart: AnalysisGeneralMetricsChart, minimumTotalReadsThreshold: number) {
    const { svg, height, x, color } = chart
    /*
     Without this check threshold can be shown when chart is height enough for this
     threshold to fit in
    */
    if (x.domain()[0] < minimumTotalReadsThreshold) {
        svg.append("line")
            .attr("x1", x(minimumTotalReadsThreshold)!)
            .attr("y1", 0)
            .attr("x2", x(minimumTotalReadsThreshold)!)
            .attr("y2", height)
            .style("stroke-width", 2)
            .style("stroke-dasharray", "4 2")
            .style("stroke", color("red"))
            .style("fill", "none")
    }
}

function addInsertSizeThresholdLine(chart: AnalysisGeneralMetricsChart, minimumInsertSizeThreshold: number) {
    const { svg, width, y, color } = chart
    /*
     Without this check threshold can be shown when chart is height enough for this
     threshold to fit in
    */
    if (y.domain()[0] < minimumInsertSizeThreshold) {
        svg.append("line")
            .attr("x1", 0)
            .attr("y1", y(minimumInsertSizeThreshold)!)
            .attr("x2", width)
            .attr("y2", y(minimumInsertSizeThreshold)!)
            .style("stroke-width", 2)
            .style("stroke-dasharray", "4 2")
            .style("stroke", color("red"))
            .style("fill", "none")
    }
}


function createYellowAreas(chart: AnalysisGeneralMetricsChart, generalMetricThresholds: GeneralMetricThresholds) {
    const { svg, x, y } = chart
    const areaIntervals: Array<any> = []
    const category = "yellow";
    // `height` can be used instead of y(y.domain()[0]), but I think y(y.domain()[0]) is more consistent
    (generalMetricThresholds.totalReads[category] ?? []).forEach(it => {
        const [ start, end ] = it
        if (start && start > x.domain()[1] || end && end < x.domain()[0]) {
            return
        }
        const xInterval = [
            Math.max(start || Number.NEGATIVE_INFINITY, x.domain()[0]),
            Math.min(end || Number.POSITIVE_INFINITY, x.domain()[1]),
        ]
        const yInterval = [ y.domain()[0], y.domain()[1] ]
        areaIntervals.push({
            xInterval,
            yInterval,
        })
    });

    (generalMetricThresholds.insertSize[category] ?? []).forEach(it => {
        const [ start, end ] = it
        if (start && start > y.domain()[1] || end && end < y.domain()[0]) {
            return
        }
        const xInterval = [ x.domain()[0], x.domain()[1] ]
        const yInterval = [
            Math.max(start || Number.NEGATIVE_INFINITY, y.domain()[0]),
            Math.min(end || Number.POSITIVE_INFINITY, y.domain()[1]),
        ]
        areaIntervals.push({
            xInterval,
            yInterval,
        })
    })


    // eslint-disable-next-line @typescript-eslint/no-shadow
    function addRect(svg: Selection<SVGGElement, unknown, any, any>, topLeft: Coordinate, bottomRight: Coordinate) {
        return svg.append("rect")
            .attr("x", topLeft.x)
            .attr("y", topLeft.y)
            .attr("width", bottomRight.x - topLeft.x)
            .attr("height", bottomRight.y - topLeft.y)
            .style("fill", "none")
    }

    return areaIntervals.map(template => new Area(
        addRect(
            svg,
            { x: x(template.xInterval[0])!, y: y(template.yInterval[1])! },
            { x: x(template.xInterval[1])!, y: y(template.yInterval[0])! },
        ),
        template.xInterval,
        template.yInterval
    ))
}
