export interface Event<T, U> {
    type: U
    payload: T
}

export class EventBus<U> {

    listeners: Map<U, Array<any>> = new Map()

    subscribe<T>(eventType: U, callback: (payload: T) => any) {

        if (!this.listeners.has(eventType)) {
            this.listeners.set(eventType, [])
        }
        this.listeners.get(eventType)!.push(callback)
    }

    emit<T>(event: Event<T, U>) {
        const listeners = this.listeners.get(event.type)
        if (listeners) {
            listeners.forEach(it => it(event.payload))
        }
    }
}
