import { arc, pie, PieArcDatum, scaleOrdinal, schemeSet2, select, Selection } from "d3"
import { sum } from "@/extensions/array-extensions"
import { Margin } from "@/charts/base-chart"
import VueI18n from "vue-i18n"

type DataPoint = [ string, number ]

export function drawChart(
    data: [ DataPoint ],
    containerId: string,
    i18n: VueI18n
) {

    const container = document.querySelector(containerId)
    if (container === null) {
        throw new Error(`Chart container element with id - ${containerId} - not found`)
    }
    const width = container.clientWidth
    const height = container.clientHeight
    const aspect = width / height
    // To align with other charts
    const margin = 15
    const radius = Math.min(width, height) / 2.3 - margin

    const svg = select(containerId)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
    const chartGroup = svg
        .append("g")
        .style("width", "100%")
        .attr("transform", `translate(${width / 2},${height / 2 + 10})`)

    svg.attr("viewBox", "0 0 " + width + " " + height)
        .attr("preserveAspectRatio", "xMinYMid")

    function resize() {
        const targetWidth = container!.clientWidth
        console.debug("Resize called", { targetWidth })
        svg.attr("width", targetWidth)
        svg.attr("height", Math.round(targetWidth / aspect))
    }

    function onLocaleChange() {
        select("#reads-distribution-chart-title").text(<string>i18n.t("analysisMetrics.readsDistribution"))
    }

    const color = scaleOrdinal<string, string>(schemeSet2)
    const pieGenerator = pie<DataPoint>().value(d => d[1])
    const pieData = pieGenerator(data)

    const mouseover = (event: MouseEvent, arcDatum: PieArcDatum<DataPoint>) => {
        select(event.currentTarget as SVGPathElement)
            .style("opacity", 1)
        setReadGroupText(arcDatum.data[0], arcDatum.data[1])
    }

    const mouseleave = (event: MouseEvent) => {
        select(event.currentTarget as SVGPathElement)
            .style("opacity", .7)
        setReadGroupText("total", sum(data.map(it => it[1])))
    }

    addReadGroupText({ chartGroup })
    setReadGroupText("total", sum(data.map(it => it[1])))
    addTitle({
        svg,
        margin: { left: margin, top: margin, right: margin, bottom: margin },
        containerWidth: width
    })
    onLocaleChange()

    chartGroup
        .selectAll("whatever")
        .data(pieData)
        .enter()
        .append("path")
        .attr("d", arc<PieArcDatum<DataPoint>>()
            .innerRadius(radius * 0.7)
            .outerRadius(radius))
        // @ts-ignore d3 types are stale
        .on("mouseover", mouseover)
        // @ts-ignore d3 types are stale
        .on("mouseleave", mouseleave)
        .attr("fill", d => color(d.data[0]))
        .attr("stroke", "black")
        .style("stroke-width", "1px")
        .style("opacity", 0.7)

    function setReadGroupText(name: string, value: number) {
        select("#read-group-name")
            .text(<string>i18n.t(`analysisMetrics.${name}`))
        select("#read-group-value")
            .text(value)
    }

    return { svg: chartGroup, resize, onLocaleChange }
}


function addReadGroupText(chart: { chartGroup: Selection<SVGGElement, unknown, any, any> }) {
    chart.chartGroup.append("text")
        .attr("id", "read-group-name")
        .attr("text-anchor", "middle")
        .attr("x", 0)
        .style("font-size", ".8em")
        // Empiric value to center text on Y-axis
        .style("transform", "translateY(-.66em)")

    chart.chartGroup.append("text")
        .attr("id", "read-group-value")
        .attr("text-anchor", "middle")
        .attr("x", 0)
        .style("font-size", ".8em")
        // Empiric value to center text on Y-axis
        .style("transform", "translateY(1.33em)")
}

function addTitle(chart: {
    svg: Selection<SVGSVGElement, unknown, HTMLElement, any>
    containerWidth: number
    margin: Margin
}) {
    const { svg, containerWidth, margin } = chart
    svg.append("text")
        .attr("id", "reads-distribution-chart-title")
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "middle")
        .style("font-size", "1.7rem")
        .style("font-weight", "600")
        .attr("x", containerWidth / 2)
        .attr("y", margin.top)
}
