<template>
    <div class="relative"
         ref="container">
        <p-input :value="value"
                 @blur="dropdownShown = false"
                 @input="inputHandler"
                 @focusin="inputHandler($event.target.value)"
                 v-bind="$attrs"
                 :class="mdClasses"
        />
        <!-- Mousedown is prevented because it's fired before option click event and causes blur on input that leads to
             dropdown hiding which prevent option click generation -->
        <div @mousedown.prevent
             class="type-ahead__dropdown custom-scroll"
             :style="dropdownPosition"
             v-show="dropdownShown">
            <ul class="options">
                <li class="option"
                    v-if="options.length === 0">
                    <i>No matching options</i>
                </li>
                <template v-else>
                    <li @click="optionClickHandler(option)"
                        class="option"
                        :key="option"
                        v-for="option in options"
                    >{{ option }}
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PTypeAheadInput",
        inheritAttrs: false,
        props: {
            options: {
                type: Array,
                required: true
            },
            value: String
        },
        data() {
            return {
                dropdownShown: false,
                mdClasses: null,
                dropdownPosition: null
            }
        },
        watch: {
            dropdownShown: {
                /*
                * The observer, when opening the dropdown, sets the width to the width of the parent container in which it is located
                */
                handler() {
                    if(this.dropdownShown && this.$refs["container"]) {
                        const { width } = this.$refs["container"].getBoundingClientRect()
                        this.dropdownPosition = {
                            width: `${width}px`,
                            "max-height": "15em"
                        }
                    }
                }
            }
        },
        methods: {
            inputHandler(value) {
                this.$emit("input", value)
                this.dropdownShown = true
            },
            optionClickHandler(option) {
                this.$emit("input", option)
                this.dropdownShown = false
            }
        },
        mounted() {
            /*
             * Dirty trick that doesn't heal the problem completely
             * p-input requires one of the md classes to be set, and I want to pass
             * this class form this component to child p-input. Vue has no means to do this,
             * so I do it manually. Nevertheless p-input is mounted before this component
             * is mounted, so it raises error since it has no md class.
             */
            this.mdClasses = [ ...this.$el.classList ].filter(it => it.startsWith("md"))

            this.dropdownPosition = {
                ...this.dropdownPosition,
                "max-height": "15em"
            }
        }
    }
</script>

<style scoped>
    .option {
        padding: .5rem 1rem;
    }

    .option:hover {
        background-color: var(--light-blue);
    }

    .type-ahead__dropdown {
        position: absolute;
        background-color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .2);
        border-radius: 2px;
        padding: 2px;
        height: fit-content;
        overflow: auto;
        z-index: var(--second-layer);
    }
</style>
