import {
    Run,
    SequencingRunFilter,
} from "@/store/modules/runs"
import { removeEmptyFields } from "@/extensions/object-extensions"
import ld from "lodash"


export function prepareFilters(
    filters: Partial<{
        runId: {
            value: string,
            modifier: "startsWith" | "equals"
        },
        dateFrom: number,
        dateTo: number,
        withExportStatus: string
    }>
): SequencingRunFilter {
    const name = filters.runId?.value ? `${filters.runId.modifier}:${filters.runId.value}` : null
    const from = filters.dateFrom ? new Date(filters.dateFrom).getTime() : null
    const to = filters.dateTo ? new Date(filters.dateTo).setHours(23, 59, 59, 999) : null

    return removeEmptyFields({ name, from, to, withExportStatus: filters.withExportStatus })
}

export function samplesNumber(run: Run): number {
    // `ld.sum([]) === 0`. I wondered about and decided to comment
    return ld.sum(Object.values(run.properties.statusAggs ?? {}))
}

export function isAnalysed(run: Run): boolean {
    return ((run.properties?.statusAggs?.RUNNING ?? 0) + (run.properties?.statusAggs?.ERROR ?? 0)) === 0
}
