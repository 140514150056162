import Vue from "vue"
import Vuex, { ActionTree, GetterTree, MutationTree, Store } from "vuex"
import { rawDataUpload } from "@/store/modules/raw-data-upload"
import { alreadyRuns, notReadyRuns, readyRuns, runs } from "@/store/modules/runs"
import { backgroundTasks } from "@/store/modules/background-tasks"
import { authentication } from "@/store/modules/authentication"
import { archive } from "@/store/modules/archive"
import { samples } from "@/store/modules/samples"
import { newNotifications, newNotificationsActionNames, readNotifications } from "@/store/modules/notifications"
import { support } from "@/store/modules/support"
import { configuredPipelines } from "@/store/modules/configured-pipelines"
import { documentedReagentKitLots } from "@/store/modules/documented-reagent-kit-lots"
import { lotManagement } from "@/store/modules/lot-management"
import { fetchAlleleFrequencies, fetchStatistics, supportEndpoints } from "@/endpoints"

Vue.use(Vuex)

type OrganizationStatistics = {
    awaitingCustomerAnalysisNumber: number
    awaitingReviewAnalysisNumber: number
    awaitingSupportAnalysisNumber: number
}

type SupportStatistics = {
    awaitingSupportAnalysisNumber: number
}

export interface AppState {
    alleleFrequencies: any
    // We have ADMIN role, so I will let this statistics coexist for now
    organizationStatistics: OrganizationStatistics | null
    supportStatistics: SupportStatistics | null,
    accountDataReady: boolean
}

function initialState(): AppState {
    return {
        alleleFrequencies: {},
        organizationStatistics: null,
        supportStatistics: null,
        accountDataReady: false
    }
}

const actions: ActionTree<AppState, any> = {
    async fetchAlleleFrequencies({ commit }) {
        commit("setAlleleFrequencies", { alleleFrequencies: await fetchAlleleFrequencies() })
    },
    async fetchStatistics({ commit, rootGetters }) {
        if (!rootGetters.user) {
            console.error("Fatal error. I cannot fetch statistics without information about user")
            return
        }
        const iHaveAccessToSupportStatistics = [ "SUPPORT", "ADMIN" ]
            .some(requiredRole => rootGetters.user.authorities.includes(requiredRole))
        const iHaveAccessToOrganizationStatistics = [ "TECHNICIAN", "SUPERVISOR", "ADMIN" ]
            .some(requiredRole => rootGetters.user.authorities.includes(requiredRole))

        if (iHaveAccessToSupportStatistics) {
            const statistics = await supportEndpoints.statistics()
            commit("setSupportStatistics", statistics)
        }

        if (iHaveAccessToOrganizationStatistics) {
            commit("setOrganizationStatistics", { statistics: await fetchStatistics() })
        }
    },
    async fetchAccountData({ dispatch, commit }) {
        return Promise.all([
            dispatch("fetchAlleleFrequencies"),
            dispatch("fetchStatistics"),
            dispatch(newNotificationsActionNames.startNewSearch),
        ]).then(() => commit("accountDataReady"))
    }
}

const mutations: MutationTree<AppState> = {
    setAlleleFrequencies(state, { alleleFrequencies }) {
        state.alleleFrequencies = alleleFrequencies
    },
    setOrganizationStatistics(state, { statistics }) {
        state.organizationStatistics = statistics
    },
    setSupportStatistics(state, statistics) {
        state.supportStatistics = statistics
    },
    // TODO: [@aslepchenkov 14.02.2020] Can I dynamically change module depending on role?
    // DRY doom will come upon you
    decreaseAwaitingReviewAnalysisNumber(state) {
        if (state.organizationStatistics) {
            if (state.organizationStatistics.awaitingReviewAnalysisNumber > 0) {
                state.organizationStatistics.awaitingReviewAnalysisNumber--
            } else {
                throw new Error("Something went totally wrong. You are trying to decrease awaitingReviewAnalysisNumber but it's already <= 0")
            }
        } else {
            throw new Error("Something went totally wrong. You are trying to modify empty statistics")
        }
    },
    increaseAwaitingSupportAnalysisNumber(state) {
        if (state.organizationStatistics) {
            state.organizationStatistics.awaitingSupportAnalysisNumber++
        } else {
            throw new Error("Something went totally wrong. You are trying to modify empty statistics")
        }
    },
    decreaseAwaitingCustomerAnalysisNumber(state) {
        if (state.organizationStatistics) {
            if (state.organizationStatistics.awaitingCustomerAnalysisNumber > 0) {
                state.organizationStatistics.awaitingCustomerAnalysisNumber--
            } else {
                throw new Error("Something went totally wrong. You are trying to decrease awaitingCustomerAnalysisNumber but it's already <= 0")
            }
        } else {
            throw new Error("Something went totally wrong. You are trying to modify empty statistics")
        }
    },
    decreaseAwaitingSupportAnalysisNumber(state) {
        if (state.supportStatistics) {
            if (state.supportStatistics.awaitingSupportAnalysisNumber > 0) {
                state.supportStatistics.awaitingSupportAnalysisNumber--
            } else {
                throw new Error("Something went totally wrong. You are trying to decrease awaitingSupportAnalysisNumber but it's already <= 0")
            }
        } else {
            throw new Error("Something went totally wrong. You are trying to modify empty statistics")
        }
    },
    accountDataReady(state) {
        state.accountDataReady = true
    },
    resetState(state) {
        console.debug("App state reset")
        Object.assign(state, initialState())
    }
}

const getters: GetterTree<AppState, any> = {
    alleleFrequencies: state => state.alleleFrequencies,
    organizationStatistics: state => state.organizationStatistics,
    supportStatistics: state => state.supportStatistics,
    accountDataReady: state => state.accountDataReady
}

export const store: Store<AppState> = new Vuex.Store({
    state: initialState(),
    mutations,
    actions,
    getters,
    modules: {
        runs,
        readyRuns,
        notReadyRuns,
        alreadyRuns,
        rawDataUpload,
        backgroundTasks,
        authentication,
        samples,
        archive,
        newNotifications,
        readNotifications,
        support,
        configuredPipelines,
        documentedReagentKitLots,
        lotManagement,
    },
    strict: process.env.NODE_ENV !== "production"
})
