export const knownAmbiguities = {
    "knownAlleleAmbiguities": {
        "A": {},
        "B": {},
        "C": {},
        "DRB1": {
            "DRB1*01:01:01": [
                "DRB1*01:01:01",
                "DRB1*01:100",
            ],
            "DRB1*01:100": [
                "DRB1*01:01:01",
                "DRB1*01:100",
            ],
            "DRB1*03:01:01": [
                "DRB1*03:01:01",
                "DRB1*03:147",
            ],
            "DRB1*03:147": [
                "DRB1*03:01:01",
                "DRB1*03:147",
            ],
        },
        "DQB1": {
            "DQB1*03:276N": [
                "DQB1*03:01",
                "DQB1*03:276N",
                "DQB1*03:297",
            ],
        }
    },
    "knownGenotypeAmbiguities": {
        "A": {
            "A*01:01:01+A*01:02:02": [
                "A*01:01:01+A*01:02:02",
                "A*01:01:03+A*01:02:01",
            ],
            "A*01:01:01+A*01:03:01": [
                "A*01:01:01+A*01:03:01",
                "A*01:01:83+A*01:03:02",
            ],
            "A*01:01:01+A*01:23:01": [
                "A*01:01:01+A*01:23:01",
                "A*01:01:83+A*01:23:02",
            ],
            "A*01:01:01+A*03:01:74": [
                "A*01:01:01+A*03:01:74",
                "A*01:01:83+A*03:01:01",
            ],
        },
        "B": {
            "B*15:335+B*18:101": [
                "B*15:18:01+B*18:57:01",
                "B*15:198+B*18:57:01",
                "B*15:263+B*18:57:01",
                "B*15:307+B*18:57:01",
                "B*15:335+B*18:101",
                "B*15:380N+B*18:57:01",
                "B*15:388+B*18:57:01",
                "B*15:487N+B*18:57:01",
                "B*15:548+B*18:57:01",
            ],
            "B*15:344+B*15:371": [
                "B*15:225+B*15:327",
                "B*15:344+B*15:371",
            ],
        },
        "C": {},
        "DQB1": {},
        "DRB1": {}
    }
}
