<template>
    <div class="pcr-plate-container">
        <!-- eslint-disable vue/require-v-for-key -->
        <div class="pcr-plate"
             v-for="plate in plates">
            <div style="text-align: center"
                 v-for="letter in [''].concat(plate.plateDescription.columnNames)">
                {{ letter }}
            </div>
            <template v-for="(maybeAnalysis, i) in plate.samples">
                <div style="text-align: center"
                     v-if="i % 12 === 0"> {{ plate.plateDescription.rowNames[i / 12] }}
                </div>
                <!-- Tooltip is inlined here because of http://jira.corp.parseq.pro/browse/VH-152 -->
                <div class="tooltip-container">
                    <!-- Maybe it's worth using d3 approach, one div that changes position. Or maybe I will rewrite this chart in d3 -->
                    <svg version="1.1" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle v-if="!isControlSample(maybeAnalysis)"
                                :r="isHoveredSample(maybeAnalysis) ? hoveredIconRadius : regularIconRadius"
                                :stroke="isHoveredSample(maybeAnalysis) ? 'black' : 'none'"
                                :style="{ 'fill': maybeAnalysis ? getWellColor(getAnalysisQuality(maybeAnalysis)) : 'grey' }"
                                :class="maybeAnalysis && 'pcr-plate-well'"
                                cx="12"
                                cy="12"
                                :stroke-width="isHoveredSample(maybeAnalysis) ? hoveredToRegularIconRadiusProportion : 1"
                        />
                        <rect v-else
                              :stroke="isHoveredSample(maybeAnalysis) ? 'black' : 'none'"
                              :style="{ 'fill': maybeAnalysis ? getWellColor(getAnalysisQuality(maybeAnalysis)) : 'grey' }"
                              :class="maybeAnalysis && 'pcr-plate-well'"
                              :stroke-width="isHoveredSample(maybeAnalysis) ? hoveredToRegularIconRadiusProportion : 1"
                              :x="isHoveredSample(maybeAnalysis) ? hoveredControlSampleSquareLeftTopOffset : regularControlSampleSquareLeftTopOffset"
                              :y="isHoveredSample(maybeAnalysis) ? hoveredControlSampleSquareLeftTopOffset : regularControlSampleSquareLeftTopOffset"
                              :width="isHoveredSample(maybeAnalysis) ? hoveredControlSampleSquareSide : regularControlSampleSquareSide"
                              :height="isHoveredSample(maybeAnalysis) ? hoveredControlSampleSquareSide : regularControlSampleSquareSide"
                              transform="rotate(45)"
                        />
                    </svg>
                    <!-- TODO: Should have fixed position -->
                    <div class="pcr-plate-tooltip" v-if="maybeAnalysis">
                        {{ maybeAnalysis.name }}
                    </div>
                </div>
            </template>
        </div>
    </div>
    <!-- eslint-enable vue/require-v-for-key -->
</template>

<script>
    import { range, rangeGenerator } from "@/extensions/array-extensions"
    import ld from "lodash"

    const PLATE_CAPACITY = 96
    const REGULAR_ICON_RADIUS = 9
    const HOVERED_ICON_RADIUS = 11

    export default {
        name: "PcrPlateChart",
        props: {
            activeDataPointId: String,
            analyses: Array,
            getAnalysisQuality: Function,
            plateIsOrientedHorizontally: Boolean,
            showEmptyPlates: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                plateDescriptions: [
                    {
                        rowNames: "ABCDEFGH",
                        columnNames: [ ...rangeGenerator(1, 13) ],
                        suitableRunIds: [ ...rangeGenerator(1, PLATE_CAPACITY + 1) ],
                    },
                    {
                        rowNames: "IJKLMNOP",
                        columnNames: [ ...rangeGenerator(1, 13) ],
                        suitableRunIds: [ ...rangeGenerator(PLATE_CAPACITY + 1, PLATE_CAPACITY * 2 + 1) ]
                    },
                ]
            }
        },
        computed: {
            dripOutDirection() {
                return this.plateIsOrientedHorizontally ? "row" : "col"
            },
            plates() {
                return this.plateDescriptions.map(plateDescription => {
                    const samples = dripOut(
                        this.dripOutDirection,
                        plateDescription,
                        fillMissingSamples(this.analyses, plateDescription.suitableRunIds)
                    )
                    return { plateDescription, samples }
                }).filter(
                    ({ samples }) => this.showEmptyPlates
                        ? true
                        : samples.some(it => it)
                )
            },
            regularIconRadius() {
                return REGULAR_ICON_RADIUS
            },
            hoveredIconRadius() {
                return HOVERED_ICON_RADIUS
            },
            regularControlSampleSquareSide() {
                return (2 * this.regularIconRadius) / Math.sqrt(2)
            },
            hoveredControlSampleSquareSide() {
                return (2 * this.hoveredIconRadius) / Math.sqrt(2)
            },
            regularControlSampleSquareLeftTopOffset() {
                return 12 - (this.regularControlSampleSquareSide / 2)
            },
            hoveredControlSampleSquareLeftTopOffset() {
                return 12 - (this.hoveredControlSampleSquareSide / 2)
            },
            hoveredToRegularIconRadiusProportion() {
                return HOVERED_ICON_RADIUS / REGULAR_ICON_RADIUS
            },
        },
        methods: {
            // Pcr plate consist of wells, i.e. distinct compartments for reagents
            getWellColor(analysisQuality) {
                switch (analysisQuality) {
                    case "red":
                        return "var(--red)"
                    case "yellow":
                        return "var(--yellow)"
                    case "grey":
                        return "#e7f3ff"
                    default:
                        throw new Error(`Unexpected analysis quality: ${analysisQuality}`)
                }
            },
            isHoveredSample(maybeAnalysis) {
                return maybeAnalysis
                    && (maybeAnalysis.name === this.activeDataPointId)
            },
            isControlSample(maybeAnalysis) {
                if (maybeAnalysis) {
                    return maybeAnalysis.isControlSample
                }
                return false
            }
        }
    }

    function dripOut(dripOutDirection, plateDescription, samples) {
        const plateColCount = plateDescription.columnNames.length
        const plateRowCount =  plateDescription.rowNames.length
        const drippedUsing = _dripOutDirection => plate => (plateRowIdx, plateColIdx) =>
            (_dripOutDirection === "row")
                ? plate[plateRowIdx * plateColCount + plateColIdx]
                : plate[plateColIdx * plateRowCount + plateRowIdx]
        const drippedUsingProvidedDirectionOn = drippedUsing(dripOutDirection)
        return ld.flatten(
            range(plateRowCount)
                .map(
                    plateRowIdx => range(plateColCount)
                        .map(plateColIdx =>
                            drippedUsingProvidedDirectionOn(samples)(plateRowIdx, plateColIdx))
                )
        )
    }

    /**
     * Fill skipped samples
     *
     * PCR plate chart is useful only when placement of samples resembles such in real life.
     * Some samples may not be uploaded for different reasons. Nevertheless they were present
     * in real pcr plates, so we intentionally fill wells with skipped samples.
     */
    function fillMissingSamples(samples, expectedRunIds) {
        return expectedRunIds.map(runId => samples.find(it => it.runId === runId))
    }
</script>

<style scoped>
    .pcr-plate-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .pcr-plate {
        margin: 2rem 2rem;
        display: grid;
        grid-template-columns: repeat(13, 2rem);
        grid-column-gap: .5rem;
    }

    .pcr-plate-well:hover {
        transform:
            scale(calc(var(--hovered-sample-icon-radius) / var(--sample-icon-radius)))
            rotate(45deg)
        ;
        transform-origin: center;
        stroke: black;
    }

    .tooltip-container {
        position: relative;
    }

    .pcr-plate-tooltip {
        display: none;
        position: absolute;
        z-index: var(--second-layer);
        bottom: 90%;
        left: 100%;
        font-size: 1.4rem;
        background-color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .2);
        padding: .5em .6em;
        border-radius: 4px;
        white-space: nowrap;
    }

    .tooltip-container:hover .pcr-plate-tooltip {
        display: block;
    }

    rect {
        transform-origin: center;
        transform-box: fill-box;
    }
</style>
