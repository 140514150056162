<template>
    <div class="modal">
        <h1 style="text-align: center; font-size: 3.4rem; margin-bottom: 3rem; font-weight: 600; position: sticky; top: 0">
            Genotype changes for {{ runName }} run
        </h1>
        <div class="analyses-lists">
            <!-- Conditional class is a hack for PAR-475 -->
            <div class="approved-analyses"
                 :class="{ 'grid-full-column' : !contaminatedAnalyses.length }"
                 v-if="assignedAnalyses.length">
                <h2 class="mb-2">{{ $t("assignedAnalyses") }}</h2>
                <div class="tabs-container" style="overflow: hidden;">
                    <div class="loci-tabs">
                        <div :class="{ active: activeLoci.length === 1 && activeLoci[0] === locus}"
                             :key="locus"
                             @click="activeLoci = [locus]"
                             v-for="locus in lociWithAnalysesWithGenotypeChanges"
                             class="loci-tabs--tab-header">
                            {{ locus }}
                        </div>
                        <div class="loci-tabs--tab-header"
                             :class="{ active: activeLoci === LOCI}"
                             @click="activeLoci = LOCI">Summary
                        </div>
                    </div>
                    <div class="loci-tabs--tab">
                        <div class="h-100 overflow-auto custom-scroll">
                            <table style="width: 100%; text-align: center; table-layout: fixed"
                                   v-if="analysesWithGenotypeChangesInActiveLoci.length">
                                <thead>
                                <tr>
                                    <td class="header-cell" style="width: 10%"></td>
                                    <td class="header-cell" style="width: 10%"></td>
                                    <td class="header-cell" style="width: 30%">
                                        <p-input class="md-outlined"
                                                 style="width: max-content; margin: 0 auto"
                                                 v-model="filters.oldGenotype"
                                                 :placeholder="$t('oldGenotype')">
                                        </p-input>
                                    </td>
                                    <td class="header-cell" style="width: 30%">
                                        <p-input class="md-outlined"
                                                 style="width: max-content; margin: 0 auto"
                                                 v-model="filters.newGenotype"
                                                 :placeholder="$t('newGenotype')">
                                        </p-input>
                                    </td>
                                    <td class="header-cell" style="width: 20%"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="filteredAnalysesWithGenotypeChangesInActiveLoci.length">
                                    <tr :key="analysis.id"
                                        v-for="analysis in filteredAnalysesWithGenotypeChangesInActiveLoci"
                                        class="row">
                                        <td class="row-cell">{{ analysis.name }}</td>
                                        <td class="row-cell">S{{ analysis.runId }}</td>
                                        <td class="row-cell">
                                            <template v-for="locus in activeLoci">
                                                <!-- v-if needed for summary tab to skip not modified genotypes -->
                                                <div :key="locus"
                                                     style="margin: 1.5rem 0;"
                                                     v-if="hasSavedGenotype(analysis, locus)">
                                                    <GenotypeComponent
                                                            :locusNameVisable="true"
                                                            :annotations="mergedAnnotations"
                                                            :genotype="genotypeFromAnalysisProperties(analysis.result.genotypes[locus])"
                                                            :locus-name="locus"/>
                                                </div>
                                            </template>
                                        </td>
                                        <td class="row-cell">
                                            <template v-for="locus in activeLoci">
                                                <!-- v-if needed for summary tab to skip not modified genotypes -->
                                                <div :key="locus"
                                                     style="margin: 1.5rem 0;"
                                                     v-if="hasSavedGenotype(analysis, locus)">
                                                    <GenotypeComponent
                                                            :locusNameVisable="true"
                                                            :annotations="mergedAnnotations"
                                                            :genotype="genotypeFromResolutionDraft(analysis.maybeResolutionDraft.genotypes[locus])"
                                                            :locus-name="locus"/>
                                                </div>
                                            </template>
                                        </td>
                                        <td class="row-cell" style="width: 6rem">
                                            <button class="md-btn-text unapprove-btn"
                                                    @click="unapproveAnalysis(analysis)">{{ $t("buttons.undo") }}
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-else class="row">
                                    <td colspan="5" class="row-cell">
                                        <i style="color: var(--grey-600)">{{ $t("noAnalysesMatchingFilter") }}</i>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div v-else class="flex"
                                 style="justify-content: center; font-size: 2rem; color: var(--grey-600); padding: 1rem">
                                {{ $t("emptyAnalysesListWithGenotypeChanges") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contaminated-analyses" v-if="contaminatedAnalyses.length">
                <h2 class="mb-2">{{ $t("contaminatedAnalyses") }}</h2>
                <table style="width: 80%; text-align: center;">
                    <tbody>
                    <tr :key="analysis.id"
                        v-for="analysis in contaminatedAnalyses"
                        class="row">
                        <td class="row-cell">{{ analysis.name }}</td>
                        <td class="row-cell">S{{ analysis.runId }}</td>
                        <td class="row-cell">
                            <button class="md-btn-text unapprove-btn"
                                    @click="unapproveAnalysis(analysis)">{{ $t("buttons.undo") }}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import GenotypeComponent from "@/components/genotype/genotype"
    import { supportActionNames, supportGetterNames } from "@/store/modules/support"
    import { Locales } from "@/i18n/main"
    import { mapGetters } from "vuex"
    import { Genotype, LOCI } from "@/genotype"
    import { hasSavedGenotype } from "@/utils/analysis"
    import { mergeAnnotations } from "@/utils/support"

    export default {
        name: "RunApprove",
        components: { GenotypeComponent },
        props: {
            runName: String,
        },
        data() {
            return {
                LOCI,
                activeLoci: [ "A" ],
                filters: {
                    newGenotype: null,
                    oldGenotype: null
                }
            }
        },
        computed: {
            analysesWithGenotypeChangesInActiveLoci() {
                return this.assignedAnalyses.filter(
                    analysis =>
                        this.activeLoci.some(locus => hasSavedGenotype(analysis, locus))
                )
            },
            filteredAnalysesWithGenotypeChangesInActiveLoci() {
                let filteredAnalyses = this.analysesWithGenotypeChangesInActiveLoci
                if (this.filters.newGenotype) {
                    filteredAnalyses = filteredAnalyses.filter(
                        analysis => this.activeLoci.some(
                            locus => analysis.maybeResolutionDraft?.genotypes?.[locus]?.includes(this.filters.newGenotype)
                        )
                    )
                }
                if (this.filters.oldGenotype) {
                    filteredAnalyses = filteredAnalyses.filter(
                        analysis => this.activeLoci.some(
                            locus => analysis.result.genotypes?.[locus]?.includes(this.filters.oldGenotype)
                        )
                    )
                }
                return filteredAnalyses
            },
            lociWithAnalysesWithGenotypeChanges() {
                return LOCI.filter(locus => this.assignedAnalyses
                    .some(analysis => hasSavedGenotype(analysis, locus)))
            },
            mergedAnnotations() {
                return this.analysesWithGenotypeChangesInActiveLoci.reduce(
                    (merged, analysis) =>
                        mergeAnnotations(
                            merged,
                            mergeAnnotations(
                                analysis.annotations ?? {},
                                analysis.maybeResolutionDraft.annotations ?? {}
                            )
                        ),
                    {}
                )
            },
            ...mapGetters({
                contaminatedAnalyses: supportGetterNames.contaminatedAnalyses,
                assignedAnalyses: supportGetterNames.assignedAnalyses
            })
        },
        methods: {
            unapproveAnalysis(analysis) {
                this.$store.dispatch(supportActionNames.markAnalysisAsUnapproved, analysis)
            },
            genotypeFromResolutionDraft: Genotype.fromGLString,
            genotypeFromAnalysisProperties: Genotype.fromGLString,
            hasSavedGenotype
        },
        mounted() {
            this.activeLoci = [ this.lociWithAnalysesWithGenotypeChanges[0] ]
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    emptyAnalysesListWithGenotypeChanges: "No analyses with genotype changes in current locus",
                    assignedAnalyses: "Analyses with changed genotypes",
                    contaminatedAnalyses: "Contaminated analyses",
                    failedAnalyses: "Failed analyses",
                    noAnalysesMatchingFilter: "No analyses matching filter"
                },
                [Locales.RU]: {
                    emptyAnalysesListWithGenotypeChanges: "Анализы с измененными генотипами в текущем локусе отсутствуют",
                    assignedAnalyses: "Анализы с измененными генотипами",
                    contaminatedAnalyses: "Контаминированные анализы",
                    failedAnalyses: "Неудачные анализы",
                    noAnalysesMatchingFilter: "Ни одного анализа подходящего под фильтр"
                }
            }
        }
    }
</script>

<style scoped>

    .modal {
        display: grid;
        grid-template-rows: fit-content(10%) minmax(70%, 1fr) 4rem;
        background-color: white;
        width: 80vw;
        height: 90vh;
        padding: 2rem 4rem;
    }

    .header-cell {
        font-weight: 600;
        padding: 1.5rem 1rem .5rem 1rem;
        border-bottom: 1px solid var(--grey-400);
        position: sticky;
        top: 0;
        background-color: white;
        z-index: var(--second-layer);
    }

    .row:nth-child(odd) {
        background-color: rgba(231, 243, 255, .2);
    }

    .row:hover {
        background-color: rgba(231, 243, 255, .4);
    }

    .row-cell {
        padding: .6rem 1rem;
        border-bottom: 1px solid #e7f3ff;
    }

    .tabs-container {
        display: grid;
        grid-template-rows: fit-content(10%) minmax(90%, 1fr);
        overflow: auto;
        flex-grow: 1;
    }

    .loci-tabs {
        display: flex;
        justify-content: space-around;
    }

    .loci-tabs--tab-header {
        padding: .5rem 1.5rem .3rem;
        border-bottom: 2px solid var(--grey-100);
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }

    .loci-tabs--tab-header:hover {
        background-color: hsl(225, 73%, 97%);
        /*border-color: royalblue;*/
    }

    .loci-tabs--tab-header.active {
        color: royalblue;
        border-bottom-style: solid;
        border-color: royalblue;
        border-bottom-width: 2px;
    }

    .loci-tabs--tab {
        overflow: auto;
        /*padding: 2rem 0;*/
    }

    .unapprove-btn {
        visibility: hidden;
        margin: 0 auto;
    }

    .row:hover .unapprove-btn {
        visibility: visible;
    }

    .analyses-lists {
        display: grid;
        grid-template-rows: minmax(60%, 3fr) minmax(30%, 2fr);
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr;
    }

    .approved-analyses {
        grid-column: 1 / -1;
        display: flex;
        flex-flow: column;
    }

    .contaminated-analyses {
        grid-column: 1 / 2;
    }
</style>
