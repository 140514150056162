export class SequencingPlatform {
    constructor(
        public name: string,
        public sequencingKits: SequencingKit[],
        public properties: {
            fileNameMask: string,
            runIdMask: string
        }
    ) {
    }
}

export interface SequencingKit {

    name: string

    properties: {
        tileCount: string,
        swathCount?: string
    }
}
