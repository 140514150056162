<template>
    <div class="content" id="start-upload-step">
        <div class="run-metadata">
            <p-input :value="platform.name"
                     class="run-medata-input md-outlined"
                     disabled
                     :placeholder="$t('platform')"
                     type="text"
            />
            <p-input :value="metadata.id.value"
                     class="run-medata-input md-outlined"
                     disabled
                     :placeholder="$t('runId')"
                     type="text"
            />
            <p-input :value="metadata.sequencingKit.value.name"
                     class="run-medata-input md-outlined"
                     disabled
                     :placeholder="$t('sequencingKit')"
            />
            <p-input :value="metadata.flows.value"
                     class="run-medata-input md-outlined"
                     disabled
                     :placeholder="$t('flows')"
                     type="text"
            />
            <p-input
                    :value="metadata.date.value.toISOString().split('T')[0]"
                    class="run-medata-input md-outlined"
                    disabled
                    :placeholder="$t('runDate')"
                    type="date"
            />
        </div>
        <div class="lot-list">
            <div :key="lot.name" class="lot" v-for="lot in lots">
                <div class="lot-name">{{ $t("lot") }} {{ lot.name }}</div>
                <div class="lot-details">
                    <div class="lot-samples-count"><span class="badge green">{{ lot.samples.length }}</span>
                        {{ $tc("lotSamplesCount", lot.samples.length) }}:
                        <!-- eslint-disable-next-line vue/require-v-for-key -->
                        <div class="badge badge-margin bg--royal-blue text-white"
                             v-for="group in collapseRunIds(lot.samples.map(it => it.runId))">{{ group }}
                        </div>
                    </div>
                    <div class="lot-samples-count" style="margin-top: 2rem">
                        {{ $t("controlSample") }}:
                        <span class="badge green" v-if="lot.controlSample">{{ lot.controlSample.runId  }}</span>
                        <span class="badge" style="background-color: darkorange" v-else>{{ $t("notSelected") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { collapseRunIds } from "@/views/data-upload/components/lot-step.vue"

    export default {
        name: "StartUploadStep",
        props: {
            metadata: Object,
            platform: Object,
            lots: Array
        },
        methods: {
            collapseRunIds
        },
    }
</script>

<style scoped>
    .content {
        padding: 3em;
        display: grid;
        height: 100%;
        grid-template-columns: minmax(30rem, 1fr) 1fr;
        grid-template-rows: 100%;
        grid-column-gap: 10rem;
        font-size: 1.8rem;
        justify-items: center;
        align-items: center;
    }

    .lot-list {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .lot {
        position: relative;
        width: 100%;
        height: fit-content;
        padding: 2.5em 2em;
        display: flex;
        flex-flow: column;
        border-radius: 4px;
        margin: 3rem 0;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);;
    }

    .lot-name {
        font-size: 2.5rem;
        padding: 0 1.5rem;
        margin-bottom: 2rem;
        margin-top: -.5rem;
        background-color: white;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .lot-details {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0 2rem;
        flex-flow: column;
    }

    .run-medata-input {
        margin: 3rem 0;
        min-width: 25rem;
        max-width: 50rem;
    }

    @media only screen and (max-width: 75em) {
        .content {
            grid-column-gap: 3rem;
        }
    }
</style>
