import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import router, { defaultRouteFor } from "@/router"
import { backgroundTasksGetterNames } from "@/store/modules/background-tasks"
import { acceptEula, fetchUser, login, logout } from "@/endpoints"
import { IllegalUser, User } from "@/types"

interface Authentication {
    user: User | null,
    legalisationInProgress: boolean
}

function initialState(): Authentication {
    return {
        legalisationInProgress: false,
        user: null
    }
}

const mutations: MutationTree<Authentication> = {
    setUser(state, user: User) {
        state.user = user
    },
    setUserEmail(state, email: string) {
        /*
         * FIXME: Well this check is a drawback,
         *  as we declare this mutation inside this module,
         *  where user can be null ("non-authenticated" application state).
         *  In fact, this mutation should be available only for an "authenticated" application state.
         */
        if (state.user) {
            state.user = { ...state.user, maybeEmail: email }
        }
    },
    subscribeForEmailNotification(state, subscriptionName: string) {
        if (state.user) {
            /*
             * FIXME: Well this check is a drawback,
             *  as we declare this mutation inside this module,
             *  where user can be null ("non-authenticated" application state).
             *  In fact, this mutation should be available only for an "authenticated" application state.
             */
            state.user = {
                ...state.user,
                subscriptions: {
                    ...state.user.subscriptions,
                    [subscriptionName]: true,
                },
            }
        }
    },
    unsubscribeFromEmailNotification(state, subscriptionName: string) {
        if (state.user) {
            /*
             * FIXME: Well this check is a drawback,
             *  as we declare this mutation inside this module,
             *  where user can be null ("non-authenticated" application state).
             *  In fact, this mutation should be available only for an "authenticated" application state.
             */
            state.user = {
                ...state.user,
                subscriptions: {
                    ...state.user.subscriptions,
                    [subscriptionName]: false,
                },
            }
        }
    },
    startLegalisation(state) {
        state.legalisationInProgress = true
    },
    stopLegalisation(state) {
        state.legalisationInProgress = false
    },
    logoutOnTheClientSide(state) {
        state.user = null
    },
    resetState(state) {
        console.debug("Auth state reset")
        Object.assign(state, initialState())
    }
}

const actions: ActionTree<Authentication, any> = {
    async confirmLegalisation({ dispatch, commit }, acceptedEulaVersion: number) {
        await acceptEula(acceptedEulaVersion)
        return getAuthenticationDataUsingCookies()
            .then(user => dispatch("onSuccessfulAuthentication", { user }))
            .then(user => goToDefaultRoute(user))
            .then(() => commit("stopLegalisation"))
    },
    abortLegalisation({ commit, dispatch }) {
        commit("stopLegalisation")
        dispatch("logout")
    },
    login({ dispatch }, { name, password }) {

        return login(name, password)
            .then(() => {
                console.debug("Authentication succeeded")
                return getAuthenticationDataUsingCookies()
            })
            .then(user => dispatch("onSuccessfulAuthentication", { user }))
            .then(user => goToDefaultRoute(user))
    },
    async onSuccessfulAuthentication({ commit, dispatch }, { user }) {
        commit("setUser", user)
        await dispatch("fetchAccountData")
        return user
    },
    logout({ commit, rootGetters }) {
        const canLogout = rootGetters[backgroundTasksGetterNames.tasksCount] === 0
        if (canLogout) {
            return logout()
                .then(() => commit("logoutOnTheClientSide"))
                .then(() => {
                    // eslint-disable-next-line promise/always-return
                    if (router.currentRoute.name !== "login") {
                        router.push({ name: "login" })
                    }
                })
                /*
                 * `resetState` is called after redirect to avoid some side effects like
                 * rendering errors, cause we are not supposed to see pages other
                 * than login page in initial store state.
                 */
                .then(() => commit("resetState"))
        } else {
            return Promise.reject("Cannot logout due to background tasks")
        }
    },
    /**
     * Performs a request for current user.
     * Must be used at the start of the application (i.e. when you are not sure
     * whether authentication is set).
     * Needed to restore authentication data after page reload in the browser using cookies.
     *
     * @returns {Promise}
     */
    restoreAuthenticationUsingCookies({ dispatch }): Promise<void> {
        console.debug("Trying to restore authentication using cookies")
        return getAuthenticationDataUsingCookies()
            .then(user => dispatch("onSuccessfulAuthentication", { user }))
    }
}

const getters: GetterTree<Authentication, any> = {
    isAuthenticated: state => Boolean(state.user),
    legalisationInProgress: state => Boolean(state.legalisationInProgress),
    user: state => state.user as User
}

export const authentication: Module<Authentication, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
}


function getAuthenticationDataUsingCookies(): Promise<User | IllegalUser | null> {
    return fetchUser()
}

function goToDefaultRoute(user: User) {
    router.push({ name: defaultRouteFor(user) })
}
