<template>
    <!--  TODO: Why do I need this outer div?  -->
    <div>
        <div id="analyses-view"
             tabindex="-1"
        >
            <p-spinner v-if="loading"
                       class="abs-center h4"/>
            <div class="content" v-else>
                <div id="run-details">
                    <div class="flex">
                        <p-with-tooltip position="top-right">
                            <svg style="width: 1.4rem;" version="1.1"
                                 id="run-details--average-quality-mark"
                                 viewBox="0 0 120 120"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle :fill="runAverageQuality" cx="60" cy="60" r="50"/>
                            </svg>
                            <template #tooltip>
                                <p class="tooltip-black">{{ $t("analysisMetrics.goodMeanReadsQuality") }}</p>
                            </template>
                        </p-with-tooltip>
                        <h2 class="run-details--run-id h2">{{ run.name }}</h2>
                    </div>
                    <div class="run-details--run-date">{{ $d(run.date) }}</div>
                    <div class="run-details--reagent-kit-details">
                        <p>{{ run.properties.sequencingKit.name }}</p>
                        <p>{{ $t("flows", [run.properties.flows]) }}</p>
                    </div>
                    <div class="run-details--samples-number">
                        {{ $tc("samples", analyses.length) }}
                    </div>
                    <div class="run-details--lots">
                        <RunLotBadge :control-sample-status="qcStatus"
                                  :key="lot"
                                  :lot-name="lot"
                                  class="mr-1"
                                  v-for="(qcStatus, lot) in run.properties['controlSampleQc']"
                                  @mouseenter.native="onAnalysisHoverStart(controlSample)"
                                  @mouseleave.native="onAnalysisHoverEnd()"
                                />
                    </div>
                </div>

                <div id="search-analysis">
                    <p-input
                        :placeholder="$t('sampleName')"
                        class="md-outlined"
                        v-model="analysisSearch"
                        @input="searchAnalysis(analysisSearch)"
                    />
                </div>

                <div id="charts-container">
                    <div>
                        <p-icon-button id="prev-chart-btn"
                                    @click="prev" icon-name="arrow_left" v-if="state > 1"/>
                    </div>
                    <!-- keys are vital, they force vue to rerender graphics -->
                    <div
                        :key="1"
                        v-if="state === 1 && scatterData"
                        class="scatter-chart-container"
                    >
                        <ChartContainer
                                v-bind="scatterChart"
                                id="scatter-chart"
                                class="relative h-100"
                        />
                        <div v-if="hoveredAnalysisHasTooLittleReads"
                             class="chart-transparent-cover"
                        >{{ $t("tooLittleReadsToBeAnalysed") }}</div>
                    </div>

                    <div :key="2"
                         v-if="state === 2"
                         id="pcr-chart-container"
                         class="h-100 overflow-auto custom-scroll"
                    >
                        <PcrPlateChart :active-data-point-id="hoveredAnalysisName"
                                       :analyses="analysesOrderedByIdInRun"
                                       :get-analysis-quality="getAnalysisQuality"
                                       :plate-is-oriented-horizontally="plateIsOrientedHorizontally"/>
                        <div>
                            <div class="mb-1" style="text-align: center; font-style: italic;">{{
                                $t("sampleArrangementOnPcrPlate") }}
                            </div>
                            <div class="orientation-toggler">
                                <button @click="plateIsOrientedHorizontally = true"
                                        :class="{ 'active-toggler-option': plateIsOrientedHorizontally }"
                                        class="orientation-toggler-option md-btn-outlined">{{ $t("row") }}
                                </button>
                                <button @click="plateIsOrientedHorizontally = false"
                                        :class="{ 'active-toggler-option': !plateIsOrientedHorizontally }"
                                        class="orientation-toggler-option md-btn-outlined">{{ $t("col") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p-icon-button id="next-chart-btn" @click="next" icon-name="arrow_right" v-if="state < 2"/>
                    </div>
                </div>
                <LabIssueAnalyses :analyses="labIssueAnalyses"
                                  v-if="labIssueAnalyses.length !== 0"
                                  class="lab-issue-analyses custom-scroll"
                                  @analysis-hover-end="onAnalysisHoverEnd"
                                  @analysis-hover-start="onAnalysisHoverStart"
                ></LabIssueAnalyses>
                <div class="lab-issue-analyses lab-issue-analyses--placeholder"
                     v-else>
                    <p-icon
                        icon-name="thumb-up"
                        size="7em" style="color: #e7f3ff"
                    />
                    <div class="mh-1" style="margin-top: 1em">{{ $t("noProblemSamples") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ChartContainer } from "@/components/chart-container"
    import { drawChart } from "@/charts/analysis-general-metrics"
    import { sum } from "@/extensions/array-extensions"
    import {
        analysisHasTooLittleReadsToBeAnalysed,
        getLabMetricsQuality,
        hasLabIssues,
        worstProblemsQuality,
    } from "@/utils/analysis"
    import ld from "lodash"
    import { Locales } from "@/i18n/main"
    import { configuredPipelinesActionNames } from "@/store/modules/configured-pipelines"
    import RunLotBadge from "@/components/run-lot-badge"
    import { store } from "@/store/store"
    import { supportEndpoints } from "@/endpoints"
    import { runsActionNames } from "@/store/modules/runs"

    import PcrPlateChart from "./components/pcr-plate-chart"
    import LabIssueAnalyses from "./components/lab-issue-analyses"

    export default {
        name: "Run",
        components: {
            RunLotBadge,
            ChartContainer,
            LabIssueAnalyses,
            PcrPlateChart,
        },
        props: {
            hasSupportRunAnalyses: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        async created() {
            const runId = this.$router.currentRoute.params.id
            const isSupportRun = this.hasSupportRunAnalyses

            const fetchRun = async () => {
                return this.$router.currentRoute.params.run
                    ? Promise.resolve(this.$router.currentRoute.params.run)
                    : (isSupportRun ? supportEndpoints.sequencingRun(runId) : store.dispatch(runsActionNames.fetchRun, { id: runId }))
            }

            const fetchAnalyses = async () => {
                return isSupportRun
                    ? supportEndpoints.analyses({ id: runId })
                    : store.dispatch(runsActionNames.fetchRunAnalyses, { id: runId })
            }

            const [ analyses, run ] = await Promise.all([ fetchAnalyses(), fetchRun() ])

            const { qualityControlThresholds } = await store.dispatch(
                configuredPipelinesActionNames.fetchPipelineData,
                analyses[0].configuredPipelineName
            )

            this.run = run
            this.analyses = analyses
            this.controlSample = this.analyses.find(it => it.isControlSample)
            this.generalMetricsThresholds = qualityControlThresholds.generalMetrics
            this.loading = false
        },
        data() {
            return {
                loading: true,
                run: null,
                analyses: [],
                controlSample: null,
                state: 1,
                hoveredAnalysisName: null,
                plateIsOrientedHorizontally: false, // = plateIsOrientedVertically
                generalMetricsThresholds: null,
                analysisSearch: null,
            }
        },
        computed: {
            analysesOrderedByIdInRun() {
                return ld.sortBy(this.analyses, "runId")
            },
            runAverageQuality() {
                // Implemented as described in VH-298. Implemented here cause, where else it should be?
                // It's used only here. Lonely shitty formal metric no one cares for.
                return worstProblemsQuality(this.analyses
                    .filter(it => it.result.problems?.generalMetrics?.meanReadsQuality)
                    .map(it => it.result.problems.generalMetrics.meanReadsQuality))
            },
            labIssueAnalyses() {
                return this.analysesOrderedByIdInRun.filter(element => hasLabIssues(element))
            },
            trafficLights() {
                return this.analyses.reduce(
                    (prev, cur) => {
                        prev[cur.result.quality] += 1
                        return prev
                    },
                    {
                        green: 0,
                        yellow: 0,
                        red: 0
                    }
                )
            },
            scatterData() {
                return this.analyses.length !== 0 && this.generalMetricsThresholds
                    ? {
                        data: this.analyses
                            .filter(it => !analysisHasTooLittleReadsToBeAnalysed(it))
                            .map(
                                it => ({
                                    quality: this.getAnalysisQuality(it),
                                    value: [
                                        sum(Object.values(it.result.qualityMetrics.readsDistribution)),
                                        it.result.qualityMetrics.insertSize,
                                    ],
                                    name: it.name,
                                    isControlSample: it.isControlSample
                                })
                            ),
                        thresholds: this.generalMetricsThresholds
                    }
                    : null
            },
            scatterChart() {
                return this.generalMetricsThresholds
                    ? {
                        /*
                         P - performance, activeDataPointId can change very often, we don't to need to
                         recalculate `this.scatterData.data` at all
                        */
                        data: { ...this.scatterData, activeDataPointId: this.hoveredAnalysisName },
                        chartConstructorFn: (data) => drawChart(data, "#scatter-chart", this.$i18n)
                    }
                    : null
            },
            hoveredAnalysisHasTooLittleReads() {
                return this.analyses && this.hoveredAnalysisName
                    ? analysisHasTooLittleReadsToBeAnalysed(this.analyses.find(it => it.name === this.hoveredAnalysisName))
                    : undefined
            }
        },
        methods: {
            onAnalysisHoverStart(analysis) {
                if(analysis){
                    this.hoveredAnalysisName = analysis.name
                }
            },
            onAnalysisHoverEnd() {
                this.hoveredAnalysisName = null
            },
            searchAnalysis(analysisName) {
                if(this.analysesOrderedByIdInRun.map(a => a.name).includes(analysisName)) {
                    this.hoveredAnalysisName = analysisName
                } else {
                    this.hoveredAnalysisName = null
                }
            },
            next() {
                this.state += 1
            },
            prev() {
                this.state -= 1
            },
            getAnalysisQuality(it) {
                return getLabMetricsQuality(it)
            },
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    samples: "No samples | {n} sample | {n} samples",
                    sampleArrangementOnPcrPlate: "Arrange samples on a plate by",
                    row: "row",
                    col: "col",
                    flows: "{0} flows",
                    noProblemSamples: "No problem samples",
                    sampleName: "Enter sample name",
                },
                [Locales.RU]: {
                    samples: "Нет образцов | {n} образец | {n} образца | {n} образцов",
                    sampleArrangementOnPcrPlate: "Упорядочить образцы на плашке по",
                    row: "ряд",
                    col: "колонка",
                    flows: "{0} циклов",
                    noProblemSamples: "Проблемные образцы отсутствуют",
                    sampleName: "Введите имя образца",
                }
            }
        }
    }
</script>

<style scoped>

    #analyses-view {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 2rem;
    }

    .content {
        grid-area: content;
        display: grid;
        grid-template-areas: "details details bad" "searchAnalysis searchAnalysis bad" "readsChart readsChart bad";
        grid-template-rows: fit-content(15%) fit-content(15%) 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        width: 100%;
        height: 100%;
    }

    #search-analysis {
        position: relative;
        grid-area: searchAnalysis;
        padding: 0 20rem;
    }

    #charts-container {
        position: relative;
        grid-area: readsChart;
        width: 100%;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 10em calc(100% - 20em) 10em;
        align-items: center;
        justify-content: center;
        --sample-icon-radius: 9;
        --hovered-sample-icon-radius: 11;
    }

    #run-details {
        grid-area: details;
        padding: 3rem;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .run-details--run-id,
    .run-details--reagent-kit-details,
    .run-details--run-date,
    .run-details--samples-number {
        margin: 0 2rem;
        text-align: center;
    }

    .run-details--run-id {
        margin-left: 1rem;
        font-weight: 600;
    }

    .run-details--reagent-kit-details,
    .run-details--run-date,
    .run-details--samples-number {
        color: var(--grey-800)
    }

    .run-details--lots {
        display: flex;
        align-items: center;
    }

    .lab-issue-analyses {
        grid-area: bad;
        overflow: auto;
        width: 100%;
        height: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .2);
        border-radius: 4px;
    }

    .lab-issue-analyses--placeholder {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .scatter-chart-container {
        height: 70%;
        width: 100%;
        font-size: 1.6rem;
        justify-self: center;
        /* Tooltip in chart relies on this, don't remove */
        position: relative;
    }

    .orientation-toggler {
        text-align: center;
        font-size: 1.2rem;
        width: fit-content;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .orientation-toggler-option {
        border: 1px solid var(--grey-400);
    }

    .orientation-toggler-option:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .orientation-toggler-option:last-child {
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .active-toggler-option {
        background-color: #e0e0e0;
    }

    @media screen and (max-width: 1100px) {

        #analyses-view {
            padding: 0;
        }

        .content {
            grid-template-areas: "details bad" "readsChart readsChart";
            grid-template-rows: 50% 50%;
            grid-template-columns: 50% 50%;
            grid-column-gap: 0;
            grid-row-gap: 0;
        }

        #run-details {
            flex-flow: column;
            justify-content: space-around;
        }

        #charts-container {
            font-size: 1.2rem;
            border: none;
            grid-template-columns: 8em calc(100% - 16em) 8em;
        }

        .scatter-chart-container {
            /* For some reason 100% cause overflow though I found no reason for it */
            height: 95%;
            width: 100%;
            max-width: 70rem;
        }

        .lab-issue-analyses {
            margin: 2rem 2rem 0 0;
            height: auto;
            width: auto;
        }
    }

    .chart-transparent-cover {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 5px);
        height: calc(100% + 5px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6));
        font-weight: 600;
        font-size: 2rem;
        color: var(--grey-700);
    }

    @supports (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) {

        .chart-transparent-cover {
            -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
        }
    }
</style>
