import Vue from "vue"
import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { Genotype, LOCI, Locus } from "@/genotype"
import {
    Analysis,
    FunctionalAnnotations,
    Genotypes,
    getGenotype,
    getSavedGenotype,
    getSavedNewAllele,
    hasSavedGenotype,
    hasSavedNewAllele,
    ResolutionDraftNewAlleles,
    ResolutionDraft,
    SupportResolution,
} from "@/utils/analysis"
import { createPageableStore } from "@/store/modules/pageable-store"
import { Run } from "@/store/modules/runs"
import { fillFalsyValues, mapValues } from "@/extensions/object-extensions"
import { annotate, HisatResults, HlaHdResults, KouramiResults, ToolResults } from "@/utils/support"
import { supportEndpoints } from "@/endpoints"
import _ from "lodash"


interface Support {
    analyses: Array<Analysis>
    tempGenotypes: {
        [analysisId: string]: Genotypes
    },
    tempNewAllele: {
        [analysisId: string]: ResolutionDraftNewAlleles
    },
    toolResults: {
        [analysisId: string]: {
            "hla-hd": object,
            "kourami": object,
            "hisat": object
        }
    },
    manuallyCreatedAlleles: {
        [analysisId: string]: {
            [locus in Locus]: Array<string>
        }
    },
    activeAnalysisIndex: number | null,
    activeRun: Run | null
}

type SupportSequencingRunsFilter = Partial<{
    ownedBy: string,
    name: string,
    from: Date,
    to: Date,
    orderByCreatedAt: SortOrder,
}>

const { actionNames, getterNames, module } = createPageableStore<Run, SupportSequencingRunsFilter>(
    "support/sequencingRuns",
    {}
)

function initialState(): Support {
    return {
        analyses: [],
        tempGenotypes: {},
        tempNewAllele: {},
        toolResults: {},
        manuallyCreatedAlleles: {},
        activeAnalysisIndex: null,
        activeRun: null
    }
}

type SortOrder = "ASC" | "DESC"

export const supportMutationNames = {
    initializeTempGenotypes: "",
    initializeTempNewAllele: "",
    setToolResults: "",
    setAnalyses: "",
    addManuallyCreatedAllele: "",
    addManuallyNewAllele: "",
    toggleAlleleInNewGenotype: "",
    toggleNewAllele: "",
    clearNewGenotype: "",
    resetNewGenotype: "",
    addPartToNewGenotype: "",
    changeActiveAnalysisIndexTo: "",
    setNewGenotype: "",
    getNewAlleleTemporaryConsensusFastaLink: "",
    setAnalysis: ""
}

export const supportActionNames = {
    fetchToolResults: "",
    saveEditedGenotype: "",
    markAnalysisAsAssigned: "",
    markAnalysisAsUnapproved: "",
    markAnalysisAsContaminated: "",
    approveAnalysesResolution: "",
    chooseRunForSupport: "",
    changeActiveAnalysisTo: "",
    updateAnalysis: "",
    ...actionNames
}

export const supportGetterNames = {
    analysesForSupport: "",
    resolvedAnalyses: "",
    unresolvedAnalyses: "",
    contaminatedAnalyses: "",
    assignedAnalyses: "",
    activeRun: "",
    activeAnalysis: "",
    ...getterNames
}

const fillNameValues = (o: {}) => fillFalsyValues(key => `support/${key}`, o)
fillNameValues(supportMutationNames)
fillNameValues(supportActionNames)
fillNameValues(supportGetterNames)


const mutations: MutationTree<Support> = {
    resetState(state) {
        Object.assign(state, initialState())
    },
    [supportMutationNames.initializeTempGenotypes](state, payload: { analyses: Array<Analysis>, loci: Array<Locus> }) {
        const { analyses, loci } = payload
        state.tempGenotypes = Object.fromEntries(analyses.map(analysis => [
            analysis.id,
            <Genotypes>Object.fromEntries(loci.map(locus => {
                return [ locus,
                    hasSavedGenotype(analysis, locus)
                        ? getSavedGenotype(analysis, locus)
                        : getGenotype(analysis, locus) ]
            })),
        ]))
    },
    [supportMutationNames.initializeTempNewAllele](state, payload: { analyses: Array<Analysis>, loci: Array<Locus> }) {
        const { analyses, loci } = payload

        state.tempNewAllele = Object.fromEntries(analyses.map(analysis => [
            analysis.id,
            <ResolutionDraftNewAlleles>{
                manuallyCreatedAlleles: Object.fromEntries(loci.map(locus => {
                    if(!hasSavedNewAllele(analysis, locus)) return []
                    return  [ locus,
                        hasSavedNewAllele(analysis, locus)
                            ? _.cloneDeep(getSavedNewAllele(analysis, locus)?.manuallyCreatedAlleles[locus])
                            : [] ]
                }).filter(it => it.length !== 0)),
                sampleAlleles: Object.fromEntries(loci.map(locus => {
                    if(!hasSavedNewAllele(analysis, locus)) return []
                    return [ locus, hasSavedNewAllele(analysis, locus)
                        ? _.cloneDeep(getSavedNewAllele(analysis, locus)?.sampleAlleles[locus])
                        : {} ]
                }).filter(it => it.length !== 0)),
            },
        ]))

    },
    [supportMutationNames.setToolResults](state, { analysis, toolResults }) {
        Vue.set(state.toolResults, analysis.id, toolResults)
    },
    [supportMutationNames.setAnalyses](state, analyses: Array<Analysis>) {
        state.analyses = analyses
    },
    [supportMutationNames.addManuallyCreatedAllele](state, payload: { allele: string, analysisId: string, locus: Locus }) {
        const { allele, analysisId, locus } = payload
        if (!state.manuallyCreatedAlleles[analysisId]) {
            Vue.set(
                state.manuallyCreatedAlleles,
                analysisId,
                Object.fromEntries(LOCI.map(it => [ it, [] ]))
            )
        }
        Vue.set(
            state.manuallyCreatedAlleles[analysisId],
            locus,
            [ ...state.manuallyCreatedAlleles[analysisId][locus], allele ]
        )
    },
    [supportMutationNames.toggleAlleleInNewGenotype](state, payload: { analysis: Analysis, locus: Locus, allele: string, genotypePartIndex: number, alleleIndex: number }) {
        const { allele, alleleIndex, analysis, genotypePartIndex, locus } = payload
        state.tempGenotypes[analysis.id] = {
            ...state.tempGenotypes[analysis.id],
            [locus]: state.tempGenotypes[analysis.id][<Locus>locus].toggleAlleleInNewGenotype(allele, genotypePartIndex, alleleIndex)
        }
    },
    [supportMutationNames.toggleNewAllele](state, payload: { analysis: Analysis, locus: Locus, allele: string, genotypePartIndex: number, alleleIndex: number, newAllele: ResolutionDraftNewAlleles }) {
        const { alleleIndex, analysis, genotypePartIndex, locus, newAllele } = payload
        /*
        *   FIXME: [@bbatanov 20.02.21] Сan be simplified
        */
        if(!state.tempNewAllele[analysis.id].manuallyCreatedAlleles[locus]) {
            state.tempNewAllele[analysis.id].manuallyCreatedAlleles = {
                ...state.tempNewAllele[analysis.id].manuallyCreatedAlleles,
                [locus]: []
            }
        }

        const indexNewAllele = state.tempNewAllele[analysis.id]?.sampleAlleles?.[locus]?.[genotypePartIndex]?.[alleleIndex] ?? undefined

        if(indexNewAllele !== undefined) {
            state.tempNewAllele[analysis.id].manuallyCreatedAlleles[locus].splice(indexNewAllele, 1)
        }

        state.tempNewAllele[analysis.id].manuallyCreatedAlleles[locus].push(newAllele.manuallyCreatedAlleles[locus][0])

        if (!state.tempNewAllele[analysis.id].sampleAlleles[locus]) {
            state.tempNewAllele[analysis.id].sampleAlleles = {
                ...state.tempNewAllele[analysis.id].sampleAlleles,
                [locus]: {}
            }
        }

        if (!state.tempNewAllele[analysis.id].sampleAlleles[locus][genotypePartIndex]) {
            state.tempNewAllele[analysis.id].sampleAlleles[locus] = {
                ...state.tempNewAllele[analysis.id].sampleAlleles[locus],
                [genotypePartIndex]: {}
            }
        }
        state.tempNewAllele[analysis.id].sampleAlleles[locus][genotypePartIndex][alleleIndex] =  state.tempNewAllele[analysis.id].manuallyCreatedAlleles[locus]?.length - 1
    },
    [supportMutationNames.clearNewGenotype](state, payload: { analysis: Analysis, locus: Locus }) {
        const { locus, analysis } = payload
        state.tempGenotypes[analysis.id] = {
            ...state.tempGenotypes[analysis.id],
            [locus]: Genotype.undefinedGenotype()
        }

        delete state.tempNewAllele[analysis.id]?.manuallyCreatedAlleles[locus]
        delete state.tempNewAllele[analysis.id]?.sampleAlleles[locus]
    },
    [supportMutationNames.resetNewGenotype](state, payload: { analysis: Analysis, locus: Locus }) {
        const { locus, analysis } = payload
        state.tempGenotypes[analysis.id] = {
            ...state.tempGenotypes[analysis.id],
            // Always reset to genotype assigned by pipeline. Decision was done in VH-261
            [locus]: getGenotype(analysis, locus)
        }

        delete state.tempNewAllele[analysis.id]?.manuallyCreatedAlleles[locus]
        delete state.tempNewAllele[analysis.id]?.sampleAlleles[locus]

    },
    [supportMutationNames.addPartToNewGenotype](state, payload: { analysis: Analysis, locus: Locus }) {
        const { id } = payload.analysis
        const { locus } = payload
        state.tempGenotypes[id] = {
            ...state.tempGenotypes[id],
            [locus]: state.tempGenotypes[id][locus].addNewGenotypePart()
        }
    },
    [supportMutationNames.setNewGenotype](state, payload: { analysis: Analysis, locus: Locus, newGenotype: Genotype }) {
        const { id } = payload.analysis
        const { locus } = payload
        state.tempGenotypes[id] = {
            ...state.tempGenotypes[id],
            [locus]: payload.newGenotype
        }
    },
    [supportMutationNames.changeActiveAnalysisIndexTo](state, index) {
        state.activeAnalysisIndex = index
    },
    [supportMutationNames.getNewAlleleTemporaryConsensusFastaLink](state, payload: { analysis: Analysis, locus: Locus, genotypePartIndex: number, alleleIndex: number } ) {
        const { analysis, alleleIndex, genotypePartIndex, locus } = payload
        return supportEndpoints.getNewAlleleTemporaryConsensusFastaLink(
            state.activeRun!,
            state.analyses.find(it => it.id === analysis.id)!,
            locus!,
            genotypePartIndex!,
            alleleIndex!
        )
    },
    [supportMutationNames.setAnalysis](state, analysis: Analysis) {
        state.analyses = state.analyses.map(it => {
            if(it.id === analysis.id) {
                return analysis
            }
            return it
        })
    },
    _saveEditedGenotype(state, payload: { analysis: Analysis, locus: Locus, annotations: FunctionalAnnotations }) {
        const { analysis, locus, annotations } = payload
        if (!analysis.maybeResolutionDraft) {
            analysis.maybeResolutionDraft = {
                resolution: null,
                genotypes: {},
                annotations: null
            }
        }
        state.analyses = state.analyses.map(it => {
            if (it.id === analysis.id) {
                if (!analysis.maybeResolutionDraft) {
                    throw "Analysis must have maybeResolutionDraft to be marked as something"
                }
                return {
                    ...it,
                    maybeResolutionDraft: {
                        ...analysis.maybeResolutionDraft,
                        genotypes: {
                            ...analysis.maybeResolutionDraft!.genotypes,
                            /*
                             I don't like this hack, why we can't store string "ND+ND" for consistency with
                             other genotypes? But this is the pipeline results specification for now.
                             So I must use it to be compliant with backend.
                            */
                            [locus]: state.tempGenotypes[analysis.id][locus].isUndefined
                                ? null
                                : state.tempGenotypes[analysis.id][locus].toGLString()
                        },
                        newAlleles: {
                            manuallyCreatedAlleles: _.cloneDeep(state.tempNewAllele[analysis.id].manuallyCreatedAlleles),
                            sampleAlleles: _.cloneDeep(state.tempNewAllele[analysis.id].sampleAlleles)
                        },
                        annotations: annotate(state.tempGenotypes[analysis.id], annotations)
                    }
                }
            }
            return it
        })
    },
    _markAnalysisAs(state, payload: { analysis: Analysis, resolution: SupportResolution }) {
        const { resolution, analysis } = payload
        state.analyses = state.analyses.map(it => {
            if (it.id === analysis.id) {
                if (!analysis.maybeResolutionDraft) {
                    throw "Analysis must have maybeResolutionDraft when to be marked as something"
                }
                const resolutionDraft: ResolutionDraft | null = (resolution === "contaminated")
                    ? {
                        ..._.omit(analysis.maybeResolutionDraft, [ "newAlleles" ]),
                        genotypes: Object.fromEntries(LOCI.map(locus => [ locus, null ]))
                    }
                    : analysis.maybeResolutionDraft

                it.maybeResolutionDraft = { ...resolutionDraft, resolution }
            }
            return it
        })
    },
    _setActiveRun(state, run: Run) {
        state.activeRun = run
    }
}

const actions: ActionTree<Support, any> = {
    async [supportActionNames.fetchToolResults]({ state, commit }, analyses: Array<Analysis>) {
        analyses.map(async analysis => {
            const rawToolResults: RawToolResults = await supportEndpoints.toolResults(state.activeRun!, analysis) as RawToolResults
            const toolResults = parseToolResults(rawToolResults)
            commit(supportMutationNames.setToolResults, { analysis, toolResults })
        })
    },
    async [supportActionNames.chooseRunForSupport]({ commit, getters, dispatch }, { id }) {
        /*
         TODO: [@aslepchenkov 14.02.2020] I have a feeling that this should not be checked here.
          Maybe in component hooks? One in router? But refactoring should be global. This
          quick fix will be enough for now
        */
        const weCameHereAfterPageReload = getters[supportGetterNames.resourceList].length === 0
        let run = null
        if (weCameHereAfterPageReload) {
            run = await supportEndpoints.sequencingRun(id)
            const weHaveNothingToSupport = run.properties.statusAggs?.IN_SUPPORT === 0
            if (weHaveNothingToSupport) {
                return Promise.reject()
            }
        } else {
            run = (<Array<Run>>getters[supportGetterNames.resourceList]).find(it => it.id === id)
            if (!run) {
                throw new Error(`Run with id ${id} not found`)
            }
        }
        commit(
            "_setActiveRun",
            run
        )
        return dispatch("_fetchAnalysesForSupport")
    },
    [supportActionNames.saveEditedGenotype]({ commit, rootState, dispatch }, payload: { analysis: Analysis, locus: Locus }) {
        const { analysis } = payload
        const annotations = rootState.configuredPipelines.pipelines[analysis.configuredPipelineName].functionalAnnotations
        commit("_saveEditedGenotype", { ...payload, annotations })
        return dispatch("_saveDraftResolution", analysis)
    },
    [supportActionNames.markAnalysisAsAssigned]({ dispatch, commit }, analysis: Analysis) {
        dispatch("_adjustActiveAnalysisIndexBeforeRemoval")
        commit("_markAnalysisAs", { analysis, resolution: "assigned" })
        return dispatch("_saveDraftResolution", analysis)
    },
    [supportActionNames.markAnalysisAsUnapproved]({ commit, getters, dispatch }, analysis: Analysis) {
        const unresolvedAnalyses = getters[supportGetterNames.unresolvedAnalyses]
        if (unresolvedAnalyses.length === 0) {
            commit(supportMutationNames.changeActiveAnalysisIndexTo, 0)
        }
        commit("_markAnalysisAs", { analysis, resolution: null })
        return dispatch("_saveDraftResolution", analysis)
    },
    [supportActionNames.markAnalysisAsContaminated]({ dispatch, commit }, analysis: Analysis) {
        dispatch("_adjustActiveAnalysisIndexBeforeRemoval")
        commit("_markAnalysisAs", { analysis, resolution: "contaminated" })
        return dispatch("_saveDraftResolution", analysis)
    },
    [supportActionNames.updateAnalysis]({ state, commit }, analysis: Analysis) {
        return supportEndpoints.analysis(state.activeRun!, analysis)
            .then((_analysis) => {
                commit(supportMutationNames.setAnalysis, _analysis)
                return _analysis
            })
    },
    _adjustActiveAnalysisIndexBeforeRemoval({ state, commit, getters }) {
        const unresolvedAnalyses = getters[supportGetterNames.unresolvedAnalyses]
        const lastAnalysisWillBeRemoved = unresolvedAnalyses.length === 1
        if (lastAnalysisWillBeRemoved) {
            commit(supportMutationNames.changeActiveAnalysisIndexTo, null)
        } else {
            if (state.activeAnalysisIndex === null) {
                console.error("This state should be impossible: activeAnalysisIndex can't be null when analysis is marked, cause active analysis is marked")
                throw new Error("This state should be impossible: activeAnalysisIndex can't be null when analysis is marked, cause active analysis is marked")
            }
            commit(
                supportMutationNames.changeActiveAnalysisIndexTo,
                nextOrLastIndexAfterOneElementRemoval(state.activeAnalysisIndex, unresolvedAnalyses)
            )
        }
    },
    _fetchAnalysesForSupport({ state, dispatch, commit, getters }) {
        return supportEndpoints.analyses(state.activeRun!)
            .then(analyses => {
                commit(supportMutationNames.setAnalyses, analyses)
                commit(supportMutationNames.initializeTempGenotypes, {
                    analyses: getters[supportGetterNames.analysesForSupport],
                    loci: LOCI
                })
                commit(supportMutationNames.initializeTempNewAllele, {
                    analyses: getters[supportGetterNames.analysesForSupport],
                    loci: LOCI
                })
                dispatch(supportActionNames.fetchToolResults, getters[supportGetterNames.analysesForSupport])
                commit(supportMutationNames.changeActiveAnalysisIndexTo, 0)
                return analyses
            })
    },
    _saveDraftResolution({ state }, analysis: Analysis) {
        return supportEndpoints.saveDraftResolution(
            state.activeRun!,
            state.analyses.find(it => it.id === analysis.id)!
        )
    },
    [supportActionNames.approveAnalysesResolution]({ state, dispatch }) {
        return supportEndpoints.resolveRun(state.activeRun!).then(() => dispatch("fetchStatistics"))
    },
    [supportActionNames.changeActiveAnalysisTo]({ commit, getters }, analysis: Analysis) {
        commit(
            supportMutationNames.changeActiveAnalysisIndexTo,
            getters[supportGetterNames.unresolvedAnalyses].findIndex((it: Analysis) => it.id === analysis.id)
        )
    }
}

const getters: GetterTree<Support, any> = {
    /* eslint-disable @typescript-eslint/no-shadow */
    [supportGetterNames.resolvedAnalyses](state, getters) {
        return getters[supportGetterNames.analysesForSupport].filter((it: Analysis) => it.maybeResolutionDraft?.resolution)
    },
    [supportGetterNames.unresolvedAnalyses](state, getters): Array<Analysis> {
        return getters[supportGetterNames.analysesForSupport].filter((it: Analysis) => !it.maybeResolutionDraft?.resolution)
    },
    [supportGetterNames.contaminatedAnalyses](state, getters) {
        return getters[supportGetterNames.analysesForSupport].filter((it: Analysis) => it.maybeResolutionDraft?.resolution === "contaminated")
    },
    [supportGetterNames.assignedAnalyses](state, getters) {
        return getters[supportGetterNames.analysesForSupport].filter((it: Analysis) => it.maybeResolutionDraft?.resolution === "assigned")
    },
    [supportGetterNames.activeRun](state) {
        return state.activeRun
    },
    [supportGetterNames.analysesForSupport](state) {
        return state.analyses.filter(it => it.status === "IN_SUPPORT")
    },
    [supportGetterNames.activeAnalysis](state: Support, getters) {
        return state.activeAnalysisIndex !== null
            ? getters[supportGetterNames.unresolvedAnalyses][state.activeAnalysisIndex]
            : null
    }
    /* eslint-enable @typescript-eslint/no-shadow */
}

export const support: Module<Support, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
    modules: {
        supportRuns: module
    }
}


function nextOrLastIndexAfterOneElementRemoval(currentIndex: number, analyses: Array<Analysis>) {
    return Math.min(
        currentIndex,
        analyses.length - 2
    )
}

type OldHisatResults = {
    [locus in Locus]: {
        alleles: Array<{ allele: string, abundance: number }>
        contamination: boolean | null
    }
}

type RawToolResults = {
    "hla-hd": HlaHdResults
    "kourami": KouramiResults
    "hisat": OldHisatResults | HisatResults
}

function parseToolResults(rawToolResults: RawToolResults): ToolResults {
    const hisatResults = rawToolResults.hisat
    return <ToolResults>{
        "hla-hd": rawToolResults["hla-hd"],
        hisat: isOldHisatResults(hisatResults)
            ? mapValues(hisatResults, (locusResults) => ({
                rankedAlleles: locusResults.alleles,
                contamination: locusResults.contamination,
                coveredAlleles: []
            }))
            : hisatResults,
        kourami: rawToolResults.kourami
    }
}

function isOldHisatResults(hisatResults: OldHisatResults | HisatResults): hisatResults is OldHisatResults {
    return "alleles" in hisatResults["A"]
}
