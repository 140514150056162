<template>
  <div>
    <p v-if="getBrandName === 'PARSEQ_NAME'">
      PARallele™ <slot/>
    </p>

    <p v-if="getBrandName === 'EPIVALUE_NAME'">
      GeneValue <slot/>
    </p>
  </div>
</template>

<script>
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "BrandName",
        computed: {
            getBrandName() {
                const { brandName } = useBrand()
                return brandName
            }
        }
    }
</script>
