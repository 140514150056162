<template>
    <div class="container">
        <textarea :class="isInvalid ? 'invalid': ''"
                  :value="value"
                  class="md-input md-text-area custom-scroll"
                  ref="input"
                  style="resize: none"
                  v-bind="$attrs"
                  v-on="inputListeners"
        />
        <!-- Border of component becomes thicker on focus, so to prevent content from moving, border exists as separate div -->
        <div class="md-border"></div>
        <label class="md-label"> {{ $attrs.placeholder }}</label>
    </div>
</template>

<script>
    import { inputMixin } from "@/mixins/input-mixin"

    /*
     * Do not use any builtin validation, if you want any preform it yourself and pass validationErrors
     *
     * Label is used as a placeholder and when input is focused as label above input
     */
    export default {
        name: "PTextArea",
        inheritAttrs: false,
        mixins: [ inputMixin ],
        computed: {
            inputListeners() {
                return {
                    ...this.$listeners,
                    input: (event) => {
                        this.$emit("input", event.target.value)
                    },
                    focusout: this.onFocusout
                }
            }
        },
        methods: {
            onFocusout() {
                this.touched = true
            }
        },
        mounted() {
            const styles = [ "md-outlined", "md-filled" ]
            if (!styles.some(style => [ ...this.$el.classList ].includes(style))) {
                console.error(`Specified style: ${this.style} is not valid. Available are ${styles}`)
            }
        }
    }
</script>

<style scoped>
    .md-text-area {
        scroll-padding: 1rem;
    }

    .md-outlined .md-text-area {
        height: 98%;
        top: 3px;
    }
</style>
