<template>
    <button class="icon-btn" v-on="$listeners">
        <p-icon
            :icon-name="iconName"
            :size="size"
        />
    </button>
</template>

<script>
    export default {
        name: "PIconButton",
        props: {
            iconName: {
                type: String,
                required: true
            },
            size: {
                type: String,
                default: () => "100%"
            }
        }
    }
</script>

<style scoped>
    .icon-btn {
        color: var(--grey-300);
    }

    .icon-btn:hover {
        color: var(--grey-800);
    }

    .icon-btn:active,
    .icon-btn:focus {
        color: var(--grey-800);
        outline: none;
    }

    .icon-btn--contrast {
        color: var(--grey-600) !important;
    }

    .icon-btn--round {
        border-radius: 50%;
        padding: .5rem;
        margin: -.5rem;
    }

    .icon-btn--round:hover {
        background-color: var(--grey-200);
    }
</style>
