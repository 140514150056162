<template>
    <div>
        <h3 style="font-size: 1.8rem; font-weight: 600; margin-bottom: 2rem">{{ $t("filesLoadError") }}</h3>
        <!-- eslint-disable vue/require-v-for-key -->
        <div v-for="(files, error) in groupedErrors" style="margin: 0 0 2rem 0">
            {{ $t(error) }}:
            <ul class="custom-scroll overflow-auto" style="margin-left: 1rem; height: 45rem;">
                <li v-for="file in files" class="monospace">{{ file.name }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"

    export default {
        name: "FileValidationError",
        props: {
            error: Object
        },
        computed: {
            groupedErrors() {
                return this.error.details.filesWithErrors.reduce(
                    (grouped, [ file, { type }]) => {
                        grouped[type] = grouped[type]
                            ? [ file, ...grouped[type] ]
                            : [ file ]
                        return grouped
                    },
                    {}
                )
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    filesLoadError: "Files addition error",
                    wrongFileName: "Following files have wrong names",
                },
                [Locales.RU]: {
                    filesLoadError: "Ошибка добавления файлов",
                    wrongFileName: "Следующие файлы имеют неправильные имена",
                }
            }
        }
    }
</script>

<style scoped>

</style>
