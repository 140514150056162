import { Analysis } from "@/utils/analysis"

export const analysesForReview: Array<Analysis> = [{
    "id": "59903726-8dd9-3a08-914e-1f7daada94f6",
    "runId": 79,
    "name": "52182",
    "createdAt": 1580917345021,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:03+44:02",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+03:01/03:276N/03:297/03:298/03:299/03:300/03:301",
            "DRB1": "03:01/03:147+12:01/04:94"
        },
        annotations: {
            "DQB1": {
                "03:276N": "N",
            },
            "DRB1": {
                "04:94": "N",
            }
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52182_S79",
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.71875989400662,
            "readsDistribution": {
                "targetLoci": 128222,
                "relatedLoci": 21046,
                "qualityTrimmed": 36202,
                "offTargetRegions": 38510
            },
            "targetLociCoverage": {
                "A": 30454,
                "B": 13990,
                "C": 26366,
                "DQB1": 25908,
                "DRB1": 31504
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7f5ba0cc-c19d-3b6b-96fc-4755ee1326a0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "9d7f128e-dd6b-3f1a-b4bf-dff76588bf97",
    "runId": 80,
    "name": "52183",
    "createdAt": 1580917355877,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 88494,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 4628,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+02:05",
            "B": "50:01+51:01",
            "C": "06:02+16:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52183_S80",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 419,
            "contamination": false,
            "meanReadsQuality": 35.12530830466001,
            "readsDistribution": {
                "targetLoci": 44678,
                "relatedLoci": 8634,
                "qualityTrimmed": 19474,
                "offTargetRegions": 15708
            },
            "targetLociCoverage": {
                "A": 11382,
                "B": 5616,
                "C": 10734,
                "DQB1": 4628,
                "DRB1": 12318
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a0dc673d-b82c-36b3-8b6b-f38a26fdf30e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "aefd90c6-0c03-3f3b-83af-be2f8caf541e",
    "runId": 81,
    "name": "52184",
    "createdAt": 1580917335943,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+44:02",
            "C": "05:01+07:01",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52184_S81",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.24479434710585,
            "readsDistribution": {
                "targetLoci": 80790,
                "relatedLoci": 14944,
                "qualityTrimmed": 33190,
                "offTargetRegions": 29174
            },
            "targetLociCoverage": {
                "A": 21076,
                "B": 9222,
                "C": 19320,
                "DQB1": 13222,
                "DRB1": 17950
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c7c402f5-5edf-331e-8660-a50029b94ec1",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "9f0f72e5-5b23-3b73-8f4e-3859fbbd8899",
    "runId": 83,
    "name": "52186",
    "createdAt": 1580917360329,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+24:02",
            "B": "07:02+15:01",
            "C": "03:03+07:02",
            "DQB1": "06:02+06:02",
            "DRB1": "15:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52186_S83",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 35.18296959749166,
            "readsDistribution": {
                "targetLoci": 104300,
                "relatedLoci": 4846,
                "qualityTrimmed": 38226,
                "offTargetRegions": 27008
            },
            "targetLociCoverage": {
                "A": 23860,
                "B": 11590,
                "C": 21430,
                "DQB1": 16810,
                "DRB1": 30610
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4388f031-361b-32f7-b408-b8bfd4415556",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "c87b3d24-0164-3d6f-8251-b73bc1b54efc",
    "runId": 84,
    "name": "52187",
    "createdAt": 1580917365374,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "15:01+44:02",
            "C": "03:04+05:01",
            "DQB1": "03:02+05:01",
            "DRB1": "04:01+10:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52187_S84",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 418,
            "contamination": false,
            "meanReadsQuality": 35.02913951079503,
            "readsDistribution": {
                "targetLoci": 102156,
                "relatedLoci": 13500,
                "qualityTrimmed": 46072,
                "offTargetRegions": 31830
            },
            "targetLociCoverage": {
                "A": 25864,
                "B": 11250,
                "C": 23266,
                "DQB1": 18958,
                "DRB1": 22818
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "dbceee8d-5a80-348f-8458-196ce68aed1c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "b476e28c-f0a3-33d6-b71a-ec1c4a42faf4",
    "runId": 85,
    "name": "52188",
    "createdAt": 1580917356080,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+11:01",
            "B": "07:02+07:02",
            "C": "07:02+07:02",
            "DQB1": "05:03+06:03",
            "DRB1": "13:01+14:54"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52188_S85",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 444,
            "contamination": false,
            "meanReadsQuality": 35.07814430126311,
            "readsDistribution": {
                "targetLoci": 57842,
                "relatedLoci": 17556,
                "qualityTrimmed": 28228,
                "offTargetRegions": 19884
            },
            "targetLociCoverage": {
                "A": 14126,
                "B": 7228,
                "C": 11202,
                "DQB1": 12692,
                "DRB1": 12594
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b7ad77cc-7796-3969-ac20-f4a0ea3253e5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "38d34dff-b199-379f-8568-bd3df1ff01c4",
    "runId": 86,
    "name": "52189",
    "createdAt": 1580917343985,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+25:01",
            "B": "13:02+18:01",
            "C": "06:02+12:03",
            "DQB1": "02:02+05:03",
            "DRB1": "07:01+14:54"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52189_S86",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 431,
            "contamination": false,
            "meanReadsQuality": 35.013329450124616,
            "readsDistribution": {
                "targetLoci": 79360,
                "relatedLoci": 15472,
                "qualityTrimmed": 40862,
                "offTargetRegions": 33052
            },
            "targetLociCoverage": {
                "A": 20080,
                "B": 8554,
                "C": 17144,
                "DQB1": 14158,
                "DRB1": 19424
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "140ec911-f41f-3f39-a4a4-8f2eff8ad599",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "81234476-b1c8-3618-8e78-a1cb9364b2a3",
    "runId": 87,
    "name": "52190",
    "createdAt": 1580917366778,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+32:01",
            "B": "18:01+35:01",
            "C": "04:01+07:01",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52190_S87",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 418,
            "contamination": false,
            "meanReadsQuality": 35.154676263642514,
            "readsDistribution": {
                "targetLoci": 68708,
                "relatedLoci": 19584,
                "qualityTrimmed": 33648,
                "offTargetRegions": 31760
            },
            "targetLociCoverage": {
                "A": 15514,
                "B": 8108,
                "C": 15156,
                "DQB1": 13526,
                "DRB1": 16404
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "59532203-33e5-3c24-b9c4-4c829cc4c5d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "4088b540-be16-30e1-b82b-32635e258fc5",
    "runId": 88,
    "name": "52191",
    "createdAt": 1580917345806,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+35:03",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+03:05",
            "DRB1": "03:01/03:147+04:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52191_S88",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 339,
            "contamination": false,
            "meanReadsQuality": 35.142958369427106,
            "readsDistribution": {
                "targetLoci": 53076,
                "relatedLoci": 13346,
                "qualityTrimmed": 24584,
                "offTargetRegions": 18792
            },
            "targetLociCoverage": {
                "A": 13356,
                "B": 7668,
                "C": 11474,
                "DQB1": 8274,
                "DRB1": 12304
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7c712859-faed-3fc6-8655-9cfdc66203fc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "03a9b21f-7dd4-38ff-896d-93b98c475316",
    "runId": 89,
    "name": "52192",
    "createdAt": 1580917351158,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+25:01",
            "B": "08:01+40:01",
            "C": "03:04+07:01",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52192_S89",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.53254917553116,
            "readsDistribution": {
                "targetLoci": 83132,
                "relatedLoci": 28170,
                "qualityTrimmed": 32114,
                "offTargetRegions": 37402
            },
            "targetLociCoverage": {
                "A": 19874,
                "B": 9316,
                "C": 19040,
                "DQB1": 13600,
                "DRB1": 21302
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2e3ec6a1-e5b5-3fe9-b13c-bd892db2519d",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "39cc44e4-4279-3e5d-8805-5d3999b2ee59",
    "runId": 90,
    "name": "52193",
    "createdAt": 1580917353808,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "13:02+49:01",
            "C": "06:02+07:01",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52193_S90",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 482,
            "contamination": false,
            "meanReadsQuality": 35.27032707418889,
            "readsDistribution": {
                "targetLoci": 82528,
                "relatedLoci": 7690,
                "qualityTrimmed": 31604,
                "offTargetRegions": 33262
            },
            "targetLociCoverage": {
                "A": 20132,
                "B": 8838,
                "C": 16932,
                "DQB1": 11680,
                "DRB1": 24946
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1cf6d0b1-5635-3c87-b74b-f4726f1f73a2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "8adf4f7d-7793-3d9c-a33a-0c9b1af6d0ea",
    "runId": 91,
    "name": "52194",
    "createdAt": 1580917334701,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "40:01+44:02",
            "C": "03:04+05:01",
            "DQB1": "06:02+06:04|06:39+06:84",
            "DRB1": "13:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52194_S91",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 410,
            "contamination": false,
            "meanReadsQuality": 35.46506266833106,
            "readsDistribution": {
                "targetLoci": 103578,
                "relatedLoci": 26204,
                "qualityTrimmed": 40170,
                "offTargetRegions": 44168
            },
            "targetLociCoverage": {
                "A": 25208,
                "B": 11466,
                "C": 24394,
                "DQB1": 18260,
                "DRB1": 24250
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "69f62459-cde0-388d-96b1-45c661524ba6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "c2fea07a-848a-3967-bd17-bb7d4af2ffb9",
    "runId": 92,
    "name": "52195",
    "createdAt": 1580917364773,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "08:01+35:01",
            "C": "04:01+07:01",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52195_S92",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 502,
            "contamination": false,
            "meanReadsQuality": 34.74222419024194,
            "readsDistribution": {
                "targetLoci": 55602,
                "relatedLoci": 14314,
                "qualityTrimmed": 33230,
                "offTargetRegions": 24562
            },
            "targetLociCoverage": {
                "A": 12784,
                "B": 6004,
                "C": 12330,
                "DQB1": 10738,
                "DRB1": 13746
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1e2d1402-2750-3c9a-8b4d-066bcd9aaec6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "0ff3543a-7879-3578-a4e7-574c98c9ac04",
    "runId": 93,
    "name": "52196",
    "createdAt": 1580917361312,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+25:01",
            "B": "08:01+18:01",
            "C": "07:01+12:03",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52196_S93",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 502,
            "contamination": false,
            "meanReadsQuality": 35.11117043347161,
            "readsDistribution": {
                "targetLoci": 71130,
                "relatedLoci": 17020,
                "qualityTrimmed": 35028,
                "offTargetRegions": 35184
            },
            "targetLociCoverage": {
                "A": 17510,
                "B": 7236,
                "C": 15494,
                "DQB1": 12508,
                "DRB1": 18382
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "50ce33ef-56e4-3ccb-ad02-31c238562016",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "fd2c9f48-ff4a-3279-ade0-23f16167b530",
    "runId": 94,
    "name": "52197",
    "createdAt": 1580917364386,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+68:24",
            "B": "13:02+35:03",
            "C": "04:01+06:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+14:06"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52197_S94",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 35.217026850686295,
            "readsDistribution": {
                "targetLoci": 117452,
                "relatedLoci": 25732,
                "qualityTrimmed": 52092,
                "offTargetRegions": 50254
            },
            "targetLociCoverage": {
                "A": 29818,
                "B": 14774,
                "C": 27724,
                "DQB1": 15316,
                "DRB1": 29820
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4f89d6ac-8901-3562-bba8-87669395274a",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "3aa08fb8-b187-30ca-93b5-a98a60cd827c",
    "runId": 95,
    "name": "52198",
    "createdAt": 1580917357503,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+31:01",
            "B": "08:01+51:01",
            "C": "07:01+15:02",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+04:07"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52198_S95",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 497,
            "contamination": false,
            "meanReadsQuality": 35.070186300292725,
            "readsDistribution": {
                "targetLoci": 57054,
                "relatedLoci": 15756,
                "qualityTrimmed": 28650,
                "offTargetRegions": 28580
            },
            "targetLociCoverage": {
                "A": 14506,
                "B": 6928,
                "C": 12546,
                "DQB1": 10074,
                "DRB1": 13000
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2f2b7011-a8b5-33cc-830d-cf2a7c9dfa01",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "7d231779-1e70-32d4-b796-5dea9fa05ea9",
    "runId": 97,
    "name": "52200",
    "createdAt": 1580917336712,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+68:01",
            "B": "35:01+40:01|35:380+40:379",
            "C": "03:04+04:01",
            "DQB1": "05:01+05:01",
            "DRB1": "01:01+01:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52200_S97",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.29614045209513,
            "readsDistribution": {
                "targetLoci": 119108,
                "relatedLoci": 32350,
                "qualityTrimmed": 49190,
                "offTargetRegions": 44490
            },
            "targetLociCoverage": {
                "A": 23540,
                "B": 12382,
                "C": 23958,
                "DQB1": 27168,
                "DRB1": 32060
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d8cb57f0-89ef-3f9f-971f-5cfdc8dd95d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "d7920200-e6bc-3b82-a01b-e65592bcfcff",
    "runId": 98,
    "name": "52201",
    "createdAt": 1580917338438,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "27:05+39:06",
            "C": "01:02+07:02",
            "DQB1": "04:02+06:02",
            "DRB1": "08:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52201_S98",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 453,
            "contamination": false,
            "meanReadsQuality": 34.95904302285733,
            "readsDistribution": {
                "targetLoci": 75320,
                "relatedLoci": 5214,
                "qualityTrimmed": 34906,
                "offTargetRegions": 29690
            },
            "targetLociCoverage": {
                "A": 16158,
                "B": 7430,
                "C": 14650,
                "DQB1": 14706,
                "DRB1": 22376
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1e4b5fae-1858-3cc4-ac5b-d0d05ac2b837",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "6b6c6e6a-a69a-3144-8ee9-45e51a5c86af",
    "runId": 99,
    "name": "52202",
    "createdAt": 1580917341537,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+30:04",
            "B": "13:02+44:02",
            "C": "05:01+06:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52202_S99",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 450,
            "contamination": false,
            "meanReadsQuality": 34.97667400390526,
            "readsDistribution": {
                "targetLoci": 78024,
                "relatedLoci": 17720,
                "qualityTrimmed": 40896,
                "offTargetRegions": 37620
            },
            "targetLociCoverage": {
                "A": 18034,
                "B": 9196,
                "C": 17328,
                "DQB1": 13458,
                "DRB1": 20008
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d17f21f4-017b-3b96-b2e2-d94f71b5ec98",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "d78acc36-b0f5-3a0f-b752-f50995c58baf",
    "runId": 100,
    "name": "52203",
    "createdAt": 1580917348421,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+33:05",
            "B": "07:02+14:02",
            "C": "07:02+08:02",
            "DQB1": "05:01+06:02",
            "DRB1": "01:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52203_S100",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 435,
            "contamination": false,
            "meanReadsQuality": 35.13038265306277,
            "readsDistribution": {
                "targetLoci": 109582,
                "relatedLoci": 20370,
                "qualityTrimmed": 47908,
                "offTargetRegions": 40858
            },
            "targetLociCoverage": {
                "A": 23386,
                "B": 11952,
                "C": 21358,
                "DQB1": 24084,
                "DRB1": 28802
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "44bc61d3-c362-3afc-93ae-9739b53d12d4",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "c7532750-3237-3d56-805d-1a27b216055f",
    "runId": 101,
    "name": "52288",
    "createdAt": 1580917365995,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+07:02",
            "C": "07:02+07:02",
            "DQB1": "05:01+05:02",
            "DRB1": "01:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52288_S101",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 449,
            "contamination": false,
            "meanReadsQuality": 35.26437932221171,
            "readsDistribution": {
                "targetLoci": 105528,
                "relatedLoci": 21114,
                "qualityTrimmed": 42208,
                "offTargetRegions": 40252
            },
            "targetLociCoverage": {
                "A": 23816,
                "B": 11306,
                "C": 18558,
                "DQB1": 25712,
                "DRB1": 26136
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "798b2a4e-3420-3f72-8d00-71d87c9bd481",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "3a2cae15-a01c-3fa7-a5f6-9d9b67ec73de",
    "runId": 102,
    "name": "52289",
    "createdAt": 1580917350197,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+56:01",
            "C": "01:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52289_S102",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 461,
            "contamination": false,
            "meanReadsQuality": 34.884205787475324,
            "readsDistribution": {
                "targetLoci": 78018,
                "relatedLoci": 14358,
                "qualityTrimmed": 38508,
                "offTargetRegions": 29990
            },
            "targetLociCoverage": {
                "A": 20388,
                "B": 9418,
                "C": 19150,
                "DQB1": 9438,
                "DRB1": 19624
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "cdbe895d-a2e4-3732-bccb-a347006e48e9",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "4a1a9bf1-9a29-39f3-b106-ea5d6b09ed64",
    "runId": 103,
    "name": "52290",
    "createdAt": 1580917353405,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+57:01",
            "C": "06:02+07:02",
            "DQB1": "03:02+06:02",
            "DRB1": "04:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52290_S103",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 427,
            "contamination": false,
            "meanReadsQuality": 35.23386727999673,
            "readsDistribution": {
                "targetLoci": 86518,
                "relatedLoci": 10564,
                "qualityTrimmed": 34798,
                "offTargetRegions": 36782
            },
            "targetLociCoverage": {
                "A": 19586,
                "B": 10174,
                "C": 18580,
                "DQB1": 18276,
                "DRB1": 19902
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2cf51169-fc3b-3587-b797-e16c93ce581b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "31429a65-f071-302e-800d-224f5f9c05be",
    "runId": 104,
    "name": "52291",
    "createdAt": 1580917335359,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+47:01",
            "C": "06:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52291_S104",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 465,
            "contamination": false,
            "meanReadsQuality": 34.95144881287701,
            "readsDistribution": {
                "targetLoci": 47374,
                "relatedLoci": 10568,
                "qualityTrimmed": 23960,
                "offTargetRegions": 19330
            },
            "targetLociCoverage": {
                "A": 12312,
                "B": 5430,
                "C": 10012,
                "DQB1": 8466,
                "DRB1": 11154
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d9b747ea-2ba9-33b1-b255-8aa96cf3a5c9",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "6f2e226e-86cd-3ddf-9a79-761a901adbb2",
    "runId": 105,
    "name": "52292",
    "createdAt": 1580917342427,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "30:01+31:01",
            "B": "13:02+44:02",
            "C": "06:02+07:04",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52292_S105",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 392,
            "contamination": false,
            "meanReadsQuality": 35.569396950562144,
            "readsDistribution": {
                "targetLoci": 75518,
                "relatedLoci": 18648,
                "qualityTrimmed": 29210,
                "offTargetRegions": 37874
            },
            "targetLociCoverage": {
                "A": 17846,
                "B": 9402,
                "C": 17270,
                "DQB1": 10812,
                "DRB1": 20188
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "78ffc991-7995-345f-b6f1-79dbe46a808c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "46f8791d-ee0f-311b-ad03-2f8ac7bde4e9",
    "runId": 106,
    "name": "52293",
    "createdAt": 1580917332286,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+35:08",
            "C": "04:01+07:02",
            "DQB1": "05:02+06:03",
            "DRB1": "13:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52293_S106",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 423,
            "contamination": false,
            "meanReadsQuality": 35.32791841169001,
            "readsDistribution": {
                "targetLoci": 107012,
                "relatedLoci": 22534,
                "qualityTrimmed": 45470,
                "offTargetRegions": 41946
            },
            "targetLociCoverage": {
                "A": 26390,
                "B": 12810,
                "C": 22792,
                "DQB1": 21070,
                "DRB1": 23950
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "22f58f06-c150-30a2-aa38-0300b6df37d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "ec2b86ce-4fc1-3d61-b976-0da28e4ae094",
    "runId": 107,
    "name": "52294",
    "createdAt": 1580917359926,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:05+26:01",
            "B": "50:01+55:01",
            "C": "03:03+06:02",
            "DQB1": "02:02+03:02",
            "DRB1": "04:02+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52294_S107",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.36957209175395,
            "readsDistribution": {
                "targetLoci": 148408,
                "relatedLoci": 21130,
                "qualityTrimmed": 60280,
                "offTargetRegions": 61872
            },
            "targetLociCoverage": {
                "A": 34132,
                "B": 15688,
                "C": 31006,
                "DQB1": 27570,
                "DRB1": 40012
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "0374bf50-8426-37ef-8bb8-689e11b43b75",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "6fa4b61e-d542-3b78-8c1e-b6127e2b638b",
    "runId": 108,
    "name": "52295",
    "createdAt": 1580917330939,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "26:01+31:01",
            "B": "15:01+50:01",
            "C": "03:03+06:02",
            "DQB1": "02:01+06:03",
            "DRB1": "03:01/03:147+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52295_S108",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 431,
            "contamination": false,
            "meanReadsQuality": 35.51124165739496,
            "readsDistribution": {
                "targetLoci": 113400,
                "relatedLoci": 38364,
                "qualityTrimmed": 49566,
                "offTargetRegions": 56546
            },
            "targetLociCoverage": {
                "A": 28204,
                "B": 11582,
                "C": 25578,
                "DQB1": 20598,
                "DRB1": 27438
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "9776f71a-c734-39dc-996e-b7478a5c1f7f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "bbd32ddf-d290-3815-b442-c1c10bdb3e64",
    "runId": 109,
    "name": "52296",
    "createdAt": 1580917338644,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+26:01",
            "B": "08:01+38:01",
            "C": "02:02+12:03",
            "DQB1": "02:01+06:03",
            "DRB1": "03:01/03:147+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52296_S109",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 455,
            "contamination": false,
            "meanReadsQuality": 35.46781823738291,
            "readsDistribution": {
                "targetLoci": 93368,
                "relatedLoci": 25490,
                "qualityTrimmed": 35600,
                "offTargetRegions": 29998
            },
            "targetLociCoverage": {
                "A": 24712,
                "B": 9490,
                "C": 21308,
                "DQB1": 15384,
                "DRB1": 22474
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "76cdd26e-5237-3412-9303-425b8ace149c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "f1ad619e-6396-3c90-9d26-47b1c9a92b01",
    "runId": 110,
    "name": "52297",
    "createdAt": 1580917364978,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+30:01",
            "B": "13:02+44:06",
            "C": "05:01+06:02",
            "DQB1": "03:01/03:276N/03:297+06:04",
            "DRB1": "11:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52297_S110",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 35.46568563611428,
            "readsDistribution": {
                "targetLoci": 95130,
                "relatedLoci": 26370,
                "qualityTrimmed": 37160,
                "offTargetRegions": 31626
            },
            "targetLociCoverage": {
                "A": 23826,
                "B": 11322,
                "C": 22076,
                "DQB1": 14642,
                "DRB1": 23264
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "25fc97a1-b20d-3505-9c8b-ef53eaab2673",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "b88eb21b-85f4-3779-bc27-687b8729fc74",
    "runId": 111,
    "name": "52298",
    "createdAt": 1580917354000,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+24:02",
            "B": "07:02+08:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52298_S111",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.5849509536022,
            "readsDistribution": {
                "targetLoci": 130156,
                "relatedLoci": 22016,
                "qualityTrimmed": 41814,
                "offTargetRegions": 40710
            },
            "targetLociCoverage": {
                "A": 32712,
                "B": 15782,
                "C": 28550,
                "DQB1": 18964,
                "DRB1": 34148
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c1d62f81-97d6-3b4a-ad24-9ad8e630811c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "dff44b2b-7361-399d-bfd3-e853b96ace04",
    "runId": 112,
    "name": "52299",
    "createdAt": 1580917359087,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "39:06+58:01",
            "C": "03:02+12:03",
            "DQB1": "05:02+06:09",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52299_S112",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.435746167975815,
            "readsDistribution": {
                "targetLoci": 62984,
                "relatedLoci": 11502,
                "qualityTrimmed": 23402,
                "offTargetRegions": 20894
            },
            "targetLociCoverage": {
                "A": 15338,
                "B": 6478,
                "C": 14186,
                "DQB1": 11468,
                "DRB1": 15514
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "8b3ff4af-1615-3919-8e81-77814d54838b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "9e7972a6-02c2-3bf5-9a95-cfa6bc960735",
    "runId": 113,
    "name": "52300",
    "createdAt": 1580917331698,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+25:01",
            "B": "18:01+35:01",
            "C": "04:01+12:03",
            "DQB1": "05:03+06:02",
            "DRB1": "14:54+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52300_S113",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 421,
            "contamination": false,
            "meanReadsQuality": 35.61957067769008,
            "readsDistribution": {
                "targetLoci": 136566,
                "relatedLoci": 24548,
                "qualityTrimmed": 42548,
                "offTargetRegions": 46844
            },
            "targetLociCoverage": {
                "A": 31880,
                "B": 15640,
                "C": 30334,
                "DQB1": 28870,
                "DRB1": 29842
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1b53cf28-6b76-3fe6-b583-e97f034e334b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "06bfd805-f573-35a6-aca0-f88e7ba396bc",
    "runId": 114,
    "name": "52301",
    "createdAt": 1580917350402,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+33:01",
            "B": "14:02+35:03",
            "C": "08:02+12:03",
            "DQB1": "03:04+05:01",
            "DRB1": "01:02+04:08"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52301_S114",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 441,
            "contamination": false,
            "meanReadsQuality": 35.30188887234482,
            "readsDistribution": {
                "targetLoci": 130646,
                "relatedLoci": 18370,
                "qualityTrimmed": 46546,
                "offTargetRegions": 39278
            },
            "targetLociCoverage": {
                "A": 31984,
                "B": 14836,
                "C": 28682,
                "DQB1": 25158,
                "DRB1": 29986
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "10aaf0e0-0a4d-3c73-8329-15dd31a681c6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "2e0afabe-1862-3260-abab-6477a7cbb9f9",
    "runId": 115,
    "name": "52302",
    "createdAt": 1580917358885,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+25:01",
            "B": "15:01+18:01",
            "C": "03:04+12:03",
            "DQB1": "03:02+06:02",
            "DRB1": "04:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52302_S115",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 426,
            "contamination": false,
            "meanReadsQuality": 35.38401852322764,
            "readsDistribution": {
                "targetLoci": 158040,
                "relatedLoci": 5784,
                "qualityTrimmed": 49934,
                "offTargetRegions": 44744
            },
            "targetLociCoverage": {
                "A": 38208,
                "B": 15860,
                "C": 34034,
                "DQB1": 29652,
                "DRB1": 40286
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "9daecf98-6862-381d-8cbf-4e2a93ae5d87",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "a37bf667-0ce7-3ac8-96b9-7194dad1d08b",
    "runId": 116,
    "name": "52303",
    "createdAt": 1580917354883,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:704",
            "B": "18:01+35:01",
            "C": "04:01+12:03",
            "DQB1": "03:01/03:276N/03:297+03:03",
            "DRB1": "07:01+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52303_S116",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 447,
            "contamination": false,
            "meanReadsQuality": 35.35983935919971,
            "readsDistribution": {
                "targetLoci": 107110,
                "relatedLoci": 17766,
                "qualityTrimmed": 38656,
                "offTargetRegions": 34816
            },
            "targetLociCoverage": {
                "A": 25868,
                "B": 10904,
                "C": 25430,
                "DQB1": 17568,
                "DRB1": 27340
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "09059a3c-d7ef-311a-8bfd-f22e26b8b9e2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "f937f0c1-176a-3eb7-88b6-ca74595bb12f",
    "runId": 117,
    "name": "52304",
    "createdAt": 1580917342629,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+31:01",
            "B": "07:02+35:08",
            "C": "04:01+07:02",
            "DQB1": "02:02+06:04",
            "DRB1": "07:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52304_S117",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 435,
            "contamination": false,
            "meanReadsQuality": 35.59479351920184,
            "readsDistribution": {
                "targetLoci": 145986,
                "relatedLoci": 26032,
                "qualityTrimmed": 45182,
                "offTargetRegions": 50414
            },
            "targetLociCoverage": {
                "A": 38692,
                "B": 17500,
                "C": 33514,
                "DQB1": 19120,
                "DRB1": 37160
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1a0ccd74-4275-3004-8f21-b92c30415043",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "91bc0e42-c34f-3db7-9e24-ec89d97f5557",
    "runId": 118,
    "name": "52305",
    "createdAt": 1580917352179,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "35:01+57:01",
            "C": "04:01+06:02",
            "DQB1": "03:03+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52305_S118",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 441,
            "contamination": false,
            "meanReadsQuality": 35.340701819726334,
            "readsDistribution": {
                "targetLoci": 138366,
                "relatedLoci": 20736,
                "qualityTrimmed": 48020,
                "offTargetRegions": 44094
            },
            "targetLociCoverage": {
                "A": 30942,
                "B": 15810,
                "C": 30906,
                "DQB1": 24478,
                "DRB1": 36230
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f368c406-dc84-3e28-89bb-cb2269c1f421",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "ae5538ca-7290-3e73-9e07-de6df0f1b28d",
    "runId": 119,
    "name": "52306",
    "createdAt": 1580917367596,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+23:01",
            "B": "35:03+44:03",
            "C": "04:01+04:01",
            "DQB1": "02:02+04:02",
            "DRB1": "07:01+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52306_S119",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.59712630699594,
            "readsDistribution": {
                "targetLoci": 168702,
                "relatedLoci": 12844,
                "qualityTrimmed": 47960,
                "offTargetRegions": 51492
            },
            "targetLociCoverage": {
                "A": 36346,
                "B": 18606,
                "C": 36160,
                "DQB1": 28432,
                "DRB1": 49158
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "461a75c6-9470-3e0d-b23f-d7700a71ecc0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "0c641fd0-0c00-3bc0-b2cc-5fe79bbbb135",
    "runId": 120,
    "name": "52307",
    "createdAt": 1580917330114,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+30:01",
            "B": "14:02+38:01",
            "C": "08:02+12:03",
            "DQB1": "05:02+06:09",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52307_S120",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.38585479653518,
            "readsDistribution": {
                "targetLoci": 77252,
                "relatedLoci": 14712,
                "qualityTrimmed": 26856,
                "offTargetRegions": 23674
            },
            "targetLociCoverage": {
                "A": 18092,
                "B": 8728,
                "C": 18364,
                "DQB1": 13762,
                "DRB1": 18306
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "23a973da-80c1-3ae8-a6d7-ffd517e301f5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "b6f116fe-6bc8-3849-bd6b-c3c79f26ed14",
    "runId": 121,
    "name": "52308",
    "createdAt": 1580917338031,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "07:02+35:02",
            "C": "06:02+07:02",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:03+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52308_S121",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 389,
            "contamination": false,
            "meanReadsQuality": 35.69113839673075,
            "readsDistribution": {
                "targetLoci": 94738,
                "relatedLoci": 20494,
                "qualityTrimmed": 28598,
                "offTargetRegions": 35378
            },
            "targetLociCoverage": {
                "A": 20460,
                "B": 12052,
                "C": 20256,
                "DQB1": 22312,
                "DRB1": 19658
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "163e46cd-fb57-34bf-9eca-87347a7d0ac7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "b4c7fbcb-42ff-32d2-9364-4a93b1cd250c",
    "runId": 123,
    "name": "52310",
    "createdAt": 1580917333525,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 35438,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+25:01",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52310_S123",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.49636043466944,
            "readsDistribution": {
                "targetLoci": 152062,
                "relatedLoci": 31744,
                "qualityTrimmed": 53074,
                "offTargetRegions": 60088
            },
            "targetLociCoverage": {
                "A": 35948,
                "B": 16024,
                "C": 30474,
                "DQB1": 35438,
                "DRB1": 34178
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-1",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "bbb95c5d-a045-3557-a80d-f4b958a868fe",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "fake-sample-for-review-1",
    "runId": 13,
    "name": "45764",
    "createdAt": 1580917333525,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 35438,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+25:01",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52310_S123",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.49636043466944,
            "readsDistribution": {
                "targetLoci": 152062,
                "relatedLoci": 31744,
                "qualityTrimmed": 53074,
                "offTargetRegions": 60088
            },
            "targetLociCoverage": {
                "A": 35948,
                "B": 16024,
                "C": 30474,
                "DQB1": 35438,
                "DRB1": 34178
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-2",
    "sequencingRunId": "G2H9F",
    "sampleUuid": "bbb95c5d-a045-3557-a80d-f4b958a868fe",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
},
{
    "id": "fake-sample-for-review-2",
    "runId": 56,
    "name": "45787",
    "createdAt": 1580917333525,
    sequencingRunDate: 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 35438,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+25:01",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52310_S123",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.49636043466944,
            "readsDistribution": {
                "targetLoci": 152062,
                "relatedLoci": 31744,
                "qualityTrimmed": 53074,
                "offTargetRegions": 60088
            },
            "targetLociCoverage": {
                "A": 35948,
                "B": 16024,
                "C": 30474,
                "DQB1": 35438,
                "DRB1": 34178
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "fake-run-2",
    "sequencingRunId": "G2H9F",
    "sampleUuid": "bbb95c5d-a045-3557-a80d-f4b958a868fe",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}]

export const analysesForApprove: Array<Analysis> = [
    {
        "id": "fake-sample-for-approve-1",
        "runId": 29,
        "name": "5467",
        "createdAt": 1582117000945,
        sequencingRunDate: 1580917352789,
        "status": "AWAITING_APPROVE",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            "quality": "red",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 71830,
                        "quality": "yellow"
                    }
                },
                "locusSpecificMetrics": {
                    "A": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3838,
                            "quality": "red"
                        }
                    },
                    "B": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 1416,
                            "quality": "red"
                        }
                    },
                    "C": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3740,
                            "quality": "yellow"
                        }
                    },
                    "DQB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3134,
                            "quality": "yellow"
                        }
                    },
                    "DRB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3628,
                            "quality": "yellow"
                        }
                    }
                }
            },
            "genotypes": {
                "A": null,
                "B": null,
                "C": "02:02+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+08:03"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "PC_R1",
            annotations: null,
            "qualityMetrics": {
                "insertSize": 434,
                "contamination": false,
                "meanReadsQuality": 37.758890640533664,
                "readsDistribution": {
                    "targetLoci": 15756,
                    "relatedLoci": 2218,
                    "qualityTrimmed": 46356,
                    "offTargetRegions": 7500
                },
                "targetLociCoverage": {
                    "A": 3838,
                    "B": 1416,
                    "C": 3740,
                    "DQB1": 3134,
                    "DRB1": 3628
                }
            },
        },
        maybeSupportRequest: {
            "loci": [ "B", "C" ],
            "comment": "4564",
            "createdBy": "brother"
        },
        maybeResolutionDraft: {
            "genotypes": {
                "A": "01:03 + 02:06",
                "B": "50:13 + 08:10"
            },
            "resolution": "assigned",
            annotations: null
        },
        "sequencingRunUuid": "fake-run-1",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "39da6274-9617-3ad6-97fc-27d3793b16e2",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "UNDEFINED",
        "priorStates": [
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:51.468+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "AWAITING_APPROVE",
                "prior_analysis_state_analysis_result": null,
                "prior_analysis_state_maybe_resolution_draft": null,
                "prior_analysis_state_maybe_support_request": null
            },
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:27.067+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "IN_SUPPORT",
                "prior_analysis_state_analysis_result": {
                    "quality": "red",
                    "problems": {
                        "generalMetrics": {
                            "totalReads": {
                                "type": "LAB_ISSUE",
                                "value": 71830,
                                "quality": "yellow"
                            }
                        },
                        "locusSpecificMetrics": {
                            "A": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3838,
                                    "quality": "red"
                                }
                            },
                            "B": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 1416,
                                    "quality": "red"
                                }
                            },
                            "C": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3740,
                                    "quality": "yellow"
                                }
                            },
                            "DQB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3134,
                                    "quality": "yellow"
                                }
                            },
                            "DRB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3628,
                                    "quality": "yellow"
                                }
                            }
                        }
                    },
                    "genotypes": {
                        "A": null,
                        "B": null,
                        "C": "02:02+05:01",
                        "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                        "DRB1": "04:01+08:03"
                    },
                    "resolution": "SUPPORT_REQUEST",
                    "sampleName": "PC_R1",
                    annotations: null,
                    "qualityMetrics": {
                        "insertSize": 434,
                        "contamination": false,
                        "meanReadsQuality": 37.758890640533664,
                        "readsDistribution": {
                            "targetLoci": 15756,
                            "relatedLoci": 2218,
                            "qualityTrimmed": 46356,
                            "offTargetRegions": 7500
                        },
                        "targetLociCoverage": {
                            "A": 3838,
                            "B": 1416,
                            "C": 3740,
                            "DQB1": 3134,
                            "DRB1": 3628
                        }
                    },
                },
                "prior_analysis_state_maybe_resolution_draft": {
                    "genotypes": {
                        "A": null,
                        "B": null
                    },
                    "resolution": "assigned",
                    annotations: null
                },
                "prior_analysis_state_maybe_support_request": null
            },
        ]
    },
    {
        "id": "fake-sample-for-approve-2",
        "runId": 30,
        "name": "5468",
        "createdAt": 1582117000945,
        sequencingRunDate: 1580917352789,
        "status": "AWAITING_APPROVE",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            "quality": "red",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 71830,
                        "quality": "yellow"
                    }
                },
                "locusSpecificMetrics": {
                    "A": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3838,
                            "quality": "red"
                        }
                    },
                    "B": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 1416,
                            "quality": "red"
                        }
                    },
                    "C": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3740,
                            "quality": "yellow"
                        }
                    },
                    "DQB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3134,
                            "quality": "yellow"
                        }
                    },
                    "DRB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3628,
                            "quality": "yellow"
                        }
                    }
                }
            },
            "genotypes": {
                "A": null,
                "B": null,
                "C": "02:02+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+08:03"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "PC_R1",
            annotations: null,
            "qualityMetrics": {
                "insertSize": 434,
                "contamination": false,
                "meanReadsQuality": 37.758890640533664,
                "readsDistribution": {
                    "targetLoci": 15756,
                    "relatedLoci": 2218,
                    "qualityTrimmed": 46356,
                    "offTargetRegions": 7500
                },
                "targetLociCoverage": {
                    "A": 3838,
                    "B": 1416,
                    "C": 3740,
                    "DQB1": 3134,
                    "DRB1": 3628
                }
            },
        },
        maybeSupportRequest: {
            "loci": [ "B", "C" ],
            "comment": "4564",
            "createdBy": "brother"
        },
        maybeResolutionDraft: {
            "genotypes": {
                "A": "01:03 + 02:06",
                "B": "50:13 + 08:10"
            },
            "resolution": "assigned",
            annotations: null
        },
        "sequencingRunUuid": "fake-run-1",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "39da6274-9617-3ad6-97fc-27d3793b16e2",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "UNDEFINED",
        "priorStates": [
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:51.468+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "AWAITING_APPROVE",
                "prior_analysis_state_analysis_result": null,
                "prior_analysis_state_maybe_resolution_draft": null,
                "prior_analysis_state_maybe_support_request": null
            },
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:27.067+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "IN_SUPPORT",
                "prior_analysis_state_analysis_result": {
                    "quality": "red",
                    "problems": {
                        "generalMetrics": {
                            "totalReads": {
                                "type": "LAB_ISSUE",
                                "value": 71830,
                                "quality": "yellow"
                            }
                        },
                        "locusSpecificMetrics": {
                            "A": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3838,
                                    "quality": "red"
                                }
                            },
                            "B": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 1416,
                                    "quality": "red"
                                }
                            },
                            "C": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3740,
                                    "quality": "yellow"
                                }
                            },
                            "DQB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3134,
                                    "quality": "yellow"
                                }
                            },
                            "DRB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3628,
                                    "quality": "yellow"
                                }
                            }
                        }
                    },
                    "genotypes": {
                        "A": null,
                        "B": null,
                        "C": "02:02+05:01",
                        "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                        "DRB1": "04:01+08:03"
                    },
                    "resolution": "SUPPORT_REQUEST",
                    "sampleName": "PC_R1",
                    annotations: null,
                    "qualityMetrics": {
                        "insertSize": 434,
                        "contamination": false,
                        "meanReadsQuality": 37.758890640533664,
                        "readsDistribution": {
                            "targetLoci": 15756,
                            "relatedLoci": 2218,
                            "qualityTrimmed": 46356,
                            "offTargetRegions": 7500
                        },
                        "targetLociCoverage": {
                            "A": 3838,
                            "B": 1416,
                            "C": 3740,
                            "DQB1": 3134,
                            "DRB1": 3628
                        }
                    },
                },
                "prior_analysis_state_maybe_resolution_draft": {
                    "genotypes": {
                        "A": null,
                        "B": null
                    },
                    "resolution": "assigned",
                    annotations: null
                },
                "prior_analysis_state_maybe_support_request": null
            },
        ]
    },
    {
        "id": "fake-sample-for-approve-3",
        "runId": 31,
        "name": "5469",
        "createdAt": 1582117000945,
        sequencingRunDate: 1580917352789,
        "status": "AWAITING_APPROVE",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            "quality": "red",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 71830,
                        "quality": "yellow"
                    }
                },
                "locusSpecificMetrics": {
                    "A": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3838,
                            "quality": "red"
                        }
                    },
                    "B": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 1416,
                            "quality": "red"
                        }
                    },
                    "C": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3740,
                            "quality": "yellow"
                        }
                    },
                    "DQB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3134,
                            "quality": "yellow"
                        }
                    },
                    "DRB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3628,
                            "quality": "yellow"
                        }
                    }
                }
            },
            "genotypes": {
                "A": null,
                "B": null,
                "C": "02:02+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+08:03"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "PC_R1",
            annotations: null,
            "qualityMetrics": {
                "insertSize": 434,
                "contamination": false,
                "meanReadsQuality": 37.758890640533664,
                "readsDistribution": {
                    "targetLoci": 15756,
                    "relatedLoci": 2218,
                    "qualityTrimmed": 46356,
                    "offTargetRegions": 7500
                },
                "targetLociCoverage": {
                    "A": 3838,
                    "B": 1416,
                    "C": 3740,
                    "DQB1": 3134,
                    "DRB1": 3628
                }
            },
        },
        maybeSupportRequest: {
            "loci": [ "B", "C" ],
            "comment": "4564",
            "createdBy": "brother"
        },
        maybeResolutionDraft: {
            "genotypes": {
                "A": "01:03 + 02:06",
                "B": "50:13 + 08:10",
            },
            "resolution": "assigned",
            annotations: null
        },
        "sequencingRunUuid": "fake-run-1",
        "sequencingRunId": "CPTKH",
        "sampleUuid": "39da6274-9617-3ad6-97fc-27d3793b16e2",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "UNDEFINED",
        "priorStates": [
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:51.468+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "AWAITING_APPROVE",
                "prior_analysis_state_analysis_result": null,
                "prior_analysis_state_maybe_resolution_draft": null,
                "prior_analysis_state_maybe_support_request": null
            },
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:27.067+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "IN_SUPPORT",
                "prior_analysis_state_analysis_result": {
                    "quality": "red",
                    "problems": {
                        "generalMetrics": {
                            "totalReads": {
                                "type": "LAB_ISSUE",
                                "value": 71830,
                                "quality": "yellow"
                            }
                        },
                        "locusSpecificMetrics": {
                            "A": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3838,
                                    "quality": "red"
                                }
                            },
                            "B": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 1416,
                                    "quality": "red"
                                }
                            },
                            "C": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3740,
                                    "quality": "yellow"
                                }
                            },
                            "DQB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3134,
                                    "quality": "yellow"
                                }
                            },
                            "DRB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3628,
                                    "quality": "yellow"
                                }
                            }
                        }
                    },
                    "genotypes": {
                        "A": null,
                        "B": null,
                        "C": "02:02+05:01",
                        "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                        "DRB1": "04:01+08:03"
                    },
                    "resolution": "SUPPORT_REQUEST",
                    "sampleName": "PC_R1",
                    annotations: null,
                    "qualityMetrics": {
                        "insertSize": 434,
                        "contamination": false,
                        "meanReadsQuality": 37.758890640533664,
                        "readsDistribution": {
                            "targetLoci": 15756,
                            "relatedLoci": 2218,
                            "qualityTrimmed": 46356,
                            "offTargetRegions": 7500
                        },
                        "targetLociCoverage": {
                            "A": 3838,
                            "B": 1416,
                            "C": 3740,
                            "DQB1": 3134,
                            "DRB1": 3628
                        }
                    },
                },
                "prior_analysis_state_maybe_resolution_draft": {
                    "genotypes": {
                        "A": null,
                        "B": null
                    },
                    "resolution": "assigned",
                    annotations: null
                },
                "prior_analysis_state_maybe_support_request": null
            },
        ]
    },
    {
        "id": "fake-sample-for-approve-4",
        "runId": 32,
        "name": "5470",
        "createdAt": 1582117000945,
        sequencingRunDate: 1580917352789,
        "status": "AWAITING_APPROVE",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            "quality": "red",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 71830,
                        "quality": "yellow"
                    }
                },
                "locusSpecificMetrics": {
                    "A": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3838,
                            "quality": "red"
                        }
                    },
                    "B": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 1416,
                            "quality": "red"
                        }
                    },
                    "C": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3740,
                            "quality": "yellow"
                        }
                    },
                    "DQB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3134,
                            "quality": "yellow"
                        }
                    },
                    "DRB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3628,
                            "quality": "yellow"
                        }
                    }
                }
            },
            "genotypes": {
                "A": null,
                "B": null,
                "C": "02:02+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+08:03"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "PC_R1",
            annotations: null,
            "qualityMetrics": {
                "insertSize": 434,
                "contamination": false,
                "meanReadsQuality": 37.758890640533664,
                "readsDistribution": {
                    "targetLoci": 15756,
                    "relatedLoci": 2218,
                    "qualityTrimmed": 46356,
                    "offTargetRegions": 7500
                },
                "targetLociCoverage": {
                    "A": 3838,
                    "B": 1416,
                    "C": 3740,
                    "DQB1": 3134,
                    "DRB1": 3628
                }
            },
        },
        maybeSupportRequest: {
            "loci": [ "B", "C" ],
            "comment": "4564",
            "createdBy": "brother"
        },
        maybeResolutionDraft: {
            "genotypes": {
                "A": "01:03 + 02:06",
                "B": "50:13 + 08:10"
            },
            "resolution": "assigned",
            annotations: null
        },
        "sequencingRunUuid": "fake-run-2",
        "sequencingRunId": "G2H9F",
        "sampleUuid": "39da6274-9617-3ad6-97fc-27d3793b16e2",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "UNDEFINED",
        "priorStates": [
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:51.468+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "AWAITING_APPROVE",
                "prior_analysis_state_analysis_result": null,
                "prior_analysis_state_maybe_support_request": null,
                "prior_analysis_state_maybe_resolution_draft": null,
            },
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:27.067+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "IN_SUPPORT",
                "prior_analysis_state_analysis_result": {
                    "quality": "red",
                    "problems": {
                        "generalMetrics": {
                            "totalReads": {
                                "type": "LAB_ISSUE",
                                "value": 71830,
                                "quality": "yellow"
                            }
                        },
                        "locusSpecificMetrics": {
                            "A": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3838,
                                    "quality": "red"
                                }
                            },
                            "B": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 1416,
                                    "quality": "red"
                                }
                            },
                            "C": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3740,
                                    "quality": "yellow"
                                }
                            },
                            "DQB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3134,
                                    "quality": "yellow"
                                }
                            },
                            "DRB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3628,
                                    "quality": "yellow"
                                }
                            }
                        }
                    },
                    "genotypes": {
                        "A": null,
                        "B": null,
                        "C": "02:02+05:01",
                        "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                        "DRB1": "04:01+08:03"
                    },
                    "resolution": "SUPPORT_REQUEST",
                    "sampleName": "PC_R1",
                    annotations: null,
                    "qualityMetrics": {
                        "insertSize": 434,
                        "contamination": false,
                        "meanReadsQuality": 37.758890640533664,
                        "readsDistribution": {
                            "targetLoci": 15756,
                            "relatedLoci": 2218,
                            "qualityTrimmed": 46356,
                            "offTargetRegions": 7500
                        },
                        "targetLociCoverage": {
                            "A": 3838,
                            "B": 1416,
                            "C": 3740,
                            "DQB1": 3134,
                            "DRB1": 3628
                        }
                    },
                },
                "prior_analysis_state_maybe_resolution_draft": {
                    "genotypes": {
                        "A": null,
                        "B": null
                    },
                    "resolution": "assigned",
                    annotations: null
                },
                "prior_analysis_state_maybe_support_request": null,
            },
        ]
    },
    {
        "id": "fake-sample-for-approve-5",
        "runId": 33,
        "name": "5471",
        "createdAt": 1582117000945,
        sequencingRunDate: 1580917352789,
        "status": "AWAITING_APPROVE",
        "configuredPipelineName": "mercury-pipeline-1.1-1",
        "configuredPipelineImgtVersion": "3.39.0",
        configuredPipelineGenotypingResolution: "TWO_FIELD",
        isControlSample: false,
        "result": {
            "quality": "red",
            "problems": {
                "generalMetrics": {
                    "totalReads": {
                        "type": "LAB_ISSUE",
                        "value": 71830,
                        "quality": "yellow"
                    }
                },
                "locusSpecificMetrics": {
                    "A": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3838,
                            "quality": "red"
                        }
                    },
                    "B": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 1416,
                            "quality": "red"
                        }
                    },
                    "C": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3740,
                            "quality": "yellow"
                        }
                    },
                    "DQB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3134,
                            "quality": "yellow"
                        }
                    },
                    "DRB1": {
                        "locusCoverage": {
                            "type": "ANALYSIS_ISSUE",
                            "value": 3628,
                            "quality": "yellow"
                        }
                    }
                }
            },
            "genotypes": {
                "A": null,
                "B": null,
                "C": "02:02+05:01",
                "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                "DRB1": "04:01+08:03"
            },
            "resolution": "SUPPORT_REQUEST",
            "sampleName": "PC_R1",
            annotations: null,
            "qualityMetrics": {
                "insertSize": 434,
                "contamination": false,
                "meanReadsQuality": 37.758890640533664,
                "readsDistribution": {
                    "targetLoci": 15756,
                    "relatedLoci": 2218,
                    "qualityTrimmed": 46356,
                    "offTargetRegions": 7500
                },
                "targetLociCoverage": {
                    "A": 3838,
                    "B": 1416,
                    "C": 3740,
                    "DQB1": 3134,
                    "DRB1": 3628
                }
            },
        },
        maybeSupportRequest: {
            "loci": [ "B", "C" ],
            "comment": "4564",
            "createdBy": "brother"
        },
        maybeResolutionDraft: {
            "genotypes": {
                "A": "01:03 + 02:06",
                "B": "50:13 + 08:10"
            },
            "resolution": "assigned",
            annotations: null
        },
        "sequencingRunUuid": "fake-run-2",
        "sequencingRunId": "G2H9F",
        "sampleUuid": "39da6274-9617-3ad6-97fc-27d3793b16e2",
        "reagentKitLotName": "6666-6",
        "reagentKitLotQcStatus": "UNDEFINED",
        "priorStates": [
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:51.468+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "AWAITING_APPROVE",
                "prior_analysis_state_analysis_result": null,
                "prior_analysis_state_maybe_support_request": null,
                "prior_analysis_state_maybe_resolution_draft": null,
            },
            {
                "prior_analysis_state_analysis_uuid": "00d75e52-cc80-35a4-8770-a996d0845b3e",
                "prior_analysis_state_modified_at": "2020-04-17T17:14:27.067+00:00",
                "prior_analysis_state_modified_by": "brother",
                "prior_analysis_state_analysis_status": "IN_SUPPORT",
                "prior_analysis_state_analysis_result": {
                    "quality": "red",
                    "problems": {
                        "generalMetrics": {
                            "totalReads": {
                                "type": "LAB_ISSUE",
                                "value": 71830,
                                "quality": "yellow"
                            }
                        },
                        "locusSpecificMetrics": {
                            "A": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3838,
                                    "quality": "red"
                                }
                            },
                            "B": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 1416,
                                    "quality": "red"
                                }
                            },
                            "C": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3740,
                                    "quality": "yellow"
                                }
                            },
                            "DQB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3134,
                                    "quality": "yellow"
                                }
                            },
                            "DRB1": {
                                "locusCoverage": {
                                    "type": "ANALYSIS_ISSUE",
                                    "value": 3628,
                                    "quality": "yellow"
                                }
                            }
                        }
                    },
                    "genotypes": {
                        "A": null,
                        "B": null,
                        "C": "02:02+05:01",
                        "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
                        "DRB1": "04:01+08:03"
                    },
                    "resolution": "SUPPORT_REQUEST",
                    "sampleName": "PC_R1",
                    annotations: null,
                    "qualityMetrics": {
                        "insertSize": 434,
                        "contamination": false,
                        "meanReadsQuality": 37.758890640533664,
                        "readsDistribution": {
                            "targetLoci": 15756,
                            "relatedLoci": 2218,
                            "qualityTrimmed": 46356,
                            "offTargetRegions": 7500
                        },
                        "targetLociCoverage": {
                            "A": 3838,
                            "B": 1416,
                            "C": 3740,
                            "DQB1": 3134,
                            "DRB1": 3628
                        }
                    },
                },
                "prior_analysis_state_maybe_resolution_draft": {
                    "genotypes": {
                        "A": null,
                        "B": null
                    },
                    "resolution": "assigned",
                    annotations: null
                },
                "prior_analysis_state_maybe_support_request": null,
            },
        ]
    },
]


export const runsWithAnalysesForApprove = [{
    "id": "fake-run-1",
    "name": "CPTKH",
    "count": 3
}, {
    "id": "fake-run-2",
    "name": "G2H9F",
    "count": 2
}]


export const runsWithAnalysesForReview = [{
    "id": "fake-run-1",
    "name": "CPTKH",
    "count": 42
}, {
    "id": "fake-run-2",
    "name": "G2H9F",
    "count": 2
}]
