<template>
    <p-dropdown
        class="md-container"
        full-width
        v-model="dropdownShown"
    >
        <template v-slot:activator="{ toggleDropdown }">
            <select-button
                :disabled="disabled"
                :invalid="isInvalid"
                :label="$attrs.placeholder"
                :value-is-empty="value.length === 0"
                :view="view"
                @toggle="toggleDropdown"
                @clear="clearValue()"
            >
                <span class="chips" v-show="value.length !== 0">
                    <span :key="getKey(v)"
                          class="chip"
                          v-for="v in value"
                    >
                        <span class="mr-1">{{ v ? view(v) : v }}</span>
                        <p-icon
                            @click.native.stop="removeSelectedOption(v)"
                            class="chip__clear-selection-btn"
                            icon-name="cross"
                            size=".6em"
                        />
                    </span>
                </span>
            </select-button>
            <p-input-errors v-if="touched" :validationErrors="validationErrors"/>
        </template>
        <div class="dropdown--white">
            <div class="select__dropdown-search">
                <p-input
                    class="md-outlined"
                    v-model="search"
                    @input="$emit('update:search-query', search)"
                    type="text"
                    autofocus
                >
                    <template #icon-right>
                        <p-icon icon-name="search" size="1.5em"/>
                    </template>
                </p-input>
            </div>
            <ul class="select__options custom-scroll">
                <p-spinner v-if="options === null"/>
                <li v-else-if="options && options.length === 0"
                    class="select__no-results-placeholder"
                >{{ $t("noMatchingResults") }}
                </li>
                <template v-else>
                    <template v-for="option in options">
                        <li @click="removeSelectedOption(option)"
                            class="select__option selected"
                            v-if="isSelected(option)"
                            :key="getKey(option)"
                        >{{ view(option) }}
                        </li>
                        <li @click="selectOption(option)"
                            class="select__option"
                            :key="getKey(option)"
                            v-else
                        >{{ view(option) }}
                        </li>
                    </template>
                </template>
            </ul>
        </div>
    </p-dropdown>
</template>

<script>
    import { selectWithSearchMixin } from "@/mixins/select-with-search-mixin"
    import SelectButton from "@/base-components/selects/select-button"

    export default {
        name: "PMultiSelectWithSearch",
        components: { SelectButton },
        mixins: [ selectWithSearchMixin ],
        props: {
            value: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        methods: {
            isSelected(option) {
                return this.value.includes(option)
            },
            selectOption(option) {
                this.$emit("input", [ ...this.value, option ])
            },
            removeSelectedOption(option) {
                this.$emit("input", this.value.filter(it => it !== option))
            },
            clearValue() {
                this.$emit("input", [])
            }
        },
    }
</script>

<style scoped>
.chips {
    display: inline-flex;
    flex-wrap: wrap;
}

.chip {
    --font-size: 1.5rem;
    font-size: var(--font-size);
    line-height: var(--font-size);
    border-radius: 10px;
    color: rgba(0, 0, 0, .87);
    padding: .3em .4em;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    margin-right: 1rem;
}

.md-filled .chip {
    background-color: white;
}

.md-outlined .chip {
    background-color: var(--grey-400);
}

.chip__clear-selection-btn {
    color: var(--grey-600);
}

.chip__clear-selection-btn:hover {
    fill: black;
}
</style>
