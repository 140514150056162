import { waitForMessage, whenReady } from "@/extensions/websocket-extensions"
import { RunBlueprint, SampleBlueprint } from "@/store/modules/raw-data-upload"
import { globalLogger, PrefixedLogger } from "@/logger"

export interface IBatchUploadMessageBroker {

    sendNewRunBatchUploadInitializationMessage(run: RunBlueprint, samples: Array<SampleBlueprint>): this

    waitForBatchUploadIds(): Promise<Array<string>>

    completeUpload(): Promise<void>

    sendCompleteSampleUploadMessage(sampleId: string): this

    sendRequestForSampleFileUploadLink(sampleId: string, sampleFileName: string): this

    waitForSampleFileUploadLink(): Promise<string>
}

export class BatchUploadMessageBroker implements IBatchUploadMessageBroker {

    private readonly socket: WebSocket
    private whenBrokerIsReady: Promise<WebSocket>
    /*
     * This field is introduced due to some weird websocket implementation details
     * e.g. chrome always closes socket with 1006
     * We expect that socket will always be closed from frontend first using completeUpload method
     */
    private intentionallyClosed = false
    private logger = new PrefixedLogger(globalLogger, "Batch upload websocket")

    constructor(
        endpoint: string,
        onError?: (error: any) => any,
        onSpontaneousClose?: (closeEvent: CloseEvent) => any
    ) {
        const protocol = document.location.protocol.startsWith("https") ? "wss" : "ws"

        this.socket = new WebSocket(`${protocol}://${document.location.host}/${endpoint}`)
        this.whenBrokerIsReady = whenReady(this.socket)
        this.socket.addEventListener("error", (e) => {
            onError?.(e)
            this.logger.error("error encountered in websocket connection", e)
        })
        this.socket.onclose = (e) => {
            if (!this.intentionallyClosed) {
                this.logger.error("closed unintentionally", e)
                onSpontaneousClose?.(e)
            } else {
                this.logger.log("closed")
            }
        }
    }

    sendNewRunBatchUploadInitializationMessage(sequencingRun: RunBlueprint, samples: Array<SampleBlueprint>) {
        return this.sendMessage({
            action: "INITIALIZE_UPLOAD",
            payload: { sequencingRun, samples }
        })
    }

    sendRequestForSampleFileUploadLink(sampleId: string, sampleFileName: string) {
        return this.sendMessage({
            action: "REQUEST_SAMPLE_RESOURCE_UPLOAD_LINK",
            payload: { sampleId, sampleFileName }
        })
    }

    sendCompleteSampleUploadMessage(sampleId: string) {
        return this.sendMessage({
            action: "COMPLETE_SAMPLE_UPLOAD",
            payload: { sampleId }
        })
    }

    waitForBatchUploadIds(): Promise<Array<string>> {
        const parseBatchUploadIds = (message: { data: string; }) => {
            const { samples: sampleIds } = JSON.parse(message.data)
            this.logger.debug(`batch upload ids have been received: ${sampleIds}`)
            return sampleIds
        }

        const batchUploadIdsPromise =
            this.whenBrokerIsReady
                .then(waitForMessage)
                .then(parseBatchUploadIds)

        this.whenBrokerIsReady = batchUploadIdsPromise.then(() => this.socket)

        return batchUploadIdsPromise
    }

    waitForSampleFileUploadLink(): Promise<string> {
        const parseFileUploadLink = (message: { data: string }) => {
            const { sampleFileUploadLink } = JSON.parse(message.data)
            this.logger.debug(`file upload link has been received: ${sampleFileUploadLink}`)
            return sampleFileUploadLink
        }

        const sampleFileUploadLinkPromise =
            this.whenBrokerIsReady
                .then(waitForMessage)
                .then(parseFileUploadLink)

        this.whenBrokerIsReady = sampleFileUploadLinkPromise.then(() => this.socket)
        return sampleFileUploadLinkPromise
    }

    private sendMessage(message: BatchUploadMessage) {

        const sendMessage = (socket: WebSocket) => {
            socket.send(JSON.stringify(message))
            return socket
        }
        this.whenBrokerIsReady = this.whenBrokerIsReady.then(sendMessage)

        return this
    }

    async completeUpload(): Promise<void> {
        this.intentionallyClosed = true
        this.socket.close(1000)
    }
}

type ButchUploadAction = "INITIALIZE_UPLOAD" | "REQUEST_SAMPLE_RESOURCE_UPLOAD_LINK" | "COMPLETE_SAMPLE_UPLOAD"

interface BatchUploadMessage {
    action: ButchUploadAction,
    payload: object
}

// Isn't removed cause it's useful for tests and doesn't take much space
export class FakeMessageBroker implements IBatchUploadMessageBroker {

    private logger = new PrefixedLogger(globalLogger, "Fake batch upload websocket")
    private samples: Array<SampleBlueprint> = []

    sendNewRunBatchUploadInitializationMessage(run: RunBlueprint, samples: Array<SampleBlueprint>) {
        this.logger.debug("sendNewRunBatchUploadInitializationMessage")
        this.samples = samples
        return this
    }

    waitForBatchUploadIds() {
        this.logger.debug("waitForBatchUploadIds")
        return Promise.resolve(this.samples.map((it, i) => i.toString()))
    }

    _sendMessage(_message: BatchUploadMessage) {
        this.logger.debug("_sendMessage")
        return this
    }

    async completeUpload(): Promise<void> {
        return Promise.resolve()
    }

    sendCompleteSampleUploadMessage(_sampleId: string): this {
        return this
    }

    sendRequestForSampleFileUploadLink(_sampleId: string, _sampleFileName: string): this {
        return this
    }

    waitForSampleFileUploadLink(): Promise<string> {
        return Promise.resolve("SOME URL")
    }
}
