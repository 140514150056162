<template>
    <InteractiveDocs :steps="steps"></InteractiveDocs>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { circler } from "@/interactive-docs/mixin"

    export default {
        name: "DataExportDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [ swimlanesOverview, search, notReadyRunCard, readyRunCard, alreadyRunCard ],
            }
        }
    }


    const swimlanesOverview = {
        name: "swimlanesOverview",
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight(document.querySelectorAll(".run-swimlanes-container"))
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение экспорта данных</h3>
                    <p>
                        <b>Предназначение:</b> просмотр готовности запусков к экспорту и экспорт результатов.
                    </p>
                    <br/>
                    <p>
                        Все запуски разделены на 3 группы:
                        <ul class="bullet-list">{
                            [
                                "Не готовы к экспорту. В запуске есть анализы в промежуточном статусе",
                                "Готовы к экспорту. Все анализы в запуске в терминальном статусе, но его результаты не были ни разу экспортированы",
                                "Запуски, результаты которых уже были экспортированы",
                            ].map(text =>
                                (<li class="bullet-list__element">{text}</li>))}
                        </ul>
                    </p>
                    <p>
                        Переход запусков между группами осуществляется автоматически, после выполнения
                        соответствующих условий.
                    </p>
                    <br/>
                    <p>
                        Результаты запусков выдаются в формате CSV, как описано в&nbsp;
                        <a href="/docs/#/?id=Экспорт-результатов"
                           target="_blank"
                           rel="noreferrer noopener">документации</a>.
                        <br/>
                        Файлы с результатами, даже если экспорт идет по 1 запуску, упаковываются в архив.
                        <br/>
                        <i>В режиме интерактивной документации будут скачиваться пустые архивы
                            и статусы запусков не обновляются</i>.
                    </p>
                    <br/>
                    <p>
                        Разделение запусков на группы готовых к экспорту и экспортированных сделано
                        для того, чтобы пользователь знал, по каким запускам отчеты уже были получены.
                    </p>
                </div>
            )
        }
    }

    const search = {
        name: "search",
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight(document.querySelectorAll(".composite-search"))
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение экспорта данных</h3>
                    <p>
                        Фильтры применяются сразу ко всем трем группам запусков.
                        Для открытия списка всех фильтров нужно нажать на иконку в правой части строки поиска.
                    </p>
                </div>
            )
        }
    }

    const notReadyRunCard = {
        name: "notReadyRunCard",
        props: {
            highlighter: Object,
        },
        mounted() {
            this.selectRunWithAnalyzedSamples()
        },
        methods: {
            selectRunWithAnalyzedSamples() {
                this.highlighter.moveHighlight([ document.querySelectorAll("#not-ready-runs-swimlane .run-card")[1] ])
            },
            selectRunWithRunningAnalyses() {
                this.highlighter.moveHighlight([ document.querySelectorAll("#not-ready-runs-swimlane .run-card")[0] ])
            }
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение экспорта данных</h3>
                    <p>
                        Карточка <b>не готового</b> запуска включает в себя:
                        <ul class="bullet-list">
                            <li class="bullet-list__element">Имя запуска</li>
                            <li class="bullet-list__element">Полосу прогресса, показывающую сколько
                                анализов находятся в промежуточном состоянии.
                                При наведении на части полосы прогресса, во всплывающих подсказках указано какому
                                статусу они соответствуют.
                                (Есть корреляция с цветом бейджей статусов, но неполная).
                                <p onMouseenter={this.selectRunWithRunningAnalyses}
                                   onMouseleave={this.selectRunWithAnalyzedSamples}
                                   class="dashed-underline"
                                >
                                    <i>
                                        На карточках запусков с образцами в процессе анализа отсутствует полоса
                                        прогресса.
                                    </i>
                                </p>
                            </li>
                            <li class="bullet-list__element">Дату постановки запуска</li>
                        </ul>
                    </p>
                </div>
            )
        }
    }

    const readyRunCard = {
        name: "readyRunCard",
        props: {
            highlighter: Object,
        },
        mixins: [ circler ],
        mounted() {
            this.highlighter.moveHighlight([ document.querySelector("#ready-runs-swimlane .run-card") ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение экспорта данных</h3>
                    <p>
                        Карточка <b>готового</b> запуска включает в себя:
                        <ul class="bullet-list">
                            <li class="bullet-list__element">Имя запуска</li>
                            <li class="bullet-list__element"
                                onMouseenter={() => this.circler.moveHighlight([ document.querySelector("#ready-runs-swimlane .run-card .status-badges") ])}
                                onMouseleave={() => this.circler.clean()}
                            ><span class="dashed-underline">Бейджи с количеством образцов в терминальных статусах</span>.
                                <br/>
                                Стоит обратить обратить внимание, что бейджи для образцов статусах ошибка
                                типирования
                                и лабораторная ошибка имеют одинаковый красный цвет
                            </li>
                            <li class="bullet-list__element">
                                Дату постановки запуска
                            </li>
                            <li class="bullet-list__element dashed-underline"
                                onMouseenter={() => this.circler.moveHighlight([ document.querySelector("#ready-runs-swimlane .run-card [i-docs='export-button']") ])}
                                onMouseleave={() => this.circler.clean()}
                            >Кнопку экспорта результатов
                            </li>
                        </ul>
                    </p>
                </div>
            )
        }
    }

    const alreadyRunCard = {
        name: "alreadyRunCard",
        props: {
            highlighter: Object,
        },
        mounted() {
            this.highlighter.moveHighlight([ document.querySelector("#already-runs-swimlane .run-card") ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Приложение экспорта данных</h3>
                    <p>
                        Карточка <b>экспортированного</b> запуска включает в себя:
                        <ul class="bullet-list">
                            <li class="bullet-list__element">Имя запуска</li>
                            <li class="bullet-list__element">Дату постановки запуска</li>
                            <li class="bullet-list__element">Дату экспорта</li>
                            <li class="bullet-list__element">Кнопки для скачивания результатов</li>
                        </ul>
                    </p>
                </div>
            )
        }
    }
</script>
