import { FunctionalAnnotations } from "@/utils/analysis"

export const annotations: FunctionalAnnotations = {
    "A": {
        "A*01:01:38L": "L",
        "A*01:04": "N",
        "A*01:11N": "N",
        "A*01:123N": "N",
        "A*01:147Q": "Q",
        "A*01:159Q": "Q",
        "A*01:15N": "N",
        "A*01:160N": "N",
        "A*01:162N": "N",
        "A*01:16N": "N",
        "A*01:178N": "N",
        "A*01:179N": "N",
        "A*01:186N": "N",
        "A*01:18N": "N",
        "A*01:208Q": "Q",
        "A*01:228Q": "Q",
        "A*01:22N": "N",
        "A*01:240N": "N",
        "A*01:247N": "N",
        "A*01:248Q": "Q",
        "A*01:250N": "N",
        "A*01:258N": "N",
        "A*01:269N": "N",
        "A*01:27N": "N",
        "A*01:281Q": "Q",
        "A*01:285N": "N",
        "A*01:287N": "N",
        "A*01:290N": "N",
        "A*01:293N": "N",
        "A*01:301Q": "Q",
        "A*01:308N": "N",
        "A*01:31N": "N",
        "A*01:320N": "N",
        "A*01:326N": "N",
        "A*01:328N": "N",
        "A*01:331N": "N",
        "A*01:336N": "N",
        "A*01:52": "N",
        "A*01:53N": "N",
        "A*01:56N": "N",
        "A*01:57N": "N",
        "A*01:87N": "N",
        "A*02:01:14Q": "Q",
        "A*02:113": "N",
        "A*02:125N": "N",
        "A*02:15N": "N",
        "A*02:222N": "N",
        "A*02:223N": "N",
        "A*02:225N": "N",
        "A*02:226N": "N",
        "A*02:227N": "N",
        "A*02:250N": "N",
        "A*02:284N": "N",
        "A*02:293Q": "Q",
        "A*02:301N": "N",
        "A*02:305N": "N",
        "A*02:314N": "N",
        "A*02:321N": "N",
        "A*02:32N": "N",
        "A*02:350N": "N",
        "A*02:356N": "N",
        "A*02:366N": "N",
        "A*02:373N": "N",
        "A*02:395N": "N",
        "A*02:437Q": "Q",
        "A*02:439N": "N",
        "A*02:43N": "N",
        "A*02:440Q": "Q",
        "A*02:468": "N",
        "A*02:476N": "N",
        "A*02:490N": "N",
        "A*02:500Q": "Q",
        "A*02:501N": "N",
        "A*02:506N": "N",
        "A*02:514N": "N",
        "A*02:516N": "N",
        "A*02:525N": "N",
        "A*02:53N": "N",
        "A*02:540N": "N",
        "A*02:581Q": "Q",
        "A*02:605Q": "Q",
        "A*02:608N": "N",
        "A*02:618Q": "Q",
        "A*02:622N": "N",
        "A*02:643N": "N",
        "A*02:672Q": "Q",
        "A*02:675N": "N",
        "A*02:691N": "N",
        "A*02:696N": "N",
        "A*02:710N": "N",
        "A*02:715N": "N",
        "A*02:728Q": "Q",
        "A*02:748N": "N",
        "A*02:760N": "N",
        "A*02:773N": "N",
        "A*02:775N": "N",
        "A*02:788N": "N",
        "A*02:789N": "N",
        "A*02:791N": "N",
        "A*02:792N": "N",
        "A*02:793N": "N",
        "A*02:795Q": "Q",
        "A*02:796N": "N",
        "A*02:797N": "N",
        "A*02:803N": "N",
        "A*02:805Q": "Q",
        "A*02:806N": "N",
        "A*02:807N": "N",
        "A*02:826Q": "Q",
        "A*02:827Q": "Q",
        "A*02:82N": "N",
        "A*02:831N": "N",
        "A*02:832N": "N",
        "A*02:833N": "N",
        "A*02:83N": "N",
        "A*02:858N": "N",
        "A*02:871N": "N",
        "A*02:879N": "N",
        "A*02:880N": "N",
        "A*02:887N": "N",
        "A*02:88N": "N",
        "A*02:895N": "N",
        "A*02:896N": "N",
        "A*02:915N": "N",
        "A*02:94N": "N",
        "A*03:03N": "N",
        "A*03:11N": "N",
        "A*03:129N": "N",
        "A*03:161N": "N",
        "A*03:162N": "N",
        "A*03:168N": "N",
        "A*03:178N": "N",
        "A*03:192N": "N",
        "A*03:197N": "N",
        "A*03:21N": "N",
        "A*03:234Q": "Q",
        "A*03:262N": "N",
        "A*03:266N": "N",
        "A*03:269N": "N",
        "A*03:275N": "N",
        "A*03:279N": "N",
        "A*03:283N": "N",
        "A*03:284N": "N",
        "A*03:286N": "N",
        "A*03:297N": "N",
        "A*03:323N": "N",
        "A*03:329N": "N",
        "A*03:330N": "N",
        "A*03:334N": "N",
        "A*03:335N": "N",
        "A*03:336N": "N",
        "A*03:337N": "N",
        "A*03:342N": "N",
        "A*03:357N": "N",
        "A*03:364N": "N",
        "A*03:36N": "N",
        "A*03:374N": "N",
        "A*03:381N": "N",
        "A*03:388Q": "Q",
        "A*03:68N": "N",
        "A*03:69N": "N",
        "A*03:91N": "N",
        "A*11:109N": "N",
        "A*11:115N": "N",
        "A*11:127N": "N",
        "A*11:137": "N",
        "A*11:170Q": "Q",
        "A*11:180N": "N",
        "A*11:182Q": "Q",
        "A*11:208N": "N",
        "A*11:210N": "N",
        "A*11:215N": "N",
        "A*11:21N": "N",
        "A*11:235Q": "Q",
        "A*11:238N": "N",
        "A*11:251N": "N",
        "A*11:256Q": "Q",
        "A*11:272Q": "Q",
        "A*11:287N": "N",
        "A*11:310N": "N",
        "A*11:313Q": "Q",
        "A*11:340N": "N",
        "A*11:347N": "N",
        "A*11:351Q": "Q",
        "A*11:365N": "N",
        "A*11:50Q": "Q",
        "A*11:52Q": "Q",
        "A*11:69N": "N",
        "A*11:78N": "N",
        "A*11:99N": "N",
        "A*23:07N": "N",
        "A*23:08N": "N",
        "A*23:103N": "N",
        "A*23:106N": "N",
        "A*23:107Q": "Q",
        "A*23:108N": "N",
        "A*23:11N": "N",
        "A*23:19N": "N",
        "A*23:38N": "N",
        "A*23:84N": "N",
        "A*23:91N": "N",
        "A*24:02:03Q": "Q",
        "A*24:09N": "N",
        "A*24:11N": "N",
        "A*24:132N": "N",
        "A*24:155N": "N",
        "A*24:158N": "N",
        "A*24:163N": "N",
        "A*24:183N": "N",
        "A*24:185N": "N",
        "A*24:222N": "N",
        "A*24:232N": "N",
        "A*24:240N": "N",
        "A*24:252N": "N",
        "A*24:278N": "N",
        "A*24:294Q": "Q",
        "A*24:303N": "N",
        "A*24:312N": "N",
        "A*24:323N": "N",
        "A*24:329Q": "Q",
        "A*24:357N": "N",
        "A*24:359N": "N",
        "A*24:36N": "N",
        "A*24:370N": "N",
        "A*24:378Q": "Q",
        "A*24:388N": "N",
        "A*24:389N": "N",
        "A*24:396N": "N",
        "A*24:408N": "N",
        "A*24:40N": "N",
        "A*24:425N": "N",
        "A*24:426N": "N",
        "A*24:427N": "N",
        "A*24:428N": "N",
        "A*24:429N": "N",
        "A*24:430N": "N",
        "A*24:433N": "N",
        "A*24:434N": "N",
        "A*24:435N": "N",
        "A*24:445N": "N",
        "A*24:447Q": "Q",
        "A*24:448N": "N",
        "A*24:450Q": "Q",
        "A*24:456N": "N",
        "A*24:45N": "N",
        "A*24:467N": "N",
        "A*24:473Q": "Q",
        "A*24:479Q": "Q",
        "A*24:48N": "N",
        "A*24:60N": "N",
        "A*24:83N": "N",
        "A*24:84N": "N",
        "A*24:86N": "N",
        "A*24:90": "N",
        "A*25:12N": "N",
        "A*25:42N": "N",
        "A*25:49N": "N",
        "A*26:107N": "N",
        "A*26:11N": "N",
        "A*26:127N": "N",
        "A*26:145N": "N",
        "A*26:161N": "N",
        "A*26:166Q": "Q",
        "A*26:180N": "N",
        "A*26:191N": "N",
        "A*26:25N": "N",
        "A*26:60N": "N",
        "A*26:71N": "N",
        "A*29:08N": "N",
        "A*29:112N": "N",
        "A*29:126Q": "Q",
        "A*29:78N": "N",
        "A*30:101Q": "Q",
        "A*30:121N": "N",
        "A*30:123N": "N",
        "A*30:130N": "N",
        "A*30:132N": "N",
        "A*30:145N": "N",
        "A*30:14L": "L",
        "A*30:158N": "N",
        "A*30:27N": "N",
        "A*30:59N": "N",
        "A*30:70N": "N",
        "A*30:73N": "N",
        "A*30:76N": "N",
        "A*30:78N": "N",
        "A*31:126N": "N",
        "A*31:131N": "N",
        "A*31:141N": "N",
        "A*31:149N": "N",
        "A*31:14N": "N",
        "A*31:151Q": "Q",
        "A*31:158N": "N",
        "A*31:60N": "N",
        "A*32:101Q": "Q",
        "A*32:112N": "N",
        "A*32:117N": "N",
        "A*32:11Q": "Q",
        "A*32:126N": "N",
        "A*32:130N": "N",
        "A*32:132N": "N",
        "A*32:133N": "N",
        "A*32:135N": "N",
        "A*32:19N": "N",
        "A*32:27N": "N",
        "A*32:45N": "N",
        "A*32:48N": "N",
        "A*32:56N": "N",
        "A*32:92N": "N",
        "A*33:03:03Q": "Q",
        "A*33:123N": "N",
        "A*33:129N": "N",
        "A*33:140N": "N",
        "A*33:143N": "N",
        "A*33:154N": "N",
        "A*33:156N": "N",
        "A*33:157N": "N",
        "A*33:174N": "N",
        "A*33:175Q": "Q",
        "A*33:176N": "N",
        "A*33:194N": "N",
        "A*33:198N": "N",
        "A*33:73N": "N",
        "A*33:74N": "N",
        "A*33:80N": "N",
        "A*33:96N": "N",
        "A*34:10N": "N",
        "A*66:26Q": "Q",
        "A*66:27N": "N",
        "A*66:28N": "N",
        "A*66:39N": "N",
        "A*68:11N": "N",
        "A*68:120N": "N",
        "A*68:142N": "N",
        "A*68:148Q": "Q",
        "A*68:159Q": "Q",
        "A*68:171": "N",
        "A*68:181N": "N",
        "A*68:182N": "N",
        "A*68:18N": "N",
        "A*68:199N": "N",
        "A*68:203N": "N",
        "A*68:205N": "N",
        "A*68:210N": "N",
        "A*68:213N": "N",
        "A*68:216N": "N",
        "A*68:236N": "N",
        "A*68:49N": "N",
        "A*68:59N": "N",
        "A*68:94N": "N",
        "A*74:12N": "N",
        "A*74:14N": "N",
        "A*74:32N": "N"
    },
    "B": {
        "B*07:111N": "N",
        "B*07:135N": "N",
        "B*07:161N": "N",
        "B*07:167N": "N",
        "B*07:181N": "N",
        "B*07:182N": "N",
        "B*07:201N": "N",
        "B*07:231N": "N",
        "B*07:251N": "N",
        "B*07:272N": "N",
        "B*07:285N": "N",
        "B*07:315N": "N",
        "B*07:316N": "N",
        "B*07:318N": "N",
        "B*07:325N": "N",
        "B*07:330N": "N",
        "B*07:343N": "N",
        "B*07:351N": "N",
        "B*07:360Q": "Q",
        "B*07:373N": "N",
        "B*07:374N": "N",
        "B*07:386N": "N",
        "B*07:44N": "N",
        "B*07:49N": "N",
        "B*07:67N": "N",
        "B*08:08N": "N",
        "B*08:148N": "N",
        "B*08:19N": "N",
        "B*08:214N": "N",
        "B*08:215N": "N",
        "B*08:220N": "N",
        "B*08:236N": "N",
        "B*08:252N": "N",
        "B*08:30N": "N",
        "B*08:67": "N",
        "B*08:72N": "N",
        "B*08:82N": "N",
        "B*08:86N": "N",
        "B*13:07N": "N",
        "B*13:103N": "N",
        "B*13:116N": "N",
        "B*13:123Q": "Q",
        "B*13:137N": "N",
        "B*13:139N": "N",
        "B*13:49N": "N",
        "B*13:56": "N",
        "B*13:63N": "N",
        "B*13:76N": "N",
        "B*14:07N": "N",
        "B*14:41N": "N",
        "B*14:69N": "N",
        "B*14:70Q": "Q",
        "B*14:72N": "N",
        "B*14:76N": "N",
        "B*14:78N": "N",
        "B*14:79N": "N",
        "B*14:85N": "N",
        "B*15:111N": "N",
        "B*15:149N": "N",
        "B*15:181N": "N",
        "B*15:182N": "N",
        "B*15:190N": "N",
        "B*15:209N": "N",
        "B*15:218Q": "Q",
        "B*15:226N": "N",
        "B*15:245": "Q",
        "B*15:246N": "N",
        "B*15:258N": "N",
        "B*15:262N": "N",
        "B*15:26N": "N",
        "B*15:272N": "N",
        "B*15:294N": "N",
        "B*15:302N": "N",
        "B*15:304N": "N",
        "B*15:321Q": "Q",
        "B*15:375N": "N",
        "B*15:377Q": "Q",
        "B*15:380N": "N",
        "B*15:400N": "N",
        "B*15:454N": "N",
        "B*15:463N": "N",
        "B*15:483N": "N",
        "B*15:487N": "N",
        "B*15:496N": "N",
        "B*15:520Q": "Q",
        "B*15:528N": "N",
        "B*15:540N": "N",
        "B*15:544N": "N",
        "B*15:546Q": "Q",
        "B*15:549N": "N",
        "B*15:562N": "N",
        "B*15:79N": "N",
        "B*15:94N": "N",
        "B*18:106Q": "Q",
        "B*18:138N": "N",
        "B*18:154N": "N",
        "B*18:17N": "N",
        "B*18:182N": "N",
        "B*18:23N": "N",
        "B*18:74N": "N",
        "B*18:94N": "N",
        "B*27:176N": "N",
        "B*27:185Q": "Q",
        "B*27:212N": "N",
        "B*27:215N": "N",
        "B*27:223N": "N",
        "B*27:225N": "N",
        "B*27:59N": "N",
        "B*27:64N": "N",
        "B*27:65N": "N",
        "B*27:66N": "N",
        "B*27:94N": "N",
        "B*35:129N": "N",
        "B*35:130N": "N",
        "B*35:134N": "N",
        "B*35:145N": "N",
        "B*35:165N": "N",
        "B*35:173": "N",
        "B*35:216N": "N",
        "B*35:333Q": "Q",
        "B*35:381N": "N",
        "B*35:390N": "N",
        "B*35:40N": "N",
        "B*35:427N": "N",
        "B*35:428Q": "Q",
        "B*35:430N": "N",
        "B*35:448N": "N",
        "B*35:453N": "N",
        "B*35:456N": "N",
        "B*35:459N": "N",
        "B*35:461N": "N",
        "B*35:53N": "N",
        "B*35:65Q": "Q",
        "B*37:03N": "N",
        "B*37:16Q": "Q",
        "B*37:30N": "N",
        "B*37:33N": "N",
        "B*37:42N": "N",
        "B*37:79N": "N",
        "B*37:82N": "N",
        "B*37:86N": "N",
        "B*38:165N": "N",
        "B*38:34N": "N",
        "B*38:55Q": "Q",
        "B*38:68L": "L",
        "B*38:80N": "N",
        "B*38:83N": "N",
        "B*39:116N": "N",
        "B*39:133N": "N",
        "B*39:139N": "N",
        "B*39:142N": "N",
        "B*39:146N": "N",
        "B*39:147N": "N",
        "B*39:150Q": "Q",
        "B*39:157N": "N",
        "B*39:161N": "N",
        "B*39:25N": "N",
        "B*39:38Q": "Q",
        "B*39:40": "N",
        "B*39:87N": "N",
        "B*39:95N": "N",
        "B*39:97N": "N",
        "B*40:118N": "N",
        "B*40:133Q": "Q",
        "B*40:142N": "N",
        "B*40:144N": "N",
        "B*40:155": "N",
        "B*40:216N": "N",
        "B*40:22N": "N",
        "B*40:256N": "N",
        "B*40:263N": "N",
        "B*40:265N": "N",
        "B*40:286N": "N",
        "B*40:291N": "N",
        "B*40:337N": "N",
        "B*40:338N": "N",
        "B*40:345N": "N",
        "B*40:361N": "N",
        "B*40:372N": "N",
        "B*40:399N": "N",
        "B*40:421Q": "Q",
        "B*40:426N": "N",
        "B*40:428N": "N",
        "B*40:438N": "N",
        "B*41:45N": "N",
        "B*41:56Q": "Q",
        "B*44:108N": "N",
        "B*44:138Q": "Q",
        "B*44:149N": "N",
        "B*44:160Q": "Q",
        "B*44:171N": "N",
        "B*44:195N": "N",
        "B*44:198N": "N",
        "B*44:19N": "N",
        "B*44:217N": "N",
        "B*44:237N": "N",
        "B*44:23N": "N",
        "B*44:267N": "N",
        "B*44:303N": "N",
        "B*44:306N": "N",
        "B*44:309N": "N",
        "B*44:310N": "N",
        "B*44:314N": "N",
        "B*44:328N": "N",
        "B*44:333N": "N",
        "B*44:334N": "N",
        "B*44:341N": "N",
        "B*44:345N": "N",
        "B*44:438N": "N",
        "B*44:448N": "N",
        "B*44:449N": "N",
        "B*44:466N": "N",
        "B*44:480Q": "Q",
        "B*44:52N": "N",
        "B*44:56N": "N",
        "B*44:58N": "N",
        "B*44:61N": "N",
        "B*46:07N": "N",
        "B*46:15N": "N",
        "B*46:41N": "N",
        "B*46:51Q": "Q",
        "B*46:55N": "N",
        "B*46:79N": "N",
        "B*49:19N": "N",
        "B*49:60N": "N",
        "B*51:110N": "N",
        "B*51:118N": "N",
        "B*51:11N": "N",
        "B*51:149N": "N",
        "B*51:173Q": "Q",
        "B*51:178N": "N",
        "B*51:184N": "N",
        "B*51:235N": "N",
        "B*51:245N": "N",
        "B*51:256N": "N",
        "B*51:264N": "N",
        "B*51:273N": "N",
        "B*51:27N": "N",
        "B*51:287N": "N",
        "B*51:306N": "N",
        "B*51:313N": "N",
        "B*51:41N": "N",
        "B*51:44N": "N",
        "B*51:98N": "N",
        "B*52:49N": "N",
        "B*52:89N": "N",
        "B*52:94N": "N",
        "B*52:96N": "N",
        "B*53:48N": "N",
        "B*54:05N": "N",
        "B*54:08N": "N",
        "B*55:55N": "N",
        "B*55:83N": "N",
        "B*55:89N": "N",
        "B*55:97N": "N",
        "B*56:19N": "N",
        "B*56:28N": "N",
        "B*56:38N": "N",
        "B*57:122N": "N",
        "B*57:130N": "N",
        "B*57:28N": "N",
        "B*57:79N": "N",
        "B*57:98Q": "Q",
        "B*58:10N": "N",
        "B*58:17N": "N",
        "B*58:31N": "N",
        "B*58:39N": "N",
        "B*58:72N": "N",
        "B*58:93N": "N",
        "B*58:94N": "N",
        "B*59:10N": "N",
        "B*81:04N": "N"
    },
    "C": {
        "C*01:109N": "N",
        "C*01:111N": "N",
        "C*01:117N": "N",
        "C*01:121Q": "Q",
        "C*01:137N": "N",
        "C*01:143N": "N",
        "C*01:145N": "N",
        "C*01:171N": "N",
        "C*01:181N": "N",
        "C*01:185Q": "Q",
        "C*01:37N": "N",
        "C*01:56N": "N",
        "C*01:69N": "N",
        "C*01:86N": "N",
        "C*01:89N": "N",
        "C*01:98N": "N",
        "C*02:105N": "N",
        "C*02:121N": "N",
        "C*02:135N": "N",
        "C*02:150N": "N",
        "C*02:165N": "N",
        "C*02:169N": "N",
        "C*02:25Q": "Q",
        "C*02:38": "N",
        "C*02:52N": "N",
        "C*02:67Q": "Q",
        "C*02:92N": "N",
        "C*03:121N": "N",
        "C*03:169Q": "Q",
        "C*03:189N": "N",
        "C*03:201N": "N",
        "C*03:208N": "N",
        "C*03:20N": "N",
        "C*03:224N": "N",
        "C*03:229N": "N",
        "C*03:22Q": "Q",
        "C*03:23N": "N",
        "C*03:244Q": "Q",
        "C*03:265N": "N",
        "C*03:277N": "N",
        "C*03:316N": "N",
        "C*03:318N": "N",
        "C*03:323N": "N",
        "C*03:363N": "N",
        "C*03:366N": "N",
        "C*03:377N": "N",
        "C*03:380N": "N",
        "C*03:391N": "N",
        "C*03:392N": "N",
        "C*03:396N": "N",
        "C*03:421N": "N",
        "C*03:424N": "N",
        "C*03:432N": "N",
        "C*03:442Q": "Q",
        "C*03:444N": "N",
        "C*03:445N": "N",
        "C*03:446N": "N",
        "C*03:447N": "N",
        "C*03:448Q": "Q",
        "C*03:449N": "N",
        "C*03:462N": "N",
        "C*03:463N": "N",
        "C*03:470N": "N",
        "C*03:508N": "N",
        "C*03:509N": "N",
        "C*03:510N": "N",
        "C*03:511N": "N",
        "C*04:09N": "N",
        "C*04:105N": "N",
        "C*04:115N": "N",
        "C*04:123N": "N",
        "C*04:170N": "N",
        "C*04:173N": "N",
        "C*04:191N": "N",
        "C*04:203N": "N",
        "C*04:205N": "N",
        "C*04:215N": "N",
        "C*04:217N": "N",
        "C*04:225N": "N",
        "C*04:233N": "N",
        "C*04:234N": "N",
        "C*04:236N": "N",
        "C*04:253N": "N",
        "C*04:255N": "N",
        "C*04:279N": "N",
        "C*04:300N": "N",
        "C*04:305N": "N",
        "C*04:309N": "N",
        "C*04:338Q": "Q",
        "C*04:349N": "N",
        "C*04:350N": "N",
        "C*04:362N": "N",
        "C*04:364N": "N",
        "C*04:365N": "N",
        "C*04:369N": "N",
        "C*04:371N": "N",
        "C*04:374N": "N",
        "C*04:377N": "N",
        "C*04:382Q": "Q",
        "C*04:385N": "N",
        "C*04:396N": "N",
        "C*04:410N": "N",
        "C*04:411N": "N",
        "C*04:59Q": "Q",
        "C*04:88N": "N",
        "C*04:93N": "N",
        "C*04:95N": "N",
        "C*05:07N": "N",
        "C*05:113N": "N",
        "C*05:128N": "N",
        "C*05:153N": "N",
        "C*05:154N": "N",
        "C*05:169N": "N",
        "C*05:175N": "N",
        "C*05:180N": "N",
        "C*05:202Q": "Q",
        "C*05:208N": "N",
        "C*05:213N": "N",
        "C*05:48N": "N",
        "C*05:51Q": "Q",
        "C*05:91N": "N",
        "C*05:92N": "N",
        "C*05:99N": "N",
        "C*06:116N": "N",
        "C*06:128N": "N",
        "C*06:134N": "N",
        "C*06:152N": "N",
        "C*06:16N": "N",
        "C*06:171": "N",
        "C*06:175N": "N",
        "C*06:200Q": "Q",
        "C*06:208N": "N",
        "C*06:211": "N",
        "C*06:215N": "N",
        "C*06:220N": "N",
        "C*06:257N": "N",
        "C*06:259N": "N",
        "C*06:263N": "N",
        "C*06:267N": "N",
        "C*06:281N": "N",
        "C*06:285Q": "Q",
        "C*06:46N": "N",
        "C*06:49N": "N",
        "C*06:74Q": "Q",
        "C*06:79N": "N",
        "C*07:104N": "N",
        "C*07:121Q": "Q",
        "C*07:150Q": "Q",
        "C*07:152N": "N",
        "C*07:164N": "N",
        "C*07:191N": "N",
        "C*07:198N": "N",
        "C*07:226Q": "Q",
        "C*07:227N": "N",
        "C*07:235Q": "Q",
        "C*07:264N": "N",
        "C*07:329N": "N",
        "C*07:32N": "N",
        "C*07:33N": "N",
        "C*07:347N": "N",
        "C*07:350N": "N",
        "C*07:393N": "N",
        "C*07:432Q": "Q",
        "C*07:437N": "N",
        "C*07:451N": "N",
        "C*07:452N": "N",
        "C*07:476N": "N",
        "C*07:483N": "N",
        "C*07:484N": "N",
        "C*07:491": "N",
        "C*07:494Q": "Q",
        "C*07:507N": "N",
        "C*07:513Q": "Q",
        "C*07:551N": "N",
        "C*07:55N": "N",
        "C*07:582Q": "Q",
        "C*07:593N": "N",
        "C*07:600": "N",
        "C*07:603N": "N",
        "C*07:61N": "N",
        "C*07:632Q": "Q",
        "C*07:633N": "N",
        "C*07:663Q": "Q",
        "C*07:672N": "N",
        "C*07:675N": "N",
        "C*07:686N": "N",
        "C*07:690N": "N",
        "C*07:697Q": "Q",
        "C*07:702N": "N",
        "C*07:713Q": "Q",
        "C*07:726N": "N",
        "C*07:733N": "N",
        "C*07:743N": "N",
        "C*07:745N": "N",
        "C*07:746N": "N",
        "C*07:747N": "N",
        "C*07:749N": "N",
        "C*07:750N": "N",
        "C*07:751N": "N",
        "C*07:752N": "N",
        "C*07:753N": "N",
        "C*07:754N": "N",
        "C*07:770N": "N",
        "C*07:773N": "N",
        "C*07:776N": "N",
        "C*07:787N": "N",
        "C*07:796N": "N",
        "C*07:797N": "N",
        "C*07:804N": "N",
        "C*07:807N": "N",
        "C*07:808N": "N",
        "C*07:819Q": "Q",
        "C*07:820N": "N",
        "C*07:821N": "N",
        "C*07:839N": "N",
        "C*07:840N": "N",
        "C*07:849N": "N",
        "C*07:98N": "N",
        "C*08:121N": "N",
        "C*08:127N": "N",
        "C*08:129N": "N",
        "C*08:130N": "N",
        "C*08:141Q": "Q",
        "C*08:161N": "N",
        "C*08:173N": "N",
        "C*08:180N": "N",
        "C*08:181N": "N",
        "C*08:208N": "N",
        "C*08:26N": "N",
        "C*08:36N": "N",
        "C*08:52N": "N",
        "C*08:55N": "N",
        "C*08:70Q": "Q",
        "C*08:88N": "N",
        "C*08:89N": "N",
        "C*12:104N": "N",
        "C*12:105N": "N",
        "C*12:139Q": "Q",
        "C*12:148N": "N",
        "C*12:155Q": "Q",
        "C*12:219N": "N",
        "C*12:232N": "N",
        "C*12:236N": "N",
        "C*12:270N": "N",
        "C*12:274": "N",
        "C*12:295N": "N",
        "C*12:299N": "N",
        "C*12:39N": "N",
        "C*12:42Q": "Q",
        "C*12:46N": "N",
        "C*12:80N": "N",
        "C*12:84N": "N",
        "C*14:07N": "N",
        "C*14:105Q": "Q",
        "C*14:117N": "N",
        "C*14:21N": "N",
        "C*14:35N": "N",
        "C*14:47": "N",
        "C*14:93N": "N",
        "C*14:97N": "N",
        "C*14:99N": "N",
        "C*15:105Q": "Q",
        "C*15:115N": "N",
        "C*15:122N": "N",
        "C*15:145N": "N",
        "C*15:156N": "N",
        "C*15:160N": "N",
        "C*15:164N": "N",
        "C*15:177N": "N",
        "C*15:185N": "N",
        "C*15:188N": "N",
        "C*15:189N": "N",
        "C*15:213N": "N",
        "C*15:216N": "N",
        "C*15:32Q": "Q",
        "C*15:84Q": "Q",
        "C*15:92N": "N",
        "C*15:95N": "N",
        "C*15:96Q": "Q",
        "C*16:123N": "N",
        "C*16:132N": "N",
        "C*16:16Q": "Q",
        "C*16:30N": "N",
        "C*16:77N": "N",
        "C*16:89N": "N",
        "C*17:27N": "N",
        "C*18:07N": "N"
    },
    "DQB1": {
        "DQB1*02:129N": "N",
        "DQB1*02:132N": "N",
        "DQB1*02:134N": "N",
        "DQB1*02:162N": "N",
        "DQB1*02:163N": "N",
        "DQB1*02:167N": "N",
        "DQB1*02:171Q": "Q",
        "DQB1*02:18N": "N",
        "DQB1*02:20N": "N",
        "DQB1*02:53Q": "Q",
        "DQB1*02:58N": "N",
        "DQB1*02:67N": "N",
        "DQB1*02:96N": "N",
        "DQB1*03:118N": "N",
        "DQB1*03:197Q": "Q",
        "DQB1*03:213N": "N",
        "DQB1*03:237N": "N",
        "DQB1*03:269N": "N",
        "DQB1*03:276N": "N",
        "DQB1*03:282N": "N",
        "DQB1*03:303N": "N",
        "DQB1*03:310N": "N",
        "DQB1*03:334N": "N",
        "DQB1*03:338N": "N",
        "DQB1*03:339N": "N",
        "DQB1*03:340N": "N",
        "DQB1*03:354N": "N",
        "DQB1*03:356N": "N",
        "DQB1*03:357N": "N",
        "DQB1*03:358N": "N",
        "DQB1*03:375N": "N",
        "DQB1*03:376N": "N",
        "DQB1*03:385N": "N",
        "DQB1*03:399N": "N",
        "DQB1*03:400N": "N",
        "DQB1*03:403N": "N",
        "DQB1*03:407N": "N",
        "DQB1*03:411N": "N",
        "DQB1*03:422N": "N",
        "DQB1*03:427N": "N",
        "DQB1*03:66N": "N",
        "DQB1*03:84N": "N",
        "DQB1*03:90N": "N",
        "DQB1*03:91Q": "Q",
        "DQB1*03:95N": "N",
        "DQB1*03:99Q": "Q",
        "DQB1*04:25N": "N",
        "DQB1*04:36N": "N",
        "DQB1*04:41N": "N",
        "DQB1*04:46N": "N",
        "DQB1*04:59N": "N",
        "DQB1*04:68N": "N",
        "DQB1*04:73N": "N",
        "DQB1*05:110N": "N",
        "DQB1*05:128N": "N",
        "DQB1*05:132Q": "Q",
        "DQB1*05:185N": "N",
        "DQB1*05:206N": "N",
        "DQB1*05:208N": "N",
        "DQB1*05:215N": "N",
        "DQB1*05:224N": "N",
        "DQB1*05:235N": "N",
        "DQB1*05:236N": "N",
        "DQB1*05:41N": "N",
        "DQB1*05:87Q": "Q",
        "DQB1*05:90N": "N",
        "DQB1*06:102N": "N",
        "DQB1*06:112N": "N",
        "DQB1*06:144N": "N",
        "DQB1*06:158N": "N",
        "DQB1*06:179N": "N",
        "DQB1*06:193N": "N",
        "DQB1*06:216N": "N",
        "DQB1*06:252N": "N",
        "DQB1*06:26N": "N",
        "DQB1*06:303N": "N",
        "DQB1*06:304N": "N",
        "DQB1*06:306N": "N",
        "DQB1*06:308N": "N",
        "DQB1*06:317N": "N",
        "DQB1*06:330N": "N",
        "DQB1*06:341N": "N",
        "DQB1*06:345N": "N",
        "DQB1*06:54N": "N",
        "DQB1*06:75N": "N",
        "DQB1*06:77N": "N"
    },
    "DRB1": {
        "DRB1*01:01": "N",
        "DRB1*01:33N": "N",
        "DRB1*01:39N": "N",
        "DRB1*01:40N": "N",
        "DRB1*01:52N": "N",
        "DRB1*01:62": "N",
        "DRB1*01:68N": "N",
        "DRB1*01:91Q": "Q",
        "DRB1*03:156N": "N",
        "DRB1*03:67N": "N",
        "DRB1*03:68N": "N",
        "DRB1*04:119N": "N",
        "DRB1*04:120N": "N",
        "DRB1*04:142N": "N",
        "DRB1*04:157N": "N",
        "DRB1*04:158N": "N",
        "DRB1*04:178N": "N",
        "DRB1*04:186N": "N",
        "DRB1*04:212N": "N",
        "DRB1*04:214N": "N",
        "DRB1*04:247N": "N",
        "DRB1*04:264N": "N",
        "DRB1*04:266N": "N",
        "DRB1*04:267N": "N",
        "DRB1*04:280N": "N",
        "DRB1*04:286N": "N",
        "DRB1*04:299N": "N",
        "DRB1*04:300N": "N",
        "DRB1*04:81N": "N",
        "DRB1*04:94": "N",
        "DRB1*07:101N": "N",
        "DRB1*07:10N": "N",
        "DRB1*07:26N": "N",
        "DRB1*07:58N": "N",
        "DRB1*07:68N": "N",
        "DRB1*07:87N": "N",
        "DRB1*08:60N": "N",
        "DRB1*08:78N": "N",
        "DRB1*08:89N": "N",
        "DRB1*09:37N": "N",
        "DRB1*11:169N": "N",
        "DRB1*11:217N": "N",
        "DRB1*11:246N": "N",
        "DRB1*11:248Q": "Q",
        "DRB1*11:250N": "N",
        "DRB1*12:24N": "N",
        "DRB1*12:31N": "N",
        "DRB1*12:60N": "N",
        "DRB1*12:72N": "N",
        "DRB1*12:74N": "N",
        "DRB1*13:113N": "N",
        "DRB1*13:137N": "N",
        "DRB1*13:142N": "N",
        "DRB1*13:185N": "N",
        "DRB1*13:200N": "N",
        "DRB1*13:249N": "N",
        "DRB1*13:252N": "N",
        "DRB1*13:255N": "N",
        "DRB1*13:268N": "N",
        "DRB1*13:278Q": "Q",
        "DRB1*13:289N": "N",
        "DRB1*13:295N": "N",
        "DRB1*13:298N": "N",
        "DRB1*14:137N": "N",
        "DRB1*14:152N": "N",
        "DRB1*14:166N": "N",
        "DRB1*14:188N": "N",
        "DRB1*14:195N": "N",
        "DRB1*14:197N": "N",
        "DRB1*14:210Q": "Q",
        "DRB1*14:92N": "N",
        "DRB1*15:113N": "N",
        "DRB1*15:115N": "N",
        "DRB1*15:129N": "N",
        "DRB1*15:134N": "N",
        "DRB1*15:137N": "N",
        "DRB1*15:138N": "N",
        "DRB1*15:148N": "N",
        "DRB1*15:154N": "N",
        "DRB1*15:159N": "N",
        "DRB1*15:163N": "N",
        "DRB1*15:164Q": "Q",
        "DRB1*15:176N": "N",
        "DRB1*15:17N": "N",
        "DRB1*15:180N": "N",
        "DRB1*15:183N": "N",
        "DRB1*15:50N": "N",
        "DRB1*15:80N": "N",
        "DRB1*16:13N": "N",
        "DRB1*16:21N": "N",
        "DRB1*16:41N": "N",
        "DRB1*16:55N": "N",
        "DRB1*16:59Q": "Q",
        "DRB1*16:62N": "N",
        "DRB1*16:63N": "N"
    }
}
