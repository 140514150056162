<template>
    <div
        class="missing-results-placeholder"
        v-if="analysisHadTooLittleReadsToBeAnalysed"
    >
        {{ $t("tooLittleReadsToBeAnalysed") }}
    </div>
    <div
        v-else
        class="charts-grid"
    >
        <div class="grid-full-row h-100 w-100 charts-group-loci">
            <chart-container
                v-bind="insertSizeChart"
                class="chart-container"
                id="insert-size-chart"
            />
            <chart-container
                v-bind="readsMeanQualityChart"
                id="mean-reads-quality-chart"
                class="chart-container"
            />
            <chart-container
                v-bind="lociCoverageChart"
                id="loci-coverage-chart"
                class="relative h-100 w-100"
            />
            <chart-container
                v-bind="lociCoverageChartPercent"
                id="loci-coverage-chart-percent"
                class="relative h-100 w-100"
            />
        </div>

        <div
            class="grid-full-row h-100 w-100"
            style="display: flex; flex-flow: column;"
            :key="activeComponent"
        >
            <chart-container
                v-if="activeComponent === 'chart1'"
                v-bind="readsDistributionChart"
                id="reads-distribution-chart"
                class="relative"
                style="flex-shrink: 1; flex-grow: 1"
            />

            <chart-container
                v-if="activeComponent === 'chart2'"
                v-bind="readsRelativeDistributionChart"
                id="reads-relative-distribution-chart"
                class="relative"
                style="flex-shrink: 1; flex-grow: 1"
            />

            <chart-container
                v-if="activeComponent === 'chart3'"
                v-bind="percentagesByLociChart"
                id="percentages-by-loci-chart"
                class="relative"
                style="flex-shrink: 1; flex-grow: 1"
            />

            <div
                class="flex"
                style="width: 90%; margin: 0 auto; flex-shrink: 0; justify-content: space-between"
                v-show="readsDistributionChart.data"
            >
                <div class="flex btn-group">
                    <button
                        class="btn"
                        @click="activeComponent='chart2'"
                        :class="{ active: activeComponent === 'chart2' }"
                    >
                        {{ $t("chart2") }}
                    </button>
                    <button
                        class="btn"
                        @click="activeComponent='chart1'"
                        :class="{ active: activeComponent === 'chart1' }"
                    >
                        {{ $t("chart1") }}
                    </button>
                    <button
                        class="btn"
                        @click="activeComponent='chart3'"
                        :class="{ active: activeComponent === 'chart3' }"
                    >
                        {{ $t("chart3") }}
                    </button>
                </div>

                <div v-if="activeComponent === 'chart1' || activeComponent === 'chart2'" class="flex">
                    <div :key="k" v-for="(_, k) in getSortReadsDistribution"
                         class="mr-1">
                        <div class="flex">
                        <span class="mr-1" style="width: 2rem; height: 2rem; border-radius: 2px;"
                              :style="{'background-color': getColorReadsMetric[k]}"/>
                            {{ $t(`analysisMetrics.${k}`) }}
                        </div>
                    </div>
                </div>

                <div v-if="activeComponent === 'chart3'" class="flex">
                    <div :key="k" v-for="(k) in LOCI"
                         class="mr-1">
                        <div class="flex">
                        <span class="mr-1" style="width: 2rem; height: 2rem; border-radius: 2px;"
                              :style="{'background-color': getColorPercentagesByLociMetric[k]}"/>
                            {{ k }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { drawChart as lociCoverageBoxPlot } from "@/charts/analysis-loci-coverage"
    import { drawChart as lociCoverageBoxPlotPercent } from "@/charts/analysis-loci-coverage-percent"
    import { drawChart as singleValueBoxPlot } from "@/charts/single-metric-boxplot"
    import { drawChart as readsDistributionChart } from "@/charts/reads-distribution-chart"
    import { drawChart as readsRelativeDistributionChart } from "@/charts/reads-relative-distribution-chart"
    import { drawChart as percentagesByLociChart } from "@/charts/percentages-by-loci-chart"
    import { Locales } from "@/i18n/main"
    import { ChartContainer } from "@/components/chart-container"
    import { analysisHasTooLittleReadsToBeAnalysed } from "@/utils/analysis"
    import { LOCI } from "@/genotype"

    export default {
        name: "SupportAnalysisDetails",
        components: { ChartContainer },
        props: {
            analysis: Object,
            locus: String,
        },
        data() {
            return {
                selectedReadsDistributionMetric: "qualityTrimmed",
                activeComponent: "chart2",
            }
        },
        computed: {
            LOCI() {
                return LOCI
            },
            allAnalysesFromRun() {
                return this.$store.state.support.analyses
            },
            readsMeanQualityChart() {
                return {
                    data: {
                        sameRunAnalysesMetric: this.analysesWithData.map(it => it.result.qualityMetrics.meanReadsQuality),
                        currentAnalysisMetric: this.analysis.result.qualityMetrics.meanReadsQuality
                    },
                    chartConstructorFn: (data) => singleValueBoxPlot(
                        data,
                        "#mean-reads-quality-chart",
                        "meanReadsQuality",
                        this.$i18n,
                        "phred",
                        { fractionDigits: 2 }
                    )
                }
            },
            insertSizeChart() {
                return {
                    data: {
                        sameRunAnalysesMetric: this.analysesWithData.map(it => it.result.qualityMetrics.insertSize),
                        currentAnalysisMetric: this.analysis.result.qualityMetrics.insertSize
                    },
                    chartConstructorFn: (data) => singleValueBoxPlot(data, "#insert-size-chart", "insertSize", this.$i18n, "bp")
                }
            },
            lociCoverageChart() {
                return {
                    data: {
                        sameRunAnalyses: this.analysesWithData,
                        currentAnalysis: this.analysis
                    },
                    chartConstructorFn: (data) => lociCoverageBoxPlot(data, "#loci-coverage-chart", this.$i18n)
                }
            },
            lociCoverageChartPercent() {
                return {
                    data: {
                        sameRunAnalyses: this.analysesWithData,
                        currentAnalysis: this.analysis
                    },
                    chartConstructorFn: (data) => lociCoverageBoxPlotPercent(data, "#loci-coverage-chart-percent", this.$i18n)
                }
            },
            getColorReadsMetric() {
                return {
                    targetLoci: "#008000",
                    relatedLoci: "#ffa500",
                    qualityTrimmed: "#ff0000",
                    offTargetRegions: "#0000ff",
                }
            },
            getColorPercentagesByLociMetric() {
                return {
                    A: "#008000",
                    B: "#ffa500",
                    C: "#ff0000",
                    DQB1: "#0000ff",
                    DRB1: "#949191",
                }
            },
            readsDistributionChart() {
                return {
                    data: {
                        sameRunAnalyses: this.analysesWithData.map(it => ({
                            name: it.name,
                            runId: it.runId,
                            isControlSample: it.isControlSample,
                            value: it.result.qualityMetrics.readsDistribution,
                        })),
                        currentAnalysis: this.analysis
                    },
                    chartConstructorFn: (data) => readsDistributionChart(data, "#reads-distribution-chart", this.$i18n, this.getColorReadsMetric)
                }
            },
            readsRelativeDistributionChart() {
                return {
                    data: {
                        sameRunAnalyses: this.analysesWithData.map(it => ({
                            name: it.name,
                            runId: it.runId,
                            isControlSample: it.isControlSample,
                            value: it.result.qualityMetrics.readsDistribution
                        })),
                        currentAnalysis: this.analysis
                    },
                    chartConstructorFn: (data) => readsRelativeDistributionChart(data, "#reads-relative-distribution-chart", this.$i18n, this.getColorReadsMetric)
                }
            },
            percentagesByLociChart() {
                return {
                    data: {
                        sameRunAnalyses: this.analysesWithData.map(it => ({
                            name: it.name,
                            runId: it.runId,
                            isControlSample: it.isControlSample,
                            value: it.result.qualityMetrics.targetLociCoverage
                        })),
                        currentAnalysis: this.analysis
                    },
                    chartConstructorFn: (data) => percentagesByLociChart(data, "#percentages-by-loci-chart", this.$i18n, this.getColorPercentagesByLociMetric)
                }
            },
            analysesWithData() {
                return this.allAnalysesFromRun.filter(it => !analysisHasTooLittleReadsToBeAnalysed(it))
            },
            analysisHadTooLittleReadsToBeAnalysed() {
                return analysisHasTooLittleReadsToBeAnalysed(this.analysis)
            },
            getSortReadsDistribution() {
                return {
                    targetLoci: this.analysis.result.qualityMetrics.readsDistribution.targetLoci,
                    relatedLoci: this.analysis.result.qualityMetrics.readsDistribution.relatedLoci,
                    offTargetRegions: this.analysis.result.qualityMetrics.readsDistribution.offTargetRegions,
                    qualityTrimmed: this.analysis.result.qualityMetrics.readsDistribution.qualityTrimmed,
                }
            }
        },
        methods: {
            toggleReadsDistributionMetric(metric) {
                this.selectedReadsDistributionMetric = metric
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    generalProblems: "General problems",
                    locusSpecificProblems: "Locus specific problems",
                    // I'm not sure that this is really better that No problems.
                    noProblems: "Not found",
                    sample: "Sample",
                    chart1: "Absolute",
                    chart2: "Relative",
                    chart3: "Percentages by loci",
                },
                [Locales.RU]: {
                    generalProblems: "Общие проблемы",
                    locusSpecificProblems: "Локус специфичные проблемы",
                    noProblems: "Не обнаружены",
                    sample: "Образец",
                    chart1: "Абсолютный",
                    chart2: "Относительный",
                    chart3: "Проценты по локусам",
                }
            }
        }
    }
</script>

<style scoped>
    .charts-grid {
        height: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
        align-items: center;
        justify-items: center;
    }

    .chart-container {
        width: 20rem;
        height: 100%;
        position: relative;
    }

    .charts-group-loci {
        display: grid;
        grid-template-columns: 25rem 25rem 1fr 1fr;
        justify-content: space-between;
    }

    .missing-results-placeholder {
        display: flex;
        padding: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 2rem;
        color: var(--grey-700);
    }

    .btn-group {
        font-size: 1.2rem;
        border: 1px solid #3949AB;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 2rem;
    }

    .btn-group .btn {
        border-radius: 0;
        padding: .5rem 1rem;
        height: 100%;
        text-transform: uppercase;
        color: #3949AB;
    }

    .btn-group .btn:not(:last-child) {
        border-right: 1px solid #3949AB;
    }

    .btn-group .btn.active {
        background-color: #4169e1;
        color: white;
    }
</style>
