<template>
    <div class="analysis-card relative">
        <p-modal v-if="modals['detailsModal'].opened">
            <div class="analysis-details-modal">
                <analysis-details :analysis="analysis"></analysis-details>
                <button @click="closeModal('detailsModal')"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <div class="analysis--description">
            <span id="sequencing-run-date">{{ $d(analysis.sequencingRunDate) }}</span>
            <h1 class="mb-1"
                id="analysis-name"
                style="display: inline; text-align: center; word-break: break-all">{{ analysis.name }}</h1>
            <button @click="openModal('detailsModal')"
                    id="qc-details-btn"
                    class="md-btn-text"
                    style="justify-self: right; color: royalblue; position: relative"
            >
                {{ $t("qcDetails") }}<sup class="problems-indicator"
                                          v-if="analysis.result.quality !== 'green'"></sup>
            </button>

            <h2 class="ellipsis"
                style="font-weight: 400; display: inline"
                id="run-name"
            >{{ analysis.sequencingRunId }}</h2>
            <h2 id="sample-id-in-run"
                style="font-weight: 400; display: inline;
                                   justify-self: center">S{{ analysis.runId }}</h2>
            <div style="justify-self: right; margin-right: 1.5rem">
                <analysis-lot-badge id="lot-badge"
                          :control-sample-status="analysis.reagentKitLotQcStatus"
                          :lot-name="analysis.reagentKitLotName"
                          :configured-pipeline-name="analysis.configuredPipelineName"
                          :imgt-version="analysis.configuredPipelineImgtVersion"
                />
            </div>
        </div>
        <GenotypesTable :genotypes="analysis.result.genotypes"
                        :annotations="analysis.result.annotations || {}"
                        class="analysis--genotypes-table"/>
        <ImgtVersionLink :imgt-version="analysis.configuredPipelineImgtVersion"/>
    </div>
</template>

<script>
    import AnalysisLotBadge from "@/components/analysis/lot-badge"
    import GenotypesTable from "@/components/analysis/genotypes-table"
    import AnalysisDetails from "@/components/analysis/details"
    import { modalMixin } from "@/mixins/modal-mixin"
    import ImgtVersionLink from "@/components/imgt-version-link"

    export default {
        name: "AnalysisCard",
        mixins: [ modalMixin ],
        components: {
            ImgtVersionLink,
            AnalysisDetails,
            AnalysisLotBadge,
            GenotypesTable
        },
        props: {
            analysis: Object
        },
        data() {
            return {
                modals: {
                    "detailsModal": {
                        opened: false,
                        onOpen: () => this.$emit("modal-opened"),
                        onClose: () => this.$emit("modal-closed")
                    }
                }
            }
        }
    }
</script>

<style scoped>

    .analysis-card {
        border-radius: 4px;
        border: 1px solid var(--grey-400);
        padding: 2rem 5rem 3rem 5rem;
    }

    .analysis--description {
        align-self: start;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-row-gap: 1rem;
        align-items: center;
        margin-bottom: 4rem;
    }

    .analysis-details-modal {
        padding: 2em;
        padding-bottom: 1em;
    }

    .analysis--genotypes-table {
        font-size: 2rem;
        text-align: center;
        width: 100%;
    }

    @media screen and (max-width: 75em) {
        .analysis-card {
            padding: 3rem;
        }
    }

    .problems-indicator {
        position: absolute;
        border-radius: 50%;
        right: .5rem;
        width: .8rem;
        height: .8rem;
        background-color: var(--yellow);
        align-self: flex-start;
        margin-left: .5rem;
    }
</style>
