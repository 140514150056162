/*
 * Validators are moved here by 2 reasons
 *   1. To ease testing, imports from separate file are better than import from components
 *   2. I can gather all validation errors here to simplify their translation.
 *
 * Name validators factory like create${something}Validator for consistency
 */
import { isEmpty } from "lodash"
import { runsActionNames, runsGetterNames } from "@/store/modules/runs"
import { Store } from "vuex"
import { FUNCTIONAL_ANNOTATIONS, LOCI, NEW_ALLELE_PLACEHOLDER } from "@/genotype"
import { lotManagementGetterNames } from "@/store/modules/lot-management"
import { StandaloneValidator, Validator } from "@/validation/validatable-input"

export enum ValidationError {
    FlowsFormatError = "FlowsFormatError",
    Required = "Required",
    ExistingRunId = "ExistingRunId",
    InvalidAlleleFormat = "InvalidAlleleFormat",
    ExistingLotName = "ExistingLotName",
    InvalidLotName = "InvalidLotName",
    LotDoesNotExist = "LotDoesNotExist",
    AlleleAlreadyExists = "AlleleAlreadyExists",
    WrongAlleleFormatAtSupport = "WrongAlleleFormatAtSupport"
}

export function createRunIdValidator(store: Store<any>): Validator<{ id: string }, ValidationError> {
    return (modelUpdate) => {
        if ("id" in modelUpdate) {
            const requiredError = _isEmpty(modelUpdate.id) ? [ ValidationError.Required ] : []

            if (requiredError.length === 0) {
                // Very straightforward implementation to avoid duplication of api calls. Could be optimized.
                return store.dispatch(runsActionNames.startNewSearch, { filter: { name: `equals:${modelUpdate.id}` } })
                    .then(() => {
                        return store.getters[runsGetterNames.resourceList].length !== 0
                            ? [ ValidationError.ExistingRunId ]
                            : []
                    })
            } else {
                return Promise.resolve(requiredError)
            }
        } else {
            return undefined
        }
    }
}

export function validateFlows(modelUpdate: { flows?: string }): Promise<Array<ValidationError>> | undefined {
    if ("flows" in modelUpdate) {
        /*
         Required validation doesn't make much sense, because empty value won't pass format check,
         and it's message conveys more information
        */
        return Promise.resolve(
            // eslint-disable-next-line unicorn/no-unsafe-regex
            /^\d+\s*(\+\s*\d+)?$/.test(modelUpdate.flows!)
                ? []
                : [ ValidationError.FlowsFormatError ]
        )
    } else {
        return undefined
    }
}

export function required(fieldName: string) {
    return (modelUpdate: any) => {
        if (fieldName in modelUpdate) {
            return requiredStandalone(modelUpdate[fieldName])
        } else {
            return undefined
        }
    }
}

export function requiredStandalone<Value>(newValue: Value) {
    return _isEmpty(newValue)
        ? [ ValidationError.Required ]
        : []
}


export function validateAllele(modelUpdate: { allele?: string }) {
    if ("allele" in modelUpdate) {
        return validateAlleleStandalone(modelUpdate.allele!)
    } else {
        return undefined
    }
}

export function validateAlleleStandalone(newValue: string) {
    const anyValueOf = (values: string[]) => values.join("|")
    const anyLocus = anyValueOf(LOCI)
    const anyFunctionalAnnotation = anyValueOf(FUNCTIONAL_ANNOTATIONS)
    const anyUnambiguousImgtIdentifier = `\\d+(:\\d+){0,3}(${anyFunctionalAnnotation})?`
    const anyImgtIdentifier = `${anyUnambiguousImgtIdentifier}(/${anyUnambiguousImgtIdentifier})*`
    const anyLocusDefinedAlleleGlString = `((${anyImgtIdentifier})|${NEW_ALLELE_PLACEHOLDER})`
    const anyLocusAlleleFunctionalAnnotation = `(${anyFunctionalAnnotation})`
    return Promise.resolve(
        (new RegExp(`^(${anyLocus})((\\*${anyLocusDefinedAlleleGlString})|(@${anyLocusAlleleFunctionalAnnotation}))$`)).test(newValue)
            ? []
            : [ ValidationError.InvalidAlleleFormat ]
    )
}

function _isEmpty(v: any): boolean {
    // `isEmpty` consider Date empty cause it does not have any keys — https://stackoverflow.com/a/54713916/6540091
    return (v instanceof Object && !(v instanceof Date))
        // Eliminates null, {} and []
        ? isEmpty(v)
        // Eliminates undefined, NaN and empty string
        : ((v !== 0) && (v !== false) && !v)
}

export function createLotNameValidator(store: Store<any>): Validator<{ name: string }, ValidationError> {
    return (modelUpdate) => {
        if ("name" in modelUpdate) {
            const requiredError = _isEmpty(modelUpdate.name) ? [ ValidationError.Required ] : []

            if (requiredError.length === 0) {
                if (!/^\d{4}-[1-9]\d*/.test(modelUpdate.name!)) {
                    return Promise.resolve([ ValidationError.InvalidLotName ])
                } else {
                    // Very straightforward implementation to avoid duplication of api calls. Could be optimized.
                    return Promise.resolve(store.getters[lotManagementGetterNames.lots].find((it: { name: string }) => it.name === modelUpdate.name)
                        ? [ ValidationError.ExistingLotName ]
                        : [])
                }
            } else {
                return Promise.resolve(requiredError)
            }
        } else {
            return undefined
        }
    }
}

/**
 * Validate that allele in the correct format is entered and
 * it's not in the given list of alleles (We shouldn't duplicate alleles)
 *
 * @param presentAlleles alleles already present in tool results
 */
export function createAlleleValidator(presentAlleles: string[]) {
    return async ({ existingAllele }: { existingAllele: string }) => {
        if (!existingAllele) {
            return Promise.resolve([ ValidationError.Required ])
        }

        const nameValidationResult = await validateAlleleName(existingAllele, true)
        if (nameValidationResult.length !== 0) {
            return nameValidationResult
        }

        if (presentAlleles.includes(existingAllele)) {
            return Promise.resolve([ ValidationError.AlleleAlreadyExists ])
        }
        return Promise.resolve([])
    }
}

export function createReferenceAlleleValidator() {
    return async ({ referenceAllele }: { referenceAllele: string }) => {
        if (!referenceAllele) {
            return Promise.resolve([ ValidationError.Required ])
        }

        const nameValidationResult = await validateAlleleName(referenceAllele)
        if (nameValidationResult.length !== 0) {
            return nameValidationResult
        }

        return Promise.resolve([])
    }
}


export function validateAlleleName(value: string, isAllowLetters?: boolean) {
    const unambiguousAlleleRegex = isAllowLetters ? "[a-zA-Z0-9]+(:[a-zA-Z0-9]+){0,3}" : "\\d+(:\\d+){0,3}"
    const alleleRegex = `^${unambiguousAlleleRegex}(/${unambiguousAlleleRegex})*$`
    return Promise.resolve(
        !value.match(alleleRegex)
            ? [ ValidationError.WrongAlleleFormatAtSupport ]
            : []
    )
}

export function composeWaitingForAll<T>(
    ...validators: Array<StandaloneValidator<T>>
): StandaloneValidator<T> {
    return (value: T) =>
        Promise.all(
            validators.map(it => it(value))
        ).then(
            results => results.reduce((acc, it) => acc.concat(it), [])
        )
}

export function leaveOnlyFirst<T>(validator: StandaloneValidator<T>): StandaloneValidator<T> {
    return async (value: T) => (await validator(value))?.slice(0, 1)
}
