<template>
    <div :class="analysisStatus"
         class="badge">
        {{ $t(`analysisStatuses.${analysisStatus}`).toUpperCase() }}
    </div>
</template>

<script>
    export default {
        name: "AnalysisStatusBadge",
        props: {
            analysisStatus: String
        }
    }
</script>

<style scoped>
    .badge.RUNNING, .badge.IN_SUPPORT {
        border: 2px solid var(--grey-400);
        font-weight: 600;
        color: var(--grey-400);
    }

    .badge.AWAITING_REVIEW {
        border: 2px solid var(--green);
        font-weight: 600;
        color: var(--green);
    }

    .badge.COMPLETED {
        background-color: var(--green);
        font-weight: 600;
        color: white;
    }

    .badge.AWAITING_APPROVE {
        border: 2px solid var(--yellow);
        font-weight: 600;
        color: var(--yellow);
    }

    .badge.LAB_FAILURE, .badge.TYPING_FAULT {
        background-color: var(--red);
        font-weight: 600;
        color: white;
    }

    .badge.ERROR {
        background-color: var(--grey-400);
        font-weight: 600;
        color: white;
    }
</style>
