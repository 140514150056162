<template>
    <div v-if="!loading" class="p-1" style="font-weight: 400;">
        <div class="overflow-auto custom-scroll">
            <div class="flex mb-2" style="justify-content: space-between;">
                <h2>
                <div class="flex">
                    <span class="ml-1" style="margin-right: 5rem;">{{locusName}}</span>
                    <div
                        class="monospace bold flex"
                        style="padding: .5rem 0rem; font-weight: 400;"
                    >
                        <span class="flex">
                            <span
                                :style="{ color: alleleIndex === 0 ? 'var(--yellow)' : '', border: alleleIndex !== 0 ? 'none' : '' }"
                                :class="{ selected: alleleIndex === 0 }"
                                class="allele"
                            >
                                <span class="allele--text">{{ genotype[0].toGLString() }}</span>
                            </span>
                            <span class="mh-1">+</span>
                            <span
                                :style="{ color: alleleIndex === 1 ? 'var(--yellow)' : '', border: alleleIndex !== 1 ? 'none' : '' }"
                                class="allele"
                                :class="{ selected: alleleIndex === 1 }"
                            >
                                <span class="allele--text">{{ genotype[1].toGLString() }}</span>
                            </span>
                        </span>
                    </div>
                </div>
                </h2>
                <p-dropdown
                    class="ml-auto"
                    align="left-top"
                    i-docs="sample-card-context-menu"
                    style="margin-right: 0.5rem;"
                    fixed
                >
                    <template v-slot:activator="{ toggleDropdown }">
                        <p-icon-button
                            class="icon-btn--contrast icon-btn--round"
                            icon-name="more-vertical"
                            size="2em"
                            @click="toggleDropdown"
                        />
                    </template>
                    <div class="dropdown--white">
                        <ul>
                            <li>
                                <button
                                    class="sample-card--btn analysis-card--details-btn"
                                    @click="downloadConsensusFasta"
                                >
                                    CONSENSUS FASTA
                                </button>
                                <button
                                    v-if="!hasResolutionDraft"
                                    class="sample-card--btn"
                                    @click="dowloadNewAlleleReport"
                                >
                                    {{ $t("report") }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </p-dropdown>
            </div>
            <div class="flex mb-5 ml-1" style="align-items: baseline;">
                <div>{{ $t("referenceAllele") }}: </div>
                <div class="ml-1" style="font-weight: 500;">{{ newAllele.referenceAllele }}</div>
            </div>
            <div class="custom-scroll" style="height: 40rem; overflow-y: auto; overflow-x: hidden;">
                <table class="w-100 table--striped">
                    <thead>
                        <tr>
                            <th class="genotypes-table--header-cell">
                                <div class="genotypes-table--header-cell-content">{{ $t("region") }}</div>
                            </th>
                            <th class="genotypes-table--header-cell">
                                <div class="genotypes-table--header-cell-content">{{ $t("nucleotideSubstitution") }}</div>
                            </th>
                            <th class="genotypes-table--header-cell">
                                <div class="genotypes-table--header-cell-content">{{ $t("aminoAcidReplacement") }}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="font-size: smaller;">
                        <tr
                            :key="index"
                            v-for="(variant, index) in newAllele.geneticVariants">
                          <td v-for="(varKey, index1) in Object.keys(variant)" :key="index1 + index">{{ variant[varKey] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
    </div>
    <div v-else style="width: 250px;
         height: 200px;">
        <p-spinner
            class="abs-center h4"/>
    </div>

</template>
<script>
    import axios from "axios"
    import { Locales } from "@/i18n/main"
    import { viewPdfInNewTab } from "@/helpers"

    export default {
        name: "NewAalleleMetadate",
        props: {
            locusName: {
                type: String,
                required: true
            },
            analysis: {
                type: Object,
                required: true
            },
            genotype: {
                type: Array,
                required: true
            },
            genotypePartIndex: {
                type: Number,
                required: true
            },
            alleleIndex: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                data: null,
                newAllele: {},
                referenceAllele: null,
                geneticVariants: [],
                loading: true
            }
        },
        computed: {
            getIndexTempNewAllele() {
                return this.analysis.maybeResolutionDraft.newAlleles.sampleAlleles[this.locusName][this.genotypePartIndex][this.alleleIndex]
            },
            alleleIndexToENUM(){
                switch (this.alleleIndex) {
                    case 0:
                        return "FIRST"

                    case 1:
                        return "SECOND"

                    default:
                        throw new Error(`Invalid diploid genotype allele index: ${this.alleleIndex}`)
                }
            },
            hasResolutionDraft() {
                return this.analysis.maybeResolutionDraft != undefined
            }
        },
        mounted() {
            if(this.hasResolutionDraft) {
                this.getTempNewAllele()
            } else {
                this.fetchNewAlleleMetadata()
            }
        },
        methods: {
            getTempNewAllele() {
                this.newAllele = this.analysis.maybeResolutionDraft.newAlleles.manuallyCreatedAlleles[this.locusName][this.getIndexTempNewAllele]
                this.loading = false
            },
            fetchNewAlleleMetadata() {
                return axios.get(
                    "/api/newAlleleMetadata/metadata",
                    {
                        params: {
                            analysisId: this.analysis.id,
                            locus: this.locusName,
                            genotypeNumber: this.genotypePartIndex+1,
                            alleleNumber: this.alleleIndexToENUM,
                        },
                    }
                ).then(result => {
                    this.newAllele.geneticVariants = result.data.geneticVariants
                    this.newAllele.referenceAllele = result.data.referenceAllele.identifier
                    this.loading = false
                    return this.newAllele
                })
            },
            downloadConsensusFasta() {
                return axios.get(
                    `/api/newAlleleMetadata/${this.hasResolutionDraft ? "temporaryConsensusFasta" : "consensusFasta"}`,
                    {
                        params: {
                            analysisId: this.analysis.id,
                            locus: this.locusName,
                            genotypeNumber: this.genotypePartIndex+1,
                            alleleNumber: this.alleleIndexToENUM,
                        },
                    }
                ).then(result => {
                    viewPdfInNewTab(result.data)
                    return result.data
                })
            },
            dowloadNewAlleleReport() {
                viewPdfInNewTab(`/api/newAlleleMetadata/report?analysisId=${this.analysis.id}&locus=${this.locusName}&genotypeNumber=${this.genotypePartIndex+1}&alleleNumber=${this.alleleIndexToENUM}`)
            },
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    newAllele: "New allele",
                    referenceAllele: "Reference allele",
                    region: "Region",
                    nucleotideSubstitution: "Nucleotide substitution",
                    aminoAcidReplacement: "Amino acid replacement",
                    report: "Report"
                },
                [Locales.RU]: {
                    newAllele: "Новая аллель",
                    referenceAllele: "Референсная аллель",
                    region: "Регион",
                    nucleotideSubstitution: "Нуклеотидная замена",
                    aminoAcidReplacement: "Аминокислотная замена",
                    report: "Отчет"
                }
            }
        }
    }
</script>

<style scoped>
.analysis-card--details-btn {
    color: royalblue;
}

.analysis-card--details-btn > a {
    text-decoration: none;
    color: royalblue;
}

.allele {
    border-radius: 4px;
    padding: .5rem 1rem;
    border: 2px solid currentColor;
    transition: all .2s;
}

.allele.selected {
    background-color: currentColor;
}

.allele.selected .allele--text {
    color: black;
}

.sample-card--not-analysed-stub {
    grid-column: 1 / -1;
    font-size: 3rem;
    font-weight: 700;
    color: var(--grey-400);
    text-align: center;
    margin: auto 0;
}

.sample-card {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    padding: 2rem;
    border: 1px solid var(--grey-400);
    border-radius: 4px;
    margin: 0 1rem;
    width: 30em;
    position: relative;
    max-height: 80%;
    min-height: 40rem;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

.sample-card--btn {
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 600;
    color: royalblue;
    white-space: nowrap;
    padding: 1rem 1.5rem;
    width: 100%;
}

.sample-card--btn:hover {
    background-color: rgba(65, 105, 225, .15);
}
</style>
