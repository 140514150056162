import VueI18n from "vue-i18n"
import { BaseChart, Margin } from "@/charts/base-chart"
import { select, Selection } from "d3-selection"
import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from "d3-scale"
import { axisBottom, axisLeft } from "d3-axis"
import { Analysis } from "@/utils/analysis"
import { addTooltip } from "@/charts/utils"


interface Chart {
    chartGroup: Selection<SVGGElement, unknown, any, any>
    chartWidth: number
    chartHeight: number
    x: ScaleBand<string>
    y: ScaleLinear<number, number>
    margin: Margin
    barWidth: number

    resize(): void

    onLocaleChange(): void
}

type DataPoint = {
    name: string
    runId: string
    isControlSample: boolean
    value: {
        offTargetRegions: number
        qualityTrimmed: number
        relatedLoci: number
        targetLoci: number
    }
}

type DataPointFormat = {
    name: string
    runId: string
    isControlSample: boolean
    values: {
        metric: string
        value: number
    }[]
}

enum ReadsMetricEn {
    targetLoci="targetLoci",
    relatedLoci="relatedLoci",
    offTargetRegions="offTargetRegions",
    qualityTrimmed="qualityTrimmed",
}
type ReadsMetricColor = Record<ReadsMetricEn, string>

type ReadsDistributionData = {
    sameRunAnalyses: DataPoint[],
    currentAnalysis: Analysis
}

type MetricStatistics = {
    maxMetricValue: number
    minMetricValue: number,
    metricValueRange: number
}

const tranformDataPointFormat = (sameRunAnalyses: DataPoint[]): DataPointFormat[] => {
    return sameRunAnalyses.map(item => {
        const sum = item.value.targetLoci + item.value.relatedLoci + item.value.offTargetRegions + item.value.qualityTrimmed
        return {
            name: item.name,
            runId: item.runId,
            isControlSample: item.isControlSample,
            values: [
                { metric: "targetLoci", value: item.value.targetLoci / sum * 100 },
                { metric: "relatedLoci", value: item.value.relatedLoci / sum * 100 },
                { metric: "offTargetRegions", value: item.value.offTargetRegions / sum * 100 },
                { metric: "qualityTrimmed", value: item.value.qualityTrimmed / sum * 100 },
            ]
        }
    })
}

export function drawChart(
    data: ReadsDistributionData,
    containerId: string,
    i18n: VueI18n,
    getColorReadsMetric: ReadsMetricColor
) {
    const isControlSampleNames = data.sameRunAnalyses.filter(item => item.isControlSample).map(item => item.name)
    const newSameRunAnalyses = tranformDataPointFormat(data.sameRunAnalyses)
    const baseChart = new BaseChart(containerId, { bottom: 50, left: 60, right: 30, top: 30 })

    const x = scaleBand()
        .domain(newSameRunAnalyses.map(it => it.name))
        .range([ 0, baseChart.chartWidth ])
        .paddingInner(1)
        .paddingOuter(.5)

    const { maxMetricValue, metricValueRange, minMetricValue } = calculateMetricStatistics()
    const y = scaleLinear()
        .domain([ minMetricValue, maxMetricValue + metricValueRange * .1 ])
        .range([ baseChart.chartHeight, 0 ])

    function onLocaleChange() {
        select(`${containerId}-title`).text(<string>i18n.t("analysisMetrics.readsDistribution"))
    }

    const chart: Chart = {
        ...baseChart,
        x,
        y,
        onLocaleChange,
        barWidth: 0
    }

    const emptySpaceBetweenBars = 4
    const barWidth = Math.min(Math.max(2, (chart.chartWidth / newSameRunAnalyses.length) - emptySpaceBetweenBars), 40)

    chart.barWidth = barWidth
    addTitle(baseChart, containerId)
    addAxes(chart, data.currentAnalysis, isControlSampleNames)
    addBars(chart, newSameRunAnalyses, data.currentAnalysis, containerId, i18n, getColorReadsMetric)
    onLocaleChange()

    return chart
}

function addBars(
    chart: Chart,
    readsDistributions: DataPointFormat[],
    currentAnalysis: Analysis,
    containerId: string,
    i18n: VueI18n,
    getColorReadsMetric: ReadsMetricColor,
) {
    const { chartHeight, x, y, barWidth } = chart

    const tooltip = addTooltip(select(containerId))

    const showTooltip = function (this: SVGRectElement, event: MouseEvent, d: {metric: string; value: number; sampleName: string; runId: string}) {
        const selectedMetric = <string>i18n.t(`analysisMetrics.${d.metric}`)
        const sampleName = <string>i18n.t("sampleName")
        const runInId = <string>i18n.t("runInId")
        const selectedValue = `${Math.round(d.value)}%`

        tooltip
            .html(`
                <b>${sampleName}:</b> ${d.sampleName}
                <p></p>
                <b>${runInId}:</b> S${d.runId}
                <p></p>
                <b>${selectedMetric}:</b> ${selectedValue}`)
            .style("display", "block")
            .style("left", `${event.clientX + 20}px`)
            .style("top", `${event.clientY}px`)
    }

    // It's important that this function is not arrow function, cause it has circle as this
    const hideTooltip = function (this: SVGRectElement) {
        return tooltip
            .style("display", "none")
    }
    /* eslint-enable no-invalid-this */

    const bars = chart.chartGroup
        .selectAll("p")
        .data(readsDistributions)
        .enter()
        .append("g")
        .attr("class", "bars-group")
        .attr("style", (d) =>  `${(currentAnalysis.name === d.name || d.isControlSample) ? "opacity: 1;" : "opacity: 0.55;"}`)
        .attr("transform", (d) => `translate(${(x(d.name) || 0)}, 0)`)

    bars
        .selectAll(".bar")
        .data((d) => {
            const newData = []
            let cumulativeHeight = 0

            for (const value of d.values) {
                newData.push({
                    ...value,
                    sampleName: d.name,
                    runId: d.runId,
                    cumulativeHeight,
                })
                cumulativeHeight += chartHeight - y(value.value) || 0
            }
            return newData
        })
        .enter()
        .append("rect")
        .attr("class", (d) =>  "bar " + d.metric)
        .attr("x", 0)
        .attr("y", (d) => chartHeight - d.cumulativeHeight - (chartHeight - y(d.value) || 0))
        .attr("width", barWidth)
        .attr("height", (d) => chartHeight - y(d.value) || 0)
        .attr("fill", (d) => {
            if(getColorReadsMetric[d.metric as ReadsMetricEn]) {
                return getColorReadsMetric[d.metric as ReadsMetricEn]
            } else {
                return "gray"
            }
        })
        // @ts-ignore d3 types are obsolete
        .on("mouseenter", showTooltip)
        .on("mouseleave", hideTooltip)

    return bars
}

function addAxes(chart: Chart, currentAnalysis: Analysis, isControlSampleNames: string[]) {
    const { chartGroup, chartHeight, x, y, barWidth  } = chart

    const xAxis = chartGroup.append("g")
        .attr("transform", `translate(0,${chartHeight})`)
        .attr("class", "chart-axis")
        .attr("id", "chart-x-axis")
        .call(
            axisBottom(x)
                .tickValues(x.domain().filter(name => currentAnalysis.name === name || isControlSampleNames.includes(name)))
        )

    xAxis.selectAll(".tick")
        // @ts-ignore d3 types are obsolete
        .attr("transform", (d: string) => `translate(${x(d) + barWidth/2}, 0)`)

    xAxis.selectAll(".tick text")
        .attr("transform", "rotate(-90)")
        .attr("text-anchor", "end")
        .attr("dx", "-0.45em")
        .attr("dy", "-0.1em")
        // @ts-ignore d3 types are obsolete
        .text((name: string) => {
            if(name === currentAnalysis.name)  {
                return  ""
            } else if(isControlSampleNames.includes(name)) {
                return "⬦"
            } else {
                return name
            }
        })
        // @ts-ignore d3 types are obsolete
        .style("font-size", (name: string) => {
            if (isControlSampleNames.includes(name)) {
                return "2rem"
            } else {
                return null
            }
        })

    const yAxis = chartGroup.append("g")
        .attr("class", "chart-axis")
        .attr("id", "some-y-axis")
        .call(axisLeft(y).ticks(5).tickSizeOuter(0))
    return { xAxis, yAxis }
}

function addTitle(chart: BaseChart, containerId: string) {
    const { svg, containerWidth, margin } = chart
    svg.append("text")
        .attr("id", `${containerId.slice(1)}-title`)
        .attr("class", "chart-axis-label")
        .attr("text-anchor", "middle")
        .style("font-size", "1.7rem")
        .style("font-weight", "600")
        .attr("x", containerWidth / 2 + 8)
        .attr("y", margin.top / 2)
}

function calculateMetricStatistics(): MetricStatistics {
    return {
        maxMetricValue: 100,
        minMetricValue: 0,
        get metricValueRange() {
            return this.maxMetricValue
        }
    }
}
