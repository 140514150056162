<template>
<div>
    <p-modal v-if="modals.newAllele.opened">
            <div class="modal">
                <new-allele-metadata
                    :analysis="analysis"
                    :genotype-part-index="genotypePartIndex"
                    :allele-index="alleleIndex"
                    :locus-name="locusName"
                    :genotype="genotype"
                ></new-allele-metadata>
                <div class="flex" style="margin-top: 3rem">
                    <button class="md-btn-text"
                            @click="closeMetadateNewAllele"
                    >{{ $t("buttons.close") }}
                    </button>
                </div>
            </div>
    </p-modal>
    <div v-if="allele.isUndefined">{{ allele.toGLString() }}</div>
    <div v-else-if="allele.isNew"
         class="badge yellow"
         :class="isPossibleToOpenNewAlleleModal ? 'cursor-pointer' : ''"
         @click="openMetadateNewAllele">
         {{ allele.toGLString() }}</div>
    <div v-else
         class="flex"
         style="flex-wrap: wrap"
    >
        <!-- eslint-disable vue/require-v-for-key vue/valid-v-for -->
        <div class="flex"
             :class="{ rare }"
             v-for="(allelePart, i) in this.allele">
            <AllelePart
                :annotations="annotations"
                :allelePart="allelePart"/>
            <span style="margin: 0 .3rem" v-if="i !== indexOfLastAllelePart">/</span>
        </div>
        <!-- eslint-enable vue/require-v-for-key -->
    </div>
</div>
</template>

<script>
    import { Allele } from "@/genotype"
    import { modalMixin } from "@/mixins/modal-mixin"
    import NewAlleleMetadata from "@/components/genotype/new-allele-metadata"

    import AllelePart from "./allele-part"

    export default {
        name: "Allele",
        mixins: [ modalMixin ],
        components: { AllelePart, NewAlleleMetadata },
        props: {
            allele: Allele,
            rare: Boolean,
            annotations: Object,
            locusName: String,
            analysis: Object,
            genotype: Array,
            genotypePartIndex: Number,
            alleleIndex: Number
        },
        data() {
            return {
                modals: {
                    newAllele: {
                        opened: false
                    },
                },
            }
        },
        computed: {
            indexOfLastAllelePart() {
                return this.allele.parts.length - 1
            },
            /*
            *   To open a modal window with new alleles, the following properties must be present
            *   locusName, analysis, genotype, genotypePartIndex, alleleIndex
            */
            isPossibleToOpenNewAlleleModal() {
                return this.analysis && this.genotype
            }
        },
        methods: {
            openMetadateNewAllele() {
                if (this.isPossibleToOpenNewAlleleModal) {
                    this.openModal("newAllele")
                }
            },
            closeMetadateNewAllele() {
                this.closeModal("newAllele")
            },
        }
    }
</script>

<style scoped>
.rare {
    border-bottom: 2px solid var(--yellow);
}
</style>
