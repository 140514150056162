import { createIdentifiableResourcePageableStore } from "@/store/modules/pageable-store"
import { Analysis } from "@/utils/analysis"
import { AnalysisFilterCouldBe } from "@/store/modules/samples"

export interface ArchiveAnalysisFilterCouldBe extends AnalysisFilterCouldBe {
    organizationOfficialName: string
}

export const {
    actionNames: archiveActionNames,
    getterNames: archiveGetterNames,
    module: archive,
} = createIdentifiableResourcePageableStore<Analysis, ArchiveAnalysisFilterCouldBe>(
    // FIXME: Administration routes subtree prefix trick
    "admin/analyses",
)
