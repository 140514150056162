<template>
    <div>
        <h3 style="font-weight: 400;">{{ $t("alleleSequence") }}</h3>
        <p-file-dropzone
            :on-files-drop="handleFileDrop"
            class="mt-2 dropzone allele-sequence-file-dropzone"
            style="font-size: 1.4rem;"
            v-if="!newAlleleForm.alleleSequenceFile.value"
        >
            <template>
                <div class="dropzone-description">
                    <div>
                        <p-icon class="file-icon"
                                icon-name="file-empty"
                                size="2em"
                        />
                        <div>{{ $t("dropzonePlaceholder1") }}
                            <label class="p-dropzone-hidden-file-input-label">{{
                                    $t("dropzonePlaceholder2")
                            }}
                                <input
                                    @change="($event) => handleFileDrop($event.target.files)"
                                    class="d-none"
                                    type="file"
                                >
                            </label>
                                {{ $t("dropzonePlaceholder3") }}
                        </div>
                    </div>
                </div>
            </template>
        </p-file-dropzone>
        <div class="h-100 run-progress-container" v-else>
            <div class="progressbar run-progressbar">
                <div class="progressbar--filler"
                    :style="{ width: `${getProgressPercent}%` }">
                </div>
                <span class="progressbar--content">{{ $t("loadingFile") + ':  ' + getProgressPercent.toString().split(".")[0] + " % " }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    export default {
        name: "StartUploadStep",
        props: {
            newAlleleForm: Object,
            handleFileDrop: Function,
            progress: Object
        },
        computed: {
            getProgressPercent() {
                return this.progress.loaded !== 0 ? (this.progress.loaded / this.progress.total) * 100 : 0
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    dropzonePlaceholder1: "Drop or",
                    dropzonePlaceholder2: "select",
                    dropzonePlaceholder3: "FASTA with new allele sequence",
                    alleleSequence: "New allele sequence",
                    loadingFile: "Loading"
                },
                [Locales.RU]: {
                    dropzonePlaceholder1: "Перетащите или ",
                    dropzonePlaceholder2: "выберите",
                    dropzonePlaceholder3: "FASTA с последовательностью новой аллели",
                    alleleSequence: "Последовательность новой аллели",
                    loadingFile: "Загрузка"
                }
            }
        }
    }
</script>

<style scoped>

.allele-sequence-file-dropzone {
    height: 10em;
}

.progressbar {
    position: relative;
    border: 1px solid var(--grey-300);
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.run-progress-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.run-progressbar {
    padding: 1.4rem 2rem;
    font-size: 1.6rem;
    --progreesbar-color: var(--green);
}

.progressbar--filler {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--progreesbar-color);
    transition: width ease .5s;
}

.progressbar--content {
    position: relative;
    z-index: var(--first-layer);
}
</style>
