import Vue from "vue"
import App from "@/app.vue"
import { store } from "@/store/store"
import "@/css/base.css"
import "@/css/mixins.css"
import "@/css/md.css"
import "@/css/components/select.css"
import "@/css/components/analysesTransitions.css"
import "@/css/components/chart.css"
import "@/css/components/compositeSearch.css"
import "@/css/components/dropdown.css"
import "@/css/components/dropzone.css"
import "@/css/components/genotypesTable.css"
import "@/css/components/modal.css"
import "@/css/components/error.css"
import "@/css/components/other.css"
import "@/assets/sprite.svg"
import "@/assets/ivd.svg"
import "@/assets/software_marking.svg"
import "@/filters/file-size"
import "@/filters/enum"
import "@/filters/decimal"
import "@/filters/squash-number"
import "@/filters/sentence-case"
import PDropdown from "@/base-components/p-dropdown.vue"
import PInput from "@/base-components/p-input.vue"
import PTextArea from "@/base-components/p-text-area.vue"
import PInputErrors from "@/base-components/p-input-errors.vue"
import PTypeAheadInput from "@/base-components/p-type-ahead-input.vue"
import PIcon from "@/base-components/p-icon.vue"
import PIconButton from "@/base-components/p-icon-button.vue"
import PModal from "@/base-components/p-modal.vue"
import PFileDropzone from "@/base-components/p-file-dropzone.vue"
import PWithTooltip from "@/base-components/p-with-tooltip.vue"
import PSelect from "@/base-components/selects/p-select.vue"
import PSelectWithSearch from "@/base-components/selects/p-select-with-search.vue"
import PMultiSelectWithSearch from "@/base-components/selects/p-multi-select-with-search.vue"
import PSpinner from "@/base-components/p-spinner.vue"
import PBtnDropdown from "@/base-components/p-btn-dropdown.vue"
import { useBrand } from "@/utils/brand-changer"
import axios from "axios"
import { i18n } from "@/i18n/main"
import router from "@/router"

Vue.config.productionTip = false
Vue.component("PDropdown", PDropdown)
Vue.component("PFileDropzone", PFileDropzone)
Vue.component("PInput", PInput)
Vue.component("PTextArea", PTextArea)
Vue.component("PInputErrors", PInputErrors)
Vue.component("PTypeAheadInput", PTypeAheadInput)
Vue.component("PIcon", PIcon)
Vue.component("PModal", PModal)
Vue.component("PWithTooltip", PWithTooltip)
Vue.component("PSelect", PSelect)
Vue.component("PSelectWithSearch", PSelectWithSearch)
Vue.component("PMultiSelectWithSearch", PMultiSelectWithSearch)
Vue.component("PSpinner", PSpinner)
Vue.component("PIconButton", PIconButton)
Vue.component("PBtnDropdown", PBtnDropdown)

axios.interceptors.response.use(undefined, (e) => {
    // Some errors don't have response, e.g. when you try to access undefined url.
    if (!Object.prototype.hasOwnProperty.call(e, "response")) {
        console.error("Unexpected error during request")
        throw e
    }
    return new Promise((resolve, reject) => {
        switch (e.response.status) {
            case 401:
                console.warn("You are not authorized")
                // If you ever get an unauthorized, your session has expired or you are not logged in,
                // In the first case you should clean authentication info in browser
                if (router.currentRoute.name !== "login") {
                    store.commit("logoutOnTheClientSide")
                    router.push({ name: "login" })
                }
                break
            case 451:
                console.warn("You haven't accepted license agreement")
                store.commit("startLegalisation")
                break
            case 500:
                console.error("Server error occurred", e.response)
                break
        }
        reject(e)
    })
})


new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount("#app")

const { iconName, brandName } = useBrand()

const favicon = document?.querySelector("#favicon") as HTMLAnchorElement | null
if(iconName === "PARSEQ_ICON" && favicon) {
    favicon.href = "/favicon_parseq.ico"
} else if(iconName === "EPIVALUE_ICON" && favicon) {
    favicon.href = "/favicon_epivalue.ico"
}

const title = document.querySelector("#title") as HTMLAnchorElement | null
if(brandName === "PARSEQ_NAME" && title) {
    title.innerHTML = "PARallele™ HLA software"
} else if(brandName === "EPIVALUE_NAME" && title) {
    title.innerHTML = "GeneValue HLA software"
}

// @ts-ignore
export function debounce(fn, ms) {

    // @ts-ignore
    let timeout = undefined

    // @ts-ignore
    return (...args) => {
        // @ts-ignore
        clearTimeout(timeout)
        return new Promise((resolve) => {
            timeout = setTimeout(() => resolve(fn(...args)), ms)
        })
    }
}
