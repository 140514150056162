import { inputMixin } from "./input-mixin"

export const selectMixin = {
    mixins: [ inputMixin ],
    inheritAttrs: false,
    props: {
        view: {
            type: Function,
            default: it => it
        },
        options: Array,
        disabled: Boolean
    },
    data() {
        return {
            dropdownShown: false
        }
    },
    methods: {
        closeDropdown() {
            this.dropdownShown = false
        }
    },
    /*
     * For this to work properly, you must attach v-model to dropdown state in your select component
     */
    created() {
        const vm = this
        const unwatch = vm.$watch(
            "dropdownShown",
            (newVal) => {
                if (!newVal) {
                    vm.touched = true
                    unwatch()
                }
            }
        )
    }
}
