import {
    Allele,
    AlleleResolution,
    Genotype,
    getGenotypeAlleleParts,
    LOCI,
    Locus,
    reduceResolution,
} from "@/genotype"
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"
import {
    Analysis,
    AnalysisResult,
    FunctionalAnnotations,
    Genotypes,
    GenotypingResolution,
    LocusRecord,
    LocusSpecificProblem,
    LocusSpecificProblems,
    parseRawGenotypes,
    Problem,
    Quality,
    RawGenotypes,
    ResolutionDraft,
    worstProblemsQuality,
    worstQuality,
} from "@/utils/analysis"
import { entriesOfPartial, keys, valuesOfPartial } from "@/extensions/object-extensions"
import {
    KnownAmbiguities,
    supplementAllele,
} from "@/utils/ambiguities"
import { deduplicate, sum } from "@/extensions/array-extensions"
import { compareByWeightAscending, compareNumbersAscending, updateOrSet } from "@/helpers"
import _ from "lodash"

type Tools = keyof ToolResults
export type ToolResults = {
    "hla-hd": HlaHdResults
    "kourami": KouramiResults
    "hisat": HisatResults
}
type AlleleHint = [string, Array<Tools | "manual">]
// Based on PAR-638
const TOOLS_WEIGHTS = { "hla-hd": 1, "hisat": 2, "kourami": 3, "manual": 0 }

export type HlaHdResults = LocusRecord<{
    genotypes: Array<{ alleles: Array<{ allele: string }> }>
    contamination: boolean | null
}>

export type KouramiResults = LocusRecord<{
    genotypes: Array<{ alleles: Array<{ allele: string, novel: boolean }> }>
}>

export type HisatResults = LocusRecord<{
    rankedAlleles: Array<{ allele: string, abundance: number }>
    coveredAlleles: Array<string>
    contamination: boolean | null
}>

export function updateGenotypesFromResolutionDraft(
    genotypes: RawGenotypes,
    resolutionDraft: ResolutionDraft
): RawGenotypes {
    return { ...genotypes, ...resolutionDraft.genotypes }
}

/**
 * MERC
 */
export function updateLocusSpecificProblems(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    locusSpecificProblems: LocusSpecificProblems,
    resolutionDraft?: ResolutionDraft,
): LocusSpecificProblems {

    return Object.fromEntries(LOCI
        .map(locus => {
            const _problems: any = {}
            const updateWithProblemIfPresent: (
                problemName: LocusSpecificProblem
            ) => void = problemName => {
                if (locusSpecificProblems?.[locus]?.[problemName]) {
                    _problems[problemName] = cloneDeep(locusSpecificProblems?.[locus]?.[problemName])
                }
            }
            updateWithProblemIfPresent("exonInLqrRegion")
            updateWithProblemIfPresent("locusCoverage")

            /*
             * We use null to set undefined locus genotype ([locus]: null),
             * so to check whether the locus genotype was changed
             * we use explicit undefined equality check
             */
            if (resolutionDraft?.genotypes?.[locus] !== undefined) {
                const genotype = Genotype.fromGLString(resolutionDraft.genotypes[locus]!)
                if (genotype.isUndefined) {
                    _problems.genotypesNumber = {
                        quality: "red",
                        value: 0,
                        type: "ANALYSIS_ISSUE"
                    }
                }
                if (genotype.genotypeParts.find(unambiguousGenotype => unambiguousGenotype.find(allele => allele.isNew))) {
                    _problems.novelAlleles = {
                        quality: "red",
                        value: genotype.toGLString(),
                        type: "ANALYSIS_ISSUE",
                    }
                }
            } else {
                updateWithProblemIfPresent("genotypesNumber")
                updateWithProblemIfPresent("allelesNumber")
                updateWithProblemIfPresent("novelAlleles")
            }
            return [ locus, _problems ]
        }))
}

/**
 * MERC
 */
export function constructNewProperties(analysis: Analysis): AnalysisResult {
    switch (analysis.maybeResolutionDraft?.resolution) {
        case "contaminated":
            return {
                sampleName: analysis.result.sampleName,
                resolution: "LAB_ISSUE",
                qualityMetrics: { ...analysis.result.qualityMetrics, contamination: true },
                quality: "red",
                //@ts-ignore
                genotypes: Object.fromEntries(keys(analysis.result.genotypes).map(locus => [ locus, null ])),
                problems: {
                    generalMetrics: {
                        ...analysis.result.problems?.generalMetrics,
                        contamination: {
                            quality: "red",
                            value: true,
                            type: "LAB_ISSUE"
                        }
                    },
                    locusSpecificMetrics: undefined
                },
                annotations: null
            }
        case "assigned": {
            const newLocusSpecificProblems = updateLocusSpecificProblems(
                analysis.result.problems?.locusSpecificMetrics ?? {},
                analysis.maybeResolutionDraft
            )

            const quality = worstProblemsQuality([
                ...valuesOfPartial(analysis.result.problems?.generalMetrics ?? {}),
                ...valuesOfPartial(newLocusSpecificProblems)
                    .flatMap(it => valuesOfPartial(it)),
            ])

            const newGenotypes = updateGenotypesFromResolutionDraft(analysis.result.genotypes, analysis.maybeResolutionDraft)
            return {
                sampleName: analysis.result.sampleName,
                // TODO: [@aslepchenkov 10.02.2020] Is it so?
                resolution: "WELL_TYPED",
                qualityMetrics: analysis.result.qualityMetrics,
                quality,
                genotypes: newGenotypes,
                problems: {
                    generalMetrics: analysis.result.problems?.generalMetrics,
                    locusSpecificMetrics: newLocusSpecificProblems
                },
                annotations: annotate(
                    parseRawGenotypes(newGenotypes),
                    mergeAnnotations(
                        analysis.result.annotations ?? {},
                        analysis.maybeResolutionDraft?.annotations ?? {}
                    )
                )
            }
        }
    }

    throw new Error("you are trying to construct new properties from analysis with unknown resolution status")
}

export function isSentByUser(analysis: Analysis, locus: Locus): boolean {
    return (analysis.maybeSupportRequest?.comment !== undefined)
        && analysis.maybeSupportRequest.loci.includes(locus)
}

export function locusQualityForSupport(analysis: Analysis, locus: Locus): Quality {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const locusSpecificProblems = analysis.result.problems?.locusSpecificMetrics?.[locus] ?? {}
    const userProblems = analysis.maybeSupportRequest
    return worstQuality([
        worstProblemsQuality(Object.values(locusSpecificProblems) as Array<Problem>),
        userProblems?.loci.includes(locus) ? "red" : "green",
    ])
}

export function locusQualityReAnalysisForSupport(analysis: Analysis, locus: Locus): Quality {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const locusSpecificProblems = analysis.maybeReAnalysisState?.maybeResult?.problems?.locusSpecificMetrics?.[locus] ?? {}
    return worstQuality([
        worstProblemsQuality(Object.values(locusSpecificProblems) as Array<Problem>),
    ])
}
/**
 * Collect allele hints from all tool results at specified locus
 *
 * They are already sorted and have specified resolution
 */
export function collectAlleleHints(
    toolResults: ToolResults,
    locus: Locus,
    manuallyCreatedAllelesAtActiveLocus: Array<string>,
    knownAmbiguities: KnownAmbiguities,
    resolution: AlleleResolution
): Array<AlleleHint> {

    return Object.entries(
        Object.entries(extractAllelesFromToolResults(toolResults, locus))
            .flatMap(
                ([ toolName, alleles ]) =>
                    deduplicate(
                        alleles.map(it => reduceResolution(it, resolution))
                            .map(
                                allele =>
                                    // TODO: [@aslepchenkov 25.06.2020] Could be optimized, only non ambiguous alleles are given
                                    supplementAllele(
                                        Allele.fromGLString(allele),
                                        knownAmbiguities.knownAlleleAmbiguities[locus] ?? {}
                                    ).toGLString()
                            )
                    ).map(it => [ it, toolName ] as [string, keyof ToolResults])
            )
            .reduce(
                (acc, [ allele, toolName ]) => updateOrSet(acc, allele, toolName),
                {} as Record<string, AlleleHint[1]>
            )
    )
        .concat(manuallyCreatedAllelesAtActiveLocus?.map(it => [ it, [ "manual" ] ]) ?? [])
        .sort(compareHints)
        .map(([ allele, sources ]) => [
            allele,
            sources.sort(compareByWeightAscending(TOOLS_WEIGHTS)),
        ])
}

/**
 * Extract all alleles from tool results at concrete loci
 *
 * @param toolsResults
 * @param locus
 */
export function extractAllelesFromToolResults(
    toolsResults: ToolResults,
    locus: Locus
): Record<keyof ToolResults, string[]> {

    const hlaHd = toolsResults["hla-hd"][locus].genotypes.flatMap(({ alleles }) =>
        alleles.map((it: { allele: string }) => it.allele)
            .flatMap((it: string) => it.split("/")))
    const kourami = toolsResults.kourami[locus].genotypes.flatMap(({ alleles }) =>
        alleles.map((it: { allele: string }) => it.allele)
            .flatMap((it: string) => it.split("/")))
    const hisat = [
        ...toolsResults.hisat[locus].rankedAlleles.map(it => it.allele).flatMap(it => it.split("/")),
        ...toolsResults.hisat[locus].coveredAlleles.flatMap(it => it.split("/")),
    ]
    return {
        hisat,
        kourami,
        "hla-hd": hlaHd,
    }
}

export function compareHints(firstHint: AlleleHint, secondHint: AlleleHint) {
    const isManuallyCreated = (hint: AlleleHint) => hint[1].length === 1 && hint[1][0] === "manual"
    const totalWeight = (tools: (keyof typeof TOOLS_WEIGHTS)[]) => sum(tools.map(tool => TOOLS_WEIGHTS[tool]))
    if (isManuallyCreated(firstHint)) {
        return -1
    } else if (secondHint[1].length === firstHint[1].length) {
        return compareNumbersAscending(totalWeight(firstHint[1]), totalWeight(secondHint[1]))
    }
    return secondHint[1].length - firstHint[1].length
}

export function annotate(genotypes: Genotypes, annotations: FunctionalAnnotations): FunctionalAnnotations | null {

    function annotateAllelePart(locus: Locus, part: string): string | undefined {
        let resolution = Allele.fromGLString(part).resolution
        while (resolution > 0) {
            const annotation = annotations[locus]?.[part]
            if (annotation) {
                return annotation
            }
            resolution -= 1
            if (resolution > 0) {
                part = reduceResolution(part, resolution as AlleleResolution)
            }
        }
        return undefined
    }

    const allelePartsAnnotations = Object.fromEntries(
        entriesOfPartial(genotypes)
            .map(
                ([ locus, genotype ]) =>
                    [
                        locus,
                        Object.fromEntries(getGenotypeAlleleParts(genotype)
                            .map(allelePart => [ allelePart, annotateAllelePart(locus, allelePart) ])
                            .filter(it => it[1])),
                    ]
            )
            .filter(it => !isEmpty(it[1]))
    )
    return isEmpty(allelePartsAnnotations) ? null : allelePartsAnnotations as FunctionalAnnotations
}

export function mergeAnnotations(one: FunctionalAnnotations, another: FunctionalAnnotations): FunctionalAnnotations {
    return Object.fromEntries(LOCI.map(locus => (
        [
            locus,
            {
                ...(one[locus] ?? {}),
                ...(another[locus] ?? {})
            },
        ]
    )).filter(it => !isEmpty(it[1])))
}

/*
  TODO [aslepchenkov 18.01.21] Candidate for refactoring.
  Backend introduced the same type, but due to Kotlin inability to work with number enums it uses strings,
  I currently don't want to refactor all my functions to use string, but it should be done or
  general adapter itroduced to convert resolution to frontend format as early as possible
*/
export function genotypingResolutionToAlleleResolution(genotypingResolution: GenotypingResolution): AlleleResolution {
    switch (genotypingResolution) {
        case "ONE_FIELD":
            return 1
        case "TWO_FIELD":
            return 2
        case "THREE_FIELD":
            return 3
        default:
            throw new Error(`Unknown GenotypingResolution - ${genotypingResolution}`)
    }
}

export function applyResolutionDraft(sample: Analysis, sampleResolutionDraft: ResolutionDraft): Analysis {
    const newSample = _.cloneDeep(sample)
    newSample.result.genotypes = updateGenotypesFromResolutionDraft(sample.result.genotypes, sampleResolutionDraft)

    return {
        ...newSample,
        result: constructNewProperties(newSample)
    }
}
