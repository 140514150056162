<template>
    <InteractiveDocs :steps="steps" :allow-controls="false"/>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { waitFor } from "@/interactive-docs/utils"
    import { circler } from "@/interactive-docs/mixin"

    export default {
        name: "DataUploadDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [ chooseLot,  metadata, lotSamples, lotWithSamples, uploadConfirmation ],
            }
        },
    }

    const chooseLot = {
        name: "chooseLot",
        mixins: [ circler ],
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        async mounted() {
            const el = await waitFor(
                () => document.querySelector("#raw-data-upload-choose-lot-step")
            )
            this.highlighter.moveHighlight([ el ])

            const nextButton = await waitFor(
                () => document.querySelector("#wizard-next-btn:not(:disabled)")
            )
            nextButton.addEventListener("click", () => this.nextStep(), { once: true })
            this.highlighter.moveHighlight([
                el,
                nextButton,
            ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Импорт данных</h3>
                    <p>
                        Импорт данных начинается с заполнения метаданных запуска.
                    </p>
                    <br/>
                    <p>
                        Чтобы перейти к следующему шагу, выберите серию набора реагентов, использованного для постановки.
                    </p>
                    <p>
                        Он выбирается в первую очередь для проверки совпадения характеристик запуска указанным в инструкции.
                    </p>
                </div>
            )
        }
    }

    const metadata = {
        name: "metadata",
        mixins: [ circler ],
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        async mounted() {
            const el = await waitFor(
                () => document.querySelector("#raw-data-upload-metadata-step")
            )
            this.highlighter.moveHighlight([ el ])

            const nextButton = await waitFor(
                () => document.querySelector("#wizard-next-btn:not(:disabled)")
            )
            nextButton.addEventListener("click", () => this.nextStep(), { once: true })
            this.highlighter.moveHighlight([
                el,
                nextButton,
            ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Импорт данных</h3>
                    <br/>
                    <p>
                        Заполнить метаданные запуска можно перетащив RunInfo.xml
                        файл в дропзону (у MiSeq он лежит в папке с результатами запуска).
                        Также можно выбрать файл, нажав на соответствующий текст внутри дропзоны.
                    </p>
                    <p>
                        Если запуск был поставлен на неподдерживаемой платформе
                        или наборе для секвенирования, а также если запуск с таким
                        номером уже существует в аккаунте вашей организации,
                        при попытке загрузить RunInfo возникнет предупреждение.
                    </p>
                    <br/>
                    <br/>
                    <p>
                        До момента начала загрузки введенная информация нигде не сохраняется.
                        Перезагрузка страницы приведет к потере введенных данных.
                    </p>
                    <br/>
                    <p>
                        Чтобы перейти к следующему шагу, заполните метаданные.
                    </p>
                </div>
            )
        }
    }

    function lotStep() {
        return document.querySelector("#lot-step")
    }

    function lots() {
        return document.querySelectorAll(".lot")
    }

    function allLotsHaveSamples() {
        return lots().length > 0 && document.querySelectorAll(".lot-details").length === lots().length
    }

    const lotSamples = {
        name: "lotSamples",
        mixins: [ circler ],
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        mounted() {
            const id = setInterval(() => {
                if (lotStep()) {
                    this.highlighter.moveHighlight(lots())
                    console.debug("Lot steps appeared")
                    console.debug(new Date().getMilliseconds())
                    if (lots().length > 0) {
                        clearInterval(id)
                    }
                }
            }, 100)
            const waitForSamplesAdded = setInterval(() => {
                if (allLotsHaveSamples()) {
                    this.nextStep()
                    clearInterval(waitForSamplesAdded)
                }
            }, 400)
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Импорт данных</h3>
                    <p>
                        На втором этапе необходимо добавить образцы.
                    </p>
                    <p>
                        Это можно сделать с помощью
                        перетаскивания файлов или с помощью файлового менеджера браузера.
                    </p>
                    <br/>
                    <p>
                        Чтобы продолжить, добавьте какие-нибудь файлы.
                    </p>
                </div>
            )
        }
    }

    const lotWithSamples = {
        name: "lotWithSamples",
        mixins: [ circler ],
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        mounted() {
            setTimeout(() => {
                this.highlighter.moveHighlight([
                    ...document.querySelectorAll(".lot"),
                    document.querySelector("#wizard-next-btn"),
                ])
            }, 3000)

            const nextButton = document.querySelector("#wizard-next-btn")
            nextButton.addEventListener("click", () => this.nextStep(), { once: true })
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Импорт данных</h3>
                    <p>После добавления образцов их количество и списки их идентификаторов
                        отображаются на карточке.
                    </p>
                    <p>
                        В норме, при загрузке всех образцов,
                        бейдж с идентификаторами должен быть один. Если их несколько значит какие-то образцы
                        отсутствуют.
                    </p>
                    <p>На этом же шаге можно выбрать контрольный образец.</p>
                    <br/>
                    <p>
                        Если вы забыли добавить какой-то образец или добавили лишний, то единственный вариант - удалить
                        добавленные образцы и
                        осуществить добавление заново.
                        Ограничение было сделано для упрощения формы загрузки.
                    </p>
                    <p>
                        Полностью посмотреть список образцов и файлов, которые им принадлежат, можно, нажав на кнопку
                        "посмотреть образцы".
                    </p>
                    <br/>
                    <p>
                        Нажмите на кнопку вперед, чтобы перейти к следующему шагу.
                    </p>
                </div>
            )
        }
    }

    function startUploadStep() {
        return document.querySelector("#start-upload-step")
    }

    const uploadConfirmation = {
        name: "uploadConfirmation",
        mixins: [ circler ],
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        async mounted() {
            const el = await waitFor(startUploadStep)
            this.highlighter.moveHighlight([ el ])
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Импорт данных</h3>
                    <p>
                        На последнем шаге можно еще раз проверить все введенные данные и начать загрузку,
                        нажав на соответствующую кнопку.
                    </p>
                    <p>
                        В рамках текущей документации эта кнопка заблокирована.
                    </p>
                    <br></br>
                    <p>
                        После ее нажатия будут отображены полосы прогресса загрузки образцов.
                    </p>
                    <br></br>
                    <p>
                        <b>Пока идет загрузка образцов, закрывать вкладку нельзя,
                            это приведет к прерыванию загрузки. </b>
                        При попытке закрыть вкладку во время загрузки образцов будет
                        вызвано браузерное уведомление для подтверждения закрытия вкладки
                        (если они не заблокированы настройками вашего браузера).
                    </p>
                </div>
            )
        }
    }
</script>
