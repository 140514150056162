<template>
    <div>
        <div class="label">{{ $t("enterNewAllele") }}:</div>
        <div class="mb-1">
            <p-input class="md-outlined monospace bold"
                     type="text"
                     v-model="allele.value"
                     @input="$emit('input', value)"
                     :validation-errors="allele.errors"
                     autofocus
            />
        </div>
        <div style="margin: 3.5rem 0">
            <div class="label">{{ $t("selectPlace") }}:</div>
            <div class="flex">
                <!-- eslint-disable-next-line-->
                <div class="monospace bold" v-for="(genotypePart, i) in genotype">
                    <button :style="{ color: alleleColors[2 * i] }"
                            :class="{ selected: alleleIndex === 2 * i }"
                            class="allele"
                            :disabled="genotypePart[0].toGLString() === NEW_ALLELE_PLACEHOLDER"
                            @click="selectPlaceForNewAllele(i, 0)"
                    >
                        <span class="allele--text">{{ genotypePart[0].toGLString() }}</span>
                    </button>
                    +
                    <button :style="{ color: alleleColors[2 * i + 1] }"
                            class="allele"
                            :class="{ selected: alleleIndex === 2 * i + 1 }"
                            :disabled="genotypePart[1].toGLString() === NEW_ALLELE_PLACEHOLDER"
                            @click="selectPlaceForNewAllele(i, 1)"
                    >
                        <span class="allele--text">{{ genotypePart[1].toGLString() }}</span>
                    </button>
                    <span v-if="i < genotype.length - 1"
                          class="mh-1 monospace bold">|</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { Locales } from "@/i18n/main"
    import { NEW_ALLELE_PLACEHOLDER } from "@/genotype"

    export default {
        name: "ExistingAlleleAddition",
        props: {
            genotype: Object,
            alleleColors: Array,
            value: Array
        },
        data() {
            return {
                NEW_ALLELE_PLACEHOLDER
            }
        },
        computed: {
            allele() {
                return this.value[0]
            },
            alleleIndex() {
                return this.value[1]
            },
        },
        methods: {
            selectPlaceForNewAllele(genotypePartIndex, alleleIndex) {
                this.$emit("input", [ this.allele, genotypePartIndex * 2 + alleleIndex ])
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    selectPlace: "Select place",
                    enterNewAllele: "Enter new allele"
                },
                [Locales.RU]: {
                    selectPlace: "Выбрите место",
                    enterNewAllele: "Введите новую аллель"
                }
            }
        }
    }
</script>

<style scoped>

    .label {
        font-size: 1.6rem;
        margin-bottom: .5rem;
    }

    .allele {
        border-radius: 4px;
        padding: .5rem 1rem;
        border: 2px solid currentColor;
        transition: all .2s;
    }

    .allele.selected,
    .allele:hover {
        background-color: currentColor;
    }

    .allele.selected .allele--text,
    .allele:hover .allele--text {
        color: black;
    }
</style>
