import { selectMixin } from "./select-mixin"

export const selectWithSearchMixin = {
    mixins: [ selectMixin ],
    props: {
        getKey: {
            type: Function,
            required: true
        },
    },
    data() {
        return {
            search: ""
        }
    },
}
