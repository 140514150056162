<template>
    <div style="padding: 1rem;"
         :style="{ backgroundColor: !filteredGenotypes.length ? '#dddddd' : 'white' }">
        <span style="font-weight: 700; font-size: 1.4rem;" class="mb-1">Kourami</span>
        <template v-if="filteredGenotypes.length">
            <div
                :key="i"
                v-for="(genotype, i) in filteredGenotypes"
            >
                <div
                    class="flex"
                    :key="alleleIndex"
                    v-for="alleleIndex in [0, 1]"
                >
                    <template v-if="genotype.alleles[alleleIndex]">
                        <span
                            class="badge green badge-margin flex-fixed-icon"
                            v-if="genotype.alleles[alleleIndex].novel"
                        >{{ $t("novel") }}</span>
                        <allele-component
                            :highlight="targetAllele"
                            :allele="alleleFromString(genotype.alleles[alleleIndex].allele)"
                            class="mv-1"
                        />
                    </template>
                </div>
            </div>
        </template>
        <div v-else>
            {{ $t("selectedAlleleNotFound") }}
        </div>
    </div>
</template>

<script>
    import { Allele, alleleIncludes } from "@/genotype"
    import { Locales } from "@/i18n/main"

    import AlleleComponent from "./allele"

    export default {
        name: "KouramiResults",
        components: {
            AlleleComponent,
        },
        computed: {
            filteredGenotypes() {
                return this.targetAllele
                    ? this.results.genotypes.map(it => ({
                        ...it,
                        alleles: it.alleles.filter(allele => alleleIncludes(allele.allele, this.targetAllele))
                    })).filter(it => it.alleles.length)
                    : this.results.genotypes
            },

        },
        methods: {
            alleleFromString: Allele.fromGLString
        },
        props: {
            results: Object,
            targetAllele: String
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    novel: "Новая"
                },
                [Locales.EN]: {
                    novel: "Novel"
                }
            }
        }
    }
</script>

