import { select, Selection } from "d3-selection"

export interface Margin {
    top: number
    right: number
    bottom: number
    left: number
}

export class BaseChart {
    public svg: Selection<SVGSVGElement, unknown, HTMLElement, any>
    readonly chartHeight: number
    readonly chartWidth: number
    readonly containerHeight: number
    readonly containerWidth: number
    public chartGroup: Selection<SVGGElement, unknown, any, any>
    readonly resize: () => void
    private container: Element | null

    public constructor(
        containerId: string,
        public margin: Margin = { top: 30, right: 30, bottom: 70, left: 60 }
    ) {
        this.container = document.querySelector(containerId)
        if (this.container === null) {
            throw new Error(`Chart container element with id - ${containerId} - not found`)
        }
        this.containerWidth = this.container.clientWidth
        this.containerHeight = this.container.clientHeight
        this.chartHeight = this.containerHeight - margin.top - margin.bottom
        this.chartWidth = this.containerWidth - margin.left - margin.right
        this.resize = () => {
            const targetWidth = this.container!.clientWidth
            const targetHeight = this.container!.clientHeight
            this.svg.attr("width", targetWidth)
            this.svg.attr("height", targetHeight)
        }

        this.svg = select(containerId)
            .append("svg")
            .attr("width", this.chartWidth)
            .attr("height", this.chartHeight)
            .attr("viewBox", `0 0 ${this.containerWidth} ${this.containerHeight}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
        this.resize()

        this.chartGroup = this.svg
            .append("g")
            .attr(
                "transform",
                `translate(${margin.left},${margin.top})`
            )
    }
}
