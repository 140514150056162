<template>
    <div class="run">
        <div class="flex mb-2">
            <div class="ellipsis mr-1" style="font-weight: 700; font-size: 2rem">{{ run.name }}</div>
            <div class="ml-auto">{{ $d(run.createdAt) }}</div>
        </div>
        <div>
            <div v-if="status === 'NOT_READY'">
                <div v-if="isAnalysed(run)" class="flex">
                    <div>{{ $t("readiness") }} {{ progress | decimal }}%</div>
                    <sample-statuses-progress-bar
                        class="sample-statuses-progress-bar"
                        :sample-statuses-statistics="sampleStatusesStatistics"
                    />
                </div>
                <div v-else style="text-align: center">
                    {{ $t("analysisInProgress") }}
                </div>
            </div>
            <div v-else-if="status === 'READY'" class="flex">
                <ul class="status-badges">
                    <template
                        v-for="[color, status] in [['green', 'COMPLETED'], ['red', 'LAB_FAILURE'], ['red', 'TYPING_FAULT']]"
                    >
                        <li
                            :title="$t(`analysisStatuses.${status}`)"
                            class="badge"
                            style="font-size: 1.4rem; color: white; width: fit-content;"
                            :class="[ color ]"
                            v-if="run.properties.statusAggs[status]"
                            :key="status"
                        >
                            {{ run.properties.statusAggs[status] }}
                        </li>
                    </template>
                </ul>
                <button
                    class="flex md-btn-outlined ml-auto"
                    style="font-size: 1.2rem"
                    i-docs="export-button"
                    @click="completeAndExportRun"
                >
                    {{ $t("export") }}
                </button>
            </div>
            <div
                v-else-if="status === 'ALREADY'">
                <div class="mb-1">{{ $t("exportDate") }}: {{ $d(run.properties.exportDate) }}</div>
                <ul class="run-export-resources">
                    <li>
                        <button
                            class="flex btn-link"
                            @click="viewRunPdfReport"
                        >
                            <p-icon
                                icon-name="pdf"
                                size="1.8em"
                                class="mr-1"
                            />
                            {{ $t("runReport") }}
                        </button>
                    </li>
                    <li :key="resolution" v-for="resolution in [2, 3]">
                        <button
                            class="flex btn-link"
                            @click="downloadRunGenotypesCsv(resolution)"
                        >
                            <p-icon
                                icon-name="csv"
                                size="1.8em"
                                class="mr-1"
                            />
                            {{ $t("genotypingResults", [ resolution ]) }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { AnalysisStatuses } from "@/enums/analysis-status"
    import { Locales } from "@/i18n/main"
    import { isAnalysed, samplesNumber } from "@/utils/sequencing-run"
    import {
        buildExportRunResourcesUrl,
        buildRunGenotypesExportUrl,
        buildRunPdfReportExportUrl, completeRun,
    } from "@/endpoints"
    import { downloadUsingPhantomLink, viewPdfInNewTab } from "@/helpers"
    import SampleStatusesProgressBar from "@/views/data-export/components/sample-statuses-progress-bar"

    export default {
        name: "RunCard",
        components: { SampleStatusesProgressBar },
        props: {
            run: Object,
            status: String
        },
        computed: {
            totalSamples() {
                return samplesNumber(this.run)
            },
            progress() {
                return this.sampleStatusesStatistics[AnalysisStatuses.COMPLETED] * 100
            },
            sampleStatusesStatistics() {
                return Object.fromEntries([
                    AnalysisStatuses.COMPLETED,
                    AnalysisStatuses.IN_SUPPORT,
                    AnalysisStatuses.AWAITING_APPROVE,
                ].map(status => [
                    status,
                    this.run.properties.statusAggs?.[status] && this.totalSamples
                        ? this.run.properties.statusAggs[status] / this.totalSamples
                        : 0,
                ]))
            }
        },
        methods: {
            async completeAndExportRun() {
                await completeRun(this.run.id)
                downloadUsingPhantomLink(buildExportRunResourcesUrl(this.run.id))
                this.$emit("run-exported")
            },
            viewRunPdfReport() {
                viewPdfInNewTab(buildRunPdfReportExportUrl(this.run.id))
            },
            downloadRunGenotypesCsv(resolution) {
                downloadUsingPhantomLink(buildRunGenotypesExportUrl(this.run.id, resolution))
            },
            isAnalysed
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    readiness: "Readiness",
                    exportDate: "Export date",
                    export: "Export",
                    genotypingResults: "Genotyping results ({0} fields)",
                },
                [Locales.RU]: {
                    readiness: "Готовность",
                    exportDate: "Дата экспорта",
                    export: "Экспортировать",
                    genotypingResults: "Результаты генотипирования ({0} поля)",
                }
            }
        }
    }
</script>

<style scoped>
    .run {
        border: 1px solid var(--grey-400);
        padding: 2rem 2rem 2rem 3rem;
        border-radius: 4px;
        align-items: center;
    }

    .sample-statuses-progress-bar {
        width: 15rem;
        height: 1.2rem;
    }

    .status-badges {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .status-badges > *:not(:first-child) {
        margin-left: .5rem;
    }

    .btn-link:hover {
        text-decoration: underline;
    }

    .run-export-resources li:not(:last-child) {
        margin-bottom: 1rem;
    }
</style>
