import { runs } from "@/data-stubs/runs"
import {
    analysesForApprove,
    analysesForReview,
    runsWithAnalysesForApprove,
    runsWithAnalysesForReview,
} from "@/data-stubs/review"
import { analyses } from "@/data-stubs/lab-qc-analyses"
import { mockSequencingRuns } from "@/mock-pageable-resource"
import axios from "axios"
import AxiosMockAdapter from "axios-mock-adapter"
import { AnalysisStatuses } from "@/enums/analysis-status"
import { pipelineThresholds } from "@/data-stubs/pipeline-thresholds"
import { annotations } from "@/data-stubs/functional-annotations"

import { knownAmbiguities } from "../tests/unit/data/known-ambiguities"

let mock: AxiosMockAdapter | null = null

export function setUpAxiosMock() {
    console.debug("Set up axios mock")
    mock = new AxiosMockAdapter(axios, { onNoMatch: "passthrough" })
    mock.onPost(/api\/analyses\/[\w-]+\/review/).reply(200)

    const sequencingRunsRoute = "api/sequencingRuns"
    const sequencingRunRoute = /api\/sequencingRuns\/[\w-]+$/
    const sequencingRunAnalysesRoute = /api\/sequencingRuns\/([\w-]+)\/analyses/
    const sequencingRunAggregatesHavingAnalysesRoute = "api/sequencingRuns/aggregates/havingAnalyses"
    const knownAmbiguitiesRoute = /api\/configuredPipelines\/(.+)\/resource\?type=KNOWN_AMBIGUITIES/
    const pipelineThresholdsRoute = /api\/configuredPipelines\/(.+)\/resource\?type=QUALITY_CONTROL_THRESHOLDS/
    const pipelineAnnotationsRoute = /api\/configuredPipelines\/(.+)\/resource\?type=ALLELE_FUNCTIONAL_ANNOTATIONS/
    const analysisApproveRoute = /api\/analyses\/([\w-]+)\/approve/
    const analysisSupportRequestRoute = /api\/analyses\/([\w-]+)\/supportRequest/
    const sequencingRunCompleteRoute = /api\/sequencingRuns\/complete/
    const sequencingRunGenotypesRoute = /api\/sequencingRuns\/genotypes/
    mock.onGet(sequencingRunsRoute).reply((config: any) =>
        [
            200,
            mockSequencingRuns(
                {
                    pageSize: config.params.pageSize, pageNumber: config.params.pageNumber
                },
                {
                    withExportStatus: config.params.withExportStatus,
                    name: config.params.name
                },
                runs
            ),
        ])
    mock.onGet(sequencingRunRoute).reply(200, runs[0])
    mock.onGet(sequencingRunAnalysesRoute, { params: { withStatus: AnalysisStatuses.AWAITING_REVIEW } })
        .reply((config) => {
            const runId = config.url?.match(sequencingRunAnalysesRoute)?.[1]
            return [
                200,
                analysesForReview.filter(it => it.sequencingRunUuid === runId),
            ]
        })
    mock.onGet(sequencingRunAnalysesRoute, { params: { withStatus: AnalysisStatuses.AWAITING_APPROVE } })
        .reply((config) => {
            switch (config.url?.match(sequencingRunAnalysesRoute)?.[1]) {
                case "fake-run-1": {
                    return [
                        200,
                        analysesForApprove.filter(it => it.sequencingRunUuid === "fake-run-1"),
                    ]
                }
                case "fake-run-2": {
                    return [
                        200,
                        analysesForApprove.filter(it => it.sequencingRunUuid === "fake-run-2"),
                    ]
                }
            }

            return [
                200,
                analysesForApprove,
            ]
        })
    mock.onGet(sequencingRunAnalysesRoute)
        .reply(200, analyses)
    mock.onGet(sequencingRunAggregatesHavingAnalysesRoute, { params: { withStatus: AnalysisStatuses.AWAITING_REVIEW } }).reply(200, runsWithAnalysesForReview)
    mock.onGet(sequencingRunAggregatesHavingAnalysesRoute, { params: { withStatus: AnalysisStatuses.AWAITING_APPROVE } }).reply(200, runsWithAnalysesForApprove)

    mock.onGet(knownAmbiguitiesRoute).reply(200, "?knownAmbiguitiesStub")
    mock.onGet(pipelineThresholdsRoute).reply(200, "?thresholdsStub")
    mock.onGet("?thresholdsStub").reply(200, pipelineThresholds)
    mock.onGet("?knownAmbiguitiesStub").reply(200, knownAmbiguities)
    mock.onGet(pipelineAnnotationsRoute).reply(200, "?annotationsStub")
    mock.onGet("?annotationsStub").reply(200, annotations)
    mock.onPost(analysisApproveRoute).reply(200)
    mock.onPost(analysisSupportRequestRoute).reply(200)
    mock.onPost(sequencingRunCompleteRoute).reply(200, [ "1" ])
    mock.onPost(sequencingRunGenotypesRoute).reply(200)
}


export function tearDownAxiosMock() {
    console.debug("Tear down axios mock")
    mock!.reset()
}
