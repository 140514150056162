<template>
    <div class="lot-management-app">
        <div class="entity-list">
            <h2 class="h2 mb-2">{{ $t("lots") }}</h2>
            <div style="display: flex; flex-direction: row; gap: 1rem;">
                <p-input
                    class="md-outlined mb-2"
                    style="flex: 1 1 0"
                    type="text"
                    :placeholder="$t('lotName')"
                    v-model="lotSearch"/>
                <p-select-with-search
                    class="md-outlined mb-2"
                    style="flex: 1 1 0"
                    fixed
                    clearable
                    v-model="lotPiplineSearch"
                    :placeholder="$tc('pipeline', 1)"
                    :options="pipelines"
                    :get-key="v => v.id"
                    :view="v => v.name"
                />
            </div>
            <div v-if="loadingLots" class="flex" style="height: 100%; width: 100%; justify-content: center;">
                <p-spinner  />
            </div>
            <ul v-if="!loadingLots" class="overflow-auto custom-scroll"
                style="flex-grow: 1; padding-bottom: 6rem">
                <li :key="lot.id"
                    v-for="lot in filteredLots"
                    class="lot">
                    <span style="width: 5em">{{ lot.name }}</span>
                    <span style="width: 5em"
                          class="badge design-version-badge"
                          v-for="designVersion in [{representation: reagentKitLotDesignVersion(lot), key: lot.id}]"
                          :key="designVersion.key"
                          :class="designVersion.representation"
                    >
                        {{ designVersion.representation }}
                    </span>
                    <div v-if="lotBeingEdited === null || lotBeingEdited.id !== lot.id"
                         style="width: 50%"
                         class="flex">{{ lot.configuredPipeline.name }}
                    </div>
                    <p-select-with-search
                        class="md-outlined"
                        style="width: 50%"
                        v-model="lotBeingEdited.configuredPipeline"
                        :options="pipelines"
                        :get-key="v => v.id"
                        :view="v => v.name"
                        v-else
                    />
                    <p-with-tooltip fixed>
                        <span>{{ $d(lot.expirationDate) }}</span>
                        <template #tooltip>
                            <div class="tooltip-black">{{ $t("expirationDate") }}</div>
                        </template>
                    </p-with-tooltip>
                    <div style="width: 8em; text-align: right">
                        <p-icon-button @click="startLotModification(lot)"
                                    class="ml-1 md-round-icon-btn edit-btn ml-auto"
                                    v-if="lotBeingEdited === null || lotBeingEdited.id !== lot.id"
                                    icon-name="edit"
                                    size="1.5em"/>
                        <div class="flex"
                             style="flex-shrink: 0"
                             v-else>
                            <p-icon-button @click="discardLotModifications(lotBeingEdited)"
                                        class="ml-auto md-round-icon-btn"
                                        style="color: var(--red); flex-shrink: 0"
                                        icon-name="clear"
                                        size="1.5em"/>
                            <p-icon-button @click="saveLotModifications(lotBeingEdited)"
                                        class="ml-1 md-round-icon-btn"
                                        :disabled="!lotBeingEdited.configuredPipeline"
                                        :class="{ 'text-green': lotBeingEdited.configuredPipeline }"
                                        style="flex-shrink: 0"
                                        icon-name="checkmark"
                                        size="1.5em"/>
                        </div>
                    </div>
                </li>
            </ul>
            <form
                class="mb-2 create-lot-form"
                v-if="lotBeingCreated">
                <p-input class="md-outlined mb-2 mt-1"
                         :placeholder="$t('lotName')"
                         autocomplete="off"
                         required="true"
                         :validation-errors="lotBeingCreated.name.errors"
                         v-model="lotBeingCreated.name.value"/>
                <p-select-with-search
                    class="md-outlined ml-1 mr-1 mb-2 mt-1"
                    style="width: 20em"
                    fixed
                    align="bottom"
                    v-model="lotBeingCreated.configuredPipeline.value"
                    :validation-errors="lotBeingCreated.configuredPipeline.errors"
                    :placeholder="$tc('pipeline', 1)"
                    :options="pipelines"
                    :get-key="v => v.id"
                    :view="v => v.name"
                />
                <p-input :validation-errors="lotBeingCreated.expirationDate.errors"
                         class="md-outlined mb-2 mt-1"
                         type="date"
                         @input.native="lotBeingCreated.expirationDate.value = $event.target.valueAsDate"
                         :value="lotBeingCreated.expirationDate.value && lotBeingCreated.expirationDate.value.toISOString().split('T')[0]"
                         required="true"
                         autocomplete="off"
                         :placeholder="$t('expirationDate')"
                />
                <div class="btn-group ml-1 mb-2 mt-1">
                    <button class="btn-group--btn"
                            :key="reagentKitLotDesignVersionName"
                            :class="{ 'btn-group--btn-active': lotBeingCreated.designVersionName.value === reagentKitLotDesignVersionName }"
                            @click="lotBeingCreated.designVersionName.value = reagentKitLotDesignVersionName"
                            v-for="reagentKitLotDesignVersionName in ['a', 'b']"> <!-- TODO: Don not hardcode the list, fetch it from the server instead -->
                        {{ "v3" + reagentKitLotDesignVersionName }}
                    </button>
                </div>
                <div class="flex" style="width: 8em; text-align: right; flex-shrink: 0">
                    <p-icon-button @click="discardLotCreation(lotBeingCreated)"
                                class="ml-auto md-round-icon-btn"
                                style="color: var(--red); flex-shrink: 0"
                                icon-name="clear"
                                size="1.5em"/>
                    <p-icon-button @click="createLot(lotBeingCreated)"
                                :disabled="!lotBeingCreated.isValid"
                                :class="{ 'text-green': lotBeingCreated.isValid }"
                                class="ml-1 md-round-icon-btn"
                                style="flex-shrink: 0"
                                icon-name="checkmark"
                                size="1.5em"/>
                </div>
            </form>
            <button
                class="md-btn-contained royal-blue mh-1"
                style="position: absolute; bottom: 1rem"
                @click="startLotCreation"
                v-else
            >
                {{ $t("createNewLot") }}
            </button>
        </div>
        <div class="entity-list relative">
            <h2 class="h2 mb-2">{{ $tc("pipeline", 0) }}</h2>
            <div>
                <p-input
                    type="text"
                    class="md-outlined mb-2"
                    :placeholder="$t('pipelineName')"
                    v-model="pipelineSearch"/>
            </div>
            <ul class="overflow-auto custom-scroll" style="padding-bottom: 6rem">
                <li :key="pipeline.id"
                    v-for="pipeline in filteredPipelines"
                    class="pipeline">
                    <span>{{ pipeline.name }}</span>
                    <div class="ml-auto" style="display: flex; align-items: center;">
                        <span v-if="pipeline.id === actualPipelineId">
                            {{ $t("currentPipeline") }}
                        </span>
                        <p-icon-button v-if="pipeline.id !== actualPipelineId" @click="setActualPipeline(pipeline.id)"
                                    class="mr-1 ml-auto md-round-icon-btn edit-btn"
                                    icon-name="recommend"
                                    size="1.5em"/>
                        <p-icon v-else class="mr-1 ml-auto md-round-icon-btn" icon-name="recommend"
                                    size="2.9em"/>

                        <span class="badge yellow ml-auto">{{ pipeline.imgtVersion }}</span>
                    </div>
                </li>
            </ul>
            <button class="md-btn-contained royal-blue mh-1"
                    style="position: absolute; bottom: 1rem"
                    @click="synchronizePipelines">{{ $t("synchronize") }}
            </button>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/store"
    import { mapGetters, mapMutations } from "vuex"
    import { lotManagementActionNames, lotManagementGetterNames } from "@/store/modules/lot-management"
    import { cloneDeep } from "lodash"
    import { Locales } from "@/i18n/main"
    import { ValidatableInput } from "@/validation/validatable-input"
    import { createLotNameValidator, required } from "@/validation/validators"
    import { EventBus } from "@/event-bus"
    import { lotManagmentEndpoints } from "@/endpoints"

    export default {
        name: "LotManagement",
        async beforeRouteEnter(to, from, next) {
            await Promise.all([
                store.dispatch(lotManagementActionNames.fetchAllLots),
                store.dispatch(lotManagementActionNames.fetchAllPipelines),
            ])
            next()
        },
        methods: {
            synchronizePipelines() {
                this.$store.dispatch(lotManagementActionNames.synchronizePipelines)
            },
            async saveLotModifications(lot) {
                await store.dispatch(lotManagementActionNames.saveLotModifications, lot)
                this.lotBeingEdited = null
            },
            startLotModification(lot) {
                this.lotBeingEdited = cloneDeep(lot)
            },
            discardLotModifications() {
                this.lotBeingEdited = null
            },
            startLotCreation() {
                this.lotBeingCreated = new NewLotForm()
                this.lotBeingCreated.configuredPipeline.value = this.pipelines[0]
            },
            async createLot(lot) {
                await store.dispatch(lotManagementActionNames.createLot, lot.valuesAsObject)
                this.lotBeingCreated = null
            },
            discardLotCreation() {
                this.lotBeingCreated = null
            },
            reagentKitLotDesignVersion(reagentKitLot) {
                return reagentKitLot.maybeDesignVersionName && reagentKitLot.maybeDesignVersionName.toUpperCase()
                    || "legacy"
            },
            setActualPipeline(configuredPipelineId) {
                this.loadingLots = true
                lotManagmentEndpoints.setActualPipeline(configuredPipelineId)
                this.actualPipelineId = configuredPipelineId
                store.commit("sortPipelines", this.actualPipelineId)
                // eslint-disable-next-line promise/catch-or-return
                store.dispatch(lotManagementActionNames.fetchAllLots)
                    .then(() => this.loadingLots = false)
            }
        },
        data() {
            return {
                lotBeingEdited: null,
                lotBeingCreated: null,
                pipelineSearch: "",
                lotSearch: "",
                lotPiplineSearch: "",
                actualPipelineId: "",
                loadingLots: false
            }
        },
        async mounted() {
            this.actualPipelineId = (await lotManagmentEndpoints.fetchActualPipeline()).id
            store.commit("sortPipelines", this.actualPipelineId)
        },
        computed: {
            ...mapGetters({
                lots: lotManagementGetterNames.lots,
                pipelines: lotManagementGetterNames.pipelines
            }),
            ...mapMutations([
                "sortPipelines",
            ]),
            filteredLots() {
                return this.lots.filter(it => it.name.includes(this.lotSearch) && it.configuredPipeline.name.includes(this.lotPiplineSearch?.name ?? ""))
            },
            filteredPipelines() {
                return this.pipelines.filter(it => it.name.includes(this.pipelineSearch))
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    expirationDate: "Срок годности",
                    lotName: "Серия",
                    pipelineName: "Имя пайплайна",
                    synchronize: "Синхронизировать",
                    lots: "Серии реагентов",
                    pipeline: "Пайплайн | Пайплайны",
                    createNewLot: "Новая серия"
                },
                [Locales.EN]: {
                    expirationDate: "Expiration date",
                    lotName: "Lot number",
                    pipelineName: "Pipeline name",
                    synchronize: "Synchronize",
                    lots: "Lots",
                    pipeline: "Pipeline | Pipelines",
                    createNewLot: "Create new lot"
                }
            }
        }
    }

    class NewLotForm {

        constructor() {
            this._eventBus = new EventBus()
            this.name = new ValidatableInput("name", this._eventBus, createLotNameValidator(store))
            this.configuredPipeline = new ValidatableInput("configuredPipeline", this._eventBus, required("configuredPipeline"))
            this.expirationDate = new ValidatableInput("expirationDate", this._eventBus, required("expirationDate"))
            this.designVersionName = new ValidatableInput("designVersionName", this._eventBus, required("designVersionName"))
        }

        get isValid() {
            return (this.name.errors.length === 0)
                && this.configuredPipeline.errors.length === 0
                && this.expirationDate.errors.length === 0
                && this.designVersionName.errors.length === 0
        }

        get valuesAsObject() {
            return Object.entries(this)
                .filter(([ key ]) => !key.startsWith("_"))
                .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value.value }), {})
        }
    }
</script>

<style scoped>
.lot-management-app {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 6rem;
    grid-template-rows: 100%;
    padding: 1rem 2rem;
    height: 100%;
}

.entity-list {
    display: flex;
    flex-flow: column;
    position: relative;
}

.create-lot-form,
.lot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .7rem 1rem;
    margin: .9rem .5rem;
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, .01), 0 3px 5px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
}

.create-lot-form {
    position: absolute;
    bottom: 1rem;
    background-color: white;
    flex-wrap: wrap;
    z-index: var(--second-layer);
}

.pipeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .7rem 1rem;
    margin: .9rem .5rem;
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, .01), 0 3px 5px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
}

.edit-btn {
    opacity: 0;
    pointer-events: none;
}

.lot:hover .edit-btn {
    opacity: 1;
    pointer-events: all;
}

.pipeline:hover .edit-btn {
    opacity: 1;
    pointer-events: all;
}

.create-lot-form,
.pipeline:hover,
.lot:hover {
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, .05), 0 3px 5px 2px rgba(0, 0, 0, 0.07);
}

.btn-group {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--parseq-blue);
    width: fit-content;
    display: flex;
}

.btn-group--btn {
    transition: background-color .2s;
    padding: 1rem;
}

.btn-group--btn:hover:not(.btn-group--btn-active) {
    background-color: hsl(225, 73%, 95%);
}

.btn-group--btn:not(:last-child) {
    border-right: 1px solid var(--parseq-blue);
}

.btn-group--btn-active {
    background-color: var(--parseq-blue);
    color: white;
}

.design-version-badge {
    border: 2px solid var(--grey-400);
    font-weight: 600;
    color: var(--grey-400);
}

.design-version-badge.A {
    border: 2px solid var(--parseq-blue);
    color: var(--parseq-blue);
}

.design-version-badge.B {
    border: 2px solid var(--parseq-orange);
    color: var(--parseq-orange);
}
</style>
