<template>
    <div
        class="relative"
        ref="container"
        tabindex="-1"
        @focusout="dropdownFocusoutHandler"
    >
        <slot
            name="activator"
            :toggleDropdown="toggleDropdown"
        />
        <div
            v-show="dropdownShown"
            :style="dropdownPosition"
            :class="[align, fixed ? 'fixed' : 'absolute', fullWidth ? 'w-100': '']"
            class="dropdown-wrapper dropdown custom-scroll"
        >
            <slot :closeDropdown="closeDropdown" />
        </div>
    </div>
</template>

<script>
    import { dropdownMixin } from "@/mixins/dropdown-mixin"

    export default {
        name: "PDropdown",
        mixins: [ dropdownMixin ],
        /*
         * Little hack to use this component as a part of the other one. e.g. selects where we want
         * a little bit more control over dropdown state.
         *
         * Previously dropdown template and state were
         * duplicated in selects and dropdown mixin was used to remove state control
         * boilerplate, however, template is also sophisticated with all it's classes,
         * so to avoid it's copy paste I introduced this hack.
         * May be one more redesign will lead us to a brighter future.
         */
        watch: {
            dropdownShown(val) {
                if (this.value !== undefined && this.value !== val) {
                    this.$emit("input", val)
                }
            },
            value(val) {
                if (this.dropdownShown !== val) {
                    this.dropdownShown = val
                }
            }
        },
        props: {
            fullWidth: {
                type: Boolean,
                default: false
            },
            value: {
                type: Boolean,
                required: false
            }
        },
    }
</script>

<style scoped>
    .dropdown-wrapper {
        background-color: transparent;
        border-radius: 2px;
        overflow: auto;
        z-index: var(--dropdown-layer);
    }
</style>
