<template>
    <GenericAnalysesReview :fetch-analyses="fetchAnalysesToReview"
                           :fetch-runs="fetchRunsWithAnalysesToReview"
                           :approve="approve"
                           :send-to-support="sendToSupport"
    >
        <template #approve-btn-text>
            {{ $t("buttons.review") }}&nbsp;<span
            style="transform: translateY(-10%)">&darr;</span>
        </template>
        <template v-slot:default="slotProps">
            <analysis-card
                :analysis="slotProps.analysis"
                :key="slotProps.analysis.id"
                @modal-opened="slotProps.removeKeydownListener"
                @modal-closed="slotProps.setKeydownListener"
            />
        </template>
        <template #fallback-text>
            {{ $t("nothingToReview") }}
        </template>
    </GenericAnalysesReview>
</template>

<script>
    import { modalMixin } from "@/mixins/modal-mixin"
    import GenericAnalysesReview from "@/components/generic-analyses-review"
    import { Locales } from "@/i18n/main"
    import {
        fetchAnalysesToReview,
        fetchRunsWithAnalysesToReview,
        markAnalysisAsReviewed,
        sendAnalysisToSupport,
    } from "@/endpoints"

    import AnalysisCard from "./components/analysis-card"


    export default {
        name: "AnalysesReview",
        mixins: [ modalMixin ],
        components: {
            AnalysisCard,
            GenericAnalysesReview
        },
        methods: {
            fetchAnalysesToReview,
            fetchRunsWithAnalysesToReview,
            approve(analysis) {
                return markAnalysisAsReviewed(analysis.id)
                    .then(() => this.$store.commit("decreaseAwaitingReviewAnalysisNumber"))
            },
            sendToSupport(analysis, { comment, loci }) {
                return sendAnalysisToSupport({ analysisId: analysis.id, comment, loci })
                    // eslint-disable-next-line promise/always-return
                    .then(() => {
                        this.$store.commit("decreaseAwaitingReviewAnalysisNumber")
                        this.$store.commit("increaseAwaitingSupportAnalysisNumber")
                    })

            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    nothingToReview: "No analyses to review"
                },
                [Locales.RU]: {
                    nothingToReview: "Анализов на утверждение нет"
                }
            }
        }
    }
</script>
