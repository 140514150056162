<template>
    <div class="flex">
        <span>{{ allelePart }}</span>
        <div class="badge"
             style="font-size: .5em; margin-left: .2rem; color: white;"
             :style="{ backgroundColor: annotationColor }"
             v-if="annotation"
        >{{ annotation }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AllelePart",
        props: {
            allelePart: {
                type: String,
                required: true
            },
            annotations: Object
        },
        computed: {
            annotation() {
                return this.annotations && this.annotations[this.allelePart]
            },
            annotationColor() {
                return annotationColor(this.annotation)
            }
        }
    }

    function annotationColor(annotation) {
        switch (annotation) {
            case "N":
                return "var(--red)"
            default:
                return "var(--grey-600)"
        }
    }
</script>
