import { IVueI18n } from "vue-i18n"

export function remainingTime(i18n: IVueI18n, timeInMillis: number) {
    const decimalPlaces = 1
    if (timeInMillis === Number.POSITIVE_INFINITY) return "?"
    const minutes = timeInMillis / 60000
    if (minutes < 1) {
        return `< 1 ${i18n.t("min")}`
    } else {
        return minutes > 59
            ? `~ ${(minutes / 60).toFixed(decimalPlaces)} ${i18n.t("h")}`
            : `~ ${minutes.toFixed(decimalPlaces)} ${i18n.t("min")}`
    }
}
