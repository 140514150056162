var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticStyle:{"font-size":"1.8rem","font-weight":"600","margin-bottom":"2rem"}},[_vm._v(_vm._s(_vm.$t("sampleLoadError")))]),_vm._v(" "+_vm._s(_vm.$tc( "samplesCannotBeLoaded", _vm.error.details.samplesWithErrors.length, [ _vm.error.details.samplesWithErrors.map(function (ref) {
	var sample = ref[0];
	var _ = ref[1];

	return sample.name;
}).join(", ") ] ))+" "),_c('div',{staticStyle:{"margin":"1rem 0 2rem 0","border":"1px solid var(--grey-100)","border-radius":"4px","padding":"1rem"}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("sample")))]),_c('th',[_vm._v(_vm._s(_vm.$t("errors")))])])]),_c('tbody',_vm._l((_vm.error.details.samplesWithErrors),function(ref){
var sample = ref[0];
var errors = ref[1];
return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(sample.name))]),_c('td',[_vm._l((errors),function(error){return [(error.type === 'forwardFilesCount')?_c('p',[_vm._v(_vm._s(_vm.$t(error.type, [ error.details.expected, error.details.actual ])))]):_vm._e(),(error.type === 'reverseFilesCount')?_c('p',[_vm._v(_vm._s(_vm.$t(error.type, [ error.details.expected, error.details.actual ])))]):_vm._e(),(error.type === 'wrongSampleName')?_c('p',[_vm._v(_vm._s(_vm.$t(error.type)))]):_vm._e()]})],2)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }