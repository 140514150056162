<template>
    <div>
        <form
            class="create-new-allele-form overflow-auto mb-4"
            @input="checkFormValidity"
        >
            <!-- eslint-disable vue/no-mutating-props-->
            <p-input
                class="md-outlined mr-1 mt-1"
                type="text"
                v-model="newAlleleForm.referenceAllele.value"
                :validation-errors="newAlleleForm.referenceAllele.errors"
                :placeholder="$t('referenceAllele')"
            />
            <section
                class="overflow-auto variants flex-no-shrink"
            >
                <h3 style="font-weight: 400;">{{ $t("referenceAlleleVariants") }}</h3>
                <ul class="overflow-auto custom-scroll">
                    <!-- eslint-disable-next-line vue/require-v-for-key -->
                    <li
                        v-for="variant in newAlleleForm.variants.value"
                        class="mv-2"
                    >
                        <fieldset class="variant flex">
                            <p-input
                                class="md-outlined mr-1 variant__field"
                                type="text"
                                v-model="variant.region.value"
                                :validation-errors="variant.region.errors"
                                :placeholder="$t('region')"
                            />
                            <p-input
                                class="md-outlined variant__field"
                                type="text"
                                v-model="variant.genomicHgvs.value"
                                :validation-errors="variant.genomicHgvs.errors"
                                :placeholder="$t('genomicHgvs')"
                            />
                            <p-input
                                class="md-outlined ml-1 variant__field"
                                type="text"
                                v-model="variant.proteinHgvs.value"
                                :validation-errors="variant.proteinHgvs.errors"
                                :placeholder="$t('proteinHgvs')"
                            />
                        </fieldset>
                    </li>
                </ul>
                <div>
                    <button
                        class="md-btn-outlined royal-blue"
                        @click="addNewVariant"
                        :disabled="!formIsValid"
                    >{{ $t("addVariant") }}
                    </button>
                </div>
            </section>
        </form>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import { StandaloneValidatableInput } from "@/validation/validatable-input"
    import { requiredStandalone } from "@/validation/validators"

    export default {
        name: "choose-metadate-new-allele",
        props: {
            newAlleleForm: Object
        },
        data() {
            return {
                formIsValid: null,
                variantIsValid: null,
                formIsValidPromise: Promise.resolve(),
            }
        },
        methods: {
            async addNewVariant() {
                if(await this.checkFormVariantsValidity()) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.newAlleleForm.variants.value = [ ...this.newAlleleForm.variants.value, createEmptyVariant() ]
                }
                this.checkFormValidity()
            },
            async checkFormVariantsValidity(){
                this.variantIsValid = await Promise.all([
                    ...this.newAlleleForm.variants.value.reduce((accumulator, variant) => {
                        return accumulator.concat([
                            variant.region.isValid(),
                            variant.genomicHgvs.isValid(),
                            variant.proteinHgvs.isValid(),
                        ])
                    }, []),
                ])
                return this.variantIsValid.every(it => it)
            },
            async checkFormValidity() {
                this.formIsValidPromise = this.formIsValidPromise.then(() => {
                    return Promise.all([
                        this.newAlleleForm.referenceAllele.isValid(),
                        ...this.newAlleleForm.variants.value.reduce((accumulator, variant) => {
                            return accumulator.concat([
                                variant.region.isValid(),
                                variant.genomicHgvs.isValid(),
                                variant.proteinHgvs.isValid(),
                            ])
                        }, []),
                    ])
                })
                    .then(results => {
                        this.formIsValid = results.every(it => it)
                        return this.formIsValid
                    })
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    referenceAllele: "Reference allele",
                    referenceAlleleVariants: "Reference allele variants",
                    addVariant: "Add another option",
                    alleleSequence: "New allele sequence",
                    region: "Region",
                    genomicHgvs: "Nucleotide substitution",
                    proteinHgvs: "Amino acid replacement"
                },
                [Locales.RU]: {
                    referenceAllele: "Референсная аллель",
                    referenceAlleleVariants: "Варианты относительно референсной аллели",
                    addVariant: "Добавить ещё вариант",
                    alleleSequence: "Последовательность новой аллели",
                    region: "Регион",
                    genomicHgvs: "Нуклеотидная замена",
                    proteinHgvs: "Аминокислотная замена"
                }
            }
        }
    }

    function createEmptyVariant() {
        return {
            region: new StandaloneValidatableInput(requiredStandalone, ""),
            genomicHgvs: new StandaloneValidatableInput(requiredStandalone, ""),
            proteinHgvs: new StandaloneValidatableInput(requiredStandalone, ""),
        }
    }
</script>

<style scoped>
.variants {
    grid-column: 1/2;
    grid-row: 2/-1;

    display: flex;
    flex-flow: column;
}

.variant {
    border: none;
}

.variant__field {
    width: 14em;
}

.create-new-allele-form {
    flex-grow: 1;

    display: grid;
    grid-template-rows: min-content 1fr 2fr;
    grid-template-columns: minmax(min-content, 40%) 1fr;
    grid-row-gap: 4rem;
}
</style>
