import { Analysis } from "@/utils/analysis"

/*
 181 and 179 are intentionally missing for interactive docs purposes
 */
export const analyses: Array<Analysis> = [{
    "id": "1c91a919-cb74-3cdd-97f5-20d84b865dbb",
    "runId": 1,
    "name": "52104",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917329691,
    "status": "LAB_FAILURE",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 25396,
                    "quality": "red"
                }
            }
        },
        "genotypes": {
            "A": null,
            "B": null,
            "C": null,
            "DQB1": null,
            "DRB1": null
        },
        "resolution": "LAB_ISSUE",
        "sampleName": "52104_S1",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.50779638775464,
            "readsDistribution": {
                "targetLoci": 10352,
                "relatedLoci": 4120,
                "qualityTrimmed": 3726,
                "offTargetRegions": 8198
            },
            "targetLociCoverage": {
                "A": 21390,
                "B": 9492,
                "C": 21288,
                "DQB1": 12970,
                "DRB1": 20236
            }
        },
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "8060edbd-0c4e-3ebe-8ac8-c8f5cdf48fa8",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "1c91a919-cb74-3cdd-97f5-20d84b865dbb",
        "prior_analysis_state_modified_at": "2020-02-12T13:20:30.441+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "1d95df1e-80ea-3aef-a8b1-0460ae14c2cb",
    "runId": 2,
    "name": "52105",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917348622,
    "status": "LAB_FAILURE",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "generalMetrics": {
                "contamination": {
                    "type": "LAB_ISSUE",
                    "value": true,
                    "quality": "red"
                }
            }
        },
        "genotypes": {
            "A": null,
            "B": null,
            "C": null,
            "DQB1": null,
            "DRB1": null
        },
        "resolution": "LAB_ISSUE",
        "sampleName": "52105_S2",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 432,
            "contamination": true,
            "meanReadsQuality": 35.47047016171034,
            "readsDistribution": {
                "targetLoci": 77636,
                "relatedLoci": 2592,
                "qualityTrimmed": 22906,
                "offTargetRegions": 21200
            },
            "targetLociCoverage": {
                "A": 19102,
                "B": 7682,
                "C": 17988,
                "DQB1": 12442,
                "DRB1": 20422
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b61d665c-aba3-3335-99c1-8b37a19a3eae",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "1d95df1e-80ea-3aef-a8b1-0460ae14c2cb",
        "prior_analysis_state_modified_at": "2020-02-26T09:32:26.701+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "317716fc-bfc2-32b8-a4b1-084da8a560e1",
    "runId": 3,
    "name": "52106",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917340080,
    "status": "LAB_FAILURE",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "generalMetrics": {
                "insertSize": {
                    "type": "LAB_ISSUE",
                    "value": 50,
                    "quality": "red"
                }
            }
        },
        "genotypes": {
            "A": null,
            "B": null,
            "C": null,
            "DQB1": null,
            "DRB1": null
        },
        "resolution": "LAB_ISSUE",
        "sampleName": "52106_S3",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 50,
            "contamination": false,
            "meanReadsQuality": 35.17876258039132,
            "readsDistribution": {
                "targetLoci": 75158,
                "relatedLoci": 12508,
                "qualityTrimmed": 31184,
                "offTargetRegions": 25230
            },
            "targetLociCoverage": {
                "A": 15722,
                "B": 8524,
                "C": 17218,
                "DQB1": 16032,
                "DRB1": 17662
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "29373841-cfff-353c-be9d-3bf8a0da5793",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "317716fc-bfc2-32b8-a4b1-084da8a560e1",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:00.863+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "1482cff4-b243-3c45-a8fe-00165f925efc",
    "runId": 4,
    "name": "52107",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917335745,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "insertSize": {
                    "type": "LAB_ISSUE",
                    "value": 700,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": null,
            "B": null,
            "C": null,
            "DQB1": null,
            "DRB1": null
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52107_S4",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 700,
            "contamination": false,
            "meanReadsQuality": 35.22472034224924,
            "readsDistribution": {
                "targetLoci": 72496,
                "relatedLoci": 10896,
                "qualityTrimmed": 28946,
                "offTargetRegions": 22858
            },
            "targetLociCoverage": {
                "A": 18512,
                "B": 7794,
                "C": 15638,
                "DQB1": 13912,
                "DRB1": 16640
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6a6031e5-6c0c-3aa5-b61c-3097aa0a587f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "1482cff4-b243-3c45-a8fe-00165f925efc",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:02.344+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "5e935839-58fc-3cf5-8b52-c0e59681e436",
    "runId": 5,
    "name": "52108",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917332704,
    "status": "LAB_FAILURE",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 25396,
                    "quality": "red"
                },
                "insertSize": {
                    "type": "LAB_ISSUE",
                    "value": 200,
                    "quality": "yellow"
                },
                "contamination": {
                    "type": "LAB_ISSUE",
                    "value": true,
                    "quality": "red"
                }
            }
        },
        "genotypes": {
            "A": null,
            "B": null,
            "C": null,
            "DQB1": null,
            "DRB1": null
        },
        "resolution": "LAB_ISSUE",
        "sampleName": "52108_S5",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 200,
            "contamination": true,
            "meanReadsQuality": 35.334257629110255,
            "readsDistribution": {
                "targetLoci": 10352,
                "relatedLoci": 4120,
                "qualityTrimmed": 3726,
                "offTargetRegions": 8198
            },
            "targetLociCoverage": {
                "A": 23588,
                "B": 10084,
                "C": 20758,
                "DQB1": 13842,
                "DRB1": 21268
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "967fd075-80b1-30ea-b8ec-b7fe3ef6ec48",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "5e935839-58fc-3cf5-8b52-c0e59681e436",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:03.708+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "b762942a-c294-3a05-a028-01e79932b392",
    "runId": 6,
    "name": "52109",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917347437,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "44:03+52:01",
            "C": "04:01+12:02",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52109_S6",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 423,
            "contamination": false,
            "meanReadsQuality": 35.15169952400572,
            "readsDistribution": {
                "targetLoci": 74640,
                "relatedLoci": 9890,
                "qualityTrimmed": 29934,
                "offTargetRegions": 23222
            },
            "targetLociCoverage": {
                "A": 17508,
                "B": 8610,
                "C": 17006,
                "DQB1": 11668,
                "DRB1": 19848
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7da5a60b-e37a-3879-b77d-31487974767e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "b762942a-c294-3a05-a028-01e79932b392",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:05.344+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "565371e1-a32f-34a3-a16e-29df304e006c",
    "runId": 7,
    "name": "52110",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917346842,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 95748,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "02:01+03:01",
            "B": "35:01+57:01",
            "C": "04:01+06:02",
            "DQB1": "02:02+03:03",
            "DRB1": "07:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52110_S7",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.374790461274316,
            "readsDistribution": {
                "targetLoci": 57336,
                "relatedLoci": 3940,
                "qualityTrimmed": 18372,
                "offTargetRegions": 16100
            },
            "targetLociCoverage": {
                "A": 13566,
                "B": 6734,
                "C": 13324,
                "DQB1": 6280,
                "DRB1": 17432
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7fa57f32-1f2e-380a-afd0-9934cb17c761",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "565371e1-a32f-34a3-a16e-29df304e006c",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:06.578+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null

    }]
}, {
    "id": "48b93296-079e-3207-9422-d80d5accb169",
    "runId": 8,
    "name": "52111",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917351977,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+57:01",
            "C": "06:02+07:01",
            "DQB1": "02:01+03:03",
            "DRB1": "03:01/03:147+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52111_S8",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 389,
            "contamination": false,
            "meanReadsQuality": 35.23014616545632,
            "readsDistribution": {
                "targetLoci": 78878,
                "relatedLoci": 18388,
                "qualityTrimmed": 34410,
                "offTargetRegions": 29600
            },
            "targetLociCoverage": {
                "A": 20582,
                "B": 8556,
                "C": 18854,
                "DQB1": 10552,
                "DRB1": 20334
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "e6120200-5734-37ca-991c-88f4624fb303",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "48b93296-079e-3207-9422-d80d5accb169",
        "prior_analysis_state_modified_at": "2020-02-26T09:34:11.819+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "71d8dc22-ec31-36e3-9b54-9733f41669ee",
    "runId": 9,
    "name": "52112",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917341339,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "33:01+33:03",
            "B": "07:02+58:01",
            "C": "03:02+07:02",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "04:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52112_S9",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.628555033610766,
            "readsDistribution": {
                "targetLoci": 99342,
                "relatedLoci": 22272,
                "qualityTrimmed": 33790,
                "offTargetRegions": 33892
            },
            "targetLociCoverage": {
                "A": 26008,
                "B": 11326,
                "C": 21912,
                "DQB1": 20982,
                "DRB1": 19114
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b7adc934-a368-3107-b7ad-15da3771d179",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "71d8dc22-ec31-36e3-9b54-9733f41669ee",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:21.394+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "09ded031-220e-38ce-b7f9-509e2ac95375",
    "runId": 10,
    "name": "52113",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917363943,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:06+03:01",
            "B": "35:03+51:01",
            "C": "03:04+04:01",
            "DQB1": "03:01/03:276N/03:297+04:02",
            "DRB1": "08:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52113_S10",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 480,
            "contamination": false,
            "meanReadsQuality": 35.44932375845835,
            "readsDistribution": {
                "targetLoci": 94736,
                "relatedLoci": 11720,
                "qualityTrimmed": 32548,
                "offTargetRegions": 27294
            },
            "targetLociCoverage": {
                "A": 23134,
                "B": 10386,
                "C": 21240,
                "DQB1": 16096,
                "DRB1": 23880
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "db67bc39-91c9-3e51-827c-c15ae7e550b6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "09ded031-220e-38ce-b7f9-509e2ac95375",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:23.164+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "f75462ff-8974-3289-96e8-6e4d31330b5f",
    "runId": 11,
    "name": "52114",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917340649,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+11:01",
            "B": "07:02+49:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "06:02+06:04|06:39+06:84",
            "DRB1": "13:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52114_S11",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 35.603358717098516,
            "readsDistribution": {
                "targetLoci": 79202,
                "relatedLoci": 17938,
                "qualityTrimmed": 27734,
                "offTargetRegions": 27590
            },
            "targetLociCoverage": {
                "A": 21230,
                "B": 9718,
                "C": 16916,
                "DQB1": 13530,
                "DRB1": 17808
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f8227182-8860-3be0-a9af-d1d85baa85db",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "f75462ff-8974-3289-96e8-6e4d31330b5f",
        "prior_analysis_state_modified_at": "2020-02-26T10:21:36.238+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "f6425b59-05b1-3ca8-9d6e-467e4091ff74",
    "runId": 12,
    "name": "52115",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917355417,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "C": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 2,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+25:01",
            "B": "18:01+41:02",
            "C": "12:03+17:03|12:03+17:39",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "13:03+15:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52115_S12",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 411,
            "contamination": false,
            "meanReadsQuality": 35.50404237931418,
            "readsDistribution": {
                "targetLoci": 115022,
                "relatedLoci": 19576,
                "qualityTrimmed": 41004,
                "offTargetRegions": 36682
            },
            "targetLociCoverage": {
                "A": 31456,
                "B": 11946,
                "C": 26570,
                "DQB1": 18110,
                "DRB1": 26940
            }
        }
    },
    maybeResolutionDraft: {
        "genotypes": {
            "C": "12:03+17:03"
        },
        "resolution": "assigned",
        annotations: null
    },
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c3d35af3-f086-3f07-9f0c-6b5fec9a5c04",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "9a1f7858-4e66-3b65-811e-5e2a50315f66",
    "runId": 13,
    "name": "52116",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917346248,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "26:01+26:01",
            "B": "40:02+56:01",
            "C": "01:02+03:03",
            "DQB1": "04:02+06:02",
            "DRB1": "08:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52116_S13",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 426,
            "contamination": false,
            "meanReadsQuality": 35.609302258541646,
            "readsDistribution": {
                "targetLoci": 93860,
                "relatedLoci": 6630,
                "qualityTrimmed": 29650,
                "offTargetRegions": 32970
            },
            "targetLociCoverage": {
                "A": 20954,
                "B": 10298,
                "C": 20162,
                "DQB1": 18002,
                "DRB1": 24444
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "51cd9f8d-b892-3831-a9ba-b99517bf5409",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "9a1f7858-4e66-3b65-811e-5e2a50315f66",
        "prior_analysis_state_modified_at": "2020-02-26T09:32:38.469+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "be3cd9f5-f5fc-36cb-ae53-7286e1f56f87",
    "runId": 14,
    "name": "52117",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917352381,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+11:01",
            "B": "27:05+51:01",
            "C": "02:02+04:01",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "04:07+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52117_S14",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 457,
            "contamination": false,
            "meanReadsQuality": 35.29021233078181,
            "readsDistribution": {
                "targetLoci": 77482,
                "relatedLoci": 5990,
                "qualityTrimmed": 29952,
                "offTargetRegions": 24950
            },
            "targetLociCoverage": {
                "A": 18358,
                "B": 9398,
                "C": 18266,
                "DQB1": 13598,
                "DRB1": 17862
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d5a0521a-36a3-37c4-8c91-8ba90b7a8c7d",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "be3cd9f5-f5fc-36cb-ae53-7286e1f56f87",
        "prior_analysis_state_modified_at": "2020-02-26T09:32:40.234+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "18ea0bbc-8007-3f32-9eba-5d0d2761f875",
    "runId": 15,
    "name": "52118",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917367964,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "29:02+31:01",
            "B": "38:01+44:03",
            "C": "12:03+16:01",
            "DQB1": "02:02+03:03",
            "DRB1": "07:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52118_S15",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.577876439560896,
            "readsDistribution": {
                "targetLoci": 91046,
                "relatedLoci": 14672,
                "qualityTrimmed": 30472,
                "offTargetRegions": 30470
            },
            "targetLociCoverage": {
                "A": 21990,
                "B": 8994,
                "C": 19966,
                "DQB1": 13012,
                "DRB1": 27084
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "45127a86-0032-3e3e-b36d-29875b9eb044",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "18ea0bbc-8007-3f32-9eba-5d0d2761f875",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:24.486+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "5aad35b0-cef7-37f7-9c13-b387183b8efa",
    "runId": 16,
    "name": "52119",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917336147,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "35:02+44:02",
            "C": "04:01+05:01",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:04+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52119_S16",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 384,
            "contamination": false,
            "meanReadsQuality": 35.45430529680266,
            "readsDistribution": {
                "targetLoci": 76006,
                "relatedLoci": 24952,
                "qualityTrimmed": 32782,
                "offTargetRegions": 32564
            },
            "targetLociCoverage": {
                "A": 20488,
                "B": 9806,
                "C": 19600,
                "DQB1": 8638,
                "DRB1": 17474
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "53a43579-d84f-343b-a5d5-8473976dfaf9",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "5aad35b0-cef7-37f7-9c13-b387183b8efa",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:26.118+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "bf79e838-aad4-3108-aa8a-ed4f654ec69c",
    "runId": 17,
    "name": "52120",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917367787,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "13:02+40:02",
            "C": "02:02+06:02",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52120_S17",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 382,
            "contamination": false,
            "meanReadsQuality": 35.89946582638393,
            "readsDistribution": {
                "targetLoci": 97130,
                "relatedLoci": 24692,
                "qualityTrimmed": 26090,
                "offTargetRegions": 33362
            },
            "targetLociCoverage": {
                "A": 23680,
                "B": 11586,
                "C": 23140,
                "DQB1": 19590,
                "DRB1": 19134
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a8894df1-0153-318c-8ce9-d427152225b0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "bf79e838-aad4-3108-aa8a-ed4f654ec69c",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:27.659+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "679c4afe-b6f1-3586-bd47-3d2289a8a29a",
    "runId": 18,
    "name": "52121",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917346445,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+27:02",
            "C": "02:02+07:02",
            "DQB1": "05:01+05:02",
            "DRB1": "01:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52121_S18",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 432,
            "contamination": false,
            "meanReadsQuality": 35.70475868499466,
            "readsDistribution": {
                "targetLoci": 156148,
                "relatedLoci": 21602,
                "qualityTrimmed": 43248,
                "offTargetRegions": 44294
            },
            "targetLociCoverage": {
                "A": 35228,
                "B": 15264,
                "C": 32226,
                "DQB1": 33790,
                "DRB1": 39640
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "29529499-bc39-398e-9791-b55b5562f452",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "679c4afe-b6f1-3586-bd47-3d2289a8a29a",
        "prior_analysis_state_modified_at": "2020-02-26T12:55:08.124+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "038cc217-4361-31d9-9105-5d29069176db",
    "runId": 19,
    "name": "52122",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917355259,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+07:02",
            "C": "07:02+07:02",
            "DQB1": "06:02+06:02",
            "DRB1": "15:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52122_S19",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 439,
            "contamination": false,
            "meanReadsQuality": 35.514923599943984,
            "readsDistribution": {
                "targetLoci": 123936,
                "relatedLoci": 5604,
                "qualityTrimmed": 34966,
                "offTargetRegions": 31822
            },
            "targetLociCoverage": {
                "A": 29778,
                "B": 13734,
                "C": 23886,
                "DQB1": 20072,
                "DRB1": 36466
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "55a05671-51b5-3375-99b4-dd74993a8939",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "038cc217-4361-31d9-9105-5d29069176db",
        "prior_analysis_state_modified_at": "2020-02-26T12:55:10.015+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "8ce9ea93-6c35-3a9a-a611-dae903c3394e",
    "runId": 20,
    "name": "52123",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917367188,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+35:03",
            "C": "04:01+07:02",
            "DQB1": "03:02+06:02",
            "DRB1": "08:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52123_S20",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 460,
            "contamination": false,
            "meanReadsQuality": 35.175375785888804,
            "readsDistribution": {
                "targetLoci": 137560,
                "relatedLoci": 8360,
                "qualityTrimmed": 52508,
                "offTargetRegions": 46802
            },
            "targetLociCoverage": {
                "A": 29326,
                "B": 15906,
                "C": 26924,
                "DQB1": 26152,
                "DRB1": 39252
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "3dac5b17-ef22-31ea-880e-acc5423359b5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "8ce9ea93-6c35-3a9a-a611-dae903c3394e",
        "prior_analysis_state_modified_at": "2020-02-26T12:55:15.292+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "9e22e7ce-4735-399a-97c2-234e22c14fd1",
    "runId": 21,
    "name": "52124",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343003,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+13:02",
            "C": "06:02+07:02",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:03+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52124_S21",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.728267086453506,
            "readsDistribution": {
                "targetLoci": 129906,
                "relatedLoci": 39938,
                "qualityTrimmed": 42468,
                "offTargetRegions": 52008
            },
            "targetLociCoverage": {
                "A": 32138,
                "B": 15466,
                "C": 28682,
                "DQB1": 25876,
                "DRB1": 27744
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "8c56af81-c39a-3c7b-a4d7-3727b0be1e39",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "9e22e7ce-4735-399a-97c2-234e22c14fd1",
        "prior_analysis_state_modified_at": "2020-02-26T12:55:19.553+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "3b20c690-4627-3a1b-9709-4b56f6cb0025",
    "runId": 22,
    "name": "52125",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917357102,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 324306,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 39250,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+29:02",
            "B": "14:02+27:05",
            "C": "02:02+08:02",
            "DQB1": "04:02+05:01",
            "DRB1": "01:02+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52125_S22",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 411,
            "contamination": false,
            "meanReadsQuality": 35.540368402779436,
            "readsDistribution": {
                "targetLoci": 179930,
                "relatedLoci": 26374,
                "qualityTrimmed": 58798,
                "offTargetRegions": 59204
            },
            "targetLociCoverage": {
                "A": 40840,
                "B": 16584,
                "C": 36646,
                "DQB1": 39250,
                "DRB1": 46610
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "842420fa-53eb-382d-a35e-950addfbccd2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "3b20c690-4627-3a1b-9709-4b56f6cb0025",
        "prior_analysis_state_modified_at": "2020-02-26T12:55:23.944+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "fbd64259-c53f-3a2f-8782-ebfb29e10581",
    "runId": 23,
    "name": "52126",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917348221,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+32:01",
            "B": "15:01+40:01",
            "C": "03:03+03:04",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:04+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52126_S23",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.72113636480645,
            "readsDistribution": {
                "targetLoci": 125164,
                "relatedLoci": 22728,
                "qualityTrimmed": 36962,
                "offTargetRegions": 46686
            },
            "targetLociCoverage": {
                "A": 32384,
                "B": 13956,
                "C": 28150,
                "DQB1": 27568,
                "DRB1": 23106
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "0c2a7de5-8857-3e98-9dac-b5d8c76a3f5a",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "fbd64259-c53f-3a2f-8782-ebfb29e10581",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:16.266+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "f7515306-3cfd-3714-abc2-40e5499b31a7",
    "runId": 24,
    "name": "52127",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917357893,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+25:01",
            "B": "07:02+35:01",
            "C": "04:01+07:02",
            "DQB1": "05:01+06:02",
            "DRB1": "01:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52127_S24",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 400,
            "contamination": false,
            "meanReadsQuality": 35.33841637433659,
            "readsDistribution": {
                "targetLoci": 94096,
                "relatedLoci": 14216,
                "qualityTrimmed": 33016,
                "offTargetRegions": 29670
            },
            "targetLociCoverage": {
                "A": 20714,
                "B": 11256,
                "C": 19882,
                "DQB1": 17450,
                "DRB1": 24794
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "5b60405e-b225-3595-8c0f-7ff8562cc426",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "f7515306-3cfd-3714-abc2-40e5499b31a7",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:16.706+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "3cab5d72-38a4-3766-8126-a40c20735c09",
    "runId": 25,
    "name": "52128",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917364591,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "15:01+15:16",
            "C": "04:01+14:02",
            "DQB1": "04:02+04:02",
            "DRB1": "04:04+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52128_S25",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 419,
            "contamination": false,
            "meanReadsQuality": 35.75145992170634,
            "readsDistribution": {
                "targetLoci": 103558,
                "relatedLoci": 9658,
                "qualityTrimmed": 27694,
                "offTargetRegions": 32548
            },
            "targetLociCoverage": {
                "A": 23324,
                "B": 10328,
                "C": 22572,
                "DQB1": 24162,
                "DRB1": 23172
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4759149b-cf6b-325e-aefb-a3934589fccb",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "3cab5d72-38a4-3766-8126-a40c20735c09",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:20.166+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "de1a3bcd-a51f-3524-a4e4-e359b41b1137",
    "runId": 26,
    "name": "52129",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917334942,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+25:01",
            "B": "18:01+37:01",
            "C": "06:02+12:03",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52129_S26",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 440,
            "contamination": false,
            "meanReadsQuality": 35.62259623087269,
            "readsDistribution": {
                "targetLoci": 121640,
                "relatedLoci": 18758,
                "qualityTrimmed": 35782,
                "offTargetRegions": 37936
            },
            "targetLociCoverage": {
                "A": 28302,
                "B": 10922,
                "C": 27290,
                "DQB1": 26204,
                "DRB1": 28922
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "42dbc784-3d6d-33bc-b0c3-7ae91a2714cb",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "de1a3bcd-a51f-3524-a4e4-e359b41b1137",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:20.295+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "7a6fb22d-f82e-3ef4-a78b-98d073461831",
    "runId": 27,
    "name": "52130",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917351361,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+26:01",
            "B": "38:01+39:06",
            "C": "07:02+12:03",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:03+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52130_S27",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 425,
            "contamination": false,
            "meanReadsQuality": 35.59767104587293,
            "readsDistribution": {
                "targetLoci": 122892,
                "relatedLoci": 21610,
                "qualityTrimmed": 37946,
                "offTargetRegions": 37040
            },
            "targetLociCoverage": {
                "A": 31204,
                "B": 12542,
                "C": 27194,
                "DQB1": 26854,
                "DRB1": 25098
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "458c0d88-ff68-3b3f-93f7-d8cb8f299b58",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "7a6fb22d-f82e-3ef4-a78b-98d073461831",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:20.807+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "35c72c74-aa74-308c-b3c3-bf1a3da44794",
    "runId": 28,
    "name": "52131",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917361513,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+26:01",
            "B": "35:01+40:01|35:380+40:379",
            "C": "03:04+04:01",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52131_S28",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 426,
            "contamination": false,
            "meanReadsQuality": 35.39369458652857,
            "readsDistribution": {
                "targetLoci": 107854,
                "relatedLoci": 15094,
                "qualityTrimmed": 36674,
                "offTargetRegions": 31600
            },
            "targetLociCoverage": {
                "A": 25888,
                "B": 12168,
                "C": 24370,
                "DQB1": 20824,
                "DRB1": 24604
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6fa9b5bf-2a66-38ab-890a-e42e7ce76808",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "35c72c74-aa74-308c-b3c3-bf1a3da44794",
        "prior_analysis_state_modified_at": "2020-02-26T12:59:21.296+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "6a990655-8f50-33cc-8de4-7e4cd8273906",
    "runId": 29,
    "name": "52132",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917366981,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+13:02",
            "C": "06:02+07:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52132_S29",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 459,
            "contamination": false,
            "meanReadsQuality": 35.433333092591056,
            "readsDistribution": {
                "targetLoci": 127488,
                "relatedLoci": 7820,
                "qualityTrimmed": 39816,
                "offTargetRegions": 43112
            },
            "targetLociCoverage": {
                "A": 29312,
                "B": 13794,
                "C": 26404,
                "DQB1": 18580,
                "DRB1": 39398
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c42e2a7a-908a-37cb-949e-0a7fca85c422",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "6a990655-8f50-33cc-8de4-7e4cd8273906",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:28.256+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "6463707e-3db5-3e38-acaf-203944e51732",
    "runId": 30,
    "name": "52133",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917365801,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 332210,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "02:01+23:01",
            "B": "49:01+51:01",
            "C": "07:01+07:01",
            "DQB1": "03:01/03:276N/03:297+06:04",
            "DRB1": "11:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52133_S30",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 411,
            "contamination": false,
            "meanReadsQuality": 35.55006918347945,
            "readsDistribution": {
                "targetLoci": 168258,
                "relatedLoci": 48814,
                "qualityTrimmed": 59182,
                "offTargetRegions": 55956
            },
            "targetLociCoverage": {
                "A": 44004,
                "B": 22078,
                "C": 36806,
                "DQB1": 27094,
                "DRB1": 38276
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "27f37b2c-498f-3f3c-9ad5-6d179a2a4ad6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "6463707e-3db5-3e38-acaf-203944e51732",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:29.122+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "5bfd71ca-763c-3475-a86f-99df1e8105db",
    "runId": 31,
    "name": "52134",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917341980,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+08:01",
            "C": "07:01+07:01",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52134_S31",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 35.6188270578173,
            "readsDistribution": {
                "targetLoci": 102458,
                "relatedLoci": 24838,
                "qualityTrimmed": 32108,
                "offTargetRegions": 35486
            },
            "targetLociCoverage": {
                "A": 25766,
                "B": 10464,
                "C": 22636,
                "DQB1": 18710,
                "DRB1": 24882
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b78f092e-a2d9-3e90-b16a-8372823cf014",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "5bfd71ca-763c-3475-a86f-99df1e8105db",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:29.564+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "d0f4b0c2-6a6f-303e-af2f-0dde2c8d2b14",
    "runId": 32,
    "name": "52135",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917358682,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "08:01+15:01",
            "C": "03:04+07:01",
            "DQB1": "02:01+03:02",
            "DRB1": "03:01/03:147+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52135_S32",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 362,
            "contamination": false,
            "meanReadsQuality": 35.527967946876856,
            "readsDistribution": {
                "targetLoci": 90702,
                "relatedLoci": 17430,
                "qualityTrimmed": 29600,
                "offTargetRegions": 28978
            },
            "targetLociCoverage": {
                "A": 23136,
                "B": 10592,
                "C": 21660,
                "DQB1": 14886,
                "DRB1": 20428
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "ded0b4ab-a493-3284-bf29-a70a9e1e78be",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "d0f4b0c2-6a6f-303e-af2f-0dde2c8d2b14",
        "prior_analysis_state_modified_at": "2020-02-26T13:18:29.814+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "44081580-180f-395a-88b2-a03fa3b4f14b",
    "runId": 33,
    "name": "52136",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349620,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "C": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "24:02+32:01",
            "B": "07:02+41:01",
            "C": null,
            "DQB1": "03:02+03:03",
            "DRB1": "04:04+07:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52136_S33",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 390,
            "contamination": false,
            "meanReadsQuality": 35.67965647333952,
            "readsDistribution": {
                "targetLoci": 86604,
                "relatedLoci": 11642,
                "qualityTrimmed": 24702,
                "offTargetRegions": 28612
            },
            "targetLociCoverage": {
                "A": 21036,
                "B": 9692,
                "C": 18346,
                "DQB1": 17254,
                "DRB1": 20276
            }
        },
    },
    maybeResolutionDraft: {
        "genotypes": {
            "C": "07:02+17:01"
        },
        "resolution": "assigned",
        annotations: null
    },
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "33b4b294-ebca-3a6f-9b47-ec52125cbe1b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "ce71583d-595f-3e40-9f15-592b06cdeec3",
    "runId": 34,
    "name": "52137",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917356262,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+24:02",
            "B": "39:01+44:03",
            "C": "04:01+07:02",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:04+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52137_S34",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 397,
            "contamination": false,
            "meanReadsQuality": 35.71279885273779,
            "readsDistribution": {
                "targetLoci": 108080,
                "relatedLoci": 20102,
                "qualityTrimmed": 30516,
                "offTargetRegions": 34536
            },
            "targetLociCoverage": {
                "A": 27218,
                "B": 11824,
                "C": 24684,
                "DQB1": 22686,
                "DRB1": 21668
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "50a0d736-266b-314c-a657-ef15d11032ae",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "ce71583d-595f-3e40-9f15-592b06cdeec3",
        "prior_analysis_state_modified_at": "2020-02-26T13:23:37.103+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "99dd66af-1621-3552-9e77-ffbdd1f0e904",
    "runId": 35,
    "name": "52138",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917365565,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+23:01",
            "B": "40:01+49:01|40:117+50:01",
            "C": "03:04+07:01",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "11:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52138_S35",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 385,
            "contamination": false,
            "meanReadsQuality": 35.62046101449457,
            "readsDistribution": {
                "targetLoci": 101894,
                "relatedLoci": 19288,
                "qualityTrimmed": 32570,
                "offTargetRegions": 39262
            },
            "targetLociCoverage": {
                "A": 25838,
                "B": 11678,
                "C": 24166,
                "DQB1": 17462,
                "DRB1": 22750
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "3a919df0-ecf8-37f1-ad97-a15be4f2122e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "99dd66af-1621-3552-9e77-ffbdd1f0e904",
        "prior_analysis_state_modified_at": "2020-02-27T05:59:19.355+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null,
    }]
}, {
    "id": "b3f332b1-183e-39d5-ac6c-a878a7eae093",
    "runId": 36,
    "name": "52139",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917333937,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+11:83",
            "B": "07:02+37:01",
            "C": "06:02+07:02",
            "DQB1": "05:01+05:01",
            "DRB1": "01:01+10:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52139_S36",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 35.29370228787503,
            "readsDistribution": {
                "targetLoci": 92072,
                "relatedLoci": 20880,
                "qualityTrimmed": 35024,
                "offTargetRegions": 26560
            },
            "targetLociCoverage": {
                "A": 20974,
                "B": 9670,
                "C": 18770,
                "DQB1": 20334,
                "DRB1": 22324
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "3a1373ae-aa23-3ad3-8adc-e8ce8456017c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "b3f332b1-183e-39d5-ac6c-a878a7eae093",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:00.336+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "0770a557-7ac6-36ff-9493-259077e1a274",
    "runId": 37,
    "name": "52140",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917341777,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "31:01+68:01",
            "B": "39:01+56:01",
            "C": "01:02+12:03",
            "DQB1": "05:03+06:02",
            "DRB1": "14:54+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52140_S37",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 411,
            "contamination": false,
            "meanReadsQuality": 35.60853505157175,
            "readsDistribution": {
                "targetLoci": 126494,
                "relatedLoci": 26634,
                "qualityTrimmed": 42520,
                "offTargetRegions": 55244
            },
            "targetLociCoverage": {
                "A": 32762,
                "B": 13958,
                "C": 29846,
                "DQB1": 23248,
                "DRB1": 26680
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "86589429-6748-3909-9e54-9730a0221f73",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "0770a557-7ac6-36ff-9493-259077e1a274",
        "prior_analysis_state_modified_at": "2020-02-27T06:48:52.399+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "38a7f7aa-ce98-37d6-b190-72e20e797a82",
    "runId": 38,
    "name": "52141",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917333335,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "48:01+51:01",
            "C": "07:01+08:01",
            "DQB1": "03:01/03:276N/03:297+06:03",
            "DRB1": "12:01/12:10+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52141_S38",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 440,
            "contamination": false,
            "meanReadsQuality": 35.304720698130104,
            "readsDistribution": {
                "targetLoci": 103782,
                "relatedLoci": 30830,
                "qualityTrimmed": 44668,
                "offTargetRegions": 40284
            },
            "targetLociCoverage": {
                "A": 26214,
                "B": 13614,
                "C": 23760,
                "DQB1": 17852,
                "DRB1": 22342
            }
        }
    },
    maybeSupportRequest: {
        "loci": [ "DRB1" ],
        "comment": "55555",
        "createdBy": "brother"
    },
    maybeResolutionDraft: {
        resolution: null,
        annotations: null
    },
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "12a8f8c8-1249-3ee3-b54b-29f36255859b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "38a7f7aa-ce98-37d6-b190-72e20e797a82",
        "prior_analysis_state_modified_at": "2020-02-28T07:16:37.159+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "f4d58439-f79a-3ab5-b781-df2f5b619e12",
    "runId": 39,
    "name": "52142",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917336930,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "68:01+68:01",
            "B": "07:02+15:01",
            "C": "03:04+07:02",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52142_S39",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 35.51240092185367,
            "readsDistribution": {
                "targetLoci": 85850,
                "relatedLoci": 17248,
                "qualityTrimmed": 29892,
                "offTargetRegions": 34740
            },
            "targetLociCoverage": {
                "A": 20530,
                "B": 9604,
                "C": 18192,
                "DQB1": 18728,
                "DRB1": 18796
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "5b02a1c5-1616-34ae-a76a-b5a1e5ef963d",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "f4d58439-f79a-3ab5-b781-df2f5b619e12",
        "prior_analysis_state_modified_at": "2020-04-01T14:13:41.998+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "630354f6-a9bf-3dae-9352-d48540dab7b7",
    "runId": 40,
    "name": "52143",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917360725,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "23:01+24:02",
            "B": "44:03+44:03",
            "C": "04:01+04:01",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52143_S40",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.23122676398793,
            "readsDistribution": {
                "targetLoci": 99964,
                "relatedLoci": 24208,
                "qualityTrimmed": 43432,
                "offTargetRegions": 41708
            },
            "targetLociCoverage": {
                "A": 24506,
                "B": 11072,
                "C": 25196,
                "DQB1": 13870,
                "DRB1": 25320
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4fa7696e-f39c-324b-815d-35d2e716db11",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "630354f6-a9bf-3dae-9352-d48540dab7b7",
        "prior_analysis_state_modified_at": "2020-02-28T14:19:28.023+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "c3f28fef-3873-3415-8b77-fefeac9d7a4d",
    "runId": 41,
    "name": "52144",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917338231,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+68:01",
            "B": "44:05+51:01",
            "C": "02:02+15:02",
            "DQB1": "05:02+06:03",
            "DRB1": "13:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52144_S41",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 460,
            "contamination": false,
            "meanReadsQuality": 35.550611112281146,
            "readsDistribution": {
                "targetLoci": 65118,
                "relatedLoci": 14450,
                "qualityTrimmed": 23720,
                "offTargetRegions": 29410
            },
            "targetLociCoverage": {
                "A": 15584,
                "B": 7636,
                "C": 14164,
                "DQB1": 13252,
                "DRB1": 14482
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "8c143cae-247b-3fff-a91f-cca2a152e155",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "c3f28fef-3873-3415-8b77-fefeac9d7a4d",
        "prior_analysis_state_modified_at": "2020-04-03T16:49:17.429+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "55e61270-33eb-3f23-be55-e3331c78141f",
    "runId": 42,
    "name": "52145",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917345421,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+68:01",
            "B": "07:05+39:01",
            "C": "12:03+15:05",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:03+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52145_S42",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 484,
            "contamination": false,
            "meanReadsQuality": 35.48247666225073,
            "readsDistribution": {
                "targetLoci": 58024,
                "relatedLoci": 13608,
                "qualityTrimmed": 20900,
                "offTargetRegions": 22634
            },
            "targetLociCoverage": {
                "A": 15276,
                "B": 5992,
                "C": 13544,
                "DQB1": 13044,
                "DRB1": 10168
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "dec395b2-0ae9-3506-80cb-943571554aab",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "55e61270-33eb-3f23-be55-e3331c78141f",
        "prior_analysis_state_modified_at": "2020-02-28T14:19:30.869+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "852a4c29-92f4-3c59-bed4-7a456cf6a854",
    "runId": 43,
    "name": "52146",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917351772,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+25:01",
            "B": "44:05+52:01",
            "C": "02:02+12:02",
            "DQB1": "03:03+06:01",
            "DRB1": "07:01+15:02/15:140/15:149"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52146_S43",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 441,
            "contamination": false,
            "meanReadsQuality": 35.600939534034765,
            "readsDistribution": {
                "targetLoci": 101574,
                "relatedLoci": 13880,
                "qualityTrimmed": 32702,
                "offTargetRegions": 40950
            },
            "targetLociCoverage": {
                "A": 22992,
                "B": 11482,
                "C": 21820,
                "DQB1": 19868,
                "DRB1": 25412
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "218f6bb0-fada-32dd-a240-5694807a2381",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "852a4c29-92f4-3c59-bed4-7a456cf6a854",
        "prior_analysis_state_modified_at": "2020-04-03T16:55:55.406+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "86699d59-93b9-39a7-a647-98854354ff2b",
    "runId": 44,
    "name": "52147",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917346043,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+44:27",
            "C": "07:01+07:04",
            "DQB1": "02:01+05:02",
            "DRB1": "03:01/03:147+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52147_S44",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.495194450623586,
            "readsDistribution": {
                "targetLoci": 103066,
                "relatedLoci": 23658,
                "qualityTrimmed": 38396,
                "offTargetRegions": 42026
            },
            "targetLociCoverage": {
                "A": 23580,
                "B": 11162,
                "C": 21914,
                "DQB1": 21252,
                "DRB1": 25158
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "66b4387d-51cc-3c36-9fee-47994fff1867",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "86699d59-93b9-39a7-a647-98854354ff2b",
        "prior_analysis_state_modified_at": "2020-02-28T14:19:44.364+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "7a8d6e18-c4dc-3083-8b8a-c8352515a59b",
    "runId": 45,
    "name": "52148",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917362559,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+24:02",
            "B": "18:01+27:05",
            "C": "02:02+07:01",
            "DQB1": "03:02+06:02",
            "DRB1": "04:04+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52148_S45",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 475,
            "contamination": false,
            "meanReadsQuality": 35.517678273294216,
            "readsDistribution": {
                "targetLoci": 86792,
                "relatedLoci": 11906,
                "qualityTrimmed": 30860,
                "offTargetRegions": 41674
            },
            "targetLociCoverage": {
                "A": 20512,
                "B": 8800,
                "C": 18790,
                "DQB1": 19176,
                "DRB1": 19514
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "23763ef3-1633-3810-b09c-658cd6990221",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "7a8d6e18-c4dc-3083-8b8a-c8352515a59b",
        "prior_analysis_state_modified_at": "2020-02-28T06:57:06.601+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "d0f73016-8f48-3718-be32-6a875608f831",
    "runId": 46,
    "name": "52149",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917333125,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 315098,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 44458,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+15:01",
            "C": "04:01+07:02",
            "DQB1": "03:02+04:02",
            "DRB1": "04:04+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52149_S46",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 439,
            "contamination": false,
            "meanReadsQuality": 35.5061535149855,
            "readsDistribution": {
                "targetLoci": 179746,
                "relatedLoci": 15670,
                "qualityTrimmed": 58506,
                "offTargetRegions": 61176
            },
            "targetLociCoverage": {
                "A": 40658,
                "B": 18118,
                "C": 33626,
                "DQB1": 44458,
                "DRB1": 42886
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "94bcc0b6-c3a5-3432-819d-73ced2a674ae",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "d0f73016-8f48-3718-be32-6a875608f831",
        "prior_analysis_state_modified_at": "2020-04-17T15:45:34.855+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "b90c0130-9af1-3621-9226-bbc28a2284be",
    "runId": 47,
    "name": "52150",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349014,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+57:01",
            "C": "06:02+07:02",
            "DQB1": "03:03+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52150_S47",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 446,
            "contamination": false,
            "meanReadsQuality": 35.58535579409609,
            "readsDistribution": {
                "targetLoci": 96060,
                "relatedLoci": 24560,
                "qualityTrimmed": 34474,
                "offTargetRegions": 45472
            },
            "targetLociCoverage": {
                "A": 24358,
                "B": 10878,
                "C": 20938,
                "DQB1": 16368,
                "DRB1": 23518
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "dd5c547a-17d6-3d46-9650-454c5d079341",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "b90c0130-9af1-3621-9226-bbc28a2284be",
        "prior_analysis_state_modified_at": "2020-02-28T06:57:17.198+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "4cd17f45-9e2a-39c8-85f3-2416c5e46238",
    "runId": 48,
    "name": "52151",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917364144,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:17+11:01",
            "B": "15:01+35:01|15:20+35:43",
            "C": "03:04+04:01",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52151_S48",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 416,
            "contamination": false,
            "meanReadsQuality": 35.463137514972345,
            "readsDistribution": {
                "targetLoci": 73718,
                "relatedLoci": 18414,
                "qualityTrimmed": 28144,
                "offTargetRegions": 29614
            },
            "targetLociCoverage": {
                "A": 17472,
                "B": 8358,
                "C": 16558,
                "DQB1": 14036,
                "DRB1": 17294
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c721a318-e2cc-37ed-bdb5-ef1e96de1e8e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "4cd17f45-9e2a-39c8-85f3-2416c5e46238",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:05.132+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "a2dc51a6-3f7b-36dc-b5fb-2886649f5982",
    "runId": 49,
    "name": "52152",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917360128,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:05",
            "B": "07:02+15:220",
            "C": "07:02+12:03",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52152_S49",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 419,
            "contamination": false,
            "meanReadsQuality": 34.94870443113717,
            "readsDistribution": {
                "targetLoci": 109134,
                "relatedLoci": 9066,
                "qualityTrimmed": 50808,
                "offTargetRegions": 36984
            },
            "targetLociCoverage": {
                "A": 26252,
                "B": 12460,
                "C": 22850,
                "DQB1": 15550,
                "DRB1": 32022
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "9934e40c-e48f-37f8-ad32-270dfaac8bf4",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "a2dc51a6-3f7b-36dc-b5fb-2886649f5982",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:11.256+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "29bc34c0-a03d-3e8b-b4a7-27bd8c25e7d6",
    "runId": 50,
    "name": "52153",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917359292,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "08:01+37:01",
            "C": "06:02+07:01",
            "DQB1": "05:03+06:03",
            "DRB1": "13:01+14:54"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52153_S50",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 413,
            "contamination": false,
            "meanReadsQuality": 35.07243918766963,
            "readsDistribution": {
                "targetLoci": 109690,
                "relatedLoci": 28810,
                "qualityTrimmed": 53042,
                "offTargetRegions": 36026
            },
            "targetLociCoverage": {
                "A": 25862,
                "B": 12112,
                "C": 26522,
                "DQB1": 18644,
                "DRB1": 26550
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d9905eea-ae51-3344-8dcb-f240dd7c0044",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "29bc34c0-a03d-3e8b-b4a7-27bd8c25e7d6",
        "prior_analysis_state_modified_at": "2020-02-26T13:25:59.108+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "46eb2673-5ac1-3382-892e-95b0b0c1fd15",
    "runId": 51,
    "name": "52154",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917337585,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "15:01+35:01|15:20+35:43",
            "C": "03:03+04:01",
            "DQB1": "05:01+06:03",
            "DRB1": "01:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52154_S51",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 394,
            "contamination": false,
            "meanReadsQuality": 34.89477240140437,
            "readsDistribution": {
                "targetLoci": 98852,
                "relatedLoci": 21214,
                "qualityTrimmed": 52714,
                "offTargetRegions": 35278
            },
            "targetLociCoverage": {
                "A": 25264,
                "B": 11494,
                "C": 24480,
                "DQB1": 14436,
                "DRB1": 23178
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "ec55d94c-3869-3ba0-8c22-759a2c42ac25",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "46eb2673-5ac1-3382-892e-95b0b0c1fd15",
        "prior_analysis_state_modified_at": "2020-02-26T09:33:04.749+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "84c69d3f-e13a-3cc0-8d76-e2ec8f015084",
    "runId": 52,
    "name": "52155",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917347034,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+26:01",
            "B": "07:02+35:01",
            "C": "04:01+07:02",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "11:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52155_S52",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 433,
            "contamination": false,
            "meanReadsQuality": 34.48448954058252,
            "readsDistribution": {
                "targetLoci": 87710,
                "relatedLoci": 13086,
                "qualityTrimmed": 56122,
                "offTargetRegions": 30100
            },
            "targetLociCoverage": {
                "A": 22264,
                "B": 11012,
                "C": 19514,
                "DQB1": 13942,
                "DRB1": 20978
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d7553ac2-e75a-3704-9506-285bdd07d5d8",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "84c69d3f-e13a-3cc0-8d76-e2ec8f015084",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:18.044+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "d4027203-cbda-327f-8d6c-0b8b68dbcc5f",
    "runId": 53,
    "name": "52156",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917354646,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+23:01",
            "B": "07:02+49:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "11:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52156_S53",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 421,
            "contamination": false,
            "meanReadsQuality": 34.79467618210659,
            "readsDistribution": {
                "targetLoci": 68296,
                "relatedLoci": 10116,
                "qualityTrimmed": 35580,
                "offTargetRegions": 23054
            },
            "targetLociCoverage": {
                "A": 17934,
                "B": 7940,
                "C": 15686,
                "DQB1": 10304,
                "DRB1": 16432
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4aae46b5-6990-3891-85ce-e3a3278a11d7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "d4027203-cbda-327f-8d6c-0b8b68dbcc5f",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:06.841+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "441eba54-8579-344d-ab40-3e79030e3d94",
    "runId": 54,
    "name": "52157",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917344375,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+26:01",
            "B": "44:05+48:01",
            "C": "02:02+08:03",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52157_S54",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 425,
            "contamination": false,
            "meanReadsQuality": 34.69837953662564,
            "readsDistribution": {
                "targetLoci": 121256,
                "relatedLoci": 16742,
                "qualityTrimmed": 66858,
                "offTargetRegions": 37346
            },
            "targetLociCoverage": {
                "A": 28302,
                "B": 12512,
                "C": 26126,
                "DQB1": 23442,
                "DRB1": 30874
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1f342be6-529d-3f56-bed6-0ced9f296e4e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "441eba54-8579-344d-ab40-3e79030e3d94",
        "prior_analysis_state_modified_at": "2020-02-26T09:33:08.013+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "11ad991b-075c-30b4-811c-fb38cd285652",
    "runId": 55,
    "name": "52158",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917363339,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+24:02",
            "B": null,
            "C": "03:03+06:02",
            "DQB1": "02:02+03:03",
            "DRB1": "07:01+09:01/09:21/09:31"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52158_S55",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 386,
            "contamination": false,
            "meanReadsQuality": 35.10146676327509,
            "readsDistribution": {
                "targetLoci": 98130,
                "relatedLoci": 8086,
                "qualityTrimmed": 40598,
                "offTargetRegions": 29442
            },
            "targetLociCoverage": {
                "A": 21210,
                "B": 10914,
                "C": 20158,
                "DQB1": 16034,
                "DRB1": 29814
            }
        },
    },
    maybeResolutionDraft: {
        "genotypes": {
            "A": "02:01+24:02",
            "DRB1": null
        },
        "resolution": null,
        annotations: null
    },
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d0f20b72-2db7-3a1f-93da-80a7a9d48bb7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0f22f028-7081-388a-a5ea-d58f31f55e59",
    "runId": 56,
    "name": "52159",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917331500,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "48:01+57:01",
            "C": "06:02+08:03",
            "DQB1": "03:03+03:03",
            "DRB1": "07:01+09:01/09:21/09:31"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52159_S56",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 397,
            "contamination": false,
            "meanReadsQuality": 34.69944458280179,
            "readsDistribution": {
                "targetLoci": 70546,
                "relatedLoci": 6080,
                "qualityTrimmed": 36336,
                "offTargetRegions": 17338
            },
            "targetLociCoverage": {
                "A": 15272,
                "B": 7328,
                "C": 14400,
                "DQB1": 12988,
                "DRB1": 20558
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c97e1da2-0efd-351f-8823-98b4b86fac96",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "0f22f028-7081-388a-a5ea-d58f31f55e59",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:08.767+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "04a31255-d62d-3335-9f29-21ffaee2a23f",
    "runId": 57,
    "name": "52160",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917362758,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+31:01",
            "B": "39:01+57:01",
            "C": "04:01+07:02",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:04+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52160_S57",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 398,
            "contamination": false,
            "meanReadsQuality": 35.04043233049768,
            "readsDistribution": {
                "targetLoci": 85646,
                "relatedLoci": 16012,
                "qualityTrimmed": 45164,
                "offTargetRegions": 30046
            },
            "targetLociCoverage": {
                "A": 21066,
                "B": 8632,
                "C": 18910,
                "DQB1": 19074,
                "DRB1": 17964
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4dedbed2-b34a-3310-987f-fdbb54e3fc4f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "04a31255-d62d-3335-9f29-21ffaee2a23f",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:10.281+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "e19eef2e-3aa2-302f-9ae5-06125c875050",
    "runId": 58,
    "name": "52161",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917354236,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:07+26:01",
            "B": "38:01+46:01",
            "C": "01:02+12:03",
            "DQB1": "03:01/03:276N/03:297+03:03",
            "DRB1": "09:01/09:21/09:31+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52161_S58",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 399,
            "contamination": false,
            "meanReadsQuality": 35.16989531451726,
            "readsDistribution": {
                "targetLoci": 64564,
                "relatedLoci": 8448,
                "qualityTrimmed": 28348,
                "offTargetRegions": 18354
            },
            "targetLociCoverage": {
                "A": 16382,
                "B": 6554,
                "C": 14926,
                "DQB1": 12508,
                "DRB1": 14194
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "48d96773-0745-3a6c-bebc-d006bb5098f6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "e19eef2e-3aa2-302f-9ae5-06125c875050",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:12.774+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "6831f915-0d97-3d09-9e97-bdedef7c3e88",
    "runId": 59,
    "name": "52162",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917347237,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+11:01",
            "B": "49:01+52:01",
            "C": "07:01+12:02",
            "DQB1": "02:02+06:01",
            "DRB1": "07:01+15:02/15:140/15:149"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52162_S59",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 404,
            "contamination": false,
            "meanReadsQuality": 34.800110375275935,
            "readsDistribution": {
                "targetLoci": 98962,
                "relatedLoci": 10054,
                "qualityTrimmed": 56406,
                "offTargetRegions": 33898
            },
            "targetLociCoverage": {
                "A": 21702,
                "B": 12136,
                "C": 21060,
                "DQB1": 18160,
                "DRB1": 25904
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2206e0f6-8caa-3944-b10e-435b2cb46d09",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "6831f915-0d97-3d09-9e97-bdedef7c3e88",
        "prior_analysis_state_modified_at": "2020-02-26T09:33:11.547+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "1755a25b-29dc-32fc-a649-ee717ab4ea71",
    "runId": 60,
    "name": "52163",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917362959,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "08:01+35:02",
            "C": "04:01+07:01",
            "DQB1": "05:01+05:01",
            "DRB1": "01:01+01:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52163_S60",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 410,
            "contamination": false,
            "meanReadsQuality": 34.61501768077308,
            "readsDistribution": {
                "targetLoci": 75154,
                "relatedLoci": 17264,
                "qualityTrimmed": 47530,
                "offTargetRegions": 19154
            },
            "targetLociCoverage": {
                "A": 15480,
                "B": 8080,
                "C": 15842,
                "DQB1": 16544,
                "DRB1": 19208
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "3a274ed7-eb72-3228-b923-c47ffd644a1e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "1755a25b-29dc-32fc-a649-ee717ab4ea71",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:13.952+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "b93a0c8e-4bd8-3ce3-ac89-47e6404583e6",
    "runId": 61,
    "name": "52164",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917339671,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+33:03",
            "B": "15:01+58:01",
            "C": "03:02+03:04",
            "DQB1": "05:01+06:09",
            "DRB1": "01:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52164_S61",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 403,
            "contamination": false,
            "meanReadsQuality": 35.00075441243972,
            "readsDistribution": {
                "targetLoci": 97944,
                "relatedLoci": 28266,
                "qualityTrimmed": 56962,
                "offTargetRegions": 38324
            },
            "targetLociCoverage": {
                "A": 24716,
                "B": 10496,
                "C": 21152,
                "DQB1": 18160,
                "DRB1": 23420
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "046824e9-f67f-35d8-ba6a-62e0342a0c32",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "b93a0c8e-4bd8-3ce3-ac89-47e6404583e6",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:21.515+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "e78d8807-d1d6-303f-b3f1-b3e842a6997a",
    "runId": 62,
    "name": "52165",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349215,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "01:01+03:01",
            "B": null,
            "C": "05:01+06:02",
            "DQB1": "02:02+03:02",
            "DRB1": "04:03+07:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52165_S62",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 420,
            "contamination": false,
            "meanReadsQuality": 34.63469700324587,
            "readsDistribution": {
                "targetLoci": 93420,
                "relatedLoci": 10800,
                "qualityTrimmed": 60182,
                "offTargetRegions": 34612
            },
            "targetLociCoverage": {
                "A": 19280,
                "B": 11398,
                "C": 19374,
                "DQB1": 17528,
                "DRB1": 25840
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "70a61761-bd53-369c-a20b-7d807c1c60dd",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "cf04c7dd-eaeb-392f-b833-94396c2161cd",
    "runId": 63,
    "name": "52166",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917356464,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+24:02",
            "B": "15:01+27:05",
            "C": "02:02+03:03",
            "DQB1": "04:02+06:03",
            "DRB1": "08:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52166_S63",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 34.95967732387457,
            "readsDistribution": {
                "targetLoci": 77108,
                "relatedLoci": 16644,
                "qualityTrimmed": 45314,
                "offTargetRegions": 30978
            },
            "targetLociCoverage": {
                "A": 16828,
                "B": 7684,
                "C": 17234,
                "DQB1": 14762,
                "DRB1": 20600
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "34b95d88-0796-3747-b049-e9a68de4f9f8",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "cf04c7dd-eaeb-392f-b833-94396c2161cd",
        "prior_analysis_state_modified_at": "2020-02-27T06:49:22.508+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "dac13a85-dd57-3797-8eec-e29f2496b55c",
    "runId": 64,
    "name": "52167",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349988,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+30:01",
            "B": "13:02+40:01",
            "C": "03:04+06:02",
            "DQB1": "02:02+03:03",
            "DRB1": "07:01+09:01/09:21/09:31"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52167_S64",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 362,
            "contamination": false,
            "meanReadsQuality": 34.741316281923346,
            "readsDistribution": {
                "targetLoci": 70946,
                "relatedLoci": 10334,
                "qualityTrimmed": 42650,
                "offTargetRegions": 24254
            },
            "targetLociCoverage": {
                "A": 15560,
                "B": 8530,
                "C": 16264,
                "DQB1": 9558,
                "DRB1": 21034
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "ea6793f0-b674-3e5b-a379-3641ef5a5964",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "dac13a85-dd57-3797-8eec-e29f2496b55c",
        "prior_analysis_state_modified_at": "2020-02-27T06:49:30.773+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "9dd09dce-6c6b-398e-9ffb-c98b0918fb28",
    "runId": 65,
    "name": "52168",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917359484,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+29:01",
            "B": "07:02+07:05",
            "C": "07:02+15:05",
            "DQB1": "05:01+06:02",
            "DRB1": "10:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52168_S65",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 389,
            "contamination": false,
            "meanReadsQuality": 35.32813171070636,
            "readsDistribution": {
                "targetLoci": 129660,
                "relatedLoci": 24844,
                "qualityTrimmed": 50484,
                "offTargetRegions": 44718
            },
            "targetLociCoverage": {
                "A": 29524,
                "B": 14028,
                "C": 26066,
                "DQB1": 25912,
                "DRB1": 34130
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "22d24bf7-9d8e-37d0-8abc-c18e5c6d1e69",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "9dd09dce-6c6b-398e-9ffb-c98b0918fb28",
        "prior_analysis_state_modified_at": "2020-02-27T06:49:34.972+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "2b1f7aa2-cbbe-3326-af72-f30c13bdc429",
    "runId": 66,
    "name": "52169",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917330328,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "13:02+15:01",
            "C": "03:03+06:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52169_S66",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 415,
            "contamination": false,
            "meanReadsQuality": 35.205189143380196,
            "readsDistribution": {
                "targetLoci": 109950,
                "relatedLoci": 23022,
                "qualityTrimmed": 49682,
                "offTargetRegions": 46248
            },
            "targetLociCoverage": {
                "A": 26474,
                "B": 13038,
                "C": 25420,
                "DQB1": 15618,
                "DRB1": 29400
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4499c410-69a9-3592-a1e6-64613dc574f2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "2b1f7aa2-cbbe-3326-af72-f30c13bdc429",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:23.418+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "c4cbb63f-75d3-36c0-ba75-657746946942",
    "runId": 67,
    "name": "52170",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917344578,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+30:01",
            "B": "13:02+40:02",
            "C": "02:02+06:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52170_S67",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 434,
            "contamination": false,
            "meanReadsQuality": 34.95262573806943,
            "readsDistribution": {
                "targetLoci": 77528,
                "relatedLoci": 17680,
                "qualityTrimmed": 41610,
                "offTargetRegions": 33864
            },
            "targetLociCoverage": {
                "A": 18092,
                "B": 9214,
                "C": 18544,
                "DQB1": 11134,
                "DRB1": 20544
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4dfe6afb-7faf-3939-8ab1-c5a9d1155c36",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "c4cbb63f-75d3-36c0-ba75-657746946942",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:29.023+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "6ef1c8f1-525f-3e23-86ba-0c56308d7e95",
    "runId": 68,
    "name": "52171",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917366390,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+24:02",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "13:05+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52171_S68",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 34.79171570274969,
            "readsDistribution": {
                "targetLoci": 85050,
                "relatedLoci": 17270,
                "qualityTrimmed": 48402,
                "offTargetRegions": 33908
            },
            "targetLociCoverage": {
                "A": 20420,
                "B": 10190,
                "C": 19342,
                "DQB1": 15388,
                "DRB1": 19710
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1d8328c2-25ff-3433-aff8-9801e75b5417",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "6ef1c8f1-525f-3e23-86ba-0c56308d7e95",
        "prior_analysis_state_modified_at": "2020-02-27T07:26:36.065+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "fa05fc2c-0c6e-32d1-851b-b3d9c9da7484",
    "runId": 69,
    "name": "52172",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917362357,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "13:02+27:02",
            "C": "02:02+06:02",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52172_S69",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 437,
            "contamination": false,
            "meanReadsQuality": 35.027443756484196,
            "readsDistribution": {
                "targetLoci": 74514,
                "relatedLoci": 7496,
                "qualityTrimmed": 34250,
                "offTargetRegions": 29816
            },
            "targetLociCoverage": {
                "A": 18172,
                "B": 8122,
                "C": 16570,
                "DQB1": 10448,
                "DRB1": 21202
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "25421e76-560b-3105-9876-7b2abe586161",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "fa05fc2c-0c6e-32d1-851b-b3d9c9da7484",
        "prior_analysis_state_modified_at": "2020-02-27T07:27:14.923+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "55fdb7a0-24ee-3c09-8308-db7d05b5fbfe",
    "runId": 70,
    "name": "52173",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917361952,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+44:02",
            "C": "05:01+07:02",
            "DQB1": "04:02+06:02",
            "DRB1": "08:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52173_S70",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 436,
            "contamination": false,
            "meanReadsQuality": 34.79725984165425,
            "readsDistribution": {
                "targetLoci": 87392,
                "relatedLoci": 5750,
                "qualityTrimmed": 43704,
                "offTargetRegions": 29938
            },
            "targetLociCoverage": {
                "A": 19770,
                "B": 9204,
                "C": 17140,
                "DQB1": 16208,
                "DRB1": 25070
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "888d93be-10ee-3220-b436-3559f1ba6ea0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "55fdb7a0-24ee-3c09-8308-db7d05b5fbfe",
        "prior_analysis_state_modified_at": "2020-04-17T12:35:11.439+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "cb4ae03f-89ec-3cff-b1b4-9fcd41933dbc",
    "runId": 71,
    "name": "52174",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917339465,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "23:01+24:02",
            "B": "07:02+44:03",
            "C": "04:01+07:02",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52174_S71",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 407,
            "contamination": false,
            "meanReadsQuality": 35.05451738560873,
            "readsDistribution": {
                "targetLoci": 60502,
                "relatedLoci": 12894,
                "qualityTrimmed": 28374,
                "offTargetRegions": 23826
            },
            "targetLociCoverage": {
                "A": 13216,
                "B": 6878,
                "C": 13126,
                "DQB1": 10698,
                "DRB1": 16584
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6259a4e8-5854-3b04-9d80-f8971f89f191",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "cb4ae03f-89ec-3cff-b1b4-9fcd41933dbc",
        "prior_analysis_state_modified_at": "2020-02-28T06:57:26.19+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "1fc3969f-ece4-3831-a5cd-c633696bd921",
    "runId": 72,
    "name": "52175",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917350804,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 81932,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "A": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 7926,
                        "quality": "yellow"
                    }
                },
                "B": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 3762,
                        "quality": "yellow"
                    },
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                },
                "C": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 7820,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+03:01",
            "B": null,
            "C": "02:02+04:01",
            "DQB1": "05:01+05:01",
            "DRB1": "01:01+01:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52175_S72",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 448,
            "contamination": false,
            "meanReadsQuality": 34.55619164721641,
            "readsDistribution": {
                "targetLoci": 36952,
                "relatedLoci": 9274,
                "qualityTrimmed": 23358,
                "offTargetRegions": 12348
            },
            "targetLociCoverage": {
                "A": 7926,
                "B": 3762,
                "C": 7820,
                "DQB1": 7724,
                "DRB1": 9720
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "826323bd-7e25-33bd-adbf-d7d92f088399",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "972d16e5-07c8-3954-aaaa-5a633eab1b66",
    "runId": 73,
    "name": "52176",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917357702,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "11:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52176_S73",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 398,
            "contamination": false,
            "meanReadsQuality": 35.72836242972875,
            "readsDistribution": {
                "targetLoci": 162902,
                "relatedLoci": 26246,
                "qualityTrimmed": 46808,
                "offTargetRegions": 53106
            },
            "targetLociCoverage": {
                "A": 42168,
                "B": 18810,
                "C": 36672,
                "DQB1": 27220,
                "DRB1": 38032
            }
        },
    },
    maybeSupportRequest: {
        "loci": [ "A", "B" ],
        "comment": "456454",
        "createdBy": "brother"
    },
    maybeResolutionDraft: {
        resolution: null,
        annotations: null
    },
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a2e59d56-3d5f-3bde-ba0f-a8b4c2dffa0d",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "972d16e5-07c8-3954-aaaa-5a633eab1b66",
        "prior_analysis_state_modified_at": "2020-04-17T17:03:30.418+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "c6680d78-155a-3a29-9161-3a9e5ce2aa54",
    "runId": 74,
    "name": "52177",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917348015,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 321048,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 50044,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+11:01",
            "B": "27:02+55:02",
            "C": "01:02+02:02",
            "DQB1": "04:01+04:02",
            "DRB1": "04:04+04:05"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52177_S74",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 402,
            "contamination": false,
            "meanReadsQuality": 35.88238442527874,
            "readsDistribution": {
                "targetLoci": 208386,
                "relatedLoci": 13746,
                "qualityTrimmed": 47560,
                "offTargetRegions": 51356
            },
            "targetLociCoverage": {
                "A": 46402,
                "B": 22522,
                "C": 43444,
                "DQB1": 50044,
                "DRB1": 45974
            }
        },
    },
    maybeSupportRequest: {
        "loci": [ "C", "DQB1" ],
        "comment": "456",
        "createdBy": "brother"
    },
    maybeResolutionDraft: {
        resolution: null,
        annotations: null
    },
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d9603448-1c54-38e1-994f-95ae3cc90a3f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "c6680d78-155a-3a29-9161-3a9e5ce2aa54",
        "prior_analysis_state_modified_at": "2020-04-17T17:10:09.082+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "5c041ef3-8c62-378a-87d7-369d7d28b55c",
    "runId": 75,
    "name": "52178",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917361117,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "40:01+44:27",
            "C": "03:04+07:04",
            "DQB1": "05:02+06:04",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52178_S75",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 420,
            "contamination": false,
            "meanReadsQuality": 35.66495039227607,
            "readsDistribution": {
                "targetLoci": 132320,
                "relatedLoci": 23106,
                "qualityTrimmed": 39978,
                "offTargetRegions": 45020
            },
            "targetLociCoverage": {
                "A": 33830,
                "B": 14540,
                "C": 30698,
                "DQB1": 22212,
                "DRB1": 31040
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "24a3cb0d-6b0d-3073-97f0-49ff47269584",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "5c041ef3-8c62-378a-87d7-369d7d28b55c",
        "prior_analysis_state_modified_at": "2020-02-27T07:55:03.982+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "c88ebe15-c60c-3acb-9c29-cc32b2a2a81b",
    "runId": 76,
    "name": "52179",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917329467,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+68:01",
            "B": "40:02+49:01",
            "C": "03:04+07:01",
            "DQB1": "03:03+05:04",
            "DRB1": "01:01+09:01/09:21/09:31"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52179_S76",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 409,
            "contamination": false,
            "meanReadsQuality": 35.411390258271695,
            "readsDistribution": {
                "targetLoci": 157934,
                "relatedLoci": 22628,
                "qualityTrimmed": 55622,
                "offTargetRegions": 51136
            },
            "targetLociCoverage": {
                "A": 36690,
                "B": 15994,
                "C": 33074,
                "DQB1": 32010,
                "DRB1": 40166
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "846b3543-fb6d-3fac-b905-52721869153a",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "c88ebe15-c60c-3acb-9c29-cc32b2a2a81b",
        "prior_analysis_state_modified_at": "2020-02-27T07:55:05.663+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "3810182d-5708-3209-9799-60adbe1ac589",
    "runId": 77,
    "name": "52180",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917362155,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+26:01",
            "B": "07:02+38:01",
            "C": "07:02+12:03",
            "DQB1": "02:02+06:04",
            "DRB1": "07:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52180_S77",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 35.5209474166591,
            "readsDistribution": {
                "targetLoci": 141584,
                "relatedLoci": 28188,
                "qualityTrimmed": 48182,
                "offTargetRegions": 50524
            },
            "targetLociCoverage": {
                "A": 37718,
                "B": 15756,
                "C": 31728,
                "DQB1": 19142,
                "DRB1": 37240
            }
        },
    },
    maybeSupportRequest: {
        "loci": [ "DQB1" ],
        "comment": "/**",
        "createdBy": "brother"
    },
    maybeResolutionDraft: {
        resolution: null,
        annotations: null
    },
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2cc9de7d-80d5-3714-9f2b-70f49831b408",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "3810182d-5708-3209-9799-60adbe1ac589",
        "prior_analysis_state_modified_at": "2020-04-17T17:27:14.749+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "aa58b0bd-3025-3f09-95a6-77108d34232d",
    "runId": 78,
    "name": "52181",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917352583,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+32:01",
            "B": "27:05+39:01",
            "C": "02:02+12:03",
            "DQB1": "05:02+06:02",
            "DRB1": "15:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52181_S78",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 467,
            "contamination": false,
            "meanReadsQuality": 35.174782346157684,
            "readsDistribution": {
                "targetLoci": 142854,
                "relatedLoci": 4336,
                "qualityTrimmed": 51256,
                "offTargetRegions": 37616
            },
            "targetLociCoverage": {
                "A": 33518,
                "B": 12710,
                "C": 30544,
                "DQB1": 22472,
                "DRB1": 43610
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c31979d1-339a-3513-984a-2b577ce97602",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "aa58b0bd-3025-3f09-95a6-77108d34232d",
        "prior_analysis_state_modified_at": "2020-04-18T06:32:32.878+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "59903726-8dd9-3a08-914e-1f7daada94f6",
    "runId": 79,
    "name": "52182",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917345021,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+44:02",
            "C": "05:01+07:02",
            "DQB1": "04:02+05:02",
            "DRB1": "08:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52182_S79",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.71875989400662,
            "readsDistribution": {
                "targetLoci": 128222,
                "relatedLoci": 21046,
                "qualityTrimmed": 36202,
                "offTargetRegions": 38510
            },
            "targetLociCoverage": {
                "A": 30454,
                "B": 13990,
                "C": 26366,
                "DQB1": 25908,
                "DRB1": 31504
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7f5ba0cc-c19d-3b6b-96fc-4755ee1326a0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "9d7f128e-dd6b-3f1a-b4bf-dff76588bf97",
    "runId": 80,
    "name": "52183",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917355877,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 88494,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 4628,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+02:05",
            "B": "50:01+51:01",
            "C": "06:02+16:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52183_S80",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 419,
            "contamination": false,
            "meanReadsQuality": 35.12530830466001,
            "readsDistribution": {
                "targetLoci": 44678,
                "relatedLoci": 8634,
                "qualityTrimmed": 19474,
                "offTargetRegions": 15708
            },
            "targetLociCoverage": {
                "A": 11382,
                "B": 5616,
                "C": 10734,
                "DQB1": 4628,
                "DRB1": 12318
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a0dc673d-b82c-36b3-8b6b-f38a26fdf30e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "9d7f128e-dd6b-3f1a-b4bf-dff76588bf97",
        "prior_analysis_state_modified_at": "2020-04-27T13:37:58.916+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "aefd90c6-0c03-3f3b-83af-be2f8caf541e",
    "runId": 81,
    "name": "52184",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917335943,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+44:02",
            "C": "05:01+07:01",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52184_S81",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.24479434710585,
            "readsDistribution": {
                "targetLoci": 80790,
                "relatedLoci": 14944,
                "qualityTrimmed": 33190,
                "offTargetRegions": 29174
            },
            "targetLociCoverage": {
                "A": 21076,
                "B": 9222,
                "C": 19320,
                "DQB1": 13222,
                "DRB1": 17950
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c7c402f5-5edf-331e-8660-a50029b94ec1",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "61442e74-a132-3f10-bd95-12e0007d38fd",
    "runId": 82,
    "name": "52185",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917363743,
    "status": "COMPLETED",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "23:01+25:01",
            "B": "44:02+44:03",
            "C": "04:01+05:01",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52185_S82",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 428,
            "contamination": false,
            "meanReadsQuality": 35.264267259378,
            "readsDistribution": {
                "targetLoci": 78738,
                "relatedLoci": 18758,
                "qualityTrimmed": 33456,
                "offTargetRegions": 30658
            },
            "targetLociCoverage": {
                "A": 18840,
                "B": 7824,
                "C": 18030,
                "DQB1": 14814,
                "DRB1": 19230
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "140ce944-8e61-33fe-a2a7-adaf4ac67029",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": [{
        "prior_analysis_state_analysis_uuid": "61442e74-a132-3f10-bd95-12e0007d38fd",
        "prior_analysis_state_modified_at": "2020-02-28T14:19:49.491+00:00",
        "prior_analysis_state_modified_by": "brother",
        "prior_analysis_state_analysis_status": "AWAITING_REVIEW",
        "prior_analysis_state_analysis_result": null,
        "prior_analysis_state_maybe_resolution_draft": null,
        "prior_analysis_state_maybe_support_request": null
    }]
}, {
    "id": "9f0f72e5-5b23-3b73-8f4e-3859fbbd8899",
    "runId": 83,
    "name": "52186",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917360329,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+24:02",
            "B": "07:02+15:01",
            "C": "03:03+07:02",
            "DQB1": "06:02+06:02",
            "DRB1": "15:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52186_S83",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 35.18296959749166,
            "readsDistribution": {
                "targetLoci": 104300,
                "relatedLoci": 4846,
                "qualityTrimmed": 38226,
                "offTargetRegions": 27008
            },
            "targetLociCoverage": {
                "A": 23860,
                "B": 11590,
                "C": 21430,
                "DQB1": 16810,
                "DRB1": 30610
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4388f031-361b-32f7-b408-b8bfd4415556",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "c87b3d24-0164-3d6f-8251-b73bc1b54efc",
    "runId": 84,
    "name": "52187",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917365374,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "15:01+44:02",
            "C": "03:04+05:01",
            "DQB1": "03:02+05:01",
            "DRB1": "04:01+10:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52187_S84",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 418,
            "contamination": false,
            "meanReadsQuality": 35.02913951079503,
            "readsDistribution": {
                "targetLoci": 102156,
                "relatedLoci": 13500,
                "qualityTrimmed": 46072,
                "offTargetRegions": 31830
            },
            "targetLociCoverage": {
                "A": 25864,
                "B": 11250,
                "C": 23266,
                "DQB1": 18958,
                "DRB1": 22818
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "dbceee8d-5a80-348f-8458-196ce68aed1c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "b476e28c-f0a3-33d6-b71a-ec1c4a42faf4",
    "runId": 85,
    "name": "52188",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917356080,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+11:01",
            "B": "07:02+07:02",
            "C": "07:02+07:02",
            "DQB1": "05:03+06:03",
            "DRB1": "13:01+14:54"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52188_S85",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 444,
            "contamination": false,
            "meanReadsQuality": 35.07814430126311,
            "readsDistribution": {
                "targetLoci": 57842,
                "relatedLoci": 17556,
                "qualityTrimmed": 28228,
                "offTargetRegions": 19884
            },
            "targetLociCoverage": {
                "A": 14126,
                "B": 7228,
                "C": 11202,
                "DQB1": 12692,
                "DRB1": 12594
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b7ad77cc-7796-3969-ac20-f4a0ea3253e5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "38d34dff-b199-379f-8568-bd3df1ff01c4",
    "runId": 86,
    "name": "52189",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343985,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+25:01",
            "B": "13:02+18:01",
            "C": "06:02+12:03",
            "DQB1": "02:02+05:03",
            "DRB1": "07:01+14:54"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52189_S86",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 431,
            "contamination": false,
            "meanReadsQuality": 35.013329450124616,
            "readsDistribution": {
                "targetLoci": 79360,
                "relatedLoci": 15472,
                "qualityTrimmed": 40862,
                "offTargetRegions": 33052
            },
            "targetLociCoverage": {
                "A": 20080,
                "B": 8554,
                "C": 17144,
                "DQB1": 14158,
                "DRB1": 19424
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "140ec911-f41f-3f39-a4a4-8f2eff8ad599",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "81234476-b1c8-3618-8e78-a1cb9364b2a3",
    "runId": 87,
    "name": "52190",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917366778,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+32:01",
            "B": "18:01+35:01",
            "C": "04:01+07:01",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52190_S87",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 418,
            "contamination": false,
            "meanReadsQuality": 35.154676263642514,
            "readsDistribution": {
                "targetLoci": 68708,
                "relatedLoci": 19584,
                "qualityTrimmed": 33648,
                "offTargetRegions": 31760
            },
            "targetLociCoverage": {
                "A": 15514,
                "B": 8108,
                "C": 15156,
                "DQB1": 13526,
                "DRB1": 16404
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "59532203-33e5-3c24-b9c4-4c829cc4c5d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "4088b540-be16-30e1-b82b-32635e258fc5",
    "runId": 88,
    "name": "52191",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917345806,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "08:01+35:03",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+03:05",
            "DRB1": "03:01/03:147+04:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52191_S88",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 339,
            "contamination": false,
            "meanReadsQuality": 35.142958369427106,
            "readsDistribution": {
                "targetLoci": 53076,
                "relatedLoci": 13346,
                "qualityTrimmed": 24584,
                "offTargetRegions": 18792
            },
            "targetLociCoverage": {
                "A": 13356,
                "B": 7668,
                "C": 11474,
                "DQB1": 8274,
                "DRB1": 12304
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7c712859-faed-3fc6-8655-9cfdc66203fc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "03a9b21f-7dd4-38ff-896d-93b98c475316",
    "runId": 89,
    "name": "52192",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917351158,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+25:01",
            "B": "08:01+40:01",
            "C": "03:04+07:01",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52192_S89",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 405,
            "contamination": false,
            "meanReadsQuality": 35.53254917553116,
            "readsDistribution": {
                "targetLoci": 83132,
                "relatedLoci": 28170,
                "qualityTrimmed": 32114,
                "offTargetRegions": 37402
            },
            "targetLociCoverage": {
                "A": 19874,
                "B": 9316,
                "C": 19040,
                "DQB1": 13600,
                "DRB1": 21302
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2e3ec6a1-e5b5-3fe9-b13c-bd892db2519d",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "39cc44e4-4279-3e5d-8805-5d3999b2ee59",
    "runId": 90,
    "name": "52193",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917353808,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "13:02+49:01",
            "C": "06:02+07:01",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52193_S90",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 482,
            "contamination": false,
            "meanReadsQuality": 35.27032707418889,
            "readsDistribution": {
                "targetLoci": 82528,
                "relatedLoci": 7690,
                "qualityTrimmed": 31604,
                "offTargetRegions": 33262
            },
            "targetLociCoverage": {
                "A": 20132,
                "B": 8838,
                "C": 16932,
                "DQB1": 11680,
                "DRB1": 24946
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1cf6d0b1-5635-3c87-b74b-f4726f1f73a2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "8adf4f7d-7793-3d9c-a33a-0c9b1af6d0ea",
    "runId": 91,
    "name": "52194",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917334701,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "40:01+44:02",
            "C": "03:04+05:01",
            "DQB1": "06:02+06:04|06:39+06:84",
            "DRB1": "13:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52194_S91",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 410,
            "contamination": false,
            "meanReadsQuality": 35.46506266833106,
            "readsDistribution": {
                "targetLoci": 103578,
                "relatedLoci": 26204,
                "qualityTrimmed": 40170,
                "offTargetRegions": 44168
            },
            "targetLociCoverage": {
                "A": 25208,
                "B": 11466,
                "C": 24394,
                "DQB1": 18260,
                "DRB1": 24250
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "69f62459-cde0-388d-96b1-45c661524ba6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "c2fea07a-848a-3967-bd17-bb7d4af2ffb9",
    "runId": 92,
    "name": "52195",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917364773,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "08:01+35:01",
            "C": "04:01+07:01",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52195_S92",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 502,
            "contamination": false,
            "meanReadsQuality": 34.74222419024194,
            "readsDistribution": {
                "targetLoci": 55602,
                "relatedLoci": 14314,
                "qualityTrimmed": 33230,
                "offTargetRegions": 24562
            },
            "targetLociCoverage": {
                "A": 12784,
                "B": 6004,
                "C": 12330,
                "DQB1": 10738,
                "DRB1": 13746
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1e2d1402-2750-3c9a-8b4d-066bcd9aaec6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0ff3543a-7879-3578-a4e7-574c98c9ac04",
    "runId": 93,
    "name": "52196",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917361312,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+25:01",
            "B": "08:01+18:01",
            "C": "07:01+12:03",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52196_S93",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 502,
            "contamination": false,
            "meanReadsQuality": 35.11117043347161,
            "readsDistribution": {
                "targetLoci": 71130,
                "relatedLoci": 17020,
                "qualityTrimmed": 35028,
                "offTargetRegions": 35184
            },
            "targetLociCoverage": {
                "A": 17510,
                "B": 7236,
                "C": 15494,
                "DQB1": 12508,
                "DRB1": 18382
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "50ce33ef-56e4-3ccb-ad02-31c238562016",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "fd2c9f48-ff4a-3279-ade0-23f16167b530",
    "runId": 94,
    "name": "52197",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917364386,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+68:24",
            "B": "13:02+35:03",
            "C": "04:01+06:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+14:06"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52197_S94",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 430,
            "contamination": false,
            "meanReadsQuality": 35.217026850686295,
            "readsDistribution": {
                "targetLoci": 117452,
                "relatedLoci": 25732,
                "qualityTrimmed": 52092,
                "offTargetRegions": 50254
            },
            "targetLociCoverage": {
                "A": 29818,
                "B": 14774,
                "C": 27724,
                "DQB1": 15316,
                "DRB1": 29820
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4f89d6ac-8901-3562-bba8-87669395274a",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3aa08fb8-b187-30ca-93b5-a98a60cd827c",
    "runId": 95,
    "name": "52198",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917357503,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+31:01",
            "B": "08:01+51:01",
            "C": "07:01+15:02",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+04:07"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52198_S95",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 497,
            "contamination": false,
            "meanReadsQuality": 35.070186300292725,
            "readsDistribution": {
                "targetLoci": 57054,
                "relatedLoci": 15756,
                "qualityTrimmed": 28650,
                "offTargetRegions": 28580
            },
            "targetLociCoverage": {
                "A": 14506,
                "B": 6928,
                "C": 12546,
                "DQB1": 10074,
                "DRB1": 13000
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2f2b7011-a8b5-33cc-830d-cf2a7c9dfa01",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "22c051fe-db80-3234-a4bb-73ca1679910f",
    "runId": 96,
    "name": "52199",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917342833,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "11:01+68:01",
            "B": null,
            "C": "06:02+15:02",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52199_S96",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 411,
            "contamination": false,
            "meanReadsQuality": 35.02909323434946,
            "readsDistribution": {
                "targetLoci": 72744,
                "relatedLoci": 6756,
                "qualityTrimmed": 31994,
                "offTargetRegions": 28300
            },
            "targetLociCoverage": {
                "A": 18860,
                "B": 9622,
                "C": 16940,
                "DQB1": 7126,
                "DRB1": 20196
            }
        },
    },
    maybeResolutionDraft: {
        "genotypes": {
            "B": null
        },
        "resolution": "assigned",
        annotations: null
    },
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a53778a1-31fa-3b35-a35c-8048c782184a",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "7d231779-1e70-32d4-b796-5dea9fa05ea9",
    "runId": 97,
    "name": "52200",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917336712,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+68:01",
            "B": "35:01+40:01|35:380+40:379",
            "C": "03:04+04:01",
            "DQB1": "05:01+05:01",
            "DRB1": "01:01+01:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52200_S97",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.29614045209513,
            "readsDistribution": {
                "targetLoci": 119108,
                "relatedLoci": 32350,
                "qualityTrimmed": 49190,
                "offTargetRegions": 44490
            },
            "targetLociCoverage": {
                "A": 23540,
                "B": 12382,
                "C": 23958,
                "DQB1": 27168,
                "DRB1": 32060
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d8cb57f0-89ef-3f9f-971f-5cfdc8dd95d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d7920200-e6bc-3b82-a01b-e65592bcfcff",
    "runId": 98,
    "name": "52201",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917338438,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "27:05+39:06",
            "C": "01:02+07:02",
            "DQB1": "04:02+06:02",
            "DRB1": "08:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52201_S98",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 453,
            "contamination": false,
            "meanReadsQuality": 34.95904302285733,
            "readsDistribution": {
                "targetLoci": 75320,
                "relatedLoci": 5214,
                "qualityTrimmed": 34906,
                "offTargetRegions": 29690
            },
            "targetLociCoverage": {
                "A": 16158,
                "B": 7430,
                "C": 14650,
                "DQB1": 14706,
                "DRB1": 22376
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1e4b5fae-1858-3cc4-ac5b-d0d05ac2b837",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "6b6c6e6a-a69a-3144-8ee9-45e51a5c86af",
    "runId": 99,
    "name": "52202",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917341537,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+30:04",
            "B": "13:02+44:02",
            "C": "05:01+06:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52202_S99",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 450,
            "contamination": false,
            "meanReadsQuality": 34.97667400390526,
            "readsDistribution": {
                "targetLoci": 78024,
                "relatedLoci": 17720,
                "qualityTrimmed": 40896,
                "offTargetRegions": 37620
            },
            "targetLociCoverage": {
                "A": 18034,
                "B": 9196,
                "C": 17328,
                "DQB1": 13458,
                "DRB1": 20008
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d17f21f4-017b-3b96-b2e2-d94f71b5ec98",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d78acc36-b0f5-3a0f-b752-f50995c58baf",
    "runId": 100,
    "name": "52203",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917348421,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+33:05",
            "B": "07:02+14:02",
            "C": "07:02+08:02",
            "DQB1": "05:01+06:02",
            "DRB1": "01:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52203_S100",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 435,
            "contamination": false,
            "meanReadsQuality": 35.13038265306277,
            "readsDistribution": {
                "targetLoci": 109582,
                "relatedLoci": 20370,
                "qualityTrimmed": 47908,
                "offTargetRegions": 40858
            },
            "targetLociCoverage": {
                "A": 23386,
                "B": 11952,
                "C": 21358,
                "DQB1": 24084,
                "DRB1": 28802
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "44bc61d3-c362-3afc-93ae-9739b53d12d4",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "c7532750-3237-3d56-805d-1a27b216055f",
    "runId": 101,
    "name": "52288",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917365995,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+07:02",
            "C": "07:02+07:02",
            "DQB1": "05:01+05:02",
            "DRB1": "01:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52288_S101",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 449,
            "contamination": false,
            "meanReadsQuality": 35.26437932221171,
            "readsDistribution": {
                "targetLoci": 105528,
                "relatedLoci": 21114,
                "qualityTrimmed": 42208,
                "offTargetRegions": 40252
            },
            "targetLociCoverage": {
                "A": 23816,
                "B": 11306,
                "C": 18558,
                "DQB1": 25712,
                "DRB1": 26136
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "798b2a4e-3420-3f72-8d00-71d87c9bd481",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3a2cae15-a01c-3fa7-a5f6-9d9b67ec73de",
    "runId": 102,
    "name": "52289",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917350197,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+56:01",
            "C": "01:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52289_S102",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 461,
            "contamination": false,
            "meanReadsQuality": 34.884205787475324,
            "readsDistribution": {
                "targetLoci": 78018,
                "relatedLoci": 14358,
                "qualityTrimmed": 38508,
                "offTargetRegions": 29990
            },
            "targetLociCoverage": {
                "A": 20388,
                "B": 9418,
                "C": 19150,
                "DQB1": 9438,
                "DRB1": 19624
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "cdbe895d-a2e4-3732-bccb-a347006e48e9",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "4a1a9bf1-9a29-39f3-b106-ea5d6b09ed64",
    "runId": 103,
    "name": "52290",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917353405,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "07:02+57:01",
            "C": "06:02+07:02",
            "DQB1": "03:02+06:02",
            "DRB1": "04:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52290_S103",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 427,
            "contamination": false,
            "meanReadsQuality": 35.23386727999673,
            "readsDistribution": {
                "targetLoci": 86518,
                "relatedLoci": 10564,
                "qualityTrimmed": 34798,
                "offTargetRegions": 36782
            },
            "targetLociCoverage": {
                "A": 19586,
                "B": 10174,
                "C": 18580,
                "DQB1": 18276,
                "DRB1": 19902
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2cf51169-fc3b-3587-b797-e16c93ce581b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "31429a65-f071-302e-800d-224f5f9c05be",
    "runId": 104,
    "name": "52291",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917335359,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+47:01",
            "C": "06:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52291_S104",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 465,
            "contamination": false,
            "meanReadsQuality": 34.95144881287701,
            "readsDistribution": {
                "targetLoci": 47374,
                "relatedLoci": 10568,
                "qualityTrimmed": 23960,
                "offTargetRegions": 19330
            },
            "targetLociCoverage": {
                "A": 12312,
                "B": 5430,
                "C": 10012,
                "DQB1": 8466,
                "DRB1": 11154
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d9b747ea-2ba9-33b1-b255-8aa96cf3a5c9",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "6f2e226e-86cd-3ddf-9a79-761a901adbb2",
    "runId": 105,
    "name": "52292",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917342427,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "30:01+31:01",
            "B": "13:02+44:02",
            "C": "06:02+07:04",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52292_S105",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 392,
            "contamination": false,
            "meanReadsQuality": 35.569396950562144,
            "readsDistribution": {
                "targetLoci": 75518,
                "relatedLoci": 18648,
                "qualityTrimmed": 29210,
                "offTargetRegions": 37874
            },
            "targetLociCoverage": {
                "A": 17846,
                "B": 9402,
                "C": 17270,
                "DQB1": 10812,
                "DRB1": 20188
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "78ffc991-7995-345f-b6f1-79dbe46a808c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "46f8791d-ee0f-311b-ad03-2f8ac7bde4e9",
    "runId": 106,
    "name": "52293",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917332286,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+35:08",
            "C": "04:01+07:02",
            "DQB1": "05:02+06:03",
            "DRB1": "13:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52293_S106",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 423,
            "contamination": false,
            "meanReadsQuality": 35.32791841169001,
            "readsDistribution": {
                "targetLoci": 107012,
                "relatedLoci": 22534,
                "qualityTrimmed": 45470,
                "offTargetRegions": 41946
            },
            "targetLociCoverage": {
                "A": 26390,
                "B": 12810,
                "C": 22792,
                "DQB1": 21070,
                "DRB1": 23950
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "22f58f06-c150-30a2-aa38-0300b6df37d5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "ec2b86ce-4fc1-3d61-b976-0da28e4ae094",
    "runId": 107,
    "name": "52294",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917359926,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:05+26:01",
            "B": "50:01+55:01",
            "C": "03:03+06:02",
            "DQB1": "02:02+03:02",
            "DRB1": "04:02+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52294_S107",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.36957209175395,
            "readsDistribution": {
                "targetLoci": 148408,
                "relatedLoci": 21130,
                "qualityTrimmed": 60280,
                "offTargetRegions": 61872
            },
            "targetLociCoverage": {
                "A": 34132,
                "B": 15688,
                "C": 31006,
                "DQB1": 27570,
                "DRB1": 40012
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "0374bf50-8426-37ef-8bb8-689e11b43b75",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "6fa4b61e-d542-3b78-8c1e-b6127e2b638b",
    "runId": 108,
    "name": "52295",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917330939,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "26:01+31:01",
            "B": "15:01+50:01",
            "C": "03:03+06:02",
            "DQB1": "02:01+06:03",
            "DRB1": "03:01/03:147+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52295_S108",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 431,
            "contamination": false,
            "meanReadsQuality": 35.51124165739496,
            "readsDistribution": {
                "targetLoci": 113400,
                "relatedLoci": 38364,
                "qualityTrimmed": 49566,
                "offTargetRegions": 56546
            },
            "targetLociCoverage": {
                "A": 28204,
                "B": 11582,
                "C": 25578,
                "DQB1": 20598,
                "DRB1": 27438
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "9776f71a-c734-39dc-996e-b7478a5c1f7f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "bbd32ddf-d290-3815-b442-c1c10bdb3e64",
    "runId": 109,
    "name": "52296",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917338644,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+26:01",
            "B": "08:01+38:01",
            "C": "02:02+12:03",
            "DQB1": "02:01+06:03",
            "DRB1": "03:01/03:147+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52296_S109",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 455,
            "contamination": false,
            "meanReadsQuality": 35.46781823738291,
            "readsDistribution": {
                "targetLoci": 93368,
                "relatedLoci": 25490,
                "qualityTrimmed": 35600,
                "offTargetRegions": 29998
            },
            "targetLociCoverage": {
                "A": 24712,
                "B": 9490,
                "C": 21308,
                "DQB1": 15384,
                "DRB1": 22474
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "76cdd26e-5237-3412-9303-425b8ace149c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "f1ad619e-6396-3c90-9d26-47b1c9a92b01",
    "runId": 110,
    "name": "52297",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917364978,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+30:01",
            "B": "13:02+44:06",
            "C": "05:01+06:02",
            "DQB1": "03:01/03:276N/03:297+06:04",
            "DRB1": "11:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52297_S110",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 35.46568563611428,
            "readsDistribution": {
                "targetLoci": 95130,
                "relatedLoci": 26370,
                "qualityTrimmed": 37160,
                "offTargetRegions": 31626
            },
            "targetLociCoverage": {
                "A": 23826,
                "B": 11322,
                "C": 22076,
                "DQB1": 14642,
                "DRB1": 23264
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "25fc97a1-b20d-3505-9c8b-ef53eaab2673",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "b88eb21b-85f4-3779-bc27-687b8729fc74",
    "runId": 111,
    "name": "52298",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917354000,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+24:02",
            "B": "07:02+08:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52298_S111",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.5849509536022,
            "readsDistribution": {
                "targetLoci": 130156,
                "relatedLoci": 22016,
                "qualityTrimmed": 41814,
                "offTargetRegions": 40710
            },
            "targetLociCoverage": {
                "A": 32712,
                "B": 15782,
                "C": 28550,
                "DQB1": 18964,
                "DRB1": 34148
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c1d62f81-97d6-3b4a-ad24-9ad8e630811c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "dff44b2b-7361-399d-bfd3-e853b96ace04",
    "runId": 112,
    "name": "52299",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917359087,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "39:06+58:01",
            "C": "03:02+12:03",
            "DQB1": "05:02+06:09",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52299_S112",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.435746167975815,
            "readsDistribution": {
                "targetLoci": 62984,
                "relatedLoci": 11502,
                "qualityTrimmed": 23402,
                "offTargetRegions": 20894
            },
            "targetLociCoverage": {
                "A": 15338,
                "B": 6478,
                "C": 14186,
                "DQB1": 11468,
                "DRB1": 15514
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "8b3ff4af-1615-3919-8e81-77814d54838b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "9e7972a6-02c2-3bf5-9a95-cfa6bc960735",
    "runId": 113,
    "name": "52300",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917331698,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+25:01",
            "B": "18:01+35:01",
            "C": "04:01+12:03",
            "DQB1": "05:03+06:02",
            "DRB1": "14:54+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52300_S113",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 421,
            "contamination": false,
            "meanReadsQuality": 35.61957067769008,
            "readsDistribution": {
                "targetLoci": 136566,
                "relatedLoci": 24548,
                "qualityTrimmed": 42548,
                "offTargetRegions": 46844
            },
            "targetLociCoverage": {
                "A": 31880,
                "B": 15640,
                "C": 30334,
                "DQB1": 28870,
                "DRB1": 29842
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1b53cf28-6b76-3fe6-b583-e97f034e334b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "06bfd805-f573-35a6-aca0-f88e7ba396bc",
    "runId": 114,
    "name": "52301",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917350402,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+33:01",
            "B": "14:02+35:03",
            "C": "08:02+12:03",
            "DQB1": "03:04+05:01",
            "DRB1": "01:02+04:08"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52301_S114",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 441,
            "contamination": false,
            "meanReadsQuality": 35.30188887234482,
            "readsDistribution": {
                "targetLoci": 130646,
                "relatedLoci": 18370,
                "qualityTrimmed": 46546,
                "offTargetRegions": 39278
            },
            "targetLociCoverage": {
                "A": 31984,
                "B": 14836,
                "C": 28682,
                "DQB1": 25158,
                "DRB1": 29986
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "10aaf0e0-0a4d-3c73-8329-15dd31a681c6",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "2e0afabe-1862-3260-abab-6477a7cbb9f9",
    "runId": 115,
    "name": "52302",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917358885,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+25:01",
            "B": "15:01+18:01",
            "C": "03:04+12:03",
            "DQB1": "03:02+06:02",
            "DRB1": "04:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52302_S115",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 426,
            "contamination": false,
            "meanReadsQuality": 35.38401852322764,
            "readsDistribution": {
                "targetLoci": 158040,
                "relatedLoci": 5784,
                "qualityTrimmed": 49934,
                "offTargetRegions": 44744
            },
            "targetLociCoverage": {
                "A": 38208,
                "B": 15860,
                "C": 34034,
                "DQB1": 29652,
                "DRB1": 40286
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "9daecf98-6862-381d-8cbf-4e2a93ae5d87",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "a37bf667-0ce7-3ac8-96b9-7194dad1d08b",
    "runId": 116,
    "name": "52303",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917354883,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:704",
            "B": "18:01+35:01",
            "C": "04:01+12:03",
            "DQB1": "03:01/03:276N/03:297+03:03",
            "DRB1": "07:01+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52303_S116",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 447,
            "contamination": false,
            "meanReadsQuality": 35.35983935919971,
            "readsDistribution": {
                "targetLoci": 107110,
                "relatedLoci": 17766,
                "qualityTrimmed": 38656,
                "offTargetRegions": 34816
            },
            "targetLociCoverage": {
                "A": 25868,
                "B": 10904,
                "C": 25430,
                "DQB1": 17568,
                "DRB1": 27340
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "09059a3c-d7ef-311a-8bfd-f22e26b8b9e2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "f937f0c1-176a-3eb7-88b6-ca74595bb12f",
    "runId": 117,
    "name": "52304",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917342629,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+31:01",
            "B": "07:02+35:08",
            "C": "04:01+07:02",
            "DQB1": "02:02+06:04",
            "DRB1": "07:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52304_S117",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 435,
            "contamination": false,
            "meanReadsQuality": 35.59479351920184,
            "readsDistribution": {
                "targetLoci": 145986,
                "relatedLoci": 26032,
                "qualityTrimmed": 45182,
                "offTargetRegions": 50414
            },
            "targetLociCoverage": {
                "A": 38692,
                "B": 17500,
                "C": 33514,
                "DQB1": 19120,
                "DRB1": 37160
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1a0ccd74-4275-3004-8f21-b92c30415043",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "91bc0e42-c34f-3db7-9e24-ec89d97f5557",
    "runId": 118,
    "name": "52305",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917352179,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "35:01+57:01",
            "C": "04:01+06:02",
            "DQB1": "03:03+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52305_S118",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 441,
            "contamination": false,
            "meanReadsQuality": 35.340701819726334,
            "readsDistribution": {
                "targetLoci": 138366,
                "relatedLoci": 20736,
                "qualityTrimmed": 48020,
                "offTargetRegions": 44094
            },
            "targetLociCoverage": {
                "A": 30942,
                "B": 15810,
                "C": 30906,
                "DQB1": 24478,
                "DRB1": 36230
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f368c406-dc84-3e28-89bb-cb2269c1f421",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "ae5538ca-7290-3e73-9e07-de6df0f1b28d",
    "runId": 119,
    "name": "52306",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917367596,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+23:01",
            "B": "35:03+44:03",
            "C": "04:01+04:01",
            "DQB1": "02:02+04:02",
            "DRB1": "07:01+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52306_S119",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.59712630699594,
            "readsDistribution": {
                "targetLoci": 168702,
                "relatedLoci": 12844,
                "qualityTrimmed": 47960,
                "offTargetRegions": 51492
            },
            "targetLociCoverage": {
                "A": 36346,
                "B": 18606,
                "C": 36160,
                "DQB1": 28432,
                "DRB1": 49158
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "461a75c6-9470-3e0d-b23f-d7700a71ecc0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0c641fd0-0c00-3bc0-b2cc-5fe79bbbb135",
    "runId": 120,
    "name": "52307",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917330114,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+30:01",
            "B": "14:02+38:01",
            "C": "08:02+12:03",
            "DQB1": "05:02+06:09",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52307_S120",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.38585479653518,
            "readsDistribution": {
                "targetLoci": 77252,
                "relatedLoci": 14712,
                "qualityTrimmed": 26856,
                "offTargetRegions": 23674
            },
            "targetLociCoverage": {
                "A": 18092,
                "B": 8728,
                "C": 18364,
                "DQB1": 13762,
                "DRB1": 18306
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "23a973da-80c1-3ae8-a6d7-ffd517e301f5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "b6f116fe-6bc8-3849-bd6b-c3c79f26ed14",
    "runId": 121,
    "name": "52308",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917338031,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "07:02+35:02",
            "C": "06:02+07:02",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:03+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52308_S121",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 389,
            "contamination": false,
            "meanReadsQuality": 35.69113839673075,
            "readsDistribution": {
                "targetLoci": 94738,
                "relatedLoci": 20494,
                "qualityTrimmed": 28598,
                "offTargetRegions": 35378
            },
            "targetLociCoverage": {
                "A": 20460,
                "B": 12052,
                "C": 20256,
                "DQB1": 22312,
                "DRB1": 19658
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "163e46cd-fb57-34bf-9eca-87347a7d0ac7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3ae96396-d531-3882-a5be-e494d96df6d8",
    "runId": 122,
    "name": "52309",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917334135,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "C": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+66:01",
            "B": "27:05+41:02",
            "C": null,
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:01+13:03"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52309_S122",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 404,
            "contamination": false,
            "meanReadsQuality": 35.508980514872164,
            "readsDistribution": {
                "targetLoci": 135804,
                "relatedLoci": 39618,
                "qualityTrimmed": 48394,
                "offTargetRegions": 46334
            },
            "targetLociCoverage": {
                "A": 33766,
                "B": 14794,
                "C": 28740,
                "DQB1": 29054,
                "DRB1": 29450
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6a5b83b0-d069-3917-9457-2f9930a94393",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "b4c7fbcb-42ff-32d2-9364-4a93b1cd250c",
    "runId": 123,
    "name": "52310",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917333525,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 35438,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+25:01",
            "B": "07:02+18:01",
            "C": "07:02+12:03",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52310_S123",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.49636043466944,
            "readsDistribution": {
                "targetLoci": 152062,
                "relatedLoci": 31744,
                "qualityTrimmed": 53074,
                "offTargetRegions": 60088
            },
            "targetLociCoverage": {
                "A": 35948,
                "B": 16024,
                "C": 30474,
                "DQB1": 35438,
                "DRB1": 34178
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "bbb95c5d-a045-3557-a80d-f4b958a868fe",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d74169f9-91c9-39e1-9982-c94fd4b250d3",
    "runId": 124,
    "name": "52311",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917347823,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 42320,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "24:02+68:01",
            "B": "35:03+44:02",
            "C": "04:01+05:01",
            "DQB1": "03:02+03:02",
            "DRB1": "04:04+08:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52311_S124",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 35.54215468827792,
            "readsDistribution": {
                "targetLoci": 172384,
                "relatedLoci": 14288,
                "qualityTrimmed": 52016,
                "offTargetRegions": 55336
            },
            "targetLociCoverage": {
                "A": 35926,
                "B": 18276,
                "C": 34072,
                "DQB1": 42320,
                "DRB1": 41790
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "e412c559-715d-37a2-b7a4-822b1453ec58",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3cb03597-c35f-3e16-8c06-8265771e852d",
    "runId": 125,
    "name": "52312",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917360912,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+30:01",
            "B": "13:02+44:02",
            "C": "05:01+06:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "04:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52312_S125",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 428,
            "contamination": false,
            "meanReadsQuality": 35.57422671084513,
            "readsDistribution": {
                "targetLoci": 163410,
                "relatedLoci": 18578,
                "qualityTrimmed": 50214,
                "offTargetRegions": 63644
            },
            "targetLociCoverage": {
                "A": 36544,
                "B": 17876,
                "C": 35258,
                "DQB1": 28540,
                "DRB1": 45192
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "641b0abc-a7fc-3a7a-8f3f-d9651d604d47",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "01cd58bc-b6b4-34e8-ad0a-8f34f18a14df",
    "runId": 126,
    "name": "52313",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917351565,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 340518,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "02:01+02:11",
            "B": "27:05+48:01",
            "C": "02:02+08:03",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52313_S126",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.51601424615688,
            "readsDistribution": {
                "targetLoci": 161796,
                "relatedLoci": 40890,
                "qualityTrimmed": 60550,
                "offTargetRegions": 77282
            },
            "targetLociCoverage": {
                "A": 40164,
                "B": 17926,
                "C": 36730,
                "DQB1": 26884,
                "DRB1": 40092
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "ff502b65-ed7e-38b3-85da-68c6f20f44b3",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "849c9081-0b89-36be-81c6-ddb118a3f540",
    "runId": 127,
    "name": "52314",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343605,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+26:01",
            "B": "38:01+44:27",
            "C": "07:04+12:03",
            "DQB1": "05:01+05:02",
            "DRB1": "10:01+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52314_S127",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 432,
            "contamination": false,
            "meanReadsQuality": 35.55868429972039,
            "readsDistribution": {
                "targetLoci": 133360,
                "relatedLoci": 25714,
                "qualityTrimmed": 43756,
                "offTargetRegions": 52350
            },
            "targetLociCoverage": {
                "A": 31164,
                "B": 13362,
                "C": 26694,
                "DQB1": 30994,
                "DRB1": 31146
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "e7e30b2a-f323-3b89-9371-4c19af84a0a1",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "38e8cd93-1a95-37a5-ad18-7f6752829850",
    "runId": 128,
    "name": "52315",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917354441,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:05+30:01",
            "B": "13:02+52:01",
            "C": "06:02+12:02",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "11:03+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52315_S128",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 418,
            "contamination": false,
            "meanReadsQuality": 35.46080202925634,
            "readsDistribution": {
                "targetLoci": 91284,
                "relatedLoci": 17876,
                "qualityTrimmed": 33108,
                "offTargetRegions": 39074
            },
            "targetLociCoverage": {
                "A": 22054,
                "B": 12444,
                "C": 19952,
                "DQB1": 15626,
                "DRB1": 21208
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6c4e0256-b9c8-345e-ae3b-a1c1d04e96af",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3f62e7fe-9a18-31a4-a2ed-d9e8301ecd5c",
    "runId": 129,
    "name": "52316",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917366177,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "35:01+39:01",
            "C": "04:01+07:02",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52316_S129",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 374,
            "contamination": false,
            "meanReadsQuality": 35.56970014920141,
            "readsDistribution": {
                "targetLoci": 72136,
                "relatedLoci": 20234,
                "qualityTrimmed": 25546,
                "offTargetRegions": 32190
            },
            "targetLociCoverage": {
                "A": 16972,
                "B": 8764,
                "C": 16130,
                "DQB1": 13938,
                "DRB1": 16332
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "27af4397-03f8-3c3d-804c-a31eb362c551",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d3a5a048-0949-39bd-9748-d5cfaf261d6c",
    "runId": 130,
    "name": "52317",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917339049,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+26:01",
            "B": "13:02+56:01",
            "C": "06:02+07:02",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52317_S130",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.240414479856305,
            "readsDistribution": {
                "targetLoci": 96012,
                "relatedLoci": 21262,
                "qualityTrimmed": 40718,
                "offTargetRegions": 40890
            },
            "targetLociCoverage": {
                "A": 23848,
                "B": 11770,
                "C": 19508,
                "DQB1": 16158,
                "DRB1": 24728
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1c7b0d4d-a891-34a0-8790-32a86c0c7af2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "40dbbfc2-97ee-3681-b525-97a3ea129560",
    "runId": 131,
    "name": "52318",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917331294,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+35:01",
            "C": "04:01+07:02",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52318_S131",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 444,
            "contamination": false,
            "meanReadsQuality": 35.271895846253415,
            "readsDistribution": {
                "targetLoci": 124736,
                "relatedLoci": 26992,
                "qualityTrimmed": 51556,
                "offTargetRegions": 55118
            },
            "targetLociCoverage": {
                "A": 27754,
                "B": 13838,
                "C": 24812,
                "DQB1": 24510,
                "DRB1": 33822
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2a6aa05b-e29a-37ab-aa1f-e842e34a87da",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "7bc538cf-bfab-3111-b3fb-1dc3ee17140d",
    "runId": 132,
    "name": "52319",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343402,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 340270,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "26:01+68:02",
            "B": "14:02+40:02",
            "C": "03:04+08:02",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52319_S132",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 406,
            "contamination": false,
            "meanReadsQuality": 35.50676834932602,
            "readsDistribution": {
                "targetLoci": 161890,
                "relatedLoci": 46940,
                "qualityTrimmed": 61546,
                "offTargetRegions": 69894
            },
            "targetLociCoverage": {
                "A": 38484,
                "B": 16974,
                "C": 36312,
                "DQB1": 32718,
                "DRB1": 37402
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f8de5872-ac36-373f-b736-6a9cdeccd876",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "89d53782-6103-339f-befa-5c7c81b776ab",
    "runId": 133,
    "name": "52320",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917353608,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:06",
            "B": "15:01+44:02",
            "C": "01:02+03:03",
            "DQB1": "05:01+06:03",
            "DRB1": "01:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52320_S133",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 454,
            "contamination": false,
            "meanReadsQuality": 35.33645733275355,
            "readsDistribution": {
                "targetLoci": 108680,
                "relatedLoci": 25382,
                "qualityTrimmed": 42108,
                "offTargetRegions": 42012
            },
            "targetLociCoverage": {
                "A": 26658,
                "B": 11904,
                "C": 24682,
                "DQB1": 20074,
                "DRB1": 25362
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a5994e40-3ebb-3454-8415-6fb45696bdb2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "193e3a06-da88-37a5-b1c8-b81b286c8b00",
    "runId": 134,
    "name": "52321",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917335151,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+03:01",
            "B": "08:01+35:02",
            "C": "04:01+07:01",
            "DQB1": "02:01+03:01/03:276N/03:297",
            "DRB1": "03:01/03:147+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52321_S134",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.396329680226295,
            "readsDistribution": {
                "targetLoci": 109438,
                "relatedLoci": 29690,
                "qualityTrimmed": 41274,
                "offTargetRegions": 37056
            },
            "targetLociCoverage": {
                "A": 24316,
                "B": 12758,
                "C": 24664,
                "DQB1": 21384,
                "DRB1": 26316
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "71e14c14-5665-3428-925d-acff6ed58eb2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "ed191e83-8831-34d7-ac71-4410f6276416",
    "runId": 135,
    "name": "52322",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917342157,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+32:01",
            "B": "07:02+49:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "06:02+06:04|06:39+06:84",
            "DRB1": "13:02+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52322_S135",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 410,
            "contamination": false,
            "meanReadsQuality": 35.515711852547376,
            "readsDistribution": {
                "targetLoci": 123636,
                "relatedLoci": 26844,
                "qualityTrimmed": 41094,
                "offTargetRegions": 40274
            },
            "targetLociCoverage": {
                "A": 30828,
                "B": 15046,
                "C": 26202,
                "DQB1": 21952,
                "DRB1": 29608
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "766f51ec-5ce2-3f0b-94b8-0577a639dcfc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e1362c35-b391-38f7-a398-230e09d42701",
    "runId": 136,
    "name": "52323",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917332495,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+24:02",
            "B": "08:01+51:01",
            "C": "01:02+07:01",
            "DQB1": "03:01/03:276N/03:297+06:03",
            "DRB1": "11:04+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52323_S136",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 462,
            "contamination": false,
            "meanReadsQuality": 34.963994993176826,
            "readsDistribution": {
                "targetLoci": 57692,
                "relatedLoci": 8886,
                "qualityTrimmed": 25844,
                "offTargetRegions": 22166
            },
            "targetLociCoverage": {
                "A": 14584,
                "B": 7520,
                "C": 13790,
                "DQB1": 8002,
                "DRB1": 13796
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c62b70be-f4c5-3109-86d3-e6ac43bc31e0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e7ab5fb7-0baa-37b8-8d32-9d8b7502d31e",
    "runId": 137,
    "name": "52324",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917359691,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 76960,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "A": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 9338,
                        "quality": "yellow"
                    }
                },
                "B": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 4188,
                        "quality": "yellow"
                    }
                },
                "C": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 8544,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "33:03+68:01",
            "B": "35:01+58:34|35:03+58:01",
            "C": "03:02+04:01",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52324_S137",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 468,
            "contamination": false,
            "meanReadsQuality": 35.42677713373077,
            "readsDistribution": {
                "targetLoci": 37710,
                "relatedLoci": 9734,
                "qualityTrimmed": 14236,
                "offTargetRegions": 15280
            },
            "targetLociCoverage": {
                "A": 9338,
                "B": 4188,
                "C": 8544,
                "DQB1": 7042,
                "DRB1": 8598
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "d2ecaed4-e4c2-3a11-a35b-9c5c9d0b94a4",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "eed890d7-5b54-33f6-9b9d-c044c0618513",
    "runId": 138,
    "name": "52325",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917331154,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                },
                "C": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "03:01+66:01",
            "B": null,
            "C": null,
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52325_S138",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.40593470509256,
            "readsDistribution": {
                "targetLoci": 87824,
                "relatedLoci": 13460,
                "qualityTrimmed": 29874,
                "offTargetRegions": 29276
            },
            "targetLociCoverage": {
                "A": 21090,
                "B": 10434,
                "C": 19638,
                "DQB1": 13718,
                "DRB1": 22944
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b13d4fc7-ea56-3730-acdf-eda297de6263",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "10bc424c-0b4f-3880-ae38-bdcb8df1731a",
    "runId": 139,
    "name": "52326",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917338846,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "07:02+57:01",
            "C": "06:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52326_S139",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 428,
            "contamination": false,
            "meanReadsQuality": 35.51143462101925,
            "readsDistribution": {
                "targetLoci": 121032,
                "relatedLoci": 24448,
                "qualityTrimmed": 41428,
                "offTargetRegions": 45526
            },
            "targetLociCoverage": {
                "A": 29652,
                "B": 13886,
                "C": 26206,
                "DQB1": 23354,
                "DRB1": 27934
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c4b3a24c-ba1f-3087-8952-3c6ac95b1712",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "93f4fdca-6bb3-37f4-9c69-14d23a0a7356",
    "runId": 140,
    "name": "52327",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917333730,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+25:01",
            "B": "15:01+27:05",
            "C": "02:02+04:01",
            "DQB1": "03:03+04:02",
            "DRB1": "08:01+09:01/09:21/09:31"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52327_S140",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 474,
            "contamination": false,
            "meanReadsQuality": 35.47429172205072,
            "readsDistribution": {
                "targetLoci": 81078,
                "relatedLoci": 6268,
                "qualityTrimmed": 25832,
                "offTargetRegions": 25060
            },
            "targetLociCoverage": {
                "A": 17280,
                "B": 6624,
                "C": 15688,
                "DQB1": 20184,
                "DRB1": 21302
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f33fda69-347c-3543-bff9-0e1e3df1ecd7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0481fa87-2d34-3fc2-9d9b-089982d2c6f3",
    "runId": 141,
    "name": "52328",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917339877,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+32:01",
            "B": "15:01+48:01",
            "C": "03:03+08:03",
            "DQB1": "02:02+03:02",
            "DRB1": "07:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52328_S141",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 440,
            "contamination": false,
            "meanReadsQuality": 35.61082349053663,
            "readsDistribution": {
                "targetLoci": 126594,
                "relatedLoci": 23592,
                "qualityTrimmed": 40244,
                "offTargetRegions": 45660
            },
            "targetLociCoverage": {
                "A": 31424,
                "B": 13590,
                "C": 27312,
                "DQB1": 23098,
                "DRB1": 31170
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "e0e4e504-3a73-319d-9325-51a9effeb637",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "b6dbe8e8-e46f-3f97-b92d-2909447a2fcd",
    "runId": 142,
    "name": "52329",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917336508,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+11:01",
            "B": "44:05+52:01",
            "C": "02:02+12:02",
            "DQB1": "05:02+06:01",
            "DRB1": "15:02/15:140/15:149+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52329_S142",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 437,
            "contamination": false,
            "meanReadsQuality": 35.4641642568597,
            "readsDistribution": {
                "targetLoci": 124174,
                "relatedLoci": 5894,
                "qualityTrimmed": 37358,
                "offTargetRegions": 36464
            },
            "targetLociCoverage": {
                "A": 27518,
                "B": 14476,
                "C": 24144,
                "DQB1": 27768,
                "DRB1": 30268
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "abaf3c97-7856-32c8-9b60-dca8b5f4de72",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "61283016-c3c0-3e63-bf0f-01f4b5eb7737",
    "runId": 143,
    "name": "52330",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917329907,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "08:01+35:01",
            "C": "04:01+07:01",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52330_S143",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 437,
            "contamination": false,
            "meanReadsQuality": 35.603221052603956,
            "readsDistribution": {
                "targetLoci": 85044,
                "relatedLoci": 17360,
                "qualityTrimmed": 27072,
                "offTargetRegions": 32068
            },
            "targetLociCoverage": {
                "A": 19814,
                "B": 10424,
                "C": 20040,
                "DQB1": 14170,
                "DRB1": 20596
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "287407f6-c738-3bcb-bda3-9d5759da11e0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "c79364c5-316c-335f-bf79-a89e91c62e13",
    "runId": 144,
    "name": "52331",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917345625,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+32:01",
            "B": "40:02+44:02",
            "C": "02:02+07:04",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:01+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52331_S144",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 35.5102472232043,
            "readsDistribution": {
                "targetLoci": 74652,
                "relatedLoci": 19694,
                "qualityTrimmed": 25184,
                "offTargetRegions": 24478
            },
            "targetLociCoverage": {
                "A": 22838,
                "B": 8418,
                "C": 18712,
                "DQB1": 7030,
                "DRB1": 17654
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "3fac7f0d-b18c-36e0-ae18-e6cfb0064ddf",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0acc8486-dd52-3cf6-8388-16af44130617",
    "runId": 145,
    "name": "52332",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917363127,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "C": {
                    "novelAlleles": {
                        "type": "ANALYSIS_ISSUE",
                        "value": [ "07:04" ],
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "24:02+68:01",
            "B": "49:01+57:01",
            "C": "07:01+07:04",
            "DQB1": "02:01+03:02",
            "DRB1": "03:01/03:147+04:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52332_S145",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 34.94110131069891,
            "readsDistribution": {
                "targetLoci": 96650,
                "relatedLoci": 24640,
                "qualityTrimmed": 55928,
                "offTargetRegions": 47396
            },
            "targetLociCoverage": {
                "A": 25068,
                "B": 10644,
                "C": 21302,
                "DQB1": 19872,
                "DRB1": 19764
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "abd20383-3f0f-3aa4-9c43-8a4947c97831",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "217c8c9c-f320-3197-863e-b75265da0b5b",
    "runId": 146,
    "name": "52333",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917357305,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+31:01",
            "B": "18:01+27:05",
            "C": "02:02+07:01",
            "DQB1": "03:01/03:276N/03:297+04:02",
            "DRB1": "08:01+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52333_S146",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 442,
            "contamination": false,
            "meanReadsQuality": 34.4240967848304,
            "readsDistribution": {
                "targetLoci": 106268,
                "relatedLoci": 17728,
                "qualityTrimmed": 73420,
                "offTargetRegions": 40940
            },
            "targetLociCoverage": {
                "A": 26374,
                "B": 10508,
                "C": 21554,
                "DQB1": 22188,
                "DRB1": 25644
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2e29248f-103a-37c6-9e56-c2cf6749b953",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "247cbdf4-b794-393f-b05b-223898ba3999",
    "runId": 147,
    "name": "52334",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917341134,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+51:01",
            "C": "01:02+07:02",
            "DQB1": "06:02+06:03",
            "DRB1": "13:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52334_S147",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 439,
            "contamination": false,
            "meanReadsQuality": 34.64500091869491,
            "readsDistribution": {
                "targetLoci": 112608,
                "relatedLoci": 26064,
                "qualityTrimmed": 72776,
                "offTargetRegions": 47990
            },
            "targetLociCoverage": {
                "A": 25958,
                "B": 13966,
                "C": 22844,
                "DQB1": 23372,
                "DRB1": 26468
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "0ee530a7-a401-3a4a-bdd8-20308096e310",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "4a2fb702-0bf7-3fdf-b44f-f4a80528fe13",
    "runId": 148,
    "name": "52335",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917358482,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+40:01",
            "C": "03:04+07:02",
            "DQB1": "04:02+06:02",
            "DRB1": "08:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52335_S148",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 469,
            "contamination": false,
            "meanReadsQuality": 34.55050336276912,
            "readsDistribution": {
                "targetLoci": 93724,
                "relatedLoci": 9098,
                "qualityTrimmed": 60018,
                "offTargetRegions": 41322
            },
            "targetLociCoverage": {
                "A": 19352,
                "B": 9258,
                "C": 17162,
                "DQB1": 19930,
                "DRB1": 28022
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "544cce85-5bb0-379a-9be4-887883496bb5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d51d395d-9479-3638-95f6-51922da534e6",
    "runId": 149,
    "name": "PC",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917340449,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+11:01",
            "B": "18:01+44:02",
            "C": "02:02+05:01",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "04:01+08:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "PC_S149",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 491,
            "contamination": false,
            "meanReadsQuality": 34.592606669879046,
            "readsDistribution": {
                "targetLoci": 90698,
                "relatedLoci": 9982,
                "qualityTrimmed": 54588,
                "offTargetRegions": 37804
            },
            "targetLociCoverage": {
                "A": 21454,
                "B": 8438,
                "C": 19446,
                "DQB1": 19314,
                "DRB1": 22046
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a15f2a22-542e-3858-b29e-35fdf36dcdf5",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "58f80a23-120f-3bd8-a4f1-3681304756e0",
    "runId": 150,
    "name": "52336",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917355667,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "07:02+13:02",
            "C": "05:01+06:02",
            "DQB1": "02:02+06:02",
            "DRB1": "07:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52336_S150",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 452,
            "contamination": false,
            "meanReadsQuality": 34.47559007664968,
            "readsDistribution": {
                "targetLoci": 75634,
                "relatedLoci": 8710,
                "qualityTrimmed": 50176,
                "offTargetRegions": 33830
            },
            "targetLociCoverage": {
                "A": 17872,
                "B": 8382,
                "C": 16098,
                "DQB1": 11824,
                "DRB1": 21458
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "dc2d3ee7-6f99-332a-a5e9-86ba91a5aee8",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "22c0bb52-6e56-3000-9ce3-7f8ef90c97d0",
    "runId": 151,
    "name": "52337",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917356867,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 4788,
                        "quality": "yellow"
                    }
                },
                "C": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 9948,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "24:02+33:03",
            "B": "15:01+58:01",
            "C": "03:02+03:03|03:04+03:132",
            "DQB1": "06:03+06:09",
            "DRB1": "13:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52337_S151",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 471,
            "contamination": false,
            "meanReadsQuality": 34.556029854065166,
            "readsDistribution": {
                "targetLoci": 45480,
                "relatedLoci": 16036,
                "qualityTrimmed": 33298,
                "offTargetRegions": 21336
            },
            "targetLociCoverage": {
                "A": 11276,
                "B": 4788,
                "C": 9948,
                "DQB1": 8310,
                "DRB1": 11158
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "08cf21f1-411b-3e9a-b2ad-8b150f78cd9b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "2bd8e3d1-2d95-3952-bd27-9c09489014a4",
    "runId": 152,
    "name": "52338",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917345217,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 4566,
                        "quality": "yellow"
                    }
                },
                "C": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 9774,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+32:01",
            "B": "14:02+27:05",
            "C": "01:02+08:02",
            "DQB1": "02:01+05:01",
            "DRB1": "01:03+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52338_S152",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 467,
            "contamination": false,
            "meanReadsQuality": 34.403457525676586,
            "readsDistribution": {
                "targetLoci": 45542,
                "relatedLoci": 13214,
                "qualityTrimmed": 35932,
                "offTargetRegions": 23584
            },
            "targetLociCoverage": {
                "A": 11398,
                "B": 4566,
                "C": 9774,
                "DQB1": 8528,
                "DRB1": 11276
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a040d310-9990-3395-b530-c7808be438f1",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "42a3bdd9-5c92-33b5-a009-c283dca297f5",
    "runId": 153,
    "name": "52339",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917350953,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "44:02+51:01",
            "C": "05:01+12:03",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:01+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52339_S153",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 381,
            "contamination": false,
            "meanReadsQuality": 34.84952742500174,
            "readsDistribution": {
                "targetLoci": 59886,
                "relatedLoci": 15568,
                "qualityTrimmed": 37034,
                "offTargetRegions": 23924
            },
            "targetLociCoverage": {
                "A": 13542,
                "B": 7440,
                "C": 12772,
                "DQB1": 11770,
                "DRB1": 14362
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "96113c2f-6b19-3f57-94d9-15b15d673965",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "8e066d4b-63db-33ea-971d-f15060b732e7",
    "runId": 154,
    "name": "52340",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917344780,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+24:02",
            "B": "07:02+08:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+05:01",
            "DRB1": "01:01+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52340_S154",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 387,
            "contamination": false,
            "meanReadsQuality": 34.625602753875086,
            "readsDistribution": {
                "targetLoci": 121924,
                "relatedLoci": 34182,
                "qualityTrimmed": 87400,
                "offTargetRegions": 46482
            },
            "targetLociCoverage": {
                "A": 28858,
                "B": 13610,
                "C": 25402,
                "DQB1": 24370,
                "DRB1": 29684
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "676934bf-3cc8-30dd-a9df-4b3ba41efa46",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "5725a3e7-3213-3b13-8ffa-4eb352354350",
    "runId": 155,
    "name": "52341",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917350608,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "15:01+40:01",
            "C": "01:02+03:04",
            "DQB1": "03:03+04:02",
            "DRB1": "08:01+09:01/09:21/09:31"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52341_S155",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 427,
            "contamination": false,
            "meanReadsQuality": 34.25664640294208,
            "readsDistribution": {
                "targetLoci": 74998,
                "relatedLoci": 4460,
                "qualityTrimmed": 52772,
                "offTargetRegions": 22060
            },
            "targetLociCoverage": {
                "A": 15268,
                "B": 7854,
                "C": 14392,
                "DQB1": 16750,
                "DRB1": 20734
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "74b9d418-665b-3ae6-acfe-a0907b41655f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "60f634c5-1ecc-3738-9ce1-302ad8806deb",
    "runId": 156,
    "name": "52342",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917356665,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+32:01",
            "B": "14:02+56:01",
            "C": "01:02+07:04",
            "DQB1": "03:01/03:276N/03:297+05:01",
            "DRB1": "01:02+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52342_S156",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 34.7892765395678,
            "readsDistribution": {
                "targetLoci": 99364,
                "relatedLoci": 25554,
                "qualityTrimmed": 65110,
                "offTargetRegions": 40950
            },
            "targetLociCoverage": {
                "A": 23590,
                "B": 11114,
                "C": 21454,
                "DQB1": 19834,
                "DRB1": 23372
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "fb8ebd28-00f5-3363-9ea6-c299587f25bc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "1b59ff2d-c64e-33a5-a035-212bd3bdf2ec",
    "runId": 157,
    "name": "52343",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917355088,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "C": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "01:01+66:01",
            "B": "08:01+41:02",
            "C": null,
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52343_S157",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 408,
            "contamination": false,
            "meanReadsQuality": 34.842154576856345,
            "readsDistribution": {
                "targetLoci": 83604,
                "relatedLoci": 18330,
                "qualityTrimmed": 52054,
                "offTargetRegions": 37126
            },
            "targetLociCoverage": {
                "A": 21156,
                "B": 9120,
                "C": 19034,
                "DQB1": 13946,
                "DRB1": 20348
            }
        },
    },
    maybeResolutionDraft: {
        "genotypes": {
            "C": "07:458+07:419"
        },
        "resolution": null,
        annotations: null
    },
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c2fe5654-2bf0-324f-8a7f-81283b001a0e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3edc49e0-6648-3752-b7c7-7a9d22bd4a81",
    "runId": 158,
    "name": "52344",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917358091,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "08:01+37:01",
            "C": "06:02+07:01",
            "DQB1": "03:01/03:276N/03:297+03:03",
            "DRB1": "09:01/09:21/09:31+11:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52344_S158",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 392,
            "contamination": false,
            "meanReadsQuality": 34.71301835394114,
            "readsDistribution": {
                "targetLoci": 89988,
                "relatedLoci": 14198,
                "qualityTrimmed": 54120,
                "offTargetRegions": 28240
            },
            "targetLociCoverage": {
                "A": 19112,
                "B": 9678,
                "C": 19556,
                "DQB1": 19390,
                "DRB1": 22252
            }
        },
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2b9dfdb3-4a5e-35fa-8095-2f624690fc08",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "462ee9a1-d27a-3bca-ab04-3cedc84bc995",
    "runId": 159,
    "name": "52345",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917352992,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "35:03+44:27",
            "C": "04:01+07:04",
            "DQB1": "03:04+06:03",
            "DRB1": "04:08+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52345_S159",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 423,
            "contamination": false,
            "meanReadsQuality": 34.738024744545555,
            "readsDistribution": {
                "targetLoci": 69444,
                "relatedLoci": 13194,
                "qualityTrimmed": 42616,
                "offTargetRegions": 27174
            },
            "targetLociCoverage": {
                "A": 18102,
                "B": 8822,
                "C": 16486,
                "DQB1": 11284,
                "DRB1": 14750
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a0564318-a11d-31a6-9285-bf8653cb1a65",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "6187ee85-32ba-39a0-8e15-56052d91e82e",
    "runId": 160,
    "name": "52346",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917332115,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+24:02",
            "B": null,
            "C": "03:03+06:02",
            "DQB1": "02:02+04:02",
            "DRB1": "07:01+08:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52346_S160",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 431,
            "contamination": false,
            "meanReadsQuality": 34.46240954585354,
            "readsDistribution": {
                "targetLoci": 51616,
                "relatedLoci": 2274,
                "qualityTrimmed": 32380,
                "offTargetRegions": 15536
            },
            "targetLociCoverage": {
                "A": 12252,
                "B": 6008,
                "C": 10836,
                "DQB1": 7318,
                "DRB1": 15202
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "14c7446d-297e-3d82-8c69-b700eabc47ca",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "19b64cd6-a258-3bdd-b5a1-eaee89e59eaa",
    "runId": 161,
    "name": "52347",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917330742,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+32:01",
            "B": "35:01+37:01",
            "C": "04:01+04:01",
            "DQB1": "03:01/03:276N/03:297+03:02",
            "DRB1": "04:02+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52347_S161",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 424,
            "contamination": false,
            "meanReadsQuality": 35.03121855117239,
            "readsDistribution": {
                "targetLoci": 102348,
                "relatedLoci": 17140,
                "qualityTrimmed": 45958,
                "offTargetRegions": 33408
            },
            "targetLociCoverage": {
                "A": 25194,
                "B": 11988,
                "C": 23918,
                "DQB1": 21896,
                "DRB1": 19352
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "a6dfcb2c-782f-3b5c-a26e-c3de469fb5a0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e5bec5ef-9e47-371c-8438-1c1684a03dd2",
    "runId": 162,
    "name": "52348",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917337356,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "novelAlleles": {
                        "type": "ANALYSIS_ISSUE",
                        "value": [ "08:01" ],
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+08:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+06:02",
            "DRB1": "03:01/03:147+15:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52348_S162",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 427,
            "contamination": false,
            "meanReadsQuality": 34.65671225100248,
            "readsDistribution": {
                "targetLoci": 63654,
                "relatedLoci": 11248,
                "qualityTrimmed": 35856,
                "offTargetRegions": 20040
            },
            "targetLociCoverage": {
                "A": 16262,
                "B": 8306,
                "C": 13208,
                "DQB1": 9716,
                "DRB1": 16162
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2413c126-12e9-3ff1-b8dd-3cf7439901a2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e88fc464-c447-3fa8-a57e-41c34c454619",
    "runId": 163,
    "name": "52349",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917334492,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "38:01+44:27",
            "C": "07:04+12:03",
            "DQB1": "05:02+06:04",
            "DRB1": "13:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52349_S163",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 374,
            "contamination": false,
            "meanReadsQuality": 35.21349707895216,
            "readsDistribution": {
                "targetLoci": 136124,
                "relatedLoci": 26674,
                "qualityTrimmed": 56194,
                "offTargetRegions": 43254
            },
            "targetLociCoverage": {
                "A": 32628,
                "B": 14930,
                "C": 30262,
                "DQB1": 26818,
                "DRB1": 31486
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "503eec84-2d62-35e4-910c-a3aaefccc394",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "fb519a02-a4e1-32fd-8855-0cb36d8c2d13",
    "runId": 164,
    "name": "52350",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917347638,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+11:01",
            "B": "07:02+56:01",
            "C": "01:02+07:02",
            "DQB1": "06:02+06:02",
            "DRB1": "15:01+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52350_S164",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 433,
            "contamination": false,
            "meanReadsQuality": 34.93756954206903,
            "readsDistribution": {
                "targetLoci": 113244,
                "relatedLoci": 2538,
                "qualityTrimmed": 44568,
                "offTargetRegions": 25004
            },
            "targetLociCoverage": {
                "A": 26152,
                "B": 13106,
                "C": 23154,
                "DQB1": 15398,
                "DRB1": 35434
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c60f6bd4-d1f8-35f0-af97-0a9b52242213",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "72b99fa2-1830-3e11-bd84-9dd9a59cca2d",
    "runId": 165,
    "name": "52351",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349382,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:02+11:01",
            "B": "07:02+08:01",
            "C": "07:01+07:02|07:27+07:639",
            "DQB1": "02:01+03:04",
            "DRB1": "03:01/03:147+04:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52351_S165",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 391,
            "contamination": false,
            "meanReadsQuality": 35.19428711668834,
            "readsDistribution": {
                "targetLoci": 137848,
                "relatedLoci": 25842,
                "qualityTrimmed": 55036,
                "offTargetRegions": 41970
            },
            "targetLociCoverage": {
                "A": 35294,
                "B": 16690,
                "C": 29332,
                "DQB1": 26462,
                "DRB1": 30070
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "2850737d-5f64-3780-8cdc-626c557111c7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "feee9e5b-f93f-38fc-a3da-5cc5f198081a",
    "runId": 166,
    "name": "52352",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917358281,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+68:01",
            "B": "13:02+35:03",
            "C": "04:01+06:02",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52352_S166",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 34.912778360065744,
            "readsDistribution": {
                "targetLoci": 115126,
                "relatedLoci": 16218,
                "qualityTrimmed": 54960,
                "offTargetRegions": 37762
            },
            "targetLociCoverage": {
                "A": 26716,
                "B": 13688,
                "C": 24232,
                "DQB1": 20246,
                "DRB1": 30244
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "839d4549-ad6f-342a-a8d4-444daab2e73e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "5763bbe8-e442-3bfd-9d42-b803e340f963",
    "runId": 167,
    "name": "52353",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917353197,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:05",
            "B": "37:01+49:01",
            "C": "06:02+07:01",
            "DQB1": "05:01+06:04",
            "DRB1": "10:01+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52353_S167",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 398,
            "contamination": false,
            "meanReadsQuality": 35.145452830273115,
            "readsDistribution": {
                "targetLoci": 95816,
                "relatedLoci": 25896,
                "qualityTrimmed": 44854,
                "offTargetRegions": 37652
            },
            "targetLociCoverage": {
                "A": 23366,
                "B": 11112,
                "C": 21348,
                "DQB1": 17160,
                "DRB1": 22830
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f172def7-bd86-3f42-a824-8d0ac5389ecb",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "388586b6-1787-3b5b-979c-961661a87abf",
    "runId": 168,
    "name": "52448",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917340942,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+01:01",
            "B": "08:01+13:02",
            "C": "06:02+07:01",
            "DQB1": "02:01+02:02",
            "DRB1": "03:01/03:147+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52448_S168",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 392,
            "contamination": false,
            "meanReadsQuality": 35.07263245868867,
            "readsDistribution": {
                "targetLoci": 99362,
                "relatedLoci": 19032,
                "qualityTrimmed": 44592,
                "offTargetRegions": 35114
            },
            "targetLociCoverage": {
                "A": 22938,
                "B": 12560,
                "C": 23260,
                "DQB1": 13706,
                "DRB1": 26898
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c0bbfc21-f07a-3aeb-bbc3-d65421dfcd6e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "1c3af285-41e8-39a9-960e-7f15c17f1211",
    "runId": 169,
    "name": "52449",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343813,
    "status": "IN_SUPPORT",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "red",
        "problems": {
            "locusSpecificMetrics": {
                "B": {
                    "genotypesNumber": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 0,
                        "quality": "red"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+11:01",
            "B": null,
            "C": "04:01+06:02",
            "DQB1": "02:02+03:02",
            "DRB1": "04:03+07:01"
        },
        "resolution": "SUPPORT_REQUEST",
        "sampleName": "52449_S169",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 396,
            "contamination": false,
            "meanReadsQuality": 35.64043609836129,
            "readsDistribution": {
                "targetLoci": 172004,
                "relatedLoci": 10950,
                "qualityTrimmed": 46494,
                "offTargetRegions": 52528
            },
            "targetLociCoverage": {
                "A": 40240,
                "B": 21148,
                "C": 37694,
                "DQB1": 27720,
                "DRB1": 45202
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f81571a8-7468-3918-90e6-86bccad32582",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "cceab466-eae3-3445-b30b-bb9987222c05",
    "runId": 170,
    "name": "52450",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917367391,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 311684,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "01:01+24:02",
            "B": "07:02+37:01",
            "C": "06:02+07:02",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "12:01/12:10+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52450_S170",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 426,
            "contamination": false,
            "meanReadsQuality": 35.37447844739336,
            "readsDistribution": {
                "targetLoci": 156034,
                "relatedLoci": 30520,
                "qualityTrimmed": 60388,
                "offTargetRegions": 64742
            },
            "targetLociCoverage": {
                "A": 38134,
                "B": 17736,
                "C": 34372,
                "DQB1": 29322,
                "DRB1": 36470
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "1b2e4709-69cf-3fa8-8da3-11aad17271cc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "3d0fd753-a66d-387d-938a-d3d289167dd9",
    "runId": 171,
    "name": "52451",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917363506,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 358174,
                    "quality": "yellow"
                }
            }
        },
        "genotypes": {
            "A": "24:02+32:01",
            "B": "35:01+51:01|53:01+78:02",
            "C": "01:02+04:01",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52451_S171",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 417,
            "contamination": false,
            "meanReadsQuality": 35.42171689315826,
            "readsDistribution": {
                "targetLoci": 183724,
                "relatedLoci": 35202,
                "qualityTrimmed": 66650,
                "offTargetRegions": 72598
            },
            "targetLociCoverage": {
                "A": 40854,
                "B": 22304,
                "C": 38100,
                "DQB1": 33712,
                "DRB1": 48754
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "80270374-e6e6-34b6-b249-bff8d89aeefc",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "061bb45a-f4a4-3b15-8f0a-7cc8a5a93012",
    "runId": 172,
    "name": "52452",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917360508,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "25:01+26:01",
            "B": "35:01+38:01",
            "C": "04:01+12:03",
            "DQB1": "05:01+05:03",
            "DRB1": "01:01+14:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52452_S172",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 444,
            "contamination": false,
            "meanReadsQuality": 35.438168389477696,
            "readsDistribution": {
                "targetLoci": 136136,
                "relatedLoci": 32762,
                "qualityTrimmed": 49584,
                "offTargetRegions": 45110
            },
            "targetLociCoverage": {
                "A": 29314,
                "B": 14956,
                "C": 28548,
                "DQB1": 33376,
                "DRB1": 29942
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "c4c5bd3e-d2ca-317a-9018-b49cc2670bf3",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "28f42acf-02c0-303c-a0ab-253771ece703",
    "runId": 173,
    "name": "52453",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917361750,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 415108,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "B": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 25558,
                        "quality": "yellow"
                    }
                },
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 37210,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "01:01+24:02",
            "B": "44:02+53:01",
            "C": "05:01+07:04",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+12:01/12:10"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52453_S173",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.631398518358935,
            "readsDistribution": {
                "targetLoci": 208032,
                "relatedLoci": 44882,
                "qualityTrimmed": 69210,
                "offTargetRegions": 92984
            },
            "targetLociCoverage": {
                "A": 48204,
                "B": 25558,
                "C": 46338,
                "DQB1": 37210,
                "DRB1": 50722
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "16a69281-faaf-30dc-bbe9-4a8776ded92c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "2eb6032e-2170-3743-9684-f62fb353d789",
    "runId": 174,
    "name": "52504",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917337120,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 312624,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 36118,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "02:01+03:01",
            "B": "35:01+40:01|35:380+40:379",
            "C": "03:04+04:01",
            "DQB1": "03:02+05:01",
            "DRB1": "01:01+04:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52504_S174",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 422,
            "contamination": false,
            "meanReadsQuality": 35.49242119005324,
            "readsDistribution": {
                "targetLoci": 157254,
                "relatedLoci": 33944,
                "qualityTrimmed": 56594,
                "offTargetRegions": 64832
            },
            "targetLociCoverage": {
                "A": 34474,
                "B": 17802,
                "C": 33944,
                "DQB1": 36118,
                "DRB1": 34916
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7f2017eb-4406-3fd0-934a-190052fa70e0",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "0693b09f-77e9-3d22-9662-5e93306e79f7",
    "runId": 175,
    "name": "52505",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917334295,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "yellow",
        "problems": {
            "generalMetrics": {
                "totalReads": {
                    "type": "LAB_ISSUE",
                    "value": 388506,
                    "quality": "yellow"
                }
            },
            "locusSpecificMetrics": {
                "DQB1": {
                    "locusCoverage": {
                        "type": "ANALYSIS_ISSUE",
                        "value": 42196,
                        "quality": "yellow"
                    }
                }
            }
        },
        "genotypes": {
            "A": "24:02+68:01",
            "B": "35:02+38:01",
            "C": "04:01+07:04",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "11:01+11:04"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52505_S175",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 414,
            "contamination": false,
            "meanReadsQuality": 35.68025799153238,
            "readsDistribution": {
                "targetLoci": 193288,
                "relatedLoci": 54118,
                "qualityTrimmed": 63742,
                "offTargetRegions": 77358
            },
            "targetLociCoverage": {
                "A": 44840,
                "B": 21710,
                "C": 42112,
                "DQB1": 42196,
                "DRB1": 42430
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "42f28697-9828-3f94-9470-cf53f2a0b170",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "2c012c6c-9573-3f66-b00e-97357c37d3b1",
    "runId": 176,
    "name": "52506",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917330540,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+24:02",
            "B": "08:01+40:01",
            "C": "03:04+07:01",
            "DQB1": "02:01+02:01",
            "DRB1": "03:01/03:147+03:01/03:147"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52506_S176",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 35.44732172492232,
            "readsDistribution": {
                "targetLoci": 96514,
                "relatedLoci": 30244,
                "qualityTrimmed": 37874,
                "offTargetRegions": 38964
            },
            "targetLociCoverage": {
                "A": 24698,
                "B": 10564,
                "C": 22104,
                "DQB1": 14684,
                "DRB1": 24464
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "6d5f1f8c-75b5-3bc7-932b-d4a4a5b9f09e",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e6477eaf-4c18-39da-b7d4-f0667c364a57",
    "runId": 177,
    "name": "52507",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917331908,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+26:01",
            "B": "35:01+38:01",
            "C": "04:01+12:03",
            "DQB1": "05:01+06:03",
            "DRB1": "01:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52507_S177",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 386,
            "contamination": false,
            "meanReadsQuality": 35.271460777466515,
            "readsDistribution": {
                "targetLoci": 79492,
                "relatedLoci": 23060,
                "qualityTrimmed": 35702,
                "offTargetRegions": 32824
            },
            "targetLociCoverage": {
                "A": 18474,
                "B": 8900,
                "C": 18510,
                "DQB1": 15514,
                "DRB1": 18094
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "5589ae34-aab4-30d7-b675-75a8b2d7fc7f",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "650fe990-79ed-326a-87bc-f48e8b87576f",
    "runId": 178,
    "name": "52508",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917349792,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "11:01+24:02",
            "B": "35:01+44:02",
            "C": "04:01+05:01",
            "DQB1": "03:01/03:276N/03:297+03:01/03:276N/03:297",
            "DRB1": "04:01+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52508_S178",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 423,
            "contamination": false,
            "meanReadsQuality": 34.92705681681679,
            "readsDistribution": {
                "targetLoci": 81686,
                "relatedLoci": 19050,
                "qualityTrimmed": 42954,
                "offTargetRegions": 31406
            },
            "targetLociCoverage": {
                "A": 19470,
                "B": 9598,
                "C": 18736,
                "DQB1": 17900,
                "DRB1": 15982
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7ff76a09-48f8-3f3e-bf25-dd9adbdf3e93",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "a81aaa0c-9314-3bf7-866d-2bbe3431a198",
    "runId": 180,
    "name": "52510",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917335552,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "13:02+41:02",
            "C": "06:02+17:03",
            "DQB1": "02:02+03:01/03:276N/03:297",
            "DRB1": "07:01+13:03"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52510_S180",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 429,
            "contamination": false,
            "meanReadsQuality": 35.03733156014134,
            "readsDistribution": {
                "targetLoci": 97034,
                "relatedLoci": 20782,
                "qualityTrimmed": 48732,
                "offTargetRegions": 41316
            },
            "targetLociCoverage": {
                "A": 25152,
                "B": 11360,
                "C": 21556,
                "DQB1": 15870,
                "DRB1": 23096
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "bdd6e8a2-5077-3608-bc87-4fb8f59c9272",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "52d13d85-1871-3e03-a07f-5188de665c4a",
    "runId": 182,
    "name": "52512",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917332910,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "08:01+35:01",
            "C": "04:01+06:02",
            "DQB1": "02:02+05:01",
            "DRB1": "01:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52512_S182",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 416,
            "contamination": false,
            "meanReadsQuality": 35.052002361854136,
            "readsDistribution": {
                "targetLoci": 97020,
                "relatedLoci": 14650,
                "qualityTrimmed": 42740,
                "offTargetRegions": 31660
            },
            "targetLociCoverage": {
                "A": 21728,
                "B": 10814,
                "C": 20924,
                "DQB1": 17368,
                "DRB1": 26186
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "7dc60271-0c05-31d0-9d44-5b5860a0d02b",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "e6643a43-f9cd-33ad-bfdb-56fc98151a80",
    "runId": 183,
    "name": "52513",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917329098,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "23:01+33:03",
            "B": "44:03+58:01",
            "C": "03:02+04:01",
            "DQB1": "02:01+02:02",
            "DRB1": "03:01/03:147+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52513_S183",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 402,
            "contamination": false,
            "meanReadsQuality": 35.27226410369421,
            "readsDistribution": {
                "targetLoci": 131574,
                "relatedLoci": 27806,
                "qualityTrimmed": 53796,
                "offTargetRegions": 48212
            },
            "targetLociCoverage": {
                "A": 32582,
                "B": 14222,
                "C": 30418,
                "DQB1": 21192,
                "DRB1": 33160
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "732533af-8b77-3aa5-ae8e-eda08f9a06e3",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "10dd4184-39f3-3f9d-acba-1b63bb6a4f6e",
    "runId": 184,
    "name": "52514",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917337828,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+33:03",
            "B": "07:02+35:02",
            "C": "04:01+07:02",
            "DQB1": "02:02+06:03",
            "DRB1": "07:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52514_S184",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 374,
            "contamination": false,
            "meanReadsQuality": 35.22339741622935,
            "readsDistribution": {
                "targetLoci": 104674,
                "relatedLoci": 20102,
                "qualityTrimmed": 43156,
                "offTargetRegions": 37334
            },
            "targetLociCoverage": {
                "A": 25482,
                "B": 14444,
                "C": 23918,
                "DQB1": 14098,
                "DRB1": 26732
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "db6bcd3d-6bba-334a-9ccc-51dca7123e1c",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "d2830a56-dd8e-3a75-b5b6-b695135cc311",
    "runId": 185,
    "name": "52515",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917339253,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "03:01+32:01",
            "B": "07:02+27:05",
            "C": "02:02+07:02",
            "DQB1": "03:03+06:02",
            "DRB1": "09:01/09:21/09:31+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52515_S185",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 366,
            "contamination": false,
            "meanReadsQuality": 35.663810890196274,
            "readsDistribution": {
                "targetLoci": 98456,
                "relatedLoci": 7018,
                "qualityTrimmed": 26656,
                "offTargetRegions": 29114
            },
            "targetLociCoverage": {
                "A": 22558,
                "B": 10768,
                "C": 20518,
                "DQB1": 18552,
                "DRB1": 26060
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "4ded2335-0899-3b8e-b248-b87b1e012ed2",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "9b05976c-dc96-3610-8249-56059f9f94f1",
    "runId": 186,
    "name": "52516",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917343195,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+03:01",
            "B": "07:02+51:01",
            "C": "02:02+07:02",
            "DQB1": "03:01/03:276N/03:297+06:03",
            "DRB1": "11:01+13:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52516_S186",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 404,
            "contamination": false,
            "meanReadsQuality": 35.33995766768729,
            "readsDistribution": {
                "targetLoci": 91978,
                "relatedLoci": 27226,
                "qualityTrimmed": 37946,
                "offTargetRegions": 32294
            },
            "targetLociCoverage": {
                "A": 22282,
                "B": 12544,
                "C": 19920,
                "DQB1": 16232,
                "DRB1": 21000
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "b38bf9f7-6309-3b45-9499-6be460ef7161",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "5f07c896-7a43-31e4-8e09-0499ee45dbc1",
    "runId": 187,
    "name": "52517",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917340270,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+32:01",
            "B": "07:02+50:01",
            "C": "06:02+07:02",
            "DQB1": "02:02+02:02",
            "DRB1": "07:01+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52517_S187",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 471,
            "contamination": false,
            "meanReadsQuality": 34.794085021803056,
            "readsDistribution": {
                "targetLoci": 80864,
                "relatedLoci": 6094,
                "qualityTrimmed": 36048,
                "offTargetRegions": 24136
            },
            "targetLociCoverage": {
                "A": 18814,
                "B": 9020,
                "C": 15652,
                "DQB1": 10748,
                "DRB1": 26630
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "45114f82-0107-3223-9b0c-207e58a025c7",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "a44a6ead-84e6-3314-be6c-43930e61b842",
    "runId": 188,
    "name": "52518",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917365182,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+02:01",
            "B": "15:01+35:02",
            "C": "04:01+04:01",
            "DQB1": "03:03+04:02",
            "DRB1": "04:04+07:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52518_S188",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 488,
            "contamination": false,
            "meanReadsQuality": 35.094441878931065,
            "readsDistribution": {
                "targetLoci": 75168,
                "relatedLoci": 8230,
                "qualityTrimmed": 31818,
                "offTargetRegions": 26386
            },
            "targetLociCoverage": {
                "A": 16844,
                "B": 7730,
                "C": 15822,
                "DQB1": 16590,
                "DRB1": 18182
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "80b5facc-656b-3928-8794-75b7ee3ea631",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "030a4dfd-8eef-3be6-9937-b9a56b196bfc",
    "runId": 189,
    "name": "52519",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917366585,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "07:02+40:02",
            "C": "02:02+07:02",
            "DQB1": "03:01/03:276N/03:297+06:02",
            "DRB1": "04:08+15:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52519_S189",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 464,
            "contamination": false,
            "meanReadsQuality": 35.31810160925808,
            "readsDistribution": {
                "targetLoci": 87128,
                "relatedLoci": 8270,
                "qualityTrimmed": 31388,
                "offTargetRegions": 31868
            },
            "targetLociCoverage": {
                "A": 22338,
                "B": 9892,
                "C": 18106,
                "DQB1": 16904,
                "DRB1": 19888
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "ddc6bf83-260a-3f5b-b13a-2d84f97cd4ea",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "88020446-7e6d-3863-804a-cde2cd795e9b",
    "runId": 190,
    "name": "52520",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917348822,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "02:01+02:01",
            "B": "40:01+51:01",
            "C": "02:02+03:04",
            "DQB1": "03:01/03:276N/03:297+03:03",
            "DRB1": "09:01/09:21/09:31+11:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52520_S190",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 421,
            "contamination": false,
            "meanReadsQuality": 35.35339358651727,
            "readsDistribution": {
                "targetLoci": 133028,
                "relatedLoci": 19884,
                "qualityTrimmed": 48092,
                "offTargetRegions": 43020
            },
            "targetLociCoverage": {
                "A": 32016,
                "B": 16162,
                "C": 28392,
                "DQB1": 26526,
                "DRB1": 29932
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "63e81db5-9411-3685-9757-5dac04995744",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "82147b40-57af-3fb9-a5c0-22b4e4bf99fc",
    "runId": 191,
    "name": "52521",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917346638,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "01:01+25:01",
            "B": "07:05+14:02",
            "C": "08:02+15:05",
            "DQB1": "03:01/03:276N/03:297+06:04",
            "DRB1": "11:04+13:02"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52521_S191",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 446,
            "contamination": false,
            "meanReadsQuality": 35.37782662217529,
            "readsDistribution": {
                "targetLoci": 89112,
                "relatedLoci": 27686,
                "qualityTrimmed": 35172,
                "offTargetRegions": 31222
            },
            "targetLociCoverage": {
                "A": 22154,
                "B": 10066,
                "C": 20828,
                "DQB1": 15742,
                "DRB1": 20322
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "5e8b3048-491d-33db-914f-6754bda39148",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}, {
    "id": "30f040c4-89b4-3efc-8e30-137860b56220",
    "runId": 192,
    "name": "52522",
    sequencingRunDate: 1580917352789,
    "createdAt": 1580917352789,
    "status": "AWAITING_REVIEW",
    "configuredPipelineName": "mercury-pipeline-1.1-1",
    "configuredPipelineImgtVersion": "3.39.0",
    configuredPipelineGenotypingResolution: "TWO_FIELD",
    isControlSample: false,
    "result": {
        "quality": "green",
        "problems": null,
        "genotypes": {
            "A": "24:02+31:01",
            "B": "07:02+38:01",
            "C": "07:02+12:03",
            "DQB1": "03:02+05:02",
            "DRB1": "04:02+16:01"
        },
        "resolution": "WELL_TYPED",
        "sampleName": "52522_S192",
        annotations: null,
        "qualityMetrics": {
            "insertSize": 440,
            "contamination": false,
            "meanReadsQuality": 35.178103296544535,
            "readsDistribution": {
                "targetLoci": 59748,
                "relatedLoci": 6052,
                "qualityTrimmed": 23172,
                "offTargetRegions": 20490
            },
            "targetLociCoverage": {
                "A": 15008,
                "B": 6922,
                "C": 12496,
                "DQB1": 12452,
                "DRB1": 12870
            }
        }
    },
    maybeResolutionDraft: null,
    maybeSupportRequest: null,
    "sequencingRunUuid": "1f84f916-97a7-3816-8e4a-2ae1522b9f28",
    "sequencingRunId": "CPTKH",
    "sampleUuid": "f287dd88-b825-3676-9eb8-b3ba373d8199",
    "reagentKitLotName": "6666-6",
    "reagentKitLotQcStatus": "PASSED",
    "priorStates": []
}]
