import Vue from "vue"

Vue.filter("squash", (value: number) => {
    if (!value) return ""
    if (value / 1000000 > 1) {
        return `${Math.round(value / 1000000).toString()}m`
    }
    if (value / 1000 > 1) {
        return `${Math.round(value / 1000).toString()}k`
    }
    return value
})
