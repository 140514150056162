<template>
    <div class="error-backdrop">
        <transition name="slides" appear>
            <div class="error-message">
                <div class="circle-icon">
                    <p-icon icon-name="error-outline" size="4.5em"
                        style="color: var(--red); flex-shrink: 0"/>
                </div>

                <div class="flex" style="flex-flow: column">
                    <div style="color: var(--grey-700);" class="overflow-auto custom-scroll mt-1 mh-3">
                        <slot></slot>
                    </div>
                    <button class="md-btn-text ml-auto mt-1"
                            @click="$emit('close')"
                            style="font-size: 1.2rem; color: royalblue; margin-bottom: -1.5rem; margin-right: -1.5rem;">{{
                        $t("buttons.close") }}
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "ErrorMessage",
    }
</script>

<style scoped>

    .error-backdrop {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        z-index: var(--modal-backdrop-layer);
        transition: all .3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @supports (-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px)) {

        .error-backdrop {
            -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
            background-color: rgba(0, 0, 0, .1);
        }
    }


    .error-message {
        font-size: 1.6rem;
        position: relative;
        margin-top: 5rem;
        padding: 2rem;
        border-radius: 4px;
        min-width: 30rem;
        max-width: 90rem;
        background-color: white;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 4px 5px rgba(0, 0, 0, 0.2);
        max-height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .slides-enter {
        opacity: 0;
        top: 0;
    }

    .slides-enter-active {
        transition: all .2s;
    }

    .slides-enter-to {
        opacity: 1;
    }

    .details-btn {
        transition: background-color .2s;
    }

    .details-btn:hover {
        background-color: #e0e0e0;
    }

    .circle-icon {
        border-radius: 50%;
        margin-top: -6rem;
        background-color: white;
    }
</style>
