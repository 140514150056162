import { EventBus } from "@/event-bus"

export type ValidationFormEventType = "update"
export type Validator<Model, Error = string> = (modelUpdate: Partial<Model>) => Promise<Error[]> | undefined
export type StandaloneValidator<Value, Error = string> = (newValue: Value) => Promise<Error[]>
export type Pending = "pending"


interface GenericValidatableInput<Value> {
    errors: string[] | Pending
    value: Value | null
    isValidSync: "pending" | boolean

    isValid(): Promise<boolean>
}

// TODO: [@aslepchenkov 09.02.2021] Should become FormInput, maybe some Form class should be introduced
export class ValidatableInput<Value, Model> implements GenericValidatableInput<Value> {
    errors: string[] | Pending = []
    private validationPromise: Promise<string[]> = Promise.resolve([])

    private _value: Value | null = null

    constructor(
        private name: keyof Model,
        private eventBus: EventBus<ValidationFormEventType>,
        validate: Validator<Model> = (_) => Promise.resolve([]),
        initialValue?: Value | undefined,
    ) {
        this.eventBus.subscribe<Model>("update", async (modelUpdate) => {
            // If validation function returns undefined then validation status hasn't been changed for this input
            const maybeValidationPromise = validate(modelUpdate)
            if (maybeValidationPromise !== undefined) {
                // Cannot be set directly, cause absence of validation will erase real previous validation
                this.validationPromise = maybeValidationPromise
                this.errors = "pending"
                this.errors = await this.validationPromise
            }
        })
        // TODO: [@aslepchenkov 19.06.2019] This can result in erroneous validation for dependent fields?
        //  Some of them might be not subscribed yet
        this.value = initialValue ?? null
    }

    get value() {
        return this._value
    }

    set value(value) {
        this.eventBus.emit({ type: "update", payload: { [this.name]: value } })
        this._value = value
    }

    get isValidSync(): "pending" | boolean {
        return this.errors === "pending" ? "pending" : this.errors.length === 0
    }

    async isValid(): Promise<boolean> {
        return (await this.validationPromise).length === 0
    }
}

export class StandaloneValidatableInput<Value> implements GenericValidatableInput<Value> {
    errors: string[] | Pending = []
    private validationPromise: Promise<string[]> = Promise.resolve([])
    // @ts-ignore It's actually set in constructor via setter.
    private _value: Value

    constructor(
        private validate: StandaloneValidator<Value> = (_) => Promise.resolve([]),
        initialValue: Value
    ) {
        this.value = initialValue
    }

    get value() {
        return this._value
    }

    set value(value) {
        this._value = value
        this.runValidation(value)
    }

    get isValidSync(): "pending" | boolean {
        return this.errors === "pending" ? "pending" : this.errors.length === 0
    }

    async isValid(): Promise<boolean> {
        return (await this.validationPromise).length === 0
    }

    private async runValidation(value: Value) {
        const maybeValidationPromise = this.validate(value)
        if (maybeValidationPromise !== undefined) {
            // Cannot be set directly, cause absence of validation will erase real previous validation
            this.validationPromise = maybeValidationPromise
            this.errors = "pending"
            this.errors = await this.validationPromise
        }
    }
}
