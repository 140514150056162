import { Locus } from "@/genotype"

export type Maybe<T> = T | null

export type User = Readonly<{
    authorities: ReadonlyArray<Role>
    fullName: string
    language: string
    organizationName: string
    organizationOfficialName: string
    name: string
    licenseAgreement: {
        serialNumber: number,
        acceptedAt: Date
    },
    timeZone: string
    maybeEmail: string | null
    subscriptions: Record<string, boolean>
}>

export type Role = "ADMIN" | "SUPERVISOR" | "SUPPORT" | "TECHNICIAN"

export type IllegalUser = "illegal"
export const IllegalUser: IllegalUser = "illegal"

export type Eula = {
    serialNumber: number
    text: string
}

export type LotWithConfiguredPipeline = {
    id : string
    name : string
    expirationDate : number
    properties : Record<string, any>
    configuredPipeline : {
        name: string
        isArchived: boolean
        imgtVersion: string
        id: string
    }
}

export type LegalDocumentation = [name: string, link: string][]

export type NonEmptyArray<A> = [A, ...A[]]

export type SupportRequestData = {
    comment: String
    loci: NonEmptyArray<Locus>
}

export type AnalysisSupportRequestData = {
    analysisId: String
} & SupportRequestData

export type SupportRequestFormState = {
    loci: Locus[]
    comment: String
}

export function isValidSupportRequestData(
    supportRequestFormState: SupportRequestFormState
): supportRequestFormState is SupportRequestData {
    return (supportRequestFormState.loci.length > 0) && (supportRequestFormState.comment.length > 0) && (supportRequestFormState.comment.length <= 200)
}
