<template>
    <div class="overflow-auto custom-scroll">
        <table v-show="!isLoading" style="text-align: center; width: 100%"
               class="table--striped"
        >
            <thead class="genotypes-table--header">
            <tr class="genotypes-table--header-row">
                <th class="genotypes-table--header-cell">
                    <div class="genotypes-table--header-cell-content">{{ $t("locus") }}</div>
                </th>
                <th class="genotypes-table--header-cell">
                    <div class="genotypes-table--header-cell-content">{{ $t("oldGenotype") }}</div>
                </th>
                <th v-if="reAnalysisGenotypes" class="genotypes-table--header-cell">
                    <div class="genotypes-table--header-cell-content">{{ $t("reAnalysisGenotype") }}</div>
                </th>
                <th class="genotypes-table--header-cell">
                    <div v-if="!isСontamination" class="genotypes-table--header-cell-content">{{ $t("newGenotype") }}</div>
                    <div v-else class="genotypes-table--header-cell-content">
                        {{ $t("newGenotype") }}
                        <p-with-tooltip
                            fixed
                            class="allele"
                            style="justify-self: end">
                            <div class="badge"
                                style="font-size: .5em; margin-left: .2rem; color: white; backgroundColor: var(--red);">
                                С
                            </div>
                            <template #tooltip>
                                <div class="tooltip-black">
                                <div class="badge-margin" style="font-size: 0.8em; display: flex;">
                                    {{ $t("сontamination") }}
                                </div>
                                </div>
                            </template>
                        </p-with-tooltip>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="locus in LOCI">
                <tr :key="locus" :style="{ 'background-color': hasWarningColorLocus(locus) ? '#ffca2824' : '' }">
                    <td>{{ locus }}</td>
                    <td>
                        <GenotypeComponent
                                :annotations="annotations"
                                :genotype="oldGenotypes[locus]"
                                :locus-name="locus"
                                :analysis="analysis"
                        />
                    </td>

                    <template v-if="hasReAnalysisGenotypes && reAnalysisGenotypes">
                        <td v-if="reAnalysisGenotypes[locus]" class="monospace" :class="{'bold': hasWarningColorLocus(locus) }">
                        <GenotypeComponent :genotype="reAnalysisGenotypes[locus]"
                                           :annotations="annotations"
                                           :locus-name="locus"
                                           :analysis="analysis"/>
                        </td>
                        <td v-else>
                            <i style="color: var(--grey-500)">{{ $t("noChangesWereMade") }}</i>
                        </td>
                    </template>

                    <td class="monospace bold"
                        v-if="newGenotypes[locus]">
                        <GenotypeComponent :genotype="newGenotypes[locus]"
                                           :annotations="annotations"
                                           :locus-name="locus"
                                           :analysis="analysis"/>
                    </td>
                    <td v-else>
                        <i style="color: var(--grey-500)">{{ $t("noChangesWereMade") }}</i>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
        <div v-show="isLoading" style="width: 30rem; height: 20rem;">
            <p-spinner class="abs-center"/>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import GenotypeComponent from "@/components/genotype/genotype"
    import { Locales } from "@/i18n/main"
    import { supportGetterNames } from "@/store/modules/support"
    import { LOCI } from "@/genotype"
    import { mapValues } from "@/extensions/object-extensions"
    import { parseRawGenotypes } from "@/utils/analysis"
    import { supportEndpoints } from "@/endpoints"

    export default {
        name: "GenotypesComparisonTable",
        components: {
            GenotypeComponent
        },
        props: {
            oldGenotypes: Object,
            newGenotypes: Object,
            hasReAnalysisGenotypes: Boolean,
            annotations: Object,
            analysis: Object
        },
        data() {
            return {
                LOCI,
                rawReAnalysisGenotypes: {},
                isLoading: false
            }
        },
        computed: {
            isСontamination() {
                return this.analysis.maybeResolutionDraft?.resolution === "contaminated" ? true : false
            },
            reAnalysisGenotypes() {
                if(!this.rawReAnalysisGenotypes) {
                    return null
                }

                return mapValues(
                    parseRawGenotypes(this.rawReAnalysisGenotypes),
                    // eslint-disable-next-line no-unused-vars
                    (genotype, locus) => genotype
                )

            },
            ...mapGetters({
                run: supportGetterNames.activeRun,
            }),
        },
        methods: {
            genotypeParseToString(genotype) {
                if(!genotype) {
                    return null
                }
                return genotype.genotypeParts.map(genotypeParts => {
                    return genotypeParts.map(genotypePart => {
                        return genotypePart.parts.join("_")
                    }).join("_")
                }).join("_")
            },
            hasWarningColorLocus(locus) {
                if(this.reAnalysisGenotypes) {
                    const reAnalysisGenotypesParse = this.genotypeParseToString(this.reAnalysisGenotypes[locus])
                    if(this.newGenotypes[locus]) {
                        const savedGenotypesParse = this.genotypeParseToString(this.newGenotypes[locus])
                        return savedGenotypesParse !== reAnalysisGenotypesParse ? this.reAnalysisGenotypes[locus] : null
                    } else if(this.oldGenotypes[locus]) {
                        const oldGenotypesParse = this.genotypeParseToString(this.oldGenotypes[locus])
                        return oldGenotypesParse !== reAnalysisGenotypesParse ? this.reAnalysisGenotypes[locus] : null
                    }
                }
                return null
            }
        },
        async created() {
            this.isLoading = true
            try {
                const reAnalysisData = await supportEndpoints.getReAnalysisGenotype(this.run.id, this.analysis.id)
                this.rawReAnalysisGenotypes = reAnalysisData.genotypes
            } catch (error) {
                this.rawReAnalysisGenotypes = null
                console.warn(error)
            } finally {
                this.isLoading = false
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    noChangesWereMade: "Изменения отсутствуют",
                    сontamination: "СМЕСЬ"
                },
                [Locales.EN]: {
                    noChangesWereMade: "Now changes were made",
                    сontamination: "Contamination"
                }
            }
        }
    }
</script>

<style scoped>

.tooltip-black {
    font-size: .9em;
    background-color: hsl(0, 0%, 30%);
    color: white;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: .5em .8em;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
}

</style>
