<template>
    <button
        @click="$emit('toggle')"
        class="md-select__btn"
        type="button"
        :disabled="disabled"
        :class="{ invalid }"
    >
        <label
            :class="{ 'filled': !valueIsEmpty }"
            class="md-label ellipsis select-label"
        >{{ label }}</label>
        <span class="md-value selected-option ellipsis">
            <slot></slot>
        </span>
        <span class="flex ml-auto">
            <p-icon
                @click.native.stop="$emit('clear')"
                class="select__clear-selection-btn flex-fixed-icon mr-1"
                icon-name="cross"
                size=".6em"
                v-if="!disabled"
                v-show="!valueIsEmpty"
            />
            <p-icon
                class="md-select-arrow-down"
                :icon-name="arrowIconName"
                size="1em"
            />
        </span>
    </button>
</template>

<script>
    export default {
        name: "select-button",
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            invalid: {
                type: Boolean,
                default: false
            },
            valueIsEmpty: {
                type: Boolean,
                default: false
            },
            view: {
                type: Function,
                required: false
            },
            label: String,
            dropdownDirection: {
                type: String,
                default: "bottom"
            }
        },
        computed: {
            arrowIconName() {
                switch (this.dropdownDirection) {
                    case "bottom":
                        return "arrow_drop_down"
                    case "up":
                        return "arrow_drop_up"
                    default:
                        return "arrow_drop_down"
                }
            }
        }
    }
</script>

<style scoped>
.select-label {
    /* Subtract arrow icon size and borders */
    max-width: calc(100% - 2rem - 1em * 2);
}

.selected-option {
    color: rgba(0, 0, 0, .87);
    /* Otherwise text is not aligned with label, input element has this value by default*/
    text-align: start;
}
</style>
