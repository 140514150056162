import ld from "lodash"

export function removeEmptyFields(object: object): object {
    return Object.fromEntries(
        Object
            .entries(object)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([ _, v ]) =>
                (typeof v === "object")
                    // Eliminates null, {} and []
                    ? !ld.isEmpty(v)
                    // Eliminates undefined, NaN and empty string
                    : ((v === 0) || (v === false) || Boolean(v)))
    )
}

/**
 * Returns "properly" typed list of keys
 *
 * See https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 for discussion
 * main reason for loose typing - dynamic addition of keys, to hell with this. I use types and
 * I know what fields will be in objects.
 *
 */
export function keys<O>(o: O) {
    return Object.keys(o) as (keyof O)[]
}

/**
 * Object.values function already has decent signature,
 * but it decides that for partial value can be undefined, however that's not true.
 * Key will not be present at all -> now value.
 * This is true at least in my usage of `Partial`s
 *
 * Though this is actually legal
 * ```
 * interface A {
 *     a: string,
 *     b: string
 * }
 *
 * const b: Partial<A> = {
 *     a: undefined
 * }
 * ```
 *
 *  I don't use `Partial`s this way, so I will use this function on my own risk.
 */
export function valuesOfPartial<T>(o: Partial<{ [key: string]: T }>): T[] {
    return Object.values(o) as T[]
}

/**
 * "Properly" typed version of Object.entries
 *
 * See `keys` and `valuesOfPartial` for more details. Looks tremendously awful, but does what I want
 * @param o
 */
export function entriesOfPartial<O, V>(o: O & Partial<{ [key: string]: V }>) {
    return Object.entries(o) as [keyof O, V][]
}


/**
 * Set new values to keys with falsy values
 *
 * Actually it was designed to create names for stores' getters/actions etc.
 * I use objects to represent actions names, cause they reduce mistyping better than IDEA
 * inspections, alas. Values in these objects should be unique, so I duplicate keys as values with
 * store name prefix,
 * but I'm too lazy to do this every time, moreover I need to change both key and value
 * every time I change action name. So I created helper function, generalized it, and now I'm writing
 * this long comment to justify it's existence.
 *
 * @param obj that will be modified
 * @param valueFactory function to create value from key
 */
export function fillFalsyValues(
    valueFactory: (key: string) => any,
    obj: { [key: string]: any }
) {
    Object.keys(obj).forEach(key => {
        obj[key] = obj[key] ? obj[key] : valueFactory(key)
    })
}

/**
 * Return new object with values mapped using given function.
 * Used for better type inference and semantics. We can be sure that keys remain the same.
 *
 * @param o object to transform
 * @param valueMapper map function
 */
export function mapValues<K extends keyof any, V, NewV>(
    o: Record<K, V>,
    valueMapper: (v: V, k?: K) => NewV
): Record<K, NewV> {
    return Object.fromEntries(Object.entries<V>(o).map(([ key, value ]) => [ key, valueMapper(value, key as K) ])) as Record<K, NewV>
}

/**
 * Return new object with keys mapped using given function.
 * Used for better type inference and semantics. We can be sure that values remain the same.
 *
 * @param o object to transform
 * @param keyMapper map function
 */
export function mapKeys<K extends keyof any, V, NewK extends keyof any>(o: Record<K, V>, keyMapper: (key: K) => NewK): Record<NewK, V> {
    return Object.fromEntries(Object.entries<V>(o).map(([ key, value ]) => [ keyMapper(key as K), value ])) as Record<NewK, V>
}

export function mapValuesOfPartial<K extends keyof any, V, NewV>(o: Partial<Record<K, V>>, f: (v: V, k?: K) => NewV): Record<K, NewV> {
    return Object.fromEntries(
        entriesOfPartial<Partial<Record<K, V>>, V | undefined>(o)
            .map(([ key, value ]) => [ key, value ? f(value, key) : value ])
    ) as Record<K, NewV>
}

export function mapKeysAndValuesOfPartial<K extends keyof any, NewK extends keyof any, V, NewV>(
    o: Partial<Record<K, V>>,
    mapKey: (v: K) => NewK,
    mapValue: (v: V) => NewV
): Record<NewK, NewV> {
    return Object.fromEntries(
        entriesOfPartial<Partial<Record<K, V>>, V>(o)
            .map(([ key, value ]) =>
                // TODO: [@aslepchenkov 03.02.2021] Should we really leave falsy values untouched? Should check usages.
                [ mapKey(key), value ? mapValue(value) : value ])
    ) as Record<NewK, NewV>
}
