import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { AppState } from "@/store/store"
import { fillFalsyValues } from "@/extensions/object-extensions"
import { lotManagmentEndpoints } from "@/endpoints"

type LotManagement = {
    pipelines: any[],
    lots: any[]
}

function initialState(): LotManagement {
    return {
        pipelines: [],
        lots: []
    }
}

function sortByPipelineToString(arr: { id: string }[], target: string) {
    arr.sort((a, b) => {
        if (a.id === target) {
            return -1
        } else if (b.id === target) {
            return 1
        } else {
            return 0
        }
    })
}

function sortByPipelineToPipelineVersions(arr: { name: string }[]) {
    function extractVersion(str:string) {
        // eslint-disable-next-line unicorn/no-unsafe-regex
        const matches = str.match(/(\d+(?:\.\d+){1,2})/)
        return matches ? matches[1] : ""
    }

    arr.sort((a, b) => {
        const versionA = extractVersion(a.name)
        const versionB = extractVersion(b.name)

        const partsA = versionA.split(".").map(Number)
        const partsB = versionB.split(".").map(Number)

        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
            if (partsA[i] < partsB[i]) {
                return -1
            } else if (partsA[i] > partsB[i]) {
                return 1
            }
        }

        return 0 // Версии равны
    }).reverse()
}

const mutations: MutationTree<LotManagement> = {
    setPipelines(state, pipelines) {
        state.pipelines = pipelines
    },
    setLots(state, lots) {
        state.lots = lots
    },
    addLot(state, lot) {
        state.lots = [ lot, ...state.lots ]
    },
    sortPipelines(state, str) {
        sortByPipelineToPipelineVersions(state.pipelines)
        sortByPipelineToString(state.pipelines, str)
    }
}

export const lotManagementActionNames = {
    fetchAllLots: "",
    fetchAllPipelines: "",
    synchronizePipelines: "",
    saveLotModifications: "",
    createLot: ""
}

export const lotManagementGetterNames = {
    lots: "",
    pipelines: ""
}

const fillNameValues = (o: {}) => fillFalsyValues(key => `lotManagement/${key}`, o)
fillNameValues(lotManagementActionNames)
fillNameValues(lotManagementGetterNames)

const actions: ActionTree<LotManagement, AppState> = {
    async [lotManagementActionNames.fetchAllLots]({ commit }) {
        const lots = await lotManagmentEndpoints.fetchAllLots()
        commit("setLots", lots)
        return lots
    },
    async [lotManagementActionNames.fetchAllPipelines]({ commit }) {
        const pipelines = await lotManagmentEndpoints.fetchAllPipelines()
        commit("setPipelines", pipelines)
        return pipelines
    },
    async [lotManagementActionNames.synchronizePipelines]({ dispatch }) {
        await lotManagmentEndpoints.synchronize()
        return dispatch(lotManagementActionNames.fetchAllPipelines)
    },
    async [lotManagementActionNames.saveLotModifications]({ dispatch }, modifiedLot: { id: string, configuredPipeline: { id: string }}) {
        await lotManagmentEndpoints.saveNewPipelineToLot(modifiedLot.id, modifiedLot.configuredPipeline.id)
        return await dispatch(lotManagementActionNames.fetchAllLots)
    },
    async [lotManagementActionNames.createLot]({ commit }, newLot: { name: string, configuredPipeline: { id: string }, expirationDate: Date, designVersionName: string }) {
        const createdLot = await lotManagmentEndpoints.createLot(
            newLot.name,
            newLot.configuredPipeline.id,
            newLot.expirationDate.getTime(),
            newLot.designVersionName,
        )
        return commit("addLot", createdLot)
    }
}

const getters: GetterTree<LotManagement, AppState> = {
    [lotManagementGetterNames.lots]: (state) => state.lots,
    [lotManagementGetterNames.pipelines]: (state) => state.pipelines
}

export const lotManagement: Module<LotManagement, any> = {
    state: initialState(),
    mutations,
    actions,
    getters,
}
