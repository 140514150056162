<template>
    <div class="h-100">
        <div v-for="task in tasks"
             :key="task.id"
             class="run-progress-container"
        >
            <div class="progressbar run-progressbar">
                <div class="progressbar--filler"
                     :style="{ width: `${task.progress}%` }">
                </div>
                <span class="progressbar--content">{{ task.description }}</span>
            </div>
            <!-- This transition is fucked when multiple tasks are updated
                 at the same moment, i.e. progress is updated and position is changed -->
            <transition-group
                name="list"
                tag="ul"
                class="samples-progress-container overflow-auto custom-scroll"
            >
                <li v-for="subtask in task.subTasks"
                    :key="subtask.id"
                    class="progressbar sample-progressbar mv-1"
                >
                    <div class="progressbar--filler"
                         :style="{ width: `${subtask.progress}%` }">
                    </div>
                    <div class="progressbar--content flex"
                         style="justify-content: space-between">
                        <div class="mr-1">{{ subtask.description }}</div>
                        <div
                            v-show="subtask.progress !== 100"
                        >{{ remainingTime(subtask.remainingTime) }}
                        </div>
                    </div>
                </li>
            </transition-group>
        </div>
    </div>
</template>

<script>
    import { remainingTime } from "@/filters/remaining-time"
    import { Locales } from "@/i18n/main"
    import { backgroundTasksActionNames, orderedTasksByProgress } from "@/store/modules/background-tasks"

    export default {
        name: "upload-progress",
        props: {
            tasks: Array,
        },
        methods: {
            remainingTime(timeInMillis) {
                return remainingTime(this.$i18n, timeInMillis)
            }
        },
        data() {
            return {
                reorderInterval: null
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    min: "мин",
                    h: "ч"
                },
                [Locales.EN]: {
                    min: "min",
                    h: "h"
                }
            }
        },
        mounted() {
            this.reorderInterval = setInterval(
                () => this.$store.dispatch(backgroundTasksActionNames.reorderTasks, orderedTasksByProgress),
                1000
            )
        },
        destroyed() {
            clearInterval(this.reorderInterval)
        }
    }
</script>

<style scoped>
.progressbar {
    position: relative;
    border: 1px solid var(--grey-300);
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.run-progress-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.samples-progress-container {
    padding: 1rem 3rem;
    margin: 1rem 0;
}

.sample-progressbar {
    padding: .7rem 1.4rem;
    --progreesbar-color: var(--green);
}

.run-progressbar {
    padding: 1.4rem 2rem;
    font-size: 1.6rem;
    --progreesbar-color: var(--green);
}

.progressbar--filler {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--progreesbar-color);
    transition: width ease .5s;
}

.progressbar--content {
    position: relative;
    z-index: var(--first-layer);
}

.list-move {
    transition: all .5s;
}
</style>
