<template>
    <div class="modal-backdrop">
        <div class="modal-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PModal"
    }
</script>
