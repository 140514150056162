import { Page } from "@/store/modules/pageable-store"
import { Run, SequencingRunFilter } from "@/store/modules/runs"

type Response<R> = {
    number: number,
    resources: Array<R>,
    size: number,
    totalResources: number
}

export function mockPageableResource<R, F>(page: Page, filter: F, resources: Array<R>): Response<R> {

    const resourcesChunk = resources.slice(page.pageNumber * page.pageSize, (page.pageNumber + 1) * page.pageSize)
    return {
        number: page.pageNumber,
        resources: resourcesChunk,
        size: page.pageSize,
        totalResources: resources.length
    }
}

export function mockSequencingRuns(page: Page, filter: SequencingRunFilter, resources: Array<Run>): Response<Run> {
    const filteredResources = resources
        .filter(it => filter.withExportStatus ? it.exportStatus === filter.withExportStatus : true)
        .filter(it => filter.name
            ? (<string>filter.name).startsWith("equals")
                ? (<string>filter.name).split(":")[1] === it.name
                // TODO: [@aslepchenkov 28.04.2020]
                : true
            : true)
    const resourcesChunk = filteredResources.slice(page.pageNumber * page.pageSize, (page.pageNumber + 1) * page.pageSize)
    return {
        number: page.pageNumber,
        resources: resourcesChunk,
        size: page.pageSize,
        totalResources: filteredResources.length
    }
}
