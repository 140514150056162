<template>
    <div class="analysis-review-container"
         :class="{ 'single-run': runs.length === 1 }"
         tabindex="-1">
        <p-modal v-if="modals['support'].opened">
            <div class="to-support-modal">
                <div style="padding: 1rem">
                    <div>{{ $t("toSupportModal.selectProblemLoci") }}:</div>
                    <button class="flex"
                            style="padding: .2rem .6rem"
                            @click="toSupportForm.problemLoci[locus] = !toSupportForm.problemLoci[locus]"
                            :key="locus" v-for="(_, locus) in toSupportForm.problemLoci">
                        <p-icon :icon-name="toSupportForm.problemLoci[locus] ? 'checkbox-checked' : 'checkbox-unchecked'"
                                class="mr-1"/>
                        <span>{{ locus }}</span>
                    </button>
                </div>
                <p-text-area
                    class="md-outlined"
                    :placeholder="$t('toSupportModal.comments')"
                    style="height: 30rem; width: 30rem; margin: 1rem"
                    v-model="toSupportForm.comments"
                />
                <div class="to-support-modal--actions">
                    <button @click="onSendToSupportBtnClick"
                            :disabled="!toSupportFormIsValid || notReadyForNextAction"
                            class="md-btn-contained royal-blue">{{ $t("buttons.send") }}
                    </button>
                    <button @click="closeModal('support')"
                            class="md-btn-text" style="color: royalblue">{{ $t("buttons.cancel") }}
                    </button>
                </div>
            </div>
        </p-modal>
        <p-spinner v-if="loading"
                   class="abs-center h4"/>
        <template v-else>
            <template v-if="runs.length">
                <div class="flex" style="justify-content: center; position: relative">
                    <button @click="openModal('support')"
                            id="to-support-btn"
                            :disabled="notReadyForNextAction"
                            class="to-support-button md-btn-contained yellow">{{ $t("buttons.toSupport")
                        }}&nbsp;<span
                                style="transform: translateY(-10%)">&uarr;</span>
                    </button>
                    <button @click="onApproveBtnClick"
                            id="approve-btn"
                            :disabled="notReadyForNextAction"
                            class="green approve-button md-btn-contained">
                        <!-- Dirty hack due to very DRY approach -->
                        <slot name="approve-btn-text"></slot>
                    </button>
                    <p-icon-button v-if="hasPreviousAnalysis"
                                @click="() => previousAnalysis()"
                                class="previous-analysis-btn"
                                size="10em"
                                icon-name="arrow_left"/>
                    <transition :name="transition" mode="out-in"
                                @before-leave="beforeLeave"
                                @after-enter="afterEnter"
                    >
                        <slot v-if="analyses.length !== 0"
                              :analysis="activeAnalysis"
                              :removeKeydownListener="removeKeydownListener"
                              :setKeydownListener="setKeydownListener"
                        />
                    </transition>
                    <p-icon-button v-if="hasNextAnalysis"
                                @click="() => nextAnalysis()"
                                size="10em"
                                class="next-analysis-btn"
                                icon-name="arrow_right"/>
                </div>
                <ul class="runs overflow-auto custom-scroll"
                    id="run-with-analyses-to-review-list"
                    v-if="runs.length > 1">
                    <li :class="{ active: activeRun.id === run.id ? 'active' : ''}"
                        class="run"
                        v-for="run in runs"
                        @click="jumpToRun(run.id)"
                        :key="run.id"
                    >
                        <span class="ellipsis" style="font-weight: 600"> {{ run.name }}</span>
                        <span>{{ run.count }}</span>
                    </li>
                </ul>
            </template>
            <span class="fallback-text h1"
                  style="grid-column: 1 /-1; align-self: center; justify-self: center;"
                  v-else>
                <slot name="fallback-text"></slot>
            </span>
        </template>
    </div>
</template>

<script>
    import { modalMixin } from "@/mixins/modal-mixin"

    const TO_SUPPORT_MODAL = "support"

    export default {
        name: "GenericAnalysesReview",
        mixins: [ modalMixin ],
        async created() {
            // FIXME: [@aslepchenkov 13.04.2020] Order is of great importance here.
            //  DO NOT TOUCH!!!
            const runs = await this.fetchRuns()
            if (runs.length) {
                this.analyses = await this.fetchAnalyses(runs[0].id)
                if (this.analyses.length === 0) {
                    console.error("Unexpected backend result. Runs for review/approve must contain analyses in corresponding status")
                }
                this.runs = runs
                this.fullyLoadedRunIds = [ this.runs[0].id ]
            }
            this.loading = false
        },
        props: {
            fetchRuns: {
                required: true,
                type: Function
            },
            fetchAnalyses: {
                required: true,
                type: Function
            },
            approve: {
                required: true,
                type: Function
            },
            sendToSupport: {
                required: true,
                type: Function
            }
        },
        data() {
            return {
                activeAnalysisIndex: 0,
                analyses: [],
                transition: "next",
                toSupportForm: emptyToSupportForm(),
                runs: [],
                modals: {
                    [TO_SUPPORT_MODAL]: {
                        opened: false,
                        onOpen: () => this.removeKeydownListener(),
                        onClose: () => {
                            this.toSupportForm = emptyToSupportForm()
                            this.setKeydownListener()
                        }
                    }
                },
                fetchNewAnalysesThenUpdateCurrentAnalysesList: Promise.resolve(),
                analysesFetchInProgress: false,
                hasActiveApproveOrToSupportRequest: false,
                transitionInProgress: false,
                fullyLoadedRunIds: [], // VH-327 Fix
                loading: true
            }
        },
        computed: {
            hasNextAnalysis() {
                return this.hasNextRun
                    || this.activeAnalysisIndex < this.analyses.length - 1
            },
            hasPreviousAnalysis() {
                return this.hasPreviousRun
                    || this.activeAnalysisIndex > 0
            },
            activeAnalysis() {
                return this.analyses[this.activeAnalysisIndex]
            },
            hasNextRun() {
                return this.runs.length && this.currentRunIndex < this.runs.length - 1
            },
            nextRun() {
                return this.hasNextRun ? this.runs[this.currentRunIndex + 1] : null
            },
            prevRun() {
                return this.hasPreviousRun ? this.runs[this.currentRunIndex - 1] : null
            },
            currentRunIndex() {
                return this.runs.findIndex(run => run.id === this.activeAnalysis.sequencingRunUuid)
            },
            hasPreviousRun() {
                return this.runs.length && this.currentRunIndex > 0
            },
            activeRun() {
                return this.currentRunIndex !== -1
                    ? this.runs[this.currentRunIndex]
                    : null
            },
            shouldFetchNextAnalyses() {
                return this.analyses.length - this.activeAnalysisIndex < 20
                    && this.hasNextRun
                    && !this.fullyLoadedRunIds.includes(this.nextRun.id) // VH-327 Fix
                    && !this.analysesFetchInProgress
            },
            shouldFetchPreviousAnalyses() {
                return this.activeAnalysisIndex < 20
                    && this.hasPreviousRun
                    && !this.fullyLoadedRunIds.includes(this.prevRun.id) // VH-327 Fix
                    && !this.analysesFetchInProgress
            },
            notReadyForNextAction() {
                return this.hasActiveApproveOrToSupportRequest || this.transitionInProgress
            },
            toSupportFormIsValid() {
                return Object.values(this.toSupportForm.problemLoci).some(it => it) && this.toSupportForm.comments && this.toSupportForm.comments.length <= 200
            }
        },
        mounted() {
            this.setKeydownListener()
        },
        destroyed() {
            this.removeKeydownListener()
        },
        methods: {
            setKeydownListener() {
                window.addEventListener("keydown", this.keydownListener)
            },
            removeKeydownListener() {
                window.removeEventListener("keydown", this.keydownListener)
            },
            // This method can be called during transitions cause it only reads data
            async nextAnalysis(transition) {
                const isOnLastFetchedAnalysis = this.activeAnalysisIndex === this.analyses.length - 1

                const alreadyHasNextAnalysisWaiting = isOnLastFetchedAnalysis && this.analysesFetchInProgress
                if (alreadyHasNextAnalysisWaiting) {
                    return
                }

                if (this.shouldFetchNextAnalyses) {
                    this.fetchNextAnalyses()
                }

                if (this.hasNextAnalysis) {
                    if (isOnLastFetchedAnalysis) {
                        await this.fetchNewAnalysesThenUpdateCurrentAnalysesList
                    }
                    this.transition = transition ?? "next"
                    this.activeAnalysisIndex += 1
                }
            },
            // This method can be called during transitions cause it only reads data
            async previousAnalysis(transition) {
                const isOnFirstFetchedAnalysis = this.activeAnalysisIndex === 0

                const alreadyHasPreviousAnalysisWaiting = isOnFirstFetchedAnalysis && this.analysesFetchInProgress
                if (alreadyHasPreviousAnalysisWaiting) {
                    return
                }

                if (this.shouldFetchPreviousAnalyses) {
                    this.fetchPreviousAnalyses()
                }

                if (this.hasPreviousAnalysis) {
                    if (isOnFirstFetchedAnalysis) {
                        await this.fetchNewAnalysesThenUpdateCurrentAnalysesList
                    }
                    this.transition = transition ?? "previous"
                    this.activeAnalysisIndex -= 1
                }
            },
            // TODO: [@aslepchenkov 27.02.2020] Make something like private like aafanasyev suggested
            fetchNextAnalyses() {
                this.analysesFetchInProgress = true
                this.fetchNewAnalysesThenUpdateCurrentAnalysesList =
                    // eslint-disable-next-line promise/always-return
                    this.fetchAnalyses(this.nextRun.id).then(analyses => {
                        this.analyses = this.analyses.concat(analyses)
                        this.analysesFetchInProgress = false
                        this.fullyLoadedRunIds = [ ...this.fullyLoadedRunIds, this.nextRun.id ]
                    })
            },
            // TODO: [@aslepchenkov 27.02.2020] Make something like private like aafanasyev suggested
            fetchPreviousAnalyses() {
                this.analysesFetchInProgress = true
                this.fetchNewAnalysesThenUpdateCurrentAnalysesList =
                    this.fetchAnalyses(this.prevRun.id)
                        // eslint-disable-next-line promise/always-return
                        .then(analyses => {
                            // New analyses are added before old ones cause we are walking backward. And to stay
                            // Where we were, we should increase activeAnalysisIndex
                            this.analyses = analyses.concat(this.analyses)
                            this.activeAnalysisIndex += analyses.length
                            this.analysesFetchInProgress = false
                            this.fullyLoadedRunIds = [ ...this.fullyLoadedRunIds, this.prevRun.id ]
                        })
            },
            onSendToSupportBtnClick() {
                if (this.notReadyForNextAction) {
                    console.warn("Already has pending request to approve/toSupport endpoint or transition in progress")
                    return
                }
                this.hasActiveApproveOrToSupportRequest = true

                const problemLoci = Object.entries(this.toSupportForm.problemLoci)
                    .filter(([ , hasProblem ]) => hasProblem)
                    .map(([ locusName ]) => locusName)
                const details = {
                    loci: problemLoci,
                    comment: this.toSupportForm.comments,
                }

                const analysisToProcess = this.activeAnalysis
                this.sendToSupport(analysisToProcess, details)
                    .then(async () => {
                        const onLastFetchedAnalysis = this.activeAnalysisIndex === this.analyses.length - 1
                        // eslint-disable-next-line promise/always-return
                        const transition = (onLastFetchedAnalysis && !this.shouldFetchNextAnalyses)
                            ? "up-last"
                            : "up"
                        if (this.hasNextAnalysis) {
                            await this.nextAnalysis(transition)
                        } else if (this.hasPreviousAnalysis) {
                            await this.previousAnalysis(transition)
                        }
                        await this.removeAnalysis(analysisToProcess.id)
                        return this.closeModal(TO_SUPPORT_MODAL)
                    })
                    .finally(() => {
                        this.hasActiveApproveOrToSupportRequest = false
                    })
            },
            onApproveBtnClick() {
                if (this.notReadyForNextAction) {
                    console.warn("Already has pending request to approve/toSupport endpoint or transition in progress")
                    return
                }
                this.hasActiveApproveOrToSupportRequest = true
                const analysisToProcess = this.activeAnalysis
                // TODO: [@aslepchenkov 16.09.2019] Must catch errors
                this.approve(analysisToProcess)
                    .then(async () => {
                        const onLastFetchedAnalysis = this.activeAnalysisIndex === this.analyses.length - 1
                        // eslint-disable-next-line promise/always-return
                        const transition = (onLastFetchedAnalysis && !this.shouldFetchNextAnalyses)
                            ? "down-last"
                            : "down"
                        if (this.hasNextAnalysis) {
                            await this.nextAnalysis(transition)
                        } else if (this.hasPreviousAnalysis) {
                            await this.previousAnalysis(transition)
                        }
                        return this.removeAnalysis(analysisToProcess.id)
                    })
                    .finally(() => {
                        this.hasActiveApproveOrToSupportRequest = false
                    })
            },
            removeAnalysis(analysisId) {
                const analysisToRemoveIndex = this.analyses.findIndex(it => analysisId === it.id)
                const analysisToRemove = this.analyses[analysisToRemoveIndex]
                // Screw performance, will slice be better?
                // TODO: [@aslepchenkov 30.08.2019] This filter is totally fucked when you have 1000 analyses
                //  Come with something better
                this.analyses = this.analyses.filter(it => analysisToRemove.id !== it.id)

                if (analysisToRemoveIndex <= this.activeAnalysisIndex) {
                    this.activeAnalysisIndex -= 1
                }

                const runWithAnalysisToRemove = this.runs.find(it => it.id === analysisToRemove.sequencingRunUuid)
                runWithAnalysisToRemove.count -= 1
                if (runWithAnalysisToRemove.count === 0) {
                    this.runs = this.runs.filter(it => it.id !== runWithAnalysisToRemove.id)
                }
            },
            /*
             * Discard all analyses.
             * Load all analyses from selected run
             * Start review from first analysis
             */
            jumpToRun(runUuid) {
                this.transition = "next"
                this.fetchAnalyses(runUuid)
                    // eslint-disable-next-line promise/always-return
                    .then(analyses => {
                        this.analyses = analyses
                        this.activeAnalysisIndex = 0
                        this.fullyLoadedRunIds = [ runUuid ]
                    })
            },
            keydownListener(event) {
                event.preventDefault()
                event.stopPropagation()
                if (event.repeat) {
                    console.warn(`Stop pressing ${event.code}. Just release and press again`)
                    return
                }
                switch (event.code) {
                    case "ArrowLeft": {
                        if (this.hasPreviousAnalysis) {
                            this.previousAnalysis()
                        }
                        break
                    }
                    case "ArrowRight": {
                        if (this.hasNextAnalysis) {
                            this.nextAnalysis()
                        }
                        break
                    }
                    case "ArrowDown": {
                        this.onApproveBtnClick()
                        break
                    }
                    case "ArrowUp": {
                        this.openModal(TO_SUPPORT_MODAL)
                        break
                    }
                    default:

                }
            },
            /*
             Seems like I can ignore "cancelled" hooks, cause they called when you apply
             transitions to often, and I don't want activate buttons during this transition hell.
             After all I will always get afterEnter called.
            */
            afterEnter() {
                this.transitionInProgress = false
            },
            beforeLeave() {
                this.transitionInProgress = true
            }
        }
    }

    function emptyToSupportForm() {
        return {
            problemLoci: {
                "A": false,
                "B": false,
                "C": false,
                "DQB1": false,
                "DRB1": false
            },
            comments: ""
        }
    }
</script>

<style scoped>

    .analysis-review-container {
        display: grid;
        /*
         * Second column width is fixed, cause all fit/max/min content functions cause horizontal scroll in firefox
         * https://stackoverflow.com/questions/39738265/firefox-displays-unnecessary-horizontal-scrollbar
         */
        grid-template-columns: 1fr 23rem;
        grid-template-rows: 100%;
        justify-content: center;
        position: relative;
    }

    .analysis-review-container.single-run {
        grid-template-columns: 1fr;
    }

    .analysis-card {
        width: 65rem;
    }

    .next-analysis-btn,
    .previous-analysis-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: var(--second-layer);
    }

    .previous-analysis-btn {
        left: 2rem;
    }

    .next-analysis-btn {
        right: 2rem;
    }

    .to-support-modal {
        font-size: 2rem;
        padding: 2em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr min-content;
    }

    .to-support-modal--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column: 1 / -1;
        margin-top: 2em;
        margin-bottom: -1em;
        font-size: 1.8rem;
    }

    .runs {
        height: 60%;
        align-self: center;
        margin-right: 1rem;
    }

    .run {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        margin-right: 1rem;
        color: #bbb;
        border-radius: 4px;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .run:hover {
        background-color: rgba(65, 105, 225, .15);
    }

    .run.active {
        position: sticky;
        top: 0;
        bottom: 0;
        background-color: royalblue;
        color: white;
    }


    @media screen and (max-width: 75em) {
        .analysis-card {
            width: 50rem;
        }
    }

    .to-support-button {
        font-size: 1.6rem;
        position: absolute;
        top: 2em;
        left: 50%;
        transform: translateX(-50%);
    }

    .approve-button {
        font-size: 1.6rem;
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
    }
</style>
