import Vue from "vue"
import Router, { Route, RouteConfig } from "vue-router"
import Login from "@/views/login.vue"
import Workbench from "@/views/workbench/workbench.vue"
import AnalysesReview from "@/views/review/analyses-review.vue"
import DataUpload from "@/views/data-upload/data-upload.vue"
import Archive from "@/views/archive/archive.vue"
import Samples from "@/views/samples/samples.vue"
import { store } from "@/store/store"
import Run from "@/views/run-labqc/run.vue"
import Runs from "@/views/runs.vue"
import Docs from "@/views/docs.vue"
import Terms from "@/views/terms.vue"
import Approve from "@/views/approve/approve.vue"
import RunsDataExport from "@/views/data-export/runs-data-export.vue"
import Support from "@/views/support-runs/support.vue"
import SupportRun from "@/views/support-run/support-run.vue"
import LotManagement from "@/views/lot-management/lot-management.vue"
import DataExportDocs from "@/interactive-docs/data-export-docs.vue"
import ReviewDocs from "@/interactive-docs/review-docs.vue"
import ApproveDocs from "@/interactive-docs/approve-docs.vue"
import SamplesDocs from "@/interactive-docs/samples-docs.vue"
import DataUploadDocs from "@/interactive-docs/data-upload-docs.vue"
import RunsDocs from "@/interactive-docs/runs-docs.vue"
import RunDocs from "@/interactive-docs/run-docs.vue"

import { Role, User } from "./types"

Vue.use(Router)

type RouteName = "login" | "workbench" | "runs" | "run" | "dataUpload" | "analysesReview" | "samples"
    | "archive" | "approve" | "runsDataExport" | "support" | "supportRun" | "docs" | "lotManagement" | "terms"

/* Can't come up with a better name */
type CustomRouteConfig = {
    name?: RouteName
    meta: {
        requiresAnyOfRoles: ReadonlyArray<Role>
    }
} & RouteConfig


const defaultRoutes: Record<Role, RouteName> = {
    ADMIN: "workbench",
    SUPERVISOR: "workbench",
    SUPPORT: "support",
    TECHNICIAN: "runs"
}

export function defaultRouteFor(user: User): RouteName {
    return defaultRoutes[user.authorities[0]]
}

// ADMIN roles are set explicitly to distinguish between routes where no authority is needed and ADMIN only routes.
const routes: Array<CustomRouteConfig> = [
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter(to, from, next) {
            const stayWhereYouAre = () => next(false)
            const redirectToDefault = () => next({ name: defaultRouteFor(store.getters.user) })
            const proceed = next

            store.getters.isAuthenticated
                ? from.name ? stayWhereYouAre() : redirectToDefault()
                : proceed()
        },
        meta: { requiresAnyOfRoles: [] }
    },
    {
        path: "/workbench",
        name: "workbench",
        component: Workbench,
        meta: { requiresAnyOfRoles: [ "SUPERVISOR", "ADMIN" ] as const }
    },
    {
        path: "/runs",
        name: "runs",
        components: {
            default: Runs,
            docs: RunsDocs
        },
        meta: { requiresAnyOfRoles: [ "TECHNICIAN", "SUPERVISOR", "ADMIN" ]  as const },
    },
    {
        path: "/runs/:id",
        name: "run",
        components: {
            default: Run,
            docs: RunDocs
        },
        meta: { requiresAnyOfRoles: [ "TECHNICIAN", "SUPERVISOR", "ADMIN" ]  as const },
    },
    {
        path: "/dataUpload",
        name: "dataUpload",
        components: {
            default: DataUpload,
            docs: DataUploadDocs
        },
        meta: { requiresAnyOfRoles: [ "TECHNICIAN", "SUPERVISOR", "ADMIN" ]  as const }
    },
    {
        path: "/analysesReview",
        name: "analysesReview",
        components: {
            default: AnalysesReview,
            docs: ReviewDocs
        },
        meta: { requiresAnyOfRoles: [ "SUPERVISOR", "ADMIN" ]  as const }
    },
    {
        path: "/archive",
        name: "archive",
        components: {
            default: Archive,
        },
        meta: {
            requiresAnyOfRoles: [
                "ADMIN",
            ] as const,
        },
    },
    {
        path: "/samples",
        name: "samples",
        components: {
            default: Samples,
            docs: SamplesDocs
        },
        meta: {
            requiresAnyOfRoles: [
                "TECHNICIAN",
                "SUPERVISOR",
            ]  as const,
        }
    },
    {
        path: "/approve",
        name: "approve",
        components: {
            default: Approve,
            docs: ApproveDocs
        },
        meta: { requiresAnyOfRoles: [ "SUPERVISOR", "ADMIN" ]  as const }
    },
    {
        path: "/runsDataExport",
        name: "runsDataExport",
        components: {
            default: RunsDataExport,
            docs: DataExportDocs
        },
        meta: { requiresAnyOfRoles: [ "SUPERVISOR", "ADMIN" ]  as const }
    },
    {
        path: "/support",
        name: "support",
        component: Support,
        meta: { requiresAnyOfRoles: [ "SUPPORT", "ADMIN" ]  as const }
    },
    {
        path: "/support/:id",
        name: "supportRun",
        component: SupportRun,
        meta: { requiresAnyOfRoles: [ "SUPPORT", "ADMIN" ]  as const }
    },
    {
        path: "/lotManagement",
        name: "lotManagement",
        component: LotManagement,
        meta: { requiresAnyOfRoles: [ "SUPPORT", "ADMIN" ]  as const }
    },
    {
        path: "/docs",
        name: "docs",
        component: Docs,
        meta: { requiresAnyOfRoles: [] }
    },
    {
        path: "/terms",
        name: "terms",
        component: Terms,
        meta: { requiresAnyOfRoles: [] }
    },
    {
        path: "/**",
        redirect: {
            name: store.getters.user
                ? defaultRouteFor(store.getters.user)
                : "login"
        },
        meta: { requiresAnyOfRoles: [] }
    },
]

const router = new Router({
    routes
})

/*
 * Previously I checked for authentication beforeEach transition cause I wanted to synchronize several tabs,
 * e.g. In one tab open login route, in the other open login and login, now you have cookies with session.
 * In the first tab go to some url e.g. runs, you will not get there cause in this tab you've come from login
 * route and authentication will not be restored using cookie.
 *
 * However such approach solved only one narrow case, e.g. you would still get errors
 * when you logout in one tab and try to logout in the second tab.
 * To provide normal synchronization localStorage should
 * be used. Maybe this will be done in some bright future
 */
const restoreAuthOnStartHook = router.beforeEach(async (to, from, next) => {
    /*
     * Should be unregistered before any requests, otherwise 401 will redirect to login and this
     * hook will be called again. This will not work:
     * store.dispatch("restoreAuthenticationUsingCookies").then(() => restoreAuthOnStartHook())
     */
    restoreAuthOnStartHook()
    await store.dispatch("restoreAuthenticationUsingCookies")
    next()
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkAccessHook = router.beforeEach(async (to, from, next) => {
    if (doesntRequireAuth(to)
        || store.getters.isAuthenticated
        && hasRoleBasedAccessTo(store.getters.user, to)
    ) {
        next()
    } else {
        console.debug("Redirecting to login...")
        next({ name: "login", query: { redirect: to.fullPath } })
    }
})

export function doesntRequireAuth(route: CustomRouteConfig | Route) {
    return route.meta.requiresAnyOfRoles.length === 0
}

export function hasRoleBasedAccessTo(user: User, route: CustomRouteConfig | Route): boolean {
    return user.authorities.some(it => route.meta.requiresAnyOfRoles.includes(it))
}

/**
 * Is meant to be used from outside of the router.ts
 * to hide routes.
 * Can't find a name for a function that encapsulate
 * hasRoleBasedAccessTo and getRouteByName, though they are supposed to be used together from outside world
 */
export function getRouteByName(routeName: RouteName): RouteConfig | undefined {
    return routes.find(it => it.name === routeName)
}

export default router
