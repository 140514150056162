<template>
    <div class="samples-container">
        <div class="samples-search">
            <div class="samples-search-top-panel">
                <div class="composite-search mr-1">
                    <AnalysisStatusBadge :analysis-status="filters.status"
                                         class="ml-1"
                                         v-if="filters.status"
                    />
                    <div class="badge yellow ml-1" v-if="filters.runId">Run: {{ filters.runId }}</div>
                    <div class="badge yellow ml-1" v-if="filters.allele">{{ filters.allele }}</div>
                    <div class="badge yellow ml-1" v-if="filters.queryForAnalysesWithNewAllele">
                        {{ $t("queryForAnalysesWithNewAlleleIndicator") }}
                    </div>
                    <div class="badge yellow ml-1"
                          v-if="filters.organizationName">Org.: {{ filters.organizationName }}</div>
                    <div class="badge yellow ml-1" v-if="filters.from">From: {{ filters.from }}</div>
                    <div class="badge yellow ml-1" v-if="filters.to">To: {{ filters.to }}</div>
                    <input autocomplete="off"
                           class="composite-search--input"
                           :placeholder="$t('liveSearchPlaceholder')"
                           type="text"
                           v-model="liveSearch"
                           aria-label="sample name live search"
                    >
                    <button @click="resetFilters"
                            class="composite-search--clear"
                            v-if="filtersHaveSomeValue">
                        <p-icon icon-name="clear" size="1.5em"/>
                    </button>
                    <button @click="toggleCompositeSearchDropdown"
                            class="composite-search--arrow"
                            ref="showDropdownButton">
                        <p-icon icon-name="arrow_drop_down" size="1.5em"/>
                    </button>
                    <div @focusout="onCompositeSearchDropdownFocusout"
                         class="composite-search--dropdown"
                         ref="dropdown"
                         tabindex="-1"
                         v-if="compositeSearchDropdownShown"
                    >
                        <form style="font-size: 1.5rem;">
                            <div>
                                <p-input
                                    class="md-outlined filters--input"
                                    :placeholder="$t('sampleName')"
                                    type="text"
                                    v-model="tempFilters.sampleName"
                                >
                                </p-input>
                                <p-type-ahead-input
                                    class="md-outlined"
                                    :placeholder="$t('runId')"
                                    :options="runTypeAheadOptions"
                                    type="text"
                                    v-model="tempFilters.runId"/>
                                <div
                                    class="filters--input"
                                     style="display: flex; gap: 1.5rem;"
                                >
                                    <p-input
                                        class="md-outlined"
                                        style="flex-basis: 50%"
                                        :placeholder="$t('dateFrom')"
                                        type="date"
                                        v-model="tempFilters.from"/>

                                    <p-input
                                        class="md-outlined"
                                        style="flex-basis: 50%"
                                        :placeholder="$t('dateTo')"
                                        type="date"
                                        v-model="tempFilters.to"/>
                                </div>
                                <p-select
                                    :options="analysisStatuses"
                                    :placeholder="$t('status')"
                                    class="md-outlined filters--input"
                                    :view="v => $t(`analysisStatuses.${v}`).toUpperCase()"
                                    :get-key="v => v"
                                    v-model="tempFilters.status"
                                >
                                    <template v-slot:default="slotProps">
                                        <AnalysisStatusBadge :analysis-status="slotProps.option"/>
                                    </template>
                                </p-select>
                                <!-- TODO: [@aslepchenkov 28.02.2020] Some ergonomic improvements are done, but more are still pending -->
                                <p-input
                                    class="md-outlined filters--input"
                                    :placeholder="$t('allele')"
                                    v-model="tempFilters.allele"
                                    :validation-errors="alleleValidationErrors"
                                >
                                    <template #icon-right>
                                        <p-with-tooltip fixed position="left">
                                            <p-icon
                                                size="1.5em"
                                                icon-name="help-outline"
                                            />
                                            <template #tooltip>
                                                <div class="tooltip-black">
                                                    {{ $t("searchBy") }}
                                                    <ul class="bullet-list">
                                                        <li class="bullet-list__element">
                                                            {{ $t("alleleLocusNameWithAlleleAtFieldResolution") }}
                                                        </li>
                                                        <li class="bullet-list__element">
                                                            {{ $t("annotationLocusNameWithAnnotationLetter") }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </template>
                                        </p-with-tooltip>
                                    </template>
                                </p-input>
                                <button class="flex"
                                        style="padding: .2rem .6rem"
                                        @click="toggleAnalysesWithNewAllele"
                                >
                                    <p-icon :icon-name="tempFilters.queryForAnalysesWithNewAllele ? 'checkbox-checked' : 'checkbox-unchecked'"
                                            class="mr-1"/>
                                    <span>{{ $t("queryForAnalysesWithNewAllele") }}</span>
                                </button>
                                <div v-if="organizationNames.length < 4" class="btn-group" style="margin: 2rem">
                                    <button class="btn-group--btn"
                                            :class="{ 'btn-group--btn-active': organizationName === tempFilters.organizationName }"
                                            @click="organizationSelect(organizationName)"
                                            :key="organizationName"
                                            v-for="organizationName in organizationNames">
                                        {{ organizationName }}
                                    </button>
                                </div>
                                <p-select v-else
                                          :options="organizationNames"
                                          :placeholder="$t('runFilterPlaceholders.organization')"
                                          autocomplete="off"
                                          class="md-outlined filter"
                                          type="text"
                                          v-model="tempFilters.organizationName"
                                          style="margin: 2rem; width: auto"
                                />
                            </div>
                            <div class="flex" style="font-size: 1.5rem">
                                <button @click="resetFilters"
                                        class="md-btn-text ml-auto mr-1"
                                        style="color: royalblue">{{ $t("buttons.reset") }}
                                </button>
                                <button @click="search"
                                        :disabled="alleleValidationErrors.length !== 0"
                                        class="md-btn-contained royal-blue"
                                        ref="searchDropdownCloser">{{ $t("buttons.search") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <p-dropdown>
                    <template v-slot:activator="{ toggleDropdown }">
                        <button class="md-btn-contained royal-blue"
                                @click="toggleDropdown"
                        >
                            <p-icon
                                class="mr-1"
                                icon-name="report"
                            />
                            <span style="margin-right: .5rem">{{ $t("buttons.export") }}</span>
                            <p-icon
                                class="text-white ml-1"
                                size="1em"
                                icon-name="arrow_drop_down"
                            />
                        </button>
                    </template>
                    <ul
                        class="dropdown"
                        style="background-color: white"
                    >
                        <li :key="resolution" v-for="resolution in [2, 3]">
                            <button
                                @click="exportGenotypes(resolution)"
                                class="md-btn-text samples-export-resource__btn"
                            >
                                {{ $t("resolution", [ resolution ]) }}
                            </button>
                        </li>
                        <li>
                            <button
                                class="md-btn-text samples-export-resource__btn"
                                @click="exportGeneralStatistics"
                            >
                                {{ $t("buttons.generalStatisticsExport") }}
                            </button>
                        </li>
                        <li>
                            <button
                                class="md-btn-text samples-export-resource__btn"
                                @click="exportLocusSpecificStatistics"
                            >
                                {{ $t("buttons.locusSpecificStatisticsExport") }}
                            </button>
                        </li>
                    </ul>
                </p-dropdown>
            </div>
            <div style="margin: .4em; margin-top: 1rem" v-if="samplesNumber">
                <span style="font-size: .9em">{{ $tc("foundSamples", samplesNumber) }}</span>
            </div>
        </div>

        <div @scroll="throttledOnScroll"
             class="samples-list custom-scroll"
             ref="infiniteScrollContainer"
             v-if="samplesNumber !== 0">
            <div @click="shownAnalysis = analysis"
                 class="samples-list--sample"
                 :class="{ selected: shownAnalysis && analysis.id === shownAnalysis.id }"
                 :key="analysis.id"
                 v-for="analysis in samples"
            >
                <div style="font-weight: 600; font-size: 1.2em; grid-column: 1 / 5; word-break: break-all">{{ analysis.name }}</div>
                <div style="grid-column: 5 / 9; text-align: center">
                    <AnalysisStatusBadge :analysis-status="analysis.status"></AnalysisStatusBadge>
                </div>
                <div>{{ $d(analysis.sequencingRunDate) }}</div>
                <div style="grid-column: 11 / 13;">{{ analysis.ownedBy }}</div>
            </div>
        </div>

        <div class="empty-search-results-placeholder" v-else>
            <span class="fallback-text h1">{{ $tc("foundSamples", samplesNumber) }}</span>
        </div>

        <div v-if="samplesNumber !== 0" style="align-self: center; justify-self: center">
            <SampleCard v-if="samplesNumber && shownAnalysis"
                        :sample="shownAnalysis"
                        :possibility-removing-samples-and-runs="true"
                        @update:sample="updateSample"
                        @delete:sample="deleteSample"
                        @delete:run="deleteRun"
            />
            <div class="no-selected-sample-placeholder" v-else>
                <span class="fallback-text h2">{{ $t("noSelectedSamplePlaceholder") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/store"
    import { AnalysisStatuses } from "@/enums/analysis-status"
    import ld from "lodash"
    import AnalysisStatusBadge from "@/components/analysis/status-badge"
    import { compositeSearchMixin } from "@/mixins/composite-search"
    import { archiveActionNames, archiveGetterNames } from "@/store/modules/archive"
    import { Locales } from "@/i18n/main"
    import { removeEmptyFields } from "@/extensions/object-extensions"
    import { validateAllele } from "@/validation/validators"
    import { debounce } from "@/main"
    import {
        buildArchiveAnalysesGeneralStatisticsExportUrl,
        buildArchiveAnalysesGenotypesExportUrl,
        buildArchiveAnalysesLocusSpecificStatisticsExportUrl,
        fetchOrganizationNames,
        fetchRunNamesMatchingPrefix,
    } from "@/endpoints"
    import { downloadUsingPhantomLink } from "@/helpers"

    import SampleCard from "../samples/components/sample-card"

    export default {
        name: "Archive",
        components: {
            SampleCard,
            AnalysisStatusBadge,
        },
        watch: {
            /*
             TODO: [@aslepchenkov 28.02.2020] Simple workaround. I should use ValidatableInput but it will
              bring much more code overhead than this hack. Think about all this validatable thing
            */
            "tempFilters.allele"(newVal) {
                (newVal ? validateAllele({ allele: newVal }) : Promise.resolve([]))
                    .then(validationErrors => this.alleleValidationErrors = validationErrors)
            },
            "tempFilters.runId"(newVal) {
                this.updateRunTypeAheadOptions(newVal)
            },
        },
        mixins: [ compositeSearchMixin ],
        beforeRouteEnter(to, from, next) {
            Promise
                .all([
                    store.dispatch(archiveActionNames.startNewSearch),
                    fetchOrganizationNames(),
                ])
                .then(([ _, organizationNames ]) =>
                    next(vm => vm.organizationNames = organizationNames))
        },
        data() {
            return {
                throttledOnScroll: null,
                shownAnalysis: null,
                liveSearchFilterName: "sampleName",
                alleleValidationErrors: [],
                organizationNames: [],
                runTypeAheadOptions: [],
                tempFilters: {
                    queryForAnalysesWithNewAllele: false,
                    // Dirty hack to enforce Vue to watch for this property value
                    organizationName: null,
                },
            }
        },
        computed: {
            samples() {
                return this.$store.getters[archiveGetterNames.resourceList]
            },
            samplesNumber() {
                return this.$store.getters[archiveGetterNames.resourceNumber]
            },
            fetchInProgress() {
                return this.$store.getters[archiveGetterNames.fetchInProgress]
            },
            analysisStatuses() {
                return Object.keys(AnalysisStatuses)
            },
        },
        methods: {
            updateRunTypeAheadOptions(caseInsensitiveNamePrefix) {
                fetchRunNamesMatchingPrefix(
                    caseInsensitiveNamePrefix,
                    10,
                ).then(sequencingRunNames => this.runTypeAheadOptions = Array.from(new Set(sequencingRunNames)))
            },
            exportGenotypes(resolution) {
                const backendCompatibleFilters = makeFiltersBackendCompatible(this.filters)
                if (!ld.isEmpty(backendCompatibleFilters)
                    || confirm(this.$t("noFiltersForExport"))) {
                    downloadUsingPhantomLink(buildArchiveAnalysesGenotypesExportUrl(backendCompatibleFilters, resolution))
                }
            },
            exportGeneralStatistics(event) {
                event.stopPropagation()
                const backendCompatibleFilters = makeFiltersBackendCompatible(this.filters)
                if (!ld.isEmpty(backendCompatibleFilters)
                    || confirm(this.$t("noFiltersForGeneralStatisticsExport"))) {
                    downloadUsingPhantomLink(buildArchiveAnalysesGeneralStatisticsExportUrl(backendCompatibleFilters))
                }
            },
            exportLocusSpecificStatistics(event) {
                event.stopPropagation()
                const backendCompatibleFilters = makeFiltersBackendCompatible(this.filters)
                if (!ld.isEmpty(backendCompatibleFilters)
                    || confirm(this.$t("noFiltersForLocusSpecificStatisticsExport"))) {
                    downloadUsingPhantomLink(buildArchiveAnalysesLocusSpecificStatisticsExportUrl(backendCompatibleFilters))
                }
            },
            onScroll() {
                const resourceListScrollableContainer = this.$refs["infiniteScrollContainer"]
                const scrollPercent = resourceListScrollableContainer.scrollTop / (resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight)
                if (scrollPercent > 0.9 && !this.fetchInProgress && (this.samples.length !== this.samplesNumber)) {
                    this.$store.dispatch(archiveActionNames.continueSearch)
                }
            },
            startNewSearch(filters) {
                const resourceListScrollableContainer = this.$refs["infiniteScrollContainer"]
                return this.$store.dispatch(archiveActionNames.startNewSearch, { filter: makeFiltersBackendCompatible(updateFilterDate(filters)) })
                    .then(() => {
                        // eslint-disable-next-line promise/always-return
                        if (resourceListScrollableContainer) {
                            resourceListScrollableContainer && resourceListScrollableContainer.scrollTo(0, 0)
                        }
                        this.shownAnalysis = null
                    })
                    .then(() => this.fetchSamplesTillScrollAppearsOrTheyEnd())
                    .catch(() => { /* Bypass to avoid uncaught promise rejection */ })
            },
            async fetchSamplesTillScrollAppearsOrTheyEnd() {
                const resourceListScrollableContainer = this.$refs["infiniteScrollContainer"]
                let scrollIsMissing = () => resourceListScrollableContainer.scrollHeight - resourceListScrollableContainer.clientHeight === 0
                while (scrollIsMissing() && this.samples.length !== this.samplesNumber) {
                    await this.$store.dispatch(archiveActionNames.continueSearch)
                }
            },
            updateSample(updatedSample) {
                this.$store.dispatch(archiveActionNames.updateResource, updatedSample)
                this.shownAnalysis = updatedSample
            },
            deleteSample() {
                this.shownAnalysis = null
                this.startNewSearch(this.filters)
            },
            deleteRun() {
                this.shownAnalysis = null
                this.startNewSearch(this.filters)
            },
            toggleAnalysesWithNewAllele() {
                this.tempFilters = {
                    ...this.tempFilters,
                    queryForAnalysesWithNewAllele: !this.tempFilters.queryForAnalysesWithNewAllele
                }
            },
            organizationSelect(organizationName) {
                this.tempFilters = {
                    ...this.tempFilters,
                    organizationName: (this.tempFilters.organizationName === organizationName) ? null : organizationName
                }
            }
        },
        created() {
            this.throttledOnScroll = ld.throttle(this.onScroll, 1000)
            this.updateRunTypeAheadOptions = debounce(this.updateRunTypeAheadOptions, 200)
        },
        mounted() {
            this.fetchSamplesTillScrollAppearsOrTheyEnd()
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    noSelectedSamplePlaceholder: "Select sample from the list to see details",
                    // This dumb shit treats "text | otherText" as 1 and more than one
                    foundSamples: "No samples found | Found {n} sample | Found {n} samples",
                    liveSearchPlaceholder: "Search by sample name",
                    searchBy: "Search by",
                    alleleLocusNameWithAlleleAtFieldResolution: "allele: specify locus name with allele GL String, e.g. A*01:01:01 or DQB1*01:02/02:03",
                    annotationLocusNameWithAnnotationLetter: "annotation: specify locus name with annotation letter, e.g. A@N",
                    noFiltersForExport: "You did not specify any filter, are you sure want to export all existing genotypes?",
                    noFiltersForGeneralStatisticsExport: "You did not specify any filter, are you sure want to export general statistics for all existing analyses?",
                    noFiltersForLocusSpecificStatisticsExport: "You did not specify any filter, are you sure want to export locus specific statistics for all existing analyses?",
                    resolution: "{0} field genotypes",
                    queryForAnalysesWithNewAllele: "Search for samples with new allele",
                    queryForAnalysesWithNewAlleleIndicator: "NEW",
                    dateFrom: "Date from",
                    dateTo: "Date to",
                },
                [Locales.RU]: {
                    noSelectedSamplePlaceholder: "Выберите образец из списка, чтобы увидеть детали",
                    // For reasons why 2, 3 and 4 translations exist, though they are the same, see russianPluralization
                    foundSamples: "Не найдено ни одного образца | Найдено образцов: {n} | Найдено образцов: {n} | Найдено образцов: {n}",
                    liveSearchPlaceholder: "Введите имя образца",
                    searchBy: "Поиск по",
                    alleleLocusNameWithAlleleAtFieldResolution: "аллели: укажите название локуса и аллель в формате GL String, например, A*01:01:01 или DQB1*01:02/02:03",
                    annotationLocusNameWithAnnotationLetter: "аннотации: укажите имя локуса и символ функциональной аннотации, например, A@N",
                    noFiltersForExport: "Не указано никаких фильтров, вы уверены что хотите экспортировать все существующие образцы?",
                    noFiltersForGeneralStatisticsExport: "Не указано никаких фильтров, вы уверены что хотите экспортировать общие статистики для всех существующих образцов?",
                    noFiltersForLocusSpecificStatisticsExport: "Не указано никаких фильтров, вы уверены что хотите экспортировать локус специфичные статистики для всех существующих образцов?",
                    resolution: "Генотипы на {0} поля",
                    queryForAnalysesWithNewAllele: "Искать образцы с новой аллелью",
                    queryForAnalysesWithNewAlleleIndicator: "NEW",
                    dateFrom: "Дата от",
                    dateTo: "Дата до",
                }
            }
        }
    }

    function makeFiltersBackendCompatible(filters) {
        return removeEmptyFields({
            nameStartsWith: filters.sampleName,
            fromRunWithId: filters.runId,
            from: new Date(filters.from).getTime() || null,
            to: new Date(filters.to).getTime() || null,
            withStatus: filters.status,
            organizationName: filters.organizationName,
            holdingGenotype: [
                filters.allele,
                filters.queryForAnalysesWithNewAllele && "WITH_NEW_ALLELE",
            ].filter(it => it),
        })
    }

    function updateFilterDate(filters) {
        return {
            ...filters,
            from: filters.from ? Date.parse(filters.from) : null,
            to: filters.to ? Date.parse(filters.to) : null,
        }
    }
</script>

<style scoped>
.samples-container {
    display: grid;
    grid-template-rows: min-content minmax(70%, 1fr);
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    margin: 0 10%;
}

@media screen and (max-width: 1300px) {
    .samples-container {
        margin: 0;
    }
}

.samples-search {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    margin: 3rem 1rem;
    font-size: 1.8rem;
}

.filters--input {
    margin: 1.5rem 0;
}

.samples-list {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    overflow: auto;
}

.samples-list--sample {
    padding: 1.5rem;
    border: 1px solid var(--grey-400);
    border-radius: 4px;
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(12, minmax(8%, 1fr));
    grid-template-rows: 100%;
    align-items: center;
}

.samples-list--sample:hover {
    background-color: #F5F5F5;
    cursor: pointer;
}

.samples-list--sample.selected {
    position: sticky;
    top: 4px;
    bottom: 4px;
    z-index: var(--second-layer);
    background-color: #E1F5FE;
    border-color: #E1F5FE;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 2px 4px 2px rgba(0, 0, 0, .05), 0 4px 6px 2px rgba(0, 0, 0, .05);
}

.empty-search-results-placeholder {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-selected-sample-placeholder {
    text-align: center;
    padding: 4rem;
}

.samples-search-top-panel {
    display: flex;
}

.btn-group {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--parseq-blue);
    width: fit-content;
    display: flex;
}

.btn-group--btn {
    transition: background-color .2s;
    padding: 1rem;
}

.btn-group--btn:hover:not(.btn-group--btn-active) {
    background-color: hsl(225, 73%, 95%);
}

.btn-group--btn:not(:last-child) {
    border-right: 1px solid var(--parseq-blue);
}

.btn-group--btn-active {
    background-color: var(--parseq-blue);
    color: white;
}

.samples-export-resource__btn {
    color: royalblue;
    width: 100%;
    font-size: .8em;
}
</style>
