





































import { Locales } from "@/i18n/main"
// eslint-disable-next-line no-unused-vars
import { SampleValidationErrorType } from "@/store/modules/sample"

const messages: Record<Locales, Record<SampleValidationErrorType, string | object>
    & Record<string, string | object>> = {
        [Locales.EN]: {
            sampleLoadError: "Samples creation errors",
            forwardFilesCount: "Should have {0} forward file got {1}",
            reverseFilesCount: "Should have {0} reverse file got {1}",
            wrongSampleName: "Invalid sample name",
            samplesCannotBeLoaded: "Sample {0} will not be added | Samples {0} will not be added",
            sample: "Sample",
            errors: "Errors"
        },
        [Locales.RU]: {
            sampleLoadError: "Ошибки при добавлении образцов",
            forwardFilesCount: "Должен быть {0} файл с прямыми ридами, найдено {1}",
            reverseFilesCount: "Должен быть {0} файл с обратными ридами, найдено {1}",
            wrongSampleName: "Неправильное имя образца",
            samplesCannotBeLoaded: "Образец {0} не может быть добавлен | Образцы {0} не могут быть добавлены",
            sample: "Образец",
            errors: "Ошибки"
        }
    }

export default {
    name: "SampleValidationError",
    props: {
        error: Object
    },
    i18n: {
        messages
    }
}
