<template>
<div>
    <error-message v-if="maybeFirstError" @close="removeFirstError">
        <existing-run-error v-if="maybeFirstError.type === 'existingRunError'" :error="maybeFirstError"/>
        <template v-else-if="maybeFirstError.type === 'fileNotMatchSelectedPlatform'">
            <h3 class="error__header">{{ $t("parsingErrorRunInfo") }}</h3>
            <div>{{ $t("fileNotMatchSelectedPlatform", [maybeFirstError.details.platform.name]) }}</div>
            <div>{{ $t("makeSureAttachedCorrectRunInfo") }}</div>
        </template>
        <template v-else>
            <h3 class="error__header">{{ $t("parsingErrorRunInfo") }}</h3>
            <div>{{ $t(maybeFirstError.type) }}</div>
            <div>{{ $t("makeSureAttachedCorrectRunInfo") }}</div>
        </template>
    </error-message>
    <div
        v-if="completedUpload"
        class="uploaded-run"
    >
        <span
            v-if="completedUpload.successful"
            class="uploaded-run--message mb-2"
        >{{ $t("uploadSuccess", { runName: runMetadataForm.id.value }) }}</span>
        <span
            v-else
            class="uploaded-run--message mb-2"
        >{{ $t("uploadFailed", { runName: runMetadataForm.id.value }) }}</span>
        <button style="font-size: 2rem"
                class="md-btn-contained royal-blue"
                @click="startNewRunUpload">{{ $t("startAnotherUpload") }}
        </button>
    </div>
    <div
        v-else-if="!tasksCount"
        class="wizard"
    >
        <div class="step-map-container">
            <ul class="step-map">
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <li
                    v-for="(step, index) in steps"
                    :class="{ active: activeStepIndex === index }"
                    class="step-description"
                >
                    <div class="bullet">{{ index + 1 }}</div>
                    {{ step.name }}
                </li>
            </ul>
        </div>
        <transition :name="transition" mode="out-in">
            <component
                :is="activeStep.component"
                class="active-step"
                v-bind="activeStep.properties"
                v-on="activeStep.listeners"
            />
        </transition>
        <div class="wizard-controls">
            <button @click="previous"
                    id="wizard-previous-btn"
                    class="md-btn-outlined wizard-btn previous-btn"
                    v-if="hasPreviousStep"
            >{{ $t("buttons.previous") }}
            </button>
            <button @click="next"
                    id="wizard-next-btn"
                    :disabled="!canGoToNextStep"
                    class="md-btn-contained wizard-btn ml-auto royal-blue"
                    v-if="hasNextStep"
            >{{ $t("buttons.next") }}
            </button>
            <button @click="startUpload"
                    class="md-btn-contained wizard-btn ml-auto green"
                    v-else
            >{{ $t("buttons.startUpload") }}
            </button>
        </div>
    </div>
    <div v-else class="upload-progress-container">
        <upload-progress :tasks="tasks"/>
    </div>
</div>
</template>

<script>
    import { ValidatableInput } from "@/validation/validatable-input"
    import { EventBus } from "@/event-bus"
    import MetadataStep from "@/views/data-upload/components/metadata-step.vue"
    import LotStep from "@/views/data-upload/components/lot-step.vue"
    import StartUploadStep from "@/views/data-upload/components/start-upload-step.vue"
    import ChooseLotStep from "@/views/data-upload/components/choose-lot-step.vue"
    import { Lot } from "@/store/modules/lot"
    import { store } from "@/store/store"
    import { Locales } from "@/i18n/main"
    import { required, validateFlows, createRunIdValidator } from "@/validation/validators"
    import { backgroundTasksGetterNames } from "@/store/modules/background-tasks"
    import { parseRunInfo, readFile } from "@/utils/run-info"
    import { mapValues } from "@/extensions/object-extensions"
    import UploadProgress from "@/views/data-upload/components/upload-progress"
    import ErrorMessage from "@/components/error-message"
    import ExistingRunError from "@/components/errors/existing-run-error"
    import { errorMixin } from "@/mixins/error-mixin"

    export default {
        name: "RawDataUpload",
        mixins: [ errorMixin ],
        components: {
            UploadProgress,
            ChooseLotStep,
            LotStep,
            MetadataStep,
            StartUploadStep,
            ErrorMessage,
            ExistingRunError
        },
        data() {
            return {
                runMetadataForm: new RunMetadataForm(new EventBus()),
                lots: [],
                activeStepIndex: 0,
                selectedLot: null,
                transition: "next",
                completedUpload: null
            }
        },
        methods: {
            next() {
                if (this.canGoToNextStep) this.activeStep.onExit?.()
                this.transition = "next"
                this.activeStepIndex += 1
            },
            previous() {
                this.activeStep.onGoBack?.()
                this.transition = "previous"
                this.activeStepIndex -= 1
            },
            startUpload() {
                this.transition = null
                // eslint-disable-next-line no-unused-vars
                const { lotNames, id: name, ...properties } = this.runMetadataForm.valuesAsObject

                this.$store.dispatch("uploadRun", {
                    run: {
                        name,
                        date: properties.date.getTime(),
                        reagentKitLotUUID: this.lots[0].id,
                        properties
                    },
                    lots: this.lots
                }).then(successful => this.completedUpload = { successful })
            },
            addSamplesToLot(lotName, samples) {
                samples.forEach(it => this.lots.find(lot => lot.name === lotName).addSample(it))
            },
            removeAllSamplesFromLot(lotName) {
                this.lots.find(lot => lot.name === lotName).removeAllSamples()
            },
            setControlSampleForLot(lotName, controlSample) {
                this.lots.find(lot => lot.name === lotName).controlSample = controlSample
            },
            startNewRunUpload() {
                this.activeStepIndex = 0
                this.runMetadataForm = new RunMetadataForm(new EventBus())
                this.completedUpload = null
                this.lots = []
                this.selectedLot = null
            },
            parseRunInfo(fileContent) {
                return parseRunInfo(this.platform, fileContent)
            },
            mapToFormInputs(parsedRunInfo) {
                const form = new RunMetadataForm(new EventBus())

                form.id.value = parsedRunInfo.id
                form.date.value = parsedRunInfo.date
                form.flows.value = parsedRunInfo.flows
                form.sequencingKit.value = parsedRunInfo.sequencingKit

                return form
            },
            async validateFormInputs(form) {
                if (await form.isValid()) {
                    return form
                }
                if (form.id.errors.length !== 0) {
                    throw {
                        type: "existingRunError",
                        details: {
                            runId: form.id.value
                        }
                    }
                } else {
                    throw {
                        type: "metadataFailedValidated",
                    }
                }
            },
            fillInTheForm(form) {
                this.runMetadataForm = form
            },
            showTheError(error) {
                if(error.type === "fileNotMatchSelectedPlatform"){
                    this.addError({
                        type: error.type,
                        details: {
                            platform: {
                                name: this.platform.name
                            }
                        }
                    })
                } else if (error.type !== undefined) {
                    this.addError({
                        type: error.type,
                        details: error.details
                    })
                } else {
                    throw error
                }
            },
            onRunInfoDrop(file) {
                return readFile(file)
                    .then(this.parseRunInfo)
                    .then(this.mapToFormInputs)
                    .then(this.validateFormInputs)
                    .then(this.fillInTheForm)
                    .catch(this.showTheError)
            }
        },
        computed: {
            tasks() {
                return this.$store.getters[backgroundTasksGetterNames.tasks]
            },
            tasksCount() {
                return this.$store.getters[backgroundTasksGetterNames.tasksCount]
            },
            platform() {
                return this.$store.state.rawDataUpload.platforms[0]
            },
            activeStep() {
                return this.steps[this.activeStepIndex]
            },
            hasNextStep() {
                return this.activeStepIndex < this.steps.length - 1
            },
            hasPreviousStep() {
                return this.activeStepIndex > 0
            },
            canGoToNextStep() {
                return this.steps[this.activeStepIndex].isDone()
            },
            steps() {
                return [
                    {
                        name: this.$t("chooseLot"),
                        component: "ChooseLotStep",
                        properties: {
                            platform: this.platform,
                            selectedLot: this.selectedLot
                        },
                        listeners: {
                            "lot-selected": lot => this.selectedLot = lot
                        },
                        isDone: () => this.selectedLot !== null,
                        onExit: () => this.lots = [ new Lot(this.selectedLot.name, this.selectedLot.id) ]
                    },
                    {
                        name: this.$t("fillMetadata"),
                        component: "MetadataStep",
                        properties: {
                            runMetadataForm: this.runMetadataForm,
                            platform: this.platform,
                            onRunInfoDrop: this.onRunInfoDrop
                        },
                        isDone() {
                            return this.properties.runMetadataForm.isValidSync === true
                        },
                        onGoBack: () => {
                            this.runMetadataForm = new RunMetadataForm(new EventBus())
                            this.lots = []
                        }
                    },
                    {
                        name: this.$t("addSamples"),
                        component: "LotStep",
                        properties: {
                            lots: this.lots,
                            platform: this.platform,
                            addSamplesToLot: this.addSamplesToLot,
                            removeAllSamplesFromLot: this.removeAllSamplesFromLot,
                            setControlSampleForLot: this.setControlSampleForLot
                        },
                        isDone() {
                            return this.properties.lots.every(lot => lot.samples.length > 0)
                        },
                        onGoBack: () => this.lots = this.lots.map(lot => new Lot(lot.name, lot.id))
                    },
                    {
                        name: this.$t("startUpload"),
                        component: "StartUploadStep",
                        properties: {
                            metadata: this.runMetadataForm,
                            platform: this.platform,
                            lots: this.lots
                        },
                        isDone() {
                            return true
                        }
                    },
                ]
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    fillMetadata: "Fill metadata",
                    addSamples: "Add samples to lots",
                    startUpload: "Start upload",
                    startAnotherUpload: "Start another upload",
                    uploadSuccess: "Run {runName} successfully uploaded",
                    uploadFailed: "Run {runName} upload failed",
                    chooseLot: "Choose lot",
                    parsingErrorRunInfo: "Error parsing RunInfo.xml",
                    fileNotMatchSelectedPlatform: "RunInfo file doesn't match selected platform - {0}",
                    idIsMissing: "Id attribute is missing",
                    idNotMatchMask: "Id doesn't match mask for specified platform",
                    unknownSequencingKit: "Unknown sequencing kit",
                    metadataFailedValidated: "Metadata was not validated",
                    makeSureAttachedCorrectRunInfo: "Make sure to attach the correct RunInfo.xml file from the supplied launcher. If you are sure that the file is correct, but the error remains, write to our support. For now, you can fill out the form manually."
                },
                [Locales.RU]: {
                    fillMetadata: "Заполнение метаданных",
                    addSamples: "Добавление образцов",
                    startUpload: "Загрузка",
                    startAnotherUpload: "Начать новую загрузку",
                    uploadSuccess: "Запуск {runName} успешно загружен",
                    uploadFailed: "Загрузка запуска {runName} завершилась с ошибкой",
                    chooseLot: "Выбор серии",
                    parsingErrorRunInfo: "Ошибка при разборе RunInfo.xml",
                    fileNotMatchSelectedPlatform: "Файл RunInfo не соответствует выбранной платформе - {0}",
                    idIsMissing: "Атрибут Id отсутствует",
                    idNotMatchMask: "Идентификатор не соответствует маске для указанной платформы",
                    unknownSequencingKit: "Неизвестный набор для секвенирования",
                    metadataFailedValidated: "Метаданные не прошли валидацию",
                    makeSureAttachedCorrectRunInfo: "Убедитесь что вы прикрепили верный файл RunInfo.xml из поставленного запуска. Если вы уверены, что файл верный, но ошибка остается напишите нам в поддержку. Пока что вы можете заполнить форму вручную."
                }
            }
        }
    }

    class RunMetadataForm {
        constructor(eventBus) {
            this.id = new ValidatableInput("id", eventBus, createRunIdValidator(store))
            this.sequencingKit = new ValidatableInput("sequencingKit", eventBus, required("sequencingKit"))
            this.date = new ValidatableInput("date", eventBus, required("date"))
            this.flows = new ValidatableInput("flows", eventBus, validateFlows)
        }

        async isValid() {
            const validationResults = await Promise.all([
                this.id.isValid(),
                this.sequencingKit.isValid(),
                this.date.isValid(),
                this.flows.isValid(),
            ])
            return validationResults.every(it => it)
        }

        // Not so honest, it returns false when validation is pending, but will do for now
        get isValidSync() {
            return [
                this.id.isValidSync,
                this.sequencingKit.isValidSync,
                this.date.isValidSync,
                this.flows.isValidSync,
            ].every(it => it === true)
        }

        get valuesAsObject() {
            return mapValues(this, (value) => value.value)
        }
    }
</script>

<style scoped>

.wizard {
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: 1fr fit-content(10%);
    grid-template-areas: "stepContent stepMap" "wizardControls stepMap";
    height: 100%;
}

.step-map-container {
    grid-area: stepMap;
    border-color: hsl(0, 0%, 96%);
    /* These are separated to ease media query changes */
    border-width: 1px;
    border-style: none;
    border-left-style: solid;
}

.step-map {
    position: sticky;
    top: 0;
    font-size: 1.6rem;
    padding: 2em;
}

.step-description {
    margin: 2em 0;
    white-space: nowrap;
}

.step-description.active .bullet {
    border-color: var(--parseq-orange);
}

.bullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 2px solid #337ab7;
    margin-right: 1em;
}

.active-step {
    grid-area: stepContent;
}

.wizard-controls {
    grid-area: wizardControls;
    display: flex;
    margin: 3em;
    align-items: center;
    justify-content: space-between;
}

.wizard-btn {
    font-size: 1.6rem;
}

.previous-btn {
    color: royalblue;
}

.previous-enter-active, .previous-leave-active,
.next-enter-active, .next-leave-active {
    transition: all .4s;
}

.next-enter, .next-leave-to, .previous-enter, .previous-leave-to {
    opacity: 0;
}

.next-enter {
    transform: translateX(20%);
}

.next-leave-to {
    transform: translateX(-20%);
}

.previous-enter {
    transform: translateX(-20%);
}

.previous-leave-to {
    transform: translateX(20%);
}

.upload-progress-container {
    padding: 5rem 10rem 2rem;
    max-width: 80rem;
    width: 100%;
    margin: 0 auto;
}

.uploaded-run {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 6rem;
}

.uploaded-run--message {
    font-size: 5rem;
    color: var(--grey-500);
    font-weight: 700;
    text-align: center;
}

@media only screen and (max-width: 75em) {
    .wizard {
        grid-template-columns: 100%;
        grid-template-rows: min-content 1fr fit-content(60%);
        grid-template-areas: "stepMap" "stepContent" "wizardControls";
    }

    .step-map-container {
        border-style: none;
        border-bottom-style: solid;
    }

    .step-description {
        margin: 0;
    }

    .step-map {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
