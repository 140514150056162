export const useBrand = () => {
    let iconName: string | null = null
    let brandName: string | null = null
    let brand: string | null = null

    if (process.env.VUE_APP_BRAND_NAME === "PARSEQ") {
        iconName = "PARSEQ_ICON"
        brandName = "PARSEQ_NAME"
        brand = "PARSEQ"
    } else if (process.env.VUE_APP_BRAND_NAME === "EPIVALUE") {
        iconName = "EPIVALUE_ICON"
        brandName = "EPIVALUE_NAME"
        brand = "EPIVALUE"
    } else {
        iconName = null
        brandName = null
        brand = null
    }

    return {
        iconName,
        brandName,
        brand
    }
}
