export function whenReady(webSocket: WebSocket): Promise<WebSocket> {
    return new Promise(resolve => {
        webSocket.addEventListener("open", () => resolve(webSocket))
    })
}

export function waitForMessage(webSocket: WebSocket): Promise<MessageEvent> {
    return new Promise(resolve => {
        webSocket.addEventListener("message", (message) => resolve(message))
    })
}
