/*
 * Something like `:focus-within` can be used as a trigger for dropdown, however this
 * approach has some drawbacks: `transition` doesn't work, because there is no
 * `v-show`. Also it's not always desirable to open dropdown on focus, e.g. for select
 * I want the button press to be the trigger, and there might be other events leading to dropdown close,
 * using `blur()` for this might not be easy
 */
export const dropdownMixin = {
    props: {
        disabled: Boolean,
        align: {
            type: String,
            default: "bottom-right"
        },
        fixed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdownShown: false,
            dropdownPosition: null
        }
    },
    methods: {
        openDropdown() {
            this.dropdownShown = true
        },
        closeDropdown() {
            this.dropdownShown = false
        },
        toggleDropdown() {
            if (this.dropdownShown) {
                this.dropdownShown = false
            } else {
                this.openDropdown()
            }
        },
        dropdownFocusoutHandler(event) {
            if (!this.$refs.container.contains(event.relatedTarget)) {
                this.closeDropdown()
            }
        }
    },
    mounted() {
        if (!Object.prototype.hasOwnProperty.call(this.$refs, "container")) {
            console.error("Container element absent")
        }
        if (this.align === "right-bottom") {
            this.dropdownPosition = {
                left: "100%",
                bottom: "0px"
            }
        } else if (this.align === "left-top") {
            this.dropdownPosition = {
                right: "100%",
                bottom: "0px"
            }
        } else if (this.align === "right-top") {
            this.dropdownPosition = {
                left: "100%",
                bottom: "0px"
            }
        } else if (this.align === "bottom") {
            this.dropdownPosition = {
                left: "auto",
                bottom: "100%"
            }
        } else {
            this.dropdownPosition = {
                left: "auto",
                top: "100%",
            }
        }
        this.dropdownPosition = {
            ...this.dropdownPosition,
            position: this.fixed ? "fixed" : "absolute"
        }
    },
    watch: {
        dropdownShown: {
            handler() {
                /*
                 Pray that this watcher is called synchronously after data update and before v-show takes action.
                 Nothing criminal, but it's better to render element in desired place, than show
                 it in stale position and then move it.
                */
                if (this.dropdownShown && this.$refs["container"] && this.fixed) {
                    const { bottom, left, width, right, top } = this.$refs["container"].getBoundingClientRect()
                    const { height: bodyHeight, width: bodyWidth } = document.body.getBoundingClientRect()
                    if (this.align === "right-bottom") {
                        this.dropdownPosition = {
                            position: "fixed",
                            left: `${right}px`,
                            bottom: `${bodyHeight - bottom}px`
                        }
                    } else if (this.align === "left-top") {
                        this.dropdownPosition = {
                            position: "fixed",
                            right: `${bodyWidth - left}px`,
                            top: `${top}px`
                        }
                    } else if (this.align === "right-top") {
                        this.dropdownPosition = {
                            position: "fixed",
                            left: `${right}px`,
                        }
                    } else if (this.align === "bottom") {
                        this.dropdownPosition = {
                            position: "fixed",
                            bottom: `${bodyHeight - top}px`,
                            width: `${width}px`
                        }
                    } else {
                        this.dropdownPosition = {
                            position: "fixed",
                            left: `${left}px`,
                            top: `${bottom}px`,
                            width: `${width}px`
                        }
                    }
                }
                this.$nextTick(() => {
                    if (this.dropdownShown && this.$refs["dropdown"] && this.$refs["container"]) {
                        const { top, height: containerHeight } = this.$refs["container"].getBoundingClientRect()
                        const { bottom } = this.$refs["dropdown"].getBoundingClientRect()
                        const { height: maxHeight } = document.body.getBoundingClientRect()
                        if (bottom >= maxHeight) {
                            if (this.fixed) {
                                this.dropdownPosition = {
                                    ...this.dropdownPosition,
                                    top: "unset",
                                    bottom: `${maxHeight - top}px`,
                                }
                            } else {
                                this.dropdownPosition = {
                                    ...this.dropdownPosition,
                                    top: "unset",
                                    bottom: `${containerHeight}px`,
                                }
                            }
                        }
                    }
                })
            }
        }
    },
}
