import { ActionTree } from "vuex"
import { createPageableStore, PageableState, PageableStoreModule } from "@/store/modules/pageable-store"
import { AnalysisStatus } from "@/enums/analysis-status"
import { Quality } from "@/utils/analysis"
import { fetchRun, fetchRunAnalyses } from "@/endpoints"

const RESOURCE_NAME = "sequencingRuns"
export type ExportStatus = "NOT_READY" | "READY" | "ALREADY"

export type SequencingRunFilter = Partial<{
    name: string[] | string,
    from: number,
    to: number,
    withExportStatus: ExportStatus
}>

export interface Run {
    id: string
    ownedBy: string,
    createdBy: string,
    createdAt: number,
    name: string,
    date: number,
    exportStatus: ExportStatus,
    properties: {
        flows: string,
        labQcAggs?: {
            [key in Quality]: number
        },
        // Missing when there are no analyses in run. It's debatable whether this state is legal
        statusAggs?: {
            [status in AnalysisStatus]: number
        },
        sequencingKit: { name: string, platform: string },
        controlSampleQc: { [lotName: string]: string }
    },
}

const {
    module: {
        state,
        getters,
        actions: pageableStoreActions,
        mutations
    },
    actionNames: pageableStoreActionNames,
    getterNames
} = createPageableStore<Run, SequencingRunFilter>(RESOURCE_NAME)

export const runsActionNames = {
    ...pageableStoreActionNames,
    fetchRun: `${RESOURCE_NAME}/fetchRun`,
    fetchRunAnalyses: `${RESOURCE_NAME}/fetchRunAnalyses`
}

export const runsGetterNames = getterNames

const actions: ActionTree<PageableState<Run, SequencingRunFilter>, any> = {
    ...pageableStoreActions,
    [runsActionNames.fetchRun](_, { id }) {
        return fetchRun(id)
    },
    [runsActionNames.fetchRunAnalyses](_, { id }) {
        return fetchRunAnalyses(id)
    }
}

export const runs = {
    state,
    getters,
    actions,
    mutations
}

function createPrefilteredByExportStatusRunsStore(
    withExportStatus: ExportStatus
): PageableStoreModule<Run, SequencingRunFilter> {
    return createPageableStore<Run, SequencingRunFilter>(RESOURCE_NAME, { withExportStatus }, withExportStatus)
}

export const {
    actionNames: notReadyRunsActionNames,
    getterNames: notReadyRunsGetterNames,
    module: notReadyRuns
} = createPrefilteredByExportStatusRunsStore("NOT_READY")

export const {
    actionNames: readyRunsActionNames,
    getterNames: readyRunsGetterNames,
    module: readyRuns
} = createPrefilteredByExportStatusRunsStore("READY")

export const {
    actionNames: alreadyRunsActionNames,
    getterNames: alreadyRunsGetterNames,
    module: alreadyRuns
} = createPrefilteredByExportStatusRunsStore("ALREADY")
