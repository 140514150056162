import { ActionTree, Module, MutationTree } from "vuex"
import { fetchResourcePage } from "@/endpoints"

const DEFAULT_PAGE_SIZE = 20
export type MaybeCurrentPageMutation = void
export type Identifiable<Id> = { id: Id }

export interface ActiveFetchPromise {
    fetchFn: () => Promise<MaybeCurrentPageMutation>
}

export interface Page {
    pageNumber: number,
    pageSize: number
}

export interface ResourcePage<Resource> extends Page {
    resourceList: Array<Resource>,
    resourceTotalNumber: number
}

export interface FilteredPage<Resource, Filter> extends ResourcePage<Resource> {
    filter: Partial<Filter>
}

export interface PageableState<Resource, Filter> {
    resourceList: Array<Resource>,
    currentPage: FilteredPage<Resource, Filter>,
    activeFetchPromise: ActiveFetchPromise | null
}

export interface PageableStoreActionNames {
    startNewSearch: string
    continueSearch: string
}

export interface PageableStoreMutationNames {
    resetResourceList: string
    replenishResourceList: string
    activateFetchPromise: string
    deactivateFetchPromise: string
}

export interface PageableStoreGetterNames {
    resourceList: string
    resourceNumber: string
    fetchInProgress: string
}

function removeNullProperties(obj: Record<string, any>) {
    for (const key in obj) {
        if (obj[key] === null) {
            delete obj[key]
        }
    }
    return obj
}

function nullPage<Resource, Filter>(
    baseFilter: Partial<Filter> | undefined
): FilteredPage<Resource, Filter> {
    return {
        pageNumber: -1,
        pageSize: DEFAULT_PAGE_SIZE,
        resourceTotalNumber: 0,
        resourceList: [],
        filter: baseFilter || {}
    }
}

function createState<Resource, Filter>(
    baseFilter: Partial<Filter> | undefined
): PageableState<Resource, Filter> {
    return {
        resourceList: [],
        currentPage: nullPage(baseFilter),
        activeFetchPromise: null
    }
}

function resetPagination(page: Page): Page {
    return { pageNumber: 0, pageSize: page.pageSize }
}

function nextPage(page: Page): Page {
    return { pageNumber: page.pageNumber + 1, pageSize: page.pageSize }
}


export interface PageableStoreModule<Resource, Filter> {
    module: Module<PageableState<Resource, Filter>, any>
    actionNames: PageableStoreActionNames
    getterNames: PageableStoreGetterNames
}

export function createPageableStore<Resource, Filter>(
    resourceName: string,
    baseFilter?: Partial<Filter>,
    prefix?: string
): PageableStoreModule<Resource, Filter> {
    /*
     * There are some cases when I need separate stores with the same resource.
     * e.g. to have separate states for runs with different statuses. See RunsDataExport view.
     */
    prefix = prefix ? `${resourceName}-${prefix}` : resourceName
    function fetchPage(filter: Partial<Filter>, page: Page) {
        return fetchResourcePage(resourceName, filter, page)
    }

    const actionNames: PageableStoreActionNames = {
        startNewSearch: `${prefix}/startNewSearch`,
        continueSearch: `${prefix}/continueSearch`,
    }

    const mutationNames: PageableStoreMutationNames = {
        resetResourceList: `${prefix}/resetResourceList`,
        replenishResourceList: `${prefix}/replenishResourceList`,
        activateFetchPromise: `${prefix}/activateFetchPromise`,
        deactivateFetchPromise: `${prefix}/deactivateFetchPromise`,
    }

    const getterNames: PageableStoreGetterNames = {
        resourceList: `${prefix}/resourceList`,
        resourceNumber: `${prefix}/resourceNumber`,
        fetchInProgress: `${prefix}/fetchInProgress`,
    }

    function newFetchFnInstance(
        state: PageableState<Resource, Filter>,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        fetchPage: (filter: Partial<Filter>, page: Page) => Promise<Page>,
        commit: any,
        filter: Partial<Filter>,
        page: Page,
    ) {
        const fetchFnInstance: () => Promise<MaybeCurrentPageMutation> =
            () => fetchPage(filter, page)
                // eslint-disable-next-line @typescript-eslint/no-shadow
                .then(page => {
                    // eslint-disable-next-line promise/always-return
                    if (state.activeFetchPromise && (state.activeFetchPromise.fetchFn == fetchFnInstance)) {

                        commit(
                            (page.pageNumber === 0)
                                ? mutationNames.resetResourceList
                                : mutationNames.replenishResourceList,
                            page
                        )
                        commit(mutationNames.deactivateFetchPromise)
                    } else {
                        console.debug("Fetch was cancelled")
                    }
                })
        return fetchFnInstance
    }

    const actions: ActionTree<PageableState<Resource, Filter>, any> = {
        [actionNames.startNewSearch]({ commit, state }, { filter } = { filter: {} }) {
            const newSearchFn =
                newFetchFnInstance(
                    state,
                    fetchPage,
                    commit,
                    {
                        ...removeNullProperties(filter),
                        ...baseFilter
                    },
                    resetPagination(state.currentPage)
                )
            commit(mutationNames.activateFetchPromise, newSearchFn)
            return newSearchFn()
        },
        [actionNames.continueSearch]({ commit, state }) {
            const searchContinuationFn =
                newFetchFnInstance(state, fetchPage, commit, state.currentPage.filter, nextPage(state.currentPage))
            commit(mutationNames.activateFetchPromise, searchContinuationFn)
            return searchContinuationFn()
        },
    }

    const getters: MutationTree<PageableState<Resource, Filter>> = {
        [getterNames.resourceList]({ resourceList }): Array<Resource> {
            return resourceList
        },
        [getterNames.resourceNumber]({ currentPage }): number {
            return currentPage.resourceTotalNumber
        },
        [getterNames.fetchInProgress]({ activeFetchPromise }): boolean {
            return activeFetchPromise !== null
        }
    }

    const mutations = {
        [mutationNames.activateFetchPromise](state: PageableState<Resource, Filter>, fetchFn: () => Promise<MaybeCurrentPageMutation>) {
            state.activeFetchPromise = { fetchFn }
        },
        [mutationNames.deactivateFetchPromise](state: PageableState<Resource, Filter>) {
            state.activeFetchPromise = null
        },
        [mutationNames.resetResourceList](state: PageableState<Resource, Filter>, page: FilteredPage<Resource, Filter>) {
            state.resourceList = [ ...page.resourceList ]
            state.currentPage = page
        },
        [mutationNames.replenishResourceList](state: PageableState<Resource, Filter>, page: FilteredPage<Resource, Filter>) {
            state.resourceList = [ ...state.resourceList, ...page.resourceList ]
            state.currentPage = page
        },
        resetState(state: PageableState<Resource, Filter>) {
            console.debug("State reset")
            Object.assign(state, createState<Resource, Filter>(baseFilter))
        },
    }

    return {
        module: {
            state: createState<Resource, Filter>(baseFilter),
            actions,
            getters,
            mutations,
        },
        actionNames,
        getterNames,
    }
}

export function createIdentifiableResourcePageableStore<Resource extends Identifiable<unknown>, Filter>(
    resourceName: string,
    baseFilter?: Partial<Filter>,
    prefix?: string
): PageableStoreModule<Resource, Filter> & { actionNames: { updateResource: string }} {

    const { actionNames, getterNames, module } = createPageableStore<Resource, Filter>(resourceName, baseFilter, prefix)
    /*
     There are some cases when I need separate stores with the same resource.
     e.g. to have separate states for runs with different statuses. See RunsDataExport view.
    */
    prefix = prefix ? `${resourceName}-${prefix}` : resourceName

    const updateResourceActionName = `${prefix}/updateResource`
    const updateResourceMutationName = `${prefix}/updateResource`
    const mutations: MutationTree<PageableState<Resource, Filter>> = {
        [updateResourceMutationName](state: PageableState<Resource, Filter>, resource: Resource) {
            const resourceToUpdateIndex = state.resourceList.findIndex(it => resource.id == it.id)
            state.resourceList = [
                ...state.resourceList.slice(0, resourceToUpdateIndex),
                resource,
                ...state.resourceList.slice(resourceToUpdateIndex + 1),
            ]
        },
    }

    const actions: ActionTree<PageableState<Resource, Filter>, any> = {
        /*
         Something like optimistic UI, we don't want to refetch all pages
         if one element has been changed
        */
        [updateResourceActionName]({ commit }, resource: Resource) {
            commit(updateResourceMutationName, resource)
        }
    }

    return {
        actionNames: {
            ...actionNames,
            updateResource: updateResourceActionName,
        },
        getterNames,
        module: {
            actions: {
                ...module.actions,
                ...actions,
            },
            mutations: {
                ...module.mutations,
                ...mutations,
            },
            getters: module.getters,
            state: module.state
        }
    }
}
