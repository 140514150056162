import ld, { zip } from "lodash"
import { GLString, ResolutionDraftNewAlleles } from "@/utils/analysis"


export type Locus = "A" | "B" | "C" | "DQB1" | "DRB1"
export const LOCI: Array<Locus> = [ "A", "B", "C", "DQB1", "DRB1" ]
export const MISSING_ALLELE_PLACEHOLDER = "ND"
export const NEW_ALLELE_PLACEHOLDER = "NEW"
export const GENOTYPE_PARTS_SEPARATOR = "|"
export const ALLELES_SEPARATOR = "+"
export const ALLELE_PARTS_SEPARATOR = "/"
export const ALLELE_FIELDS_SEPARATOR = ":"
export type AlleleResolution = 1 | 2 | 3 | 4
export const FUNCTIONAL_ANNOTATIONS = [ "A", "C", "L", "N", "Q", "S" ]


export enum ComparisonResult {
    LESS = -1,
    EQUAL = 0,
    GREATER = 1
}

export class Genotype extends Object {

    private constructor(
        public genotypeParts: Array<[ Allele, Allele ]>,
    ) {
        super()
    }

    static undefinedGenotype() {
        return new Genotype([ Genotype.undefinedGenotypePart() ])
    }

    get isUndefined() {
        return this.genotypeParts.every(it => it[0].isUndefined && it[1].isUndefined)
    }

    get isAmbiguous() {
        return this.genotypeParts.length > 1
    }

    get partsAsGenotypes(): Genotype[] {
        return this.genotypeParts.map(it => new Genotype([ it ]))
    }

    toGLString() {
        // Genotype parts are deliberately left unsorted, cause we don't know how to sort them
        return this.genotypeParts
            .map(element => sortAlleles(element))
            .map(([ firstAllele, secondAllele ]) => `${firstAllele.toGLString()}${ALLELES_SEPARATOR}${secondAllele.toGLString()}`)
            .join(GENOTYPE_PARTS_SEPARATOR)
    }

    /**
     * Parses allele from GLString
     *
     * Actually we treat GL spec loosely https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3715123/ (at least we omit gene prefixes to save space)
     * Besides, there is no information about homozygous genotypes representation in spec.
     *
     * We want to display "homozygous" genotypes as "allele + ND"
     * due to https://efi-web.org/committees/standards-committee Version 8.0 spec F1.2.3.1
     * cause we actually not so confident about homozygosity. But for some reason pipeline is
     * confident giving us two equal alleles. For now it will be a workaround here treating
     * genotypes with equal alleles and genotypes with second ND allele the same way.
     * But what if we want to display real, I mean REAL, homozygous genotypes?
     * Our components will not be ready for that.
     *
     * At least I think we should start with correcting our pipeline to be less confident.
     * And MAYBE then think about real homozygous genotype display.
     * Main problem lies in support application by the way.
     *
     * @param s string to parse allele from
     */
    static fromGLString(s: string | null) {
        return s
            ? new Genotype(s.split(GENOTYPE_PARTS_SEPARATOR).map(genotypePart => {
                const [ firstAlleleGLString, secondAlleleGLString ] = genotypePart.trim()
                    .split(ALLELES_SEPARATOR)
                    .map(it => it.trim())
                /*
                 TODO: [@aslepchenkov 05.11.2019] VH-142. Dirty hack or not dirty hack?
                 Have been moved here from GenotypePart component, to ensure that genotypes are displayed
                 the same way across application (e.g. to fix VH-328).
                 I thought that replacing second allele with ND in homozygous genotype is
                 pure matter of display, since we store both alleles in pipeline, but now it seems less so.
                 Maybe it's time for pipeline to change...

                 [@bbatanov 26.02.2021] Tweaked so that the code does not reset a new allele when there are two of them in the same genotype
                */
                if (firstAlleleGLString === secondAlleleGLString && firstAlleleGLString !== NEW_ALLELE_PLACEHOLDER) {
                    return [ Allele.fromGLString(firstAlleleGLString), Allele.undefinedAllele() ]
                }
                return [ Allele.fromGLString(firstAlleleGLString), Allele.fromGLString(secondAlleleGLString) ]
            }))
            : Genotype.undefinedGenotype()
    }

    * [Symbol.iterator]() {
        for (const genotypePart of this.genotypeParts) {
            yield genotypePart
        }
    }

    get length() {
        return this.genotypeParts.length
    }

    toggleAlleleInNewGenotype(allele: string, genotypePartIndex: number, alleleIndex: number): Genotype {
        const possibleIndices = this.findIndicesWhereAlleleIsAlreadyPresent(allele)
        if (possibleIndices.length && allele !== NEW_ALLELE_PLACEHOLDER) {
            possibleIndices
                .filter(([ possibleGenotypePartIndex, _ ]) => possibleGenotypePartIndex === genotypePartIndex)
                // eslint-disable-next-line @typescript-eslint/no-shadow
                .map(possibleIndices => this.removeAllele(possibleIndices[0], possibleIndices[1], allele))

            const isAlleleWereAlreadySelectedAtTheSamePlace = possibleIndices.some(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                possibleIndices => possibleIndices[0] === genotypePartIndex && possibleIndices[1] === alleleIndex
            )
            if (isAlleleWereAlreadySelectedAtTheSamePlace) {
                return this
            }
        }

        this.addAllele(genotypePartIndex, alleleIndex, allele)
        return this
    }

    addNewGenotypePart(): Genotype {
        this.genotypeParts.push(Genotype.undefinedGenotypePart())
        return this
    }

    private mutateAllele(
        mutation: "removeAllelePart" | "addAllelePart",
        genotypePartIndex: number,
        alleleIndex: number,
        allele: string
    ) {
        const newAlleles: [ Allele, Allele ] = [ Allele.undefinedAllele(), Allele.undefinedAllele() ]
        newAlleles[alleleIndex] = this.genotypeParts[genotypePartIndex][alleleIndex][mutation](allele)
        newAlleles[1 - alleleIndex] = this.genotypeParts[genotypePartIndex][1 - alleleIndex]
        this.genotypeParts[genotypePartIndex] = newAlleles
    }

    private removeAllele(genotypePartIndex: number, alleleIndex: number, allele: string) {
        this.mutateAllele("removeAllelePart", genotypePartIndex, alleleIndex, allele)
    }

    private addAllele(genotypePartIndex: number, alleleIndex: number, allele: string) {
        this.mutateAllele("addAllelePart", genotypePartIndex, alleleIndex, allele)
    }

    private findIndicesWhereAlleleIsAlreadyPresent(allele: string): Array<[ number, number ]> {
        return this.genotypeParts.map((genotypePart, genotypePartIndex) => {
            const alleleIndex = genotypePart.findIndex(it => it.includes(allele))
            return <[ number, number ]>[ genotypePartIndex, alleleIndex ]
        }).filter(([ _, alleleIndex ]) => alleleIndex !== -1)
    }

    private static undefinedGenotypePart(): [ Allele, Allele ] {
        return [ Allele.undefinedAllele(), Allele.undefinedAllele() ]
    }
}


export class Allele {

    constructor(
        public parts: Array<string>,
    ) {
    }

    // Must be of the same resolution
    intersects(allele: Allele): boolean {
        return this.parts.some(it => allele.parts.includes(it))
    }

    includes(allele: Allele | string): boolean {
        const testAllelePart = (allelePartToTest: string) => {
            const pattern = new RegExp(`${allelePartToTest}(:|$)`)
            return this.parts.some(it => pattern.test(it))
        }

        let alleleParts: Array<string>
        if (allele instanceof Allele) {
            alleleParts = allele.parts
        } else {
            alleleParts = allele.split(ALLELE_PARTS_SEPARATOR)
        }
        // TODO: [@aslepchenkov 11.03.2020] Think about some
        return alleleParts.some(it => testAllelePart(it))
    }

    addAllelePart(part: string): Allele {
        if(part !== NEW_ALLELE_PLACEHOLDER) {
            this.parts = this.parts.concat(
                part.split(ALLELE_PARTS_SEPARATOR)
                    .filter(it => !this.includes(it))
            )
        } else {
            this.parts = []
            this.parts.push(part)
        }
        this.parts.sort(compareUnambiguousAlleles)
        return this
    }

    removeAllelePart(part: string): Allele {
        if (part.includes(ALLELE_PARTS_SEPARATOR)) {
            // TODO: [@aslepchenkov 10.03.2020] Clean
            const partSubParts = part.split(ALLELE_PARTS_SEPARATOR)
            this.parts = this.parts.filter(it => !partSubParts.includes(it))
        } else {
            this.parts = this.parts.filter(it => it !== part)
        }
        return this
    }

    * [Symbol.iterator]() {
        for (const allelePart of this.parts) {
            yield allelePart
        }
    }

    static undefinedAllele() {
        return new Allele([])
    }

    static fromGLString(s: string): Allele {
        return s === MISSING_ALLELE_PLACEHOLDER
            ? Allele.undefinedAllele()
            : new Allele(s.split(ALLELE_PARTS_SEPARATOR).map(it => it.trim()))
    }

    get isUndefined() {
        return !this.parts.length
    }

    get isNew() {
        return this.parts.length === 1 && this.parts[0] === NEW_ALLELE_PLACEHOLDER
    }

    get isAmbiguous() {
        return this.parts.length > 1
    }

    get resolution(): AlleleResolution {
        // Match can be used, but code won't become simpler
        return Math.min(...this.parts.map(allelePart => allelePart.split(ALLELE_FIELDS_SEPARATOR).length)) as AlleleResolution
    }

    toGLString(): string {
        return this.isUndefined
            ? MISSING_ALLELE_PLACEHOLDER
            : this.parts.join(ALLELE_PARTS_SEPARATOR)
    }
}


export function reduceResolution(allele: string, newResolution: AlleleResolution) {
    // TODO: [@aslepchenkov 29.01.2020] Maybe optimize without spilt through substring?
    return allele.split(ALLELE_FIELDS_SEPARATOR).slice(0, newResolution).join(ALLELE_FIELDS_SEPARATOR)
}


/**
 * Check whether `alleleToSearchFor` is included into `allele`
 *
 * It's better than just use `allele.includes(alleleToSearchFor)` due to some corner cases.
 * e.g. `allele = "01:02:03/03:04:06` and we search for "04:06", using simple includes we will
 * return true in this case. However there is no "04:06" in this allele.
 *
 * @param allele Can be ambiguous
 * @param alleleToSearchFor Must be unambiguous
 */
export function alleleIncludes(allele: string, alleleToSearchFor: string): boolean {
    return Allele.fromGLString(allele).includes(alleleToSearchFor)
}


/**
 * Tests alleles for equality
 *
 * Alleles are considered equal if they have the same parts in the same order with the same resolution
 */
export function allelesEqual(firstAllele: Allele, secondAllele: Allele): boolean {
    return ld.zip(firstAllele.parts, secondAllele.parts).every(([ a, b ]) => a === b)
}

/*
* Perhaps JSON.stringify the method is not the best solution.
*/
export function newAllelesEqual(firstAllele: ResolutionDraftNewAlleles, secondAllele: ResolutionDraftNewAlleles, locus: Locus): boolean {
    return JSON.stringify(firstAllele.manuallyCreatedAlleles[locus]) === JSON.stringify(secondAllele.manuallyCreatedAlleles[locus])
}

export function genotypesEqual(firstGenotype: Genotype, secondGenotype: Genotype): boolean {
    /*
     WARNING don't modify this. Implementation is crucible after PAR-350.
      Genotypes could have different order of alleles and comparison should take this fact into consideration.
      `toGLString` applies sort over alleles so genotypes strings become equal/
    */
    return firstGenotype.toGLString() === secondGenotype.toGLString()
}

export function compareAlleles(firstAllele: Allele, secondAllele: Allele) {
    // First three conditions are workaround (maybe adequate solution) for PAR-393, undefined allele should always be less than not undefined
    if (firstAllele.isUndefined && secondAllele.isUndefined) {
        return ComparisonResult.EQUAL
    }
    /*
    * FIXME: [bbatanov 26.02.21] A crutch so that the ND+NEW genotype is not filtered and leaves in there form.
    *         Genotype type ND+NEW cannot be and 01:92+02:03 | ND+ND. Solutions: validation, filter
    *        [bbatanov 14.04.21] Simplistically, if in the first position or in the second a new allele, then we return EQUAL
    */
    if(firstAllele.isNew || secondAllele.isNew) {
        return ComparisonResult.EQUAL
    }
    if (firstAllele.isUndefined) {
        return ComparisonResult.GREATER
    }
    if (secondAllele.isUndefined) {
        return ComparisonResult.LESS
    }

    for (const [ firstAllelePart, secondAllelePart ] of zip(firstAllele.parts, secondAllele.parts)) {
        if (firstAllelePart === undefined) {
            return ComparisonResult.LESS
        } else if (secondAllelePart === undefined) {
            return ComparisonResult.GREATER
        } else {
            const res = compareUnambiguousAlleles(firstAllelePart, secondAllelePart)
            switch (res) {
                case ComparisonResult.LESS:
                case ComparisonResult.GREATER:
                    return res
            }
        }
    }
    return ComparisonResult.EQUAL
}

export function compareUnambiguousAlleles(firstAllele: GLString, secondAllele: GLString): ComparisonResult {
    for (const [ firstAlleleField, secondAlleleField ] of zip(firstAllele.split(":"), secondAllele.split(":"))
        // Nice trick js. 114N is parsed as 114 and it's actually what I want
        // TODO: [@aslepchenkov 16.06.2020] Check octal things https://stackoverflow.com/questions/4090518/what-is-the-difference-between-parseint-and-number
        .map(([ f, s ]) => [ f ? Number.parseInt(f) : undefined, s ? Number.parseInt(s) : undefined ])) {

        if (firstAlleleField && secondAlleleField) {
            if (firstAlleleField < secondAlleleField) {
                return ComparisonResult.LESS
            } else if (firstAlleleField > secondAlleleField) {
                return ComparisonResult.GREATER
            }
        }
    }
    return ComparisonResult.EQUAL
}

export function sortAlleles(alleles: Allele[]): Allele[] {
    const allelesCopy = [ ...alleles ]
    allelesCopy.sort(compareAlleles)
    return allelesCopy
}

export function getGenotypeAlleleParts(genotype: Genotype): Array<string> {
    // TODO: [@aslepchenkov 10.06.2020] Should parts be deduplicated?
    return genotype.genotypeParts
        .flatMap(genotypePart => genotypePart[0].parts.concat(genotypePart[1].parts))
}
