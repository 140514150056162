export const modalMixin = {
    methods: {
        openModal(modalName) {
            this.modals[modalName].opened = true
            if (this.modals[modalName].onOpen) {
                this.modals[modalName].onOpen()
            }
            this.removeModalMixinKeydownListener = setKeydownListener({
                "Escape": () => this.closeModal(modalName)
            })
        },
        closeModal(modalName) {
            if (this.modals[modalName].onClose) {
                this.modals[modalName].onClose()
            }
            this.modals[modalName].opened = false
            this.removeModalMixinKeydownListener()
        },
    },
}

function setKeydownListener(callbacks) {

    function keydownListener(event) {
        event.stopPropagation()
        if (callbacks[event.code]) {
            callbacks[event.code]()
        }
    }

    window.addEventListener("keydown", keydownListener, { capture: true })
    return () => window.removeEventListener("keydown", keydownListener, { capture: true })
}

