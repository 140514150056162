<template>
    <div :title="$t('analysisStatuses.AWAITING_REVIEW')"
         class="progress-bar ml-auto">
        <div :style="completedProgressBarChunkStyle"
             :title="$t('analysisStatuses.COMPLETED')"
             class="progress-bar-chunk"
             style="left: 0; background-color: var(--green); opacity: 1"></div>
        <div :style="awaitingApproveProgressBarChunkStyle"
             :title="$t('analysisStatuses.AWAITING_APPROVE')"
             class="progress-bar-chunk"
             style="background-color: var(--yellow)"
        ></div>
        <div :style="inSupportProgressBarChunkStyle"
             :title="$t('analysisStatuses.IN_SUPPORT')"
             class="progress-bar-chunk"
             style="background-color: var(--grey-400)"></div>
    </div>
</template>

<script>
    import { AnalysisStatuses } from "@/enums/analysis-status"

    export default {
        name: "sample-statuses-progress-bar",
        props: {
            sampleStatusesStatistics: Object
        },
        computed: {
            completedProgressBarChunkStyle() {
                return {
                    width: `${this.sampleStatusesStatistics[AnalysisStatuses.COMPLETED] * 100}%`
                }
            },
            inSupportProgressBarChunkStyle() {
                return {
                    right: 0,
                    width: `${this.sampleStatusesStatistics[AnalysisStatuses.IN_SUPPORT] * 100}%`
                }
            },
            awaitingApproveProgressBarChunkStyle() {
                return {
                    right: this.sampleStatusesStatistics[AnalysisStatuses.IN_SUPPORT] * 100,
                    width: `${this.sampleStatusesStatistics[AnalysisStatuses.AWAITING_APPROVE] * 100}%`
                }
            },
        }
    }
</script>

<style scoped>
.progress-bar {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px 4px rgba(0, 0, 0, .05), 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar-chunk {
    position: absolute;
    height: 100%;
    top: 0;
    opacity: .7;
    transition: opacity .2s;
}

.progress-bar-chunk:hover {
    opacity: 1;
}
</style>
