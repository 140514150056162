<template>
    <div class="analysis-card"
         :key="analysis.id"
         v-if="analysis">
        <p-modal v-if="qcDetailsModal">
            <div class="analysis-details-modal">
                <AnalysisDetails :analysis="analysisWithNewQCMetrics"></AnalysisDetails>
                <button @click="closeQcDetailsModal"
                        class="md-btn-text ml-auto"
                        style="font-size: 1.6rem; color: royalblue;">{{ $t("buttons.close") }}
                </button>
            </div>
        </p-modal>
        <div class="analysis-card--description">
            <span id="sequencing-run-date">{{ $d(analysis.sequencingRunDate) }}</span>
            <h1 class="mb-1"
                id="analysis-name"
                style="display: inline; text-align: center; word-break: break-all">{{ analysis.name }}</h1>
            <button @click="openQcDetailsModal"
                    id="qc-details-btn"
                    class="md-btn-text analysis-card--details-btn"
                    style="justify-self: right"
            >
                {{ $t("qcDetails") }}
            </button>

            <h2 class="ellipsis"
                id="run-name"
                style="font-weight: 400; display: inline">{{ analysis.sequencingRunId }}</h2>
            <h2 style="font-weight: 400; display: inline;
                                   justify-self: center"
                id="sample-id-in-run"
            >S{{ analysis.runId }}</h2>
            <div style="justify-self: right; margin-right: 1.5rem">
                <AnalysisLotBadge id="lot-badge"
                          :control-sample-status="analysis.reagentKitLotQcStatus"
                          :lot-name="analysis.reagentKitLotName"
                          :configured-pipeline-name="analysis.configuredPipelineName"
                          :imgt-version="analysis.configuredPipelineImgtVersion"
                />
            </div>
        </div>
        <GenotypesComparisonTable :new-genotypes="newGenotypes"
                                  :old-genotypes="oldGenotypes"
                                  :annotations="mergedAnnotations || {}"
                                  :analysis="analysis"
                                  class="analysis-card--genotypes-table"
        />
        <ImgtVersionLink :imgt-version="analysis.configuredPipelineImgtVersion"/>
    </div>
</template>

<script>
    import { Genotype, LOCI } from "@/genotype"
    import { hasSavedGenotype, parseRawGenotypes } from "@/utils/analysis"
    import { mergeAnnotations, constructNewProperties } from "@/utils/support"
    import AnalysisLotBadge from "@/components/analysis/lot-badge"
    import AnalysisDetails from "@/components/analysis/details"
    import { modalMixin } from "@/mixins/modal-mixin"
    import ImgtVersionLink from "@/components/imgt-version-link"
    import GenotypesComparisonTable from "@/components/analysis/genotypes-comparison-table"

    const QC_DETAILS_MODAL = "qcDetails"

    export default {
        name: "ApproveAnalysisCard",
        components: { ImgtVersionLink, AnalysisDetails, AnalysisLotBadge, GenotypesComparisonTable },
        mixins: [ modalMixin ],
        props: {
            analysis: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                modals: {
                    [QC_DETAILS_MODAL]: {
                        opened: false,
                        onOpen: () => this.$emit("modal-opened"),
                        onClose: () => this.$emit("modal-closed")
                    }
                },
            }
        },
        computed: {
            newGenotypes() {
                return Object.fromEntries(
                    LOCI.map(
                        locus =>
                            ([ locus, hasSavedGenotype(this.analysis, locus)
                                ? Genotype.fromGLString(this.analysis.maybeResolutionDraft.genotypes[locus])
                                : null ])
                    )
                )
            },
            oldGenotypes() {
                return parseRawGenotypes(this.analysis.result.genotypes)
            },
            qcDetailsModal() {
                return this.modals[QC_DETAILS_MODAL].opened
            },
            mergedAnnotations() {
                return mergeAnnotations(
                    this.analysis.annotations ?? {},
                    this.analysis.maybeResolutionDraft.annotations ?? {}
                )
            },
            analysisWithNewQCMetrics() {
                return {
                    ...this.analysis,
                    result: constructNewProperties(this.analysis)
                }
            },
        },
        methods: {
            openQcDetailsModal() {
                this.openModal(QC_DETAILS_MODAL)
            },
            closeQcDetailsModal() {
                this.closeModal(QC_DETAILS_MODAL)
            }
        }
    }
</script>

<style scoped>
    .analysis-card {
        padding: 2rem;
        border: 1px solid var(--grey-400);
        border-radius: 4px;
        margin: 1rem;
        width: 65rem;
        position: relative;
    }

    .analysis-card--description {
        align-self: start;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-row-gap: 1rem;
        align-items: center;
        margin-bottom: 4rem;
    }

    .analysis-card--details-btn {
        color: royalblue;
    }

    .analysis-card--genotypes-table {
        margin-top: 2rem;
        font-size: 1.8rem;
        text-align: center;
        width: 100%;
    }

    .analysis-details-modal {
        padding: 2em 2em 1em;
        width: fit-content;
    }
</style>
