<template>
    <p
       id="imgt-version"
       class="mt-1"
       style="text-align: center;">
        <a href="https://www.ebi.ac.uk/ipd/imgt/hla/release/"
           target="_blank"
           class="grey-link"
           rel="noreferrer noopener">{{ imgtVersionLink }}</a>
    </p>
</template>

<script>
    export default {
        name: "ImgtVersionLink",
        props: {
            imgtVersion: String
        },
        computed: {
            imgtVersionLink() {
                return `IPD-IMGT/HLA ${this.imgtVersion}`
            }
        }
    }
</script>

<style scoped>
    .grey-link {
        color: var(--grey-400);
        font-size: 1.4rem;
    }

    .grey-link:hover {
        color: var(--grey-500);
    }
</style>
