import { debounce } from "lodash"

export const compositeSearchMixin = {
    props: {},
    watch: {
        filters: {
            handler(newValue) {
                this.tempFilters = { ...newValue }
                this.liveSearch = newValue[this.liveSearchFilterName]
                this.startNewSearch(newValue)
            },
            deep: true
        },
        liveSearch: {
            handler(newValue) {
                if (newValue !== this.filters[this.liveSearchFilterName]) {
                    this.debouncedUpdateFilters({ ...this.filters, [this.liveSearchFilterName]: newValue })
                }
            }
        }
    },
    data() {
        return {
            liveSearch: null,
            liveSearchFilterName: null,
            filters: {},
            tempFilters: {},
            compositeSearchDropdownShown: false
        }
    },
    computed: {
        filtersHaveSomeValue() {
            return Object.values(this.filters).some(it => Boolean(it))
        }
    },
    methods: {
        openCompositeSearchDropdown() {
            this.compositeSearchDropdownShown = true
            // Lil Trick - https://stackoverflow.com/a/46041731/6540091
            // Element appears asynchronously, so $refs may not exist
            this.$nextTick(() => {
                if (this.$refs.dropdown) {
                    const firstInput = this.$refs.dropdown.querySelector("input")
                    if (firstInput) {
                        firstInput.focus()
                    } else {
                        console.error("Dropdown has no element to be focused")
                    }
                }
            })
        },
        search() {
            this.filters = { ...this.tempFilters }
            this.closeCompositeSearchDropdown()
        },
        resetFilters() {
            this.filters = {}
        },
        resetTempFilters() {
            this.tempFilters = { ...this.filters }
        },
        toggleCompositeSearchDropdown() {
            this.compositeSearchDropdownShown
                ? this.closeCompositeSearchDropdown()
                : this.openCompositeSearchDropdown()
        },
        onCompositeSearchDropdownFocusout(event) {
            const clickOutsideDropdown = !this.$refs.dropdown.contains(event.relatedTarget)
            // Click on showDropdownButton should be ignored, cause it already causes dropdown state to toggle
            const showDropdownButtonClicked = this.$refs.showDropdownButton === event.relatedTarget
            if (clickOutsideDropdown && !showDropdownButtonClicked) {
                this.closeCompositeSearchDropdown()
            }
        },
        closeCompositeSearchDropdown() {
            this.compositeSearchDropdownShown = false
            this.resetTempFilters()
        },
    },
    created() {
        this.debouncedUpdateFilters = debounce((newFilters) => this.filters = newFilters, 500)
    }
}
