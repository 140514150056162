import { Circler } from "@/interactive-docs/utils"

export const circler = {
    data() {
        return {
            circler: new Circler("border: 2px solid var(--yellow); border-radius: 8px")
        }
    },
    destroyed() {
        this.circler.onDestroy()
    }
}

export const tooltipActivator = {
    methods: {
        toggleTooltip(query) {
            const el = document.querySelector(query)
            el.style.display = el.style.display === "none"
                ? "block"
                : "none"
        },
        showTooltip(query) {
            document.querySelector(query).style.display = "block"
        },
        hideTooltip(query) {
            document.querySelector(query).style.display = "none"
        }
    },
}
