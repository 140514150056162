<template>
    <svg
      v-if="getIconName === 'PARSEQ_ICON'"
      class="parallele-logo"
      xml:space="preserve"
      style="shape-rendering:geometricPrecision; text-rendering: geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 653 699"
      id="logo"
      >
      <g style="fill:#ffffff">
            <path
                    class="fil0"
                    d="M175 160c0,28 -9,49 -27,64 -18,15 -44,22 -77,22l-28 0 0 99 -43 0 0 -265 76 0c33,0 58,7 74,21 17,13 25,33 25,59zm-132 50l23 0c22,0 39,-4 49,-12 10,-8 16,-20 16,-36 0,-15 -5,-27 -14,-34 -10,-8 -24,-11 -44,-11l-30 0 0 93zm331 135l-27 -73 -101 0 -26 73 -45 0 99 -266 47 0 99 266 -46 0zm-38 -111l-25 -72c-2,-5 -4,-12 -7,-23 -4,-10 -6,-18 -7,-22 -3,14 -8,31 -14,48l-24 69 77 0zm162 -31l30 0c20,0 35,-3 44,-11 9,-7 13,-18 13,-33 0,-15 -5,-26 -14,-32 -10,-7 -25,-10 -44,-10l-29 0 0 86zm0 36l0 106 -43 0 0 -265 74 0c35,0 60,7 76,20 17,12 25,32 25,58 0,32 -17,56 -52,70l75 117 -49 0 -63 -106 -43 0z"
                    style="fill:#ffffff"/>
            <path
                    class="fil0"
                    d="M108 620l-3 -25 -1 0c-8,10 -16,17 -25,22 -8,4 -18,6 -29,6 -16,0 -28,-4 -36,-12 -9,-8 -13,-19 -13,-33 0,-15 6,-27 19,-36 13,-8 31,-13 55,-13l30 -1 0 -10c0,-15 -3,-26 -9,-34 -6,-8 -16,-12 -29,-12 -15,0 -30,5 -45,13l-6 -13c18,-8 35,-12 51,-12 18,0 31,5 39,13 9,9 13,23 13,42l0 105 -11 0zm-57 -10c16,0 30,-5 39,-15 10,-9 15,-22 15,-40l0 -15 -28 1c-22,1 -38,5 -47,10 -9,6 -14,15 -14,28 0,10 3,17 9,23 6,5 15,8 26,8zm133 10l-14 0 0 -225 14 0 0 225zm67 0l-14 0 0 -225 14 0 0 225zm116 3c-23,0 -40,-7 -53,-21 -13,-14 -19,-34 -19,-59 0,-25 6,-46 18,-61 12,-15 29,-22 50,-22 19,0 33,6 44,19 11,13 16,30 16,52l0 12 -113 0c0,22 5,38 15,50 10,11 24,17 42,17 9,0 17,-1 24,-2 7,-1 15,-4 26,-8l0 13c-9,4 -17,6 -25,8 -7,1 -16,2 -25,2zm-4 -151c-15,0 -27,5 -36,15 -10,10 -15,25 -16,44l97 0c0,-19 -4,-33 -12,-43 -8,-11 -19,-16 -33,-16zm118 148l-15 0 0 -225 15 0 0 225zm116 3c-23,0 -41,-7 -54,-21 -13,-14 -19,-34 -19,-59 0,-25 6,-46 19,-61 12,-15 29,-22 50,-22 18,0 33,6 43,19 11,13 16,30 16,52l0 12 -113 0c0,22 5,38 15,50 10,11 24,17 43,17 9,0 17,-1 23,-2 7,-1 16,-4 26,-8l0 13c-9,4 -17,6 -24,8 -8,1 -16,2 -25,2zm-4 -151c-16,0 -28,5 -37,15 -9,10 -14,25 -16,44l97 0c0,-19 -4,-33 -12,-43 -8,-11 -19,-16 -32,-16z"
                    style="fill:#ffffff"/>
            <rect
                    class="fil1"
                    y="17"
                    width="653.188"
                    height="14.0111"
                    rx="9"
                    ry="4"
                    style="fill:#ffffff"/>
            <rect
                    class="fil2"
                    x="152"
                    width="48.7466"
                    height="48.7466"
                    rx="7"
                    ry="7"
                    style="fill:#ffffff"/>
            <rect
                    class="fil1"
                    y="668"
                    width="653.188"
                    height="14.0111"
                    rx="9"
                    ry="4"
                    style="fill:#ffffff"/>
            <rect
                    class="fil3"
                    x="449"
                    y="651"
                    width="48.7466"
                    height="48.7466"
                    rx="7"
                    ry="7"
                    style="fill:#ffffff"/>
        </g>
    </svg>
    <svg v-else-if="getIconName === 'EPIVALUE_ICON'" class="epivalue-logo" fill="white" viewBox="138 198 1400 615">
        <g>
            <path
                d="m 627.5967,583.2284 h 51.0236 c 18.7087,0 34.0158,15.3071 34.0158,34.0158 v 192.7559 h -51.0236 c -18.7087,0 -34.0158,-15.3071 -34.0158,-34.0157 v -73.7008 c 0,-18.7087 15.3071,-34.0158 34.0157,-34.0158 v 0 h 22.6772 v -48.189 c 0,-4.6771 -3.8268,-8.5039 -8.504,-8.5039 h -48.1889 z m 36.8504,113.3858 h 19.8425 v 85.0394 h -19.8425 c -4.6772,0 -8.5039,-3.8268 -8.5039,-8.504 v -68.0315 c 0,-4.6771 3.8267,-8.5039 8.5039,-8.5039 z m 388.3461,-113.3858 v 188.5039 c 0,23.3859 -19.1339,42.5198 -42.5198,42.5198 v 0 c -23.3858,0 -42.5196,-19.1339 -42.5196,-42.5198 V 583.2284 h 28.3464 v 186.378 c 0,7.7953 6.378,14.1732 14.1732,14.1732 h 10e-5 c 7.7953,0 14.1732,-6.3779 14.1732,-14.1732 V 583.2284 Z M 826.0217,810.0001 h 28.3465 V 526.5355 h -28.3465 z m 382.6767,-231.0236 v 0 c 23.3859,0 42.5198,19.1338 42.5198,42.5196 v 75.1182 h -15.9049 -12.4416 -28.3465 v 72.9921 c 0,7.7953 6.378,14.1732 14.1732,14.1732 h 10e-5 c 7.7953,0 14.1732,-6.3779 14.1732,-14.1732 v -44.6457 h 28.3465 v 46.7716 c 0,23.3859 -19.1339,42.5198 -42.5198,42.5198 v 0 c -23.3858,0 -42.5196,-19.1339 -42.5196,-42.5198 V 621.4961 c 0,-23.3858 19.1338,-42.5196 42.5196,-42.5196 z m 14.1733,89.2913 v -44.6457 c 0,-7.7953 -6.3779,-14.1732 -14.1732,-14.1732 h -10e-5 c -7.7952,0 -14.1732,6.3779 -14.1732,14.1732 v 44.6457 z M 478.778,814.4887 h 0.003 c 14.905,-0.001 26.8994,-10.4986 28.2354,-25.768 l 17.9782,-205.4923 h -28.4548 l -17.7565,202.9579 -0.005,0.0638 h -10e-5 v 0 l -0.006,-0.0638 -17.7565,-202.9579 h -28.4547 l 17.9782,205.4923 c 1.3359,15.2694 13.3303,25.7665 28.2354,25.768 z"
                style="fill: #0083b7; fill-rule: evenodd;"
            />
            <path
                d="m 854.3682,271.4173 h 19.8425 c 4.6771,0 8.5039,3.8268 8.5039,8.504 v 153.0708 c 0,4.6772 -3.8268,8.504 -8.5039,8.504 h -19.8425 z m -28.3465,255.1181 h 28.3465 v -56.6929 h 22.6771 c 18.7087,0 34.0158,-15.3071 34.0158,-34.0157 V 277.0866 c 0,-18.7086 -15.3071,-34.0157 -34.0158,-34.0157 h -22.6771 -28.3465 v 226.7716 z m 198.4249,-283.4645 h 28.3465 v 226.7716 h -28.3465 z m -340.1569,85.0396 v -44.6459 c 0,-7.7953 -6.378,-14.1733 -14.1732,-14.1733 v 0 c -7.7953,0 -14.1733,6.378 -14.1733,14.1733 v 44.6459 z m -14.1732,-89.2916 v 0 c 23.3858,0 42.5197,19.1339 42.5197,42.5197 v 75.1184 h -15.905 -12.4415 -28.3465 v 72.9921 c 0,7.7953 6.378,14.1729 14.1733,14.1729 v 0 c 7.7952,0 14.1732,-6.3776 14.1732,-14.1729 v -44.6457 h 28.3465 v 46.7714 c 0,23.3858 -19.1339,42.5197 -42.5197,42.5197 v 0 c -23.3859,0 -42.5197,-19.1339 -42.5197,-42.5197 V 281.3386 c 0,-23.3858 19.1338,-42.5197 42.5197,-42.5197 z"
                style="fill: #ed6b1e; fill-rule: evenodd;"
            />
            <path
                d="m 627.5963,923.3859 v 28.3465 h 425.1969 v -28.3465 z"
                style="fill: #ed6b1e; fill-rule: evenodd;"
            />
        </g>
    </svg>
</template>

<script>
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "BrandIcon",
        computed: {
            getIconName() {
                const { iconName } = useBrand()
                return iconName
            }
        }
    }
</script>
<style scoped>
.epivalue-logo {
    width: 30rem;
    height: 10rem;
    /* margin: 1.5rem 0 0 2rem; */
    color: white;
}
</style>
