import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
// @ts-ignore
import qs from "querystring"
import {
    AnalysisSupportRequestData,
    Eula,
    IllegalUser,
    LegalDocumentation,
    LotWithConfiguredPipeline,
    User,
} from "@/types"
import { Run } from "@/store/modules/runs"
import { Analysis, FunctionalAnnotations, GenotypingResolution } from "@/utils/analysis"
import { AnalysisStatuses } from "@/enums/analysis-status"
import { KnownAmbiguities, OldKnownAmbiguities, parseKnownAmbiguities } from "@/utils/ambiguities"
import { FilteredPage, Page } from "@/store/modules/pageable-store"
import { ArchiveAnalysisFilterCouldBe } from "@/store/modules/archive"
import { AnalysisFilterCouldBe } from "@/store/modules/samples"

import { Locus } from "./genotype"

// All errors are handled globally if not specified otherwise

export function fetchActualEula(): Promise<Eula> {
    return fetchData("/api/eula")
}

export function fetchEula(serialNumber: number): Promise<Eula | null> {
    return fetchData("/api/eula", { params: { serialNumber } })
}

export function acceptEula(serialNumber: number): Promise<void> {
    return axios.post("/api/eulaAdoption", {}, { params: { serialNumber } })
}

export async function fetchUser(): Promise<User | IllegalUser | null> {
    const data = await fetchData<any>("api/whoami")
    return {
        authorities: data.AUTHORITIES,
        fullName: data.FULL_NAME,
        language: data.LANGUAGE,
        organizationName: data.ORGANIZATION_NAME,
        organizationOfficialName: data.ORGANIZATION_OFFICIAL_NAME,
        name: data.USERNAME,
        licenseAgreement: {
            acceptedAt: new Date(data.EULA.maybeAdoptedEula.adoptionDate),
            serialNumber: data.EULA.maybeAdoptedEula.serialNumber
        },
        timeZone: data.TIMEZONE,
        maybeEmail: data.EMAIL,
        subscriptions: data.SUBSCRIPTIONS,
    }
}

export function login(name: string, password: string) {
    const data = new FormData()
    data.append("user", name)
    data.append("password", password)
    return axios.post(
        "api/login",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
    )
}

export function fetchDocumentationLinks(reagentKitLotName: string): Promise<LegalDocumentation> {
    return fetchData<{ volumes: Record<string, string>[]}>(
        "api/documentation",
        {
            params: { reagentKitLotName }
        }
    ).then(({ volumes }) => volumes.flatMap(volume => Object.entries(volume)))
}

export function setUserLanguage(language: "EN" | "RU"): Promise<AxiosResponse<void>> {
    return axios.put("api/preferences", undefined, {
        params: {
            language,
        },
    })
}

export function setUserEmail(email: string): Promise<AxiosResponse<void>> {
    return axios.put(
        "api/preferences",
        undefined,
        {
            params: {
                email,
            },
        },
    )
}

export function subscribeForEmailNotification(subscription: string): Promise<AxiosResponse<void>> {
    return axios.put(
        "api/preferences",
        undefined,
        {
            params: {
                subscription,
            },
        },
    )
}

export function unsubscribeFromEmailNotification(subscription: string): Promise<AxiosResponse<void>> {
    return axios.delete(
        "api/preferences",
        {
            params: {
                subscription,
            },
        },
    )
}

export function fetchRun(runId: string) {
    return fetchData(`api/sequencingRuns/${runId}`)
}

export function fetchRunAnalyses(runId: string) {
    return fetchData(`api/sequencingRuns/${runId}/analyses`)
}

export const sse = {
    notifications: "/api/sse/notifications"
}

export const supportEndpoints = {
    saveDraftResolution(run: Run, analysis: Analysis) {
        return axios.post(`api/support/sequencingRuns/${run.id}/analyses/${analysis.id}/resolutionDraft`, analysis.maybeResolutionDraft)
    },
    getNewAlleleTemporaryConsensusFastaLink(run: Run, analysis: Analysis, locus: Locus, genotypePartIndex: number, alleleIndex: number) {
        return axios.get(
            `api/support/sequencingRuns/${run.id}/analyses/${analysis.id}/newAlleleTemporaryConsensusFastaPutLink`,
            {
                params: {
                    hlaLocus: locus,
                    genotypeIndexReference: genotypePartIndex,
                    alleleIndexReference: alleleIndex
                }
            }
        )
    },
    resolveRun(run: Run) {
        return axios.post(`api/support/sequencingRuns/${run.id}/resolve`)
    },
    toolResults(run: Run, analysis: Analysis) {
        return fetchStorageData<{ toolResults: unknown }>(
            `api/support/sequencingRuns/${run.id}/analyses/${analysis.id}/resource?type=UNDERLYING_TOOLS_RESULT`
        ).then(data => data.toolResults)
    },
    analyses(run: Run) {
        return fetchData(`api/support/sequencingRuns/${run.id}/analyses`)
    },
    analysis(run: Run, analysis: Analysis) {
        return fetchData(`api/support/sequencingRuns/${run.id}/analyses/${analysis.id}`)
    },
    sequencingRun(runId: string): Promise<Run> {
        return fetchData(`api/support/sequencingRuns/${runId}`)
    },
    statistics() {
        return fetchData("api/support/statistics")
    },
    organizations() {
        return fetchData("api/support/organizations")
    },
    getSupports() {
        return fetchData("api/support/users")
    },
    sapportsEdit(runId: string, supportName?: string) {
        return axios.put(`api/support/sequencingRuns/${runId}/responsibleSupport`, undefined, {
            params: {
                supportName,
            },
        })
    },
    fetchRunNamesMatchingPrefix(prefix: string) {
        return fetchData(
            "api/support/sequencingRunNames",
            {
                params: {
                    caseInsensitiveNamePrefix: prefix,
                    takeFirst: 10
                }
            }
        )
    },
    restartPipeline(analysisId: string) {
        return axios.post(`api/support/reAnalysis?analysisId=${analysisId}`)
    },
    getReAnalysisGenotype(runId: string, analysisId: string) {
        return fetchStorageData<{ annotations: unknown, genotypes: unknown, newAlleles: unknown, resolution: unknown }>(
            `api/support/sequencingRuns/${runId}/analyses/${analysisId}/resource?type=RE_ANALYSIS_GENOTYPE`
        )
    },
}

export function approveAnalysis(analysisId: string, newProperties: any) {
    return axios.post(
        `api/analyses/${analysisId}/approve`,
        newProperties
    )
}

export function markAnalysisAsReviewed(analysisId: string) {
    return axios.post(
        `api/analyses/${analysisId}/review`
    )
}

export function sendAnalysisToSupport({ analysisId, comment, loci }: AnalysisSupportRequestData) {
    return axios.post(
        `api/analyses/${analysisId}/supportRequest`,
        null,
        {
            params: { comment, loci },
            // Without this serializer arrays are serialized as loci[]=A&loci[]=B
            paramsSerializer: qs.stringify
        }
    ).then(({ data })=> data)
}

export function fetchRunsWithAnalysesToApprove() {
    return fetchData(
        "api/sequencingRuns/aggregates/havingAnalyses",
        { params: { withStatus: AnalysisStatuses.AWAITING_APPROVE } }
    )
}

export function fetchAnalysesToApprove(runId: string) {
    return fetchData(
        `api/sequencingRuns/${runId}/analyses`,
        { params: { withStatus: AnalysisStatuses.AWAITING_APPROVE } }
    )
}


export const lotManagmentEndpoints = {
    fetchAllLots() {
        return fetchData("api/entityManagement/reagentKitLots/all")
    },
    fetchAllPipelines() {
        return fetchData("api/entityManagement/configuredPipelines/all")
    },
    fetchActualPipeline() {
        return fetchData("api/entityManagement/configuredPipelines/actual")
    },
    synchronize() {
        return axios.post("api/entityManagement/configuredPipelines/sync")
    },
    saveNewPipelineToLot(lotId: string, pipelineId: string) {
        return axios.put(
            `api/entityManagement/reagentKitLots/${lotId}/configuredPipeline`,
            null,
            {
                params: {
                    configuredPipelineId: pipelineId
                }
            }
        )
    },
    createLot(name: string, configuredPipelineId: string, expirationDate: number, designVersionName: string): Promise<LotWithConfiguredPipeline> {
        return axios.post(
            "api/entityManagement/reagentKitLots",
            {
                reagentKitLotName: name,
                designVersionName,
                configuredPipelineId,
                reagentKitLotExpirationDate: expirationDate
            }
        ).then(({ data })=> data)
    },
    setActualPipeline(configuredPipelineId: string): Promise<unknown> {
        return axios.post(
            "api/entityManagement/configuredPipelines/actual/set",
            null,
            {
                params: {
                    configuredPipelineId
                }
            }
        ).then(({ data })=> data)
    }
}

export const websocket = {
    dataUpload: "api/ws/sequencingDataUpload"
}

export function fetchOrganizationRunNamesMatchingPrefix(prefix: string) {
    return fetchData(
        "api/sequencingRunNames",
        {
            params: {
                caseInsensitiveNamePrefix: prefix,
                takeFirst: 10
            }
        }
    )
}

export function fetchOrganizationNames(): Promise<Array<String>> {
    return fetchData(
        "api/admin/organizationNames",
    )
}

export function fetchRunNamesMatchingPrefix(
    caseInsensitiveNamePrefix: string,
    takeFirst: number,
): Promise<Array<String>> {
    return fetchData(
        "api/admin/sequencingRunNames",
        {
            params: {
                caseInsensitiveNamePrefix,
                takeFirst,
            }
        }
    )
}

export function logout() {
    return axios.delete("api/logout")
}

export function fetchPipelines() {
    return fetchData("api/configuredPipelines")
}

export const pipelineEndpoints = {
    fetchKnownAmbiguities(pipelineName: string): Promise<KnownAmbiguities> {
        return fetchStorageData<KnownAmbiguities | OldKnownAmbiguities>(
            `api/configuredPipelines/${pipelineName}/resource?type=KNOWN_AMBIGUITIES`
        )
            .then(data => parseKnownAmbiguities(data))
    },
    fetchQualityControlThresholds(pipelineName: string) {
        return fetchStorageData(`api/configuredPipelines/${pipelineName}/resource?type=QUALITY_CONTROL_THRESHOLDS`)
    },
    fetchAlleleFunctionalAnnotations(pipelineName: string): Promise<FunctionalAnnotations> {
        return fetchStorageData<FunctionalAnnotations>(
            `api/configuredPipelines/${pipelineName}/resource?type=ALLELE_FUNCTIONAL_ANNOTATIONS`
        )
            .catch(error => {
                if (error.response.status === 404) {
                    console.warn("Functional annotations are not found")
                    return {}
                }
                throw error
            })
    }
}

export function fetchPipelineDocsLink(configuredPipelineName: string): Promise<string> {
    return fetchData(`api/configuredPipelines/${configuredPipelineName}/documentation`)
}

export function markNotificationAsRead(notificationId: string) {
    return axios.post(`api/notifications/${notificationId}/markAsRead`)
}

export function markRunsAsCompleted(filter: {}) {
    return axios.post("api/sequencingRuns/complete", {}, {
        params: filter,
        // Without this serializer arrays are serialized as param[]=value1&param[]=value2
        paramsSerializer: qs.stringify
    }).then(({ data: completedRunNames }) => completedRunNames)
}


export function fetchResourcePage<R, F>(resourceName: string, filter: F, page: Page): Promise<FilteredPage<R, F>> {
    return fetchData<{ number: number, size: number, totalResources: number, resources: R[] }>(
        `api/${resourceName}`,
        {
            params: {
                ...page,
                ...filter,
            },
            paramsSerializer: qs.stringify,
        }
    )
        .then(({ number, size, totalResources, resources }) =>
            ({
                pageNumber: number,
                pageSize: size,
                resourceList: resources,
                resourceTotalNumber: totalResources,
                filter
            }))
}

export function fetchLots(filters = {}) {
    return fetchData<{ resources: unknown[] }>("/api/reagentKitLots", {
        params: {
            ...filters
        }
    }).then(data => data.resources)
}

export function fetchAlleleFrequencies() {
    return fetchData("api/alleles/aggregates/frequencies")
}

export function fetchStatistics() {
    return fetchData("api/statistics")
}

export function fetchRunsWithAnalysesToReview() {
    return fetchData(
        "api/sequencingRuns/aggregates/havingAnalyses",
        { params: { withStatus: AnalysisStatuses.AWAITING_REVIEW } }
    )
}

export function fetchAnalysesToReview(runId: string) {
    return fetchData(`api/sequencingRuns/${runId}/analyses`, { params: { withStatus: AnalysisStatuses.AWAITING_REVIEW } })
}


function fetchData<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.get(endpoint, config).then(response => response.data)
}

function fetchStorageData<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return fetchData<string>(endpoint, config)
        .then(link => fetchData<T>(link))
}

export const buildRunPdfReportExportUrl =
    (sequencingRunId: string) => `api/sequencingRuns/${sequencingRunId}/report`

export const buildSamplePdfReportExportUrl =
    (analysisId: string) => `api/analyses/${analysisId}/report`

// TODO: [@bbatanov 15.02.2021] Get rid of an unnecessary parameter &as=CSV
export const buildAnalysesGenotypesExportUrl =
    (filter: Partial<AnalysisFilterCouldBe>, resolution: 3 | 2) => `api/analyses/genotypes?${qs.stringify(filter)}&as=CSV&resolution=${makeExportResolutionBackendCompatible(resolution)}`

export const buildArchiveAnalysesGenotypesExportUrl =
    (filter: Partial<ArchiveAnalysisFilterCouldBe>, resolution: 3 | 2) => `api/admin/analyses/genotypes?${qs.stringify(filter)}&as=CSV&resolution=${makeExportResolutionBackendCompatible(resolution)}`

export const buildArchiveAnalysesGeneralStatisticsExportUrl =
    (filters: Partial<ArchiveAnalysisFilterCouldBe>) => `api/admin/analyses/generalStatistics?${qs.stringify(filters)}`

export const buildArchiveAnalysesLocusSpecificStatisticsExportUrl =
    (filters: Partial<ArchiveAnalysisFilterCouldBe>) => `api/admin/analyses/locusSpecificStatistics?${qs.stringify(filters)}`

export const buildRunGenotypesExportUrl =
    (sequencingRunId: string, resolution: 3 | 2) => `api/sequencingRuns/${sequencingRunId}/genotypes?resolution=${makeExportResolutionBackendCompatible(resolution)}`

export const buildExportRunResourcesUrl =
    (sequencingRunId: string) => `api/sequencingRuns/${sequencingRunId}/exportResources`

export function completeRun(sequencingRunId: string) {
    return axios.post(`api/sequencingRuns/${sequencingRunId}/complete`)
}

function makeExportResolutionBackendCompatible(resolution: 3 | 2): GenotypingResolution {
    switch (resolution) {
        case 2:
            return "TWO_FIELD"
        case 3:
            return "THREE_FIELD"
        default:
            throw new Error(`Unknown export resolution: ${resolution}`)
    }
}
