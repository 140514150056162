<template>
    <InteractiveDocs :steps="steps" :allow-controls="false"/>
</template>

<script type="text/jsx">
    import InteractiveDocs from "@/components/interactive-docs"
    import { waitFor } from "@/interactive-docs/utils"

    export default {
        name: "RunsDocs",
        components: { InteractiveDocs },
        data() {
            return {
                steps: [ generalDescription ],
            }
        }
    }

    const generalDescription = {
        name: "generalDescription",
        props: {
            highlighter: Object,
            nextStep: Function,
            prevStep: Function,
        },
        async mounted() {
            const el = await waitFor(() => document.querySelector(".run-list"))
            this.highlighter.moveHighlight([ el ])
        },
        methods: {
            selectSearch() {
                this.highlighter.moveHighlight([
                    document.querySelector(".search-panel"),
                ])
            },
            deselectSearch() {
                this.highlighter.moveHighlight([
                    document.querySelector(".run-list"),
                ])
            }
        },
        render() {
            return (
                <div>
                    <h3 class="h3 mb-2">Лабораторный контроль качества</h3>
                    <p>
                        <b>Предназначение:</b> анализ лабораторных метрик качества.
                    </p>
                    <br/>
                    <p>
                        В данном окне предоставлен список всех запусков организации. Для каждого запуска указаны
                        его имя, дата постановки, серии наборов реагентов, использованные для подготовки образцов, и
                        количество образцов, попавших в оптимальную/субоптимальную и не прошедшую контроль качества
                        по лабораторным метрикам группы.
                    </p>
                    <p>
                        Запуски с образцами в процессе анализа будут иметь соответствующую подпись.
                        Посмотреть их результаты можно будет после успешного завершения анализа всех образцов.
                    </p>
                    <br/>
                    <p>
                        Для поиска конкретного запуска можно воспользоваться
                        <span onMouseenter={this.selectSearch}
                              onMouseleave={this.deselectSearch}> фильтром
                        </span>
                        .
                    </p>
                    <br/>
                    <p>Можете перейти к конкретному запуску, щелкнув на него.</p>
                </div>
            )
        }
    }
</script>
