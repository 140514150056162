<template>
    <div class="content">
        <p-modal v-if="modals.solutionDocumentation.opened">
            <!-- Without explicitly specified height, e.g. with min-height only, form cannot take 100% of space -->
            <div
                class="relative"
                style="padding: 3rem; max-width: 60rem; height: 45rem;"
            >
                <template v-if="documentationLinks">
                    <h3
                        style="font-weight: 400; margin-bottom: 6rem"
                    >Набор реагентов «PARallele™ HLA solution v3» для типирования генов HLA в высоком разрешении методом
                        высокопроизводительного секвенирования</h3>
                    <span
                        style="font-weight: 600">Инструкция по применению медицинского изделия для диагностики <i>in vitro</i>:</span>
                    <ul>
                        <li
                            :key="i"
                            v-for="([name, link], i) in documentationLinks"
                        >
                            <a :href="link" target="_blank" ref="noreferrer noopener">
                                ТОМ <b style="font-weight: 600">{{ i + 1 }}</b> из {{ documentationLinks.length }}.
                                {{ name }}
                            </a>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <form
                        class="documentation-access-form"
                        @submit.prevent.stop="fetchDocs(lot)">
                        <h3 class="mb-2">{{ $t("enterLot") }}</h3>
                        <div></div>
                        <figure class="mb-3" style="text-align: center">
                            <img
                                src="../assets/lot_instruction.png"
                                alt="Lot instructions"
                                class="lot-on-the-box-img"
                            >
                            <figcaption>{{ $t("lotOnTheBoxCaption") }}</figcaption>
                        </figure>
                        <p-input
                            class="md-outlined mb-3"
                            type="text"
                            autocomplete="off"
                            autofocus
                            :placeholder="$t('lot')"
                            style="width: 20rem"
                            v-model="lot"
                            :validation-errors="lotValidationErrors"
                        />
                        <button
                            class="md-btn-contained green"
                            style="margin-top: auto"
                            type="submit">{{ $t("getAccessToDocs") }}
                        </button>
                    </form>
                </template>
                <p-icon-button
                    style="position: absolute; top: 1rem; right: 1rem;"
                    icon-name="cross"
                    size="1.2em"
                    class="md-round-icon-btn"
                    @click="closeModal('solutionDocumentation')"
                />
            </div>
        </p-modal>
        <div class="header">
            <BrandLogo/>
            <p-with-tooltip
                v-if="getBrandName ==='PARSEQ_NAME'"
                fixed
                position="bottom-left"
            >
                <svg fill="currentColor" id="ivd-logo">
                    <use xlink:href="#ivd_logo"/>
                </svg>
                <template #tooltip>
                    <svg id="software-marking" class="mt-1">
                        <use xlink:href="#software_marking"></use>
                    </svg>
                </template>
            </p-with-tooltip>
        </div>

        <h2 style="color: rgba(255, 255, 255, .9); margin-bottom: 4rem; text-align: center"
        >
            <BrandName style="font-size: 5.5rem;"/>
            <span style="font-size: 3rem; color: rgba(255, 255, 255, .7);">HLA software</span>
            <br>
        </h2>
        <form @submit.prevent="login"
              id="login-form">
            <p class="error" v-show="invalidCredentials">{{ $t("invalidCredentials") }}</p>
            <p-input
                id="name"
                autocomplete="username"
                class="md-filled"
                :placeholder="$t('name')"
                style="margin: .5em 1em"
                name="name"
                type="text"
                v-model="name"
            />
            <p-input
                id="password"
                autocomplete="current-password"
                class="md-filled"
                :placeholder="$t('password')"
                style="margin: .5em 1em 1.5em"
                name="password"
                type="password"
                v-model="password"
            />
            <button class="md-btn-contained yellow"
                    style="margin: 1em;"
                    type="submit">
                {{ $t("buttons.login") }}
            </button>
        </form>
        <button
            v-if="getBrandName ==='PARSEQ_NAME'"
            class="ml-auto"
            style="color: white; font-size: 1.2rem; margin: 1.5rem; position: fixed; right: 0; bottom: 0"
            @click="openModal('solutionDocumentation')"
        >
            {{ $t("customDocumentation") }}
        </button>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import { fetchDocumentationLinks } from "@/endpoints"
    import { ValidationError } from "@/validation/validators"
    import { modalMixin } from "@/mixins/modal-mixin"
    import { useBrand } from "@/utils/brand-changer"
    import BrandName from "@/components/brand-name"
    import BrandLogo from "@/components/brand-logo"
    import { mapGetters } from "vuex"

    export default {
        name: "Login",
        components: { BrandName, BrandLogo },
        mixins: [ modalMixin ],
        data() {
            return {
                name: null,
                password: null,
                invalidCredentials: false,
                lot: "",
                documentationLinks: null,
                modals: {
                    solutionDocumentation: {
                        opened: false,
                        onClose: () => {
                            this.documentationLinks = null
                        }
                    }
                },
                lotValidationErrors: []
            }
        },
        methods: {
            login() {
                this.$store.dispatch("login", { name: this.name, password: this.password })
                    // eslint-disable-next-line consistent-return
                    .catch((error) => {
                        if (error.response.status === 401) {
                            return this.invalidCredentials = true
                        }
                    })
            },
            fetchDocs(lotName) {
                fetchDocumentationLinks(lotName)
                    // eslint-disable-next-line promise/always-return
                    .then(links => {
                        this.documentationLinks = links
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            this.lotValidationErrors = [ ValidationError.LotDoesNotExist ]
                        } else {
                            throw error
                        }
                    })
            }
        },
        directives: {
            hideIfHasNoAccessToRoute(el, binding, vnode) {
                const user = vnode.componentInstance.$store.getters.user
            }
        },
        computed: {
            getBrandName() {
                const { brandName } = useBrand()
                return brandName
            },
            ...mapGetters([
                "user",
            ]),
        },
        mounted() {
            const browserLanguage = window.navigator.language

            if (this.user && this.user.language) {
                this.$root.$i18n.locale = Locales[this.user.language]
            } else if (browserLanguage !== "ru" && browserLanguage !== "ru-RU") {
                this.$root.$i18n.locale = Locales.EN
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    "getAccessToDocs": "Получить доступ к документации",
                    lotOnTheBoxCaption: "Он указан на фронтальной стороне коробки",
                    enterLot: "Введите серию приобретенного вами набора",
                    customDocumentation: "Пользовательская документация на Набор реагентов \"PARallele™ HLA solution v3\"",
                },
                [Locales.EN]: {
                    "getAccessToDocs": "Get access to documentation",
                    lotOnTheBoxCaption: "It's specified on the front side of the box",
                    enterLot: "Enter lot of the product you bought",
                    customDocumentation: "Custom documentation for Reagent Kit \"PARallele™ HLA solution v3\"",
                }
            }
        }
    }
</script>

<style scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#parseq-logo {
    color: white;
    height: 5rem;
    width: 15rem;
    margin: 1.5rem 0 0 2rem;
}

#ivd-logo {
    height: 3rem;
    width: 9rem;
    color: white;
}

#software-marking {
    color: white;
    width: 88rem;
    height: 48rem;
}

#login-form {
    display: flex;
    flex-flow: column;
    font-size: 2rem;
    width: 20em;
    padding: 2rem 2rem;
    border-radius: 3px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/login-background.webp");
    background-size: cover;
    flex-flow: column;
}

.error {
    color: rgb(176, 11, 32);
    margin: 0 1em;
    text-align: center;
}

ul > li {
    padding: 0.5rem 1rem;
}

.documentation-access-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

.lot-on-the-box-img {
    width: 60%;
    border-radius: 8px;
    border: 1px solid var(--grey-500);

}
</style>
