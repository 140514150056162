<template>
    <div id="raw-data-upload-metadata-step">
        <error-message v-if="maybeFirstError" @close="removeFirstError">
            <h3 class="error__header">{{ $t("error") }}</h3>
            <template v-if="maybeFirstError.type === 'onlyOneFileCanBeDropped'">
                <div>{{ $t("onlyOneFileCanBeDropped") }}</div>
            </template>
        </error-message>
        <template v-if="!runMetadataForm.id.value && !manualEdit">
            <p-file-dropzone :on-files-drop="handleFilesDrop"
                            class="dropzone run-info-dropzone mb-1"
                            style="width: 60%;"
            >
                <div style="padding: 1rem">
                    <p-icon class="file-icon"
                            icon-name="file-empty"
                            size="8rem"
                    />
                </div>
                <div style="margin: 0 3rem">{{ $t("dropzonePlaceholder1") }}
                    <label class="p-dropzone-hidden-file-input-label">{{ $t("dropzonePlaceholder2") }}
                        <input @change="handleFilesDrop(fileListToArray($event.target.files))"
                               class="d-none"
                               type="file"
                        >
                    </label>
                    <b>RunInfo.xml</b>
                </div>
            </p-file-dropzone>
            <button
                @click="manualEdit = true"
                class="manual-edit-btn"
            >{{ $t("fillManually") }}
            </button>
        </template>
        <form id="run-metadata" v-else>
            <!-- eslint-disable vue/no-mutating-props-->
            <p-input :validation-errors="runMetadataForm.id.errors"
                     class="mv-2 md-outlined"
                     :placeholder="$t('runId')"
                     type="text"
                     :disabled="!manualEdit"
                     v-model="runMetadataForm.id.value"
            />
            <p-select
                class="mv-2 md-outlined"
                :options="platform.sequencingKits"
                :view="option => option.name"
                :validation-errors="runMetadataForm.sequencingKit.errors"
                :placeholder="$t('sequencingKit')"
                :disabled="!manualEdit"
                v-model="runMetadataForm.sequencingKit.value"
            />
            <p-input :validation-errors="runMetadataForm.flows.errors"
                     class="mv-2 md-outlined"
                     :placeholder="$t('flows')"
                     type="text"
                     :disabled="!manualEdit"
                     v-model="runMetadataForm.flows.value"
            />
            <!-- Notice that date input wants data in 'yyyy-MM-dd' format not as Date object,
                 so conversion must be made -->
            <p-input
                :validation-errors="runMetadataForm.date.errors"
                :value="runMetadataForm.date.value && runMetadataForm.date.value.toISOString().split('T')[0]"
                class="mv-2 md-outlined"
                :placeholder="$t('runDate')"
                :disabled="!manualEdit"
                @input.native="runMetadataForm.date.value = $event.target.valueAsDate"
                type="date"
            />
        </form>
    </div>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import ErrorMessage from "@/components/error-message"
    import { errorMixin } from "@/mixins/error-mixin"

    export default {
        name: "MetadataStep",
        mixins: [ errorMixin ],
        components: { ErrorMessage },
        props: {
            runMetadataForm: Object,
            platform: Object,
            onRunInfoDrop: Function,
        },
        methods: {
            fileListToArray(files) {
                return [ ...files ]
            },
            handleFilesDrop(files) {
                if (files.length !== 1) {
                    this.addError({
                        type: "onlyOneFileCanBeDropped",
                    })
                } else {
                    this.onRunInfoDrop(files[0])
                }
            },
        },
        data() {
            return {
                manualEdit: false
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    dropzonePlaceholder1: "Drop or ",
                    dropzonePlaceholder2: "select",
                    fillManually: "or fill manually",
                    error: "Errors when adding a file",
                    onlyOneFileCanBeDropped: "Only one file can be dropped"
                },
                [Locales.RU]: {
                    dropzonePlaceholder1: "Перетащите или ",
                    dropzonePlaceholder2: "выберите файл",
                    fillManually: "или заполните вручную",
                    error: "Ошибки при добавлении файла",
                    onlyOneFileCanBeDropped: "Может быть перемещен только один файл",
                }
            }
        }
    }
</script>

<style scoped>
#raw-data-upload-metadata-step {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 0 3em;
}

#run-metadata {
    font-size: 1.8rem;
    grid-column: 2 / 3;
    min-width: 15em;
    width: 100%;
    max-width: 20em;
    display: flex;
    flex-flow: column;
}

.run-info-dropzone {
    height: 20em;
    min-width: 80%;
}

.manual-edit-btn {
    text-align: center;
    text-decoration: underline;
    color: var(--grey-500);
}

.manual-edit-btn:hover {
    color: #5ea4e0;
}
</style>
