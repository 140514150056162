import ld from "lodash"

type HighlightOptions = { isFixed: boolean, isModal: boolean }

export class Highlighter {
    private highlightedElements: Array<HTMLElement> = []
    private highlights: Array<HTMLElement> = []
    private readonly highlightStyle: CSSStyleDeclaration
    private backdrop: HTMLElement
    private cleanups: (() => void)[] = []

    constructor(highlightStyle: CSSStyleDeclaration) {
        this.highlightStyle = highlightStyle
        this.backdrop = addBackdrop()
    }

    private static addHighlightingStyles(el: HTMLElement) {
        if (window.getComputedStyle(el).position === "static") {
            el.style.position = "relative"
        }
        el.style.zIndex = "var(--interactive-docs-highlighted-element)"
        if (window.getComputedStyle(el).backgroundColor === "rgba(0, 0, 0, 0)") {
            el.style.backgroundColor = "white"
        }
        if (window.getComputedStyle(el).boxShadow) {
            el.style.boxShadow = window.getComputedStyle(el).boxShadow === "none"
                ? "0 0 2px 7px rgb(255, 255, 255)"
                : window.getComputedStyle(el).boxShadow + ", 0 0 2px 12px rgb(255, 255, 255)"
        }
        if (window.getComputedStyle(el).borderRadius === "0px") {
            el.style.borderRadius = "8px"
        }
    }

    private highlight(
        targets: Array<HTMLElement>,
        options: HighlightOptions
    ) {
        targets.forEach((target, i) => {
            this.highlightedElements.push(target)
            const stableCssText = target.style.cssText
            this.cleanups.push(
                () => target.setAttribute("style", stableCssText)
            )

            if (options.isModal) {
                let parent = target.parentElement
                while (parent) {
                    if (parent.classList.contains("modal-content")) {
                        const modalContent = parent
                        const modalContentParent = modalContent.parentElement!
                        this.backdrop.append(modalContent)
                        this.cleanups.push(
                            () => {
                                const nextSibling = modalContent.nextSibling
                                const prevSibling = modalContent.previousSibling
                                if (nextSibling) {
                                    nextSibling.before(modalContent)
                                } else if (prevSibling) {
                                    prevSibling.after(modalContent)
                                } else {
                                    modalContentParent.append(modalContent)
                                }
                            }
                        )
                        break
                    }
                    parent = parent.parentElement
                }
                return
            }

            if (options.isFixed) {
                const highlight = document.createElement("div")
                this.highlights.push(highlight)
                this.cleanups.push(
                    () => highlight.remove()
                )

                highlight.setAttribute("id", `highlight-${i}`)

                const { top, left, width, height } = target.getBoundingClientRect()
                const padding = 1.5
                highlight.setAttribute("style", `position: fixed; top: calc(${top}px - ${padding}rem); left: calc(${left}px - ${padding}rem); width: calc(${width}px + ${padding * 2}rem); height: calc(${height}px + ${padding * 2}rem); ${this.highlightStyle}; transition: all .8s`)
                highlight.style.zIndex = "var(--interactive-docs-highlighting-element)"

                document.body.append(highlight)
                const instance = this

                // eslint-disable-next-line no-inner-declarations
                function onResize() {
                    ld.zip(instance.highlights, instance.highlightedElements)
                        .forEach(([ h, t ]) => {
                            if (!h || !t) {
                                return
                            }
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            const { top, left, width, height } = t.getBoundingClientRect()
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            h.setAttribute("style", `position: fixed; top: calc(${top}px - ${padding}rem); left: calc(${left}px - ${padding}rem); width: calc(${width}px + ${padding * 2}rem); height: calc(${height}px + ${padding * 2}rem); ${instance.highlightStyle}; transition: all .8s`)
                        })
                }

                const resizeEventListener = ld.throttle(onResize, 200)
                window.addEventListener("resize", resizeEventListener)
                this.cleanups.push(
                    () => window.removeEventListener("resize", resizeEventListener)
                )
            } else {
                Highlighter.addHighlightingStyles(target)
            }
        })
    }

    moveHighlight(targets: Array<HTMLElement>, options: HighlightOptions = { isModal: false, isFixed: false }) {
        this.clean()
        this.highlight(targets, options)
    }

    private clean() {
        this.cleanups.forEach(cleanup => cleanup())
    }

    onDestroy() {
        this.clean()
        this.backdrop.remove()
    }
}


export class Circler {
    private highlights: Array<HTMLElement> = []
    private readonly highlightStyle: CSSStyleDeclaration

    constructor(highlightStyle: CSSStyleDeclaration) {
        this.highlightStyle = highlightStyle
    }

    highlight(targets: Array<HTMLElement>) {
        targets.forEach((target, i) => {
            const { top, left, width, height } = target.getBoundingClientRect()
            const highlight = document.createElement("div")
            highlight.setAttribute("id", `circle-${i}`)
            const padding = 1
            highlight.setAttribute("style", `position: fixed; top: calc(${top}px - ${padding}rem); left: calc(${left}px - ${padding}rem); width: calc(${width}px + ${padding * 2}rem); height: calc(${height}px + ${padding * 2}rem); ${this.highlightStyle}; transition: all .8s`)
            highlight.style.zIndex = "var(--interactive-docs-circler)"
            document.body.append(highlight)
            this.highlights.push(highlight)
        })
    }

    moveHighlight(targets: Array<HTMLElement>) {
        this.clean()
        this.highlight(targets)
    }

    clean() {
        this.highlights.forEach(el => {
            el.remove()
        })
        this.highlights = []
    }

    onDestroy() {
        this.clean()
    }
}


export function addBackdrop() {
    const backdrop = document.createElement("div")
    backdrop.setAttribute("id", "backdrop")
    backdrop.classList.add("modal-backdrop")
    backdrop.style.zIndex = "var(--interactive-docs-backdrop-layer)"
    // @ts-ignore
    backdrop.style.backdropFilter = "blur(2px)"
    document.body.append(backdrop)
    return backdrop
}


export function waitFor(elementGetter: () => HTMLElement) {
    return new Promise(resolve => {
        const id = setInterval(() => {
            const el = elementGetter()
            if (el) {
                resolve(el)
                clearInterval(id)
            }
        }, 200)
    })
}
