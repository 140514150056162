<template>
    <div class="problems w-100">
        <p-with-tooltip fixed v-if="isSentByUser">
            <template>
                <div class="flex">
                    <span>{{ $t("sentByUser") }}</span>
                    <!-- Translated cause icon is "visual center" not in it's real center -->
                    <p-icon icon-name="comment" class="ml-1" size="2.5rem" style="transform: translateY(8%)" />
                </div>
            </template>
            <template #tooltip>
                <p class="tooltip-black">{{ analysis.maybeSupportRequest.comment }}</p>
            </template>
        </p-with-tooltip>

        <div class="flex w-100" style="gap: 1rem;">
            <div class="flex" style="flex: 1 1 0; justify-content: start; flex-wrap: wrap;">
                <template v-for="(problem, name) in locusSpecificProblemsAtActiveLocus">
                    <p-with-tooltip fixed :key="name">
                        <template>
                            <div :class="problem.quality" class="badge badge-margin">
                                {{ $t(`analysisMetrics.${name}`) }}
                            </div>
                        </template>
                        <template #tooltip>
                            <p class="tooltip-black">{{ problem.value }}</p>
                        </template>
                    </p-with-tooltip>
                </template>
            </div>
            <div v-if="locusSpecificReAnalysisProblemsAtActiveLocus.length !== 0" class="delimiter"></div>
            <div class="flex" style="flex: 1 1 0; justify-content: flex-end; flex-wrap: wrap;">
                <template v-for="(problem, name) in locusSpecificReAnalysisProblemsAtActiveLocus">
                    <p-with-tooltip fixed :key="name">
                        <template>
                            <div :class="problem.quality" class="badge badge-margin">
                                {{ $t(`analysisMetrics.${name}`) }}
                            </div>
                        </template>
                        <template #tooltip>
                            <p class="tooltip-black">{{ problem.value }}</p>
                        </template>
                    </p-with-tooltip>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import { isSentByUser } from "@/utils/support"
    import { Locales } from "@/i18n/main"

    export default {
        name: "problems",
        props: {
            analysis: {},
            locus: {}
        },
        computed: {
            isSentByUser() {
                return isSentByUser(this.analysis, this.locus)
            },
            locusSpecificProblemsAtActiveLocus() {
                return this.analysis.result?.problems?.locusSpecificMetrics?.[this.locus] ?? []
            },
            locusSpecificReAnalysisProblemsAtActiveLocus() {
                return this.analysis?.maybeReAnalysisState?.maybeResult?.problems?.locusSpecificMetrics?.[this.locus] ?? []
            }
        },
        i18n: {
            messages: {
                [Locales.RU]: {
                    sentByUser: "Направлен пользователем"
                },
                [Locales.EN]: {
                    sentByUser: "Sent by user"
                }
            }
        }
    }
</script>
<style scoped>
.problems {
    display: flex;
    align-items: center;
}

.tooltip-black {
    width: 30rem;
    word-break: break-all;
}

.delimiter {
    background: var(--grey-400) !important;
    width: 1px;
    height: 28px;
}
</style>
