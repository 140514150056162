// Some meme background: https://knowyourmeme.com/memes/virgin-vs-chad
// Chad for TS usage
export type AnalysisStatus =
    NonTerminalAnalysisStatus
    | TerminalAnalysisStatus

export type NonTerminalAnalysisStatus =
    "AWAITING_APPROVE"
    | "AWAITING_REVIEW"
    | "IN_SUPPORT"
    | "RUNNING"

export type TerminalAnalysisStatus =
    "COMPLETED"
    | "ERROR"
    | "LAB_FAILURE"
    | "TYPING_FAULT"

// Virgin for JS usage. Maybe type can also be used from JS but this way we get nice autocompletion
export const AnalysisStatuses: { [key in AnalysisStatus]: string | null } = {
    RUNNING: null,
    AWAITING_REVIEW: null,
    COMPLETED: null,
    IN_SUPPORT: null,
    AWAITING_APPROVE: null,
    LAB_FAILURE: null,
    TYPING_FAULT: null,
    ERROR: null,
}

Object.keys(AnalysisStatuses)
    .forEach(key => Object.assign(AnalysisStatuses, { [key]: key }))

