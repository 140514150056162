<template>
    <GenericAnalysesReview :fetch-analyses="fetchAnalysesToApprove"
                           :fetch-runs="fetchRunsWithAnalysesToApprove"
                           :approve="approve"
                           :send-to-support="sendToSupport">
        <template #approve-btn-text>
            {{ $t("buttons.approve") }}&nbsp;<span
                style="transform: translateY(-10%)">&darr;</span>
        </template>
        <template v-slot:default="slotProps">
            <approve-analysis-card :analysis="slotProps.analysis"
                                 :key="slotProps.analysis.id"
                                 @modal-opened="slotProps.removeKeydownListener"
                                 @modal-closed="slotProps.setKeydownListener"
            />
        </template>
        <template #fallback-text>
            {{ $t("nothingToApprove") }}
        </template>
    </GenericAnalysesReview>
</template>

<script>
    import { Locales } from "@/i18n/main"
    import GenericAnalysesReview from "@/components/generic-analyses-review"
    import { constructNewProperties } from "@/utils/support"
    import {
        approveAnalysis,
        fetchAnalysesToApprove,
        fetchRunsWithAnalysesToApprove,
        sendAnalysisToSupport,
    } from "@/endpoints"

    import ApproveAnalysisCard from "./components/approve-analysis-card"

    export default {
        name: "Approve",
        components: { ApproveAnalysisCard, GenericAnalysesReview },
        methods: {
            fetchAnalysesToApprove,
            fetchRunsWithAnalysesToApprove,
            approve(analysis) {
                return approveAnalysis(analysis.id, constructNewProperties(analysis))
                    .then(() => this.$store.commit("decreaseAwaitingCustomerAnalysisNumber"))
            },
            sendToSupport(analysis, { comment, loci }) {
                return sendAnalysisToSupport({ analysisId: analysis.id, comment, loci })
                    // eslint-disable-next-line promise/always-return
                    .then(() => {
                        this.$store.commit("decreaseAwaitingCustomerAnalysisNumber")
                        this.$store.commit("increaseAwaitingSupportAnalysisNumber")
                    })
            }
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    nothingToApprove: "No samples to approve"
                },
                [Locales.RU]: {
                    nothingToApprove: "Образцов на одобрение нет"
                }
            }
        }
    }
</script>

<style scoped>
</style>
