<template>
    <div style="height: 100vh">
        <p-modal v-if="legalisationInProgress">
            <p-spinner v-if="!licenseAgreement"
                       class="abs-center h4"/>
            <license-agreement
                style="max-width: 90vw; max-height: 90vh;"
                v-else
                :license-agreement="licenseAgreement"
                @accept="confirmLegalisation(licenseAgreement.serialNumber)"
                @reject="abortLegalisation"
            />
        </p-modal>
        <div id="app" :class="{'doc-mode': docMode}" v-if="isAuthenticated && accountDataReady">
            <error-message v-if="maybeFirstError" @close="removeFirstError">
                <template v-if="maybeFirstError.type === 'missingDocs'">
                    <h4 class="h4">{{ $t("missingDocs") }}</h4>
                </template>
                <template v-if="maybeFirstError.type === 'unableToLogoutDueToRunningTasks'">
                    <h3 class="error__header">{{ $t("error") }}</h3>
                    <div>{{ $t("unableToLogoutDueToRunningTasks") }}</div>
                </template>
            </error-message>
            <p-modal v-if="modals.profile.opened">
                <div style="padding: 3rem; max-width: 80vw">
                    <UserProfile/>
                    <button @click="closeModal('profile')"
                            class="md-btn-text ml-auto"
                            style="font-size: 1.6rem; color: royalblue; margin-right: -1.5rem; margin-bottom: -1.5rem">
                        {{
                            $t("buttons.close")
                        }}
                    </button>
                </div>
            </p-modal>
            <p-modal v-if="modals.notifications.opened">
                <div style="padding: 4rem 5rem">
                    <UserNotifications/>
                    <button @click="closeModal('notifications')"
                            class="md-btn-text ml-auto"
                            style="font-size: 1.6rem; color: royalblue; margin-right: -1.5rem; margin-bottom: -1.5rem">
                        {{
                            $t("buttons.close")
                        }}
                    </button>
                </div>
            </p-modal>
            <div class="navigation-panel overflow-auto custom-scroll">
                <BrandSubLogo id="soft-logo"/>
                <ul id="app-list" class="navigation-panel--links">
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/workbench"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("home") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/analysesReview"
                        v-hide-if-has-no-access-to-route
                    >
                        {{ $t("review") }}<sup class="badge yellow sup-badge"
                                               v-if="organizationStatistics && organizationStatistics.awaitingReviewAnalysisNumber > 0">{{
                            organizationStatistics.awaitingReviewAnalysisNumber
                        }}</sup>
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/approve"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("approve") }}<sup class="badge sup-badge yellow"
                                                v-if="organizationStatistics && organizationStatistics.awaitingCustomerAnalysisNumber > 0">{{
                            organizationStatistics.awaitingCustomerAnalysisNumber
                        }}</sup>
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/samples"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("samples") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/archive"
                        v-hide-if-has-no-access-to-route
                    >
                        {{ $t("archive") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/runs"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("labQc") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/dataUpload"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("import") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/runsDataExport"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("runsDataExport") }}
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/support"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("support") }}
                        <sup class="badge sup-badge yellow"
                             v-if="supportStatistics && supportStatistics.awaitingSupportAnalysisNumber > 0">{{
                                supportStatistics.awaitingSupportAnalysisNumber
                            }}</sup>
                    </router-link>
                    <router-link
                        class="navigation-link navigation-link--primary"
                        tag="li"
                        to="/lotManagement"
                        v-hide-if-has-no-access-to-route>
                        {{ $t("lotManagement") }}
                    </router-link>
                </ul>

                <p-dropdown
                    id="user-menu"
                    class="user-menu mb-2"
                    align="right-bottom"
                    fixed
                >
                    <template v-slot:activator="{ toggleDropdown }">
                        <button class="user-menu--btn flex"
                                @click.prevent.stop="toggleDropdown"
                                style="padding: .5rem .8rem">
                            <p-icon icon-name="person" style="flex-shrink: 0;"/>
                            <span class="ellipsis" style="flex-grow: 0; padding: .5rem">
                                {{ user.name }}
                            </span>
                            <sup class="yellow new-notifications-indicator"
                                 style="flex-shrink: 0;"
                                 v-if="newNotificationsNumber"
                            />
                            <p-icon icon-name="arrow_drop_down"
                                    style="flex-shrink: 0; margin-left: auto; transform: rotate(-90deg); margin-right: -0.5rem"/>
                        </button>
                    </template>
                    <template>
                        <div class="dropdown user-menu--dropdown">
                            <ul class="user-menu--dropdown-items">
                                <li @click="openModal('notifications')"
                                    class="user-menu--dropdown-item">{{ $t("userMenu.notifications") }}
                                </li>
                                <li @click="goToProfile"
                                    class="user-menu--dropdown-item">{{ $t("profile") }}
                                </li>
                                <li @click="logout" class="user-menu--dropdown-item">{{ $t("userMenu.logout") }}</li>
                            </ul>
                        </div>
                    </template>
                </p-dropdown>
                <svg v-if="getBrand === 'PARSEQ'" fill="currentColor" id="ivd-logo">
                    <use xlink:href="#ivd_logo"/>
                </svg>
                <ul v-if="getBrand === 'PARSEQ'" class="secondary-navigation" >
                    <li>
                        <router-link
                            class="navigation-link"
                            to="/docs"
                        >
                            {{ $t("docs") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="navigation-link"
                            to="/terms"
                        >
                            {{ $t("terms") }}
                        </router-link>
                    </li>
                </ul>
                <div class="navigation-panel--version">
                    <BrandName>
                      HLA software
                    </BrandName>
                    <span>{{ $t("version") }} 1 </span>
                    <span>(SP 1.13.2)</span>
                </div>
            </div>
            <!-- Force rerender of components on docMode activation -->
            <router-view :key="docMode" class="custom-scroll h-100 app-content"></router-view>
            <router-view v-if="docMode" name="docs"></router-view>
        </div>
        <template v-else>
            <router-view class="h-100"></router-view>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex"
    import { Locales, setTimeZone } from "@/i18n/main"
    import UserProfile from "@/components/user-profile"
    import { modalMixin } from "@/mixins/modal-mixin"
    import UserNotifications from "@/components/user-notifications"
    import { newNotificationsGetterNames } from "@/store/modules/notifications"
    import { doesntRequireAuth, getRouteByName, hasRoleBasedAccessTo } from "@/router"
    import { backgroundTasksGetterNames } from "@/store/modules/background-tasks"
    import ErrorMessage from "@/components/error-message"
    import { docModeMixin } from "@/mixins/doc-mode-mixin"
    import LicenseAgreement from "@/components/license-agreement"
    import { fetchActualEula } from "@/endpoints"
    import { errorMixin } from "@/mixins/error-mixin"
    import BrandName from "@/components/brand-name"
    import BrandSubLogo from "@/components/brand-sub-logo"
    import { useBrand } from "@/utils/brand-changer"

    export default {
        name: "App",
        components: { LicenseAgreement, ErrorMessage, UserNotifications, UserProfile, BrandName, BrandSubLogo },
        mixins: [ modalMixin, docModeMixin, errorMixin ],
        data() {
            return {
                modals: {
                    "profile": {
                        opened: false
                    },
                    "notifications": {
                        opened: false
                    }
                },
                removeKeydownListener: null,
                licenseAgreement: null,
            }
        },
        watch: {
            isAuthenticated(newVal) {
                if (newVal) {
                    switch (this.user.language) {
                        case "EN":
                            this.$root.$i18n.locale = Locales.EN
                            break
                        case "RU":
                            this.$root.$i18n.locale = Locales.RU
                            break
                    }
                    setTimeZone(this.user.timeZone)
                }
            },
            async legalisationInProgress(value) {
                if (value) {
                    this.licenseAgreement = await fetchActualEula()
                }
            }
        },
        directives: {
            hideIfHasNoAccessToRoute(el, binding, vnode) {
                const user = vnode.componentInstance.$store.getters.user
                const rootName = vnode.componentOptions.propsData.to.slice(1)
                const route = getRouteByName(rootName)
                const requiresAuth = !doesntRequireAuth(route)
                if (
                    requiresAuth && !(user && hasRoleBasedAccessTo(user, route))
                ) {
                    el.style.display = "none"
                }
            }
        },
        computed: {
            ...mapGetters([
                "user",
                "isAuthenticated",
                "organizationStatistics",
                "supportStatistics",
                "accountDataReady",
                "legalisationInProgress",
            ]),
            ...mapGetters({
                tasksCount: backgroundTasksGetterNames.tasksCount
            }),
            newNotificationsNumber() {
                return this.$store.getters[newNotificationsGetterNames.resourceNumber]
            },
            getBrand() {
                const { brand } = useBrand()
                return brand
            }
        },
        methods: {
            logout() {
                this.$store.dispatch("logout").catch(error => {
                    this.addError({
                        type: "unableToLogoutDueToRunningTasks",
                        details: error
                    })
                })
                this.dropdownShown = false
            },
            goToProfile() {
                this.dropdownShown = false
                this.openModal("profile")
            },
            ...mapActions([
                "confirmLegalisation",
                "abortLegalisation",
            ])
        },
        created() {
            window.addEventListener("beforeunload", (event) => {
                if (this.tasksCount !== 0) {
                    event.preventDefault()
                    event.returnValue = this.$tc("backgroundTasksAlert", this.tasksCount)
                }
            })
            const v = this

            function keydownListener(event) {
                if (event.ctrlKey && event.code === "KeyH") {
                    event.preventDefault()
                    v.toggleDocMode()
                }
            }

            window.addEventListener("keydown", keydownListener)
            this.removeKeydownListener = () => window.removeEventListener("keydown", keydownListener)
        },
        destroyed() {
            this.removeKeydownListener?.()
        },
        i18n: {
            messages: {
                [Locales.EN]: {
                    "home": "Home",
                    "review": "Review",
                    "approve": "Approve",
                    "archive": "Archive",
                    "samples": "Samples",
                    "labQc": "Lab QC",
                    "import": "Import",
                    "version": "Version",
                    "runsDataExport": "Export",
                    "docs": "Docs",
                    "lotManagement": "Lot management",
                    "userMenu": {
                        "logout": "Log out",
                        "notifications": "Notifications"
                    },
                    terms: "Terms",
                    support: "Support",
                    backgroundTasksAlert: "You have {n} running background tasks (e.g. uploading files). Do you really want to leave?",
                    missingDocs: "Interactive documentation for this page is unavailable",
                    error: "Error",
                    unableToLogoutDueToRunningTasks: "Cannot logout due to background tasks"
                },
                [Locales.RU]: {
                    "home": "Главная",
                    "review": "Утверждение",
                    "approve": "Одобрение",
                    "archive": "Архив",
                    "samples": "Образцы",
                    "labQc": "Лаб. КК",
                    "import": "Импорт данных",
                    "runsDataExport": "Экспорт данных",
                    "docs": "Документация",
                    "version": "Версия",
                    "lotManagement": "Серии реагентов",
                    "userMenu": {
                        "logout": "Выйти",
                        "notifications": "Сообщения"
                    },
                    terms: "Условия",
                    support: "Поддержка",
                    backgroundTasksAlert: "У вас {n} незавершенных фоновых задач (e.g. загрузка файлов). Вы точно хотите покинуть приложение?",
                    missingDocs: "Интерактивная документация для данной страницы недоступна",
                    error: "Ошибка",
                    unableToLogoutDueToRunningTasks: "Невозможно выйти из аккаунта так как у вас есть незавершенные фоновые задачи (e.g. загрузка файлов)"
                },
            }
        }
    }
</script>

<style scoped>
    #app {
        display: grid;
        grid-template-areas: "panel content content";
        grid-template-rows: 100%;
        grid-template-columns: fit-content(15rem) 1fr 30rem;
        height: 100vh;
        --navigation-panel-dark-grey: hsla(0, 0%, 25%, 1);
        --navigation-panel-light-grey: #898989
    }

    #app.doc-mode {
        grid-template-areas: "panel content docs";
    }

    #soft-logo {
        padding: 2.5rem 0;
        margin-bottom: auto;
        width: 100%;
        height: 12rem;
        color: white;
        flex-shrink: 0;
    }

    #ivd-logo {
        width: 100%;
        height: 2rem;
        color: white;
        margin-bottom: 1rem;
        flex-shrink: 0;
    }

    .app-content {
        grid-area: content;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .navigation-panel {
        grid-area: panel;
        background-color: var(--navigation-panel-dark-grey);
        display: flex;
        flex-flow: column;
        width: fit-content;
    }

    .user-menu {
        max-width: 90%;
        align-self: center;
        position: relative;
        margin-top: auto;
        color: white;
    }

    .user-menu:focus-within .user-menu--btn {
        background-color: var(--navigation-panel-light-grey);
    }

    .user-menu--btn {
        width: 100%;
        color: white;
    }

    .user-menu--btn:hover {
        background-color: var(--navigation-panel-light-grey);
    }

    .user-menu--dropdown-items {
        width: max-content;
        background-color: var(--navigation-panel-dark-grey);
        list-style: none;
    }

    .user-menu--dropdown-item {
        padding: .5em 1em;
        cursor: pointer;
    }

    .user-menu--dropdown-item:hover {
        background-color: var(--navigation-panel-light-grey);
    }

    .navigation-link {
        padding: .5em;
        color: white;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
    }

    .navigation-link--primary.router-link-active {
        background-color: var(--navigation-panel-light-grey);
    }

    .sup-badge {
        margin-left: .3em;
        font-size: .5em;
        transform: translateY(-25%);
    }

    .navigation-panel--version {
        font-size: 1.2rem;
        color: var(--grey-400);
        text-align: center;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    .navigation-panel--links {
        font-size: 1.8rem;
    }

    .new-notifications-indicator {
        display: block;
        position: relative;
        top: 5px;
        right: 1px;
        border-radius: 50%;
        /* Just empirical remark, at some rem values circle looks a little uneven, though they have
         *  the same height and width.
         */
        --size: .7rem;
        width: var(--size);
        height: var(--size);
        align-self: start;
    }

    .secondary-navigation {
        display: flex;
        align-items: center;
        text-align: center;
        margin: .5rem 0;
        font-size: 1.2rem;
        justify-content: center;
    }
</style>
