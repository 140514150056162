import Vue, { CreateElement, VNode } from "vue"

interface Chart {
    onLocaleChange: (() => void) | null
    onDataChange: ((newData: any) => void) | null
    resize: (() => void) | null
}

export const ChartContainer = Vue.component("ChartContainer", {
    props: {
        chartConstructorFn: Function,
        data: [ Object, Array ],
    },
    watch: {
        "$root.$i18n.locale": {
            handler() {
                if (this.chart?.onLocaleChange) {
                    this.chart.onLocaleChange()
                }
            },
        },
        "data": {
            handler(newValue) {
                if (!newValue) {
                    return
                }

                if (this.chart) {
                    if (this.chart.onDataChange) {
                        this.chart.onDataChange(newValue)
                    } else {
                        console.error("Chart data changed, but chart doesn't know what to do with updates. " +
                            "Implement onDataChange in the chart or rerender component." +
                            "The easiest way is to use the :key on the component container and then when the data changes it will be automatically re-rendered.")
                    }
                } else {
                    /*
                     Render is called after watcher and there are cases when data is immediately available.
                     Cause render is not yet called we don't have div for chart creation, but since
                     data is available there will be attempt to create chart that will fail.
                     Maybe it's better to use mounted/updated callbacks, however I need to put this code in
                     both methods, meh. So I will leave hack with $nextTick.
                     By the way it seems that updated is not called on props change.
                    */
                    this.$nextTick(() => this.createChart())
                }
            },
            immediate: true
        }
    },
    data(): { chart: Chart | null } {
        return {
            chart: null
        }
    },
    methods: {
        createChart() {
            this.chart = this.chartConstructorFn(this.data)
            if (this.chart?.resize) {
                // Too wide resize event? Can be narrowed to chart resize by adding id
                window.addEventListener("resize", this.chart.resize)
            }
        },
        destroyChart() {
            if (this.chart?.resize) {
                window.removeEventListener("resize", this.chart.resize)
            }
            this.chart = null
        }
    },
    destroyed() {
        this.destroyChart()
    },
    render(createElement: CreateElement): VNode {
        return createElement("div")
    }
})
