<template>
    <div style="display: flex; flex-flow: column; padding: 3rem 5rem; width: 80rem; max-width: 90vw; max-height: 90vh;">
        <h2 style="text-align: center">ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ</h2>
        <h4 style="text-align: center" class="mb-4">на использование программного обеспечения «PARallele™ HLA
            software»</h4>
        <article class="license__text overflow-auto custom-scroll">
            <h4 style="text-align: center; margin-bottom: 2rem; position: sticky; top: 0; background-color: white">Уважаемый Пользователь!</h4>
            <p v-html="licenseAgreement.text"></p>
        </article>
        <span style="text-align: right;" class="mt-2">Текущая версия Соглашения: v.PAR-{{ licenseAgreement.serialNumber.toString().padStart(2, "0") }}
        </span>
        <div class="flex mt-3">
            <button class="md-btn-text"
                    @click="$emit('reject')"
            >{{ $t("buttons.reject") }}
            </button>
            <button class="md-btn-contained ml-auto royal-blue"
                    @click="$emit('accept')"
            >{{ $t("buttons.accept") }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LicenseAgreement",
        props: {
            licenseAgreement: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
.license__text {
    padding: 0 3rem;
    text-align: justify;
}
</style>
